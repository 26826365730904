import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PortalService } from '../../portal.service';

@Component({
  selector: 'app-exhibitor',
  templateUrl: './exhibitor.component.html',
  styleUrls: ['./exhibitor.component.css']
})
export class ExhibitorComponent implements OnInit {
  @Input() exhibitors
  showExhibitorName = -1;
  show: boolean = false
  constructor(public portaleventservice: PortalService) {

  }
  ngOnInit(): void {
  }
  showElement(id) {
    this.showExhibitorName = id;
  }
  showMoreDetails(value, id) {
    
    if (value) {
      this.showExhibitorName = id;
      this.show = true
      document.getElementById(id).style.background = "transparent linear-gradient(180deg,#00000059 0%,#00000094 0%,#000000 100%) 0% 0% no-repeat padding-box";
    }
    else {
      this.showExhibitorName = null;
      this.show = false
      document.getElementById(id).style.background = "transparent   linear-gradient(180deg, #00000000 0%, #000000E7 80%, #000000 100%) 0% 0% no-repeat padding-box";

    }
  }
}
