import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EventPageComponent } from '../event-page/event-page.component';
import { DefaultLayoutComponent } from './default-layout.component';
import { DashboardTestService } from './../dashboard-test.service';
import { PassesComponent } from '../passes/passes.component';
import { InvitationsComponent } from '../invitations/invitations.component';
import { RegistrationComponent } from '../registration/registration.component';
import { SessionsComponent } from '../sessions/sessions.component';
import { ExhibitorsComponent } from '../exhibitors/exhibitors.component';
import { PortalService } from '../../portal/portal.service';

const routes: Routes =
    [
        { 
            path: "", 
            children: [
                {
                    path: "event",
                    resolve: { DashboardTestService,PortalService },
                    component: EventPageComponent,
                    data: {
                        title: "Event page",
                    },
                },
                {
                    path: "registration",
                    component: RegistrationComponent,
                    resolve: { DashboardTestService },
                    data: {
                        title: "Registration",
                    },
                },
                {
                    path: "passes",
                    component: PassesComponent,
                    resolve: { DashboardTestService },
                    data: {
                        title: "Access pass",
                    },
                },
                {
                    path: "invitations",
                    component: InvitationsComponent,
                    resolve: { DashboardTestService },
                    data: {
                        title: "Invitations",
                    },
                }
                ,
                {
                    path: "sessions",
                    component: SessionsComponent,
                    resolve: { DashboardTestService },
                    data: {
                        title: "Sessions",
                    },
                }
                ,
                {
                    path: "exhibitors",
                    component: ExhibitorsComponent,
                    resolve: { DashboardTestService },
                    data: {
                        title: "exhibitors",
                    },
                }
            ]
        }
    ];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DefaultLayoutRoutingModule { }
