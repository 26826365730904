import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { DashboardTestService } from '../dashboard-test.service';

@Component({
  selector: 'app-add-edit-session',
  templateUrl: './add-edit-session.component.html',
  styleUrls: ['./add-edit-session.component.css']
})
export class AddEditSessionComponent implements OnInit {

  @Input() data;
  AddEditSessionForm: FormGroup
  constructor(private fb: FormBuilder, private dashboardtestservice: DashboardTestService, public dialogRef: NbDialogRef<AddEditSessionComponent>) { }
  ngOnInit(): void {
  }

}
