import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { DataService } from 'angular2-multiselect-dropdown/lib/multiselect.service';
import { AddEditSessionComponent } from '../add-edit-session/add-edit-session.component';
import { DashboardTestService } from '../dashboard-test.service';
import * as lodash from 'lodash';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.css']
})
export class SessionsComponent implements OnInit {
  eventID
  constructor(public dialog: NbDialogService, private route: ActivatedRoute, private dashboardtestservice: DashboardTestService) { }
  rooms
  myRooms
  sessions: any = []
  days
  selectedday = "0"
  status = "Live"
  selectedRoom: any;
  timerRooms
  rightArrowValue: number;
  activated: boolean = false
  selected_session: any;
  show: boolean;
  speaker: any;
   selectedSession: any;
    rightArrowValueDay: number;
  overflowActivated: boolean;
  overflowActivateddays: boolean;
  overflowActivatedRooms: boolean;
  ngOnInit(): void {

    this.route.params.subscribe((params) => {
      this.eventID = params["eventid"];
    });
    this.dashboardtestservice.onChangedDaysByEventId.subscribe(res => {
      this.days = res
    })
    this.dashboardtestservice.onChangedRoomsByEventid.subscribe(res => {
      this.rooms = res
    })
    if (this.rooms[0]) {
      this.selectedRoom = this.rooms[0]
      this.rooms[0].sessions.forEach(session => {
        if (session.day_index == this.selectedday) {
          this.sessions.push(session)
        }
      });
     
    }
  }
  ngAfterViewInit() {
    if (document.getElementById(this.selectedday)) {
      document.getElementById(this.selectedday).style.background = "linear-gradient(#ffffff 0px, #ffffff 0px) left bottom / 25% 4px no-repeat";
    }
    if (document.getElementById("Days") && document.getElementById("days-container")) {
      if (document.getElementById("Days").scrollWidth > document.getElementById("days-container").offsetWidth) {
        this.overflowActivateddays = true
        document.getElementById("days-container").scrollLeft = 0
      }
    }
    if (document.getElementById("Rooms") && document.getElementById("Rooms-container")) {
      if (document.getElementById("Rooms").scrollWidth > document.getElementById("Rooms-container").offsetWidth) {
        this.overflowActivatedRooms = true
      }
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (document.getElementById("Days") && document.getElementById("days-container")) {
      if (document.getElementById("Days").scrollWidth > document.getElementById("days-container").offsetWidth) {
        this.overflowActivateddays = true
      }
      else {
        this.overflowActivateddays = false
      }
    }
    if (document.getElementById("Rooms") && document.getElementById("Rooms-container")) {
      if (document.getElementById("Rooms").scrollWidth > document.getElementById("Rooms-container").offsetWidth) {
        this.overflowActivatedRooms = true
      }
      else {
        this.overflowActivatedRooms = false
      }
    }

  }
  setReminder(id) {
    this.selected_session = id

    this.activated = true
  }
  getSessionByDay(i) {
    if (document.getElementById(this.selectedday)) {
      document.getElementById(this.selectedday).style.background = "transparent";
      this.selectedday = i
      document.getElementById(this.selectedday).style.background = "linear-gradient(#ffffff 0px, #ffffff 0px) left bottom / 25% 4px no-repeat";
      this.sessions = []
      this.selectedRoom.sessions.forEach(session => {
        if (session.day_index == this.selectedday) {
          this.sessions.push(session)
        }
      });
    }
  }
  onClickRoom(id) {
    let room = lodash.find(this.rooms, { '_id': id });
    this.selectedRoom = room
    this.sessions = []
    room.sessions.forEach(session => {
      if (session.day_index == this.selectedday) {
        this.sessions.push(session)
      }
    });
  }
  
  scrollDivRooms(elementToScroll: HTMLElement, depl, div, arrowId) {
    if (div == "Rooms") {
      if (this.rooms.length > 0) {
        if (document.getElementById(arrowId) != null) {
          elementToScroll = document.getElementById("Rooms") as HTMLInputElement
          elementToScroll.scrollLeft -= depl;
          this.rightArrowValue = elementToScroll.scrollLeft
          this.timerRooms = setTimeout(() => {
            this.scrollDivRooms(elementToScroll, depl, div, arrowId)
          }, 30);
        }
      }
    }
    else {
      if (this.days.length > 0) {
        if (document.getElementById(arrowId) != null) {
          elementToScroll = document.getElementById("days-container") as HTMLInputElement
          elementToScroll.scrollLeft -= depl;
          this.rightArrowValueDay = elementToScroll.scrollLeft
          this.timerRooms = setTimeout(() => {
            this.scrollDivRooms(elementToScroll, depl, div, arrowId)
          }, 30);
        }
      }
    }

  }
  stopTimerRooms(timerRooms: number) {
    clearTimeout(timerRooms);
  }
 
  AddRoom(){

  }

















  addSessions(day_index) {

    const dialogRef = this.dialog.open(AddEditSessionComponent, {
      context:
      {
        data: {
          name: "add",
          day_index: day_index,
          eventid: this.eventID
        },
      }, hasBackdrop: false
    });
    dialogRef.onClose.subscribe((result) => {
    });
  }
}
