import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { CookieService } from 'ngx-cookie-service';
import { DataService } from '../services/data.service';
@Component({
  selector: 'app-authentification-system',
  templateUrl: './authentification-system.component.html',
  styleUrls: ['./authentification-system.component.css']
})
export class AuthentificationSystemComponent implements OnInit {
  @Input() data
  @Input() context;
  @Output() OutPutDataShared = new EventEmitter();
  loginPopup: boolean = false;
  signupPopup: boolean = false;
  dataLogin
  dataSignup
  accessPopup: boolean = false;
  alertPopup: boolean = false;
  verifPopup: boolean = false;
  constructor(public dialogRef: NbDialogRef<AuthentificationSystemComponent>, public shareddata: DataService, 
    public changeDetectorRef: ChangeDetectorRef,private cookieService: CookieService) {
  }
  ngOnInit(): void {
    this.dataLogin = this.data;
    this.dataSignup = this.data;
    if (this.data.loginType || this.data.status == "auth" || this.data.status == "email") {
      this.loginPopup = true;
      this.signupPopup = false;
      this.accessPopup = false;
      this.alertPopup = false
    }
    else if (this.data.signType) {
      this.loginPopup = false;
      this.signupPopup = true;
      this.accessPopup = false;
      this.alertPopup = false
    }
    else if (this.data.showAccess_Popup) {
      this.accessPopup = false;
    }
    this.shareddata.currentaccesspopup.subscribe((popup)=>{
      if (popup && popup=="email")
      {
        this.loginPopup = true;
        this.signupPopup = false;
      }
    })
  }
  changePopup(event) {
    if (event.popup == "signup") 
    {
      this.loginPopup = false;
      this.alertPopup = false;
      this.accessPopup = false;
      this.signupPopup = true;
      this.verifPopup = false;
      this.dataSignup = event;
      // this.OutPutDataShared.emit(event)
    }
    else if (event.popup == "login") 
    {
      this.loginPopup = true;
      this.signupPopup = false;
      this.alertPopup = false;
      this.accessPopup = false;
      this.verifPopup = false;
      this.dataLogin = event
      // this.OutPutDataShared.emit(event)
    }
    else 
    {
      this.OutPutDataShared.emit(event)
    }
  }
  back() {
    this.dataLogin = this.data
    if (this.data.showAccess_Popup) 
    {
      this.loginPopup = true
      this.accessPopup = false;
    }
    else if (!this.data.showNavBar && !this.cookieService.get("token") && this.shareddata.event &&  this.shareddata.event.is_inviteonly) 
    {
      this.dialogRef.close()
    }
    else if (!this.data.showNavBar && this.cookieService.get("token")) 
    {
      this.loginPopup = true
      this.accessPopup = false;
    }
    else {
      if (this.loginPopup) {
        if (this.dataLogin.loginType || this.dataLogin.signType) {
          this.dialogRef.close((res) => {
            this.changeDetectorRef.reattach();
          })
        }
        else {
          if (this.shareddata.event && !this.shareddata.event.is_inviteonly) {
            this.accessPopup = true
            this.loginPopup = false
          }
          else {
            this.dialogRef.close((res) => {
              this.changeDetectorRef.reattach();
            })
          }
        }
      }
      else {
        if (this.data.signType) {
          this.dialogRef.close((res) => {
            this.changeDetectorRef.reattach();
          })
        }
        else {
          this.loginPopup = true
          this.signupPopup = false
        }
      }
    }
  }
}