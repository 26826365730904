import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import Swal from 'sweetalert2';
import { DashboardTestService } from '../dashboard-test.service';
import { PopupComponent } from '../popup/popup.component';
@Component({
  selector: 'app-passes',
  templateUrl: './passes.component.html',
  styleUrls: ['./passes.component.css']
})
export class PassesComponent implements OnInit {
  passes
  eventID
  type
  constructor(private dashboardtestservice: DashboardTestService, public dialog: NbDialogService, private route: ActivatedRoute) { }
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.eventID = params["eventid"];
    });
    this.dashboardtestservice.onChangedPassesByEventid.subscribe((response: any) => {
      if (response && response.status == "success") {
        this.passes = response.passes
        this.type = response.type
      }
      else {
        this.passes = []
      }
    })
  }
  openPopup(popupname, pass) {
    const dialogRef = this.dialog.open(PopupComponent, {
      context : {

        data: {
          name: popupname,
          eventid: this.eventID,
          pass: pass,
        },
} ,     hasBackdrop:false

    });
    dialogRef.onClose.subscribe((result) => {
    });
  }
  ListOfVouchers(popupname, ticketid) {
    const dialogRef = this.dialog.open(PopupComponent, {
      context : {

        data: {
          name: popupname,
          eventid: this.eventID,
          ticketid: ticketid,
        },
},      hasBackdrop:false

    });
    dialogRef.onClose.subscribe((result) => {
    });
  }
  generateVoucher(popupname, pass) {
    const dialogRef = this.dialog.open(PopupComponent, {
      context : {

        data: {
          name: popupname,
          eventid: this.eventID,
          pass: pass,
          ticketid: pass._id
        },
},      hasBackdrop:false

    });
    dialogRef.onClose.subscribe((result) => {
    });
  }
  deleteTicket(ticketid) {
    Swal.fire({
      title: "Are you sure want to remove this ticket?",
      icon: "warning",
      showCancelButton: true,
      // showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.dashboardtestservice.deleteTicketById(ticketid, this.eventID).then((res: any) => {
          Swal.fire(res.message, "", res.status);
        });
        // Swal.fire("Deleted!", "The event has been deleted.", "success");
      } else if (result.dismiss == Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "", "error");
      }
    });
  }
}
