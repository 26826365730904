import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PortalRoutingModule } from './portal-routing.module';
import { PortalEventComponent } from './portal-event/portal-event.component';
import { SessionComponent } from './portal-event/session/session.component';
import { SpeakerComponent } from './portal-event/speaker/speaker.component';
import { ExhibitorComponent } from './portal-event/exhibitor/exhibitor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountdownModule } from 'ngx-countdown';
import { NbIconModule, NbCheckboxModule, NbTooltipModule, NbPopoverModule, NbSelectModule } from '@nebular/theme';
import { PortalComponent } from './portal.component';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../auth.interceptor';
import { EventSessionComponent } from './event-session/event-session.component';
import { EventChannelComponent } from './event-channel/event-channel.component';
import { SharedLayoutModule } from '../shared-layout/shared-layout.module';
 @NgModule({
  declarations: [
    PortalComponent,
    PortalEventComponent,
    SessionComponent,
    SpeakerComponent,
    ExhibitorComponent,
    EventSessionComponent,
    EventChannelComponent,
    ],
  imports: [
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    PortalRoutingModule,
    RouterModule,
    NbIconModule,
    NbCheckboxModule,
    CountdownModule,
    NbTooltipModule,
    NbPopoverModule,
    NbSelectModule,
    FormsModule   ,
    SharedLayoutModule
    
  ],
  // providers: [  { 
  //   provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true,
  // },],
  exports:[    PortalComponent,
    EventSessionComponent,
    PortalEventComponent,
    SessionComponent,
    SpeakerComponent,
    ExhibitorComponent,]
})
export class PortalModule { }
