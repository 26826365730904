import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

import videojs from 'video.js';
import 'videojs-sprite-thumbnails'
// import 'videojs-contrib-ads';
// import 'videojs-ima';
import 'videojs-playlist'
import 'videojs-playlist-ui'
import 'videojs-overlay'
import { BehaviorSubject, Subject } from 'rxjs';
@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css']
})
export class VideoPlayerComponent implements OnInit {
  @ViewChild('target', { static: true }) target: ElementRef;

  @Input() data
  @Input() options: {
    // fluid: true,
    aspectRatio: '9:16',
    autoplay: true,
    responsive: true,
  };
  session
  player: videojs.Player;
  duration: number;
  videosrc = [];
  ad: boolean;
  countDownText = "10"
  endVideo = new BehaviorSubject(false);
  shownSessions = []
  currentSession
  shownVideos: any = [];
  play: boolean = false;
  finishedSession: boolean = false;
  userActivity;
  userInactive: Subject<any> = new Subject();
  event_title
  constructor(private dialogRef: NbDialogRef<VideoPlayerComponent>, private cd: ChangeDetectorRef) {
    this.setTimeout();
    this.userInactive.subscribe(() => {
      this.showControls(false)
    });
  }
  ngOnInit(): void {
    this.event_title = this.data.title
    this.shownVideos = this.data.sessions
    /***************************************Create the playlist**************************/
    let newArray = []
    for (var i = 0; i < this.data.sessions.length; i++) {
      var vidsUrl = this.data.sessions[i].video_url;
      if (i >= this.data.index) {
        this.videosrc.push({ sources: [{ src: vidsUrl, type: 'video/mp4' }], name: this.data.sessions[i].title ,session : this.data.sessions[i]});
      }
      else {
        newArray.push({ sources: [{ src: vidsUrl, type: 'video/mp4' }], name: this.data.sessions[i].title,session : this.data.sessions[i] });

      }
    }
  console.log("this.videosrc",this.videosrc)
  console.log("newArray",newArray)
    if (newArray.length > 0) {
      for (let i = 0; i < newArray.length; i++) {
        this.videosrc.push(newArray[i])
      }
    }

    /************************************* Single video *********************************/
    var midrollPlayer = false
    var data = this.data
    var videosrcLocal = this.videosrc
    var sentDuration = true;
    let session = new BehaviorSubject(this.videosrc[1]);
    let duration = new BehaviorSubject(0);
    let videoEnd = new BehaviorSubject(false);
    let ad = new BehaviorSubject(false);
    var currentSessionLocal

    if (this.videosrc.length > 0) {
      currentSessionLocal = new BehaviorSubject(this.videosrc[0].name);
    }
    var buttonComponent = videojs.getComponent('Button');
    var prevButton
    var nextButton
    var player
    videojs.Hls.xhr.beforeRequest = function (options) {
      options.headers = {
        "Access-Control-Allow-Origin": "*",
      };
      return options;
    };
    videojs.registerPlugin('examplePlugin', function () {
      player = this
      player.playlist(videosrcLocal);
      player.playlist.autoadvance(5)
      // if (player.playlist.currentIndex() == 0) {
      //   player.ads();
      // }
      currentSessionLocal.next(videosrcLocal[0].name)
      player.on("loadedmetadata", (event) => {
        currentSessionLocal.next(videosrcLocal[this.playlist.currentIndex()].name)
        videoEnd.next(false)
        midrollPlayer = false
        sentDuration = true
        if (this.playlist.currentIndex() < videosrcLocal.length - 1) {
          session.next(videosrcLocal[this.playlist.nextIndex()].session)
        }
        else {
          session.next(null)
        }
        duration.next(player.duration())
        if (this.playlist.currentIndex() == 0) {
          player.getChild('controlBar').removeChild('prevButton');
          player.getChild('controlBar').removeChild('nextButton');
          player.getChild('controlBar').addChild('nextButton', {}, 1);

        }
        else if (this.playlist.currentIndex() == (videosrcLocal.length - 1).toString()) {
          player.getChild('controlBar').removeChild('nextButton');
          player.getChild('controlBar').removeChild('prevButton');
          player.getChild('controlBar').addChild('prevButton', {}, 0);

        }
        else {
          player.getChild('controlBar').removeChild('prevButton');
          player.getChild('controlBar').removeChild('nextButton');
          player.getChild('controlBar').addChild('prevButton', {}, 0);
          player.getChild('controlBar').addChild('nextButton', {}, 2);
        }
      });
      player.on('play', function () {
        videoEnd.next(false)
      })
      player.on('contentchanged', function () {
        videoEnd.next(false)
        midrollPlayer = false
        sentDuration = true

        if (this.playlist.currentIndex() < videosrcLocal.length - 1) {
          session.next(videosrcLocal[this.playlist.nextIndex()].session)
        }
        else {
          session.next(null)
        }
        buttonComponent = videojs.getComponent('Button');
      });
      /************ preroll Ads **************/
      // player.on('readyforpreroll', function () {
      //   {
      //     if (player.playlist.currentIndex() == 0) {
      //       player.ads.startLinearAdMode();
      //       player.src('../../assets/Video.mp4');
      //       player.one('adplaying', function () {
      //         player.trigger('ads-ad-started');
      //         ad.next(true)
      //       });
      //       player.one('adended', function () {
      //         player.ads.endLinearAdMode();
      //         player.playlist.autoadvance(5)
      //         ad.next(false)
      //       });
      //     }
      //   }
      // });
      player.on('ended', function () {

        // videoEnd.next(true)
      })
      /************ midroll Ads **************/
      player.on('timeupdate', function (event) {

        if (player) {
          if (player.currentTime() == player.duration()) {
            videoEnd.next(true)
            player.trigger('ended');
          }
        }
        // if (!midrollPlayer) {
        //   if (player.playlist.currentIndex() == 0 && Math.round(player.currentTime()) == 15) {
        //     player.ads.startLinearAdMode();
        //     player.src('../../assets/Video.mp4');
        //     player.one('adplaying', function () {
        //       player.trigger('ads-ad-started');
        //       ad.next(true)
        //     });
        //     player.one('adended', function () {
        //       player.ads.endLinearAdMode();
        //       player.playlist.autoadvance(5)
        //       ad.next(false)
        //       midrollPlayer = true
        //     });
        //   }
        // }
      });
      // if (player.playlist.currentIndex() == 0) {
      //   player.trigger('adsready');
      // }
    });
    /************************************* Playlist *********************************/
    this.player = videojs(this.target.nativeElement, {
      inactivityTimeout: 0,
      Headers: {
        "Access-Control-Allow-Origin": "*",
      },
      crossOrigin: "Anonymous"
    })
    // this.player.playlist(this.videosrc);
    player = this.player
    this.player.examplePlugin()
    this.player.playlistUi({});
    // this.player.playlist.autoadvance(5)
    buttonComponent = videojs.getComponent('Button');
    prevButton = videojs.extend(buttonComponent, {
      constructor: function () {
        buttonComponent.apply(this, arguments);
        this.addClass('vjs-icon-previous-item');
        this.controlText('Previous');
      },
      handleClick: function (e) {
        player.playlist.previous();
      }
    });
    nextButton = videojs.extend(buttonComponent, {
      constructor: function () {
        buttonComponent.apply(this, arguments);
        this.addClass('vjs-icon-next-item');
        this.controlText('Next');
      },
      handleClick: function (e) {
        player.playlist.next();
      }
    });
    videojs.registerComponent('prevButton', prevButton);
    videojs.registerComponent('nextButton', nextButton);
    if (player.playlist.currentIndex() == 0) {
      player.getChild('controlBar').removeChild('prevButton');
      player.getChild('controlBar').removeChild('nextButton');
      player.getChild('controlBar').addChild('nextButton', {}, 1);
    }
    else if (player.playlist.currentIndex() == (this.videosrc.length - 1).toString()) {
      player.getChild('controlBar').removeChild('nextButton');
      player.getChild('controlBar').removeChild('prevButton');
      player.getChild('controlBar').addChild('prevButton', {}, 0);
    }
    else {
      player.getChild('controlBar').addChild('prevButton', {}, 0);
      player.getChild('controlBar').addChild('nextButton', {}, 2);
    }
    // this.player.spriteThumbnails({
    //   url: '../../assets/coverChannel2.jpg',
    //   width: 100,
    //   height: 100,
    // });
    duration.subscribe((res) => {
      if (res != 0) {
        this.duration = res
        if (this.data.sessions.length > 1) {
          if (this.session != null) {
            player.overlay({
              content: document.getElementById("nextVideo"),
              overlays: [{
                start: this.duration,
                end: this.duration + 10,
                align: 'bottom-right'
              }]
            });
          }
          else {
            this.onResize()
            player.overlay({
              content: document.getElementById("allVideo"),
              overlays: [{
                start: this.duration,
                end: this.duration + 10,
                align: 'center'
              }]
            });
          }
        }

      }
    })
    ad.subscribe((res) => {
      this.ad = res
    })
    session.subscribe((res) => {
      // this.currentSession = this.videosrc[player.playlist.currentIndex()]      
      if (res && res != null) {
        this.session = res
      }
      else {
        this.session = null
      }
    })
    videoEnd.subscribe((res: any) => {
      if (res == true) {
        this.endVideo.next(true)
        this.countDown(10)
      }
      else {
        this.endVideo.next(false)
      }
    })
    currentSessionLocal.subscribe((res) => {
      if (res) {
        this.currentSession = res
      }
    })
  }
  @HostListener('document:keydown.Space ', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.play) {
      this.target.nativeElement.play()
      this.play = !this.play
    }
    else {
      this.target.nativeElement.pause()
      this.play = !this.play
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.shownVideos = []
    if (document.getElementById("allVideo")) {
      if (window.innerWidth >= 1267 && window.innerWidth < 1920) {
        document.getElementById("allVideo").style.width = "100%"
      }
      else {
        document.getElementById("allVideo").style.width = "80vw"

      }
      if (window.innerWidth >= 1267 && window.innerWidth < 1920) {
        if (this.data.sessions.length >= 3) {
          for (let i = 0; i < 3; i++) {
            const el = this.data.sessions[i];
            this.shownVideos.push(el)
          }
        }
        else {
          for (let i = 0; i < this.data.sessions.length; i++) {
            const el = this.data.sessions[i];
            this.shownVideos.push(el)
          }
        }

      }
      else if (window.innerWidth >= 1920) {
        if (this.data.sessions.length >= 6) {
          for (let i = 0; i < 6; i++) {
            const el = this.data.sessions[i];
            this.shownVideos.push(el)
          }
        }
        else {
          for (let i = 0; i < this.data.sessions.length; i++) {
            const el = this.data.sessions[i];
            this.shownVideos.push(el)
          }
        }


      }
      else if (window.innerWidth < 1297) {
        if (this.data.sessions.length >= 2) {
          for (let i = 0; i < 2; i++) {
            const el = this.data.sessions[i];
            this.shownVideos.push(el)
          }
        }
        else {
          for (let i = 0; i < this.data.sessions.length; i++) {
            const el = this.data.sessions[i];
            this.shownVideos.push(el)
          }
        }

      }


    }
  }
  countDown(time) {
    setTimeout(() => {
      time = time - 1
      this.countDownText = time.toString()
      if (time > 0) {
        this.countDown(time)
      }
    }, 1000);
  }
  ngAfterViewInit() {
    // this.player.src({
    //   src: '//commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
    //   overrideNative: true,
    //   captionServices: {
    //     CC1: {
    //       language: 'en',
    //       label: 'English'
    //     },
    //     SERVICE1: {
    //       langauge: 'fr',
    //       label: 'French',
    //       encoding: 'euc-fr',
    //       default: true,
    //     }
    //   }
    // });

    /********************************************  thumbnails  *****************************************************/
    // this.player.spriteThumbnails({
    //   url: '../../assets/coverChannel2.jpg',
    //   width: 100,
    //   height: 100,
    // });



    /********************************************Ads using google ima *****************************************************/

    // this.player.ads(); // initialize videojs-contrib-ads

    // // request ads whenever there's new video content
    // this.player.on('contentchanged', function () {

    //   // in a real plugin, you might fetch new ad inventory here
    //   this.player.trigger('adsready');
    // });

    // this.player.on('readyforpreroll', function () {
    //   this.player.ads.startLinearAdMode();
    //   // play your linear ad content
    //   // in this example, we use a static mp4
    //   this.player.src('../../assets/Video.mp4');
    //   // send event when ad is playing to remove loading spinner
    //   this.player.one('adplaying', function () {
    //     this.player.trigger('ads-ad-started');
    //   });

    //   // resume content when all your linear ads have finished
    //   this.player.one('adended', function () {
    //     this.player.ads.endLinearAdMode();
    //   });
    // });



    /********************************************  Ads using google ima   *****************************************************/
    // var imaOptions = {
    //   adTagUrl: 'http://pubads.g.doubleclick.net/gampad/ads?sz=640x480&' +
    //   'iu=/124319096/external/ad_rule_samples&ciu_szs=300x250&ad_rule=1&' +
    //   'impl=s&gdfp_req=1&env=vp&output=xml_vmap1&unviewed_position_start=1&' +
    //   'cust_params=sample_ar%3Dpremidpostpod%26deployment%3Dgmf-js&cmsid=496&' +
    //   'vid=short_onecue&correlator=',
    // };

    // this.player.ima(imaOptions);
    // var player = this.player
    // var startEvent = 'click';
    // if (navigator.userAgent.match(/iPhone/i) ||
    //     navigator.userAgent.match(/iPad/i) ||
    //     navigator.userAgent.match(/Android/i)) {
    //   startEvent = 'touchend';
    // }
    // player.one(startEvent, function() {
    //   player.ima.initializeAdDisplayContainer();
    // });
  }
  @HostListener('window:mousemove', ['$event']) refreshUserState(e) {

    if (e) {
      this.showControls(true)
    }
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 3000);
  }
  onClickSkipAds() {
    this.player.trigger('adended');
  }
  onClickOnNextSession() {
    this.player.playlist.next();
  }
  showControls(show) {
    let value = false
    this.endVideo.subscribe((res: any) => {
      if (res == true) {
        value = true
      }
      else {
        value = false
      }
      if (document.getElementById("videoContent")) {
        if (show) {
          document.getElementById("videoContent").style.visibility = "visible"
        }
        else if (!show && !value) {
          document.getElementById("videoContent").style.visibility = "hidden"
        }
        else if (!show && value) {
          document.getElementById("videoContent").style.visibility = "visible"
        }
      }
    })
    if (document.getElementById("videoContent")) {
      if (show) {
        document.getElementById("videoContent").style.visibility = "visible"
      }
      else if (!show && !value) {
        document.getElementById("videoContent").style.visibility = "hidden"
      }
      else if (!show && value) {
        document.getElementById("videoContent").style.visibility = "visible"
      }
    }
  }
  closeVideoPlayer() {
    this.dialogRef.close()
  }
  playVideo() {
    if (this.play) {
      this.target.nativeElement.play()
      this.play = !this.play
    }
    else {
      this.target.nativeElement.pause()
      this.play = !this.play
    }
  }
  selectSession(i) {
    this.player.playlist.currentItem(i);
  }
}
