import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as lodash from 'lodash';
import jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ExploreEventService implements Resolve<any> {
  url: any;
  params: any;
  data: any;
  onChangedRecommendedEvents: BehaviorSubject<any>;
  onChangedUpcommingEvents: BehaviorSubject<any>;
  onChangedPlaylist: BehaviorSubject<any>;
  onChangedSpeaker: BehaviorSubject<any>;
  onChangedTestimonials: BehaviorSubject<any>;
  onChangedSession: BehaviorSubject<any>;
  onChangedCategories: BehaviorSubject<any>;
  onChangedExplore: BehaviorSubject<any>;
  onChangedEventById: BehaviorSubject<any>;
  test: any;
  explore_id

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private cookieService: CookieService) {
    this.onChangedRecommendedEvents = new BehaviorSubject([]);
    this.onChangedUpcommingEvents = new BehaviorSubject([]);
    this.onChangedPlaylist = new BehaviorSubject(null);
    this.onChangedSpeaker = new BehaviorSubject([]);
    this.onChangedTestimonials = new BehaviorSubject([]);
    this.onChangedSession = new BehaviorSubject([]);
    this.onChangedCategories = new BehaviorSubject([]);
    this.onChangedExplore = new BehaviorSubject([]);
    this.onChangedEventById  = new BehaviorSubject(null);
    this.url = environment.url
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  
    return new Promise<void>((resolve, reject) => {
      this.params = route.params;
      this.data = route.data;
      Promise.all([
        this.getCategories()
      ]).then(
        () => {
          resolve();
        },
        (reject) => {
        }
      );
    });
  }
  getCoverData() {

  }
  getCategories()
  {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get(
          this.url + "/exploreevents/categories"
        )
        .subscribe((response: any) => {
          if (response && response.status == "success") {
            this.onChangedCategories.next(response.categories)
            this.explore_id=response.categories._id
            resolve(response.categories);
          }
        }, reject);
    });
  }
  getAllEvents() {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get(this.url + "/exploreevents/recommended")
        .subscribe((response: any) => {
          if (response && response.status == "success") {
            this.test = response
            this.onChangedRecommendedEvents.next(response.recommended_events)
            resolve(response.recommended_events.recommended_events);
          }
        }, reject);
    });
  }
  getLaunchedEvents() {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get(
          this.url + "/exploreevents/upcoming"
        )
        .subscribe((response: any) => {
          if (response && response.status == "success") {

            this.onChangedUpcommingEvents.next(response.upcoming_events)
            resolve(response.upcoming_events.recommended_events);
          }

        }, reject);
    });
  }
  // getSessionById(id) {
  //   let channel = {
  //     title: "This is the channel name",
  //     cover_url: "../../../../assets/coverChannel2.jpg",
  //     sessions: [
  //       {
  //         _id: "8216000cbe39a48cdaedc942",
  //         title: "Ux Design Introduction 1",
  //         starting_time: "11:30",
  //         ending_time: "12:00",
  //         speakers: [
  //           {
  //             _id: "6216000cbe39a48cdaedc942",
  //             name: "rania",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png",
  //             bio: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolor sit am dolore nonumy eirmod em ipsum dolor sit amet, cons…"
  //           },
  //           {
  //             _id: "621793f9fcdabec32c245c3f",
  //             name: "Dalanda Drissi",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png",
  //             bio: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolor sit am dolore nonumy eirmod em ipsum dolor sit amet, cons…"
  //           },
  //           {
  //             _id: "62179401fcdabec32c245c41",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //         ],
  //         room: "628f5a88ba7ebf3accd84507",
  //         event_id: "628e4932c54d0cad187b5862",
  //         event_title: "World biggest conference for digital technologies Title",
  //         status: "finished",
  //         tags: [
  //           "session",
  //           "room",
  //           "azerty",
  //           "hello"
  //         ],
  //         duration: "1h30min",
  //         cover_url: "../../../../assets/coverChannel3.jpg",
  //         video_url: "../../assets/v11_Trim.mp4"
  //       },

  //       {
  //         _id: "11216000cbe39a48cdaedc942",
  //         title: "Ux Design Introduction 3",
  //         starting_time: "11:30",
  //         ending_time: "12:00",
  //         speakers: [
  //           {
  //             _id: "6216000cbe39a48cdaedc942",
  //             name: "rania",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png",
  //             bio: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolor sit am dolore nonumy eirmod em ipsum dolor sit amet, cons…"
  //           },
  //           {
  //             _id: "621793f9fcdabec32c245c3f",
  //             name: "Dalanda Drissi",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png",
  //             bio: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolor sit am dolore nonumy eirmod em ipsum dolor sit amet, cons…"
  //           },
  //           {
  //             _id: "62179401fcdabec32c245c43",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c44",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c45",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c46",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c47",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c48",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c49",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c50",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c41",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c52",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //         ],
  //         room: "628f5a88ba7ebf3accd84553",
  //         event_id: "628e4932c54d0cad187b5862",
  //         event_title: "World biggest conference for digital technologies Title",

  //         status: "finished",
  //         tags: [
  //           "session",
  //           "room",
  //           "azerty",
  //           "hello"
  //         ],
  //         duration: "1h30min",
  //         cover_url: "../../../../assets/coverChannel2.jpg",
  //         video_url: "../../assets/v4.mp4"
  //       },
  //       {
  //         _id: "12216000cbe39a48cdaedc942",
  //         title: "Ux Design Introduction 4",
  //         starting_time: "11:30",
  //         ending_time: "12:00",
  //         speakers: [
  //           {
  //             _id: "6216000cbe39a48cdaedc942",
  //             name: "rania",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png",
  //             bio: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolor sit am dolore nonumy eirmod em ipsum dolor sit amet, cons…"
  //           },
  //           {
  //             _id: "621793f9fcdabec32c245c3f",
  //             name: "Dalanda Drissi",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png",
  //             bio: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolor sit am dolore nonumy eirmod em ipsum dolor sit amet, cons…"
  //           },
  //           {
  //             _id: "62179401fcdabec32c245c43",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c44",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c45",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c46",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c47",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c48",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c49",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c50",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c41",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c52",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //         ],
  //         room: "628f5a88ba7ebf3accd84553",
  //         event_id: "628e4932c54d0cad187b5862",
  //         event_title: "World biggest conference for digital technologies Title",

  //         status: "finished",
  //         tags: [
  //           "session",
  //           "room",
  //           "azerty",
  //           "hello"
  //         ],
  //         duration: "1h30min",
  //         cover_url: "../../../../assets/coverChannel6.jpg",
  //         video_url: "../../assets/v11.mp4"
  //       },
  //       {
  //         _id: "25216000cbe39a48cdaedc942",
  //         title: "Ux Design Introduction 5",
  //         starting_time: "11:30",
  //         ending_time: "12:00",
  //         speakers: [
  //           {
  //             _id: "6216000cbe39a48cdaedc942",
  //             name: "rania",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png",
  //             bio: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolor sit am dolore nonumy eirmod em ipsum dolor sit amet, cons…"
  //           },
  //           {
  //             _id: "621793f9fcdabec32c245c3f",
  //             name: "Dalanda Drissi",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png",
  //             bio: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolor sit am dolore nonumy eirmod em ipsum dolor sit amet, cons…"
  //           },
  //           {
  //             _id: "62179401fcdabec32c245c43",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c44",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c45",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c46",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c47",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c48",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c49",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c50",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c41",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c52",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //         ],
  //         room: "628f5a88ba7ebf3accd84553",
  //         event_id: "628e4932c54d0cad187b5862",
  //         event_title: "World biggest conference for digital technologies Title",

  //         status: "finished",
  //         tags: [
  //           "session",
  //           "room",
  //           "azerty",
  //           "hello"
  //         ],
  //         duration: "1h30min",
  //         cover_url: "../../../../assets/portal/cover.png",
  //         video_url: "../../assets/v3.mp4"
  //       },
  //       {
  //         _id: "41216000cbe39a48cdaedc942",
  //         title: "Ux Design Introduction 2",
  //         starting_time: "11:30",
  //         ending_time: "12:00",
  //         speakers: [
  //           {
  //             _id: "6216000cbe39a48cdaedc942",
  //             name: "rania",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png",
  //             bio: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolor sit am dolore nonumy eirmod em ipsum dolor sit amet, cons…"
  //           },
  //           {
  //             _id: "621793f9fcdabec32c245c3f",
  //             name: "Dalanda Drissi",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png",
  //             bio: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolor sit am dolore nonumy eirmod em ipsum dolor sit amet, cons…"
  //           },
  //           {
  //             _id: "62179401fcdabec32c245c43",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c44",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c45",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c46",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c47",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c48",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c49",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c50",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c41",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //           ,
  //           {
  //             _id: "62179401fcdabec32c245c52",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //         ],
  //         room: "628f5a88ba7ebf3accd84553",
  //         event_id: "628e4932c54d0cad187b5862",
  //         event_title: "World biggest conference for digital technologies Title",

  //         status: "finished",
  //         tags: [
  //           "session",
  //           "room",
  //           "azerty",
  //           "hello"
  //         ],
  //         duration: "1h30min",
  //         cover_url: "../../../../assets/coverChannel3.jpg",
  //         video_url: "../../assets/v4.mp4"
  //       },
  //       {
  //         _id: "10216000cbe39a48cdaedc942",
  //         title: " last video in the playlist",
  //         starting_time: "11:30",
  //         ending_time: "12:00",
  //         speakers: [
  //           {
  //             _id: "6216000cbe39a48cdaedc942",
  //             name: "rania",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png",
  //             bio: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolor sit am dolore nonumy eirmod em ipsum dolor sit amet, cons…"
  //           },
  //           {
  //             _id: "621793f9fcdabec32c245c3f",
  //             name: "Dalanda Drissi",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png",
  //             bio: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolor sit am dolore nonumy eirmod em ipsum dolor sit amet, cons…"
  //           },
  //           {
  //             _id: "62179401fcdabec32c245c41",
  //             name: "Rakia",
  //             position: " My Position",
  //             linkedin: "dalanda-drissi",
  //             photo: "https://www.pngfind.com/pngs/m/114-1146554_girl-avatar-png-pic-female-avatar-icon-transparent.png"
  //           }
  //         ],
  //         room: "628f5a88ba7ebf3accd84507",
  //         event_id: "628e4932c54d0cad187b5862",
  //         event_title: "World biggest conference for digital technologies Title",

  //         status: "finished",
  //         tags: [
  //           "session",
  //           "room",
  //           "azerty",
  //           "hello"
  //         ],
  //         duration: "1h30min",
  //         cover_url: "../../../../assets/coverChannel4.jpg",
  //         video_url: "../../assets/v3.mp4"
  //       },
  //     ],
  //   }
  //   let session = lodash.find(channel.sessions, { '_id': id });
  //   if (session) {
  //     let data = {
  //       session: session,
  //       playlist: channel
  //     }

  //     this.onChangedSession.next(data)
  //     return (data)
  //   }
  // }
  getPlaylist() {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get(
          this.url + "/exploreevents/channel"
        )
        .subscribe((response: any) => {

          if (response && response.status == "success") {

            this.onChangedPlaylist.next(response.selected_channel)
            resolve(response.selected_channel);
          }
        }, reject);
    });
  }
  getSpeakers() {
   
    return new Promise<void>((resolve, reject) => {
      this.http
        .get(
          this.url + "/exploreevents/speakers"
        )
        .subscribe((response: any) => {
          if (response && response.status == "success") {
            this.onChangedSpeaker.next(response.speakers)
            resolve(response.speakers);
          }
        }, reject);
    });
  }
  getTestimonials() {
    
    return new Promise<void>((resolve, reject) => {
      this.http
        .get(
          this.url + "/exploreevents/testimonials"
        )
        .subscribe((response: any) => {
          if (response && response.status == "success") {
            this.onChangedTestimonials.next(response.testimonials)
            resolve(response.testimonials);
          }
        }, reject);
    });
  }
  testcookies() {
    // const httpOptions = {
    //   withCredentials: true,

    //   credentials: 'include'
    // };

    // var headers = new Headers();
    // headers.append('Content-Type', 'application/json');
    // headers.append('Accept', 'application/json');
    // return fetch( this.url +"/users/testcookies", {
    //   method: 'GET', 
    //   credentials: 'include', // Don't forget to specify this if you need cookies
    //   headers: headers 

    // })

    return this.http
      .get<any>(this.url + "/users/testcookies", { withCredentials: true })
      .toPromise();
  }
  editExploreVideoCover(coverexplore, exploreid) {
    if (coverexplore && this.cookieService.get('token')) {

      let decodedToken: any = jwt_decode(this.cookieService.get('token'));
      const userId = decodedToken.data._id;
      let header = { userid: userId, exploreid: exploreid }
      return new Promise((resolve, reject) => {

        var formData1 = new FormData();
        formData1.append("coverexplore", coverexplore);
        this.http
          .post(this.url + "/exploreevents/setcover", formData1, {
            headers: header,
            withCredentials: true
          })
          .subscribe((response: any) => {
            // this.getExplore();
            resolve(response);
          }, reject);
      });
    }

  }

  getEventDetailsFromInvitation() {
    if (this.data && this.data.title && this.data.title == 'confirm invitation') {
      return new Promise<void>((resolve, reject) => {
        if (this.params.eventid) {
          this.http
            .get(
              this.url + "/events/invitationdetails/" + this.params.eventid
            )
            .subscribe((response: any) => {
              if (response && response.status == "success") {
                this.onChangedEventById.next(response.event);
                resolve(response.event);
              }
              else {
                this.router.navigateByUrl("/")
              }
            }, reject);
        } else {
          resolve();
        }
      });
    }
  }
  checkinvitation(eventid, email, code) {
    if (this.data && this.data.title && this.data.title == 'confirm invitation') {
      return new Promise<void>((resolve, reject) => {
        if (this.params.eventid) {
          this.http
            .get(
              this.url + "/invitations/checkinvitation/" + email + "/" + eventid + "/" + code
            )
            .subscribe((response: any) => {              
              if (response ) {
 
                resolve(response);
              }
              else {
                this.router.navigateByUrl("/")
              }
            }, reject);
        } else {
          resolve();
        }
      });
    }
  }
  acceptInvitation(eventid, email, code) {
    if (this.data && this.data.title && this.data.title == 'confirm invitation') {
      return new Promise<void>((resolve, reject) => {
        if (this.params.eventid) {
          this.http
            .post(
              this.url + "/invitations/acceptinvitationaddattendee/" + eventid + "/" + code, { email: email }
            )
            .subscribe((response: any) => {
              if (response && response.status == "success") {

                resolve(response);
              }
              else {
                this.router.navigateByUrl("/")
              }
            }, reject);
        } else {
          resolve();
        }
      });
    }
  }
  declineInvitation(eventid, email, code) {
    if (this.data && this.data.title && this.data.title == 'confirm invitation') {
      return new Promise<void>((resolve, reject) => {
        if (this.params.eventid) {
          this.http
            .post(
              this.url + "/invitations/declineinvitationaddattendee/" + eventid + "/" + code, { email: email }
            )
            .subscribe((response: any) => {
              if (response && response.status == "success") {

                resolve(response);
              }
              else {
                this.router.navigateByUrl("/")
              }
            }, reject);
        } else {
          resolve();
        }
      });
    }
  }
  CheckCurrentUSer(email) {
    if (this.data && this.data.title && this.data.title == 'confirm invitation') {
      return new Promise<void>((resolve, reject) => {
        if (this.params.eventid) {
          this.http
            .get(
              this.url + "/users/checkcurrentuser/" + email, { withCredentials: true }
            )
            .subscribe((response: any) => {
              if (response) {
                resolve(response);
              }

            }, reject);
        } else {
          resolve();
        }
      });
    }
  }

  /**********************Dashboard*************************/



}

