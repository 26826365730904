import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { VideoPlayerComponent } from 'src/app/video-player/video-player.component';
import { PortalService } from '../portal.service';
const CountdownTimeUnits: Array<[string, number]> = [
  ['Y', 1000 * 60 * 60 * 24 * 365], // years
  ['Mo', 1000 * 60 * 60 * 24 * 30], // months
  ['D', 1000 * 60 * 60 * 24], // days
  ['H', 1000 * 60 * 60], // hours
  ['M', 1000 * 60], // minutes
  ['S', 1000], // seconds
  // ['S', 1], // million seconds
];
@Component({
  selector: 'app-event-session',
  templateUrl: './event-session.component.html',
  styleUrls: ['./event-session.component.css', '../portal-event/portal-event.component.css']
})

export class EventSessionComponent implements OnInit {

  @Input() edit_event
  @Input() event_title
  @Input() event_type
  @Input() event_short_summary
  @Input() event_start_date
  @Input() event_end_date
  @Input() event_utcoffset
  @Input() event_tags
  @Input() event_cover_url
  @Input() event_description

  event
  cover
  rightArrowValue: number;
  timerRooms
  overflowActivated: boolean = false
  sameday = false
  join: boolean = false
  haveAccess: boolean = false
  show: boolean = false
  countdown: boolean = false
  register: boolean = false
  showMoreInfo: boolean = false
  muted: boolean = false
  joinBtn = "watch"
  message = ""
  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  duration: number;
  moreThan24Hours: { leftTime: number; format: any; formatDate: ({ date, formatStr }: { date: any; formatStr: any; }) => any; };
  speakers
  accessEvent

  constructor(private portalservice: PortalService, public router: Router, public dialog: NbDialogService) { }

  ngOnInit(): void {
    this.portalservice.onChangedPublicEventById.subscribe((res) => {
      if (res) {
        if (res.type == "session") {
          this.event = res
          this.accessEvent = JSON.parse(this.event.access);
          if (!res.cover_url) {
            this.cover = "../../../assets/portal/cover.png"
          }
          else {
            this.cover = res.cover_url
          }
          this.portalservice.getMoreSpeakers("1").then((res1: any) => {
            this.speakers = res1.speakers;
          })
        }
        else {
          this.router.navigateByUrl("/").then();
        }
      }
    });
  }
  ngAfterViewInit() {
    let audio = document.getElementById('videoCover') as HTMLMediaElement

    if (audio) {
      audio.muted = false
      this.muted = false
    }
    if (audio) {
      audio.muted = true
      this.muted = true
    }
    if (document.getElementById("tags") && document.getElementById("event-tags-container")) {
      if (document.getElementById("tags").scrollWidth > document.getElementById("event-tags-container").offsetWidth) {
        this.overflowActivated = true
      }
    }
  }

  ngOnChanges(): void {

    if (this.event && this.edit_event && this.edit_event == "true") {
      if (this.event_title) { this.event.title = this.event_title }
      if (this.event_start_date) { this.event.start_date = this.event_start_date }
      if (this.event_end_date) { this.event.end_date = this.event_end_date }
      if (this.event_utcoffset) { this.event.utcoffset = this.event_utcoffset }
      if (this.event_tags) { this.event.tags = this.event_tags }
      if (this.event_cover_url) { this.cover = this.event_cover_url }
      if (this.event_description) { this.event.description = this.event_description }
      if (this.event_type) { this.event.type = this.event_type }
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (document.getElementById('event-infos') && document.getElementById('tags') && document.getElementById('tags').style && document.getElementById('event-infos').offsetWidth) {
      document.getElementById('tags').style.maxWidth = (document.getElementById('event-infos').offsetWidth).toString() + 'px'
    }
    if (document.getElementById("tags") && document.getElementById("event-tags-container")) {
      if (document.getElementById("tags").scrollWidth > document.getElementById("event-tags-container").offsetWidth) {
        this.overflowActivated = true
      }
      else {
        this.overflowActivated = false
        this.rightArrowValue = 0
      }
    }
  }
  scrollDivRooms(elementToScroll: HTMLElement, depl, div, arrowId) {
    if (this.event.tags && this.event.tags.length > 0) {
      if (document.getElementById(arrowId) != null) {
        elementToScroll = document.getElementById("tags") as HTMLInputElement
        elementToScroll.scrollLeft -= depl;
        this.rightArrowValue = elementToScroll.scrollLeft
        this.timerRooms = setTimeout(() => {
          this.scrollDivRooms(elementToScroll, depl, div, arrowId)
        }, 30);
      }
    }
  }
  stopTimerRooms(timerRooms: number) {
    clearTimeout(timerRooms);
  }
  convertDate(startdate, enddate, utcoffset) {
    if (!startdate && !enddate) {
    } else {
      let starttime = this.convertTimeToAM_PM(startdate.split('T')[1].slice(0, 5));
      let endtime = this.convertTimeToAM_PM(enddate.split('T')[1].slice(0, 5));
      startdate = startdate.split('T')[0].split("-");
      enddate = enddate.split('T')[0].split("-");
      let newdate = ["", "", ""];
      newdate[0] = this.months[startdate[1] - 1].slice(0, 3) + " " + startdate[2].slice(0, 2) + " " + starttime
      if (startdate[2].slice(0, 2) == enddate[2].slice(0, 2)) {
        this.sameday = true
        newdate[1] = this.months[enddate[1] - 1].slice(0, 3) + " " + enddate[2].slice(0, 2) + " " + endtime
      }
      else {
        this.sameday = false
        newdate[1] = this.months[enddate[1] - 1].slice(0, 3) + " " + enddate[2].slice(0, 2) + " " + endtime
      }
      newdate[2] = this.convertUtCoffset(utcoffset)
      return newdate;
    }
  }
  convertTimeToAM_PM(time) {
    if (time) {
      let hour = time.split(':')[0]
      if (hour == '00') {
        return ("12:" + time.split(':')[1] + "am")
      }
      else if (Number(hour) == 12) {
        return ("12:" + time.split(':')[1] + "pm")
      }
      else if (Number(hour) > 12) {
        return (Number(hour - 12) + ":" + time.split(':')[1] + "pm")
      }
      else if (hour < 12) {
        if (hour < 10) {
          return (hour[1] + ":" + time.split(':')[1] + "am")
        }
        else {
          return (time + "am")
        }
      }
    }
  }
  convertUtCoffset(utcoffset) {
    let convertedUTC = ["", "", "+"]
    if (utcoffset) {
      if (utcoffset.includes('-')) {
        convertedUTC[2] = ''
      }
      let utc = utcoffset.split('.')
      convertedUTC[0] = utc[0]
      if (!utc[1]) {
        convertedUTC[1] = "00"
      }
      else if (utc[1].length == 1 && utc[1] != '5') {
        convertedUTC[1] = utc[1] + "0"
      }
      else if (utc[1].length == 1 && utc[1] == '5') {
        convertedUTC[1] = "30"
      }
      else if (utc[1].length == 2) {
        convertedUTC[1] = utc[1]
      }
      if (!utc[1]) {
        return convertedUTC[2] + convertedUTC[0];
      }
      else {
        return convertedUTC[2] + convertedUTC[0] + ":" + convertedUTC[1]
      }
    }
  }
  /******************* Convert the format of the date (used in event details card)  ***********************/
  splitDate(startdate, enddate) {
    if (!startdate && !enddate) {
    } else {
      startdate = startdate.split("-");
      enddate = enddate.split("-");
      let newdate: string;
      if (startdate[2].slice(0, 2) == enddate[2].slice(0, 2)) {
        newdate = startdate[2].slice(0, 2) + "/" + startdate[1] + "/" + startdate[0];
      } else {
        newdate = startdate[2].slice(0, 2) + "/" + startdate[1] + "-" + enddate[2].slice(0, 2) + "/" + enddate[1];
      }
      return newdate;
    }
  }
  showTicket() {
  }
  onClickRegister() {

  }
  onClickJoinSession() {

  }
  countDown() {
    let startevent = new Date(this.event.start_date)
    let today = new Date();
    let timezone = this.event.utcoffset
    if (!timezone.includes('.')) {
      startevent.setHours(startevent.getHours() - Number(timezone));
    }
    else if (timezone.includes('.')) {
      startevent.setHours(startevent.getHours() - Number(timezone.split('.')[0]));
      if (Number(timezone.split('.')[1]) != 5) {
        startevent.setMinutes(startevent.getMinutes() - Number(timezone.split('.')[1]));
      }
      else if (Number(timezone.split('.')[1]) == 5) {
        startevent.setMinutes(startevent.getMinutes() - 30);
      }
    }
    let difftime = ((startevent.getTime() - (today.getTime() + (today.getTimezoneOffset() * 60))) / 1000)
    let formatDate;
    var d = Math.floor(difftime / (3600 * 24));
    if (d == 0) formatDate = "HHh:MMm:SSs"
    else formatDate = "Dd:HHh:MMm:SSs"
    this.moreThan24Hours = {
      leftTime: difftime,
      format: formatDate,
      formatDate: ({ date, formatStr }) => {

        this.duration = Number(date || 0);

        return CountdownTimeUnits.reduce((current, [name, unit]) => {
          if (current && current.indexOf(name) !== -1) {
            const v = Math.floor(this.duration / unit);
            this.duration -= v * unit;
            return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
              return v.toString().padStart(match.length, '0');
            });
          }
          if (this.event.status == "launched" && this.duration == 0) {
            this.event.status = "live"
            // if (this.cookieService.get("usertype") == "User") {
            //   this.verifyUserAccess();
            // }
            // else if (this.cookieService.get("usertype") == "temporaryUser") {
            //   this.verifyTemporaryUserAccess()
            // }
          }
          return current;
        }, formatStr);
      },
    };
  }
  showMore() {
    this.showMoreInfo = true
    let audio = document.getElementById('videoCover' ) as HTMLMediaElement
    if(audio)
    {
      this.muted = true
      audio.muted = true
      audio.pause()
    }

  }
  Reduce() {
    this.showMoreInfo = false
    let audio = document.getElementById('videoCover' ) as HTMLMediaElement
    if(audio)
    {
      this.muted = false

      audio.muted = false
      audio.play()
    }
  }
  openVideo() {
    if (this.accessEvent.access == "open")
      this.portalservice.getSesionsByChannelId().then((res: any) => {
        const dialogRef = this.dialog.open(VideoPlayerComponent, {
          context: {
            data: {
              sessions: [res.sessions],
              title: this.event.title,
              index: 0
            }
          },
          hasBackdrop: false
        }).onClose.subscribe((result) => { });
      })
  }
  soundOnOff(value) {
    let audio = document.getElementById('videoCover') as HTMLMediaElement
    if (value) {
      if (audio) { audio.muted = false }
      this.muted = false
    }
    else {
      if (audio) { audio.muted = true }
      this.muted = true
    }
  }
}
