import { Component, HostListener, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ExploreEventService } from 'src/app/explore-event/explore-event.service';
import { SwiperOptions } from 'swiper';
// import { RecommendedEventsComponent } from 'src/app/explore-event/recommended-events/recommended-events.component';
import { DashboardTestService } from '../dashboard-test.service';
import { EditExplorePopupComponent } from '../edit-explore-popup/edit-explore-popup.component';
import { ExploreService } from '../explore.service';
import * as lodash from 'lodash';
import { VideoPlayerComponent } from 'src/app/video-player/video-player.component';
@Component({
  selector: 'app-manage-explore',
  templateUrl: './manage-explore.component.html',
  styleUrls: ['./manage-explore.component.css']
})
export class ManageExploreComponent implements OnInit {

  // explore_id
  // videoSrc: any = "../../assets/card.mp4"
  // video
  // original_video = "../../assets/card.mp4"
  // uploaded: boolean = false
  // ListCategories
  // rightArrowCategorie;
  // timerCategories;
  // overflowActivatedCategorie: boolean = false
  // divTest: HTMLElement
  // config: SwiperOptions = {
  //   pagination: {
  //     el: '.swiper-pagination',
  //     clickable: true
  //   },
  //   navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev'
  //   },
  //   spaceBetween: 30,
  //   autoplay: {
  //     delay: 2500,
  //     disableOnInteraction: false
  //   },
  //   loop: true,
  //   // parallax : true,
  //   // speed : 600,
  // };
  // constructor(private exploreservice: ExploreService, public dialog: NbDialogService, public exploreService: ExploreEventService) { }
  // recommendedevents = []
  // upcomingevents = []
  // // channel
  // selectedchannel
  // selectedSpeakers
  // testimonials
  // selectedTestimonial
  constructor(){}
  ngOnInit(): void {
 

    // this.exploreservice.getExplore()
    // this.exploreservice.onChangedExplore.subscribe((res: any) => {
    //   if (res && res.status == "success") {
    //     this.explore_id = res.details._id
    //     this.video = res.details.explore_video_url
    //     this.original_video = res.details.explore_video_url
    //     this.ListCategories = res.details.categories
    //     this.recommendedevents = res.details.recommended_events
    //     this.upcomingevents = res.details.upcoming_events
    //     this.selectedchannel = res.details.selected_channel
    //     this.selectedSpeakers = res.details.speakers
    //     this.testimonials = res.details.testimonials
    //     if (this.testimonials && this.testimonials.length > 0) {
    //       this.selectedTestimonial = this.testimonials[0].speaker._id;
    //     }
    //   }
    // })
  }
  ngAfterViewInit() {
    // if (document.getElementById("categories") && document.getElementById("cover-content")) {
    //   if (document.getElementById("categories").scrollWidth > document.getElementById("cover-content").offsetWidth) {
    //     document.getElementById("categories").style.width = document.getElementById("cover-content").offsetWidth + "px"
    //     this.overflowActivatedCategorie = true
    //   }
    // }
  }
  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   if (document.getElementById("categories") && document.getElementById("cover-content")) {
  //     if (document.getElementById("categories").scrollWidth > document.getElementById("cover-content").offsetWidth) {
  //       this.overflowActivatedCategorie = true
  //     }
  //     else {
  //       this.overflowActivatedCategorie = false
  //       this.rightArrowCategorie = 0
  //     }
  //   }
  // }
  // scrollCategories(elementToScroll: HTMLElement, depl, div, arrowId) {
  //   if (this.ListCategories && this.ListCategories.length > 0) {
  //     if (document.getElementById(arrowId) != null) {
  //       elementToScroll = document.getElementById("categories") as HTMLInputElement
  //       elementToScroll.scrollLeft -= depl;
  //       this.rightArrowCategorie = elementToScroll.scrollLeft
  //       this.timerCategories = setTimeout(() => {
  //         this.scrollCategories(elementToScroll, depl, div, arrowId)
  //       }, 30);
  //     }
  //   }
  // }
  // stopTimerCategories(timerCategories: number) {
  //   clearTimeout(timerCategories);
  // }
  // errorMessage = { video: "" }
  // readURL(event): void {
  //   if (event.target.files && event.target.files[0]) {
  //     if (event.target.files[0] && !event.target.files[0].type.includes('video/mp4')) {
  //       this.errorMessage.video = "Invalid file type,only files with the following extensions are allowed:.mp4 "
  //     }
  //     else {
  //       this.uploaded = true
  //       this.errorMessage.video = "";
  //       this.videoSrc = ""
  //       const file = event.target.files[0];
  //       this.video = event.target.files[0];
  //       const reader = new FileReader();
  //       reader.onload = e => {
  //         this.videoSrc = reader.result;
  //       }
  //       reader.readAsDataURL(file);
  //     }
  //   }
  // }
  // cancelVideo() {
  //   this.uploaded = false
  //   this.errorMessage.video = "";
  //   this.videoSrc = ""
  //   this.video = this.original_video
  // }
  // saveVideo() {
  //   if (this.video && this.video != null && this.video != '') {

  //     this.exploreservice.editExploreVideoCover(this.video, this.explore_id).then((res: any) => {
  //       if (res && res.explore_video_url) {
  //         this.uploaded = false
  //         this.errorMessage.video = "";
  //         this.videoSrc = ""
  //         this.video = res.explore_video_url
  //         this.original_video = res.explore_video_url
  //       }
  //     })
  //   }
  // }
  // AddCategory() {
  //   this.exploreservice.getCategories().then((res: any) => {
  //     const dialogRef = this.dialog.open(EditExplorePopupComponent, {
  //       context:
  //       {
  //         data: {
  //           name: "addcategory",
  //           explore_id: this.explore_id,
  //           icons:res,
  //           categories: this.ListCategories
  //         },
  //       }, hasBackdrop: false
  //     });
  //     dialogRef.onClose.subscribe((result) => {
  //     });
  //   })
  // }
  // mouseDown = false;
  // startX: any;
  // scrollLeft: any;
  // dragging: boolean = false;
  // startDragging(e, flag, el) {
  //   this.mouseDown = true;
  //   this.startX = e.pageX - el.offsetLeft;
  //   this.scrollLeft = el.scrollLeft;
  // }
  // stopDragging(e, flag) {
  //   this.mouseDown = false;
  // }
  // moveEvent(e, el) {
  //   e.preventDefault();
  //   this.dragging = true
  //   if (!this.mouseDown) {
  //     this.dragging = false
  //     return;
  //   }
  //   const x = e.pageX - el.offsetLeft;
  //   const scroll = x - this.startX;
  //   el.scrollLeft = this.scrollLeft - scroll;
  // }
  // selectedVideo: any;
  // // playVideo(id, play) {
  // //   let div = lodash.find(this.video.toArray(), { 'nativeElement': document.getElementById(id) });
  // //   if (div) {
  // //     if (play) {
  // //       this.selectedVideo = id
  // //       div.nativeElement.play()
  // //       document.getElementById(id).classList.add("hover")
  // //       document.getElementById(id).classList.remove("notHover")
  // //       document.getElementById("whitePlay" + id).style.visibility = "hidden"
  // //       document.getElementById("whitePlay" + id).style.position = "absolute !important"
  // //       document.getElementById("redPlay" + id).style.visibility = "visible"
  // //       document.getElementById("redPlay" + id).style.position = "relative !important"
  // //     }
  // //     else {
  // //       this.selectedVideo = ""
  // //       div.nativeElement.pause()
  // //       document.getElementById(id).classList.remove("hover")
  // //       document.getElementById(id).classList.add("notHover")
  // //       document.getElementById("whitePlay" + id).style.visibility = "visible"
  // //       document.getElementById("whitePlay" + id).style.position = "relative !important"
  // //       document.getElementById("redPlay" + id).style.visibility = "hidden"
  // //       document.getElementById("redPlay" + id).style.position = "absollute !important"
  // //     }
  // //   }
  // //   else {
  // //     let divImage = document.getElementById("image"+id)
  // //     if (divImage) {
  // //       if (play) {
  // //         this.selectedVideo = id
  // //         divImage.classList.add("hover")
  // //         divImage.classList.remove("notHover")
  // //         document.getElementById("whitePlay" + id).style.visibility = "hidden"
  // //         document.getElementById("whitePlay" + id).style.position = "absolute !important"
  // //         document.getElementById("redPlay" + id).style.visibility = "visible"
  // //         document.getElementById("redPlay" + id).style.position = "relative !important"
  // //       }
  // //       else {
  // //         this.selectedVideo = ""
  // //         divImage.classList.remove("hover")
  // //         divImage.classList.add("notHover")
  // //         document.getElementById("whitePlay" + id).style.visibility = "visible"
  // //         document.getElementById("whitePlay" + id).style.position = "relative !important"
  // //         document.getElementById("redPlay" + id).style.visibility = "hidden"
  // //         document.getElementById("redPlay" + id).style.position = "absollute !important"
  // //       }
  // //     }
  // //   }
  // // }
  // openVideo(i) {
  //   const dialogRef = this.dialog.open(VideoPlayerComponent, {
  //     context: {
  //       data: {
  //         sessions: this.selectedchannel?.rooms?.sessions,
  //         index: i
  //       }
  //     },
  //     hasBackdrop: false
  //   }).onClose.subscribe((result) => { });
  // }
  // showMore() {
  //   // this.router.navigateByUrl("/more").then();
  // }
  // showLinkedIn(show, i) {
  //   if (document.getElementById(i)) {
  //     if (show) {
  //       document.getElementById(i).style.visibility = "visible"
  //     }
  //     else {
  //       document.getElementById(i).style.visibility = "hidden"
  //     }
  //   }
  // }
  // currentZindex: "4000";

  // openTestimonial(id, zIndex) {

  //   document.getElementById(this.selectedTestimonial).style.zIndex = this.currentZindex
  //   document.getElementById(id).style.zIndex = "4000 !important"
  //   document.getElementById(id).style.borderRadius = "none !important"
  //   this.selectedTestimonial = id
  //   this.currentZindex = zIndex

  // }






  // showArrows(show) {
  //   if (show) {
  //     document.getElementById("swiper-button-next").style.visibility = "visible"
  //     document.getElementById("swiper-button-prev").style.visibility = "visible"
  //   }
  //   else {
  //     document.getElementById("swiper-button-next").style.visibility = "hidden !important"
  //     document.getElementById("swiper-button-prev").style.visibility = "hidden !important"
  //   }
  // }
  // updateRecommendedEvents() {
  //   this.exploreservice.getLiveAndFinishedEvents().then((res: any) => {
  //     if (res && res.status == "success") {
  //       // this.events = res.events
  //       const dialogRef = this.dialog.open(EditExplorePopupComponent, {
  //         context:
  //         {
  //           data: {
  //             name: "updateRecommendedEvents",
  //             explore_id: this.explore_id,
  //             events: res.events,
  //             recommendedevents: this.recommendedevents
  //           },
  //         }, hasBackdrop: false
  //       });
  //       dialogRef.onClose.subscribe((result) => {
  //       });
  //     }
  //   })
  // }
  // updateUpcomingEvents() {
  //   this.exploreservice.getUpcomingEvents().then((res: any) => {
  //     if (res && res.status == "success") {
  //       // this.events = res.events
  //       const dialogRef = this.dialog.open(EditExplorePopupComponent, {
  //         context:
  //         {
  //           data: {
  //             name: "updateUpcomingEvents",
  //             explore_id: this.explore_id,
  //             events: res.events,
  //             upcomingevents: this.upcomingevents
  //           },
  //         }, hasBackdrop: false
  //       });
  //       dialogRef.onClose.subscribe((result) => {
  //       });
  //     }
  //   })
  // }
  // updateSelectedChannel() {
  //   this.exploreservice.getChannels().then((res: any) => {
  //     if (res && res.status == "success") {
  //       // this.events = res.channels
  //       const dialogRef = this.dialog.open(EditExplorePopupComponent, {
  //         context:
  //         {
  //           data: {
  //             name: "updateSelectedChannel",
  //             explore_id: this.explore_id,
  //             events: res.channels,
  //             selectedchannel: this.selectedchannel
  //           },
  //         }, hasBackdrop: false
  //       });
  //       dialogRef.onClose.subscribe((result) => {
  //       });
  //     }
  //   })
  // }
  // updateSpeakers() {
  //   this.exploreservice.getSpeakers().then((res: any) => {
  //     if (res && res.status == "success") {
  //       // this.events = res.channels
  //       const dialogRef = this.dialog.open(EditExplorePopupComponent, {
  //         context:
  //         {
  //           data: {
  //             name: "updateSpeakers",
  //             explore_id: this.explore_id,
  //             speakers: res.speakers,
  //             selectedspeakers: this.selectedSpeakers
  //           },
  //         }, hasBackdrop: false
  //       });
  //       dialogRef.onClose.subscribe((result) => {
  //       });
  //     }
  //   })
  // }
  // updateTestimonials() {



  //   this.exploreservice.getSpeakers().then((res: any) => {
  //     if (res && res.status == "success") {
  //       // this.events = res.channels
  //       const dialogRef = this.dialog.open(EditExplorePopupComponent, {
  //         context:
  //         {
  //           data: {
  //             name: "updateTestimonials",
  //             explore_id: this.explore_id,
  //             testimonials: this.testimonials,
  //             speakers: res.speakers,
  //           },
  //         }, hasBackdrop: false
  //       });
  //       dialogRef.onClose.subscribe((result) => {
  //       });
  //     }
  //   })



  // }
}
