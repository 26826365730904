<div class="Invitation">

    <div *ngIf="firststep && code=='INC'" class="invitation-card">
        <div style="    position: absolute;left: 50px;top: 10px;">
            <div style="font: normal normal normal 14px/50px Quicksand;letter-spacing: 0.7px;color: #8F9BB3;"> From
                <span><img src="../../assets/newLogo.png" style="width: 95px;height: 20px;" /></span>
            </div>
        </div>
        <div *ngIf="event" style="width: 100%;">
            <div class="Quicksand-bold bigTitle">INVITATION</div>
            <div style="margin:30px 0 20px;">
                <div *ngIf="event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]!='mp4'" class="event-cover ">
                    <div class="ar-16-9 " style="border-radius: 15px" [style.background-image]="'url('+event.cover_url+')'">
                    </div>
                </div>
                <div *ngIf="event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]=='mp4'" class="event-cover">
                    <video autoplay="autoplay" loop muted style="width: 100%;height: 15vw;width: 30vw; min-height: 210px;min-width: 350px; border-radius: 15px;">
                        <source [src]="event.cover_url" type="video/mp4;">
                    </video>
                </div>
            </div>
            <div class="EventDate">
                <div> From: <span [innerHTML]="start_date" style="font-weight: bold;"> </span>
                </div>
                <div>
                    To: <span style="font-weight:  bold;" [innerHTML]="end_date"> </span>
                </div>
                <div>
                    Start time: <span style="font-weight: bold;" [innerHTML]="start_time"> </span>
                </div>
            </div>
            <div>
                <div style="margin-bottom: 10px;">
                    Hey there, you have been invited to <span style="color: #8FA7E6;" class="Quicksand-bold">{{event.title}} </span> Event.
                </div>
                <div>
                    Click on the button below to accept the invitation or decline
                </div>
            </div>
            <div style="display: inline-flex;justify-content: space-around;align-items: center;margin-top: 20px;width: 100%;">
                <div class="Button" style="background: #EDF1F7 0% 0% no-repeat padding-box; border: 1px solid #707070; color: #0E1120;" (click)="acceptInvitation()">ACCEPT</div>
                <div class="Button" style="border: 1px solid #EDF1F7;color: #FFFFFF;" (click)="declineInvitation()">
                    DECLINE</div>

            </div>
            <div style="margin-top: 20px;">
                Once you accept this invitation it will be expired
            </div>
        </div>
    </div>
    <div *ngIf="code=='IC' && secondstep" class="invitation-card">
        <div style="    position: absolute;left: 50px;top: 10px;">
            <div style="font: normal normal normal 14px/50px Quicksand;letter-spacing: 0.7px;color: #8F9BB3;"> From
                <span><img src="../../assets/newLogo.png" style="width: 95px;height: 20px;" /></span>
            </div>
        </div>
        <img src="../../assets/confirm_invitation.png">
        <div style="font: normal normal normal 14px/18px Quicksand;margin: 30px 0 20px 0;">this invitation is already accepted</div>
        <div style="display: inline-flex;justify-content:center;align-items: center; width: 100%;">
            <div class="Button" style="background: #EDF1F7 0% 0% no-repeat padding-box; border: 1px solid #707070; color: #0E1120;width: 235px;" (click)="goToEvent()">TAKE ME TO EVENT</div>


        </div>
    </div>
    <div *ngIf="code=='IAC'&& secondstep" class="invitation-card">
        <div style="    position: absolute;left: 50px;top: 10px;">
            <div style="font: normal normal normal 14px/50px Quicksand;letter-spacing: 0.7px;color: #8F9BB3;"> From
                <span><img src="../../assets/newLogo.png" style="width: 95px;height: 20px;" /></span>
            </div>
        </div>
        <img src="../../assets/confirm_invitation.png">
        <div style="font: normal normal normal 14px/18px Quicksand;margin: 30px 0 20px 0;">this invitation is already accepted</div>
        <div style="display: inline-flex;justify-content:center;align-items: center; width: 100%;">
            <div class="Button" style="background: #EDF1F7 0% 0% no-repeat padding-box; border: 1px solid #707070; color: #0E1120; width: 235px;" (click)="goToEvent()">TAKE ME TO EVENT</div>


        </div>
    </div>
    <div *ngIf="code=='INF'&& secondstep" class="invitation-card">

        <div style="    position: absolute;left: 50px;top: 10px;">
            <div style="font: normal normal normal 14px/50px Quicksand;letter-spacing: 0.7px;color: #8F9BB3;"> From
                <span><img src="../../assets/newLogo.png" style="width: 95px;height: 20px;" /></span>
            </div>
        </div>
        <img src="../../assets/decline_invitaion.png" alt="">
        <div style="font: normal normal normal 14px/18px Quicksand;margin: 30px 0 20px 0;">Sorry, this invitation is expired!</div>

        <div class="Button" style="background: #EDF1F7 0% 0% no-repeat padding-box; border: 1px solid #707070; color: #0E1120;width: 235px;" (click)="goToExplore()">TAKE ME TO EXPLORE</div>


    </div>
    <div *ngIf="code=='IE'&& secondstep" class="invitation-card">
        <div style="    position: absolute;left: 50px;top: 10px;">
            <div style="font: normal normal normal 14px/50px Quicksand;letter-spacing: 0.7px;color: #8F9BB3;"> From
                <span><img src="../../assets/newLogo.png" style="width: 95px;height: 20px;" /></span>
            </div>
        </div>
        <img src="../../assets/decline_invitaion.png" alt="">
        <div style="font: normal normal normal 14px/18px Quicksand;margin: 30px 0 20px 0;">Sorry, this invitation is expired!
        </div>
        <div class="Button" style="background: #EDF1F7 0% 0% no-repeat padding-box; border: 1px solid #707070; color: #0E1120;width: 235px;" (click)="goToExplore()">TAKE ME TO EXPLORE</div>

    </div>
    <div *ngIf="code=='ID'&& secondstep" class="invitation-card">
        <div style="    position: absolute;left: 50px;top: 10px;">
            <div style="font: normal normal normal 14px/50px Quicksand;letter-spacing: 0.7px;color: #8F9BB3;"> From
                <span><img src="../../assets/newLogo.png" style="width: 95px;height: 20px;" /></span>
            </div>
        </div>
        <img src="../../assets/decline_invitaion.png" alt="">
        <div style="font: normal normal normal 14px/18px Quicksand;margin: 30px 0 20px 0;">No big deal, maybe another way
        </div>
        <div class="Button" style="background: #EDF1F7 0% 0% no-repeat padding-box; border: 1px solid #707070; color: #0E1120;width: 235px;" (click)="goToExplore()">TAKE ME TO EXPLORE</div>

    </div>

</div>



















<!-- <div style="overflow: auto;width: 100%;height: 100vh;display: flex;justify-content: center;text-align: center;">
    <div *ngIf="only_message_button" style="margin: auto;text-align: center;">
        <img *ngI="code=='IC' || code=='UAHA' || code=='IAC'" src="../../assets/success1.gif" style="width: 100px;" />
        <div>
            {{message}}
        </div>
        <div class="next-btn-container">
            <button type="submit"  class="action-btn subTitle Quicksand-bold" style="color :#141A2A;text-align:center" (click)="login()">LOG IN</button>
        </div>
    </div>
    <div *ngIf="!only_message_button" style="margin: auto;">
        <div class="title welcome-msg" style="width:  100%;"> Complete This From To Confirm Invitation</div>
        <div class="formulaire" style="width: 100%;min-width:300px;margin: auto;">
            <form class="form flex-column" [formGroup]="signupForm" (keydown.enter)="keyDownFunctionSignup($event)" style="text-align: left;">
                <div class="input-group">
                    <label for="email">Email</label>
                    <input type="text" class="input-form" [ngClass]="{Error : submittedSignup && signupformControls.email.errors }" name="email" id="email" placeholder="email" formControlName="email">
                </div>
                <div class="Name" style="margin-bottom: 0 !important;  width: 100%;">
                    <div class="input-group" style="margin-right: 30px;margin-bottom: 5%;">
                        <label for="firstname">First name</label>
                        <input class="input-form" [ngClass]="{Error : submittedSignup && signupformControls.firstname.errors }" type="text" id="firstname" placeholder="First name" name="firstname" formControlName="firstname">
                    </div>
                    <div class="input-group">
                        <label for="lastname">Last name</label>
                        <input class="input-form" [ngClass]="{Error : submittedSignup && signupformControls.lastname.errors }" type="text" id="lastname" placeholder="Last name" name="lastname" formControlName="lastname">
                    </div>
                </div>
                <div class="Name" style="margin-bottom: 0 !important;  width: 100%;">
                    <div class="input-group" style="margin-right: 30px;margin-bottom: 5%;">
                        <label for="password">Password</label>
                        <input class="input-form" [ngClass]="{Error : submittedSignup && signupformControls.pwd.errors}" type="password" name="pwd" type="password" formControlName="pwd" id="password" placeholder="password">
                    </div>
                    <div class="input-group">
                        <label for="confirmpassword">Confirm password</label>
                        <input class="input-form" [ngClass]="{Error : submittedSignup && signupformControls.confirmpassword.errors}" type="password" name="confirmpassword" type="password" formControlName="confirmpassword" id="confirmpassword" placeholder="Confirm password">
                    </div>
                </div>
            </form>
            <div style=" width: 100%;" [ngClass]="Signupmsg[0]=='error' ? 'ErrorMessage' : Signupmsg[0]=='success'? 'SuccessMessage' : Signupmsg[0]=='warn'? 'WarnMessage'  :''">
                {{Signupmsg[1]}}
            </div>
        </div>
        <div class="next-btn-container">
            <button type="submit"  class="action-btn subTitle Quicksand-bold" style="color :#141A2A;text-align:center" (click)="signUp()">CONTINUE</button>
        </div>
    </div>
</div> -->