import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfilesRoutingModule } from './profiles-routing.module';
import { SpeakerProfileComponent } from './speaker-profile/speaker-profile.component';
import { ProfilesComponent } from './profiles.component';
import { SharedModule } from '../shared/shared.module';
import { PlaylistPlayerComponent } from '../playlist-player/playlist-player.component';
import { AppModule } from '../app.module';
import { SharedPlaylistComponent } from '../shared/shared-playlist/shared-playlist.component';
import { SharedLayoutModule } from '../shared-layout/shared-layout.module';
import { PopupProfileComponent } from './popup-profile/popup-profile.component';
// import { SharedPlaylistComponent } from '../shared/shared-playlist/shared-playlist.component';


@NgModule({
  declarations: [
    SpeakerProfileComponent,
    ProfilesComponent,
    PlaylistPlayerComponent,
    PopupProfileComponent
  ],
  imports: [
    CommonModule,
    ProfilesRoutingModule,  
    SharedLayoutModule 
  ],
  entryComponents: 
  [
  ],
  exports:[
    SpeakerProfileComponent,
    ProfilesComponent,
    PlaylistPlayerComponent
  ]
})
export class ProfilesModule { }
