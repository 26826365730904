import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { DashboardTestService } from '../dashboard-test.service';
@Component({
  selector: 'app-send-invitation',
  templateUrl: './send-invitation.component.html',
  styleUrls: ['./send-invitation.component.css']
})
export class SendInvitationComponent implements OnInit {
  @Input( ) data;
  emails = []
  valid = []
  allocate_pass = false
  pass_type
  passes
  selected_pass
  available_pass = 0
  message = [false, ""]
  InviteForm:FormGroup
  constructor(private dashboardservice: DashboardTestService, public dialogRef: NbDialogRef<SendInvitationComponent>,private fb: FormBuilder) { }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (document.getElementById('data-popup') && document.getElementById('popup-container')) {
      let height_popupcontainer = document.getElementById('popup-container').offsetHeight;
        document.getElementById('data-popup').style.height=(height_popupcontainer-46).toString()+'px'; 
    }
  }
  ngOnInit(): void {
    if(this.data && this.data.name=="invite to event")
 {   this.dashboardservice.getPassesByEventid().then((res: any) => {
      if (res && res.status == "success") {
        this.pass_type = res.type
        this.passes = res.passes
       
        if (this.passes[0]) {
          if (this.passes[0].quantity - this.passes[0].purchased) {
            this.selected_pass = this.passes[0]._id;
            this.available_pass = this.passes[0].quantity - this.passes[0].purchased
          }
        }
        if (this.available_pass == 0) {
          this.message = [true, "Pass not availble!"]
        }
        else {
          this.message = [false, ""]
        }
      }
    })}
    else if(this.data && this.data.name=="invite to organisation"){
      this.InviteForm = this.fb.group({
        email: new FormControl("", [Validators.required])
      })
    }
  }
  ngAfterViewInit() {
    if (document.getElementById('data-popup') && document.getElementById('popup-container')) {
       let height_popupcontainer = document.getElementById('popup-container').offsetHeight;
      document.getElementById('data-popup').style.height=(height_popupcontainer-46).toString()+'px';
    }
  }
  addEmail(email) {
    this.emails.push(email);
    if (this.validateEmail(email)) {
      this.valid[this.emails.indexOf(email)] = true
    }
    else {
      this.valid[this.emails.indexOf(email)] = false
    }
    (document.getElementById('InputEmail') as HTMLInputElement).value = ''
  }
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  removeEmail(index) {
    this.emails.splice(index, 1);
    this.valid.splice(index, 1);
  }
  selectPass(pass_id, quantity, purchased) {
    if (document.getElementById('InputEmail')) {
      let value = (document.getElementById('InputEmail') as HTMLInputElement).value;
      if (value && value != '') {
        this.addEmail(value)
      }
    }
    this.selected_pass = pass_id
    this.available_pass = quantity - purchased
    if (this.allocate_pass && this.available_pass == 0) {
      this.message = [true, "Pass not availble!"]
    }
    else {
      this.message = [false, ""]
    }
  }
  submitted
  allocatePass() {
    let value = (document.getElementById('InputEmail') as HTMLInputElement).value;
    if (value && value != '') {
      this.addEmail(value)
    }
    this.allocate_pass = !this.allocate_pass
  }
  sendInvitations() {
    if (document.getElementById('InputEmail')) {
      let value = (document.getElementById('InputEmail') as HTMLInputElement).value;
      if (value && value != '') {
        this.addEmail(value)
      }
    }
    if((this.passes && this.passes.length==0) || !this.passes){
      this.allocate_pass=false
    }
    this.submitted = true;
    if (this.allocate_pass && (this.selected_pass == '' || !this.selected_pass) && this.passes && this.passes.length>0) {
      return;
    }
    else if (this.valid.includes(false)) {
      return;
    }
    else {
      if (!this.allocate_pass) {
        this.selected_pass = null
      }
      this.dashboardservice.sendInvitations({ emails: this.emails, pass: this.selected_pass }, this.data.eventid).then((res: any) => {
        // if (res.status == "success") {
        this.dialogRef.close();
        // }
      });
      this.submitted = false;
    }
  }
  get formControls() {
    return this.InviteForm.controls;
  }
  wrongemail=false
  inviteTeamMember(){
    this.submitted=true
    if(this.InviteForm.invalid){
      return
    }
    else if(!this.validateEmail(this.InviteForm.controls['email'].value) || this.InviteForm.controls['email'].value==''  ){
      this.wrongemail=true
      return
    }
    else{
      this.wrongemail=false
        this.dashboardservice.inviteTeamMemeber(this.InviteForm.value, this.data.organisation).then((res:any)=>{
        if(res && res.status=="success"){
          this.dialogRef.close()
        }
      })
    }
  }
  back() {
    this.dialogRef.close()
  }
}
