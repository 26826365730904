<div id="popup-container" style="max-height: 95.5vh;
overflow: hidden;
;padding: 30px;">
    <div class="top-bar" (click)="back()">
        <img src="../../assets/icons8-left-arrow-50.png" style="width: 25px; cursor: pointer;">
    </div>
    <div id="data-popup" style="max-height: 97%;overflow: auto;">
        <div *ngIf="(authPopup  || accessPopup || verifPopup) &&  !alertPopup " style=" margin: auto;display: flex;align-items: center;justify-content: center;width: 25vw;min-width: 400px;padding-top: 20px;">
            <app-authentification-system *ngIf="authPopup && !verifPopup && !accessPopup && !alertPopup && !reuseEmailPopup && !forgetPwdPopup" [data]="data" (OutPutDataShared)="changePopup($event)"></app-authentification-system>
            <app-access-system *ngIf="!authPopup && !verifPopup && accessPopup && !alertPopup && !reuseEmailPopup && !forgetPwdPopup" [data]="data" [accessData]="accessData" (OutPutDataShared)="changePopup($event)"></app-access-system>
            <app-verification-popup *ngIf="!authPopup && verifPopup && !accessPopup && !alertPopup && !reuseEmailPopup && !forgetPwdPopup" [data]="data" (OutPutDataShared)="changePopup($event)"></app-verification-popup>
        </div>
        <div *ngIf="!authPopup && !verifPopup && !accessPopup && !reuseEmailPopup && alertPopup && !forgetPwdPopup" style="height: 100%; display: flex; align-items: center; justify-content: center;margin: auto;width: 25vw">
            <app-alert-popup [data]="data" (OutPutDataShared)="changePopup($event)"></app-alert-popup>
        </div>
        <div *ngIf="!authPopup && !verifPopup && !accessPopup && !alertPopup && reuseEmailPopup && !forgetPwdPopup" style="height: 100%; display: flex; align-items: center; justify-content: center;margin: auto;width: 25vw">
            <app-reuse-temporaryuser [data]="data" (OutPutDataShared)="changePopup($event)"></app-reuse-temporaryuser>
        </div>
        <div *ngIf="!authPopup && !verifPopup && !accessPopup && !alertPopup && !reuseEmailPopup && forgetPwdPopup" style="display: flex; align-items: center; justify-content: center;margin: auto;width: 25vw">
            <app-forget-password [data]="data" (OutPutDataShared)="changePopup($event)"></app-forget-password>
        </div>
    </div>
</div>