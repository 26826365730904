<div *ngIf="launchedEvents && launchedEvents.length>0 ||  is_admin=='true'" class="upcoming-events-part">
    <div style="display: inline-flex;">
        <div class="padding-div"></div>
        <div style="display: inline-flex;justify-content:space-between;width:100%;align-items: center;">
            <div [ngClass]="sections_title_icon && sections_title_icon !='' ?'titles_dupiflix':'titles'"
                style="display:inline-flex;gap:20px;align-items: center;">
                <div style="display: inline-flex;align-items: center;">
                    <img *ngIf="sections_title_icon && sections_title_icon !=''" [src]="sections_title_icon" style="width: 20px;margin-right: 5px;
                height: 20px;" />

                    <div [ngClass]="!sections_title_icon || sections_title_icon =='' ?'highlighted-title':''"
                        class=" subTitle Quicksand-bold">

                        Upcoming </div>
                </div>
            </div>
            <div *ngIf="!is_admin || is_admin!='true'" class="show-all smallText Quicksand-semibold">Show All</div>
            <div *ngIf="is_admin && is_admin=='true'" class="show-all smallText Quicksand-semibold"><i
                    class="fa fa-pencil" style="color: white;cursor: pointer;" (click)="updateUpcomingEvents()"></i>
            </div>

        </div>

        <div class="padding-div"></div>
    </div>
    <div *ngIf="launchedEvents && launchedEvents.length>0 " class="upcoming-events-container">
        <div class="cover" style="width: 100% !important ;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-height: 60vh;
        /* min-height:345px*/
        min-height:270px">
            <swiper [config]="config" *ngIf="!loading" class="slider">
                <div class="swiper-wrapper" (mouseenter)="showArrows(true)" (mouseleave)="showArrows(true)">
                    <div class="swiper-slide" *ngFor="let event of launchedEvents;let i=index">
                        <div *ngIf="event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]!='mp4'"
                            [lazyLoad]="event.cover_url" (onStateChange)="onStateChange($event)" style="width: 100%;
                        height: 100%;
                        background-position: center;
                        background-size:cover;
                        background-repeat: no-repeat;
                        position: absolute;"></div>
                        <video #video
                            *ngIf="event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]=='mp4'"
                            [id]="'videoCover'+i" [muted]="'muted'" autoplay>
                            <source [src]="event.cover_url" type="video/mp4">
                        </video>
                        <div class="upcoming-events-content">
                            <div class="upcoming-events-paraghraphe">
                                <div class="event-title bigTitle Quicksand-semibold " style="cursor: pointer;"
                                    (click)="gotoEvent(event,event._id)">{{event.title}}
                                </div>
                                <div class="event-type paragraphe Quicksand-semibold ">{{event.type}}</div>
                                <div class="event-short-desc paragraphe Quicksand-regular">{{event.short_summary}}
                                </div>
                            </div>
                            <div class="upcoming-events-button">
                                <div class="launched-button subTitle Quicksand-bold"
                                    style="text-align: center;align-self: center;">
                                    <img class="icon-clock" src="../../../assets/portal/stopwatch_23f1-fe0f.png" />
                                    <!-- <countdown [config]="event.config" style="align-self: center;"></countdown> -->
                                    <div style="align-self: center;">6d 5h 20m</div>
                                </div>
                            </div>
                            <div *ngIf="!muted && event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]=='mp4' "
                                style="width: 35px;
                            height: 35px;
                            background-repeat: no-repeat;
                            align-self: flex-end;
                            margin-right: 0px;
                            cursor: pointer;
                            position: absolute;
                            bottom: 40px;
                            right: 45px;
                            z-index: 2000;
                            background: url(../../../assets/on_sound.png);" (click)="soundOnOff(false,i)"></div>
                            <div *ngIf="muted && event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]=='mp4'"
                                style="width: 35px;
                            height: 35px;
                            background-repeat: no-repeat;
                            align-self: flex-end;
                            cursor: pointer;
                            position: absolute;
                            bottom: 40px;
                            right: 45px;
                            z-index: 2000;
                            background: url(../../../assets/off_sound.png);" (click)="soundOnOff(true,i)"></div>
                        </div>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
                <div id="swiper-button-next" class="swiper-button-next"></div>
                <div id="swiper-button-prev" class="swiper-button-prev"></div>
            </swiper>
            <ngx-spinner style="width: 100% !important;height: 60vh;" [name]="'upcommingSpinner'" bdColor="#191c304f"
                size="default" color="#8fa7e6" type="ball-spin-clockwise" [fullScreen]="false">
            </ngx-spinner>
        </div>
    </div>
</div>