<div id="popup-container" style="height: 100%;padding: 30px;">
    <div class="top-bar" (click)="close()">
        <img src="../../assets/icons8-close-30.png" style="width: 25px; cursor: pointer;">
    </div>
    <!---------Category------------>
    <div *ngIf="data && data.name && data.name=='addcategory'">
        <div style="  margin: auto;
        display: flex;
        justify-content: center;
        margin: 10vh 20%;  flex-direction: column;
      ">
            <div style="text-align:center; font-weight: bold; color: white; " class="title">Add category</div>
            <div style="margin-top:5vh;">
                <!-- <div class="input-group">
                    <label for="name">Category name</label>
                    <input class="input-form" type="text" name="name" id="name" placeholder="name" [ngClass]="{Error: submitted && name_error}" [(ngModel)]="name">
                </div> -->
                <div>
                    Select catagory
                </div>
                <div class="CategoryList">
                    <div *ngFor="let icon of icons" class="Category" (click)="selectIcon(icon)" [ngStyle]="{'border':(selected_icon.includes(icon)) ? '1px solid white':'none'} ">
                        <img [src]="'https://dupiflix-assets-portal.s3.amazonaws.com/Dev/categories/'+icon+'.png'" class="IconsCategory" [ngStyle]="{'opacity':selected_icon && selected_icon.length>=6 && !selected_icon.includes(icon) ? '0.3':'1'} " />
                        <div style="text-transform:capitalize;">
                            {{icon | filter}}
                        </div>
                    </div>
                </div>
            </div>
            <div>N.B: You can select maximum 6 categories!</div>

            <div class="next-btn-container">
                <button type="submit"  class="action-btn subTitle Quicksand-bold" style="color :#141A2A;text-align:center" (click)="updateExplore()">Update</button>
            </div>
        </div>
    </div>
    <!---------Recommended------------>

    <div *ngIf="data && data.name && data.name=='updateRecommendedEvents'">
        <div style="  margin: auto;
        display: flex;
        justify-content: center;
        margin: 10vh 10%;flex-direction: column;">
            <div style="text-align:center; font-weight: bold; color: white; " class="title">Select recommended events
            </div>
            <div style="margin-top:5vh;">
                <div class="recommended-events-select ">
                    <div *ngFor="let event of events" class="event-card">
                        <div *ngIf="event.cover_url" class="event-card-cover" [style.background-image]="'url('+event.cover_url+')'">
                            <div>
                                <nb-checkbox [checked]="selected_recommendedevents.includes(event._id)" style="cursor:pointer;" (checkedChange)="toggleRecommended($event,event)">
                                </nb-checkbox>
                            </div>
                        </div>
                        <div *ngIf="!event.cover_url" class="event-card-cover" [style.background-image]="'url(../../../assets/placeholder.png)'">
                            <nb-checkbox [checked]="selected_recommendedevents.includes(event._id)" style="cursor:pointer;" (checkedChange)="toggleRecommended($event,event)">
                            </nb-checkbox>
                        </div>
                        <div>
                            <div *ngIf="event.cover_url" class="event-card-cover-content" [style.background-image]="'url('+event.cover_url+')'">
                            </div>
                            <div *ngIf="!event.cover_url" class="event-card-cover-content" [style.background-image]="'url(../../../assets/placeholder.png)'">
                            </div>
                            <div class="event-card-content">
                                <div style="display:inline-flex;gap: 5px;">
                                    <div class="Quicksand-medium paragraphe" style="flex: none"> {{event.type}} : </div>
                                    <div class="Quicksand-bold smallText line-limit" style="cursor: pointer;flex: none;max-width: 230px;">{{event.title}}</div>
                                </div>
                                <div>
                                    <div *ngIf="event.status=='launched'" style="display: inline-flex;gap: 5px;">
                                        <div class="Quicksand-regular paragraphe" style="color: #8F9BB3;">Start date :
                                        </div>
                                        <div class="Quicksand-bold paragraphe" style="color:#8FA7E6;">02/11/2022</div>
                                    </div>
                                    <div *ngIf="event.status=='live'">
                                        <div class="Quicksand-regular paragraphe" style="color: #8F9BB3;">Finished in 6 days
                                        </div>
                                    </div>
                                    <div *ngIf="event.status=='finished'">
                                        <div class="Quicksand-regular paragraphe" style="color: #8F9BB3;">Finished</div>
                                    </div>
                                </div>
                                <div *ngIf="event.pass" class="paragraphe Quicksand-light" style="color: #FAFAFA;">
                                    US$49.00 ~ US$149.00</div>
                                <div *ngIf="!event.pass" class="paragraphe Quicksand-light" style="color: #FAFAFA;">
                                    Free</div>
                                <div style="overflow: hidden;max-width: 100% !important;display: flex;justify-content: space-between;">
                                    <div style="display: flex;
                                    justify-content: flex-start!important;
                                    width: max-content;
                                    overflow: hidden;
                                    gap: 5px;">
                                        <div *ngFor="let tag of event.tags; let i= index" style="flex: none;">
                                            <div *ngIf="i<4" class="tag tagsText Quicksand-light" style="height: 25px;align-self: center;">
                                                <div>{{tag}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="buttons">
                                        <div class="icon-favori"></div>
                                        <div class="icon-share"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="next-btn-container">
                <button type="submit"  class="action-btn subTitle Quicksand-bold" style="color :#141A2A;text-align:center" (click)="updateExplore()">Update</button>
            </div>
        </div>
    </div>
    <!---------Upcoming------------>

    <div *ngIf="data && data.name && data.name=='updateUpcomingEvents'">
        <div style="  margin: auto;
        display: flex;
        justify-content: center;
        margin: 10vh 10%;flex-direction: column;">
            <div style="text-align:center; font-weight: bold; color: white; " class="title">Select upcoming events
            </div>
            <div style="margin-top:5vh;">
                <div class="recommended-events-select ">
                    <div *ngFor="let event of events" class="event-card">
                        <div *ngIf="event.cover_url" class="event-card-cover" [style.background-image]="'url('+event.cover_url+')'">
                            <div>
                                <nb-checkbox [checked]="selected_upcomingevents.includes(event._id)" style="cursor:pointer;" (checkedChange)="toggleUpcoming($event,event)">
                                </nb-checkbox>
                            </div>
                        </div>
                        <div *ngIf="!event.cover_url" class="event-card-cover" [style.background-image]="'url(../../../assets/placeholder.png)'">
                            <nb-checkbox [checked]="selected_upcomingevents.includes(event._id)" style="cursor:pointer;" (checkedChange)="toggleUpcoming($event,event)">
                            </nb-checkbox>
                        </div>
                        <div>

                            <div *ngIf="event.cover_url" class="event-card-cover-content" [style.background-image]="'url('+event.cover_url+')'">
                            </div>
                            <div *ngIf="!event.cover_url" class="event-card-cover-content" [style.background-image]="'url(../../../assets/placeholder.png)'">
                            </div>
                            <div class="event-card-content">
                                <div style="display:inline-flex;gap: 5px;">
                                    <div class="Quicksand-medium paragraphe" style="flex: none"> {{event.type}} : </div>
                                    <div class="Quicksand-bold smallText line-limit" style="cursor: pointer;flex: none;max-width: 230px;">{{event.title}}</div>
                                </div>
                                <div>
                                    <div *ngIf="event.status=='launched'" style="display: inline-flex;gap: 5px;">
                                        <div class="Quicksand-regular paragraphe" style="color: #8F9BB3;">Start date :
                                        </div>
                                        <div class="Quicksand-bold paragraphe" style="color:#8FA7E6;">02/11/2022</div>
                                    </div>
                                    <div *ngIf="event.status=='live'">
                                        <div class="Quicksand-regular paragraphe" style="color: #8F9BB3;">Finished in 6 days
                                        </div>
                                    </div>
                                    <div *ngIf="event.status=='finished'">
                                        <div class="Quicksand-regular paragraphe" style="color: #8F9BB3;">Finished</div>
                                    </div>
                                </div>
                                <div *ngIf="event.pass" class="paragraphe Quicksand-light" style="color: #FAFAFA;">
                                    US$49.00 ~ US$149.00</div>
                                <div *ngIf="!event.pass" class="paragraphe Quicksand-light" style="color: #FAFAFA;">
                                    Free</div>
                                <div style="overflow: hidden;max-width: 100% !important;display: flex;justify-content: space-between;">
                                    <div style="display: flex;
                                    justify-content: flex-start!important;
                                    width: max-content;
                                    overflow: hidden;
                                    gap: 5px;">
                                        <div *ngFor="let tag of event.tags; let i= index" style="flex: none;">
                                            <div *ngIf="i<4" class="tag tagsText Quicksand-light" style="height: 25px;align-self: center;">
                                                <div>{{tag}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="buttons">
                                        <div class="icon-favori"></div>
                                        <div class="icon-share"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="next-btn-container">
                <button type="submit"  class="action-btn subTitle Quicksand-bold" style="color :#141A2A;text-align:center" (click)="updateExplore()">Update</button>
            </div>
        </div>
    </div>
    <!---------Channel------------>

    <div *ngIf="data && data.name && data.name=='updateSelectedChannel'">
        <div style="  margin: auto;
        display: flex;
        justify-content: center;
        margin: 10vh 10%;flex-direction: column;">
            <div style="text-align:center; font-weight: bold; color: white; " class="title">Select upcoming events
            </div>
            <div style="margin-top:5vh;">
                <div class="recommended-events-select ">
                    <div *ngFor="let event of events" class="event-card">

                        <div *ngIf="event.cover_url" class="event-card-cover" [style.background-image]="'url('+event.cover_url+')'">
                            <div>
                                <nb-checkbox [checked]="selected_channel && selected_channel._id==event._id" style="cursor:pointer;" (checkedChange)="toggleChannel($event,event)">
                                </nb-checkbox>
                            </div>
                        </div>
                        <div *ngIf="!event.cover_url" class="event-card-cover" [style.background-image]="'url(../../../assets/placeholder.png)'">
                            <nb-checkbox [checked]="selected_channel && selected_channel._id==event._id" style="cursor:pointer;" (checkedChange)="toggleChannel($event,event)">
                            </nb-checkbox>
                        </div>
                        <div>
                            <div *ngIf="event.cover_url" class="event-card-cover-content" [style.background-image]="'url('+event.cover_url+')'">
                            </div>
                            <div *ngIf="!event.cover_url" class="event-card-cover-content" [style.background-image]="'url(../../../assets/placeholder.png)'">
                            </div>
                            <div class="event-card-content">
                                <div style="display:inline-flex;gap: 5px;">
                                    <div class="Quicksand-medium paragraphe" style="flex: none"> {{event.type}} : </div>
                                    <div class="Quicksand-bold smallText line-limit" style="cursor: pointer;flex: none;max-width: 230px;">{{event.title}}</div>
                                </div>
                                <div>
                                    <div *ngIf="event.status=='launched'" style="display: inline-flex;gap: 5px;">
                                        <div class="Quicksand-regular paragraphe" style="color: #8F9BB3;">Start date :
                                        </div>
                                        <div class="Quicksand-bold paragraphe" style="color:#8FA7E6;">02/11/2022</div>
                                    </div>
                                    <div *ngIf="event.status=='live'">
                                        <div class="Quicksand-regular paragraphe" style="color: #8F9BB3;">Finished in 6 days
                                        </div>
                                    </div>
                                    <div *ngIf="event.status=='finished'">
                                        <div class="Quicksand-regular paragraphe" style="color: #8F9BB3;">Finished</div>
                                    </div>
                                </div>
                                <div *ngIf="event.pass" class="paragraphe Quicksand-light" style="color: #FAFAFA;">
                                    US$49.00 ~ US$149.00</div>
                                <div *ngIf="!event.pass" class="paragraphe Quicksand-light" style="color: #FAFAFA;">
                                    Free</div>
                                <div style="overflow: hidden;max-width: 100% !important;display: flex;justify-content: space-between;">
                                    <div style="display: flex;
                                    justify-content: flex-start!important;
                                    width: max-content;
                                    overflow: hidden;
                                    gap: 5px;">
                                        <div *ngFor="let tag of event.tags; let i= index" style="flex: none;">
                                            <div *ngIf="i<4" class="tag tagsText Quicksand-light" style="height: 25px;align-self: center;">
                                                <div>{{tag}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="buttons">
                                        <div class="icon-favori"></div>
                                        <div class="icon-share"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div>N.B: You can select maximum 1 channel!</div>

            <div class="next-btn-container">
                <button type="submit"  class="action-btn subTitle Quicksand-bold" style="color :#141A2A;text-align:center" (click)="updateExplore()">Update</button>
            </div>
        </div>
    </div>


    <!---------Speakers------------>

    <div *ngIf="data && data.name && data.name=='updateSpeakers'">
        <div style="  margin: auto;
        display: flex;
        justify-content: center;
        margin: 10vh 10%;flex-direction: column;">
            <div style="text-align:center; font-weight: bold; color: white; " class="title">Select Speakers
            </div>
            <div style="margin-top:5vh;">
                <div class="recommended-events-select ">
                    <div *ngFor="let speaker of speakers ; let i=index" class="speaker-photo" [ngStyle]="{'background-image': speaker.additional_data && speaker.additional_data.photo ? 'url('+speaker?.additional_data?.photo+')':'url(../../../assets/placeholder.png)'} ">

                        <div>
                            <nb-checkbox [checked]="selected_speakers.includes(speaker._id)" [indeterminate]="!selected_speakers.includes(speaker._id) && selected_speakers.length>=3 " [disabled]="!selected_speakers.includes(speaker._id) && selected_speakers.length>=3 " style="cursor:pointer;"
                                (checkedChange)="toggleSpeaker($event,speaker)">
                            </nb-checkbox>
                        </div>
                        <div class="speaker-content">
                            <div style="display: inline-flex; justify-content: space-between;padding:0 20px 40px 20px">
                                <div style="display: flex;flex-direction: column;justify-content: center;" >
                                    <div class="smallText Quicksand-bold"> {{speaker.firstname}} {{speaker.lastname}}
                                    </div>
                                    <div class="paragraphe Quicksand-regular"> {{speaker?.additional_data?.title}}
                                    </div>
                                </div>
                                <div *ngIf="speaker.additional_data && speaker.additional_data.social_media && speaker.additional_data.social_media.linkedin">
                                    <a [href]="speaker.additional_data.social_media.linkedin" style="text-decoration: none; color: white;" target="_blank">
                                        <div class="icon-in">
                                        </div>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div>N.B: You can select maximum 3 speakers!</div>

            <div class="next-btn-container">
                <button type="submit"  class="action-btn subTitle Quicksand-bold" style="color :#141A2A;text-align:center" (click)="updateExplore()">Update</button>
            </div>
        </div>
    </div>

    <!---------Testimonials------------>

    <div *ngIf="data && data.name && data.name=='updateTestimonials'">
        <div style="  margin: auto;
            display: flex;
            justify-content: center;
            margin: 10vh 10%;flex-direction: column;">
            <div style="text-align:center; font-weight: bold; color: white; " class="title">Update Testimonials
            </div>
            <div style="margin-top: 4vh;">

                <div class="recommended-events-select " style="width: 100%;display: inline-flex;flex-wrap: wrap;justify-content: space-between;">
                    <div *ngFor="let i of counter(4) ; let index=index" style="width: 48%;border: 1px solid #3C465F;border-radius: 5px;height: 40%;padding: 10px; ">

                        <div style="margin-bottom:10px ;">

                            <div style="margin-bottom:5px ;">Speaker</div>
                            <div>
                                <div style="cursor: pointer;">
                                    <div [id]="'dropdown-speakers'+index" class="dropdown" (click)="showDropDown('filter-speakers'+index,'dropdown-speakers'+index)">
                                        <div class="dropdown-button">
                                            <div class="dropdown-button">
                                                <!-- this['testimonial' + index].testimonial -->
                                                <div *ngIf="this['testimonial' + index].speaker && this['testimonial' + index].speaker.firstname" class="tagsText Quicksand-regular" style="color:#8F9BB3;">
                                                    {{this['testimonial' + index].speaker.firstname}} {{this['testimonial' + index].speaker.lastname}}
                                                </div>
                                                <div *ngIf="!this['testimonial' + index].speaker || !this['testimonial' + index].speaker.firstname" class="tagsText Quicksand-regular" style="color:#8F9BB3;">
                                                    Select speaker
                                                </div>
                                                <div class="arrow-down"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div [id]="'filter-speakers'+index" class="dropdown-content">
                                        <div [id]="'speaker'+i" *ngFor="let speaker of speakers ; let i=index" class="tagsText dropdown-item-filter" (click)="selectOption('speaker',speaker,'filter-speakers'+index,'dropdown-speakers'+index,'speaker'+i,index)">
                                            {{speaker.firstname}} {{speaker.lastname}}

                                        </div>
                                    </div>
                                </div>



                                <!-- <input [(ngModel)]="this['testimonial' + index].testimonial" style="width: 95%; color: white;  padding: 2%;background: transparent;" /> -->

                            </div>
                        </div>
                        <div>
                            <div style="margin-bottom:5px ;">Testimonial</div>


                            <div>
                                <textarea [(ngModel)]="this['testimonial' + index].testimonial" placeholder="Enter the testimonial" style=" height: 10vh;resize: none; width: 100%; color: white;   padding: 5px;background: transparent;"></textarea>

                                <!-- <textarea [(ngModel)]="this['testimonial' + index].testimonial"></textarea> -->
                            </div>
                        </div>


                        <!-- <div>
                                <nb-checkbox [checked]="selected_speakers.includes(speaker._id)" [indeterminate]="!selected_speakers.includes(speaker._id) && selected_speakers.length>=3 " [disabled]="!selected_speakers.includes(speaker._id) && selected_speakers.length>=3 " style="cursor:pointer;"
                                    (checkedChange)="toggleSpeaker($event,speaker)">
                                </nb-checkbox>
                            </div>
                            <div class="speaker-content">
                                <div style="display: inline-flex; justify-content: space-between;padding:0 20px 40px 20px">
                                    <div style="display: flex;flex-direction: column;justify-content: center;">
                                        <div class="smallText Quicksand-bold"> {{speaker.firstname}} {{speaker.lastname}}</div>
                                        <div class="paragraphe Quicksand-regular"> {{speaker?.additional_data?.position}}
                                        </div>
                                    </div>
                                    <div *ngIf="speaker.additional_data && speaker.additional_data.social_media && speaker.additional_data.social_media.linkedin">
                                        <a [href]="speaker.additional_data.social_media.linkedin" style="text-decoration: none; color: white;" target="_blank">
                                            <div class="icon-in">
                                            </div>
                                        </a>
                                    </div>
                                </div>
    
                            </div> -->
                    </div>
                </div>

            </div>


            <div class="next-btn-container">
                <button type="submit"  class="action-btn subTitle Quicksand-bold" style="color :#141A2A;text-align:center" (click)="updateExplore()">Update</button>
            </div>
        </div>
    </div>

</div>