import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ExploreEventService } from '../explore-event.service';
import SwiperCore, { Parallax, Pagination, Navigation, SwiperOptions } from "swiper";
import { StateChange } from 'ng-lazyload-image';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExploreService } from 'src/app/dashboard-test/explore.service';
import { NbDialogService } from '@nebular/theme';
import { EditExplorePopupComponent } from 'src/app/dashboard-test/edit-explore-popup/edit-explore-popup.component';
import { DataService } from 'src/app/services/data.service';
import { config } from 'src/config/config';
SwiperCore.use([Parallax, Pagination, Navigation]);
const CountdownTimeUnits: Array<[string, number]> = [
  ['Y', 1000 * 60 * 60 * 24 * 365], // years
  ['Mo', 1000 * 60 * 60 * 24 * 30], // months
  ['D', 1000 * 60 * 60 * 24], // days
  ['H', 1000 * 60 * 60], // hours
  ['M', 1000 * 60], // minutes
  ['S', 1000], // seconds
  // ['S', 1], // million seconds
];
@Component({
  selector: 'app-upcomming-events',
  templateUrl: './upcomming-events.component.html',
  styleUrls: ['./upcomming-events.component.css'],
})
export class UpcommingEventsComponent implements OnInit {
  @ViewChild('upcomingEvents') upcomingEvents: ElementRef;
  
  @Output() activeBloc = new EventEmitter();
  @Input()is_admin

  imageStates: string[] = [];

  config: SwiperOptions = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 30,
    autoplay: {
      delay: 80000,
      disableOnInteraction: false
    },
    on: {
      touchEnd: function () {
        this.muted = true     
        let audio = document.getElementById('videoCover'+(this.activeIndex-1).toString()) as HTMLMediaElement
        let audio1 = document.getElementById('videoCover'+(this.activeIndex+1).toString()) as HTMLMediaElement
        if(audio)
        { audio.muted = true}
        if(audio1) 
         {audio1.muted = true}
      },
      slideChange: function () {
        this.muted = true      
        let audio = document.getElementById('videoCover'+(this.activeIndex-1).toString()) as HTMLMediaElement
        let audio1 = document.getElementById('videoCover'+(this.activeIndex+1).toString()) as HTMLMediaElement
if(audio)
       { audio.muted = true}
       if(audio1) 
        {audio1.muted = true}
      },
      click: function () {
        this.muted = true   
        let audio = document.getElementById('videoCover'+(this.activeIndex-1).toString()) as HTMLMediaElement
        let audio1 = document.getElementById('videoCover'+(this.activeIndex+1).toString()) as HTMLMediaElement
        if(audio)
        { audio.muted = true}
        if(audio1) 
         {audio1.muted = true}
      }
    }
    // loop: true,
    // parallax : true,
    // speed : 600,
  };
  muted: boolean = true
  events: any;
  launchedEvents: any = [];
  loading: boolean = true;
  index: any;
  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  difftimeinsec = 0;
  moreThan24Hours;
  duration = 0;
  sameday = false
  sections_title_icon=config.sections_title_icon

  constructor(public exploreService: ExploreEventService, public router: Router, private spinner: NgxSpinnerService,
    public exploredashboardService:ExploreService,public dialog: NbDialogService,
    private cd: ChangeDetectorRef,
    private shareddata : DataService) { }
  ngOnInit(): void {
 
    this.exploreService.onChangedUpcommingEvents.subscribe((res) => {
        this.launchedEvents = res
        // this.launchedEvents.forEach(event => {
          
        //   event.push({"config" : this.countDown("config"+event._id , event)})
        // });
        this.loading = false
    })
    this.activeBloc.emit("playlist")

  }
  ngAfterViewInit() {
    this.spinner.show("upcommingSpinner").then((res) => {
    });
  }
  showArrows(show) {
    if (show) {
      document.getElementById("swiper-button-next").style.visibility = "visible"
      document.getElementById("swiper-button-prev").style.visibility = "visible"
    }
    else {
      document.getElementById("swiper-button-next").style.visibility = "hidden !important"
      document.getElementById("swiper-button-prev").style.visibility = "hidden !important"
    }
  }
  onStateChange(state: StateChange) {

    this.imageStates.push(state.reason);
    if (state.reason == "finally") {
      this.spinner.hide("upcommingSpinner").then((res) => {
      });
    }
    this.cd.detectChanges();
  }
  /*******************Manage Explore*******************/
  updateUpcomingEvents() {
    this.exploredashboardService.getUpcomingEvents().then((res: any) => {
      if (res && res.status == "success") {
        const dialogRef = this.dialog.open(EditExplorePopupComponent, {
          context:
          {
            data: {
              name: "updateUpcomingEvents",
              explore_id: this.exploreService.explore_id,
              events: res.events,
              upcomingevents: this.launchedEvents
            },
          }, hasBackdrop: false
        });
        dialogRef.onClose.subscribe((result) => {
        });
      }
    })
  }
  gotoEvent(event,id)
  {
   
      if (event.type == "channel") {
        this.router.navigateByUrl('/portal/channelevent/' + id)
      }
      else if (event.type == "session") {
        this.router.navigateByUrl('/portal/sessionevent/' + id)
      }
      else { this.router.navigateByUrl('/portal/hybridevent/' + id) }
   
  
  }
  countDown(config,event) {
    let startevent = new Date(event.start_date)
    let today = new Date();
    let timezone = event.utcoffset
    if (!timezone.includes('.')) {
      startevent.setHours(startevent.getHours() - Number(timezone));
    }
    else if (timezone.includes('.')) {
      startevent.setHours(startevent.getHours() - Number(timezone.split('.')[0]));
      if (Number(timezone.split('.')[1]) != 5) {
        startevent.setMinutes(startevent.getMinutes() - Number(timezone.split('.')[1]));
      }
      else if (Number(timezone.split('.')[1]) == 5) {
        startevent.setMinutes(startevent.getMinutes() - 30);
      }
    }
    let difftime = ((startevent.getTime() - (today.getTime() + (today.getTimezoneOffset() * 60))) / 1000)
    let formatDate;
    var d = Math.floor(difftime / (3600 * 24));
    if (d == 0) formatDate = "HHh:MMm:SSs"
    else formatDate = "Dd:HHh:MMm:SSs"
    config = {
      leftTime: difftime,
      format: formatDate,
      formatDate: ({ date, formatStr }) => {
        this.duration = Number(date || 0);
        return CountdownTimeUnits.reduce((current, [name, unit]) => {
          if (current && current.indexOf(name) !== -1) {
            const v = Math.floor(this.duration / unit);
            this.duration -= v * unit;
            return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
              return v.toString().padStart(match.length, '0');
            });
          }
          if (event.status == "launched" && this.duration == 0) {
            this.shareddata.event.status = "live"
          }
          return current;
        }, formatStr);
      },
    };
  }
  soundOnOff(value,i) {
    let audio = document.getElementById('videoCover'+i) as HTMLMediaElement
    if (value) {
      if (audio) { audio.muted = false }
      this.muted = false
    }
    else {
      if (audio) { audio.muted = true }
      this.muted = true
    }
  }
}
