<div style="color:white;font-family: 'Quicksand';">
    <!-- <div style="font-size: 22px;margin-bottom: 10px;margin-left: 10px;">
        Sessions
    </div> -->
    <div class="title title3">Scheduled Sessions</div>

    <!-- <div style="width: 100%;text-align: right;">
        <div (click)="AddRoom()" style="border: 1px solid; width: 100px; text-align: center; padding: 5px 0; border-radius: 5px; cursor: pointer;">Add room</div>
    </div>
    <div class="Sessions-part" *ngIf="rooms && rooms.length>0">
        <div class="days-container">
            <div id="leftarrowDay" *ngIf="rightArrowValueDay >= 5 " class="arrowLeft" style="height: 60px" (mouseenter)="scrollDivRooms(days, 5,'days-container','leftarrowDay')" (mouseleave)="stopTimerRooms(timerRooms)"></div>
            <div id="days-container">
                <div id="Days" [ngClass]="days.length >6? 'days' :  'few-days' ">
                    <div *ngFor="let day of days; let i=index" [id]="i" class="day title1" (click)="getSessionByDay(i)">
                        <div style="min-width: 200px;">{{day}}</div>
                    </div>
                </div>
            </div>
            <div id="rightarrowDay" *ngIf="overflowActivateddays" class="arrowRight" style="height: 60px" (mouseenter)="scrollDivRooms(days, -5,'days-container','rightarrowDay')" (mouseleave)="stopTimerRooms(timerRooms)">
            </div>
        </div>
        <div id="Rooms-container" class="rooms-container">
            <div id="leftarrow" *ngIf="rightArrowValue >= 5 " class="arrowLeft" (mouseenter)="scrollDivRooms(rooms, 5,'Rooms','leftarrow')" (mouseleave)="stopTimerRooms(timerRooms)"></div>
            <div id="Rooms" style="flex: 80%;overflow-x: hidden;overflow-y: hidden;height: 50px;">
                <div class="rooms" [ngStyle]="{'width': rooms && rooms.length>1 ? '100%':'25%'} ">
                    <div *ngFor="let room of rooms; let i=index" [id]="'room'+i" (click)="onClickRoom(room._id)" class="title1" [ngClass]="selectedRoom._id == room._id ? 'selected-room' :  (selectedRoom._id != room._id && i>0) ? 'notselected-room-1' : 'notselected-room-2' "
                        [style.width.%]="rooms.length < 5 ? 100/rooms.length : 40" [ngStyle]="{'min-width.px': rooms.length > 5 ? 300 : null } ">
                        <div *ngIf="room.is_live  && selectedRoom._id == room._id " class="icon-live-black" style="align-self: center;">
                        </div>
                        <div *ngIf="room.is_live  &&  selectedRoom._id != room._id" class="icon-live-white" style="align-self: center;"></div>
                        <div style="align-self: center;">{{room.name}}</div>
                    </div>
                </div>
            </div>
            <div id="rightarrow" *ngIf="overflowActivatedRooms" class="arrowRight" (mouseenter)="scrollDivRooms(rooms, -5,'Rooms','rightarrow')" (mouseleave)="stopTimerRooms(timerRooms)">
            </div>
        </div>
        <div *ngIf="sessions && sessions.length>0" style="width:100%">
            <div *ngFor="let session of sessions;let i=index">
                <div class="sessions">
                    <div class="session-time sub-title">{{session.starting_time}}</div>
                    <div style="width: 100%;">
                        <div class="session-card" [ngStyle]="{'border-bottom': session.status == 'live'? '2px solid red':'none'} ">
                            <div class="session-header">
                                <div class="session-title ">
                                    <div style="font-family:Quicksand-semibold;margin-right: 15px;align-self: center;" class="title1"> {{session.title}}</div>
                                    <div *ngIf="session.status == 'live'" class="session-status">{{status}}</div>
                                </div>
                                <div *ngIf="session.status == 'live'" class="watch-btn" style="background-color: red;">
                                    Watch
                                </div>
                                <div *ngIf="session.status != 'live' && !activated " class="watch-btn" style="box-shadow: 0 0 10px #0202025e;color:rgb(0, 0, 0);background-color: rgb(255, 255, 255);" (click)="setReminder(session._id)">
                                    Set Reminder
                                </div>
                                <div *ngIf="activated && selected_session == session._id" class="watch-btn" style="box-shadow: none;
                                color: rgb(255 255 255);
                                background-color: rgb(255 255 255 / 0%);
                                border: 2px solid white !important;">
                                    Activated
                                </div>
                            </div>
                            <div class="line-limit sub-title" style="min-height: 70px;">
                                {{session.description}}
                            </div>
                            <div class="session-footer">
                                <div class="session-speakers" [style.left.px]=" -15*(session.speakers.length-1) ">
                                    <div *ngFor="let speaker of session.speakers;let i = index" [style.background-image]="'url('+speaker.photo+')'" class="speaker" [style.left.px]=" 15*(session.speakers.length-1-i) ">


                                    </div>
                                </div>
                                <div class="session-tags">
                                    <div *ngFor="let tag of session.tags" class="tag sub-title" style="height: 25px;align-self: center;font-family: Quicksand-light;font-size: 12px;">
                                        {{tag}}</div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="i<sessions.length-1 && sessions[i+1].starting_time != session.ending_time" style="width: 100%;
                height: 40px;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-family: Quicksand-medium;
                font-size: 15px;
                background-image: url(../../../../assets/Intersection.png);">Time Off</div>
                        <div *ngIf="i<sessions.length-1 && sessions[i+1].starting_time == session.ending_time" style="height: 20px"> </div>
                    </div>
                </div>
                <div *ngIf="i>=sessions.length-1" style="height:20px ;"></div>
            </div>

        </div>


    </div> -->





</div>