<div style="display: inline-flex;width: 100%; ">

    <nb-sidebar>
        <app-sidebar style="width: 20%; max-width: 200px;min-width: 150px;background-color: #1C1C1C ;"></app-sidebar>
    </nb-sidebar>


    <nb-layout-column>
        <div class="Default" style="width: 100%;padding:  20px 20px 0 20px; overflow: auto;">
            <router-outlet></router-outlet>
        </div>
    </nb-layout-column>

</div>