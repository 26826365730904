import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ExploreEventService } from '../explore-event/explore-event.service';
import { PortalService } from '../portal/portal.service';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-session-details',
  templateUrl: './session-details.component.html',
  styleUrls: ['./session-details.component.css']
})
export class SessionDetailsComponent implements OnInit {
  data : any = []
  speakers: any = []
  availableSessions: any = []
  more_sessions = { page: '1', limit: '7' };
  page: string;
  eventid: string;
  constructor(private exploreService: ExploreEventService, private portaleventservice: PortalService, public router: Router, private readonly location: Location, private shareddata: DataService) { }

  ngOnInit(): void {
    this.page = this.location.path().split("/")[this.location.path().split("/").length - 3];
    this.eventid = this.location.path().split("/")[this.location.path().split("/").length - 2];
    this.shareddata.eventid = this.eventid
    if (this.page == "available-session") {
      this.more_sessions.limit = "7"
      this.portaleventservice.getMoreFinishedSessions(this.more_sessions, "").then((res: any) => {
        if (res) {
          this.data = res.sessions
        }
      });
    }
    if (this.page == "session") {
      this.portaleventservice.getPlaylistBySelectedSession().then((res: any) => {        
        if (res) {
          this.data = res.sessions
        }
      });
    }
    else if (this.page == "channel") {
      this.exploreService.getPlaylist()
      this.exploreService.onChangedPlaylist.subscribe((res) => {
        if (res) {
          this.data = res.rooms[0].sessions
        }
      })
    }
    else if(this.page == "channelevent")
    {
      this.portaleventservice.getSessionsByEventId().then((res1: any) => {
        if (res1) {
          this.data = res1[0].sessions
        }
      })
    }

  }
  BackToExplore() {
    if (this.page == "available-session" ||  this.page == "session") {
      this.router.navigate(["/portal/hybridevent/" + this.eventid],
        {
          queryParams: { filter: 'sessions' },
          queryParamsHandling: 'merge'
        }).then(res => { })
    }
    else if (this.page == "channel") {
      this.router.navigateByUrl("/").then((res) => { });
    }
    else if (this.page == "channelevent")
    {
      this.router.navigateByUrl("/portal/channelevent/"+this.eventid).then((res) => { });
    }

  }

}
