import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth.guard';
import { DashboardEventComponent } from './dashboard-event.component';
import { DashboardEventModule } from './dashboard-event.module';
import { DashboardEventService } from './dashboard-event.service';

const routes: Routes = 
[
  {
    canActivate: [AuthGuard],

    path: "restricted/:eventid",
    component: DashboardEventComponent,
    resolve: { DashboardEventService },
    data: {
        title: "Event Interface",
        page:"restricted"
    },
  },
  {
    path: "open/:eventid",
    component: DashboardEventComponent,
    resolve: { DashboardEventService },
    data: {
        title: "Event Interface",
        page:"open"
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardEventRoutingModule { }
