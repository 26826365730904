<div style="color:white;font-family: 'Quicksand';">
    <div style="font-size: 22px;margin-bottom: 10px;margin-left: 10px;">
        Exhibitors <span (click)="addExhibitor()" style="cursor: pointer; border: 1px solid; padding: 0 8px;border-radius: 5px;margin-left: 10px;">+</span>
    </div>
    <!-- <div class="exibitors">
        <div class="exhibitor" *ngFor="let exhibitor of exhibitors;let i=index" [id]="'booth'+exhibitor._id" [style.background-image]="'url('+exhibitor.card_cover+')'" 
        [ngClass]="exhibitor.card_size == 'large' ? 'large' : 'default'" (mouseenter)=" showMoreDetails(true,exhibitor._id)"
            (mouseleave)=" showMoreDetails(false,exhibitor._id)">
            <div class="exhibitor-details" [id]="exhibitor._id">
                <div style="display: inline-flex; align-items: center;  justify-content: space-between;">
                    <div class="exhibitor-logo sub-title">
                        <img [src]="exhibitor.logo" class="img_logo">
                        <div *ngIf="show && exhibitor._id == showExhibitorName" class="exibitor-name sub-title" [id]="i">
                            {{exhibitor.name | titlecase}}
                        </div>
                    </div>
                    <div style="text-align: right;position: relative;top: -15px;">
                        <i class="fa fa-edit" style="cursor: pointer;" (click)="editBooth(exhibitor._id,exhibitor)"></i>
                        <i class="fa fa-trash" style="margin-left: 5px;cursor: pointer;" (click)="removeBooth(exhibitor._id )"></i>
                    </div>
                </div>
                <div *ngIf="show && exhibitor._id == showExhibitorName" class="exhibitor-desc  sub-title">
                    {{exhibitor.description | titlecase}}
                </div>
                <div *ngIf="exhibitor._id != showExhibitorName" class="exhibitor-desc line-limit  sub-title">
                    {{exhibitor.description | titlecase}}
                </div>
            </div>
        </div>
        <div class="exhibitor default">
            <div style="    height: 100%; display: flex; align-items: center;  width: 100%; justify-content: center;" (click)="addExhibitorInDiv(exhibitors.length)">
                +
            </div>
        </div>
    </div> -->

    <!-- [ngStyle]="{'width':exhibitor.card_size == 'large' ? '70%' :  '25%' } "-->
    <!-- <div #dropListContainer class="exhibitors" cdkDropListGroup>
        <div *ngFor="let item of exhibitors;let i=index" cdkDropList [cdkDropListData]="i" style=" margin: 10px; ">
          <div cdkDrag [cdkDragData]="i " (cdkDragEntered)="dragEntered($event) " [id]=" 'booth'+exhibitor._id "
                [style.background-image]=" 'url('+exhibitor.card_cover+ ')' " (cdkDragMoved)="dragMoved($event) "
                (mouseenter)=" showMoreDetails(true,exhibitor._id)
            " (mouseleave)=" showMoreDetails(false,exhibitor._id) " (cdkDragDropped)="dragDropped($event) "
                class="exhibitor ">
                {{exhibitor.name}}

               <div class="exhibitor-details" [id]="exhibitor._id">
                    <div class="exhibitor-logo sub-title">
                        <img [src]="exhibitor.logo" class="img_logo">
                        <div *ngIf="show && exhibitor._id == showExhibitorName" class="exibitor-name sub-title" [id]="i">
                            {{exhibitor.name | titlecase}}
                        </div>
                    </div>
                    <div *ngIf="show && exhibitor._id == showExhibitorName" class="exhibitor-desc  sub-title">
                        {{exhibitor.description | titlecase}}
                    </div>
                    <div *ngIf="exhibitor._id != showExhibitorName" class="exhibitor-desc line-limit  sub-title">
                        {{exhibitor.description | titlecase}}
                    </div>
                </div> 

            </div>

  
        </div>
    </div>
-->

    <div #dropListContainer class="example-container" cdkDropListGroup>
        <div *ngFor="let exhibitor of exhibitors; let i = index" class="Style" cdkDropList [cdkDropListData]="i" [ngClass]="exhibitor.card_size == 'large' ? 'large' : 'default'" style="margin: 1%;" (mouseenter)=" showMoreDetails(true,exhibitor._id)" (mouseleave)=" showMoreDetails(false,exhibitor._id)">
            <div cdkDrag [cdkDragData]="i" (cdkDragEntered)="dragEntered($event,exhibitor)" [style.background-image]="'url('+exhibitor.card_cover+')'" (cdkDragMoved)="dragMoved($event,exhibitor.card_size)" (cdkDragDropped)="dragDropped($event)" class="example-box">
                <div class="exhibitor-details" [id]="exhibitor._id">
                    <div style="display: inline-flex; align-items: center;  justify-content: space-between;">
                        <div class="exhibitor-logo sub-title">
                            <img [src]="exhibitor.logo" class="img_logo">
                            <div *ngIf="show && exhibitor._id == showExhibitorName" class="exibitor-name sub-title" [id]="i">
                                {{exhibitor.name | titlecase}}
                            </div>
                        </div>
                        <div style="text-align: right;position: relative;top: -15px;">
                            <i class="fa fa-edit" style="cursor: pointer;" (click)="editBooth(exhibitor._id,exhibitor)"></i>
                            <i class="fa fa-trash" style="margin-left: 5px;cursor: pointer;" (click)="removeBooth(exhibitor._id )"></i>
                        </div>
                    </div>
                    <div *ngIf="show && exhibitor._id == showExhibitorName" class="exhibitor-desc  sub-title">
                        {{exhibitor.description | titlecase}}
                    </div>
                    <div *ngIf="exhibitor._id != showExhibitorName" class="exhibitor-desc line-limit  sub-title">
                        {{exhibitor.description | titlecase}}
                    </div>
                </div>
            </div>
        </div>
    </div>




</div>