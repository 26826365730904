import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import jwt_decode from 'jwt-decode';
import * as CryptoJS from 'crypto-js';
import { PortalService } from 'src/app/portal/portal.service';
import { DataService } from 'src/app/services/data.service';
import * as lodash from 'lodash';
import { UserService } from 'src/app/services/user.service';
import { AuthentificationSystemService } from '../authentification-system.service';
import { NbDialogRef } from '@nebular/theme';
import { NbDialogService } from '@nebular/theme';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {
  @Output() OutPutData = new EventEmitter();
  @Input() data;
  loginPopup: boolean = true;
  signupPopup: boolean = false
  activate: boolean = true;
  verification: boolean = false;
  hide: boolean = false;
  haveAccount: boolean = false;
  submitted
  loginForm: FormGroup;
  DecodedToken;
  loginMessage = ["", ""]
  Event: any;
  dataVerif: any;
  dataAlert: any;
  showAlert_Popup: boolean = false;
  invited: boolean = false;
  dataAccess: any;
  showAccess_Popup: boolean = false;
  event_as_attendee;
  message;
  userPass: any;
  access: any;
  dataAuth;
  /****************************** Signup *************/
  errorPassword = "Please make sure your passwords match";
  errorMessage: boolean = false;
  verifyMessage = [false, ""]
  signupForm: FormGroup;
  token;
  Signupmsg = ["", ""]
  email = ""
  submittedSignup: boolean = false;
  submittedVerification: boolean = false;
  signuppage: boolean = true;
  wrongCode: boolean = false;
  role = ""
  firsturl = ""
  urlpicture: any;
  picturetoupload
  errormessage = "";
  verificationForm: FormGroup;
  resendVerifForm = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email])
  })
  event: any;
  wrong: boolean;
  checked = false;
  verifyAccount: boolean;


  /***********************************************Verification **************************/
  authData;
  resendVerifyMessage = [false, ""]
  resend: boolean = true;


  constructor(private authService: AuthentificationSystemService, public portalService: PortalService, public shareddata: DataService, private userService: UserService,
    private cookieService: CookieService, private router: Router, private formBuilder: FormBuilder) { }
  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl("", [Validators.email, Validators.required]),
      pwd: new FormControl("", [Validators.required]),
      remember_me: new FormControl(""),
    });
    this.signupForm = this.formBuilder.group({
      firstname: new FormControl("", [Validators.required]),
      lastname: new FormControl("", [Validators.required]),
      username: new FormControl("", []),
      email: new FormControl("", [Validators.required, Validators.email]),
      pwd: new FormControl("", [Validators.required]),
      confirmpassword: new FormControl("", [Validators.required]),
    });
    this.verificationForm = this.formBuilder.group({
      nb1: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb2: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb3: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb4: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb5: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb6: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
    });
    this.Event = this.shareddata.event;
  }
  @HostListener('keyup', ['$event']) onKeyDown(e: any) {
    e.preventDefault();
    if (this.formControls.email.errors || this.formControls.pwd.errors || this.loginMessage[0] == 'error') {
      this.loginMessage = ["", ""]
    }
    if (this.wrong || this.Signupmsg[0] == 'error') {
      this.Signupmsg = ["", ""]
      this.wrong = false
    }
    if (this.wrongCode) {
      this.wrongCode = false
      this.verifyMessage = [false, ""]
    }
    if (this.resendVerifyMessage[0] == true) {
      this.resendVerifyMessage = [false, ""]
    }
    if (e.key == 'Delete' || e.keyCode == 8) {
      let prevControl: any = e.srcElement.previousElementSibling;
      while (true) {
        if (prevControl) {
          if (prevControl.type == e.srcElement.type) {
            prevControl.focus();
            prevControl.select();
            return;
          }
        }
        else {
          return;
        }
      }
    }
    else {
      if (e.srcElement.maxLength == e.srcElement.value.length) {
        let nextControl: any = e.srcElement.nextElementSibling;
        while (true) {
          if (nextControl) {
            if (nextControl.type == e.srcElement.type) {
              nextControl.focus();
              nextControl.select();
              return;
            }
            else {
              nextControl = nextControl.nextElementSibling;
            }
          }
          else {
            return;
          }
        }
      }
    }
  }
  get formControls() {
    return this.loginForm.controls;
  }
  get signupformControls() {
    return this.signupForm.controls;
  }
  get verificationformControls() {
    return this.verificationForm.controls;
  }
  signUp() {
    this.submittedSignup = true;
    if (this.signupForm.invalid) return;
    else if (this.signupForm.controls["pwd"].value != this.signupForm.controls["confirmpassword"].value) {
      this.signupForm.controls['confirmpassword'].setErrors({ [this.errorPassword]: true })
      this.signupForm.controls['pwd'].setErrors({ [this.errorPassword]: true })
      return;
    }
    else {
      let finalemail = this.signupForm.controls['email'].value
      finalemail = finalemail.trim()
      finalemail = finalemail.replace(/\r?\n|\r/g, "")
      this.signupForm.controls['email'].setValue(finalemail)
      var encryptedpwd = CryptoJS.AES.encrypt(this.signupForm.controls['pwd'].value, 'ourkey').toString();
      this.authService.registerAttendee({ firstname: this.signupForm.controls['firstname'].value, lastname: this.signupForm.controls['lastname'].value, email: this.signupForm.controls['email'].value, pwd: encryptedpwd }).subscribe((res: any) => {

        if (res.status == "error") {
          this.wrong = true
          this.Signupmsg = ['error', res['message']];
        }
        else {
          this.DecodedToken = jwt_decode(res['accessToken']);
          this.userService.onChangedUser.next(this.DecodedToken.data)
          this.signupForm.reset()
          this.signuppage = false;
          this.Signupmsg = ["success", "Successfully registered, confirm your email and get back to this page to login!"];
          this.submittedSignup = false;
          this.loginPopup = false
          this.signupPopup = false
          this.verifyAccount = true
        }
      });
    }
    this.submittedSignup = false;
  }

  keyDownFunctionSignup(e) {
    this.signUp()
  }
  keyDownFunctionVerifyCode(event) {
    // this.verifyCode()
  }

  toggle(checked: boolean) {
    this.checked = checked;
  }

  login() {
    this.submitted = true
    let finalemail = this.loginForm.controls['email'].value
    finalemail = finalemail.trim()
    finalemail = finalemail.replace(/\r?\n|\r/g, "")
    this.loginForm.controls['email'].setValue(finalemail)
    if (this.loginForm.invalid) {
      return
    }
    else {
      var encryptedpwd = CryptoJS.AES.encrypt(this.loginForm.controls['pwd'].value, 'ourkey').toString();
      this.authService.login({ email: this.loginForm.controls['email'].value, pwd: encryptedpwd }).then(data => {
        if (data['accessToken']) {
          this.DecodedToken = jwt_decode(data['accessToken']);
          this.userService.onChangedUser.next(this.DecodedToken.data)
          this.shareddata.onChangePath(true)
          this.router.navigateByUrl("/exploreevents")
        }
        else {
          this.loginMessage = ['error', data['message']]
        }
      })
    }
  }
  signup() {
    this.loginPopup = false
    this.signupPopup = true
  }
  backToLogin() {
    this.loginPopup = true
    this.signupPopup = false
  }

  forgotPassword() {
    this.loginMessage = ["", ""]
    this.dataAuth =
    {
      forgetPwdPopup: true
    }
    this.OutPutData.emit(this.dataAuth);
  }
  keyDownFunctionLogin(e) {
    this.login();
    this.submitted = true
  }
  connectWithSocialmedia(socialmedia) {
    //   window.open("this.url/users/" + socialmedia, "_self")
    //   if (this.data && this.data.id) {
    //     // sessionStorage.setItem('go_to_event', this.data.id)
    //   }
    //   if (this.data && this.data.ticketid) {
    //     // sessionStorage.setItem('ticketid', this.data.ticketid)
    //   }
  }



  /*********************************Verification ********************/
  verifyCode() {

    this.submittedVerification = true
    if (this.verificationForm.invalid) {
      return
    }
    let DecodedToken;
    DecodedToken = jwt_decode(this.cookieService.get("token"));
    let current_email = DecodedToken.data.email
    let code = this.verificationForm.controls["nb1"].value + this.verificationForm.controls["nb2"].value + this.verificationForm.controls["nb3"].value + this.verificationForm.controls["nb4"].value + this.verificationForm.controls["nb5"].value + this.verificationForm.controls["nb6"].value
    this.authService.VerifyCode(current_email, code).subscribe((res: any) => {
      if (res) {
        if (res.status == "error") {
          this.wrongCode = true
          this.verifyMessage = [true, res.message]
        }
        else {
          this.shareddata.onChangePath(true)
          this.router.navigateByUrl("/exploreevents")
          this.shareddata.verifiedStatus(true);
        }
      }
    })
  }
  skip() {
    this.router.navigateByUrl("/exploreevents")
    this.shareddata.onChangePath(true)
  }
  resendverification() {
    if (this.authService.currentUser && this.authService.currentUser._id) {
      this.authService.resendverification(this.authService.currentUser.email).subscribe((res) => {
        this.resendVerifyMessage = [true, "the verification message has been sent to you ..."]
        this.resend = false
        setTimeout(() => {
          this.resend = true
          this.resendVerifyMessage = [false, ""]
        }, 20000);
      })
    }
  }
}
