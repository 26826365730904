import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpParams } from "@angular/common/http";
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';
import jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { DataService } from '../services/data.service';

@Injectable({
  providedIn: 'root'
})
export class PortalService implements Resolve<any> {
  onChangedPublicEventById: BehaviorSubject<any>;
  onChangeEventSpeakers: BehaviorSubject<any>;
  onChangeEventExhibitors: BehaviorSubject<any>;
  onChangeEventSessions: BehaviorSubject<any>;
  onChangeEventTickets: BehaviorSubject<any>;
  onChangedtagsSpeakerById: BehaviorSubject<any>;
  onChangedRooms: BehaviorSubject<any>;
  onChangedRoleEventById
  hashedCode: BehaviorSubject<any>;

  data;
  params;
  queryparams;
  url;
  more_speakers = { page: '1', limit: '7' };
  more_testimonials = { page: '1', limit: '4' };
  more_sessions = { page: '1', limit: '4' };
  more_sessions_channel = { page: '1', limit: '8' };

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private cookieService: CookieService, private shareddata: DataService) {

    this.onChangedPublicEventById = new BehaviorSubject([]);
    this.onChangedtagsSpeakerById = new BehaviorSubject([]);
    this.onChangeEventSpeakers = new BehaviorSubject([]);
    this.onChangeEventExhibitors = new BehaviorSubject([]);
    this.onChangeEventSessions = new BehaviorSubject([]);
    this.onChangeEventTickets = new BehaviorSubject([]);
    this.onChangedRoleEventById = new BehaviorSubject([]);
    this.onChangedRooms  = new BehaviorSubject([]);
    this.route.queryParams.subscribe((queryParams) => {
      this.queryparams = queryParams
    });

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    this.url = environment.url
    return new Promise<void>((resolve, reject) => {      
      this.params = route.params;
      this.data = route.data;
      Promise.all([
        this.getEventPublicById(),
        // this.getSpeakersAndTagsOfSessionsByEventid()
      ]).then(
        () => {
          resolve();
        },
        (reject) => {
        }
      );
    });
  }
  getAllEvents() {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get(
          this.url + "/events/all/public"
        )
        .subscribe((response: any) => {

          resolve(response);
        }, reject);
    });
  }
  getEventPublicById() {    
    if (this.data && this.data.title &&(this.data.title=='Event page' ||  this.data.title == 'Portal Event' || this.data.title == 'Session Event' || this.data.title == 'Channel Event' ) ) {
      return new Promise<void>((resolve, reject) => {
        if (this.params.eventid) {
          this.http
            .get(
              this.url + "/events/public/" + this.params.eventid
            )
            .subscribe((response: any) => {

              if (response && response.status == "success") {

                this.onChangedPublicEventById.next(response.event);

                resolve(response);
              }
              else {
                this.router.navigateByUrl("/")
              }

            }, reject);
        } else {
          resolve();
        }
      });
    }
  }
  getRoleByEventid() {

    if (this.data && this.data.title && this.data.title == 'Portal Event') {

      return new Promise<void>((resolve, reject) => {
        let userId
        if (this.cookieService.get('token')) {
          const decodedToken: any = jwt_decode(this.cookieService.get('token'));
          userId = decodedToken.data._id;
        }
        let headers;
        if (userId && userId != "") {
          headers = { userid: userId }
        }
        if (this.params.eventid) {
          this.http
            .get(
              this.url + "/events/role/" + this.params.eventid, { headers: headers, withCredentials: true }
            )
            .subscribe((response: any) => {
              this.onChangedRoleEventById.next(response);
              resolve(response);
            }, reject);
        }
      });
    }
  }

  /********************************************** Join event ***************************************/

  verifyToJoinDashboardEvent(code, eventid, eventstatus, userid) {

    // if (this.data && this.data.title && this.data.title == 'Event Interface') {
    return new Promise<void>((resolve, reject) => {
      let userId = "";
      if (this.cookieService.get('token')) {
        const decodedToken: any = jwt_decode(this.cookieService.get('token'));
        userId = decodedToken.data._id;
      }

      if (this.cookieService.get("usertype") == "temporaryUser" && eventstatus != "email") {
        userId = ""
      }

      var status
      if (eventstatus == 'open' || eventstatus == 'protected_password') {
        status = 'open'

      }
      else {
        status = 'restricted'
      }
      let headers;
      if (userId && userId != "" && code && code != "") {
        headers = { code: code, userid: userId }
      }
      else if (code && code != "" && userId == "") {
        headers = { code: code }
      }
      else if (userId && userId != "" && (code == "" || !code)) {

        headers = { userid: userId }
      }

      if (eventid) {

        this.http
          .get(this.url + "/events/join/" + status + "/" + eventid, { headers: headers, withCredentials: true })
          .subscribe((response: any) => {
            if (response.status == 'error') {
              this.router.navigateByUrl("/portal/hybridevent/" + eventid)
            }
            resolve(response);

          }, reject);
      } else {
        resolve();
      }
    });
    // }
  }
  /********************************************** access code  ***************************************/
  verifyAccessCode(code, ticketid, voucher, eventid, status, userid) {

    let data;
    if (code) {
      code = CryptoJS.AES.encrypt(code, 'OurAccessKey').toString();
      this.hashedCode = code
      data = { code: code }
    }
    if (voucher) {
      voucher = CryptoJS.AES.encrypt(voucher, 'OurAccessKey').toString();
      this.hashedCode = voucher
      data = { voucher: voucher }
    }
    if (ticketid) data = { ticketid: ticketid }
    if (eventid) {
      if (status) {
        // if (this.cookieService.get("usertype") == "User") {
        return this.http.post(this.url + "/users/access/" + userid, data, { withCredentials: true });
        // }
        // else if (this.cookieService.get("usertype") == "temporaryUser") {
        //   return this.http.post(this.url + "/temporary-users/access/" + userid, data);
        // }
      }
    }
  }
  verifyExistanceOfCode(code, voucher, eventid) {
    let data;
    if (code) {
      code = CryptoJS.AES.encrypt(code, 'OurAccessKey').toString();
      data = { code: code }
    }
    if (voucher) {
      voucher = CryptoJS.AES.encrypt(voucher, 'OurAccessKey').toString();
      data = { voucher: voucher }
    }
    return new Promise((resolve, reject) => {
      if (eventid != "") {
        this.http
          .get(this.url + "/passes/verif/" + eventid, { headers: data })
          .subscribe((response: any) => {
            resolve(response);
          }, reject);
      }
    });
  }
  /********************************************** tickets ***************************************/
  getTicketsByEventId(eventid) {
    return new Promise((resolve, reject) => {

      if (eventid) {
        this.http
          .get(this.url + "/passes/" + this.params.eventid)
          .subscribe((response: any) => {

            if (response && response.status == "success") {
              this.onChangeEventTickets.next(response);
              resolve(response);
            }

          }, reject);
      }
    });
  }
  /********************************************** Invitation ***************************************/
  verifyInvitation(email, eventid) {
    if (email != "" && eventid != "") {
      return new Promise((resolve, reject) => {
        this.http
          .get(this.url + "/Invitations/verify/" + email + "/" + eventid, { withCredentials: true })
          .subscribe((response: any) => {
            resolve(response);
          }, reject);
      });
    }
  }
  /********************************************** Exhibitors requests  ***************************************/
  getExhibitorsByEventId() {
    return new Promise((resolve, reject) => {

      if (this.queryparams.filter == "exhibitors") {
        this.http
          .get(this.url + "/booths/public/" + this.params.eventid)
          .subscribe((response: any) => {
            if (response && response.status == "success") {
              resolve(response.booths);
            }

          }, reject);
      }
    });
  }
  /**********************************************  Sessions requests  ***************************************/
  getSessionsByEventId() {
    return new Promise((resolve, reject) => {
       let eventid
       if(this.params && this.params.eventid != null)
       {
        eventid = this.params.eventid
       }
       else if(this.shareddata.eventid != "")
       {
        eventid = this.shareddata.eventid
       }
      if (this.queryparams.filter == "sessions" || (this.data && this.data.title && this.data.title == 'Channel Event')) {
        this.http
          .get(this.url + "/rooms/public/" + eventid)
          .subscribe((response: any) => {
            if (response && response.status == "success") {
              this.onChangedRooms.next(response.rooms)
              resolve(response.rooms);
            }
          }, reject);
      }
    });
  }
  getMoreSesionsByChannelId(more_sessions_channel)
  {
    this.more_sessions_channel= more_sessions_channel;
    return this.getSesionsByChannelId();
  }
  getSesionsByChannelId() {
    this.url = environment.url
        return new Promise((resolve, reject) => {
      let eventid      
      if (this.params && this.params.eventid != null) {        
        eventid = this.params.eventid
      }
      else if (this.shareddata.eventid!="" && this.shareddata.eventid!=null) {
        eventid = this.shareddata.eventid
      }      
      let params = new HttpParams();
      params = params.append('page', this.more_sessions_channel.page.toString());
      params.set('page', this.more_sessions_channel.page);
      params = params.append('limit', this.more_sessions_channel.limit.toString());
      params.set('limit', this.more_sessions_channel.limit);
      if (eventid != "") {
        this.http
        .get(this.url + "/sessions/byevent/" + eventid, { params: params })
        .subscribe((response: any) => {     
               
          if (response && response.status == "success") {
            resolve(response);
          }
        }, reject);
      }
    });
  }
  getFinishedSessions(option) {
    this.url = environment.url
        return new Promise((resolve, reject) => {
      let eventid      
      if (this.params && this.params.eventid != null) {        
        eventid = this.params.eventid
      }
      else if (this.shareddata.eventid!="" && this.shareddata.eventid!=null) {
        eventid = this.shareddata.eventid
      }      
      let params = new HttpParams();
      params = params.append('page', this.more_sessions.page.toString());
      params.set('page', this.more_sessions.page);
      params = params.append('limit', this.more_sessions.limit.toString());
      params.set('limit', this.more_sessions.limit);
      if (option && option != "" && option.length != 0 ) {
        let tag: any = []
        let speaker: any = []
        let room: any = []
        option.forEach(opt => {
          if (opt.name == "tag") {
            tag.push(opt.value)
          }
          else if (opt.name == "speaker") {
            speaker.push(opt.value._id)
          }
          else if (opt.name == "room") {
            room.push(opt.value._id)
          }
        });
        if (tag.length > 0) {
          params = params.append('tag', tag);
          params.set('tag', tag);
        }
        if (speaker.length > 0) {
          params = params.append('speaker', speaker);
          params.set('speaker', speaker);
        }
        if (room.length > 0) {
          params = params.append('room', room);
          params.set('room', room);
        }
      }
      if (eventid != "") {
        this.http
        .get(this.url + "/sessions/finished/" + eventid, { params: params })
        .subscribe((response: any) => {          
          // if (response && response.status == "success") {
            resolve(response);
          // }
        }, reject);
      }
    });
  }
  getMoreFinishedSessions(more_sessions, option) {
    this.more_sessions= more_sessions;
    return this.getFinishedSessions(option);
  }
  getPlaylistBySelectedSession()
  {
    this.url = environment.url
    return new Promise((resolve, reject) => {
      let eventid = ""     
      if (this.params && this.params.eventid != null) {        
        eventid = this.params.eventid
      }
      else if (this.shareddata.eventid!="" && this.shareddata.eventid!=null) {
        eventid = this.shareddata.eventid
      } 
      if (eventid !="") {
        this.http
          .get(this.url + "/rooms/public/" + eventid)
          .subscribe((response: any) => {
            if (response && response.status == "success") {
              this.onChangedRooms.next(response.rooms)
              resolve(response.rooms[0]);
            }
          }, reject);
      }
    });
  }
  getLink()
  {
    return("../../assets/v4.mp4")
  }
  /********************************  Speakers *************************************/
  getMoreSpeakers(page) {
    this.more_speakers.page = page;
    return this.getSpeakersByEventId();
  }
  getSpeakersByEventId() {
    return new Promise((resolve, reject) => {      
      if (this.queryparams.filter == "speakers" || (this.data && this.data.title && (this.data.title == 'Session Event' || this.data.title == 'Channel Event') ) ) {
        let params = new HttpParams();
        params = params.append('page', this.more_speakers.page.toString());
        params.set('page', this.more_speakers.page);
        params = params.append('limit', this.more_speakers.limit.toString());
        params.set('limit', this.more_speakers.limit);
        this.http
          .get(this.url + "/events/public/" + this.params.eventid+"/speakers", { params: params })
          .subscribe((response: any) => {
            if (response && response.status == "success") {
              this.onChangeEventSpeakers.next(response);
              resolve(response);
            }

          }, reject);
      }
    });
  }

  /******************************** Testimonials **********************************/
  getTestimonialsByEventId() {
    return new Promise((resolve, reject) => {
      let params = new HttpParams();
      params = params.append('page', this.more_testimonials.page.toString());
      params.set('page', this.more_testimonials.page);
      params = params.append('limit', this.more_testimonials.limit.toString());
      params.set('limit', this.more_testimonials.limit);
      this.http
        .get(this.url + "/events/public/" + this.params.eventid + "/testimonials", { params: params })
        .subscribe((response: any) => {

          if (response && response.status == "success") {
            resolve(response);
          }

        }, reject);
    });
  }
  getMoreTestimonials(page) {
    this.more_testimonials.page = page;
    return this.getTestimonialsByEventId();
  }

 

  /************************************ filter session************************************************** */
  getSpeakersAndTagsOfSessionsByEventid() {
    return new Promise((resolve, reject) => {
      // if (this.queryparams.filter == "sessions") {
      this.http
        .get(this.url + "/sessions/speakers-tags/" + this.params.eventid)
        .subscribe((response: any) => {
          if (response && response.status == "success") {
            this.onChangedtagsSpeakerById.next(response)
            resolve(response);
          }
        }, reject);
      // }




    });
  }
}



