import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { DefaultUrlSerializer, RouterModule, UrlSerializer } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PortalModule } from './portal/portal.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { DashboardEventModule } from './dashboard-event/dashboard-event.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { APP_BASE_HREF, CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AuthInterceptor } from './auth.interceptor';
import { CountdownModule } from 'ngx-countdown';
import { NavbarComponent } from './default-layout/navbar/navbar.component';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { AccessSystemComponent } from './access-system/access-system.component';
import { AlertPopupComponent } from './access-system/alert-popup/alert-popup.component';
import { AuthentificationSystemComponent } from './authentification-system/authentification-system.component';
import { LoginPopupComponent } from './authentification-system/login-popup/login-popup.component';
import { SignupPopupComponent } from './authentification-system/signup-popup/signup-popup.component';
import { TicketsComponent } from './access-system/tickets/tickets.component';
import { VerificationPopupComponent } from './authentification-system/verification-popup/verification-popup.component';
import { NgSelectModule } from '@ng-select/ng-select';

import { NbLayoutModule, NbDialogModule, NbCheckboxModule, NbFormFieldModule, NbSidebarModule, NbTooltipModule, NbPopoverModule, NbSelectModule, NbToastrModule } from '@nebular/theme';
import { SidebarComponent } from './default-layout/sidebar/sidebar.component';
import { NbThemeModule, NbIconModule, NbInputModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { LoaderComponent } from './loader/loader.component';
import { LoaderInterceptorService } from './services/loader-interceptor.service';
import { SharedComponentJoinEventComponent } from './shared-component-join-event/shared-component-join-event.component';
import { ExploreEventModule } from './explore-event/explore-event.module';
import { NgxUsefulSwiperModule } from "ngx-useful-swiper";
import { SwiperModule } from 'ngx-swiper-wrapper';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { NgxSpinnerModule } from 'ngx-spinner';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { CookieService } from 'ngx-cookie-service';
import { ReuseTemporaryuserComponent } from './authentification-system/reuse-temporaryuser/reuse-temporaryuser.component';
import { ForgetPasswordComponent } from './authentification-system/forget-password/forget-password.component';
import { ResetPasswordComponent } from './authentification-system/reset-password/reset-password.component';
import { LoginPageComponent } from './authentification-system/login-page/login-page.component';
import { InfoToasterComponent } from './tosters/info-toaster/info-toaster.component';
import { WarningToasterComponent } from './tosters/warning-toaster/warning-toaster.component';
import { ConfirmInvitationComponent } from './confirm-invitation/confirm-invitation.component';
import { PlaylistPlayerComponent } from './playlist-player/playlist-player.component';
import { SessionDetailsComponent } from './session-details/session-details.component';
import { ToastrComponent } from './toastr/toastr.component';
import { DashboardTestModule } from './dashboard-test/dashboard-test.module';
import { ProfilesModule } from './profiles/profiles.module';
   @NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    DefaultLayoutComponent,
    SidebarComponent,
    AccessSystemComponent,
    AlertPopupComponent,
    AuthentificationSystemComponent,
    LoginPopupComponent,
    SignupPopupComponent,
    TicketsComponent,
    VerificationPopupComponent,
    LoaderComponent,
    SharedComponentJoinEventComponent,
    VideoPlayerComponent,
    ReuseTemporaryuserComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    LoginPageComponent,
    // InfoToasterComponent,
    WarningToasterComponent,
    ConfirmInvitationComponent,
    // PlaylistPlayerComponent,
    SessionDetailsComponent,
    ToastrComponent 
     // VideoCardComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    AppRoutingModule,
    RouterModule,
    PortalModule,
    ExploreEventModule,
    DashboardEventModule,
    ProfilesModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule ,
    ReactiveFormsModule,
    CountdownModule,
    NgSelectModule,
    NbThemeModule.forRoot({ name: 'dark'}),
    NbDialogModule.forRoot(),
    NbToastrModule.forRoot(),
    NbLayoutModule,
    NbCheckboxModule,
    NbDialogModule,
    NbIconModule,
    NbInputModule,
    NbIconModule,
    NbEvaIconsModule,
    NbFormFieldModule,
    NbTooltipModule,
    NbPopoverModule,
    NbSelectModule,
    NbSidebarModule.forRoot(),
    NgxUsefulSwiperModule,
    SwiperModule,
    NgxSpinnerModule,
    DashboardTestModule,
  ],
  providers: [ 
 
  { 
    provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,useClass: LoaderInterceptorService,multi: true
  },
  { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks   },
  { provide: APP_BASE_HREF, useValue: '/' },
  CookieService
],
  bootstrap: [AppComponent],
  entryComponents: 
  [
    AccessSystemComponent,
    LoginPopupComponent,
    SignupPopupComponent,
    ReuseTemporaryuserComponent,
    ForgetPasswordComponent,
    // PlaylistPlayerComponent
    // InfoToasterComponent
  ]
  ,
  exports : [
    // PlaylistPlayerComponent,
  ]
})
export class AppModule { }
