import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExploreEventComponent } from './explore-event.component';
import { ExploreEventService } from './explore-event.service';
import { ExploreService } from '../dashboard-test/explore.service';

const routes: Routes = [
  {
    // canActivate: [AuthGuard],
    path: "",
    component: ExploreEventComponent,
    resolve: { ExploreEventService  },
  },
];
// ,ExploreService
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExploreEventRoutingModule { }
