<div style="height: 100%;" id="popup-container">
    <div class="top-bar" (click)="back()">
        <img src="../../assets/icons8-close-30.png" style="width: 25px; cursor: pointer;">
    </div>
    <div id="data-popup" style="height: 100%;overflow: auto;">
        <div *ngIf="data" class="popup-container" style="align-self: center;">
            <div style="width: 50%;min-width: 400px;">
                <div *ngIf="data.name=='invite to event'">
                    <div style="font-size: 22px;text-align: center; font-weight: bold;">Send Invitations</div>
                    <div>
                        <div>Emails</div>
                        <div style="display: inline-flex; border: 1px solid; width: 100%;height: 150px;flex-wrap: wrap; align-items: flex-start;border-radius: 5px; margin-top: 10px;padding: 5px;" [ngStyle]="{'border-color':submitted && ( valid.includes(false) || emails.length==0) ? 'red ' : '' }">
                            <div *ngFor="let email of emails;let index=index">
                                <div [ngStyle]="{'background':valid[index] ? '#192038':'#C46363'} " style="   padding: 5px;  border-radius: 10px; margin-right: 5px;">{{email}}
                                    <span style=" cursor: pointer;   margin-left: 5px;" (click)="removeEmail(index)"><i
                                            class="fa fa-times-circle " aria-hidden="true"></i>
                                    </span>
                                </div>
                            </div>
                            <input type="text" placeholder="Add email" id="InputEmail" style="width:100%;  padding: 5px; outline: none;  background: transparent; border: none;color: white;" (keydown.enter)="addEmail($event.target.value)">
                        </div>
                        <div *ngIf="data && data.event_access=='auth'">
                            <div style="margin: 5px 0;">
                                <input type="checkbox" (change)="allocatePass()" [value]="allocate_pass" id="allocate_pass" name="allocate_pass" />
                                <label class="LabelCheckbox" style="margin-left: 10px; cursor: pointer;" [ngStyle]="{'color':allocate_pass ? 'white':'#212529'} " for="allocate_pass">
                                    Do you want to allocate pass ? </label>
                            </div>
                            <div *ngIf="allocate_pass">
                                <div *ngIf="passes && passes.length>0">


                                    <div style="display: inline-flex; flex-wrap: wrap;">
                                        <div *ngFor="let pass of passes;let index=index">
                                            <div *ngIf="pass.purchased<pass.quantity" (click)="selectPass(pass._id,pass.quantity,pass.purchased)" [ngStyle]="{'box-shadow':selected_pass==pass._id ? '0px 0px 5px 3px white':'none'} " style="margin: 10px; cursor: pointer; border: 1px solid #ccc; width: 150px; border-radius: 5px; display: flex;  flex-direction: column;"
                                                [style.border-color]="pass.color">
                                                <div [style.background-color]="pass.color" style="display: inline-flex; width: 100%; align-items: center;padding:3px 0;justify-content: space-between;">
                                                    <div>Ticket {{index+1}} </div>
                                                </div>
                                                <div style=" display: flex;padding: 10px; flex-direction: column;">
                                                    <div style="display: inline-flex;">
                                                        <div style="margin-right: 5px;">Name:</div>
                                                        <div>{{pass.name}}</div>
                                                    </div>
                                                    <div style="display: inline-flex;">
                                                        <div style="margin-right: 5px;">Available:</div>
                                                        <div *ngIf="pass.purchased>=0">{{pass.quantity-pass.purchased}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="pass.purchased==pass.quantity" style="margin: 10px;opacity: 0.2;cursor: default; border: 1px solid #ccc; width: 150px; border-radius: 5px; display: flex;  flex-direction: column;" [style.border-color]="'gray'">
                                                <div [style.background-color]="'gray'" style="display: inline-flex;width: 100%; align-items: center;padding:3px 0; justify-content: space-between;">
                                                    <div>Ticket {{index+1}} </div>
                                                </div>
                                                <div style=" display: flex;padding: 10px; flex-direction: column;">
                                                    <div style="display: inline-flex;">
                                                        <div style="margin-right: 5px;">Name:</div>
                                                        <div>{{pass.name}}</div>
                                                    </div>
                                                    <div style="display: inline-flex;">
                                                        <div style="margin-right: 5px;">Available:</div>
                                                        <div *ngIf="pass.purchased>=0">{{pass.quantity-pass.purchased}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="color: red;text-align: center;" *ngIf="message[0]"> {{message[1]}}</div>
                                </div>
                                <div *ngIf="( passes && passes.length==0 )|| !passes" style="color: red;text-align: center;"> There is no passes!</div>
                            </div>
                        </div>
                        <div style="width: 100% ;margin-top: 20px;">
                            <div (click)="sendInvitations()" style=" margin: auto;   width: 100px;
        border: 1px solid;cursor: pointer;
        border-radius: 5px;    padding: 5px;
        text-align: center;">
                                Send
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="data.name=='invite to organisation'">
                    <div style="font-size: 22px;text-align: center; font-weight: bold;">Invite a team member</div>
                    <div>
                        <form [formGroup]="InviteForm">
                            <div>
                                <div style="  margin-bottom: 5px;">Email</div>
                                <input type="text" class="form-control" formControlName="email" style="border-color: #ccc;" [ngClass]="{Error:  submitted && formControls.email.errors || wrongemail}" [ngClass]="{'is-invalid': submitted && formControls.email.errors}" />
                            </div>
                        </form>
                        <div style="width: 100% ;margin-top: 20px;">
                            <div (click)="inviteTeamMember()" style=" margin: auto;   width: 100px;
        border: 1px solid;cursor: pointer;  border-radius: 5px;    padding: 5px; text-align: center;">
                                Send
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>