import { ChangeDetectorRef, Component, EventEmitter, HostListener, Inject, Input, OnInit, Output, Query } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PortalService } from 'src/app/portal/portal.service';
import * as CryptoJS from 'crypto-js';
import * as lodash from 'lodash';
import { UserService } from 'src/app/services/user.service';
import { AuthentificationSystemService } from '../authentification-system/authentification-system.service';
import { DataService } from '../services/data.service';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-access-system',
  templateUrl: './access-system.component.html',
  styleUrls: ['./access-system.component.css']
})
export class AccessSystemComponent implements OnInit {
  @Input() data;
  @ Input() accessData ;
  @Output() OutPutDataAccess = new EventEmitter();
  @Output() OutPutDataShared = new EventEmitter();

  codeForm: FormGroup;
  voucherForm: FormGroup;
  authForm: FormGroup;
  ticketForm: FormGroup;
  ticket: boolean = false;
  authCode: boolean = false;
  openCode: boolean = false;
  voucher: boolean = false;
  onchange: boolean = false;
  submittedVoucher: boolean = false;
  submittedAuth: boolean = false;
  submittedCode: boolean = false;
  erreurMsg = ["", ""]
  currentSelectedTicket;
  decodedToken;
  userId;
  myTicket;
  pass;
  selectedticket;
  tickets: any = [];
  havePass: boolean = false;
  soldOut: boolean = false;
  isShowing: boolean = true;
  available = 0;
  disable: boolean = false;
  wrongCode: boolean = false;
  authData
  login: boolean = false;
  accessPopup: boolean = true;
  event: any;
  dataAlert: any;
  showAlert_popup: boolean = false;
  currentPopup: any;
  access: any;
  constructor(private portalService: PortalService, private router: Router,
    public dialogRef: NbDialogRef<AccessSystemComponent>, public dialog: NbDialogService,private cookieService: CookieService,
    private authService: AuthentificationSystemService, private userSevice: UserService, public shareddata: DataService, public changeDetectorRef: ChangeDetectorRef) { }
  @HostListener('keyup', ['$event']) onKeyDown(e: any) {
    e.preventDefault();
    if (this.wrongCode)
    {
       this.wrongCode = false
       this.erreurMsg = ["", ""]
    }
    if (e.key == 'Delete' || e.keyCode == 8) {
      let prevControl: any = e.srcElement.previousElementSibling;
      while (true) {
        if (prevControl) {
          if (prevControl.type == e.srcElement.type) {
            prevControl.focus();
            prevControl.select();
            return;
          }
        }
        else {
          return;
        }
      }
    }
    else {
      if (e.srcElement.maxLength == e.srcElement.value.length) {
        let nextControl: any = e.srcElement.nextElementSibling;
        while (true) {
          if (nextControl) {
            if (nextControl.type == e.srcElement.type) {
              nextControl.focus();
              nextControl.select();
              return;
            }
            else {
              nextControl = nextControl.nextElementSibling;
            }
          }
          else {
            return;
          }
        }
      }
    }
  }
  ngOnInit(): void {
    
    this.shareddata.current_auth_active_popup.subscribe(popup => {
      this.currentPopup = popup
    })
    this.codeForm = new FormGroup({
      nb1: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb2: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb3: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb4: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
   
    });
    this.ticketForm = new FormGroup({
      ticket: new FormControl("", [Validators.required]),
    });
    this.voucherForm = new FormGroup({
      nb1: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb2: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb3: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb4: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
     
    });
    this.authForm = new FormGroup({
      nb1: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb2: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb3: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb4: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
    
    });
    this.event = this.shareddata.event;
    this.access = this.shareddata.access
    if (this.cookieService.get("token") && this.cookieService.get("usertype")=="User") this.isShowing = false;
    else this.isShowing = true;

    if (this.data.type == "code" && (this.data.status == "auth" || this.data.status == "email")) {this.authCode = true;}
    else if (this.data.type == "ticket" || this.data.type == "voucher"  ) {
      this.ticket = true
      this.portalService.getTicketsByEventId(this.shareddata.eventid).then((res: any) => {
        if (res) {

          this.tickets = res.passes
          this.currentSelectedTicket = this.tickets[0]._id;
          let soldOutTicket = lodash.find(this.tickets, { '_id': this.currentSelectedTicket });
          if (soldOutTicket.purchased == soldOutTicket.quantity) {
            this.disable = true
          }
          else {
            this.disable = false
          }
          res.passes.forEach(ticket => {
            if (ticket.purchased == ticket.quantity) {
              this.available++;
            }

          });
          if (this.data.havePass)
            return;
          else if (this.available == this.tickets.length) {
            this.soldOut = true
            this.havePass = true
          }
        }
      })
    }
    else if  (this.shareddata.access && this.shareddata.access.access == "protected_password") {this.openCode = true}
    if (this.data.havePass) {
      this.havePass = true
      this.erreurMsg = [true, this.data.message];
    }
    else this.havePass = false

    if (this.data.soldOut) {
      this.soldOut = true
      this.havePass = true
    }
    else
      {this.soldOut = false}

    this.shareddata.currentaccesspopup.subscribe((res)=>{

      if (res == "ticket")
      {
        this.ticket =true
        this.voucher = false
      }
      else if (res == "voucher")
      {
        this.ticket =false
        this.voucher = true
      }
    })
  }
  get voucherFormControls() {
    return this.voucherForm.controls;
  }
  get authFormControls() {
    return this.authForm.controls;
  }
  get codeFormControls() {
    return this.codeForm.controls;
  }
  goToTickets() {
    this.data.type = "ticket"
    this.ticket = true
    this.voucher = false;
  }
  showvoucherPopup() {
    this.ticket = false
    this.voucher = true;
    this.data.type = "voucher"

    this.OutPutDataShared.emit({voucherPopup : true})

  }
  selectChangeHandler() {
    this.myTicket = this.selectedticket;
  }
  
  confirmCode() {
    this.submittedCode = true
    if (this.codeForm.invalid) return;
    else {
      let code = this.codeForm.controls["nb1"].value + this.codeForm.controls["nb2"].value + this.codeForm.controls["nb3"].value + this.codeForm.controls["nb4"].value
      
      var encrypted_code = CryptoJS.AES.encrypt(code, 'OurProtectedPwdKey').toString();
      this.portalService.verifyToJoinDashboardEvent(encrypted_code, this.shareddata.eventid, this.shareddata.access.access," ").then((res: any) => {
        if (res && res.status == "success") {
          this.shareddata.getEventPassword(encrypted_code)
          if (this.cookieService.get("token")) {

            this.userSevice.getUserById().then((res1) => {              
              this.dataAlert =
              {
                eventid: this.shareddata.eventid,
                code: true,
                passes: true,
                access: "open",
                alertPopup: true
              }
              this.OutPutDataShared.emit(this.dataAlert)
            });

          }
          else {

            this.dataAlert =
            {
              eventid: this.shareddata.eventid,
              code: true,
              passes: true,
              access: "open",
              alertPopup: true
            }
            this.OutPutDataShared.emit(this.dataAlert)

          }

        }
        else {
          this.erreurMsg = [true, res.message];
        }
      })
    }
  }
  confirmCodeRestrictedEvent() {
    if (this.currentSelectedTicket && this.data.type == "ticket") {
      this.pass = this.currentSelectedTicket
    }
    else {
      this.submittedAuth = true
      this.pass = this.authForm.controls["nb1"].value + this.authForm.controls["nb2"].value + this.authForm.controls["nb3"].value + this.authForm.controls["nb4"].value
    }
    if (this.data.type == "code") {
      if (this.authForm.invalid) return;
      else {
        if (this.cookieService.get("token") && this.cookieService.get("usertype") == "User") {
          this.portalService.verifyAccessCode(this.pass, null, null, this.shareddata.eventid, this.data.status, this.authService.currentUser._id).subscribe((res: any) => {
          
            if (res && res.status == "success") {
              this.userSevice.getUserById();              
              this.dataAlert =
              {
                eventid: this.shareddata.eventid,
                code: true,
                passes: true,
                alertPopup: true

              }
              this.OutPutDataShared.emit(this.dataAlert)

            }
            else {
              
              if (res.message == "Invalid Code" || res.message == "Code not found") {
                this.wrongCode = true
                this.erreurMsg = [true, res.message];
              }
              else if (res.message !== "Sorry No passes left.") {
                this.dataAlert =
                {
                  eventid: this.shareddata.eventid,
                  message: res.message,
                  passes: true,
                  alertPopup: true

                }
                this.OutPutDataShared.emit(this.dataAlert)


              }
              else if (res.message == "Sorry No passes left.") {
                this.dataAlert =
                {
                  eventid: this.shareddata.eventid,
                  message: res.message,
                  passes: false,
                  alertPopup: true

                }
                this.OutPutDataShared.emit(this.dataAlert)

              }
            }
          })
        }
        else {
          this.portalService.verifyExistanceOfCode(this.pass, null, this.shareddata.eventid).then((res: any) => {
            if (res && res.status == "success") {
              this.authData = {
                eventid: this.shareddata.eventid,
                status: this.data.status,
                type: this.data.type,
                pass: this.pass,
                authPopup: true
              }
              this.OutPutDataShared.emit(this.authData)

            }
            else {
              if (res.message == "Invalid Code") {
                this.wrongCode = true
                this.erreurMsg = [true, res.message];
              }
              else if (res.message !== "Sorry No passes left.") {
                this.dataAlert =
                {
                  eventid: this.shareddata.eventid,
                  message: res.message,
                  passes: true,
                  alertPopup: true

                }
                this.OutPutDataShared.emit(this.dataAlert)

              }
              else if (res.message == "Sorry No passes left.") {
                this.dataAlert =
                {
                  eventid: this.shareddata.eventid,
                  message: res.message,
                  passes: false,
                  alertPopup: true

                }
                this.OutPutDataShared.emit(this.dataAlert)

              }
            }
          })
        }
      }
    }
  }
  purchaseVoucher() {
    this.submittedVoucher = true
    if (this.voucherForm.invalid) return;
    this.pass = this.voucherForm.controls["nb1"].value + this.voucherForm.controls["nb2"].value + this.voucherForm.controls["nb3"].value + this.voucherForm.controls["nb4"].value
    if (this.cookieService.get("token") && this.cookieService.get("usertype") == "User") {
      this.portalService.verifyAccessCode(null, null, this.pass, this.shareddata.eventid, this.data.status, this.authService.currentUser._id).subscribe((res: any) => {
        if (res && res.status == "success") {
          this.userSevice.getUserById()          
          this.dataAlert =
          {
            eventid: this.shareddata.eventid,
            code: true,
            passes: true,
            alertPopup: true
          }
          this.OutPutDataShared.emit(this.dataAlert)
        }
        else {
          if (res.message == "Invalid Voucher") {
            this.erreurMsg = [true, res.message];
            this.wrongCode = true
          }
          else if (res.message == "Sorry No vouchers left.") {
            this.dataAlert =
            {
              passes: false,
              alertPopup: true
            }
            this.OutPutDataShared.emit(this.dataAlert)
          }
        }
      })
    }
    else {
      this.portalService.verifyExistanceOfCode(null, this.pass, this.shareddata.eventid).then((res: any) => {
        if (res && res.status == "success") {
          this.authData = {
            eventid: this.shareddata.eventid,
            status: this.data.status,
            type: this.data.type,
            pass: this.pass,
            authPopup: true
          }
          this.OutPutDataShared.emit(this.authData)
        }
        else {
          if (res.message == "Invalid Voucher") {
            this.erreurMsg = [true, res.message];
            this.wrongCode = true
          }
          else if (res.message == "Sorry No vouchers left.") {
            this.dataAlert =
            {
              passes: false,
              alertPopup: true
            }
            this.OutPutDataShared.emit(this.dataAlert)
          }
        }
      })
    }

  }
  onchangePopup(event) {
    this.authData = event
    this.login = true
    this.accessPopup = false
  }
  changePopupAccess(event) {

    this.OutPutDataShared.emit(event)
    
  }
  Login() {

    this.authData = {
      eventid: this.shareddata.eventid,
      status: "auth",
      type: this.data.type,
      pass: this.pass,
      authPopup: true
    }
    this.OutPutDataShared.emit(this.authData)
    
  }
  changeColor(id) {
    document.getElementById(id).className = "radio-button-checked"
    document.getElementById(this.currentSelectedTicket).className = "radio-button"
    this.currentSelectedTicket = id;
  }
  showticketPopup() {
    this.voucher = false;
    this.ticket = true
  }
  back() {

    if (this.voucher) {
      this.voucher = false
      this.ticket = true
    }
    else {

      if (this.showAlert_popup || this.currentPopup == "alert" || this.currentPopup == "verif") {
        this.dialogRef.close()
      }
      else if (( (this.shareddata.event && !this.shareddata.event.is_inviteonly) || this.data.showNavBar) && !this.login && !this.showAlert_popup) {
        this.dialogRef.close()
      }
      else if (( (this.shareddata.event && !this.shareddata.event.is_inviteonly) || this.data.showNavBar) && this.login && !this.showAlert_popup) {
        this.login = false;
        this.showAlert_popup = false
        this.accessPopup = true
      }
      else {
        this.login = true;
        this.showAlert_popup = false
        this.accessPopup = false
      }
    }
  }
  Email()
  {
    this.authData = {
      eventid: this.shareddata.eventid,
      status: "email",
      type: this.data.type,
      pass: this.pass,
      authPopup: true
    }
    this.OutPutDataShared.emit(this.authData)
  }
}
