import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { DashboardTestService } from '../dashboard-test.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  currenttab = 'Event Page'
  eventID
  accessevent
  areasevent
  constructor(public router: Router, private route: ActivatedRoute, private dashbopardtestservice: DashboardTestService, public dataService: DataService) { }
  ngOnInit(): void {


    this.accessevent = this.dashbopardtestservice.access

    this.areasevent = this.dashbopardtestservice.areasevent
    
    this.route.params.subscribe((params) => {
      this.eventID = params["eventid"];
    });
    if (document.location.href.includes('event')) {
      this.currenttab = "Event Page"
    }
    else if (document.location.href.includes('registration')) {
      this.currenttab = "Registration"
    }
    else if (document.location.href.includes('passes')) {
      this.currenttab = "Access Pass"
    }
    else if (document.location.href.includes('invitations')) {
      this.currenttab = "Invitations"
    }
    else if (document.location.href.includes('sessions')) {
      this.currenttab = "Sessions"
    }
    else if (document.location.href.includes('exhibitors')) {
      this.currenttab = "Exhibitors"
    }


  }
  ngAfterViewChecked() {
    this.accessevent = this.dashbopardtestservice.access
    this.areasevent = this.dashbopardtestservice.areasevent

  }

  clickOnTab(tab) {
    if (tab == 'Event Page' && this.currenttab != tab) {
      this.currenttab = tab
      this.router.navigateByUrl('/dashboard/' + this.eventID + "/event")
    }
    else if (tab == 'Registration' && this.currenttab != tab) {
      this.currenttab = tab
      this.router.navigateByUrl('/dashboard/' + this.eventID + "/registration")
    }
    else if (tab == 'Access Pass' && this.currenttab != tab) {
      this.currenttab = tab
      this.router.navigateByUrl('/dashboard/' + this.eventID + "/passes")
    }
    else if (tab == 'Invitations' && this.currenttab != tab) {
      this.currenttab = tab
      this.router.navigateByUrl('/dashboard/' + this.eventID + '/invitations')
    }
    else if (tab == 'Sessions' && this.currenttab != tab) {
      this.currenttab = tab
      this.router.navigateByUrl('/dashboard/' + this.eventID + '/sessions')
    }
    else if (tab == 'Exhibitors' && this.currenttab != tab) {
      this.currenttab = tab
      this.router.navigateByUrl('/dashboard/' + this.eventID + '/exhibitors')
    }
  }
  ngAfterViewInit() {



    this.dataService.navbar_height.subscribe(height => {

      if (document.getElementById('sidebar')) {
        if (height == 95) {
          document.getElementById('sidebar').style.paddingTop = '20px';

        }
        else {
          if (height < 95) {
            document.getElementById('sidebar').style.paddingTop = '0px';


          }
        }
      }

    })
  }
}
