<div *ngIf="!verification && !showAlert_Popup && !showAccess_Popup" style="overflow: auto;width: 100%;">
    <div *ngIf="!hide" class="header">
        <div *ngIf="activate" class="Quicksand-bold PopupTitle" style="text-align:center;"> LOG IN
        </div>
        <div *ngIf="!activate && changeEmail" class="Quicksand-bold PopupTitle" style="text-align: center;margin-bottom: 10px;"> change Your Email</div>
        <div *ngIf="!activate  && access && access.access=='email' && !changeEmail" class="Quicksand-bold PopupTitle"
            style="text-align:center; font-weight: bold;"> Access the event by email</div>
        <div *ngIf="activate" class="paragraphe Quicksand-regular" style="text-align: center;">Not registred? &nbsp;<span
                (click)="signup()" style="text-decoration:underline;">Signup</span></div>
        <div *ngIf="!activate" class="paragraphe Quicksand-regular" style="text-align: center;">Already have an account? &nbsp;
            <span (click)="showLoginFormulaire()" style="text-decoration:underline;">Login</span></div>
        <div class="event-info">
            <div style="width: 35%;min-width: 175px;">
                <div *ngIf=" !changeEmail && Event && Event.cover_url && Event.cover_url.split('.')[ Event.cover_url.split('.').length-1]!='mp4'"
                    class="ar-16-9 " [style.background-image]="'url('+Event.cover_url+')'"></div>
                <div
                    *ngIf=" Event &&  Event.cover_url && Event.cover_url.split('.')[ Event.cover_url.split('.').length-1]=='mp4'">
                    <video autoplay="autoplay" loop muted style="width: 100%;">
                        <source [src]="Event.cover_url" type="video/mp4;">
                    </video>
                </div>
            </div>
            <div *ngIf="!changeEmail && Event" style="align-self: center;">
                <div class="subTitle Quicksand-semibold " style="text-align: left;margin-bottom: 10px;line-height: 25px;">{{Event.title}}
                </div>
                <div class="paragraphe Quicksand-regular" style="text-align: left;color: #FFFFFF">{{Event.start_date}}
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="hide" class="header2">
        <div class="Quicksand-bold PopupTitle">LOG IN</div>
        <div *ngIf="activate" class="smallText Quicksand-regular information-text">Not registred? &nbsp; <span
                (click)="signup()">Signup</span></div>
    </div>
    <div class="formulaire">
        <form class="form flex-column" [formGroup]="loginForm" (keydown.enter)="keyDownFunctionLogin($event)">
            <div class="input-group">
                <label for="email" class="Quicksand-regular paragraphe" style="color: #8F9BB3;">User name</label>
                <input class="input-form" [ngClass]="{Error: submitted && formControls.email.errors}" type="text"
                    name="email" id="email" placeholder="Username" formControlName="email" >
            </div>
            <div *ngIf="activate" class="input-group">
                <label for="pwd" class="Quicksand-regular paragraphe" style="color: #8F9BB3;">Password</label>
                <input class="input-form" [ngClass]="{Error: submitted && formControls.pwd.errors}" type="password"
                    name="pwd" id="pwd" formControlName="pwd" placeholder="password" >
            </div>
            <div *ngIf="activate" style="display: inline-flex;justify-content: flex-start; width: 100%;margin-top: 10px; ">
                <div class="options">
                    <nb-checkbox name="remember_me" formControlName="remember_me" class="Quicksand-regular paragraphe" style="color: #8F9BB3;">Remember me </nb-checkbox>
                </div>
            </div>
        </form>
        <div style=" width: 100%;max-width: 400px;margin-top: 10px;"
            [ngClass]="loginMessage[0]=='error' ? 'ErrorMessage' : loginMessage[0]=='success'? 'SuccessMessage' : loginMessage[0]=='warn'? 'WarnMessage'  :''">
            {{loginMessage[1]}} <span *ngIf="haveAccount" style="border-bottom: 1px solid;cursor:pointer; color: white;"
                (click)="showLoginFormulaire()">&nbsp; Login</span>
        </div>
    </div>
    <div class="next-btn-container">
        <button type="submit"  class="action-btn subTitle Quicksand-bold" style="color :#141A2A;text-align:center" (click)="login()">LOG IN</button>
    </div>
    <div *ngIf="activate" style="display: inline-flex;justify-content: flex-end; width: 100%; ">
        <div class="Quicksand-regular paragraphe" style="color: #8F9BB3;">
            <a (click)="forgotPassword()">Forgot Password ?</a>
        </div>
    </div>
    <div *ngIf="activate" style="width: 100%; display: inline-flex;align-items: center;margin: 20px 0;">
        <div style="height: 1px;background-color:#6c6b7f ;width: 50%;"></div>
        <div style="margin:10px;color: #6c6b7f;">or </div>
        <div style="height: 1px;background-color:#6c6b7f;width: 50%; "></div>
    </div>
    <div *ngIf="activate" style="width: 100%;display: flex;justify-content: center;" class="googlelinkedinButton">
        <a class="Google_Linkedin" (click)="connectWithSocialmedia('google')">
            <i class="fa fa-google" style="font-size: 19px;"></i>
        </a>
        <a class="Google_Linkedin" (click)="connectWithSocialmedia('linkedin')">
            <i class="fa fa-linkedin" style="font-size: 19px;"></i>
        </a>
    </div>
</div>


<!-- <app-verification-popup *ngIf="verification && !showAlert_Popup && !showAccess_Popup" [data]="dataVerif"></app-verification-popup> -->
<!-- <app-alert-popup *ngIf="showAlert_Popup && !verification && !showAccess_Popup"  [data]="dataAlert"></app-alert-popup> -->
<!-- <app-access-system *ngIf="(showAccess_Popup && !verification && !showAlert_Popup) || data.showAccess_Popup" [data]="dataAccess"></app-access-system> -->