<div class="fixed-top">
    <!-- <div #confirm id="confirm" *ngIf="!verified && !verifiedBar" style="background-color: #ffa000;text-align: center;cursor: pointer;" (click)="confirmAcount()">
        Your not verfied , Click here to verifiy you account !
    </div>
    <div id="confirm1" *ngIf="verifiedBar && verified" style="background-color: #006e12;text-align: center;cursor: pointer;display: inline-flex;width: 100%;" (click)="closeBar()">
        <div style="flex: 1%;">
            Your had verified you account !
        </div>
        <div><img src="../../../assets/popups/icons8-close-30.png" style="width: 15px;height: 15px;" /></div>
    </div> -->
    <nav #navbar [ngClass]="isChanged == true ? 'blackNavbar' : 'navbar'" id="navbar">
        <div class="padding-div"></div>
        <div style=" width: 100%;display: inline-flex;justify-content: space-between;flex-wrap: nowrap;">
            <div style=" display: inline-flex; flex-wrap: nowrap;    width: 100%;">
                <a class="navbar-brand" href="/"><img class="logo" [src]="logo" width="155px" />
                </a>
            </div>
            <div style="display: flex; justify-content:center;">
                <div
                    style="height: 20px;width: 40px;border-right: 1px solid #edf1f74f;margin-right: 20px;align-self: center;">
                    <div class="icon-search"></div>
                </div>
                <div *ngIf="!logoutBtn && !temporaryuser && !user" class="buttons">
                    <div class="login-btn smallText Quicksand-bold" (click)="Login()">
                        <div class="icon-login"></div>
                        <div style="align-self: center;">Login</div>
                    </div>
                    <div class="signin-btn smallText Quicksand-bold" (click)="Signup()">
                        <div class="icon-signup"></div>
                        <div style="align-self: center;">Create an account</div>
                    </div>
                </div>
                <div *ngIf="logoutBtn && user && myuser" class="buttons" (click)="OpenDropDownUser()">
                    <div class="profile-pic" id="dropdownUser" [style.background-image]="'url('+profilePic+')'">
                        <div *ngIf="openDropDownUser"  class="up-btn" ></div>
                        <div *ngIf="!openDropDownUser"  class="down-btn" ></div>
                        <div *ngIf="openDropDownUser" class="dropdown-info-user">
                            <div style="padding: 0 20px;
                            display: flex;
                            flex-direction: column;
                            height: 100%;
                            flex-direction: column;
                            justify-content: center;">
                                <div *ngIf="authService.currentUser && (authService.currentUser.role.includes('admin') || authService.currentUser.role.includes('agency'))" class="Quicksand-semibold paragraphe dropdown-btn" (click)="gotoDashboard()"> Dashboard </div>
                                <div class="Quicksand-semibold paragraphe dropdown-btn"> Profile </div>
                                <div class="Quicksand-semibold paragraphe dropdown-btn"> Your library </div>
                                <div class="Quicksand-semibold paragraphe dropdown-btn"> Setting</div>
                                <div class="Quicksand-semibold paragraphe " style="color: #AF1414;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                height: 35px;
                                cursor: pointer;" (click)="logout()"> Logout </div>
                            </div>
                        </div>
                    </div>
                  
                    <!-- <div class="login-btn smallText Quicksand-bold" (click)="logout()">
                        <div style="align-self: center;">Logout</div>
                    </div> -->
                </div>
                <div *ngIf="logoutBtn && temporaryuser" class="buttons" id="dropdown">
                    <div class="temporaryUser-btn smallText Quicksand-bold" (click)="OpenDropDown()">
                        <div *ngIf="!verified" (click)="confirmAcount()"
                            style="background: url(../../../assets/notVerified.png);align-self: center; width:20px; height: 25px; background-repeat: no-repeat;background-size: contain; ">
                        </div>
                        <div style="align-self: center;">{{email}}</div>
                        <div style="align-self: center;">
                            <div class="arrow-down"></div>
                        </div>
                    </div>
                    <div *ngIf="openDropDown" class="dropdown-info">
                        <div style="padding: 20px;display: flex;flex-direction: column;gap: 10px;">
                            <div class="user-photo">
                                <div class="bigTitle Quicksand-bold " style="color: #8F9BB3; align-self: center;">
                                    {{firstLetter}}
                                </div>
                            </div>
                            <div class="paragraphe Quicksand-semibold">
                                You've been signed in with a temporary profile.
                            </div>
                            <div class="tagsText Quicksand-regular">
                                You can sign up to our plateform
                            </div>
                            <div class="signin-btn smallText Quicksand-bold" (click)="Signup()">
                                <div style="align-self: center;">Create an account</div>
                            </div>
                            <div style="width: 100%; display: inline-flex;align-items: center;justify-content: center;">
                                <div style="height: 1px;background-color:#6c6b7f ;width: 10%;"></div>
                                <div style="margin:0 10px;color: #ffffff;" class="tagsText Quicksand-regular">or</div>
                                <div style="height: 1px;background-color:#6c6b7f;width: 10%; "></div>
                            </div>
                            <div class="other-email-text paragraphe Quicksand-medium" (click)="changeEmail()">
                                Change E-mail
                            </div>
                        </div>
                        <div style="width: 100%;
                        height: 49px;
                        border-radius: 0px 0px 10px 10px;
                        background-color: #0e1120b0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;cursor: pointer;" (click)="logoutTemporaryUser()">
                            <div style="align-self: center;" class="Quicksand-bold smallText"> Quit</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="padding-div"></div>
    </nav>
</div>
<!-- <ngx-spinner  [name]="'recommendedSpinner'" size="default" color="#8fa7e6" type="ball-spin-clockwise" [fullScreen] = "true">
</ngx-spinner> -->