import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { DashboardTestService } from '../dashboard-test.service';
@Component({
  selector: 'app-add-exhibitor',
  templateUrl: './add-exhibitor.component.html',
  styleUrls: ['./add-exhibitor.component.css']
})
export class AddExhibitorComponent implements OnInit {
  @Input() data;
  AddExhibitorForm: FormGroup
  constructor(private fb: FormBuilder, private dashboardtestservice: DashboardTestService, public dialogRef: NbDialogRef<AddExhibitorComponent>) { }
  ngOnInit(): void {
    if (this.data && this.data.name == "add") {
      this.AddExhibitorForm = this.fb.group({
        name: new FormControl("", [Validators.required]),
        description: new FormControl("", [Validators.required]),
        card_size: new FormControl("default", [Validators.required]),
        event_id: new FormControl(this.data.eventid, [Validators.required]),
        position: new FormControl(this.data.index, [Validators.required]),
      })
    }
    else if (this.data && this.data.name == "edit")
      this.AddExhibitorForm = this.fb.group({
        name: new FormControl(this.data.exhibitor.name, [Validators.required]),
        description: new FormControl(this.data.exhibitor.description, [Validators.required]),
        card_size: new FormControl(this.data.exhibitor.card_size, [Validators.required]),
        event_id: new FormControl(this.data.eventid, [Validators.required]),
        position: new FormControl(this.data.exhibitor.position, [Validators.required]),
      })
  }
  submitted = false
  addeditExhibitor() {
    this.submitted = true;
    if (this.AddExhibitorForm.invalid) {
      return;
    }
    else {
      if (this.data && this.data.name == "add") {
        this.dashboardtestservice.addExhibitor(this.AddExhibitorForm.value, this.logo, this.cover, this.data.eventid).then((res: any) => {
          this.dialogRef.close();
        });
      }
      else if (this.data && this.data.name == "edit") {
        this.dashboardtestservice.editExhibitor(this.data.exhibitor_id, this.AddExhibitorForm.value, this.logo, this.cover, this.data.eventid).then((res: any) => {
          this.dialogRef.close();
        });
      }
      this.submitted = false;
    }
  }
  get formControls() {
    return this.AddExhibitorForm.controls;
  }
  logo
  cover
  readURL(event, file): void {
    if (event.target.files && event.target.files[0]) {
      if (file == "logo") { this.logo = event.target.files[0]; }
      if (file == "cover") { this.cover = event.target.files[0]; }
    }
  }
  back() {
    this.dialogRef.close()
  }
}
