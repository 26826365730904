<div class="top-bar" (click)="back()">
    <img src="../../assets/icons8-close-30.png" style="width: 25px; cursor: pointer;">
</div>
<div style="width: 100%;height: 100%;display: flex;flex-direction: column;align-items: center;justify-content: center;">
    <div style="font-size: 22px;text-align: center; font-weight: bold;">Add Organisation</div>
    <form [formGroup]="AddOrganisationForm">
        <div style="margin: 5px 0;">
            <div style="margin: 5px 0;">Name</div>
            <input type="text" id="Name" class="form-control" formControlName="name" style="border-color: #ccc;" placeholder="Add a Name to your organisation" [ngClass]="{'is-invalid': submitted && formControls.name.errors}" />
        </div>
        <div style="margin: 5px 0;">
            <div style="margin: 5px 0;">Description</div>
            <input type="text" class="form-control" formControlName="description" style="border-color: #ccc;" placeholder="Add a description to your organisation" [ngClass]="{'is-invalid': submitted && formControls.description.errors}" />
        </div>
        <div style=" margin-top: 20px; ">
            <div style=" margin-top: 20px;   margin-bottom: 5px;;">Logo</div>
            <input type="file" class="form-control" (change)="readURL($event)" style="border-color: #ccc;" />
        </div>
    </form>
    <div style="width: 100% ;margin-top: 20px;">
        <div (click)="addOrganisation()" style=" margin: auto;   width: 100px;
        border: 1px solid;cursor: pointer;
        border-radius: 5px;    padding: 5px;
        text-align: center;">
            Add
        </div>
    </div>
</div>