<div>
  <div style="display:flex;flex-direction:column">
    <div class="player-container" id="player-container">
      <div style="height: 100%;">
        <div id="videoContainer" (mouseenter)="showControls(true)" (mouseleave)="showControls(false)">
          <video id="videoPlayer" #target class="video-js vjs-playlist vjs-close-button"
            [style.width]="fullScreen==true ? '100%':'57vw'" [style.height]="fullScreen==true ? '100%':'65vh'" controls
            muted playsinline preload="none">
          </video>
          <div *ngIf="!paused" id="videoContent" class="videoContent" (click)="playVideo()">
            <div style="width: 100%; height: 50px;z-index: 3000;">
              <div
                style="background: url(../../assets/ellipsis.png);width: 30px;height: 25px;cursor: pointer;float: right;">
              </div>
            </div>
            <div *ngIf="selectedSession && fullScreen"
              style="display: flex;flex-direction: column;gap:15px;max-width: 100%">
              <div class="subTitle Quicksand-light" style="line-height: 25px;">
                Event : {{selectedSession.event_title}}
              </div>
              <div class="bigTitle Quicksand-semibold" style="text-align: left;line-height: 25px;">
                {{selectedSession.title}}
              </div>
            </div>
          </div>
          <div *ngIf="paused && isProfile && isProfile=='true' && selectedSession" class="video-poster">
            <div
              *ngIf="selectedSession.cover_url && selectedSession.cover_url.split('.')[ selectedSession.cover_url.split('.').length-1]!='mp4'"
              style="width: 100%;
            height: 100%;
            border-radius: 10px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;" [style.background-image]="'url('+selectedSession.cover_url+')'">
            </div>
            <video #video
              *ngIf="selectedSession.cover_url && selectedSession.cover_url.split('.')[ selectedSession.cover_url.split('.').length-1]=='mp4'"
              [id]="selectedSession._id" [muted]="'muted'" style="border-radius:10px;">
              <source [src]="selectedSession.cover_url" type="video/mp4">
            </video>
            <div (mouseenter)="playVideoCover(selectedSession._id ,true)"
              (mouseleave)="playVideoCover(selectedSession._id ,false)" style="position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 100%;
            height: 100%;
            padding: 35px 40px;
            border-radius: 10px;
            background: transparent linear-gradient(180deg, #171F3300 0%, #171F33 100%) 0% 0% no-repeat padding-box;">
              <div class="duration" style="align-self: flex-end;">{{selectedSession.duration}}</div>
              <div [id]="'whitePlay'+selectedSession._id"
                style="flex: none;align-self: center;margin-top: 25%;cursor: pointer;" (click)="playVideo()"
                class="play-icon">
              </div>
              <div [id]="'redPlay'+selectedSession._id" style="flex: none;align-self: center;margin-top: 3%;cursor: pointer;" class="play-icon-hover">
              </div>
              <div style="display: flex;justify-content: flex-end; flex-direction:column ;gap:20px; height: 100%;">
                <div class="smallText Quicksand-bold" style="text-align: start">
                  {{selectedSession.title}}
                </div>
                <div class="session-video-info-player">
                  <div *ngIf="selectedSession.speakers" class="session-speakers" style="align-items: flex-start;"
                    [style.left.px]=" -15*(selectedSession.speakers.length-1) ">
                    <div *ngFor="let speaker of selectedSession.speakers;let i = index" style="display: inline-flex;">
                      <div *ngIf="i<=2" [style.background-image]="'url('+speaker.photo+')'" class="speaker"
                        [style.left.px]=" 15*(selectedSession.speakers.length-1-i) ">
                      </div>
                      <div *ngIf="selectedSession.speakers.length>3 && i==2"
                        [style.background-image]="'url('+speaker.photo+')'" class="last-speaker"
                        [style.left.px]=" 15*(selectedSession.speakers.length-1-i) ">
                      </div>
                      <div *ngIf="selectedSession.speakers.length>3 && i==2" class="tagsText" style="
                                                  font-family:Quicksand-regular ;
                                                  align-self: flex-end;   
                                                  position: relative;
                                                  left: -25px;
                                                  top: -6px;">
                        +{{selectedSession.speakers.length-i}}</div>
                    </div>
                  </div>
                  <div *ngIf="selectedSession.tags" style="display: inline-flex; gap: 10px;">
                    <div *ngFor="let tag of selectedSession.tags; let i=index" class="tag tagsText Quicksand-light"
                      style="flex: none;align-self: center;">
                      {{tag}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!playerShow" class="video-launched" [style.width]="fullScreen==true ? '100%':'57vw'"
          [style.height]="fullScreen==true ? '100%':'65vh'"
          [style.background-image]="'url('+selectedSession.cover_url+')'">
          <div id="videoContent-launched" class="videoContent-launched">
            <div class="setReminder">
              <div style="display: flex; flex-direction:column;align-self: center;">
                <div class="Quicksand-medium smallText">Live in 4 days</div>
                <div class="Quicksand-regular paragraphe">4 Juin, 8h30 am </div>
              </div>
              <div class="watch-btn smallText setReminder-btn">
                <div class="iconBlack-session-card"></div>
                <div style="align-self: center;">Set Reminder</div>
              </div>
            </div>
          </div>
        </div>
        <div class="description-bar" *ngIf="selectedSession && (!isProfile || isProfile=='false')">
          <div class="Quicksand-bold subTitle">
            {{selectedSession.title}}
          </div>
          <div style="display: flex;gap:30px;">
            <div style="display: flex;gap:10px;height: 35px;" *ngIf="playerShow">
              <div class="likeIcon"></div>
              <div class="Quicksand-medium paragraphe" style="align-self: center;"> 1,302 Like </div>
            </div>
            <div class="btn-share">
              <div class="iconShare"></div>
              <div class="Quicksand-bold smallText" style="align-self: center;">
                SHARE
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="description-bar" style="border-bottom: none;height: 20px;"
          *ngIf="selectedSession && (isProfile || isProfile=='true')">
          <div></div>
          <div style="display: flex;gap:30px;">
            <div style="display: flex;gap:10px;height: 35px;" *ngIf="playerShow">
              <div class="likeIcon"></div>
              <div class="Quicksand-medium paragraphe" style="align-self: center;"> 1,302 Like </div>
            </div>
            <div class="btn-share">
              <div class="iconShare"></div>
              <div class="Quicksand-bold smallText" style="align-self: center;">
                SHARE
              </div>
            </div>
          </div>
        </div> -->
        <div *ngIf="(!isProfile || isProfile=='false') && selectedSession &&  selectedSession.description " id="description" class="Quicksand-regular paragraphe description">
          {{selectedSession.description}}
        </div>
        <div class="event-tags" id="tags" *ngIf="selectedSession && (!isProfile || isProfile=='false')">
          <div class="tag tagsText" style="color:#8f9bb3;" *ngFor="let tag of selectedSession.tags">{{tag |
            titlecase}}
          </div>
        </div>
        <div *ngIf="!isProfile || isProfile=='false'" class="speakers-bloc" id="speakers-bloc">
          <div class="speakers-title" style="margin-bottom: 15px;">
            <div class="highlighted-title subTitle Quicksand-bold">Speakers</div>
          </div>
          <div class="speakers">
            <div class="speaker-card" *ngFor="let speaker of speakers" (click)="gotoProfile(speaker)">
              <div class="speaker-pic">
                <div class="speaker-img" [style.background-image]="'url('+speaker.photo+')'"><img
                    style="visibility: hidden;" [src]="speaker.photo"></div>
                <div class="speaker-role tagsText">
                  Speaker
                </div>
                <div class="speaker-infos ">
                  <div id="name" class="smallText">{{speaker.firstname | titlecase}} {{speaker.lastname | titlecase}}
                  </div>
                  <div id="position" class="paragraphe Quicksand-medium ThreeLines">{{speaker.title | titlecase}}
                  </div>
                </div>
              </div>
              <div style="align-self: center;">
                <a [href]="speaker.linkedin" style="text-decoration: none; color: white; " target="_blank">
                  <div class="icon-in">
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; flex-direction: column;gap: 30px;">
        <div *ngIf="responsive" class="speakers-title" style="margin-bottom: 15px;">
          <div class="highlighted-title subTitle Quicksand-bold">Playlist </div>
        </div>
        <div class="playlist" [style.minHeight]="!isProfile || isProfile=='false' ? '730px' : '66vh'"
          [style.maxHeight]="!isProfile || isProfile=='false' ? '730px' : '66vh'">
          <div *ngFor="let session of data; let i=index"
            [ngClass]="i==0 ? 'session-capsule-first' : i == data.length-1 ? 'session-capsule-last': 'session-capsule'"
            [ngStyle]="{'background':selectedSession && selectedSession._id == session._id ? '#0e1120':'transparent'} "
            (click)="selectSession(i)">
            <div
              *ngIf="session.cover_url && session.cover_url.split('.')[ session.cover_url.split('.').length-1]!='mp4'"
              class="session-cover" [style.background-image]="'url('+session.cover_url+')'"> </div>
            <div  class="session-cover" (mouseenter)="playVideoCoverCapsule(selectedSession._id ,true)"
              (mouseleave)="playVideoCoverCapsule(selectedSession._id ,false)"
              *ngIf="session.cover_url && session.cover_url.split('.')[ session.cover_url.split('.').length-1]=='mp4'">
              <video style="width: 9vw;
              height: 10.5vh;
              position: relative;
              border-radius: 10px;" #videoCapsule [id]="'capsule'+session._id" [muted]="'muted'">
                <source [src]="session.cover_url" type="video/mp4">
              </video>
            </div>
            <div class="capsule-content">
              <div class="Quicksand-bold smallText line-limit" style="max-width: 177px;">{{session.title}} </div>
              <div>
                <div *ngIf="session.speakers" class="session-speakers" style="align-items: flex-start;"
                  [style.left.px]=" -15*(session.speakers.length-1) ">
                  <div *ngFor="let speaker of session.speakers;let i = index" style="display: inline-flex;"
                    (click)="gotoProfile(speaker)">
                    <div *ngIf="i<=2" [style.background-image]="'url('+speaker.photo+')'" class="speaker"
                      [style.left.px]=" 15*(session.speakers.length-1-i) ">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style="display: flex;flex-direction:column;justify-content: space-around;min-width:68px;max-width: 80px;">
              <div style="display: flex; gap: 5px;justify-content: flex-end;">
                <div *ngIf="session.status == 'Live'" class="session-status">{{session.status}}</div>
                <div *ngIf="session.status != 'Live'" style="height: 25px;"></div>
              </div>
              <div style="display: flex;justify-content:flex-end ;">
                <div class=" duration tagsText Quicksand-regular">
                  {{session.duration}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="nextVideo" *ngIf="session" (click)="onClickOnNextSession()" style="visibility: hidden;display: none;">
    <div class="smallText Quicksand-semibold" style="margin-bottom: 10px; color: #0c0f1bab;">
      Playing in <span style="color: #8FA7E6;"> {{countDownText}}</span> seconds
    </div>
    <div class="video-wrapper">
      <video #video
        *ngIf="session.cover_url && session.cover_url.split('.')[ session.cover_url.split('.').length-1]=='mp4'"
        [id]="session._id" [muted]="'muted'">
        <source [src]="session.cover_url" type="video/mp4">
      </video>
      <div [id]="'image'+session._id"
        *ngIf="session.cover_url && session.cover_url.split('.')[ session.cover_url.split('.').length-1]!='mp4'"
        [style.background-image]="'url('+session.cover_url+')'" class="session-cover-small">
      </div>
      <div class="content-video">
        <div class="header-btn">
          <div class=" duration tagsText Quicksand-regular">
            {{session.duration}}
          </div>
        </div>
        <!-- <div style="display: flex;justify-content: center;height: 100%;">
          <div [id]="'whitePlay'+session._id" class="play-icon">
          </div>
          <div [id]="'redPlay'+session._id" class="play-icon-hover">
          </div>
        </div> -->
        <div>
          <div class="session-video-info">
            <div class="smallText Quicksand-bold" style="text-align: start">
              {{session.title}}
            </div>
            <div *ngIf="session.speakers" class="session-speakers" style="align-items: flex-start;"
              [style.left.px]=" -15*(session.speakers.length-1) ">
              <div *ngFor="let speaker of session.speakers;let i = index" style="display: inline-flex;">
                <div *ngIf="i<=2" [style.background-image]="'url('+speaker.photo+')'" class="speaker"
                  [style.left.px]=" 15*(session.speakers.length-1-i) ">
                </div>
                <div *ngIf="session.speakers.length>3 && i==2" [style.background-image]="'url('+speaker.photo+')'"
                  class="last-speaker" [style.left.px]=" 15*(session.speakers.length-1-i) ">
                </div>
                <div *ngIf="session.speakers.length>3 && i==2" class="tagsText" style="
                                            font-family:Quicksand-regular ;
                                            align-self: flex-end;   
                                            position: relative;
                                            left: -25px;
                                            top: -6px;">
                  +{{session.speakers.length-i}}</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div id="allVideo"
    style="width: 140vw;flex-direction: row;flex-wrap: wrap;gap: 20px;justify-content: center;align-self : center;margin-top: 8%;visibility: hidden;display: none;">
    <div *ngFor="let session of shownVideos;let index=index">
      <div [id]="index" class="video-wrapper" (click)="selectSession(index)">
        <div [id]="'image'+session._id"
          *ngIf="session.cover_url && session.cover_url.split('.')[ session.cover_url.split('.').length-1]!='mp4'"
          [style.background-image]="'url('+session.cover_url+')'" class="session-cover-small">
        </div>
        <div class="content-video">
          <div class="header-btn">
            <div class=" duration tagsText Quicksand-regular">
              {{session.duration}}
            </div>
          </div>
          <!-- <div style="display: flex;justify-content: center;height: 100%;">
            <div [id]="'whitePlay'+session._id" class="play-icon">
            </div>
            <div [id]="'redPlay'+session._id" class="play-icon-hover">
            </div>
          </div> -->
          <div>
            <div class="session-video-info">
              <div class="smallText Quicksand-bold" style="text-align: start">
                {{session.title}}
              </div>
              <div *ngIf="session.speakers" class="session-speakers" style="align-items: flex-start;"
                [style.left.px]=" -15*(session.speakers.length-1) ">
                <div *ngFor="let speaker of session.speakers;let i = index" style="display: inline-flex;">
                  <div *ngIf="i<=2" [style.background-image]="'url('+speaker.photo+')'" class="speaker"
                    [style.left.px]=" 15*(session.speakers.length-1-i) ">
                  </div>
                  <div *ngIf="session.speakers.length>3 && i==2" [style.background-image]="'url('+speaker.photo+')'"
                    class="last-speaker" [style.left.px]=" 15*(session.speakers.length-1-i) ">
                  </div>
                  <div *ngIf="session.speakers.length>3 && i==2" class="tagsText" style="
                                              font-family:Quicksand-regular ;
                                              align-self: flex-end;   
                                              position: relative;
                                              left: -25px;
                                              top: -6px;">
                    +{{session.speakers.length-i}}</div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>