<div style="display: flex;flex-direction:column">
    <div class="explore-home-card">
        <div class="cover">
            <div id="slideshow" class="slider">
                <div class="cover-diapo">
                    <div *ngIf="!uploaded && is_admin && is_admin=='true'">
                        <input type="file" (change)="readURL($event)" name="video" id="inputfile" style="opacity: 0;position: absolute;" accept="video/*" />
                        <label class="changeVideo Quicksand-medium" style="cursor: pointer;" for="inputfile">
                            Change cover video</label>
                    </div>
                    <div *ngIf="uploaded && is_admin && is_admin=='true'" style="position: absolute;float: right;cursor: pointer;z-index: 1;top: 10px;right: 10px;display:inline-flex; ">
                        <div class="saveVideo" (click)="saveVideo()">Save</div>
                        <div class="cancelVideo" (click)="cancelVideo()">Cancel</div>
                    </div>
                    <video id="videoCoverAdmin" *ngIf="videoSrc && uploaded && is_admin && is_admin=='true'" autoplay="autoplay" [loop]="'true'" muted style="width: 100%; height: 100%;">
                        <source [src]="videoSrc" type="video/mp4;">
                    </video>
                    <video #video  id="videoCover" *ngIf="!uploaded" autoplay="autoplay" muted [loop]="'true'"  style="width: 100%; height: 100%;">
                        <source [src]="Imagedata" type="video/mp4;">
                        <ngx-spinner [name]="'homespinner'" bdColor="#191c304f" size="default" color="#8fa7e6"
                            type="ball-spin-clockwise" [fullScreen]="false">
                        </ngx-spinner>
                    </video>
                </div>
            </div>
            <div class="cover-content">
                <div *ngIf="!muted" style="width: 35px;
                height: 35px;
                background-repeat: no-repeat;
                position: relative;
                align-self: flex-end;
                margin-right: 60px;
                cursor: pointer;
                background: url(../../assets/on_sound.png);" (click)="soundOnOff(false)"></div>
                <div *ngIf="muted" style="width: 35px;
                height: 35px;
                background-repeat: no-repeat;
                position: relative;
                align-self: flex-end;
                margin-right: 60px;
                cursor: pointer;
                background: url(../../assets/off_sound.png);" (click)="soundOnOff(true)"></div>

                <div style="align-self: center;width: 100%; display: flex; justify-content: center;">
                    <div style="display: flex;flex-direction:column;justify-content: center;">
                        <div class="paragraphe Quicksand-regular" style="margin-bottom: 10px;">
                            Our suggestions of specialties, diseases and treatment topics :
                            <span *ngIf="is_admin && is_admin=='true'"> <i class="fa fa-pencil icons"
                                    style="cursor: pointer;" (click)="AddCategory()"></i></span>
                        </div>
                        <div id="categories-container" class="categories-container">
                            <div id="leftarrowDay" *ngIf="rightArrowCategorie >= 5 " class="arrowLeft" (mouseenter)="scrollCategories(divTest, 5,'categories-container','leftarrowDay')" (mouseleave)="stopTimerCategories(timerCategories)"></div>
                            <div id="categories" class="categories">
                                <div *ngFor="let categorie of ListCategories" class="categorie">
                                    <div class="icon-categorie" [style.background-image]="'url('+categorie.icon+')'">
                                    </div>
                                    <div class="name-categorie Quicksand-semibold">{{categorie.name | titlecase}}</div>
                                </div>
                            </div>
                            <div id="rightarrowDay" *ngIf="overflowActivatedCategorie" class="arrowRight" style="margin-right: 10px;" (mouseenter)="scrollCategories(divTest, -5,'categories-container','rightarrowDay')" (mouseleave)="stopTimerCategories(timerCategories)">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <app-recommended-events id="recommended-event" (activeBloc)="onActiveBloc($event)" [is_admin]="is_admin">
    </app-recommended-events>
    <app-upcomming-events *ngIf="upcommingEventComponent" id="upcoming-event" (activeBloc)="onActiveBloc($event)"
        [is_admin]="is_admin">
    </app-upcomming-events>
    <app-playlist *ngIf="playlistComponent" id="playlist" (activeBloc)="onActiveBloc($event)" [is_admin]="is_admin">
    </app-playlist>
    <app-speakers *ngIf="speakerComponent" id="speakers" (activeBloc)="onActiveBloc($event)" [is_admin]="is_admin">
    </app-speakers>
    <app-testimonials *ngIf="testimonialComponent" id="testimonial" (activeBloc)="onActiveBloc($event)"
        [is_admin]="is_admin">
    </app-testimonials>
    <div *ngIf="footer">
        <app-footer  ></app-footer>

        <!-- <div class="publish-part" [style.background-image]="'url('+top_footer_config.url+')'">
            <div class="publish-paragraphe">
                <div *ngIf="top_footer_config && top_footer_config.welcome_message  && top_footer_config.welcome_message !=''" class="bigTitle Quicksand-bold"
                    style="text-align: left;">
                    <div [innerHTML]="top_footer_config.welcome_message"></div>

                </div>
                <div *ngIf="top_footer_config && top_footer_config.message  && top_footer_config.message !=''">


                    <div class="subTitle publish-text Quicksand-medium">
                        <div [innerHTML]="top_footer_config.message"></div>
                    </div>
                    <div class="smallText speaker-showMore Quicksand-bold" style="width: 242px;height: 40px;">CREATE
                        EXPERIENCE
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-explore">
            <div style="color: #8F9BB3;display: inline-flex;width: 100%;justify-content: center;    align-items: center;
            ">
                <div class="menu-footer">
                    <div class="sub-menu">
                        <div class="paragraphe Quicksand-medium" style="color: #8F9BB3;">Home</div>
                        <div class="paragraphe Quicksand-medium" style="color: #8F9BB3;">Contact us</div>
                        <div class="paragraphe Quicksand-medium" style="color: #8F9BB3;">Terms of service
                        </div>
                        <div class="paragraphe Quicksand-medium" style="color: #8F9BB3;">About us</div>
                    </div>
                    <div class="sub-menu">
                        <div class="paragraphe Quicksand-medium" style="color: #8F9BB3;">Description</div>
                        <div class="paragraphe Quicksand-medium" style="color: #8F9BB3;">Sessions</div>
                        <div class="paragraphe Quicksand-medium" style="color: #8F9BB3;">Speakers</div>
                        <div class="paragraphe Quicksand-medium " style="color: #8F9BB3;">Exhibitors</div>
                    </div>
                </div>
                <div style="display:inline-flex; justify-content: center;width:40%;">
                    <div class="paragraphe-footer">
                        <div *ngIf="bottom_footer_config && bottom_footer_config.title && bottom_footer_config.title!=''" class="bigTitle speakers-title Quicksand-bold" style="color: #8F9BB3;">
                           <div [innerHTML]="bottom_footer_config.title"> </div>
                        </div>
                        <div *ngIf="bottom_footer_config && bottom_footer_config.description && bottom_footer_config.description!=''" class="paragraphe" style="color: #8F9BB3;line-height: 28px;max-width: 55vh;">
                            <div [innerHTML]="bottom_footer_config.description"> </div>
                        </div>
                        <div class="footer-icons">
                            <img style="width: 30px;height: 30px;" src="../../assets/twiter.png">
                            <img style="width: 30px;height: 30px;" src="../../assets/fb.png">
                            <img style="width: 30px;height: 30px;" src="../../assets/IN.png">
                            <img style="width: 30px;height: 30px;" src="../../assets/url.png">
                        </div>
                    </div>
                </div>
            </div>
            <div class="copy-right tagsText Quicksand-regular">
                © 2022 Incept. All rights reserved.
            </div>

        </div> -->
    </div>
</div>
<!-- <div >
    <app-info-toaster></app-info-toaster>
</div> -->