<!-- <div class="video-player-wrapper" (mouseenter)="showControls(true)" (mouseleave)="showControls(false)">
    <vg-player id="vdPlayer">
        <vg-overlay-play style="z-index: 2000;"></vg-overlay-play>
        <vg-buffering></vg-buffering>
        <vg-controls  id="controls" style="visibility: hidden ;z-index: 2000;">
            <vg-play-pause></vg-play-pause>
            <vg-mute></vg-mute>
            <vg-volume></vg-volume>
            <vg-scrub-bar>
                <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
            </vg-scrub-bar>
            <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display> <div style="align-self: center;">/</div>
            <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
            <vg-fullscreen></vg-fullscreen>
        </vg-controls>
        <video [vgMedia]="media" [vgHls]="'https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8'" #media id="singleVideo"  crossorigin>
            <source [src]="data.src" type="video/mp4"> 
            <track kind="subtitles" label="English" src="http://static.videogular.com/assets/subs/pale-blue-dot.vtt"
                srclang="en" default>
            <track kind="subtitles" label="Español" src="http://static.videogular.com/assets/subs/pale-blue-dot-es.vtt"
                srclang="es">
        </video>
    </vg-player>
    <div id="videoContent" style="display:flex;flex-direction: column;justify-content: flex-start; width: 100%; height: 100%; position: absolute;top: 0px;left: 0px;background: #0e1120ad;padding: 35px 100px;">
    <div style="width: 100%; height: 50px;z-index: 3000;"><div style="background: url(../../assets/icons8-close-30.png);width: 30px;height: 30px;cursor: pointer;" (click)="closeVideoPlayer()"></div> </div>
    </div>
</div> -->
<!-- <video style="width: 100%;height: 100%;" id="example_video_1" controls="true" className="video-js vjs-default-skin vjs-big-play-centered">
    <source [src]="data.src"  type="video/mp4"/>
</video> -->

<div id="content_video" class="video-player-wrapper" (mouseenter)="showControls(true)" (mouseleave)="showControls(false)">
    <video #target class="video-js vjs-playlist vjs-close-button" autoplay controls  playsinline preload="none"  style="width: 100%;height: 100%;">
    </video>
    <!-- data-setup='{"children": {"loadingSpinner": false}}' -->
    <div id="videoContent"  (click)="playVideo()"
        style="display:flex;flex-direction: column;justify-content: flex-start; width: 100%;
         height: 100%; position: absolute;top: 0px;left: 0px;background:linear-gradient(360deg, rgba(23, 31, 51, 0) 54%, rgba(10, 12, 23, 0.74) 102%) 0% 0% no-repeat padding-box padding-box transparent;padding: 35px 100px;gap: 2%;">
        <div style="width: 100%; height: 50px;z-index: 3000;">
            <div style="background: url(../../assets/icons8-close-30.png);width: 30px;height: 30px;cursor: pointer;" (click)="closeVideoPlayer()"></div>
        </div>
        <div *ngIf="currentSession" style="display: flex;flex-direction: column;gap:15px;max-width: 100%">
            <div class="subTitle Quicksand-light" style="line-height: 25px;">
                Event : {{event_title}}
            </div>
            <div class="bigTitle Quicksand-semibold" style="text-align: left;line-height: 25px;">
                {{currentSession}}
            </div>
        </div>
    </div>
    <div *ngIf="ad" class="skip" (click)="onClickSkipAds()">
        Skip Ad >>
    </div>
</div>


<div id="nextVideo" *ngIf="session" (click)="onClickOnNextSession()" >
    <div class="smallText Quicksand-semibold" style="margin-bottom: 10px; color: #0c0f1bab;;">
      Playing in <span style="color: #8FA7E6;"> {{countDownText}}</span> seconds
    </div>
    <div class="video-wrapper">
      <video #video
        *ngIf="session.cover_url && session.cover_url.split('.')[ session.cover_url.split('.').length-1]=='mp4'"
        [id]="session._id">
        <source [src]="session.cover_url" type="video/mp4">
      </video>
      <div [id]="'image'+session._id"
        *ngIf="session.cover_url && session.cover_url.split('.')[ session.cover_url.split('.').length-1]!='mp4'"
        [style.background-image]="'url('+session.cover_url+')'" class="session-cover-small" style="background-size: cover;
        width: 100%;
        height: 100%;">
      </div>
      <div class="content-video">
        <div class="header-btn">
          <div class=" duration tagsText Quicksand-regular">
            {{session.duration}}
          </div>
        </div>
        <!-- <div style="display: flex;justify-content: center;height: 100%;">
          <div [id]="'whitePlay'+session._id" class="play-icon">
          </div>
          <div [id]="'redPlay'+session._id" class="play-icon-hover">
          </div>
        </div> -->
        <div>
          <div class="session-video-info">
            <div class="smallText Quicksand-bold" style="text-align: start">
              {{session.title}}
            </div>
            <div *ngIf="session.speakers" class="session-speakers" style="align-items: flex-start;"
              [style.left.px]=" -15*(session.speakers.length-1) ">
              <div *ngFor="let speaker of session.speakers;let i = index" style="display: inline-flex;">
                <div *ngIf="i<=2" [style.background-image]="'url('+speaker.photo+')'" class="speaker"
                  [style.left.px]=" 15*(session.speakers.length-1-i) ">
                </div>
                <div *ngIf="session.speakers.length>3 && i==2" [style.background-image]="'url('+speaker.photo+')'"
                  class="last-speaker" [style.left.px]=" 15*(session.speakers.length-1-i) ">
                </div>
                <div *ngIf="session.speakers.length>3 && i==2" class="tagsText" style="
                                            font-family:Quicksand-regular ;
                                            align-self: flex-end;   
                                            position: relative;
                                            left: -25px;
                                            top: -6px;">
                  +{{session.speakers.length-i}}</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
<div id="allVideo" style="width: 140vw;display: flex;flex-direction: row;flex-wrap: wrap;gap: 20px;justify-content: center;align-self : center;margin-top: 18%;">
  <div *ngFor="let session of shownVideos;let index=index" (click)="selectSession(index)" >
    <div [id]="index" class="video-wrapper">
    <div [id]="'image'+session._id"
        *ngIf="session.cover_url && session.cover_url.split('.')[ session.cover_url.split('.').length-1]!='mp4'"
        [style.background-image]="'url('+session.cover_url+')'"
        class="session-cover">
    </div>
    <div class="content-video" >
        <div class="header-btn">
            <div class=" duration tagsText Quicksand-regular">
                {{session.duration}}
            </div>
        </div>
        <!-- <div style="display: flex;justify-content: center;height: 100%;">
            <div [id]="'whitePlay'+session._id" class="play-icon">
            </div>
            <div [id]="'redPlay'+session._id" class="play-icon-hover">
            </div>
        </div> -->
        <div>
            <div class="session-video-info">
                <!-- <div class="smallText Quicksand-bold" style="text-align: start">
                    {{session.title}}
                </div>
                <div style="display: flex;justify-content: center;height: 100%;">
                    <div [id]="'whitePlay'+session._id" class="play-icon">
                    </div>
                    <div [id]="'redPlay'+session._id" class="play-icon-hover">
                    </div>
                </div> -->
                <div>
                    <div class="session-video-info">
                        <div class="smallText Quicksand-bold" style="text-align: start">
                            {{session.title}}
                        </div>
                        <div *ngIf="session.speakers" class="session-speakers" style="align-items: flex-start;" [style.left.px]=" -15*(session.speakers.length-1) ">
                            <div *ngFor="let speaker of session.speakers;let i = index" style="display: inline-flex;">
                                <div *ngIf="i<=2" [style.background-image]="'url('+speaker.photo+')'" class="speaker" [style.left.px]=" 15*(session.speakers.length-1-i) ">
                                </div>
                                <div *ngIf="session.speakers.length>3 && i==2" [style.background-image]="'url('+speaker.photo+')'" class="last-speaker" [style.left.px]=" 15*(session.speakers.length-1-i) ">
                                </div>
                                <div *ngIf="session.speakers.length>3 && i==2" class="tagsText" style="
                                            font-family:Quicksand-regular ;
                                            align-self: flex-end;   
                                            position: relative;
                                            left: -25px;
                                            top: -6px;">
                                    +{{session.speakers.length-i}}</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>