<div class="selected-channel-part" *ngIf="channel && channel != null ||  is_admin=='true'">
    <!-- <div style="display: inline-flex;">
        <div class="padding-div"></div>

         <div [ngClass]="sections_title_icon && sections_title_icon !='' ?'titles_dupiflix':'titles'"
            style="display:inline-flex;gap:20px">
            <div style="display: inline-flex;">
                <img *ngIf="sections_title_icon && sections_title_icon !=''" [src]="sections_title_icon" style="width: 20px;margin-right: 5px;
            height: 20px;" />

                <div [ngClass]="!sections_title_icon || sections_title_icon =='' ?'highlighted-title':''"
                    class=" subTitle Quicksand-bold">
                    Our Channel Selection</div>
            </div> 
        </div>  


   









        <div *ngIf="is_admin && is_admin=='true'" class="show-all smallText Quicksand-semibold"><i class="fa fa-pencil" style="color: white;cursor: pointer;" (click)="updateSelectedChannel()"></i> </div>
        <div class="padding-div"></div>
      
        <div class="padding-div"></div>
    </div> -->


    <div style="display: inline-flex;">
        <div class="padding-div"></div>
        <div style="display: inline-flex;justify-content:space-between;width:100%;align-items: center;">
            <div [ngClass]="sections_title_icon && sections_title_icon !='' ?'titles_dupiflix':'titles'"
                style="display:inline-flex;gap:20px;align-items: center;">
                <div style="display: inline-flex;align-items: center;">
                    <img *ngIf="sections_title_icon && sections_title_icon !=''" [src]="sections_title_icon" style="width: 20px;margin-right: 5px;
                height: 20px;" />

                    <div [ngClass]="!sections_title_icon || sections_title_icon =='' ?'highlighted-title':''"
                        class=" subTitle Quicksand-bold">

                        Our Channel Selection </div>
                </div>
            </div>
            <div *ngIf="is_admin && is_admin=='true'" class="show-all smallText Quicksand-semibold"><i
                    class="fa fa-pencil" style="color: white;cursor: pointer;" (click)="updateSelectedChannel()"></i>
            </div>

        </div>

        <div class="padding-div"></div>
    </div>
    <div *ngIf="channel && channel != null " class="selected-channel-container">
        <div class="cover" style="width: 100% !important ;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-height: 60vh">
            <div class="cover-diapo" [lazyLoad]="channel.cover_url">
                <div class="cover-content" style="justify-content: space-between;padding: 0px 130px;">
                    <div class="upcoming-events-content">
                        <div class="upcoming-events-paraghraphe">
                            <div class="event-title bigTitle Quicksand-semibold line-limit">{{channel.title |
                                titlecase}}
                            </div>
                            <div *ngIf="showPlaylistBtn" class="event-type paragraphe Quicksand-semibold "
                                style="z-index: 2000;" (click)="gotToEvent()">{{channel.type | titlecase}}
                            </div>
                        </div>
                    </div>
                    <ngx-spinner style="width: 100% !important;height: 60vh; position: relative;
                    height: 60vh;
                    top: -30px;" [name]="'playlistSpinner'" bdColor="rgb(147 149 152 / 0%)" size="default"
                        color="#8fa7e6" type="ball-spin-clockwise" [fullScreen]="false"> </ngx-spinner>
                </div>
                <div *ngIf="!loading" id="channel-container" class="channel-container" #selectedChannel
                    (mousedown)="startDragging($event, false, selectedChannel)" (mouseup)="stopDragging($event, false)"
                    (mouseleave)="stopDragging($event, false)" (mousemove)="moveEvent($event, selectedChannel)">
                    <div *ngFor="let session of channel.sessions;let i=index" [id]="'videoCard'+i"
                        class="video-wrapper">
                        <div style="width: 385px;
                        height: 218px;
                        position: absolute;
                        overflow: hidden;
                        border-radius: 10px;">
                            <video #video
                                *ngIf="session.cover_url && session.cover_url.split('.')[ session.cover_url.split('.').length-1]=='mp4'"
                                [id]="session._id" [muted]="'muted'">
                                <source [src]="session.cover_url" type="video/mp4">
                            </video>
                            <div [id]="'image'+session._id"
                                *ngIf="session.cover_url && session.cover_url.split('.')[ session.cover_url.split('.').length-1]!='mp4'"
                                [lazyLoad]="session.cover_url" [defaultImage]="defaultImage"
                                (onStateChange)="onStateChange($event,i)" class="session-cover">
                            </div>
                        </div>

                        <div class="content-video" (mouseenter)="playVideo(session._id ,true)"
                            (mouseleave)="playVideo(session._id ,false)">
                            <div class="header-btn">
                                <div class=" duration tagsText Quicksand-regular">
                                    {{session.duration}}
                                </div>
                            </div>
                            <div style="display: flex;justify-content: center;height: 100%;" (click)="openVideo(i)">
                                <div [id]="'whitePlay'+session._id" class="play-icon">
                                </div>
                                <div [id]="'redPlay'+session._id" class="play-icon-hover">
                                </div>
                            </div>
                            <div>
                                <div class="session-video-info">
                                    <div class="smallText Quicksand-bold  "
                                        style="text-align: start;margin-bottom: 5px">
                                        {{session.title}}
                                    </div>

                                </div>
                                <div class="session-video-footer">
                                    <div *ngIf="session.speakers" class="session-speakers"
                                        style="align-items: flex-start;"
                                        [style.left.px]=" -15*(session.speakers.length-1) ">
                                        <div *ngFor="let speaker of session.speakers;let i = index"
                                            style="display: inline-flex;" (click)="gotoProfile(speaker)">
                                            <div *ngIf="i<=2" [style.background-image]="'url('+speaker.photo+')'"
                                                class="speaker" [style.left.px]=" 15*(session.speakers.length-1-i) ">
                                                <div *ngIf="session.speakers.length>3 && i==2"
                                                    [style.background-image]="'url('+speaker.photo+')'"
                                                    class="last-speaker"
                                                    [style.left.px]=" 15*(session.speakers.length-1-i) ">
                                                </div>
                                            </div>
                                            <div *ngIf="session.speakers.length>3 && i==2" class="tagsText" style="
                                            font-family:Quicksand-regular ;
                                            align-self: flex-end;   
                                            position: relative;
                                            left: -25px;
                                            top: -6px;">
                                                +{{session.speakers.length-i}}</div>
                                        </div>
                                    </div>
                                    <!-- <div class="session-video-tags" #tags
                                        (mousedown)="startDraggingTags($event, false, tags)"
                                        (mouseup)="stopDraggingTags($event, false)"
                                        (mouseleave)="stopDraggingTags($event, false)"
                                        (mousemove)="moveEventTags($event, tags)">
                                        <div *ngFor="let tag of session.tags; let i= index">
                                            <div *ngIf="i<4" class="tag tagsText Quicksand-light"
                                                style="height: 25px;align-self: center;">
                                                <div>{{tag}}</div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="showMore-btn" (click)="showMore(session._id)">
                                        <div class="tagsText showMore-text Quicksand-bold">Show More
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<!-- <div *ngIf="show && selectedSpeaker._id == speaker._id " class="speaker-info"
    style="visibility: visible">
    <div class="speaker-header">
        <div class="speaker-img" [style.background-image]="'url('+selectedSpeaker.photo+')'">
        </div>
        <div class="speaker-details">
            <div class="subTitle Quicksand-medium">
                {{selectedSpeaker.firstname | titlecase}}
                {{selectedSpeaker.lastname | titlecase}}
            </div>
            <div *ngIf="selectedSpeaker.title" class="smallText Quicksand-medium">
                {{selectedSpeaker.title}}
            </div>
            <div *ngIf="selectedSpeaker.linkedin" class="tagsText Quicksand-medium">
                ({{selectedSpeaker.linkedin}})

            </div>
        </div>
    </div>
    <hr class="hr-speaker">
    <div *ngIf="selectedSpeaker.bio" class="paragraphe line-limit" style="height:80px ;">{{selectedSpeaker.bio}}
    </div>
    <div *ngIf="!selectedSpeaker.bio" class="paragraphe line-limit" style="height:80px ;">
    </div>
    <div class="buttons">
        <div class="watch-btn readmore-btn">Read More</div>
        <div class="watch-btn follow-btn">Follow</div>
    </div>
</div> -->