import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PortalEventComponent } from './portal-event/portal-event.component';
import { PortalComponent } from './portal.component';
import { PortalService } from './portal.service';
import { UserService } from './../services/user.service';
import { EventSessionComponent } from './event-session/event-session.component';
import { EventChannelComponent } from './event-channel/event-channel.component';

const routes: Routes = [
 
  {
    path: "",
    // component: PortalEventComponent,
    
    data: {
        title: "portalevent",
    },
    children: [
        {
          path: "hybridevent/:eventid",
          component: PortalEventComponent,
          resolve: { PortalService,UserService },
          data: {
              title: "Portal Event"
          },
        },
        {
          path: "sessionevent/:eventid",
          component: EventSessionComponent,
          resolve: { PortalService,UserService },
          data: {
              title: "Session Event"
          },
        },
        {
          path: "channelevent/:eventid",
          component: EventChannelComponent,
          resolve: { PortalService,UserService },
          data: {
              title: "Channel Event"
          },
        },
    ],
  },
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PortalRoutingModule { }
