import { Component } from '@angular/core';
import { config } from 'src/config/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Portal';
  constructor(){
    var link:HTMLLinkElement  = document.querySelector("link[rel~='icon']");
    var title = document.createElement('title');
     if (config.platform_name)
    {   title.innerHTML= config.platform_name;}
    document.getElementsByTagName('head')[0].appendChild(title);

   if (!link) {
     link = document.createElement('link');
     link.rel = 'icon';
     document.getElementsByTagName('head')[0].appendChild(link);
   }
    if (config.favicon)
    { link.href = config.favicon;}
  }
}
