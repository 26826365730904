import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewChildren } from "@angular/core";
import * as lodash from 'lodash';
import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from "src/app/services/data.service";
import { PortalService } from "../portal.service";
import { BehaviorSubject, interval } from "rxjs";
import { UserService } from "src/app/services/user.service";
import { AuthentificationSystemService } from "src/app/authentification-system/authentification-system.service";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { SharedComponentJoinEventComponent } from "src/app/shared-component-join-event/shared-component-join-event.component";
import { CookieService } from "ngx-cookie-service";
const CountdownTimeUnits: Array<[string, number]> = [
  ['Y', 1000 * 60 * 60 * 24 * 365], // years
  ['Mo', 1000 * 60 * 60 * 24 * 30], // months
  ['D', 1000 * 60 * 60 * 24], // days
  ['H', 1000 * 60 * 60], // hours
  ['M', 1000 * 60], // minutes
  ['S', 1000], // seconds
  // ['S', 1], // million seconds
];
@Component({
  selector: 'app-portal-event',
  templateUrl: './portal-event.component.html',
  styleUrls: ['./portal-event.component.css'],
})
export class PortalEventComponent implements OnInit, OnChanges {
  @ViewChild('stickyMenu') menuElement: ElementRef;
  // @ViewChildren('video') video: any;


  @Input() edit_event
  @Input() event_title
  @Input() event_type
  @Input() event_short_summary
  @Input() event_start_date
  @Input() event_end_date
  @Input() event_utcoffset
  @Input() event_tags
  @Input() event_cover_url
  @Input() event_description
  event
  hour
  seconds
  day
  year
  month
  speakers
  total_speakers
  exhibitors
  sessions
  eventID;
  accessEvent;
  queryParams
  currentButton = "descBtn";
  sticky: boolean = false;
  scrollBody: boolean = true;
  tabsScroll: boolean = true;
  menuPosition: any;
  windowScroll: any;
  activateTabSession: boolean = false;
  activateTabSpeaker: boolean = false;
  activateTabExhibitor: boolean = false;
  activateTabDescription: boolean = false;
  loading: boolean = true;
  myDecodedToken
  public scroll = new BehaviorSubject(true);
  currentscroll = this.scroll.asObservable();
  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  message = ''
  join: boolean = false;
  register: boolean = false;
  countdown: boolean = false;
  invited: boolean = false;
  joinBtn = "REGISTER"
  user: any;
  haveAccess: boolean = false;
  difftimeinsec = 0;
  moreThan24Hours;
  duration = 0;
  sameday = false
  value = "5"
  currentHeight
  show: boolean;
  rooms: any = [];
  readmore: boolean = true;
  rightArrowValue: number;
  timerRooms;
  event_as_agency: boolean = false;
  cover: string;
  overflowActivated: boolean = false
  myRooms: any = [];
  myDays: any = [];
  availableSessions: any[];
  total_Fsessions: any;
  selectedVideo: any;
  currentDate
  more_sessions = { page: '1', limit: '4' };
  constructor(public portaleventservice: PortalService, private router: Router, private route: ActivatedRoute, public dialog: NbDialogService,
    public dataService: DataService, private authService: AuthentificationSystemService, private userService: UserService, private cookieService: CookieService,
    public changeDetectorRef: ChangeDetectorRef, private toastrService: NbToastrService) {
  }
  ngOnInit() {

    this.route.params.subscribe((params) => {
      this.eventID = params['eventid'];
      this.dataService.changeEventId(this.eventID);
    });
    this.route.queryParams.subscribe((queryParams) => {
      this.queryParams = queryParams
    });
    this.portaleventservice.onChangedPublicEventById.subscribe((res) => {
      if (res) {



        let time = this.convertDate(res.start_date, res.end_date, res.utcoffset)[0].split(" ")
        let t = time[1] + " " + time[0] + ", at " + time[2]
        if (!res.cover_url) {
          this.cover = "../../../assets/portal/cover.png"
        }
        else {
          this.cover = res.cover_url
        }
        let data = {
          cover_url: this.cover,
          title: res.title,
          start_date: t,
          startDate: res.start_date,
          utcoffset: res.utcoffset,
          is_inviteonly: res.is_inviteonly,
          pass: res.pass,
          days: res.days,
          status: res.status,
          _id: res._id
        }
        this.dataService.getEvent(data);
        this.event = res
        this.dataService.changeEventStatus(this.event.status)
        this.accessEvent = JSON.parse(this.event.access);
        this.dataService.getAccess(this.accessEvent)
        this.convertDate(this.event.start_date, this.event.end_date, this.event.utcoffset)
        this.userService.onChangedUser.subscribe((res) => {
          if (res && res._id != null) {
            this.authService.currentUser = res
            if (this.cookieService.get("usertype") == "User") {
              this.verifyUserAccess();
              return;
            }
            else if (this.cookieService.get("usertype") == "temporaryUser") {
              this.dataService.temporaryUserCurrentEmail = res.email
              this.verifyTemporaryUserAccess()
            }
          }
          else {
            
            if (this.event.status == "launched") {
              if (this.accessEvent.access == "open" || this.accessEvent.access == "protected_password" || ((this.accessEvent.access == "auth" || this.accessEvent.access == "email") && this.accessEvent.protected == "false")) {
                this.countdown = true
                this.register = false
              }
              else {
                this.countdown = false
                this.register = true
              }
            }
            else if (this.event.status == "live") {
              if (this.accessEvent.access == "open" || this.accessEvent.access == "protected_password" || ((this.accessEvent.access == "auth" || this.accessEvent.access == "email") && this.accessEvent.protected == "false")) {
                this.joinBtn = "JOIN EVENT"
              }
              else {
                this.joinBtn = "REGISTER"
              }
              this.countdown = false
              this.register = false
              this.event.status = "live"
              this.dataService.changeEventStatus("live")
            }
          }
        })
        /**********************ckeck user status********************* */
        // this.verifyUser();
        this.countDown()
        this.onPathChanged();
        this.dataService.subjectNotifier.subscribe((notif) => {
          if (notif == "logout") {
            this.join = false
            this.haveAccess = false;
            if (this.event.status == "launched") {
              if (this.accessEvent.access == "open" || this.accessEvent.access == "protected_password" || ((this.accessEvent.access == "auth" || this.accessEvent.access == "email") && this.accessEvent.protected == "false")) {
                this.countdown = true
                this.register = false
              }
              else {
                this.countdown = false
                this.register = true
              }
            }
            else if (this.event.status == "live") {
              if (this.accessEvent.access == "open" || this.accessEvent.access == "protected_password" || this.accessEvent.access == "email" || (this.accessEvent.access == "auth" && this.accessEvent.protected == "false")) {
                this.accessEvent.access == "JOIN EVENT"
              }
              else {
                this.joinBtn = "REGISTER"
              }
            }
          }
          else if (notif == "login") {            
            if (this.cookieService.get("usertype") == "User") { this.verifyUserAccess(); }
            else {
              this.verifyTemporaryUserAccess();
            }
          }
        })
      }
    })
  }
  ngOnChanges(): void {

    if (this.event && this.edit_event && this.edit_event == "true") {
      if (this.event_title) { this.event.title = this.event_title }
      if (this.event_start_date) { this.event.start_date = this.event_start_date }
      if (this.event_end_date) { this.event.end_date = this.event_end_date }
      if (this.event_utcoffset) { this.event.utcoffset = this.event_utcoffset }
      if (this.event_tags) { this.event.tags = this.event_tags }
      if (this.event_cover_url) { this.cover = this.event_cover_url }
      if (this.event_description) { this.event.description = this.event_description }
      if (this.event_type) { this.event.type = this.event_type }
    }
  }
  ngAfterViewInit() {
    if (document.getElementById('event-infos') && document.getElementById('tags') && document.getElementById('tags').style && document.getElementById('event-infos').offsetWidth) {
      document.getElementById('tags').style.maxWidth = (document.getElementById('event-infos').offsetWidth).toString() + 'px'
    }
    if (document.getElementById(this.currentButton)) {
      document.getElementById(this.currentButton).style.background = "linear-gradient(#fff 0px, #fff 0px) left bottom / 51% 4px no-repeat";
    }
    if (this.menuElement && this.menuElement.nativeElement) {
      this.menuPosition = this.menuElement.nativeElement.offsetTop
    } if (document.getElementById("tags") && document.getElementById("event-tags-container")) {
      if (document.getElementById("tags").scrollWidth > document.getElementById("event-tags-container").offsetWidth) {
        this.overflowActivated = true
      }
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (document.getElementById('event-infos') && document.getElementById('tags') && document.getElementById('tags').style && document.getElementById('event-infos').offsetWidth) {
      document.getElementById('tags').style.maxWidth = (document.getElementById('event-infos').offsetWidth).toString() + 'px'
    }
    if (document.getElementById("tags") && document.getElementById("event-tags-container")) {
      if (document.getElementById("tags").scrollWidth > document.getElementById("event-tags-container").offsetWidth) {
        this.overflowActivated = true
      }
      else {
        this.overflowActivated = false
        this.rightArrowValue = 0
      }
    }
  }
  @HostListener('window:scroll', ['$event'])
  handleScroll(event) {
    this.dataService.currentHeight.subscribe(height => {
      this.currentHeight = height
    })
    this.currentscroll.subscribe(value => {
      this.scrollBody = value
    })
    if (this.scrollBody) {
      this.windowScroll = window.pageYOffset;
    }
    else {
      window.scroll(0, this.menuPosition - this.currentHeight);
      this.changeScroll(true);
    }
    if (this.windowScroll >= this.menuPosition - this.currentHeight) {
      this.sticky = true;
      this.dataService.changeNavBarColor("#121212")
    }
    else {
      this.dataService.changeNavBarColor("#1212122b")
      this.sticky = false;
    }
  }
  changeScroll(value) {
    this.scroll.next(value)
  }
  onPathChanged() {
    if (this.queryParams && this.queryParams.filter == "sessions") {
      this.portaleventservice.getSessionsByEventId().then((res: any) => {
        this.rooms = res;
        this.myRooms = []
        this.myDays = []
        res.forEach(room => {
          let session: any = []
          session = room.sessions.filter(session => session.day_index == 0)
          if (session.length > 0) {
            this.myRooms.push(room)
          }
        });
        for (let i = 0; i < this.event.days.length; i++) {
          let daysArray = []
          const element = this.event.days[i];
          res.forEach(room => {
            let day = room.sessions.filter(session => session.day_index == i)
            if (day != null && day.length > 0) {
              daysArray.push(day)
            }
          });
          if (daysArray != null && daysArray.length > 0) {
            this.myDays.push({ day: element, visible: true })
          }
          else {
            this.myDays.push({ day: element, visible: false })
          }
        }
        this.availableSessions = []
        this.more_sessions.page = "1"
        this.portaleventservice.getMoreFinishedSessions(this.more_sessions, "").then((res: any) => {
          if (res) {
            this.availableSessions = res.sessions
            this.total_Fsessions = res.total
          }
          this.activateTabSession = true;
        })
      })
      this.currentButton = "sessionBtn";
    }
    else if (this.queryParams && this.queryParams.filter == "speakers") {
      this.portaleventservice.getSpeakersByEventId().then((res: any) => {
        this.speakers = res.speakers;
        this.total_speakers = res.total;
        this.activateTabSpeaker = true;
      })
      this.currentButton = "speakerBtn";
    }
    else if (this.queryParams && this.queryParams.filter == "exhibitors") {
      this.portaleventservice.getExhibitorsByEventId().then((res) => {
        this.exhibitors = res;
        this.activateTabExhibitor = true;
      })
      this.currentButton = "exhibitorBtn";
    }
    else {
      this.currentButton = "descBtn";
      this.activateTabDescription = true;
    }
  }
  diffBetweenTwoDates(startdate, enddate) {
    let startevent = new Date(startdate)
    let endevent = new Date(enddate)
    let date1 = new Date(startevent.getFullYear(), startevent.getMonth(), startevent.getDate(), startevent.getHours(), startevent.getMinutes(), startevent.getSeconds())
    let date2 = new Date(endevent.getFullYear(), endevent.getMonth(), endevent.getDate(), endevent.getHours(), endevent.getMinutes(), endevent.getSeconds())
    let today = new Date();
    let difftime = (((date2.getTime()) - date1.getTime()) / 1000)
    return difftime;
  }
  startTimer(seconds: number, startdate) {
    const time = seconds;
    let startevent = new Date(startdate)
    let today = new Date();
    let timezone = this.event.utcoffset
    startevent.setHours(startevent.getHours() - Number(timezone));
    let difftime = (today.getTime() - startevent.getTime()) / 1000
    const timer$ = interval(1000);
  }
  getWindowScroll(event) {
    this.windowScroll = event
    if (this.windowScroll >= this.menuPosition - 75) {
      this.sticky = true;
    }
  }
  // playVideo(id, play) {
  //   let div = lodash.find(this.video.toArray(), { 'nativeElement': document.getElementById(id) });
  //   if (div) {
  //     if (play) {
  //       this.selectedVideo = id
  //       div.nativeElement.play()
  //       document.getElementById(id).classList.add("hover")
  //       document.getElementById(id).classList.remove("notHover")
  //       document.getElementById("whitePlay" + id).style.visibility = "hidden"
  //       document.getElementById("whitePlay" + id).style.position = "absolute !important"
  //       document.getElementById("redPlay" + id).style.visibility = "visible"
  //       document.getElementById("redPlay" + id).style.position = "relative !important"
  //     }
  //     else {
  //       this.selectedVideo = ""
  //       div.nativeElement.pause()
  //       document.getElementById(id).classList.remove("hover")
  //       document.getElementById(id).classList.add("notHover")
  //       document.getElementById("whitePlay" + id).style.visibility = "visible"
  //       document.getElementById("whitePlay" + id).style.position = "relative !important"
  //       document.getElementById("redPlay" + id).style.visibility = "hidden"
  //       document.getElementById("redPlay" + id).style.position = "absollute !important"
  //     }
  //   }
  //   else {
  //     let divImage = document.getElementById("image" + id)
  //     if (divImage) {
  //       if (play) {
  //         this.selectedVideo = id
  //         divImage.classList.add("hover")
  //         divImage.classList.remove("notHover")
  //         document.getElementById("whitePlay" + id).style.visibility = "hidden"
  //         document.getElementById("whitePlay" + id).style.position = "absolute !important"
  //         document.getElementById("redPlay" + id).style.visibility = "visible"
  //         document.getElementById("redPlay" + id).style.position = "relative !important"
  //       }
  //       else {
  //         this.selectedVideo = ""
  //         divImage.classList.remove("hover")
  //         divImage.classList.add("notHover")
  //         document.getElementById("whitePlay" + id).style.visibility = "visible"
  //         document.getElementById("whitePlay" + id).style.position = "relative !important"
  //         document.getElementById("redPlay" + id).style.visibility = "hidden"
  //         document.getElementById("redPlay" + id).style.position = "absollute !important"
  //       }
  //     }
  //   }
  // }
  countDown() {
    let startevent = new Date(this.event.start_date)
    let today = new Date();
    let timezone = this.event.utcoffset
    if (!timezone.includes('.')) {
      startevent.setHours(startevent.getHours() - Number(timezone));
    }
    else if (timezone.includes('.')) {
      startevent.setHours(startevent.getHours() - Number(timezone.split('.')[0]));
      if (Number(timezone.split('.')[1]) != 5) {
        startevent.setMinutes(startevent.getMinutes() - Number(timezone.split('.')[1]));
      }
      else if (Number(timezone.split('.')[1]) == 5) {
        startevent.setMinutes(startevent.getMinutes() - 30);
      }
    }
    let difftime = ((startevent.getTime() - (today.getTime() + (today.getTimezoneOffset() * 60))) / 1000)
    let formatDate;
    var d = Math.floor(difftime / (3600 * 24));
    if (d == 0) formatDate = "HHh:MMm:SSs"
    else formatDate = "Dd:HHh:MMm:SSs"
    this.moreThan24Hours = {
      leftTime: difftime,
      format: formatDate,
      formatDate: ({ date, formatStr }) => {
        this.duration = Number(date || 0);
        return CountdownTimeUnits.reduce((current, [name, unit]) => {
          if (current && current.indexOf(name) !== -1) {
            const v = Math.floor(this.duration / unit);
            this.duration -= v * unit;
            return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
              return v.toString().padStart(match.length, '0');
            });
          }
          if (this.event.status == "launched" && this.duration == 0) {
            this.event.status = "live"
            if (this.cookieService.get("usertype") == "User") {

              this.verifyUserAccess();
            }
            else if (this.cookieService.get("usertype") == "temporaryUser") {
              this.verifyTemporaryUserAccess()
            }
          }
          return current;
        }, formatStr);
      },
    };
  }
  async verifyUserAccess() {
    let event_as_attendee;
    let events_as_exhibitor;
    let event_as_speaker;
    let event_as_agency
    if (this.authService.currentUser && this.authService.currentUser._id) {
      if (this.authService.currentUser.status == "verified")
        this.dataService.getUserStatus("verified")
      else
        this.dataService.getUserStatus("not verified")


      if (this.cookieService.get("usertype") == "User") {
        if (this.authService.currentUser.role.includes("agency")) {
          // this.portaleventservice.getRoleByEventid().then((res: any) => {
          this.userService.onChangedRoleEventById.subscribe((res: any) => {
            if (res && res.is_agency) {
              event_as_agency = true;
              this.show = true
              this.toDoAfterVerifyAccess(event_as_agency)
            }
            // else {
            //   if (this.authService.currentUser.role.includes("attendee")) {
            //     event_as_attendee = lodash.find(this.authService.currentUser.events_as_attendee, { 'event_id': this.eventID });
            //     if (event_as_attendee && event_as_attendee.pass && event_as_attendee.pass.name) {
            //       this.message = event_as_attendee.pass.name;
            //     }
            //     else {
            //       this.message = ''
            //       if (this.event.is_inviteonly && this.authService.currentUser.status == "verified")
            //         this.show = true
            //       else if (this.event.is_inviteonly && this.authService.currentUser.status != "verified")
            //         this.show = false
            //       else if (!this.event.is_inviteonly)
            //         this.show = true
            //     }
            //   }
            //   else if (this.authService.currentUser.role.includes("exhibitor")) {
            //     events_as_exhibitor = lodash.find(this.authService.currentUser.events_as_exhibitor, { 'event_id': this.eventID });
            //   }
            //   else if (this.authService.currentUser.role.includes("speaker")) {
            //     event_as_speaker = lodash.find(this.authService.currentUser.events_as_speaker, { 'event_id': this.eventID });
            //   }
            //   this.toDoAfterVerifyAccess(event_as_attendee || event_as_speaker || events_as_exhibitor)
            // }
          })
        }
        else {
          if (this.authService.currentUser.role.includes("attendee")) {
            event_as_attendee = lodash.find(this.authService.currentUser.events_as_attendee, { 'event_id': this.eventID });
            if (event_as_attendee && event_as_attendee.pass && event_as_attendee.pass.name) {
              this.message = event_as_attendee.pass.name;
            }
            else {
              this.message = ''
              if (this.event.is_inviteonly && this.authService.currentUser.status == "verified")
                this.show = true
              else if (this.event.is_inviteonly && this.authService.currentUser.status != "verified")
                this.show = false
              else if (!this.event.is_inviteonly)
                this.show = true
            }
          }
          else if (this.authService.currentUser.role.includes("exhibitor")) {
            events_as_exhibitor = lodash.find(this.authService.currentUser.events_as_exhibitor, { 'event_id': this.eventID });
          }
          else if (this.authService.currentUser.role.includes("speaker")) {
            event_as_speaker = lodash.find(this.authService.currentUser.events_as_speaker, { 'event_id': this.eventID });
          }
          this.toDoAfterVerifyAccess(event_as_agency || event_as_attendee || event_as_speaker || events_as_exhibitor)
        }
      }
      else {
        event_as_attendee = lodash.find(this.authService.currentUser.events, { 'event_id': this.eventID });
        this.toDoAfterVerifyAccess(event_as_agency || event_as_attendee || event_as_speaker || events_as_exhibitor)
      }
    }
  }
  toDoAfterVerifyAccess(hasaccess) {
    if (this.cookieService.get("token")) {
      if (hasaccess) {

        this.invited = true
        if (this.event.status == "launched") {

          this.haveAccess = true
          this.countdown = true
          this.register = false
        }
        else if (this.event.status == "live") {

          this.join = true;
          this.joinBtn = "JOIN EVENT"
          this.event.status = "live"
          this.dataService.changeEventStatus("live")
          this.register = false
          this.countdown = false
        }
      }
      else {
        this.invited = false
        if (this.event.status == "launched") {
          if (this.accessEvent.access == "open" || this.accessEvent.access == "protected_password") {
            this.register = false
            this.countdown = true
          }
          else {
            this.register = true
            this.countdown = false
          }
        }
        else if (this.event.status == "live") {
          if (this.event.pass) {
            this.join = false;
            this.event.status = "live"
            this.dataService.changeEventStatus("live")
            this.joinBtn = "REGISTER"
            this.countdown = false
          }
          else {
            this.join = false;
            this.event.status = "live"
            this.dataService.changeEventStatus("live")
            this.joinBtn = "JOIN EVENT"
            this.countdown = false
          }
        }
      }
    }
  }
  verifyTemporaryUserAccess() {
    this.userService.onChangedUser
      .subscribe((res1: any) => {
        let event = lodash.find(res1.events, { 'event_id': this.eventID });
        if (event) {
          if (this.event.is_inviteonly) {
            this.invited = true
            if (this.cookieService.get('is_verified') == "true") {
              this.dataService.getUserStatus("verified")
            }
            else { this.dataService.getUserStatus("not verified") }
            if (event.pass && event.pass.name) {
              this.message = event.pass.name
            }
            else {
              this.message = ''
              if (this.event.is_inviteonly && this.cookieService.get('is_verified') == "true")
                this.show = true
              else if (this.event.is_inviteonly && this.cookieService.get('is_verified') == "false")
                this.show = false
              else if (!this.event.is_inviteonly)
                this.show = true
            }
          }
          else {
            this.invited = true
            if (this.cookieService.get('is_verified') == "true") {
              this.dataService.getUserStatus("verified")
            }
            else {
              this.dataService.getUserStatus("not verified")
            }
            if (event.pass && event.pass.name) {
              this.message = event.pass.name
            }
            else {
              this.message = ''
              if (this.event.is_inviteonly && this.cookieService.get('is_verified') == "true")
                this.show = true
              else if (this.event.is_inviteonly && this.cookieService.get('is_verified') == "false")
                this.show = false
              else if (!this.event.is_inviteonly)
                this.show = true
            }
          }
          // if (this.event.status == "launched" && this.duration != 0) {
          //   this.countdown = true
          //   this.haveAccess = true
          //   this.register = false
          // }
          // else {            
          //   this.join = true;
          //   this.joinBtn = "JOIN EVENT"
          //   this.event.status = "live"
          //   this.dataService.changeEventStatus("live")
          //   this.register = false
          //   this.countdown = false
          // }
        }
        this.toDoAfterVerifyAccess(this.invited)

        // else {

        //   this.invited = false
        //   if (this.event.status == "launched" && this.duration != 0) {

        //     this.countdown = true;
        //     this.register = false;
        //     this.join = false;
        //   }
        //   else if (this.event.status == "live" || (this.event.status == "launched" && this.duration == 0)) {

        //     this.join = false;
        //     this.event.status = "live"
        //     this.dataService.changeEventStatus("live")
        //     this.joinBtn = "JOIN EVENT"
        //     this.countdown = false
        //   }
        // }
      })

  }
  onClickJoinEvent() {
    if (!this.event.is_inviteonly) {
      if (this.accessEvent.access == "open") {
        this.router.navigateByUrl("eventinterface/open/" + this.eventID);
      }
      else {
        if (this.join) {
          this.router.navigateByUrl("eventinterface/restricted/" + this.eventID);
        }
        else {
          if (this.cookieService.get("token") && this.cookieService.get("usertype") == "User") {
            if (this.accessEvent.access == "protected_password") {
              // if (this.cookieService.get("code")) {
              //   this.router.navigateByUrl("eventinterface/restricted/" + this.eventID);
              // }
              // else {
              this.changeDetectorRef.detach();
              const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
                context: {
                  data: {
                    eventid: this.eventID,
                    status: this.accessEvent.access,
                    showNavBar: true,
                    accessPopup: true
                  },
                },
                hasBackdrop: false
              }).onClose.subscribe((result) => {
                this.changeDetectorRef.reattach();
              });
              // }
            }
            //restricted with login or with email without pass 
            else if ((this.accessEvent.access == "auth" || this.accessEvent.access == "email") && this.accessEvent.protected == "false") {
              this.portaleventservice.verifyToJoinDashboardEvent("", this.eventID, this.accessEvent.access, this.authService.currentUser._id).then((res: any) => {
                if (res && res.status == "success") {
                  this.router.navigateByUrl("eventinterface/restricted/" + this.eventID);
                }
              })
            }
            //restricted with login or with email + (code or ticket or voucher)
            else if ((this.accessEvent.access == "auth" || this.accessEvent.access == "email") && this.accessEvent.protected == "true") {
              if (this.accessEvent.access == "email") {
                this.dataService.getAccessPopup("email")
              }
              if (this.event.pass.available) {
                this.changeDetectorRef.detach();
                const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
                  context: {
                    data: {
                      eventid: this.eventID,
                      status: "auth",
                      type: this.event.pass.type,
                      accessPopup: true
                    },
                  },
                  hasBackdrop: false
                }).onClose.subscribe((result) => {
                  this.changeDetectorRef.reattach();
                });
              }
              else {
                this.changeDetectorRef.detach();
                const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
                  context: {
                    data: {
                      eventid: this.eventID,
                      status: this.accessEvent.access,
                      type: this.event.pass.type,
                      soldOut: true,
                      accessPopup: true
                    },
                  },
                  hasBackdrop: false
                }).onClose.subscribe((result) => {
                  this.changeDetectorRef.reattach();
                });
              }
            }
          }
          else if (this.cookieService.get("token") && this.cookieService.get("usertype") == "temporaryUser" && this.accessEvent.access == "email") {
            this.dataService.temporaryUserCurrentEmail = this.authService.currentUser.email
            let event = lodash.find(this.authService.currentUser.events, { 'event_id': this.eventID });
            if (event) {
              this.router.navigateByUrl("eventinterface/restricted/" + this.eventID);
            }
            else {
              const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
                context: {
                  data: {
                    eventid: this.eventID,
                    email: this.authService.currentUser.email,
                    _id: this.authService.currentUser._id,
                    reuseEmailPopup: true
                  },
                },
                hasBackdrop: false
              }).onClose.subscribe((result) => {
              });
            }
          }

          else {
            if (this.accessEvent.access == "protected_password") {
              this.changeDetectorRef.detach();
              const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
                context: {
                  data: {
                    eventid: this.eventID,
                    status: this.accessEvent.access,
                    accessPopup: true
                  },
                },
                hasBackdrop: false
              }).onClose.subscribe((result) => {
                this.changeDetectorRef.reattach();
              });
            }
            else if ((this.accessEvent.access == "auth" || this.accessEvent.access == "email") && this.accessEvent.protected == "false") {
              if (this.accessEvent.access == "email") {
                this.dataService.getAccessPopup("email")
              }
              this.changeDetectorRef.detach();
              const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
                context: {
                  data: {
                    eventid: this.eventID,
                    status: this.accessEvent.access,
                    protected: this.accessEvent.protected,
                    authPopup: true
                    // showNavBar : true
                  },
                },
                hasBackdrop: false
              }).onClose.subscribe((result) => {
                this.changeDetectorRef.reattach();
              });
            }
            else if ((this.accessEvent.access == "auth" || this.accessEvent.access == "email") && this.accessEvent.protected == "true") {
              if (this.accessEvent.access == "email") {
                this.dataService.getAccessPopup("email")
              }
              if (this.event.pass.available) {
                this.changeDetectorRef.detach();
                const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
                  // height: '700px',
                  context: {
                    data: {
                      eventid: this.eventID,
                      status: this.accessEvent.access,
                      type: this.event.pass.type,
                      accessPopup: true
                    },
                  },
                  hasBackdrop: false
                }).onClose.subscribe((result) => {
                  this.changeDetectorRef.reattach();
                });
              }
              else {
                this.changeDetectorRef.detach();
                const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
                  context: {
                    data: {
                      eventid: this.eventID,
                      status: this.accessEvent.access,
                      type: this.event.pass.type,
                      soldOut: true,
                      accessPopup: true
                    },
                  },
                  hasBackdrop: false
                }).onClose.subscribe((result) => {
                  this.changeDetectorRef.reattach();
                });
              }
            }
          }
        }
      }
    }
    else {
      if (this.cookieService.get("token")) {
        let status;
        if (this.cookieService.get("usertype") == "User") {
          status = this.authService.currentUser.status

          if (status == "verified") {
            if (this.cookieService.get("usertype") == "User") {
              this.verifyUserAccess();
              setTimeout(() => {
                if (this.invited) {
                  this.router.navigateByUrl("eventinterface/restricted/" + this.eventID);
                }
                else {
                  this.portaleventservice.verifyInvitation(this.authService.currentUser.email, this.eventID).then((res: any) => {
                    if (res.status == "success") {
                      if (res.invitation_type == "invite_event") {
                        if (this.accessEvent && this.accessEvent.protected == "false") {
                          this.router.navigateByUrl("eventinterface/restricted/" + this.eventID);
                        }
                        else {
                          this.changeDetectorRef.detach();
                          const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
                            context: {
                              data: {
                                eventid: this.eventID,
                                status: "auth",
                                type: this.event.pass.type,
                                accessPopup: true
                              },
                            },
                            hasBackdrop: false
                          }).onClose.subscribe((result) => {
                            this.changeDetectorRef.reattach();
                          });
                        }
                      }
                      else if (res.invitation_type == "allocate_access_code") {
                        this.changeDetectorRef.detach();
                        const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
                          context: {
                            data:
                            {
                              code: true,
                              passes: true,
                              invited: true,
                              alertPopup: true
                            },
                          },
                          hasBackdrop: false
                        }).onClose.subscribe((result) => {
                          this.changeDetectorRef.reattach();
                        });
                      }
                      else if (res.invitation_type == "allocate_ticket") {
                        this.changeDetectorRef.detach();
                        const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
                          context: {
                            data:
                            {
                              passes: true,
                              invited: true,
                              ticket: res.allocated_pass,
                              alertPopup: true
                            },
                          },
                          hasBackdrop: false
                        }).onClose.subscribe((result) => {
                          this.changeDetectorRef.reattach();
                        });
                      }
                    }
                    else if (res.status == "error") {
                      this.changeDetectorRef.detach();
                      const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
                        context: {
                          data:
                          {
                            passes: true,
                            invited: false,
                            alertPopup: true
                          },
                        },
                        hasBackdrop: false
                      }).onClose.subscribe((result) => {
                        this.changeDetectorRef.reattach();
                      });
                    }
                  })
                }
              }, 20);
            }
          }
          else {
            if (this.event.pass) {
              this.changeDetectorRef.detach();
              const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
                context: {
                  data: {
                    invited: true,
                    email: this.authService.currentUser.email,
                    verif: this.accessEvent.access,
                    type: this.event.pass.type,
                    verifPopup: true
                  },
                },
                hasBackdrop: false
              }).onClose.subscribe((result) => {
                this.changeDetectorRef.reattach();
              });
            }
            else {
              this.changeDetectorRef.detach();
              const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
                context: {
                  data: {
                    invited: true,
                    email: this.authService.currentUser.email,
                    verif: this.accessEvent.access,
                    verifPopup: true
                    // type: this.event.pass.type
                  },
                },
                hasBackdrop: false
              }).onClose.subscribe((result) => {
                this.changeDetectorRef.reattach();
              });
            }
          }
        }
        else if (this.cookieService.get("usertype") == "temporaryUser" && this.accessEvent.access == "email") {
          this.dataService.temporaryUserCurrentEmail = this.authService.currentUser.email
          if (this.cookieService.get('is_verified') == "true") {
            status = "verified"
          }
          else {
            status = "not verified"
          }
          let event = lodash.find(this.authService.currentUser.events, { 'event_id': this.eventID });
          if (event) {
            this.router.navigateByUrl("eventinterface/restricted/" + this.eventID);
          }
          else {
            const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
              context: {
                data: {
                  eventid: this.eventID,
                  email: this.authService.currentUser.email,
                  status: status,
                  _id: this.authService.currentUser._id,
                  reuseEmailPopup: true
                },
              },
              hasBackdrop: false
            }).onClose.subscribe((result) => {
            });
          }
        }
        else if (this.cookieService.get("usertype") == "temporaryUser" && this.accessEvent.access != "email") {
          if (this.event.pass) {
            this.changeDetectorRef.detach();
            const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
              context: {
                data: {
                  eventid: this.eventID,
                  status: this.accessEvent.access,
                  protected: this.accessEvent.protected,
                  // is_inviteonly: true,
                  type: this.event.pass.type,
                  authPopup: true
                },
              },
              hasBackdrop: false
            }).onClose.subscribe((result) => {
              this.changeDetectorRef.reattach();
            });
          }
          else {
            this.changeDetectorRef.detach();
            const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
              context: {
                data: {
                  eventid: this.eventID,
                  status: this.accessEvent.access,
                  protected: this.accessEvent.protected,
                  // is_inviteonly: true,
                  authPopup: true
                },
              },
              hasBackdrop: false
            }).onClose.subscribe((result) => {
              this.changeDetectorRef.reattach();
            });
          }
        }
      }
      else {
        if (this.event.pass) {
          this.changeDetectorRef.detach();
          const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
            context: {
              data: {
                eventid: this.eventID,
                status: this.accessEvent.access,
                protected: this.accessEvent.protected,
                // is_inviteonly: true,
                type: this.event.pass.type,
                authPopup: true
              },
            },
            hasBackdrop: false
          }).onClose.subscribe((result) => {
            this.changeDetectorRef.reattach();
          });
        }
        else {
          this.changeDetectorRef.detach();
          const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
            context: {
              data: {
                eventid: this.eventID,
                status: this.accessEvent.access,
                protected: this.accessEvent.protected,
                // is_inviteonly: true,
                authPopup: true
              },
            },
            hasBackdrop: false
          }).onClose.subscribe((result) => {
            this.changeDetectorRef.reattach();
          });
        }
      }
    }
  }
  onClickRegister() {
    if (!this.event.is_inviteonly) {
      if (this.cookieService.get("token")) {
        if (this.cookieService.get("usertype") == "User") {
          //restricted with login or with email + (code or ticket or voucher)
          if ((this.accessEvent.access == "auth" || this.accessEvent.access == "email") && this.accessEvent.protected == "true") {
            if (this.accessEvent.access == "email") {
              this.dataService.getAccessPopup("email")
            }
            this.changeDetectorRef.detach();
            const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
              context: {
                data: {
                  eventid: this.eventID,
                  status: "auth",
                  type: this.event.pass.type,
                  accessPopup: true
                },
              },
              hasBackdrop: false
            }).onClose.subscribe((result) => {
              this.changeDetectorRef.reattach();
            });
          }
        }
        else if (this.cookieService.get("usertype") == "temporaryUser") {
          this.changeDetectorRef.detach();
          const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
            context: {
              data: {
                eventid: this.eventID,
                status: this.accessEvent.access,
                type: this.event.pass.type,
                accessPopup: true
              },
            },
            hasBackdrop: false
          }).onClose.subscribe((result) => {
            this.changeDetectorRef.reattach();
          });
        }
      }
      else {
        //restricted with login or with email + (code or ticket or voucher)
        if ((this.accessEvent.access == "auth" || this.accessEvent.access == "email") && this.accessEvent.protected == "true") {
          if (this.accessEvent.access == "email") {
            this.dataService.getAccessPopup("email")
          }
          this.changeDetectorRef.detach();
          const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
            context: {
              data: {
                eventid: this.eventID,
                status: this.accessEvent.access,
                type: this.event.pass.type,
                accessPopup: true
              },
            },
            hasBackdrop: false
          }).onClose.subscribe((result) => {
            this.changeDetectorRef.reattach();
          });
        }
      }
    }
    else {
      if (this.cookieService.get("token")) {
        let status;
        if (this.cookieService.get("usertype") == "User") {
          status = this.authService.currentUser.status
        }
        else if (this.cookieService.get("usertype") == "temporaryUser") {
          if (this.cookieService.get('is_verified') == "true") {
            status = "verified"
          }
          else {
            status = "not verified"
          }
          // let event = lodash.find(this.authService.currentUser.events, { 'event_id': this.eventID });
          // if (event) {
          //   status = event.status
          // }
        }
        if (status == "verified") {
          if (this.cookieService.get("usertype") == "User") {
            this.verifyUserAccess();
          }
          else if (this.cookieService.get("usertype") == "temporaryUser") {
            this.verifyTemporaryUserAccess()
          }
          setTimeout(() => {
            if (this.invited) {
              this.router.navigateByUrl("eventinterface/restricted/" + this.eventID);
            }
            else {
              this.portaleventservice.verifyInvitation(this.authService.currentUser.email, this.eventID).then((res: any) => {
                if (res.status == "success") {
                  if (res.invitation_type == "invite_event") {
                    this.changeDetectorRef.detach();
                    const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
                      context: {
                        data: {
                          eventid: this.eventID,
                          status: "auth",
                          type: this.event.pass.type,
                          accessPopup: true
                        },
                      },
                      hasBackdrop: false
                    }).onClose.subscribe((result) => {
                      this.changeDetectorRef.reattach();
                    });
                  }
                  else if (res.invitation_type == "allocate_access_code") {
                    this.changeDetectorRef.detach();
                    const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
                      context: {
                        data:
                        {
                          code: true,
                          passes: true,
                          invited: true,
                          alertPopup: true
                        },
                      },
                      hasBackdrop: false
                    }).onClose.subscribe((result) => {
                      this.changeDetectorRef.reattach();
                    });
                  }
                  else if (res.invitation_type == "allocate_ticket") {
                    this.changeDetectorRef.detach();
                    const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
                      context: {
                        data:
                        {
                          passes: true,
                          invited: true,
                          ticket: res.allocated_pass,
                          alertPopup: true
                        },
                      },
                      hasBackdrop: false
                    }).onClose.subscribe((result) => {
                      this.changeDetectorRef.reattach();
                    });
                  }
                }
                else if (res.status == "error") {
                  this.changeDetectorRef.detach();
                  const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
                    context: {
                      data:
                      {
                        passes: true,
                        invited: false,
                        alertPopup: true
                      },
                    },
                    hasBackdrop: false
                  }).onClose.subscribe((result) => {
                    this.changeDetectorRef.reattach();
                  });
                }
              })
            }
          }, 20);
        }
        else {
          this.changeDetectorRef.detach();
          const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
            context: {
              data: {
                invited: true,
                email: this.authService.currentUser.email,
                verif: this.accessEvent.access,
                type: this.event.pass.type,
                verifPopup: true
              },
            },
            hasBackdrop: false
          }).onClose.subscribe((result) => {
            this.changeDetectorRef.reattach();
          });
        }
      }
      else {
        if (this.event.pass) {
          this.changeDetectorRef.detach();
          const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
            context: {
              data: {
                eventid: this.eventID,
                status: this.accessEvent.access,
                protected: this.accessEvent.protected,
                // is_inviteonly: true,
                type: this.event.pass.type,
                authPopup: true
              },
            },
            hasBackdrop: false
          }).onClose.subscribe((result) => {
            this.changeDetectorRef.reattach();
          });
        }
        else {
          this.changeDetectorRef.detach();
          const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
            context: {
              data: {
                eventid: this.eventID,
                status: this.accessEvent.access,
                protected: this.accessEvent.protected,
                // is_inviteonly: true,
                authPopup: true
              },
            },
            hasBackdrop: false
          }).onClose.subscribe((result) => {
            this.changeDetectorRef.reattach();
          });
        }
      }
    }
  }
  OnClickTab(id, tab) {
    if (this.sticky) {
      window.scroll(0, this.menuPosition - 75);
      this.changeScroll(false)
    }
    if (this.currentButton !== id) {
      this.activateTabSession = false;
      this.activateTabSpeaker = false;
      this.activateTabExhibitor = false;
      this.activateTabDescription = false;
      if (tab == "speaker") {
        this.router.navigate(["/portal/hybridevent/" + this.eventID],
          {
            queryParams: { filter: 'speakers' },
            queryParamsHandling: 'merge'
          }).then(res => {
            if (res == true) {
              this.portaleventservice.getMoreSpeakers("1").then((res1: any) => {
                this.speakers = res1.speakers;
                this.total_speakers = res1.total;
                this.activateTabSpeaker = true;
              })
            }
          })
      }
      else if (tab == "session") {
        this.router.navigate(["/portal/hybridevent/" + this.eventID],
          {
            queryParams: { filter: 'sessions' },
            queryParamsHandling: 'merge'
          }).then(res => {
            // if (res == true) {
            this.portaleventservice.getSessionsByEventId().then((res1: any) => {
              this.rooms = res1;
              this.myRooms = []
              res1.forEach(room => {
                let session: any = []
                session = room.sessions.filter(session => session.day_index == 0)
                if (session.length > 0) {
                  this.myRooms.push(room)
                }
              });
              this.myDays = []
              for (let i = 0; i < this.event.days.length; i++) {
                let daysArray = []
                const element = this.event.days[i];
                res1.forEach(room => {
                  let day = room.sessions.filter(session => session.day_index == i)
                  if (day != null && day.length > 0) {
                    daysArray.push(day)
                  }
                });
                if (daysArray != null && daysArray.length > 0) {
                  this.myDays.push({ day: element, visible: true })
                }
                else {
                  this.myDays.push({ day: element, visible: false })
                }
              }
              this.availableSessions = []
              this.more_sessions.page = "1"
              this.portaleventservice.getMoreFinishedSessions(this.more_sessions, "").then((res: any) => {
                if (res) {
                  this.availableSessions = res.sessions
                  this.total_Fsessions = res.total
                }
                this.activateTabSession = true;
              })
            })
            // }
          })
      }
      else if (tab == "exhibitor") {
        this.router.navigate(["/portal/hybridevent/" + this.eventID],
          {
            queryParams: { filter: 'exhibitors' },
            queryParamsHandling: 'merge'
          }).then(res => {
            if (res == true) {
              this.portaleventservice.getExhibitorsByEventId().then((res1) => {
                this.exhibitors = res1;
                this.activateTabExhibitor = true;
              })
            }
          })
        this.activateTabExhibitor = true;
      }
      else {
        this.activateTabDescription = true;
        this.router.navigate(["/portal/hybridevent/" + this.eventID],
          {
            queryParams: null,
          })
      }
      document.getElementById(id).style.background = "linear-gradient(#ffffff 0px, #8f9bb3 0px) left bottom / 51% 4px no-repeat";
      // document.getElementById(this.currentButton).style.background = "linear-gradient(#ffffff 0px, #ffffff00 0px) left bottom / 51% 4px no-repeat;";
      document.getElementById(this.currentButton).style.background = "transparent";
      this.currentButton = id;
    }
  }
  convertDate(startdate, enddate, utcoffset) {

    if (!startdate && !enddate) {

    } else {
      let starttime = this.convertTimeToAM_PM(startdate.split('T')[1].slice(0, 5));
      let endtime = this.convertTimeToAM_PM(enddate.split('T')[1].slice(0, 5));

      startdate = startdate.split('T')[0].split("-");
      enddate = enddate.split('T')[0].split("-");
      let newdate = ["", "", ""];
      newdate[0] = this.months[startdate[1] - 1].slice(0, 3) + " " + startdate[2].slice(0, 2) + " " + starttime
      if (startdate[2].slice(0, 2) == enddate[2].slice(0, 2)) {
        this.sameday = true
        newdate[1] = this.months[enddate[1] - 1].slice(0, 3) + " " + enddate[2].slice(0, 2) + " " + endtime
      }
      else {
        this.sameday = false
        newdate[1] = this.months[enddate[1] - 1].slice(0, 3) + " " + enddate[2].slice(0, 2) + " " + endtime
      }
      newdate[2] = this.convertUtCoffset(utcoffset)
      return newdate;
    }
  }
  convertTimeToAM_PM(time) {
    if (time) {
      let hour = time.split(':')[0]
      if (hour == '00') {
        return ("12:" + time.split(':')[1] + "am")
      }
      else if (Number(hour) == 12) {
        return ("12:" + time.split(':')[1] + "pm")
      }
      else if (Number(hour) > 12) {
        return (Number(hour - 12) + ":" + time.split(':')[1] + "pm")
      }
      else if (hour < 12) {
        if (hour < 10) {
          return (hour[1] + ":" + time.split(':')[1] + "am")
        }
        else {
          return (time + "am")
        }
      }
    }
  }
  convertUtCoffset(utcoffset) {
    let convertedUTC = ["", "", "+"]
    if (utcoffset) {
      if (utcoffset.includes('-')) {
        convertedUTC[2] = ''
      }
      let utc = utcoffset.split('.')
      convertedUTC[0] = utc[0]
      if (!utc[1]) {
        convertedUTC[1] = "00"
      }
      else if (utc[1].length == 1 && utc[1] != '5') {
        convertedUTC[1] = utc[1] + "0"
      }
      else if (utc[1].length == 1 && utc[1] == '5') {
        convertedUTC[1] = "30"
      }
      else if (utc[1].length == 2) {
        convertedUTC[1] = utc[1]
      }
      if (!utc[1]) {
        return convertedUTC[2] + convertedUTC[0];
      }
      else {
        return convertedUTC[2] + convertedUTC[0] + ":" + convertedUTC[1]
      }
    }
  }
  /******************* Convert the format of the date (used in event details card)  ***********************/
  splitDate(startdate, enddate) {
    if (!startdate && !enddate) {
    } else {
      startdate = startdate.split("-");
      enddate = enddate.split("-");
      let newdate: string;
      if (startdate[2].slice(0, 2) == enddate[2].slice(0, 2)) {
        newdate = startdate[2].slice(0, 2) + "/" + startdate[1] + "/" + startdate[0];
      } else {
        newdate = startdate[2].slice(0, 2) + "/" + startdate[1] + "-" + enddate[2].slice(0, 2) + "/" + enddate[1];
      }
      return newdate;
    }
  }
  showTicket() {
    if (this.cookieService.get("usertype") == "User") {
      var event_as_attendee = lodash.find(this.authService.currentUser.events_as_attendee, { 'event_id': this.dataService.eventid });
      var ticket = event_as_attendee.pass;
      this.changeDetectorRef.detach();
      const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
        context: {
          data:
          {
            eventid: this.dataService.eventid,
            ticket: ticket,
            passes: true,
            showTicket: true,
            alertPopup: true
          },
        },
        hasBackdrop: false
      }).onClose.subscribe((result) => {
        this.changeDetectorRef.reattach();
      });
    }
    else {
      var event_as_attendee = lodash.find(this.authService.currentUser.events, { 'event_id': this.dataService.eventid });
      var ticket = event_as_attendee.pass;
      this.changeDetectorRef.detach();
      const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
        context: {
          data:
          {
            eventid: this.dataService.eventid,
            ticket: ticket,
            passes: true,
            showTicket: true,
            alertPopup: true
          },
        },
        hasBackdrop: false
      }).onClose.subscribe((result) => {
        this.changeDetectorRef.reattach();
      });
    }
  }
  countLines(id) {
    var lines = 0
    var el = document.getElementById(id);
    if (el) {
      lines = el.offsetHeight / 19
    }
    return lines;
  }
  readMore() {
    var el_more = document.getElementById("event-short-desc1");
    var el_less = document.getElementById("event-short-desc2");
    if (this.readmore) {
      el_more.style.visibility = "visible"
      el_more.style.position = "relative";
      el_more.style.zIndex = "0";
      el_less.style.position = "absolute";
      el_less.style.visibility = "hidden";
      el_less.style.zIndex = "-1";
    }
    else {
      el_less.style.position = "relative";
      el_less.style.zIndex = "0";
      el_more.style.visibility = "hidden"
      el_less.style.visibility = "visible";
      el_more.style.position = "absolute";
      el_more.style.zIndex = "-1";
    }
    this.readmore = !this.readmore
  }
  hoverdiv(e, divid, visible) {
    let lines = 0
    var div = document.getElementById(divid);
    if (document.getElementById('event-title')) {
      lines = document.getElementById('event-title').offsetHeight / 45
    }
    if (document.getElementById('organizer')) {
      lines = document.getElementById('organizer').offsetHeight / 19
    }
    if (visible && lines >= 2) {
      var left = e.clientX + "px";
      var top = e.clientY + "px";
      div.style.visibility = "visible"
      div.style.left = left;
      div.style.top = top;
      return false;
    }
    else {
      div.style.visibility = "hidden"
    }
  }
  scrollDivRooms(elementToScroll: HTMLElement, depl, div, arrowId) {
    if (this.event.tags && this.event.tags.length > 0) {
      if (document.getElementById(arrowId) != null) {
        elementToScroll = document.getElementById("tags") as HTMLInputElement
        elementToScroll.scrollLeft -= depl;
        this.rightArrowValue = elementToScroll.scrollLeft
        this.timerRooms = setTimeout(() => {
          this.scrollDivRooms(elementToScroll, depl, div, arrowId)
        }, 30);
      }
    }
  }
  stopTimerRooms(timerRooms: number) {
    clearTimeout(timerRooms);
  }
}