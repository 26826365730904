import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { config } from 'src/config/config';
import { ExploreService } from '../explore.service';
// import * as lodash from 'lodash';

@Component({
  selector: 'app-edit-explore-popup',
  templateUrl: './edit-explore-popup.component.html',
  styleUrls: ['./edit-explore-popup.component.css', '../../explore-event/recommended-events/recommended-events.component.css']
})
export class EditExplorePopupComponent implements OnInit {
  @Input() data
  name
  constructor(public dialogRef: NbDialogRef<EditExplorePopupComponent>, private exploreservice: ExploreService) {


  }
  icons = []
  // icons = 
  // ["Physical_medicine_and_rehabilitation", "Cardiology",
  //   "Pediatrics", "dentistry",
  //   "Oncology-removebg-preview", "Auto-immune",
  //   "Psychiatry",
  //   "Radiology_and_medical_imaging",
  //   "Rheumatology",
  //   "Pneumology",
  //   "venereology",
  //   "Vascular_medicine",
  //   "Infectious_and_tropical_diseases-",
  //   "Intensive_medicine-resuscitation",
  //   "Medical_genetics",
  //   "Nuclear_medicine",
  //   "Neurology",
  //   "Nephrology",
  //   "Gynecology",
  //   "Internal_Medicine",
  //   "nutrition",
  //   "Forensic medicine and medical expertise",
  //   "Medical Biology",
  //   "Endocrinology",
  //   "Addictology",
  //   "Geriatrics",
  //   "Hematology",
  //   "Dermatologie",
  //   "Allergology",
  //   "Hepato-gastroenterology",
  //   "-diabetology"]
  selected_icon = []
  submitted = false
  name_error = false
  action = "add"
  categories = []
  events
  selected_recommendedevents = []
  selected_upcomingevents = []
  selected_channel
  speakers: any = []
  selected_speakers = []
  selectedItem = '2';

  testimonial1: any = { testimonial: "", speaker: "" }
  testimonial2: any = { testimonial: "", speaker: "" }
  testimonial3: any = { testimonial: "", speaker: "" }
  testimonial0: any = { testimonial: "", speaker: "" }
  testimonials: any = []
  ngOnInit(): void {
    if (this.data && this.data.name == "addcategory" && this.data.icons && this.data.icons.length > 0) {
      this.icons = this.data.icons
      this.categories = this.data.categories
    
      if (this.data.categories && this.data.categories.length > 0)  

        {
          this.data.categories.forEach(element => {
            let icon = element.icon.split('/categories/')[1]
            if (icon) {
              this.selected_icon.push(icon.split('.png')[0])
            }
          });
        }
      }
      else if (this.data && this.data.name == "updateRecommendedEvents") {
        this.events = this.data.events
        this.data.recommendedevents.forEach(element => {
          this.selected_recommendedevents.push(element._id)
        });
        // this.exploreservice.getLiveAndFinishedEvents().then((res: any) => {
        //   if (res && res.status == "success") {
        //     this.events = res.events
        //   }
        // })
      }
      else if (this.data && this.data.name == "updateUpcomingEvents") {
        this.events = this.data.events

        this.data.upcomingevents.forEach(element => {
          this.selected_upcomingevents.push(element._id)
        });
        // this.exploreservice.getUpcomingEvents().then((res: any) => {
        //   if (res && res.status == "success") {
        //     this.events = res.events
        //   }
        // })
      }
      else if (this.data && this.data.name == "updateSelectedChannel") {
        this.events = this.data.events

        this.selected_channel = this.data.selectedchannel

        // this.exploreservice.getChannels().then((res: any) => {
        //   if (res && res.status == "success") {
        //     this.events = res.channels
        //   }
        // })
      }
      else if (this.data && this.data.name == "updateSpeakers") {
        this.speakers = this.data.speakers

        this.data.selectedspeakers.forEach(element => {
          this.selected_speakers.push(element._id)
        });
      }

      else if (this.data && this.data.name == "updateTestimonials") {
        this.speakers = this.data.speakers
        this.testimonials = this.data.testimonials

        for (let index = 0; index < this.testimonials.length; index++) {
          const element = this.testimonials[index];

          if (index == 0) { this.testimonial0 = element }
          else if (index == 1) { this.testimonial1 = element }
          else if (index == 2) { this.testimonial2 = element }
          else if (index == 3) { this.testimonial3 = element }

        }



      }
    }
    selectIcon(icon) {
      if (this.selected_icon.includes(icon)) {
        this.selected_icon.splice(this.selected_icon.indexOf(icon), 1)
        this.categories.splice(this.selected_icon.indexOf(icon), 1)
      }
      else {
        if (this.selected_icon.length < 6) {
          this.selected_icon.push(icon)
         
          this.categories.push({ name: icon.replace(/_/g, ' ').replace(/-/g, ' ').trim(), icon: "https://"+ config.bucket_prefix+"assets-portal.s3.amazonaws.com/Dev/categories/" + icon + ".png" })
        }
      }
    }
    updateExplore() {
      if (this.data && this.data.name == "addcategory") {
        this.exploreservice.editExplore(this.data.explore_id, { categories: this.categories }, 'category').then((res: any) => {
          if (res && res.status == "success") {
            this.dialogRef.close()
          }
        })
      }
      else if (this.data && this.data.name == "updateRecommendedEvents") {
        this.exploreservice.editExplore(this.data.explore_id, { recommended_events: this.selected_recommendedevents }, 'recommended').then((res: any) => {
          if (res && res.status == "success") {
            this.dialogRef.close()
          }
        })
      }
      else if (this.data && this.data.name == "updateUpcomingEvents") {
        this.exploreservice.editExplore(this.data.explore_id, { upcoming_events: this.selected_upcomingevents }, 'upcoming').then((res: any) => {
          if (res && res.status == "success") {
            this.dialogRef.close()
          }
        })
      }
      else if (this.data && this.data.name == "updateSelectedChannel") {

        if (this.selected_channel && this.selected_channel._id) {
          this.exploreservice.editExplore(this.data.explore_id, { selected_channel: this.selected_channel._id }, 'channel').then((res: any) => {
            if (res && res.status == "success") {
              this.dialogRef.close()
            }
          })
        }
        else {
          this.exploreservice.editExplore(this.data.explore_id, { selected_channel: null }, 'channel').then((res: any) => {
            if (res && res.status == "success") {
              this.dialogRef.close()
            }
          })
        }
      }
      else if (this.data && this.data.name == "updateSpeakers") {
        this.exploreservice.editExplore(this.data.explore_id, { speakers: this.selected_speakers }, 'speaker').then((res: any) => {
          if (res && res.status == "success") {
            this.dialogRef.close()
          }
        })
      }
      else if (this.data && this.data.name == "updateTestimonials") {
        this.testimonials = []
        if (this.testimonial0 && this.testimonial0.testimonial != "" && this.testimonial0.speaker != "" && this.testimonial0.speaker._id) {
          this.testimonials.push({ testimonial: this.testimonial0.testimonial, speaker: this.testimonial0.speaker._id })
        }
        if (this.testimonial1 && this.testimonial1.testimonial != "" && this.testimonial1.speaker != "" && this.testimonial1.speaker._id) {
          this.testimonials.push({ testimonial: this.testimonial1.testimonial, speaker: this.testimonial1.speaker._id })
        }
        if (this.testimonial2 && this.testimonial2.testimonial != "" && this.testimonial2.speaker != "" && this.testimonial2.speaker._id) {
          this.testimonials.push({ testimonial: this.testimonial2.testimonial, speaker: this.testimonial2.speaker._id })
        }
        if (this.testimonial3 && this.testimonial3.testimonial != "" && this.testimonial3.speaker != "" && this.testimonial3.speaker._id) {
          this.testimonials.push({ testimonial: this.testimonial3.testimonial, speaker: this.testimonial3.speaker._id })
        }
        this.exploreservice.editExplore(this.data.explore_id, { testimonials: this.testimonials }, 'testimonoal').then((res: any) => {
          if (res && res.status == "success") {
            this.dialogRef.close()
          }
        })
      }
    }
    toggleRecommended(e, event) {
      if (!e) {
        this.selected_recommendedevents.splice(this.selected_recommendedevents.indexOf(event._id), 1)
      }
      else {
        this.selected_recommendedevents.push(event._id)
      }
    }
    toggleUpcoming(e, event) {
      if (!e) {
        this.selected_upcomingevents.splice(this.selected_upcomingevents.indexOf(event._id), 1)
      }
      else {
        this.selected_upcomingevents.push(event._id)
      }
    }
    toggleChannel(e, event) {
      if (!e) {
        // this.se.splice(this.selected_upcomingevents.indexOf(event._id), 1)
        this.selected_channel = null
      }
      else {
        this.selected_channel = event
      }
    }
    toggleSpeaker(e, speaker) {
      if (!e) {
        this.selected_speakers.splice(this.selected_speakers.indexOf(speaker._id), 1)
      }
      else {
        if (this.selected_speakers.length < 3) { this.selected_speakers.push(speaker._id) }
      }
    }
    close() {
      this.dialogRef.close()
    }
    counter(i: number) {
      return new Array(i);
    }
    currentDivId: any
    currentParentId: any;

    showDropDown(idDiv, idParent) {



      if (this.currentDivId && this.currentDivId == idDiv) {
        if (document.getElementById(idDiv) && document.getElementById(idDiv).style.visibility == "hidden") {
          document.getElementById(idParent).style.border = "1px solid #3c465f"
          document.getElementById(idDiv).style.visibility = "visible"
          document.getElementById(idDiv).style.zIndex = "2"
        }
        else if (document.getElementById(idDiv).style.visibility == "visible") {
          document.getElementById(idParent).style.border = "1px solid rgb(25,32,56)"
          document.getElementById(idDiv).style.visibility = "hidden"
          document.getElementById(idDiv).style.zIndex = "0"
        }

      }
      else {
        if (!document.getElementById(this.currentDivId)) {
          document.getElementById(idParent).style.border = "1px solid #3c465f"
          document.getElementById(idDiv).style.visibility = "visible"
          document.getElementById(idDiv).style.zIndex = "2"
        }
        else {
          document.getElementById(this.currentParentId).style.border = "1px solid rgb(25,32,56)"
          document.getElementById(this.currentDivId).style.visibility = "hidden"
          document.getElementById(this.currentDivId).style.zIndex = "0"
          document.getElementById(idParent).style.border = "1px solid #3c465f"
          document.getElementById(idDiv).style.visibility = "visible"
          document.getElementById(idDiv).style.zIndex = "2"
        }
      }
      this.currentDivId = idDiv
      this.currentParentId = idParent

    }
    filterArray: any = [];
    selectedFilterSpeaker = "Speakers"

    selectOption(filter, option, idDiv, idParent, idOption, index) {
      // this.showDropDown(idDiv, idParent)
      document.getElementById(idParent).style.border = "1px solid rgb(25,32,56)"
      document.getElementById(idDiv).style.visibility = "hidden"
      document.getElementById(idDiv).style.zIndex = "0"

      if (index == 0) { this.testimonial0.speaker = option }
      else if (index == 1) { this.testimonial1.speaker = option }
      else if (index == 2) { this.testimonial2.speaker = option }
      else if (index == 3) { this.testimonial3.speaker = option }
      // let found_option
      // //  = lodash.find(this.filterArray, { 'id': idOption });
      // if (!found_option) {

      //   if (filter == "speaker") {
      //     this.filterArray.push({ name: "speaker", value: option, id: idOption })
      //     this.selectedFilterSpeaker = option.name
      //   }



      // }

    }

  }
