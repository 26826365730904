<div class="session-event-home">
    <div *ngIf="cover && cover.split('.')[ cover.split('.').length-1]!='mp4'" class="session-event-cover"
        [style.background-image]="'url('+cover+')'">
    </div>
    <div *ngIf="cover && cover.split('.')[ cover.split('.').length-1]=='mp4'" class="session-event-cover">
        <video  id="videoCover" autoplay loop muted>
            <source [src]="cover" type="video/mp4;">
        </video>
    </div>
    <div style="position: absolute;top: 0;left: 0;right: 0;display: flex;flex-direction: column;">
        <div *ngIf="!showMoreInfo"
            style="height: 100vh; display: flex;flex-direction: column;justify-content: space-between;">
            <div style="display: flex;margin: 241px 0 0 140px;">
                <div class="content-cover">
                    <div style="display: flex; gap :10px">
                        <div class="event-type Quicksand-bold tagsText" style="text-align: center;">Session</div>
                        <div *ngIf="event.status == 'Launched'||  event.status == 'launched' "
                            class="event-status  Quicksand-bold tagsText" style="text-align: center;">{{event.status |
                            titlecase}}</div>
                        <div *ngIf="event.status == 'Finished' ||  event.status == 'finished' "
                            class="event-status  Quicksand-bold tagsText" style="text-align: center;">Recorded Video
                        </div>
                        <div *ngIf="event.status == 'Live' || event.status == 'live' "
                            class="event-status  Quicksand-bold tagsText"
                            style="text-align: center;border: 1px solid #FF0000;color: #FF0000;"> {{event.status |
                            titlecase}}</div>
                    </div>
                    <div *ngIf="event.title" class="one-line-limit bigTitle Quicksand-semibold" style="text-align:left">
                        {{event.title | titlecase}}
                    </div>
                    <div *ngIf="event.short_summary">
                        <div class=" Quicksand-regular paragraphe line-limit" style="text-align: left;">
                            {{event.short_summary |
                            titlecase}}
                        </div>
                    </div>
                    <div id="event-tags-container" class="event-tags-container" *ngIf="event.tags">
                        <div id="leftarrow" *ngIf="rightArrowValue >= 4 " class="arrowLeft"
                            (mouseenter)="scrollDivRooms(event.tags, 4,'tags','leftarrow')"
                            (mouseleave)="stopTimerRooms(timerRooms)"></div>
                        <div class="event-tags" id="tags">
                            <div class="tag tagsText" style="color:#8f9bb3;" *ngFor="let tag of event.tags">{{tag |
                                titlecase}}
                            </div>
                        </div>
                        <div id="rightarrow" *ngIf="overflowActivated" class="arrowRight"
                            (mouseenter)="scrollDivRooms(event.tags, -4,'tags','rightarrow')"
                            (mouseleave)="stopTimerRooms(timerRooms)"></div>
                    </div>
                    <div *ngIf="!event.tags" style="height: 40px;"></div>

                    <div style="display: flex;flex-direction: row;gap: 40px;">
                        <div class="event-organizer" *ngIf=" event.organizer">
                            <div class="subTitle white-color Quicksand-bold" style="text-align: left">
                                Organizer
                            </div>
                            <div class="content" style="gap: 15px;">
                                <div class="organizer-logo" [style.background-image]="'url('+event.organizer.logo+')'">
                                </div>
                                <!-- <img [src]=event.organizer.logo> -->
                                <div id="organizer" class="paragraphe line-limit Quicksand-regular"
                                    style="align-self: center;">
                                    {{event.organizer.name | titlecase}}
                                </div>

                            </div>
                        </div>
                        <div *ngIf="speakers && speakers.length>0" class="event-organizer" style="max-width: 209px;">
                            <div class="subTitle white-color Quicksand-bold" style="text-align: left"> Speakers </div>
                            <div class="content">
                                <div *ngIf="speakers" class="session-speakers" style="align-items: flex-start;"
                                    [style.left.px]=" -15*(speakers.length-3) ">
                                    <div *ngFor="let speaker of speakers;let i = index" style="display: inline-flex;">
                                        <div *ngIf="i<=2" [style.background-image]="'url('+speaker.photo+')'"
                                            class="speaker" [style.left.px]=" 15*(speakers.length-1-i) ">
                                        </div>
                                        <div *ngIf="i==3" class="othersText Quicksand-regular tagsText"> +
                                            {{speakers.length-3}} others</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="event-date">
                    <div class="day-card">
                        <div style="align-self: center;">
                            <div style="display: flex;
                                flex-direction: column;
                                justify-content: center;">
                                <span id="month" class="subTitle white-color Quicksand-regular"
                                    style="text-align: center">
                                    {{
                                    convertDate(event.start_date, event.end_date,event.utcoffset)[0].split(' ')[0]
                                    }}</span>
                                <span id="day" class="bigTitle"> {{ convertDate(event.start_date,
                                    event.end_date,event.utcoffset)[0].split('
                                    ')[1] }}</span>
                                <span id="year" class="smallText white-color Quicksand-regular"
                                    style="text-align: center;">
                                    2022 </span>
                            </div>
                        </div>
                        <div style="height: 48px;
                            width: 0;
                            background: #EDF1F7;
                            border: 1px solid #EDF1F7;
                            align-self: center;"> </div>
                        <div class="bigTitle Quicksand-bold" style="align-self: center"> 4H </div>

                    </div>
                    <div class="access-details">
                        <div *ngIf="join || haveAccess " class="ticket-details paragraphe">
                            <div *ngIf="message !== ''">
                                <div class="paragraphe Quicksand-medium" style="color: #A3A3A3;"><img
                                        src="../../../assets/portal/V.png"
                                        style="width: 15px;height: 15px;align-self: center;"> You have access to this
                                    event
                                </div>
                                <div class="ticket-name paragraphe Quicksand-medium" style="align-items: center;">
                                    <div (click)="showTicket()">{{message}}
                                    </div>
                                    <img src="../../../assets/portal/Groupe 13274.png"
                                        style="position: relative; top: -5px; left: 3px;">
                                </div>
                            </div>

                            <div *ngIf="message == '' && show" class="paragraphe Quicksand-medium"
                                style="margin-bottom: 5px;color: #A3A3A3;">
                                <img src="../../../assets/portal/V.png" style="width: 15px;
                                    height: 15px;
                                    align-self: center;"> You have access to this event
                            </div>
                        </div>
                        <div *ngIf="!join && !haveAccess" class="ticket-details">

                        </div>
                        <div *ngIf="register" class="action-btn subTitle Quicksand-bold"
                            style="color :#141A2A;text-align:center" style="color:black; text-align: center;"
                            (click)="onClickRegister()">
                            REGISTER
                        </div>
                        <div class="lauched-btn subTitle Quicksand-bold" style="text-align: center;align-self: center;"
                            *ngIf="countdown">
                            <img class="icon" src="../../../assets/portal/stopwatch_23f1-fe0f.png" />
                            <countdown [config]="moreThan24Hours" style="align-self: center;"></countdown>
                        </div>
                        <div class="action-btn subTitle Quicksand-bold" style="
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        background: #AF1414;
                        border: 1px solid #AF1414;
                        color :#edf1f7eb;
                        text-align:center" *ngIf="event.status == 'live' || event.status == 'Live' "
                            (click)="openVideo()">
                            Watch
                        </div>
                        <div *ngIf="event.status == 'finished' || event.status == 'Finished' "
                            class="action-btn subTitle Quicksand-bold" style="
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        background: #edf1f7eb;
                        color :#171F33;
                        text-align:center" (click)="openVideo()">
                            <div style="align-self: center;color: #171F33;">Watch</div>
                        </div>
                        <div style="display: inline-flex;padding:10px 0px 0px 0px;" *ngIf="event.is_inviteonly">
                            <img style="width: 10px;height: 50%;align-self: center;margin-right: 5px;"
                                src="../../../assets/portal/lock1.png">
                            <div class="tagsText Quicksand-medium" style="color: #A3A3A3;">This event is by invitation
                                only
                            </div>
                        </div>
                        <hr style="background: #262626; width: 90px;">
                        <div class="social-media ">
                            <span class="tagsText">Share this event on : </span>
                            <div style="display: inline-flex;
                            align-items: center;
                            gap: 10px;">
                                <img style="width: 25px;height: 25px;" src="../../../assets/twiter.png">
                                <img style="width: 25px;height: 25px;" src="../../../assets/fb.png">
                                <img style="width: 25px;height: 25px;" src="../../../assets/IN.png">
                                <img style="width: 25px;height: 25px;" src="../../../assets/url.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="showMoreBtn-container">
                <div class="showMore-btn" (click)="showMore()">
                    <div class="tagsText  Quicksand-bold" style="align-self: center;">SHOW MORE
                    </div>
                </div>
            </div>
            <div *ngIf="!muted && event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]=='mp4' "
                style="width: 35px;
                            height: 35px;
                            background-repeat: no-repeat;
                            align-self: flex-end;
                            margin-right: 0px;
                            cursor: pointer;
                            position: absolute;
                            bottom: 40px;
                            right: 45px;
                            z-index: 2000;
                            background: url(../../../assets/on_sound.png);" (click)="soundOnOff(false)"></div>
            <div *ngIf="muted && event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]=='mp4'"
                style="width: 35px;
                            height: 35px;
                            background-repeat: no-repeat;
                            align-self: flex-end;
                            cursor: pointer;
                            position: absolute;
                            bottom: 40px;
                            right: 45px;
                            z-index: 2000;
                            background: url(../../../assets/off_sound.png);" (click)="soundOnOff(true)"></div>
        </div>
        <div *ngIf="showMoreInfo" style="padding-top: 275px; background: #0c0f1bab; width: 100%;display: flex;
        flex-direction: column;
        gap: 20px;">
            <div class="session-event-details">
                <div class="session-event-info">
                    <div *ngIf="event.title" class=" line-limit bigTitle Quicksand-semibold"
                        style="text-align:left;max-width: 80%;">
                        {{event.title | titlecase}}
                    </div>
                    <div style="display: flex; gap :10px">
                        <div class="event-type Quicksand-bold tagsText" style="text-align: center;">Session</div>
                        <div *ngIf="event.status == 'Launched'||  event.status == 'launched' "
                            class="event-status  Quicksand-bold tagsText" style="text-align: center;">Not Started</div>
                        <div *ngIf="event.status == 'Finished' ||  event.status == 'finished' "
                            class="event-status  Quicksand-bold tagsText" style="text-align: center;">Recorded Video
                        </div>
                        <div *ngIf="event.status == 'Live' || event.status == 'live' "
                            class="event-status  Quicksand-bold tagsText"
                            style="text-align: center;border: 1px solid #FF0000;color: #FF0000;"> Live</div>
                    </div>
                </div>
                <div style="display: flex; flex-direction: column ; gap:20px">
                    <div class="day-card" style="background:#edf1f7eb ; border-radius: 5px;">
                        <div style="align-self: center;">
                            <div style="display: flex;
                                flex-direction: column;
                                justify-content: center;">
                                <span id="month" class="subTitle white-color Quicksand-regular"
                                    style="text-align: center ; color:#171F33">
                                    {{
                                    convertDate(event.start_date, event.end_date,event.utcoffset)[0].split(' ')[0]
                                    }}</span>
                                <span id="day" class="bigTitle" style="color:#171F33"> {{ convertDate(event.start_date,
                                    event.end_date,event.utcoffset)[0].split('
                                    ')[1] }}</span>
                                <span id="year" class="smallText white-color Quicksand-regular"
                                    style="text-align: center;color:#171F33">
                                    2022 </span>
                            </div>
                        </div>
                        <div style="height: 48px;
                            width: 0;
                            background: #171F33;
                            border: 1px solid #171F33;
                            align-self: center;"> </div>
                        <div class="bigTitle Quicksand-bold" style="align-self: center;color:#171F33"> 4H </div>

                    </div>
                    <div *ngIf="event.status == 'Live' || event.status == 'live' "
                        class="action-btn subTitle Quicksand-bold" style="background: #AF1414;border: 1px solid #AF1414; color :#edf1f7eb;text-align:center;display: flex;
                    flex-direction: column;
                    justify-content: center;
                    " (click)="openVideo()">
                        Watch
                    </div>
                    <div *ngIf="event.status == 'Finished' || event.status == 'finished' "
                        class="action-btn subTitle Quicksand-bold" style="background: #edf1f7eb; color :#171F33;text-align:center;display: flex;
                    flex-direction: column;
                    justify-content: center;
                    " (click)="openVideo()">
                        Watch
                    </div>
                </div>
            </div>
            <div style="background: #0E1120; display: flex;flex-direction: column;gap: 40px; padding: 40px 130px;
            padding-bottom: 130px;">
                <div *ngIf="event.description && event.description !=''"
                    style="display: flex;flex-direction: column;gap:30px;">
                    <div class="speakers-title">
                        <div class="highlighted-title subTitle Quicksand-bold">Description</div>
                    </div>
                    <div class="paragraphe Quicksand-regular" [innerHTML]="event.description "></div>

                </div>
                <div *ngIf="speakers && speakers.length>0" style="display: flex;flex-direction: column;gap:30px;">
                    <div class="speakers-title">
                        <div class="highlighted-title subTitle Quicksand-bold">Speakers</div>
                    </div>
                    <div class="speakers">
                        <div class="speaker-card" *ngFor="let speaker of speakers">
                            <div class="speaker-pic">
                                <div class="speaker-img" [style.background-image]="'url('+speaker.photo+')'"><img
                                        style="visibility: hidden;" [src]="speaker.photo"></div>
                                <div class="speaker-role tagsText">
                                    Speaker
                                </div>
                                <div class="speaker-infos ">
                                    <div id="name" class="smallText">{{speaker.firstname | titlecase}}
                                        {{speaker.lastname | titlecase}}</div>
                                    <div id="position" class="paragraphe Quicksand-medium ThreeLines">{{speaker.title |
                                        titlecase}}
                                    </div>
                                </div>
                            </div>
                            <div style="align-self: center;">
                                <a [href]="speaker.linkedin" style="text-decoration: none; color: white; "
                                    target="_blank">
                                    <div class="icon-in">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="display: flex;flex-direction: column;gap:30px;">
                    <div class="speakers-title">
                        <div class="highlighted-title subTitle Quicksand-bold">Organizer</div>
                    </div>
                    <div style="display: flex;gap: 30px;">
                        <div class="organizer-logo" [style.background-image]="'url('+event.organizer.logo+')'"></div>
                        <!-- <img class="organizer-logo" [src]=event.organizer.logo> -->
                        <div style="display: flex;flex-direction: column ; gap:20px;align-self: center;">
                            <div class="Quicksand-bold smallText">{{event.organizer.name}}</div>
                            <div class="Quicksand-regular paragraphe">{{event.organizer.description}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-top: -135px;">
                <app-footer></app-footer>

                <!-- <div class="publish-part">
                    <div class="publish-paragraphe">
                        <div class="bigTitle Quicksand-bold" style="text-align: left;">
                            Welcome to EVENTZOO
                        </div>
                        <div class="subTitle publish-text Quicksand-medium">
                            Publish Medical Content.<br> Build Your Next Event In Minutes
                        </div>
                        <div class="smallText speaker-showMore Quicksand-bold" style="width: 242px;height: 40px;">CREATE
                            EXPERIENCE
                        </div>
                    </div>
                </div>
                <div class="footer-explore">
                    <div style="color: #8F9BB3;display: inline-flex;width: 100%;">
                        <div class="menu-footer">
                            <div class="sub-menu">
                                <div class="paragraphe Quicksand-medium" style="color: #8F9BB3;">Home</div>
                                <div class="paragraphe Quicksand-medium" style="color: #8F9BB3;">Contact us</div>
                                <div class="paragraphe Quicksand-medium" style="color: #8F9BB3;">Terms of service
                                </div>
                                <div class="paragraphe Quicksand-medium" style="color: #8F9BB3;">About us</div>
                            </div>
                            <div class="sub-menu">
                                <div class="paragraphe Quicksand-medium" style="color: #8F9BB3;">Description</div>
                                <div class="paragraphe Quicksand-medium" style="color: #8F9BB3;">Sessions</div>
                                <div class="paragraphe Quicksand-medium" style="color: #8F9BB3;">Speakers</div>
                                <div class="paragraphe Quicksand-medium " style="color: #8F9BB3;">Exhibitors</div>
                            </div>
                        </div>
                        <div style="display:inline-flex; justify-content: center;width:50%">
                            <div class="paragraphe-footer">
                                <div class="bigTitle speakers-title Quicksand-bold"
                                    style="color: #8F9BB3;height: 50px !important;">
                                    EVENTZOO
                                </div>
                                <div class="paragraphe" style="color: #8F9BB3;line-height: 28px;max-width: 55vh;">
                                    EventZoo is a newly established company that enables client, immersive virtual and
                                    hybrid online events on a global scale using a proprietary, multi-channel technology
                                    platform.
                                </div>
                                <div class="footer-icons">
                                    <img style="width: 30px;height: 30px;" src="../../assets/twiter.png">
                                    <img style="width: 30px;height: 30px;" src="../../assets/fb.png">
                                    <img style="width: 30px;height: 30px;" src="../../assets/IN.png">
                                    <img style="width: 30px;height: 30px;" src="../../assets/url.png">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="copy-right tagsText Quicksand-regular">
                        © 2022 EVENTZOO. All rights reserved.
                    </div>

                </div> -->
            </div>
            <div style="position: fixed;
            top: 90vh;
            left: 95vw;
            cursor: pointer;" (click)="Reduce()"> <img src="../../../assets/scrollTop.png"> </div>
        </div>
    </div>
</div>