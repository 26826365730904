<div style="color:white;font-family: 'Quicksand';">
    <div style="font-size: 22px;margin-bottom: 10px;margin-left: 10px;">
        Invitations <span (click)="sendInvitation()" style="cursor: pointer; border: 1px solid; padding: 0 8px;border-radius: 5px;margin-left: 10px;">+</span>
    </div>
    <table class="table dark-table">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Email</th>
                <th scope="col">Status</th>
                <th scope="col">Invitation Type</th>
                <th scope="col">Allocated Ticket</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let invitation of invitations; let i=index">
                <th scope="row">{{i+1}}</th>
                <td>{{invitation.email}}</td>
                <td>{{invitation.status}}</td>
                <td>{{invitation.type}}</td>
                <td>
                    <div *ngIf="invitation.type=='allocate_ticket' && invitation.allocated_pass">
                        {{invitation.allocated_pass.name}}</div>
                    <div *ngIf="invitation.type=='allocate_ticket' && !invitation.allocated_pass">-x-</div>
                </td>
                <td><i class="fa fa-trash" style="cursor: pointer;" (click)="deleteInvitation(invitation._id)"></i></td>
            </tr>
        </tbody>
    </table>
</div>