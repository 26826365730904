<div *ngIf="availableSessions && availableSessions.length > 0" class="video-player-part">
    <div class="titleSession subTitle Quicksand-bold">Available Sessions</div>
    <div class="filter-part">
        <div style="margin-bottom: 20px;display: inline-flex;gap: 20px;">
            <!-- tags -->
            <div style="cursor: pointer;">
                <div id="dropdown-tags" class="dropdown" (click)="showDropDown('filter-tags','dropdown-tags')">
                    <div class="dropdown-button">
                        <div class="dropdown-button">
                            <div class="tagsText Quicksand-regular" style="color:#8F9BB3;">
                                {{selectedFilterTag}}
                            </div>
                            <div class="arrow-down"></div>
                        </div>
                    </div>
                </div>
                <div id="filter-tags" class="dropdown-content">
                    <div *ngFor="let tag of tags ; let i=index" class="tagsText dropdown-item-filter" [id]="'tag'+i"
                        (click)="selectOption('tag',tag,'filter-tags','dropdown-tags','tag'+i )">
                        {{tag}}
                    </div>
                </div>
            </div>
            <!-- speaker -->
            <div style="cursor: pointer;">
                <div id="dropdown-speakers" class="dropdown"
                    (click)="showDropDown('filter-speakers','dropdown-speakers')">
                    <div class="dropdown-button">
                        <div class="dropdown-button">
                            <div class="tagsText Quicksand-regular" style="color:#8F9BB3;">
                                {{selectedFilterSpeaker}}
                            </div>
                            <div class="arrow-down"></div>
                        </div>
                    </div>
                </div>
                <div id="filter-speakers" class="dropdown-content">
                    <div [id]="'speaker'+i" *ngFor="let speaker of speakers ; let i=index"
                        class="tagsText dropdown-item-filter"
                        (click)="selectOption('speaker',speaker,'filter-speakers','dropdown-speakers','speaker'+i)">
                        {{speaker.firstname | titlecase}} {{speaker.lastname | titlecase}}
                    </div>
                </div>
            </div>

            <!-- room -->
            <div style="cursor: pointer;">
                <div id="dropdown-rooms" class="dropdown" (click)="showDropDown('filter-rooms','dropdown-rooms')">
                    <div class="dropdown-button">
                        <div class="dropdown-button">
                            <div class="tagsText Quicksand-regular" style="color:#8F9BB3;">
                                {{selectedFilterRooms}}
                            </div>
                            <div class="arrow-down"></div>
                        </div>
                    </div>
                </div>
                <div id="filter-rooms" class="dropdown-content">
                    <div *ngFor="let room of roomsFilter; let i=index" class="tagsText dropdown-item-filter"
                        [id]="'room'+i" (click)="selectOption('room',room,'filter-rooms','dropdown-rooms','room'+i)">
                        {{room.name}}
                    </div>
                </div>
            </div>
        </div>
        <div class="selectedOptions">
            <div style="color:#8F9BB3 ; margin-right:10px;align-self: center; flex: none;" class="paragraphe">Active
                Filters :</div>
            <div id="selectedOptions-container" *ngIf="filterArray && filterArray.length>0"
                style="max-width: 84vw;overflow: hidden;display: inline-flex; ">
                <div id="leftarrow" *ngIf="rightArrowValueFilter >= 4 " class="arrowLeft"
                    (mouseenter)="scrollOptionsFilter(filterArray, 4,'optionFilter','leftarrow')"
                    (mouseleave)="stopTimerFilter(timerRooms)"></div>
                <div id="optionFilter" style="display: inline-flex;gap: 10px;overflow: hidden;">
                    <div *ngFor="let option of filterArray;let index=index" class="tagFilter tagsText">
                        <div style="display:flex;">
                            <div *ngIf="option.value && option.value._id">{{option.value.name | titlecase}} </div>
                            <div *ngIf="option.value && !option.value._id">{{option.value | titlecase}} </div>

                            <span style=" cursor: pointer;margin-left: 15px;" (click)="removeOption(index)">
                                <i class="fa fa-times-circle " aria-hidden="true">
                                </i>
                            </span>
                        </div>
                    </div>
                </div>
                <div id="rightarrow" *ngIf="overflowFilterActivated" class="arrowRight"
                    (mouseenter)="scrollOptionsFilter(filterArray, -4,'optionFilter','rightarrow')"
                    (mouseleave)="stopTimerFilter(timerRooms)"></div>
            </div>
            <div *ngIf="!filterArray || filterArray.length==0"
                style="display: flex !important;flex-direction: row  !important;justify-content: center;"
                class="tagFilter tagsText">
                <div style="align-self: center;">All</div>
                <!-- <span style=" cursor: pointer;margin-left: 15px;align-self: center;">
                        <i class="fa fa-times-circle " aria-hidden="true">
                        </i>
                    </span> -->
            </div>
        </div>

    </div>
    <div id="videoContainer" class="videos-container">
        <!-- <app-video-card [availableSessions]="availableSessions" ></app-video-card> -->
        <div *ngFor="let session of first_availableSessions;let i=index" [id]="'videoCard'+i" class="video-wrapper" (click)="openVideo(i)">
            <video #video
                *ngIf="session.cover_url && session.cover_url.split('.')[ session.cover_url.split('.').length-1]=='mp4'"
                [id]="session._id" [muted]="'muted'">
                <source [src]="session.cover_url" type="video/mp4">
            </video>
            <div [id]="'image'+session._id"
                *ngIf="session.cover_url && session.cover_url.split('.')[ session.cover_url.split('.').length-1]!='mp4'"
                [style.background-image]="'url('+session.cover_url+')'" class="session-cover">
            </div>
            <div class="content-video" (mouseenter)="playVideo(session._id ,true)"
                (mouseleave)="playVideo(session._id ,false)">
                <div class="header-btn">
                    <div class=" duration tagsText">
                        {{session.duration}}
                    </div>
                </div>
                <div style="display: flex;justify-content: center;height: 100%;">
                    <div [id]="'whitePlay'+session._id" class="play-icon">
                    </div>
                    <div [id]="'redPlay'+session._id" class="play-icon-hover">
                    </div>
                </div>
                <div>
                    <div class="session-video-info">
                        <div class="smallText Quicksand-bold"
                            style="text-align: start; margin-bottom: 5px">
                            {{session.title}}
                        </div>
                        <div *ngIf="session.speakers" class="session-speakers" style="align-items: flex-start;"
                            [style.left.px]=" -15*(session.speakers.length-1) ">
                            <div *ngFor="let speaker of session.speakers;let i = index" style="display: inline-flex;">
                                <div *ngIf="i<2" [style.background-image]="'url('+speaker.photo+')'" class="speaker"
                                    [style.left.px]=" 15*(session.speakers.length-1-i) "
                                    (mouseenter)="showSpeakerInVideoCard(speaker , true, session._id)"
                                    (mouseleave)="showSpeakerInVideoCard(speaker , false, session._id)">
                                </div>
                                <div *ngIf="session.speakers.length>2 && i==2"
                                    [style.background-image]="'url('+speaker.photo+')'" class="last-speaker"
                                    [style.left.px]=" 15*(session.speakers.length-1-i) "
                                    (mouseenter)="showSpeakerInVideoCard(speaker , true, session._id)"
                                    (mouseleave)="showSpeakerInVideoCard(speaker , false, session._id)">
                                </div>
                                <div *ngIf="session.speakers.length>2 && i==2" class="tagsText Quicksand-regular" style="
                                    align-self: flex-end;   
                                    position: relative;
                                    left: -25px;
                                    top: -6px;">
                                    +{{session.speakers.length-i}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="session-video-footer">
                        <div class="session-video-tags">
                            <div *ngFor="let tag of session.tags; let i= index">
                                <div *ngIf="i<4" class="tag tagsText Quicksand-light"
                                    style="height: 25px;align-self: center;">
                                    <div>{{tag}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="showMore-btn" (click)="showMore(session._id)">
                            <div class="tagsText showMore-text">Show More
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="current_number_Fsessions < total_Fsessions && total_Fsessions > 4  && filterArray.length == 0 "
        class="showMoreSession-btn">
        <button class="showMoreBtn smallText" (click)="showMoreFinishedSessions()"> LOAD MORE</button>
    </div>
    <div *ngIf="current_number_Fsessions >= total_Fsessions && total_Fsessions > 4 && filterArray.length == 0"
        class="showMoreSession-btn">
        <button class="showMoreBtn smallText" (click)="reduceFinishedSessions()"> REDUCE</button>
    </div>
</div>
<div class="Sessions-part" *ngIf="rooms && rooms.length>0">
    <div class="titleSession subTitle Quicksand-bold">Scheduled Sessions</div>
    <div class="days-container">
        <div id="leftarrowDay" *ngIf="rightArrowValueDay >= 5 " class="arrowLeft" style="height: 60px"
            (mouseenter)="scrollDivRooms(myDays, 5,'days-container','leftarrowDay')"
            (mouseleave)="stopTimerRooms(timerRooms)"></div>
        <div id="days-container">
            <div id="Days" [ngClass]="days.length >6? 'days' :  'few-days' ">
                <div *ngFor="let day of myDays; let i=index" [id]="i" class="day Quicksand-medium smallText"
                    (mouseenter)="onDayHover(i,day.visible ,true)" (mouseleave)="onDayHover(i,day.visible , false)">
                    <div *ngIf="day.visible" style="min-width: 200px;" (click)="getSessionByDay(i)">{{day.day}}</div>
                    <div *ngIf="!day.visible" style="min-width: 200px;color: grey;">{{day.day}}</div>
                </div>
            </div>
        </div>
        <div id="rightarrowDay" *ngIf="overflowActivateddays" class="arrowRight" style="height: 60px"
            (mouseenter)="scrollDivRooms(myDays, -5,'days-container','rightarrowDay')"
            (mouseleave)="stopTimerRooms(timerRooms)">
        </div>
    </div>
    <div id="Rooms-container" class="rooms-container">
        <div id="leftarrow" *ngIf="rightArrowValue >= 5 " class="arrowLeft"
            (mouseenter)="scrollDivRooms(myRooms, 5,'Rooms','leftarrow')" (mouseleave)="stopTimerRooms(timerRooms)">
        </div>
        <div id="Rooms" style="flex: 80%;overflow-x: hidden;overflow-y: hidden;height: 35px;">
            <div *ngIf="myRooms && myRooms.length>0" class="rooms"
                [ngStyle]="{'width': myRooms && myRooms.length>1 ?  '100%' :'25%'} ">
                <!-- (mouseover)="hoverdiv($event,i,true,room.name)"
                (mouseout)="hoverdiv($event,i,false,room.name)" -->
                <div *ngFor="let room of myRooms; let i=index" [id]="'room'+i" (click)="onClickRoom(room._id)"
                    class="smallText"
                    [ngClass]="selectedRoom._id == room._id ? 'selected-room' :  (selectedRoom._id != room._id && i>0) ? 'notselected-room-1' : 'notselected-room-2' "
                    [style.width.%]="myRooms.length < 5 ? 100/myRooms.length : 40"
                    [ngStyle]="{'min-width.px': myRooms.length > 5 ? 300 : null } ">
                    <div *ngIf="room.is_live  && selectedRoom._id == room._id " class="icon-live-black"
                        style="align-self: center;">
                    </div>
                    <div *ngIf="room.is_live  &&  selectedRoom._id != room._id" class="icon-live-white"
                        style="align-self: center;"></div>

                    <div style="align-self: center;" class="one-line-limit" [id]="'roomname'+i">{{room.name}}</div>

                </div>
                <!-- <div id="roomnameShow" class="smallText" style="background: rgb(56 59 68 / 85%);
                text-align: left;
                padding: 10px;
                border-radius: 10px;
                color: rgb(120, 129, 152);
                width: fit-content;
                position: absolute;
                top: 79%;
                visibility: hidden;">{{room_name}}</div> -->
            </div>
        </div>
        <div id="rightarrow" *ngIf="overflowActivatedRooms" class="arrowRight"
            (mouseenter)="scrollDivRooms(myRooms, -5,'Rooms','rightarrow')" (mouseleave)="stopTimerRooms(timerRooms)">
        </div>
    </div>
    <div *ngIf="sessions && sessions.length>0" style="width:100%">
        <div *ngFor="let session of sessions;let i=index">
            <div class="sessions">
                <div class="session-time paragraphe">
                    <div style="margin-bottom: 5px;">
                        {{session.starting_time}}
                    </div>
                    <div *ngIf="session.duration">{{session.duration}}</div>
                </div>
                <div style="width: 100%;">
                    <div class="session-card"
                        [ngStyle]="{'border-bottom': session.status == 'Live'? '2px solid red':'none'} ">
                        <div class="session-header">
                            <div class="session-title Quicksand-semibold ">
                                <div style="margin-right: 15px;align-self: center; cursor: pointer;"
                                    class="smallText" (click)="showScheduledSessionPlaylist(session._id)"> {{session.title}}</div>
                                <div *ngIf="session.status == 'Live'" class="session-status">{{status}}</div>
                            </div>
                            <div *ngIf="session.status == 'Live'" class="watch-btn smallText"
                                style="background-color: red;">
                                Watch
                            </div>
                            <div [id]="'reminder'+session._id" *ngIf="session.status != 'Live'"
                                class="watch-btn smallText setReminder-btn" (click)="setReminder(session._id,true)">
                                <div class="iconBlack-session-card"></div>
                                <div style="align-self: center;">Set Reminder</div>
                            </div>
                            <div [id]="'activated'+session._id" *ngIf="session.status != 'Live'"
                                class="watch-btn smallText activated-btn" (click)="setReminder(session._id,false)">
                                <div class="iconWhite-session-card"></div>
                                <div>Activated</div>
                            </div>
                        </div>
                        <div class="two-line-limit paragraphe" style="max-width: 90%;">
                            {{session.description}}
                        </div>
                        <div class="session-footer">
                            <div class="session-speakers" [style.left.px]=" -15*(session.speakers.length-1) ">
                                <div *ngFor="let speaker of session.speakers;let i = index"
                                    [style.background-image]="'url('+speaker.photo+')'" class="speaker"
                                    [style.left.px]=" 15*(session.speakers.length-1-i) "
                                    (mouseenter)="showSpeaker(speaker , true, session._id)"
                                    (mouseleave)="showSpeaker(speaker , false, session._id)">
                                    <div *ngIf="show && selectedSpeaker._id == speaker._id && session._id == selectedSession  "
                                        class="speaker-info" style="visibility: visible">
                                        <div class="speaker-header">
                                            <div class="speaker-img"
                                                [style.background-image]="'url('+selectedSpeaker.photo+')'">
                                            </div>
                                            <div class="speaker-details">
                                                <div class="subTitle Quicksand-medium">
                                                {{selectedSpeaker.firstname | titlecase}} {{selectedSpeaker.lastname | titlecase}}
                                                </div>
                                                <div *ngIf="selectedSpeaker.title" class="smallText Quicksand-medium">
                                                    {{selectedSpeaker.title}}
                                                </div>
                                                <div *ngIf="selectedSpeaker.linkedin" class="tagsText Quicksand-medium">
                                                    ({{selectedSpeaker.linkedin}})
                                                </div>
                                            </div>
                                        </div>
                                        <hr class="hr-speaker">
                                        <div *ngIf="selectedSpeaker.bio" class="paragraphe line-limit"
                                            style="height:80px ;">{{selectedSpeaker.bio}}
                                        </div>
                                        <div *ngIf="!selectedSpeaker.bio" class="paragraphe line-limit"
                                            style="height:80px ;">
                                        </div>
                                        <div class="buttons">
                                            <div class="watch-btn readmore-btn">Read More</div>
                                            <div class="watch-btn follow-btn">Follow</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="session-tags">
                                <div *ngFor="let tag of session.tags" class="tag tagsText Quicksand-light"
                                    style="height: 25px;align-self: center;">
                                    {{tag}}</div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="i<sessions.length-1 && sessions[i+1].starting_time != session.ending_time"
                        class="time-off">Time Off</div>
                    <div *ngIf="i<sessions.length-1 && sessions[i+1].starting_time == session.ending_time"
                        style="height: 20px"> </div>
                </div>
            </div>
            <div *ngIf="i>=sessions.length-1" style="height:20px ;"></div>
        </div>

    </div>
    <div id="liveBox" class="subTitle liveBox" style="text-align:center;position: fixed;overflow: hidden;"
        (mouseenter)="showRoomsName(true)" (mouseleave)="showRoomsName(false)">
        <div class="live Quicksand-semibold">
            <div class="live-icon-side">
            </div>
            <div style="text-align: center;margin-bottom:10px">Live</div>
        </div>
        <div>
            <hr style="background: #717b91; width: 1px;height: 75px;">
        </div>
        <div class="liveRooms Quicksand-light">
            <div class="smallText Quicksand-medium " style="text-align: left;">Rooms :</div>
            <div *ngFor="let room of liveRooms; let i=index"
                [ngStyle]="{'text-decoration':selectedRoom._id == room._id ? 'underline':'none'} "
                style="text-align: left;cursor: pointer;"
                class="smallText Quicksand-regular  one-line-limit ">
                <div *ngIf="i<2" (click)="GoToLiveRoom(room._id)">{{room.name}}</div>
                <div *ngIf="i>=2">More +{{liveRooms.length-i}}</div>
            </div>
        </div>
    </div>

</div>