<div *ngIf="passes && passes.length>0" style="color:white;font-family: 'Quicksand';">
    <div *ngIf="type=='ticket'" style="font-size: 22px;margin-bottom: 10px;margin-left: 10px;">Passes <span (click)="openPopup('addTicket',null)" style="cursor: pointer; border: 1px solid; padding: 0 8px;border-radius: 5px;margin-left: 10px;">+</span>
    </div>
    <!-- <div *ngIf="type=='code'" style="font-size: 22px;margin-bottom: 10px;margin-left: 10px;">Code <span (click)="openPopup('addTicket',null)" style="cursor: pointer; border: 1px solid; padding: 0 8px;border-radius: 5px;margin-left: 10px;">+</span>
    </div> -->
    <div *ngIf="type=='ticket'" style="display: inline-flex;
    flex-wrap: wrap;">
        <div *ngFor="let pass of passes;let index=index" style="margin: 10px; border: 1px solid #ccc; width: 250px;
        border-radius: 5px; display: flex;  flex-direction: column;" [style.border-color]="pass.color">
            <div [style.background-color]="pass.color" style="    display: inline-flex;
            width: 100%;align-items: center;padding:3px 0;justify-content: space-between;">
                <div>Ticket {{index+1}} </div>
                <div style="position: relative;top: -2px;">
                    <ul>
                        <li style="  width: 50px;  text-align:right; ">
                            <img src="../../../assets/blueedit.png" style="width: 20px;cursor: pointer" />
                            <ul>
                                <li> <i class="fa  fa-caret-up triangle"></i></li>
                                <li (click)="openPopup('editTicket',pass)" class="EditSession">
                                    <i class="fa fa-pencil"></i> Edit Ticket
                                </li>
                                <li style="width: 65px;border-bottom: 0.5px solid #00000029; margin: 3px auto;">
                                </li>
                                <li (click)="deleteTicket( pass._id)" class="DeleteSession">
                                    <i class="fa fa-trash"></i> Delete Ticket
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div style=" display: flex;padding: 10px; flex-direction: column;">
                <div style="display: inline-flex;">
                    <div style="margin-right: 5px;">Name:</div>
                    <div>{{pass.name}}</div>
                </div>
                <div style="display: inline-flex;">
                    <div style="margin-right: 5px;">Price:</div>
                    <div>{{pass.price}}{{pass.currency}}</div>
                </div>
                <div style="display: inline-flex;">
                    <div style="margin-right: 5px;">Quantity:</div>
                    <div>{{pass.quantity}} </div>
                </div>
                <div style="display: inline-flex;">
                    <div style="margin-right: 5px;">Available:</div>
                    <div *ngIf="pass.purchased>=0">{{pass.quantity-pass.purchased}}</div>
                </div>
                <div style="display: inline-flex;">
                    <div style="margin-right: 5px;height: 50px;" class="TwoLines">Description: {{pass.description}}
                    </div>
                </div>
                <div *ngIf="pass.vouchers" style="display: inline-flex;justify-content: space-between;">
                    <div *ngIf="pass.vouchers.length>0" style="margin-right: 5px;text-decoration: underline;cursor: pointer;" (click)="ListOfVouchers('voucherslist',pass._id)">Vouchers list : {{pass.vouchers.length}}
                    </div>
                    <div *ngIf="pass.vouchers.length==0" style="margin-right: 5px; ">Vouchers : {{pass.vouchers.length}}
                    </div>
                    <div (click)="generateVoucher('generateVoucher',pass)" style="cursor: pointer;">
                        <i class="fa fa-plus"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="type=='code'" style="display: inline-flex;">
        <div *ngFor="let pass of passes;let index=index" style="margin: 10px; border: 1px solid #383838; width: 250px;
        border-radius: 5px; display: flex;  flex-direction: column;">
            <div [style.background-color]="'#383838'" style="    display: inline-flex;
            width: 100%;
            align-items: center;padding:3px 0;
            justify-content: space-between;">
                <div>Code</div>
                <div style="position: relative;top: -2px;">
                    <ul>
                        <li style="  width: 50px;  text-align:right; ">
                            <img src="../../../assets/blueedit.png" style="width: 20px;cursor: pointer" />
                            <ul>
                                <li> <i class="fa  fa-caret-up triangle"></i></li>
                                <li (click)="openPopup('editCode',pass)" class="EditSession">
                                    <i class="fa fa-pencil"></i> Edit Code
                                </li>
                                <li style="width: 65px;border-bottom: 0.5px solid #00000029; margin: 3px auto;">
                                </li>
                                <li (click)="deleteTicket(pass._id)" class="DeleteSession">
                                    <i class="fa fa-trash"></i> Delete Code
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div style=" display: flex;padding: 10px; flex-direction: column;">
                <div style="display: inline-flex;">
                    <div style="margin-right: 5px;">Code:</div>
                    <div>{{pass.code}}</div>
                </div>
                <div style="display: inline-flex;">
                    <div style="margin-right: 5px;">Price:</div>
                    <div>{{pass.price}}{{pass.currency}}</div>
                </div>
                <div style="display: inline-flex;">
                    <div style="margin-right: 5px;">Quantity:</div>
                    <div>{{pass.quantity}} </div>
                </div>
                <div style="display: inline-flex;">
                    <div style="margin-right: 5px;">Available:</div>
                    <div *ngIf="pass.purchased>=0">{{pass.quantity-pass.purchased}} </div>
                </div>
                <div style="display: inline-flex;">
                    <div style="margin-right: 5px;height: 50px;" class="TwoLines">Description: {{pass.description}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!passes || passes && passes.length==0  " style="color:white;font-family: 'Quicksand';">
    <div style="display: inline-flex;    margin: 50px 0;
    width: 100%;  align-items: center; justify-content: center;">
        <div (click)="openPopup('addTicket',null)" style="cursor: pointer; width: 200px;height: 200px;display: flex;align-items: center;justify-content: center;border: 1px solid #ccc;border-radius: 5px;margin: 10px;">
            Add Tickets
        </div>
        <div (click)="openPopup('addCode',null)" style="cursor: pointer; width: 200px;height: 200px;display: flex;align-items: center;justify-content: center;border: 1px solid #ccc;border-radius: 5px;margin: 10px;">
            Generate code
        </div>
    </div>
</div>