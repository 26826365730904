<div style="display: inline-flex;width: 100%; ">
    <div style="width: 15%;">
        <nb-sidebar>
            <div class="sidebar" id="nb-sidebar">
                <div *ngIf="is_admin">
                    <div style="font-size:12px; margin: 15px 5px; ">Explore Events </div>
                    <div>
                        <div class="Item" [ngClass]="index == -1 ? 'activatedItem' : ''" (click)="index=-1"
                            style="    display: inline-flex; align-items: center;justify-content: space-between;  width: 100%;">
                            <div style="    display: inline-flex; align-items: center;  ">
                                <!-- <div [style.background-image]="'url(../../../assets/v2.png)'" [style.background-size]="'cover'" [style.background-position]="'center'" [style.background-repeat]="'no-repeat'" style="width: 30px; height: 30px;  
                       filter: invert(1);   border: 1px; border-radius: 50%;  overflow: hidden;  margin-right: 5px;">
                            </div> -->
                                <img src="../../../assets/v2.png" style="width: 30px;
                           
                            overflow: hidden;
                            margin-right: 5px;" />

                                <span class="one-line-limit" style="max-width: 170px;">Manage explore</span>
                            </div>

                            <!-- <div *ngIf="is_admin || organisation.role=='owner'">
                                <i class="fa fa-cog Settings" style="cursor: pointer;" (click)="navigate(organisation._id)" aria-hidden="true"></i>
    
                            </div> -->

                        </div>

                    </div>

                </div>
                <div>


                    <div style="font-size:12px; margin: 15px 5px; ">Organisations

                    </div>
                    <div *ngFor="let organisation of organisations; let i=index;let last=last">
                        <div class="Item" [ngClass]="index == i ? 'activatedItem' : ''" (click)="index=i"
                            style="    display: inline-flex; align-items: center;justify-content: space-between;  width: 100%;">
                            <div style="    display: inline-flex; align-items: center;  ">
                                <div *ngIf="organisation.logo" [style.background-image]="'url('+organisation.logo+')'"
                                    [style.background-size]="'cover'" [style.background-position]="'center'"
                                    [style.background-repeat]="'no-repeat'" style="width: 30px; height: 30px;  
                      border: 1px; border-radius: 50%;  overflow: hidden;  margin-right: 5px;">
                                </div>
                                <span class="one-line-limit" style="max-width: 170px;">{{ organisation.name}}</span>
                            </div>

                            <div *ngIf="is_admin || organisation.role=='owner'">
                                <i class="fa fa-cog Settings" style="cursor: pointer;"
                                    (click)="navigate(organisation._id)" aria-hidden="true"></i>

                            </div>

                        </div>

                    </div>
                    <div *ngIf=" can_add" (click)="AddOrganisation()" class="Item"
                        style="margin-top:30px  ;  font-size: 16px;  text-align: center;  border: 1px solid; border-radius: 5px;">
                        Add Organisation</div>
                </div>
            </div>
        </nb-sidebar>
    </div>
    <div style="width: 88%;">
        <nb-layout-column>
            <div *ngIf="index != -1"
                style="color:white ; padding: 10px;font: normal normal normal 14px/22px Quicksand;">
                <div
                    *ngIf="organisations && organisations[index] && organisations[index].events && organisations[index].events.length>0">
                    <div style="font-size: 22px;margin-bottom: 10px;margin-left: 10px;">Events List
                        <!-- *ngIf="is_admin || organisations[index].role=='owner'"  -->
                        <span (click)="openPopup('addevent',null,null)"
                            style="cursor: pointer; border: 1px solid; padding: 0 8px;border-radius: 5px;margin-left: 10px;">+</span>
                    </div>
                    <div *ngFor="let event of organisations[index].events;let i=index " style="display: inline-flex;"
                        (click)="openEvent(event )">
                        <div
                            style="display:  flex;flex-direction: column;box-shadow: 0 0 0px 2px black;border-radius: 5px;margin: 10px;overflow: hidden;width: 250px;">
                            <div *ngIf="event.cover_url" [style.background-image]="'url('+event.cover_url+')'"
                                style="width: 250px;height:150px;background-size: cover; background-repeat: no-repeat;">
                                <ul>
                                    <li style="  width: 50px;  text-align:right; ">
                                        <img src="../../../assets/blueedit.png" style="width: 20px;cursor: pointer" />
                                        <ul>
                                            <li> <i class="fa  fa-caret-up triangle"></i></li>

                                            <li (click)="deleteEvent( event._id, organisations[index]._id)"
                                                class="DeleteSession">
                                                <i class="fa fa-times"></i> Delete event
                                            </li>
                                            <li
                                                style="width: 65px;border-bottom: 0.5px solid #00000029; margin: 3px auto;">
                                            </li>
                                            <li (click)="permanentlyDeleteEvent( event._id, organisations[index]._id)"
                                                class="PermanentlyDeleteSession">
                                                <i class="fa fa-trash"></i> Permanently delete event
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div *ngIf="!event.cover_url" [style.background-image]="'url(../../../assets/check2.png)'"
                                style="width: 250px;height:150px; background-repeat: no-repeat;">
                                <ul>
                                    <li style="  width: 50px;  text-align:right; ">
                                        <img src="../../../assets/blueedit.png" style="width: 20px;cursor: pointer" />
                                        <ul>
                                            <li> <i class="fa  fa-caret-up triangle"></i></li>

                                            <li (click)="deleteEvent(event._id,organisations[index]._id)"
                                                class="DeleteSession">
                                                <i class="fa fa-times"></i> Delete event
                                            </li>
                                            <li
                                                style="width: 65px;border-bottom: 0.5px solid #00000029; margin: 3px auto;">
                                            </li>
                                            <li (click)="permanentlyDeleteEvent(event._id,organisations[index]._id)"
                                                class="PermanentlyDeleteSession">
                                                <i class="fa fa-trash"></i> Permanently delete event
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div style="padding: 5px;cursor: pointer;"
                                [routerLink]="['/dashboard/', event._id,'event' ]">
                                <div>
                                    <span style="text-decoration: underline;"> Title </span>:
                                    <span> {{event.title}} </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf=" !organisations || !organisations[index] || !organisations[index].events ||  organisations[index].events && organisations[index].events.length == 0">
                    No event was created! <span (click)="openPopup('addevent',null,null)"
                        style="cursor: pointer; border: 1px solid; padding: 0 8px;border-radius: 5px;margin-left: 10px;">+</span>

                </div>
            </div>
            <div *ngIf="index == -1">
                <app-manage-explore></app-manage-explore>
            </div>
        </nb-layout-column>
    </div>




</div>



<!-- <div style="color:white ;font: normal normal normal 14px/22px Quicksand;">
    <div style="display: inline-flex;width: 100%;">
     

       
    </div>
</div> -->