import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardEventRoutingModule } from './dashboard-event-routing.module';
import { DashboardEventComponent } from './dashboard-event.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    DashboardEventComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    DashboardEventRoutingModule
  ]
})
export class DashboardEventModule { }
