<div *ngIf="event"
    style="display: inline-flex; width: 100%;justify-content: space-between;font-family: Quicksand !important;overflow-x:hidden ;">
    <div style="width: 30%; color: white;    padding-right: 10px;
    border-right: 1px solid #282a33;">

        <div>
            <form [formGroup]="EventForm">
                <nb-accordion>
                    <nb-accordion-item expanded="true">
                        <nb-accordion-item-header>
                            Basic
                        </nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <div>
                                <div style=" margin-top: 20px; ">
                                    <div style=" margin-top: 20px;   margin-bottom: 5px;;">Title</div>
                                    <input type="text" id="eventshortdescription" class="form-control"
                                        formControlName="title" style="border-color: #ccc; "
                                        (input)="limitInput($event,'title')" placeholder="Add a title to your event"
                                        [ngClass]="{'is-invalid': submitted && formControls.title.errors}" />
                                    <div style="color: red;    font-size: 12px;">{{errorsMsg.title}}</div>
                                </div>
                                <div style=" margin-top: 20px;  ">
                                    <div style=" margin-top: 20px;   margin-bottom: 5px;">Short summary</div>
                                    <textarea type="text" id="eventshortdescription" class="form-control"
                                        (input)="limitInput($event,'short_summary')" formControlName="short_summary"
                                        style="border-color: #ccc;  height: 5rem; resize: none "
                                        placeholder="Add a short summary to your event"
                                        [ngClass]="{'is-invalid': submitted && formControls.short_summary.errors}"></textarea>
                                    <div style="color: red;    font-size: 12px;">{{errorsMsg.short_summary}}</div>
                                </div>
                                <div
                                    style="display: inline-flex;justify-content: space-between;width:  100%; margin-top: 20px; ">
                                    <div style="width: 45%;">
                                        <div style="  margin-bottom: 5px;">Starting on</div>
                                        <input type="datetime-local" id="eventstartdate" class="form-control"
                                            formControlName="start_date" style="border-color: #ccc;"
                                            [ngClass]="{'is-invalid': submitted && formControls.start_date.errors}" />
                                    </div>
                                    <div style="width: 45%;">
                                        <div style="  margin-bottom: 5px;">Ending on</div>
                                        <input type="datetime-local" id="eventenddate" class="form-control"
                                            formControlName="end_date" style="border-color: #ccc;"
                                            [ngClass]="{'is-invalid': submitted && formControls.end_date.errors}" />
                                    </div>
                                </div>
                                <div style=" margin-top: 20px;  ">
                                    <div style="  margin-bottom: 5px;">Timezone</div>
                                    <ng-select bindLabel=" name" class="form-control" style="color: white;"
                                        (change)="selectUTC($event)" (clear)="clearSelected($event)"
                                        formControlName="utcoffset">
                                        <ng-option value="-12">(UTC-12:00) Eniwetok, Kwajalein</ng-option>
                                        <ng-option value="-11">(UTC-11) Midway Island, Samoa</ng-option>
                                        <ng-option value="-10">(UTC-10) Hawaii</ng-option>
                                        <ng-option value="-9.5">(UTC-9:30) Taiohae</ng-option>
                                        <ng-option value="-9">(UTC-9) Alaska</ng-option>
                                        <ng-option value="-8">(UTC-8) Pacific Time (US &amp; Canada)</ng-option>
                                        <ng-option value="-7">(UTC-7) Mountain Time (US &amp; Canada)</ng-option>
                                        <ng-option value="-6">(UTC-6) Central Time (US &amp; Canada), Mexico City
                                        </ng-option>
                                        <ng-option value="-5">(UTC-5) Eastern Time (US &amp; Canada), Bogota, Lima
                                        </ng-option>
                                        <ng-option value="-4.5">(UTC-4:30) Caracas</ng-option>
                                        <ng-option value="-4">(UTC-4) Atlantic Time (Canada), Caracas, La Paz
                                        </ng-option>
                                        <ng-option value="-3.5">(UTC-3:30) Newfoundland</ng-option>
                                        <ng-option value="-3">(UTC-3) Brazil, Buenos Aires, Georgetown</ng-option>
                                        <ng-option value="-2">(UTC-2) Mid-Atlantic</ng-option>
                                        <ng-option value="-1">(UTC-1) Azores, Cape Verde Islands</ng-option>
                                        <ng-option value="0" selected="selected">(UTC) Western Europe Time, London,
                                            Lisbon, Casablanca
                                        </ng-option>
                                        <ng-option value="1">(UTC+1) Brussels, Copenhagen, Madrid, Paris</ng-option>
                                        <ng-option value="2">(UTC+2) Kaliningrad, South Africa</ng-option>
                                        <ng-option value="3">(UTC+3) Baghdad, Riyadh, Moscow, St. Petersburg</ng-option>
                                        <ng-option value="3.5">(UTC+3:30) Tehran</ng-option>
                                        <ng-option value="4">(UTC+4) Abu Dhabi, Muscat, Baku, Tbilisi</ng-option>
                                        <ng-option value="4.5">(UTC+4:30)Kabul</ng-option>
                                        <ng-option value="5">(UTC+5) Ekaterinburg, Islamabad, Karachi, Tashkent
                                        </ng-option>
                                        <ng-option value="5.5">(UTC+5:30) Bombay, Calcutta, Madras, New Delhi
                                        </ng-option>
                                        <ng-option value="5.75">(UTC+5:45) Kathmandu, Pokhara</ng-option>
                                        <ng-option value="6">(UTC+6) Almaty, Dhaka, Colombo</ng-option>
                                        <ng-option value="6.5">(UTC+6:30) Yangon, Mandalay</ng-option>
                                        <ng-option value="7">(UTC+7) Bangkok, Hanoi, Jakarta</ng-option>
                                        <ng-option value="8">(UTC+8) Beijing, Perth, Singapore, Hong Kong</ng-option>
                                        <ng-option value="8.75">(UTC+8:45) Eucla</ng-option>
                                        <ng-option value="9">(UTC+9) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</ng-option>
                                        <ng-option value="9.5">(UTC+9:30) Adelaide, Darwin</ng-option>
                                        <ng-option value="10">(UTC+10) Eastern Australia, Guam, Vladivostok</ng-option>
                                        <ng-option value="10.5">(UTC+10:30) Lord Howe Island</ng-option>
                                        <ng-option value="11">(UTC+11) Magadan, Solomon Islands, New Caledonia
                                        </ng-option>
                                        <ng-option value="11.5">(UTC+11:30) Norfolk Island</ng-option>
                                        <ng-option value="12">(UTC+12) Auckland, Wellington, Fiji, Kamchatka</ng-option>
                                        <ng-option value="12.75">(UTC+12:45) Chatham Islands</ng-option>
                                        <ng-option value="13">(UTC+13) Apia, Nukualofa</ng-option>
                                        <ng-option value="14">(UTC+14) Line Islands, Tokelau</ng-option>
                                    </ng-select>
                                </div>

                                <div style=" margin-top: 20px;  " class="form-group">
                                    <div class="flex-container" class="btn-group btn-group-toggle" data-toggle="buttons"
                                        style="display: inline-flex;align-items: center;">
                                        <div style="display: inline-flex;align-items: center;">
                                            <input type="radio" id="session" value="session" name="type"
                                                formControlName="type" />
                                            <label class="flex-item btn   flex-item-config" for="session">
                                                Session
                                            </label>
                                        </div>
                                        <div style="display: inline-flex;align-items: center;">
                                            <input type="radio" id="channel" value="channel" name="type"
                                                formControlName="type" />
                                            <label class="flex-item btn  flex-item-config" for="channel">
                                                Channel </label>

                                        </div>
                                        <div style="display: inline-flex;align-items: center;">
                                            <input type="radio" id="hybrid" value="hybrid" name="type"
                                                formControlName="type" />
                                            <label class="flex-item btn  flex-item-config" for="hybrid">
                                                Hybrid </label>

                                        </div>
                                        <div style="display: inline-flex;align-items: center;">
                                            <input type="radio" id="virtual_venue" value="virtual venue" name="type"
                                                formControlName="type" />
                                            <label class="flex-item btn  flex-item-config" for="virtual_venue">
                                                Virtual Venue </label>

                                        </div>
                                    </div>
                                </div>


                                <div style=" margin-top: 20px; ">
                                    <div style=" margin-top: 20px;   margin-bottom: 5px;;">Cover</div>
                                    <input id="editcover" type="file" class="form-control" (click)="clickInput($event)"
                                        (change)="readURL($event)"
                                        accept="image/png, .jpeg, .jpg, image/gif,video/mp4,video/x-m4v,video/*"
                                        style="border-color: #ccc;" />
                                    <div>
                                        {{errorMessage.cover}}
                                    </div>
                                </div>
                                <div style=" margin-top: 20px; ">
                                    <div style=" margin-top: 20px;   margin-bottom: 5px;">Tags</div>
                                    <input type="text" id="InputTag" class="form-control" style="border-color: #ccc;"
                                        placeholder="Add tags to your event" (keydown.enter)="addTag($event)" />
                                    <div class="event-tags" style="margin-top: 5px;">
                                        <div *ngFor="let tag of tags;let index=index" class="tag  "
                                            style="font:normal normal normal 14px/20px Quicksand">
                                            {{tag | titlecase}} <span style=" cursor: pointer;   margin-left: 5px;"
                                                (click)="removeTag(index)"><i class="fa fa-times-circle "
                                                    aria-hidden="true"></i>
                                            </span></div>
                                    </div>
                                </div>
                            </div>
                        </nb-accordion-item-body>
                    </nb-accordion-item>

                    <nb-accordion-item>
                        <nb-accordion-item-header>
                            Details
                        </nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <div>
                                <div style=" margin-top: 20px; ">
                                    <div style=" margin-top: 20px;   margin-bottom: 5px;">Description</div>
                                    <ckeditor [config]="{ toolbar: [   'heading', '|',
                                    'fontfamily', 'fontsize', '|',
                                    'alignment', '|',
                                    'fontColor', 'fontBackgroundColor', '|',
                                    'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                                    'link', '|',
                                    'outdent', 'indent', '|',
                                    'bulletedList', 'numberedList', 'todoList', '|',
                                    'undo', 'redo'  ]  }" [editor]="Editor" [data]="editorData" name="description"
                                        formControlName="description">
                                    </ckeditor>
                                </div>
                                <div style=" margin-top: 20px; ">
                                    <div style=" margin-top: 20px;   margin-bottom: 5px;">Sponsors</div>
                                    <input type="file" class="form-control"
                                        (change)="readMultipleURL('sponsors',$event)" (click)="clickInput($event)"
                                        style="border-color: #ccc;" accept="image/png, .jpeg, .jpg, image/gif" />
                                    <div style="display: flex; flex-direction: row;flex-wrap: wrap;">
                                        <div *ngFor="let sponsor of formControls.sponsors.value;let index=index"
                                            class="tag  " style="font:normal normal normal 14px/20px Quicksand">
                                            {{sponsor}} <span style=" cursor: pointer;   margin-left: 5px;"
                                                (click)="removeSponsorForm(index)"><i class="fa fa-times-circle "
                                                    aria-hidden="true"></i>
                                            </span></div>
                                        <div *ngFor="let sponsor of sponsors let index=index;" class="tag  "
                                            style="font:normal normal normal 14px/20px Quicksand">
                                            {{sponsor.name}} <span style=" cursor: pointer;   margin-left: 5px;"
                                                (click)="removeSponsor(index)"><i class="fa fa-times-circle "
                                                    aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        {{errorMessage.sponsors}}
                                    </div>
                                </div>
                                <div style=" margin-top: 20px; ">
                                    <div style=" margin-top: 20px;   margin-bottom: 5px;">Videos</div>
                                    <input type="file" class="form-control" (change)="readMultipleURL('videos',$event)"
                                        (click)="clickInput($event)" style="border-color: #ccc;" accept="video/*" />
                                    <div style="display: flex; flex-direction: row;flex-wrap: wrap;">
                                        <div *ngFor="let video of formControls.videos.value;let index=index"
                                            class="tag  " style="font:normal normal normal 14px/20px Quicksand">
                                            {{video}} <span style=" cursor: pointer;   margin-left: 5px;"
                                                (click)="removeVideoForm(index)"><i class="fa fa-times-circle "
                                                    aria-hidden="true"></i>
                                            </span></div>
                                        <div *ngFor="let video of videos let index=index;" class="tag  "
                                            style="font:normal normal normal 14px/20px Quicksand">
                                            {{video.name}} <span style=" cursor: pointer;   margin-left: 5px;"
                                                (click)="removeVideo(index)"><i class="fa fa-times-circle "
                                                    aria-hidden="true"></i>
                                            </span></div>
                                    </div>
                                    <div>{{errorMessage.video}}</div>

                                </div>
                            </div>
                        </nb-accordion-item-body>
                    </nb-accordion-item>
                    <nb-accordion-item>
                        <nb-accordion-item-header>
                            Event areas
                        </nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <div>
                                <div>
                                    <div style="display: inline-flex;flex-wrap: wrap;width: 100%;">
                                        <!-- <input type="checkbox" name="sessions" [checked]="formControls.areas && formControls.areas.value && formControls.areas.value.includes('sessions')" (change)="selectArea($event,'sessions')" class="sub-title" style="color: #707070;" /> -->
                                        <div style="width: 50%;">
                                            <nb-checkbox name="sessions"
                                                [checked]=" areas && areas.includes('sessions')"
                                                (checkedChange)="selectArea($event,'sessions')" class="sub-title"
                                                style="color: #707070;">Sessions </nb-checkbox>
                                        </div>
                                        <div style="width: 50%;">
                                            <nb-checkbox name="exhibitors"
                                                [checked]=" areas && areas.includes('exhibitors')"
                                                (checkedChange)="selectArea($event,'exhibitors')" class="sub-title"
                                                style="color: #707070;">Exhibitors </nb-checkbox>
                                        </div>
                                        <div style="width: 50%;">
                                            <nb-checkbox name="networking"
                                                [checked]=" areas && areas.includes('networking')" class="sub-title"
                                                style="color: #707070;" disabled>Networking </nb-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nb-accordion-item-body>
                    </nb-accordion-item>


                </nb-accordion>


            </form>
            <div style=" margin-top: 20px;  width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;">
                <div (click)="saveChanges()" style=" margin: auto;   width: 100px;
                border: 1px solid;cursor: pointer;
                border-radius: 5px;    padding: 5px;
                text-align: center;"> Save</div>
            </div>
        </div>
    </div>
    <!-- <div style="    width: 70%;min-width: 630px;">
        <div class="event-card" id="event-card" style="padding: 20px 50px;">
             <div style="    width: 30%; ">


                <div *ngIf="imageSrc && imageSrc !='' && imageSrc.split(';')[0].includes('image')" class="picture">
                    <div>
                        <img *ngIf=" imageSrc &&  imageSrc !=''" [src]="imageSrc">
                    </div>
                </div>
                <div *ngIf="imageSrc && imageSrc !='' && imageSrc.split(';')[0].includes('video')" class="picture" style="padding-top:0 ;">

                    <div>
                        <video autoplay="autoplay" loop muted style="width: 100%;">
                            <source *ngIf=" imageSrc &&  imageSrc !=''" [src]="imageSrc" type="video/mp4;">
                        </video>
                    </div>

                </div>


                <div *ngIf="(!imageSrc || imageSrc =='' && event.cover_url) && event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]!='mp4'" class="picture">
                    <div *ngIf="event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]!='mp4'">
                        <img [src]="event.cover_url">
                    </div>
                </div>
                <div *ngIf="(!imageSrc || imageSrc =='' && event.cover_url) && event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]=='mp4'" class="picture" style="padding-top:0 ;">

                    <div *ngIf="event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]=='mp4'">
                        <video autoplay="autoplay" loop muted style="width: 100%;">
                            <source [src]="event.cover_url" type="video/mp4;">
                        </video>
                    </div>

                </div>



 




            </div>
             <div *ngIf="event" class="event-details " style="width: 65%;margin-left:0;">
                <div class="event-infos" style=" margin:0 5px;">
                    <div *ngIf="event.type" class="event-type sub-title "> {{event.type | titlecase}}</div>
                    <div *ngIf="event.title" class="event-title title4 TwoLines" style="    font-size: 20px;margin: 0;">
                        {{ formControls.title.value | titlecase}} </div>
                    <div *ngIf="event.short_summary" class="event-short-desc title1 line-limit"> {{ formControls.short_summary.value | titlecase}}
                    </div>
                    <div style="height: 30px;">
                        <div class="event-tags" style="      width: 15vw;  padding: 3px; margin: 0;">
                            <div class="tag sub-title" *ngFor="let tag of tags">
                                <div> {{tag | titlecase}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="event-organizer" *ngIf=" event.organizer" style="padding: 10px; width: unset;min-width: unset; max-width: 200px;margin: 0;">
                        <span class="subTitle white-color" style="text-align: left;    padding: 0;">Organizer</span>
                        <div class="content">
                            <img [src]="event.organizer.logo">
                            <p class="sub-title line-limit " style="align-self: center;">{{event.organizer.description | titlecase}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="date-card" style="min-width: 200px;    height: 250px;margin: 0;">
                    <div class="days-datails" *ngIf="event.days && event.days.length == 1" style="justify-content:  space-between;">
                        <div>
                            <div class="day-card" style="    width: 70px;padding: 0;">
                                <span id="month" class="sub-title white-color" style="text-align: center;"> {{
                                    convertDate(formControls.start_date.value,
                                    formControls.end_date.value,formControls.utcoffset.value)[0].split(' ')[0]
                                    }}</span>
                                <span id="day"> {{ convertDate(formControls.start_date.value,
                                    formControls.end_date.value,formControls.utcoffset.value)[0].split('
                                    ')[1] }}</span>
                                <span id="year" class="sub-title white-color" style="text-align: center;"> 2022 </span>
                            </div>
                            <div class="time-details" style="font-size: 13px;">
                                <div class="sub-title">Start : &nbsp;<span class="sub-title">{{
                                        convertDate(formControls.start_date.value,
                                        formControls.end_date.value,formControls.utcoffset.value)[0].split(' ')[2]
                                        }}</span></div>
                                <div class="sub-title">End : &nbsp;<span>{{ convertDate(formControls.start_date.value,
                                        formControls.end_date.value,formControls.utcoffset.value)[1].split(' ')[2]
                                        }}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="days-datails" *ngIf="event.days && event.days.length > 1" style="justify-content:  space-between;">
                        <div>
                            <div class="day-card" style="    width: 70px;padding: 0;">
                                <span id="month" class="sub-title white-color" style="text-align: center;"> {{
                                    convertDate(formControls.start_date.value,
                                    formControls.end_date.value,formControls.utcoffset.value)[0].split(' ')[0]
                                    }}</span>
                                <span id="day"> {{ convertDate(formControls.start_date.value,
                                    formControls.end_date.value,formControls.utcoffset.value)[0].split('
                                    ')[1] }}</span>
                                <span id="year" class="sub-title white-color" style="text-align: center;"> 2022 </span>
                            </div>
                            <div class="time-details" style="font-size: 13px;">
                                <div>Start : &nbsp;<span>{{ convertDate(formControls.start_date.value,
                                        formControls.end_date.value,formControls.utcoffset.value)[0].split(' ')[2]
                                        }}</span></div>
                            </div>
                        </div>
                        <div>
                            <div class="day-card" style="    width: 70px;padding: 0;">
                                <span id="month" class="sub-title white-color" style="text-align: center;"> {{
                                    convertDate(formControls.start_date.value,
                                    formControls.end_date.value,formControls.utcoffset.value)[1].split(' ')[0]
                                    }}</span>
                                <span id="day"> {{ convertDate(formControls.start_date.value,
                                    formControls.end_date.value,formControls.utcoffset.value)[1].split('
                                    ')[1] }}</span>
                                <span id="year" class="sub-title white-color" style="text-align: center;"> 2022 </span>
                            </div>
                            <div class="time-details" style="font-size: 13px;">
                                <div>End : &nbsp;<span>{{ convertDate(formControls.start_date.value,
                                        formControls.end_date.value,formControls.utcoffset.value)[1].split(' ')[2]
                                        }}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="access-details" style="margin: 0;">
                        <div class="action-btn subTitle" style="color:black;font-size: 15px;">
                            JOIN EVENT
                        </div>
                        <div class="social-media ">
                            <span class="sub-title">Share this event on : </span>
                            <div>
                                <img src="../../../assets/portal/icons/twitter.png">
                                <img src="../../../assets/portal/icons/facebook.png">
                                <img src="../../../assets/portal/icons/linkdin.png">
                                <img src="../../../assets/portal/icons/URL.png">
                                <div class="calendar-icon"> <img src="../../../assets/portal/icons/calendar.png"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tabs-container">
            <div id="tabButtons" class="tab-buttons" style="height: 50px;padding: 0 50px;">
                <button id="descBtn" class="tablinks subTitle " style="height: 50px;font-size: 18px;" (click)="currentButton='description'">Description</button>
                <button *ngIf="areas && areas.includes('sessions')" id="sessionBtn" class="tablinks subTitle" style="height: 50px;font-size: 18px;" (click)="currentButton='session'">Sessions</button>
                <button id="speakerBtn" class="tablinks subTitle" style="height: 50px;font-size: 18px;" (click)="currentButton='speaker'">Speakers
                </button>
                <button *ngIf="areas  && areas.includes('exhibitors')" id="exhibitorBtn" class="tablinks subTitle" style="height: 50px;font-size: 18px;" (click)="currentButton='exhibitor'">Exhibitors</button>
            </div>
            <div style="padding: 0 50px;">
                <div *ngIf="currentButton=='description'">
                    <div class="title1">
                        <div [innerHTML]="formControls.description.value "></div>
                    </div>
                    <div *ngIf="formControls.sponsors.value && formControls.sponsors.value.length>0 || sponsorsSrc && sponsorsSrc.length>0">
                        <span> Sponsors </span>
                        <div style="display: inline-flex;width: 100%;flex: wrap;">
                            <div class="sponsor-container">
                                <img *ngFor="let url of formControls.sponsors.value" [src]=url style="width: 200px; height: 100px; margin: 5px;padding: 0;" />
                                <img *ngFor="let url of sponsorsSrc" [src]=url style="width: 200px; height: 100px; margin: 5px;padding: 0;" />
                            </div>
                        </div>
                    </div>
                    <div *ngIf="formControls.videos.value && formControls.videos.value.length>0 ||videoSrc && videoSrc.length>0">
                        <span> Videos </span>
                        <div style="display: inline-flex;width: 100%;flex: wrap;">
                            <div class="sponsor-container">
                                <video *ngFor="let url of formControls.videos.value" autoplay="autoplay" loop muted style="width: 40%;     margin: 5px;
                               ">
                                    <source [src]=url type="video/mp4;">
                                </video>
                                <video *ngFor="let url of videoSrc" autoplay="autoplay" loop muted style="width: 40%;     margin: 5px;
                               ">
                                    <source [src]=url type="video/mp4;">
                                </video>






                            </div>
                        </div>
                    </div>
                </div>
                 </div>
        </div>
    </div> -->

    <app-portal-event
        [edit_event]="'true'" [event_title]="formControls.title.value" [event_type]="formControls.type.value"
        [event_short_summary]="formControls.short_summary.value" [event_start_date]="formControls.start_date.value"
        [event_end_date]="formControls.end_date.value" [event_utcoffset]="formControls.utcoffset.value"
        [event_tags]="formControls.tags.value" [event_cover_url]="imageSrc"
        [event_description]="formControls.description.value"></app-portal-event>

<!-- 
     *ngIf="formControls.type.value =='hybrid' || formControls.type.value ==' virtual venue'"
        <app-event-session *ngIf="formControls.type.value =='session' "
        [edit_event]="'true'" [event_title]="formControls.title.value" [event_type]="formControls.type.value"
        [event_short_summary]="formControls.short_summary.value" [event_start_date]="formControls.start_date.value"
        [event_end_date]="formControls.end_date.value" [event_utcoffset]="formControls.utcoffset.value"
        [event_tags]="formControls.tags.value" [event_cover_url]="imageSrc"
        [event_description]="formControls.description.value"></app-event-session> -->

</div>