import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import Swal from 'sweetalert2';
import { DashboardTestService } from '../dashboard-test.service';
import { SendInvitationComponent } from '../send-invitation/send-invitation.component';
@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.css']
})
export class InvitationsComponent implements OnInit {
  invitations
  eventID
  access=''
  constructor(public dashboardservice: DashboardTestService, public dialog: NbDialogService, private route: ActivatedRoute) { }
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.eventID = params["eventid"];
    });
    this.access=this.dashboardservice.access
    this.dashboardservice.onChangedInvitationsByEventid.subscribe((res) => {
      if (res && res.status == "success") {
        this.invitations = res.invitations
      }
    })
  }
  sendInvitation() {
    const dialogRef = this.dialog.open(SendInvitationComponent, {
      context : 
      {

        data: {
          eventid: this.eventID,
          name:"invite to event",
          event_access:this.access
        },
      },      hasBackdrop:false

    });
    dialogRef.onClose.subscribe((result) => {
    });
  }
  deleteInvitation(invitationid) {
    Swal.fire({
      title: "Are you sure want to remove this invitation?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.dashboardservice.deleteInvitation(invitationid, this.eventID,null).then((res: any) => {
          Swal.fire(res.message, "", res.status);
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "", "error");
      }
    });
  }
}
