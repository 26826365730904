import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfilesServiceService {
  url: string = environment.url
  onChangeSpeaker: BehaviorSubject<any>;
  onChangeSession: BehaviorSubject<any>;
  params
  constructor(private http: HttpClient, private router: Router, private cookieService: CookieService) {
    this.onChangeSpeaker = new BehaviorSubject([]);
    this.onChangeSession = new BehaviorSubject([]);
  }
  resolve(route: ActivatedRouteSnapshot) {
    this.params = route.params;
    return new Promise<void>((resolve, reject) => {
      Promise.all([
      this.getUserById()
      ]).then(
        () => {
          resolve();
        },
        (reject) => {
        }
      );
    });
  }
  handleError = (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) {
    }
    else {

      if (error.status == 401) {
        this.router.navigateByUrl('/login');
      }

      return throwError('Something bad happened; please try again later.');
    }
  }
  getUserById() {    
    if (this.params && this.params.id) {
      return new Promise((resolve, reject) => {
        this.http.get(this.url + "/users/speaker/" + this.params.id, {withCredentials: true})
          .pipe(catchError(this.handleError))
          .subscribe((response: any) => {            
            if (response.status == "success") {
              this.onChangeSpeaker.next(response.speaker);
              this.onChangeSession.next(response.sessions);
            }
            resolve(response);
          }, reject);
      });
    }
  }
}
