<nb-layout>
    <nb-layout-header *ngIf="!hide">
        <app-navbar></app-navbar>
    </nb-layout-header>
    <nb-layout-column id="nb-layout-column" style="height: 100%;">
        <app-toastr></app-toastr>
        <div>
            <router-outlet></router-outlet>
        </div>
    </nb-layout-column>
</nb-layout>