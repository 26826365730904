import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

 


  
  transform(value: any, args?: any): any {


    
      return value.replace(/_/g, ' ').replace(/-/g, ' ').trim()
  
  

  }

}
