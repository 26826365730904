import { Component, HostListener, OnInit, ViewChildren } from '@angular/core';
import { PortalService } from '../portal.service';
import * as lodash from 'lodash';
import { Router } from '@angular/router';
import { VideoPlayerComponent } from 'src/app/video-player/video-player.component';
import { NbDialogService } from '@nebular/theme';

@Component({
  selector: 'app-event-channel',
  templateUrl: './event-channel.component.html',
  styleUrls: ['./event-channel.component.css', '../portal-event/session/session.component.css']
})
export class EventChannelComponent implements OnInit {
  @ViewChildren('video') video: any;
  event
  cover
  speakers
  sessions
  newLoaded_sessions
  rightArrowValue: number;
  timerRooms
  overflowActivated: boolean = false
  selectedVideo: string;
  selectedFSpeaker: any;
  selectedFSession: any;
  speaker: any;
  showFSpeaker: boolean;
  current_number_sessions
  total_sessions
  more_sessions_channel = { page: '1', limit: '6' };
  constructor(private portalservice: PortalService, public router: Router,public dialog: NbDialogService) { }

  ngOnInit(): void {
    this.portalservice.onChangedPublicEventById.subscribe((res) => {
      if (res) {

        if (res.type == "channel") {

          this.event = res
          if (!res.cover_url) {
            this.cover = "../../../assets/portal/cover.png"
          }
          else {
            this.cover = res.cover_url
          }
          this.portalservice.getMoreSesionsByChannelId(this.more_sessions_channel).then((res1: any) => {
            if (res1) {
              this.sessions = res1.sessions
              this.total_sessions = res1.total
              this.current_number_sessions = res1.sessions.length
            }
          })
          this.portalservice.getMoreSpeakers("1").then((res2: any) => {
            this.speakers = res2.speakers;
          })
        }
        else {
          this.router.navigateByUrl("/").then((res) => { });
        }
      }
    });
  }
  ngAfterViewInit() {
    if (document.getElementById("tags") && document.getElementById("event-tags-container")) {
      if (document.getElementById("tags").scrollWidth > document.getElementById("event-tags-container").offsetWidth) {
        this.overflowActivated = true
      }
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (document.getElementById('event-infos') && document.getElementById('tags') && document.getElementById('tags').style && document.getElementById('event-infos').offsetWidth) {
      document.getElementById('tags').style.maxWidth = (document.getElementById('event-infos').offsetWidth).toString() + 'px'
    }
    if (document.getElementById("tags") && document.getElementById("event-tags-container")) {
      if (document.getElementById("tags").scrollWidth > document.getElementById("event-tags-container").offsetWidth) {
        this.overflowActivated = true
      }
      else {
        this.overflowActivated = false
        this.rightArrowValue = 0
      }
    }
  }
  scrollDivRooms(elementToScroll: HTMLElement, depl, div, arrowId) {
    if (this.event.tags && this.event.tags.length > 0) {
      if (document.getElementById(arrowId) != null) {
        elementToScroll = document.getElementById("tags") as HTMLInputElement
        elementToScroll.scrollLeft -= depl;
        this.rightArrowValue = elementToScroll.scrollLeft
        this.timerRooms = setTimeout(() => {
          this.scrollDivRooms(elementToScroll, depl, div, arrowId)
        }, 30);
      }
    }
  }
  stopTimerRooms(timerRooms: number) {
    clearTimeout(timerRooms);
  }
  openVideo(i) {
    const dialogRef = this.dialog.open(VideoPlayerComponent, {
      context: {
        data: {
          sessions : this.sessions,
          index : i,
          title : this.event.title
        }
      },
      hasBackdrop: false
    }).onClose.subscribe((result) => {});
  }
  playVideo(id, play) {
    let div = lodash.find(this.video.toArray(), { 'nativeElement': document.getElementById(id) });

    if (div) {
      if (play) {
        this.selectedVideo = id
        div.nativeElement.play()
        if (document.getElementById(id) && document.getElementById("whitePlay" + id) && document.getElementById("redPlay" + id)) {

          document.getElementById(id).classList.add("hover")
          document.getElementById(id).classList.remove("notHover")
          document.getElementById("whitePlay" + id).style.visibility = "hidden"
          document.getElementById("whitePlay" + id).style.position = "absolute !important"
          document.getElementById("redPlay" + id).style.visibility = "visible"
          document.getElementById("redPlay" + id).style.position = "relative !important"
        }

      }
      else {
        this.selectedVideo = ""
        div.nativeElement.pause()
        if (document.getElementById(id) && document.getElementById("whitePlay" + id) && document.getElementById("redPlay" + id)) {

          document.getElementById(id).classList.remove("hover")
          document.getElementById(id).classList.add("notHover")
          document.getElementById("whitePlay" + id).style.visibility = "visible"
          document.getElementById("whitePlay" + id).style.position = "relative !important"
          document.getElementById("redPlay" + id).style.visibility = "hidden"
          document.getElementById("redPlay" + id).style.position = "absollute !important"
        }
      }
    }
    else {
      let divImage = document.getElementById("image" + id)
      if (divImage) {
        if (play) {
          this.selectedVideo = id
          divImage.classList.add("hover")
          divImage.classList.remove("notHover")
          if (document.getElementById("whitePlay" + id) && document.getElementById("redPlay" + id)) {
            document.getElementById("whitePlay" + id).style.visibility = "hidden"
            document.getElementById("whitePlay" + id).style.position = "absolute !important"
            document.getElementById("redPlay" + id).style.visibility = "visible"
            document.getElementById("redPlay" + id).style.position = "relative !important"
          }


        }
        else {
          this.selectedVideo = ""
          divImage.classList.remove("hover")
          divImage.classList.add("notHover")
          if (document.getElementById("whitePlay" + id) && document.getElementById("redPlay" + id)) {
            document.getElementById("whitePlay" + id).style.visibility = "visible"
            document.getElementById("whitePlay" + id).style.position = "relative !important"
            document.getElementById("redPlay" + id).style.visibility = "hidden"
            document.getElementById("redPlay" + id).style.position = "absollute !important"
          }
        }
      }
    }
  }
  showSpeakerInVideoCard(speaker, showpopup, sessionId) {
    this.selectedFSpeaker = speaker
    this.selectedFSession = sessionId
    if (showpopup) {
      this.showFSpeaker = true
      this.speaker = speaker
    }
    else {
      this.showFSpeaker = false
      this.speaker = null
    }
  }
  showMore(id) {
    this.router.navigateByUrl("/more/channelevent/" + this.event._id + "/" + id).then();
  }
  showMoreSessions()
  {
    this.more_sessions_channel.page = (Number(this.more_sessions_channel.page) + 1).toString();
    this.portalservice.getMoreSesionsByChannelId(this.more_sessions_channel).then((res1: any) => {
      if (res1) {
        // array.forEach(element => {
          
        // });
        this.newLoaded_sessions = res1.sessions
        this.current_number_sessions = this.current_number_sessions + res1.sessions.length
      }
    })
  }
  reduceSessions()
  {
    this.newLoaded_sessions = []
    this.current_number_sessions = this.sessions.length
    this.more_sessions_channel.page = "1";
  }
}
