<div style="display: flex;width: 100%;">
    <div class="event-details">
        <div [style.background-image]="'url('+cover+')'" style="height: 47vh;
        width: 100%;
        background-position: center;
        background-size: cover;"></div>
        <div class="event-infos">
            <div style="display: flex; flex-direction: column; gap: 20px; padding: 15px 40px 15px 7vw;
              background: transparent linear-gradient(180deg, #171f3300 0%, #171f33 83%) 0% 0% no-repeat padding-box">
                <div *ngIf="event.title" class="one-line-limit bigTitle Quicksand-semibold" style="text-align:left">
                    {{event.title | titlecase}}
                </div>
                <div class="event-type Quicksand-bold tagsText" style="text-align: center;">Playlist</div>
            </div>
            <div *ngIf="event.description && event.description != ''"  style="padding: 0 40px 0 7vw" class="paragraphe Quicksand-regular" [innerHTML]="event.description "></div>
            <div id="event-tags-container" class="event-tags-container" *ngIf="event.tags">
                <div id="leftarrow" *ngIf="rightArrowValue >= 4 " class="arrowLeft"
                    (mouseenter)="scrollDivRooms(event.tags, 4,'tags','leftarrow')"
                    (mouseleave)="stopTimerRooms(timerRooms)"></div>
                <div class="event-tags" id="tags">
                    <div class="tag tagsText" style="color:#8f9bb3;" *ngFor="let tag of event.tags">{{tag |
                        titlecase}}
                    </div>
                </div>
                <div id="rightarrow" *ngIf="overflowActivated" class="arrowRight"
                    (mouseenter)="scrollDivRooms(event.tags, -4,'tags','rightarrow')"
                    (mouseleave)="stopTimerRooms(timerRooms)"></div>
            </div>
            <div style="display: flex;flex-direction: row;gap: 40px;padding: 0 40px 0 7vw;">
                <div class="event-organizer" *ngIf=" event.organizer">
                    <div class="subTitle white-color Quicksand-bold" style="text-align: left">
                        Organizer
                    </div>
                    <div class="content">
                        <div    class="organizer-logo"  [style.background-image]="'url('+event.organizer.logo+')'"></div>
                        <!-- <img [src]=event.organizer.logo> -->
                        <div id="organizer" class="paragraphe line-limit Quicksand-regular" style="align-self: center;">
                            {{event.organizer.name | titlecase}}
                        </div>

                    </div>
                </div>
                <div *ngIf="speakers && speakers.length>0" class="event-organizer" style="max-width: 209px;">
                    <div class="subTitle white-color Quicksand-bold" style="text-align: left"> Speakers </div>
                    <div class="content">
                        <div *ngIf="speakers" class="session-speakers" style="align-items: flex-start;"
                            [style.left.px]=" -15*(speakers.length-1) ">
                            <div *ngFor="let speaker of speakers;let i = index" style="display: inline-flex;">
                                <div *ngIf="i<=2" [style.background-image]="'url('+speaker.photo+')'" class="speaker"
                                    [style.left.px]=" 15*(speakers.length-1-i) ">
                                </div>
                                <div *ngIf="i==3" class="Quicksand-regular tagsText othersText"> + {{speakers.length-3}}
                                    others</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="width: 69px;
            height: 0px;
            background: #8F9BB3;
            border: 1px solid #8F9BB3;
            margin: 0 40px 0 7vw;"></div>
            <div class="social-media ">
                <span class="tagsText Quicksand-regular">Share this event on : </span>
                <div style="display: flex;gap:10px">
                    <img style="width: 25px !important;height: 25px !important;" src="../../../assets/twiter.png">
                    <img style="width: 25px !important;height: 25px !important;" src="../../../assets/fb.png">
                    <img style="width: 25px !important;height: 25px !important;" src="../../../assets/IN.png">
                    <img style="width: 25px !important;height: 25px !important;" src="../../../assets/url.png">
                </div>
            </div>
        </div>
    </div>
    <div class="channel-sessions">
        <div style="display: flex;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: center;">
            <div *ngFor="let session of sessions;let i=index" [id]="'videoCard'+i" class="video-wrapper"
                (click)="openVideo(i)">
                <video #video
                    *ngIf="session.cover_url && session.cover_url.split('.')[ session.cover_url.split('.').length-1]=='mp4'"
                    [id]="session._id" [muted]="'muted'">
                    <source [src]="session.cover_url" type="video/mp4">
                </video>
                <div [id]="'image'+session._id"
                    *ngIf="session.cover_url && session.cover_url.split('.')[ session.cover_url.split('.').length-1]!='mp4'"
                    [style.background-image]="'url('+session.cover_url+')'" class="session-cover">
                </div>
                <div class="content-video" (mouseenter)="playVideo(session._id ,true)"
                    (mouseleave)="playVideo(session._id ,false)">
                    <div class="header-btn">
                        <div class=" duration tagsText">
                            {{session.duration}}
                        </div>
                    </div>
                    <div style="display: flex;justify-content: center;height: 100%;">
                        <div [id]="'whitePlay'+session._id" class="play-icon">
                        </div>
                        <div [id]="'redPlay'+session._id" class="play-icon-hover">
                        </div>
                    </div>
                    <div>
                        <div class="session-video-info">
                            <div class="smallText Quicksand-bold" style="text-align: start; margin-bottom: 5px">
                                {{session.title}}
                            </div>
                            <div *ngIf="session.speakers" class="session-speakers" style="align-items: flex-start;"
                                [style.left.px]=" -15*(session.speakers.length-1) ">
                                <div *ngFor="let speaker of session.speakers;let i = index"
                                    style="display: inline-flex;">
                                    <div *ngIf="i<2" [style.background-image]="'url('+speaker.photo+')'" class="speaker"
                                        [style.left.px]=" 15*(session.speakers.length-1-i) "
                                        (mouseenter)="showSpeakerInVideoCard(speaker , true, session._id)"
                                        (mouseleave)="showSpeakerInVideoCard(speaker , false, session._id)">
                                    </div>
                                    <div *ngIf="session.speakers.length>2 && i==2"
                                        [style.background-image]="'url('+speaker.photo+')'" class="last-speaker"
                                        [style.left.px]=" 15*(session.speakers.length-1-i) "
                                        (mouseenter)="showSpeakerInVideoCard(speaker , true, session._id)"
                                        (mouseleave)="showSpeakerInVideoCard(speaker , false, session._id)">
                                    </div>
                                    <div *ngIf="session.speakers.length>2 && i==2" class="tagsText Quicksand-regular"
                                        style="
                                    align-self: flex-end;   
                                    position: relative;
                                    left: -25px;
                                    top: -6px;">
                                        +{{session.speakers.length-i}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="session-video-footer">
                            <div class="session-video-tags">
                                <div *ngFor="let tag of session.tags; let i= index">
                                    <div *ngIf="i<4" class="tag tagsText Quicksand-light"
                                        style="height: 25px;align-self: center;">
                                        <div>{{tag}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="showMore-btn" (click)="showMore(session._id)">
                                <div class="tagsText showMore-text">Show More
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="newLoaded_sessions && newLoaded_sessions.length>0 " style="display: flex;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: center;">
            <div *ngFor="let session of newLoaded_sessions;let i=index" [id]="'videoCard'+i" class="video-wrapper"
                (click)="openVideo(i)">
                <video #video
                    *ngIf="session.cover_url && session.cover_url.split('.')[ session.cover_url.split('.').length-1]=='mp4'"
                    [id]="session._id" [muted]="'muted'">
                    <source [src]="session.cover_url" type="video/mp4">
                </video>
                <div [id]="'image'+session._id"
                    *ngIf="session.cover_url && session.cover_url.split('.')[ session.cover_url.split('.').length-1]!='mp4'"
                    [style.background-image]="'url('+session.cover_url+')'" class="session-cover">
                </div>
                <div class="content-video" (mouseenter)="playVideo(session._id ,true)"
                    (mouseleave)="playVideo(session._id ,false)">
                    <div class="header-btn">
                        <div class=" duration tagsText">
                            {{session.duration}}
                        </div>
                    </div>
                    <div style="display: flex;justify-content: center;height: 100%;">
                        <div [id]="'whitePlay'+session._id" class="play-icon">
                        </div>
                        <div [id]="'redPlay'+session._id" class="play-icon-hover">
                        </div>
                    </div>
                    <div>
                        <div class="session-video-info">
                            <div class="smallText Quicksand-bold" style="text-align: start; margin-bottom: 5px">
                                {{session.title}}
                            </div>
                            <div *ngIf="session.speakers" class="session-speakers" style="align-items: flex-start;"
                                [style.left.px]=" -15*(session.speakers.length-1) ">
                                <div *ngFor="let speaker of session.speakers;let i = index"
                                    style="display: inline-flex;">
                                    <div *ngIf="i<2" [style.background-image]="'url('+speaker.photo+')'" class="speaker"
                                        [style.left.px]=" 15*(session.speakers.length-1-i) "
                                        (mouseenter)="showSpeakerInVideoCard(speaker , true, session._id)"
                                        (mouseleave)="showSpeakerInVideoCard(speaker , false, session._id)">
                                    </div>
                                    <div *ngIf="session.speakers.length>2 && i==2"
                                        [style.background-image]="'url('+speaker.photo+')'" class="last-speaker"
                                        [style.left.px]=" 15*(session.speakers.length-1-i) "
                                        (mouseenter)="showSpeakerInVideoCard(speaker , true, session._id)"
                                        (mouseleave)="showSpeakerInVideoCard(speaker , false, session._id)">
                                    </div>
                                    <div *ngIf="session.speakers.length>2 && i==2" class="tagsText Quicksand-regular"
                                        style="
                                    align-self: flex-end;   
                                    position: relative;
                                    left: -25px;
                                    top: -6px;">
                                        +{{session.speakers.length-i}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="session-video-footer">
                            <div class="session-video-tags">
                                <div *ngFor="let tag of session.tags; let i= index">
                                    <div *ngIf="i<4" class="tag tagsText Quicksand-light"
                                        style="height: 25px;align-self: center;">
                                        <div>{{tag}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="showMore-btn" (click)="showMore(session._id)">
                                <div class="tagsText showMore-text">Show More
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="current_number_sessions < total_sessions && total_sessions > 6" class="showMore-btn-channel">
            <div class="smallText Quicksand-semibold" style="text-align: center;" (click)="showMoreSessions()">SHOW MORE
            </div>
        </div>
        <div *ngIf="current_number_sessions >= total_sessions && total_sessions > 6" class="showMore-btn-channel">
            <div class="smallText Quicksand-semibold" style="text-align: center;" (click)="reduceSessions()">REDUCE
            </div>
        </div>
    </div>
</div>