import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { StateChange } from 'ng-lazyload-image';
import { NgxSpinnerService } from 'ngx-spinner';
import { EditExplorePopupComponent } from 'src/app/dashboard-test/edit-explore-popup/edit-explore-popup.component';
import { ExploreService } from 'src/app/dashboard-test/explore.service';
import { config } from 'src/config/config';
import { ExploreEventService } from '../explore-event.service';

@Component({
  selector: 'app-speakers',
  templateUrl: './speakers.component.html',
  styleUrls: ['./speakers.component.css']
})
export class SpeakersComponent implements OnInit {
  @Output() activeBloc = new EventEmitter();
  @Input() is_admin

  selectedSpeakers: any = {}
  loading: boolean = false
  imageStates: string[] = [];
  showParagraphe: boolean;
  ScrolledIntoView: any;
  defaultImage = "../../../assets/placeholder.png"
  sections_title_icon = config.sections_title_icon
  color_config = config.primary_color
  speakers_message = config.speakers
  constructor(public exploreService: ExploreEventService, public router: Router, private spinner: NgxSpinnerService,
    public exploredashboardservice: ExploreService, public dialog: NbDialogService, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.exploreService.onChangedSpeaker.subscribe((res) => {
      this.selectedSpeakers = res
      // setTimeout(() => {
      this.loading = false
      this.spinner.show("speakerSpinner0").then((res) => {
      });
      this.spinner.show("speakerSpinner1").then((res) => {
      });
      this.spinner.show("speakerSpinner2").then((res) => {
      });
      // }, 2000);
    })
    this.activeBloc.emit("testimonials")

  }
  ngAfterViewInit() {
    // this.spinner.show()
    this.spinner.show("speakerSpinner0").then((res) => {

    });
    this.spinner.show("speakerSpinner1").then((res) => {
    });
    this.spinner.show("speakerSpinner2").then((res) => {
    });
  }
  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView() {
    if (document.getElementById("paragraphe")) {
      const rect = document.getElementById("paragraphe").getBoundingClientRect();
      const topShown = rect.top >= 0;
      const bottomShown = rect.bottom <= window.innerHeight;
      this.ScrolledIntoView = topShown && bottomShown;
      if (this.ScrolledIntoView) {
        document.getElementById("paragraphe").classList.add('animate__animated', 'animate__fadeInRight');
        document.getElementById("paragraphe").style.setProperty('--animate-duration', '.5s');
      }
      else {
        document.getElementById("paragraphe").classList.remove('animate__animated', 'animate__fadeInRight');
      }
    }
  }
  onStateChange(state: StateChange, i) {
    this.imageStates.push(state.reason);
    if (state.reason == "finally") {
      this.spinner.hide("speakerSpinner" + i).then((res) => {
      });
      if (i == this.selectedSpeakers.length - 1) {
        this.showParagraphe = true
      }
    }

    this.cd.detectChanges();
  }
  showLinkedIn(show, i) {
    if (document.getElementById(i)) {
      if (show) {

        document.getElementById(i).style.visibility = "visible"
      }
      else {
        document.getElementById(i).style.visibility = "hidden"

      }
    }
  }
  gotoProfile(speaker_id) {
    this.router.navigateByUrl("/profile/speaker/" + speaker_id).then((res) => { });
  }


  /******************Manage Explore ******************/

  updateSpeakers() {
    this.exploredashboardservice.getSpeakers().then((res: any) => {
      if (res && res.status == "success") {
        // this.events = res.channels
        const dialogRef = this.dialog.open(EditExplorePopupComponent, {
          context:
          {
            data: {
              name: "updateSpeakers",
              explore_id: this.exploreService.explore_id,
              speakers: res.speakers,
              selectedspeakers: this.selectedSpeakers
            },
          }, hasBackdrop: false
        });
        dialogRef.onClose.subscribe((result) => {
        });
      }
    })
  }
}
