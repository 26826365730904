import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthentificationSystemService } from '../authentification-system/authentification-system.service';
import * as CryptoJS from 'crypto-js';
import { ExploreEventService } from '../explore-event/explore-event.service';
import * as moment from 'moment';
import { ToastrService } from '../services/toastr.service';
import { DataService } from '../services/data.service';
@Component({
  selector: 'app-confirm-invitation',
  templateUrl: './confirm-invitation.component.html',
  styleUrls: ['./confirm-invitation.component.css']
})
export class ConfirmInvitationComponent implements OnInit {
  message
  only_message_button = false
  invitation_code
  constructor(private route: ActivatedRoute, public router: Router, public toastrService:ToastrService,private shareddata:DataService,
    private formBuilder: FormBuilder, public exploreservice: ExploreEventService, public authservice: AuthentificationSystemService) { }
  event
  start_date
  end_date
  start_time
  code = ""
  email
  eventid
  firststep = false;
  secondstep = false
   ngOnInit(): void {
    this.shareddata.onChangePath(false)
    this.toastrService.changeData({display:"hidden"})

    this.route.params.subscribe((params) => {
      this.email = params["email"];
      this.eventid = params["eventid"];
      this.invitation_code = params["invitationcode"];
    });

    this.exploreservice.checkinvitation(this.eventid, this.email, this.invitation_code).then((res: any) => {
      if (res && res.status == "success") {
        this.code=res.message
        if (this.code == 'INC') {
          this.exploreservice.getEventDetailsFromInvitation()
          .then((res: any) => {
            this.event = res
            this.start_date = this.convertDate(res.start_date)
            this.end_date = this.convertDate(res.end_date)
            this.start_time = this.getStartTime(res.start_date, res.utcoffset);
          })
          this.message = "Invitation Not Confirmed";
          this.firststep = true;
          this.secondstep = false;
        }
        else if (this.code == 'IAC') {
          this.message = "Invitation Already Confirmed";
          this.firststep = false;
          this.secondstep = true;
        }
        else if (this.code == 'IC') {
          this.message = "Invitation  Confirmed";
          this.firststep = false;
          this.secondstep = true;
        }
        else if (this.code == 'INF') {
          this.message = "Invitation Not Found"
          this.firststep = false;
          this.secondstep = true;
        }
        else if (this.code == 'IE') {
          this.message = "Invitation Expired"
          this.firststep = false;
          this.secondstep = true;
        }
        else if (this.code == 'ID') {
          this.message = "Invitation Declined"
          this.firststep = false;
          this.secondstep = true;
        }
      }
    })






   
  //   if (this.router.url) {
  //     let res= this.router.url.split('=')[1]
  //  this.invitation_code = res.split('_')[0]
  //  this.code = res.split('_')[1]


  //   }
    /******************************** */
   
  
  }
   
  declined = false
  acceptInvitation() {
    this.exploreservice.acceptInvitation(this.eventid, this.email, this.invitation_code).then((res: any) => {
      if (res && res.status == "success") {
        this.firststep = false;
        this.secondstep = true
        this.code = res.message
        //  else if (res.code == 'IC') { this.message = "Invitation Confirmed" }
        if (res.code == 'IAC') { this.message = "Invitation Already Confirmed" }
        else if (this.code == 'IC') { this.message = "Invitation Confirmed" }
        // else if (res.code == 'UAIC') { this.message = "User Added. Invitation Confirmed" }
        // else if (res.code == 'NTL') { this.message = "No Passes Left" }
        else if (this.code == 'IE') { this.message = "Invitation Expired"  }
        else if (res.code == 'INF') { this.message = "Invitation Not Found" }
        else if (this.code == 'ID') {
          this.message = "Invitation Declined"
         
        }
      }
    })
  }
  declineInvitation() {
    this.exploreservice.declineInvitation(this.eventid, this.email, this.invitation_code).then((res: any) => {
      if (res && res.status == "success") {
        this.firststep = false;
        this.secondstep = true
        this.code = res.message
        //  else if (res.code == 'IC') { this.message = "Invitation Confirmed" }
        if ( this.code == 'IAC') { this.message = "Invitation Already Confirmed" }
        else if ( this.code == 'IC') { this.message = "Invitation Confirmed" }
        // else if ( this.code == 'UAIC') { this.message = "User Added. Invitation Confirmed" }
        // else if ( this.code == 'NTL') { this.message = "No Passes Left" }
        else if ( this.code == 'IE') { this.message = "Invitation Expired"  }
        else if (this.code == 'ID') {
          this.message = "Invitation Declined" 
        }

        else if ( this.code == 'INF') { this.message = "Invitation Not Found" }
      }
    })
    
  }
  convertDate(date) {
    var new_date = moment(date).format('MMMM Do YYYY');
    return new_date.split('th')[0] + '<sup>th</sup>,' + new_date.split('th')[1]
    // return new_date.split('th')[0] + ',' + new_date.split('th')[1]
  }
  getStartTime(date, utcoffset) {
    var start_time = moment(date).format('LT');
    var utc
    if (utcoffset && utcoffset.includes('-')) {
      utc = utcoffset
    }
    else {
      utc = '+' + utcoffset
    }
    return '<sup></sup>' + start_time.replace(/\s/g, "") + ' (UTC' + utc + ')';
  }
  goToExplore(){
    this.shareddata.onChangePath(true)

    this.router.navigateByUrl('/')
  }
  goToEvent(){
    this.exploreservice.CheckCurrentUSer(this.email ).then((res: any) => {
      if (res  ) {
        this.shareddata.onChangePath(true)

         if(res && res.status=="success" && res.message=="not the same user email") 
      {  let data={
          type:"info",
          title:"",
          message:"<div>You accept the invitation with  <span class='EmailColor'>"+this.email+"</span>. Logout and switch the account</div>",
          display:"visible",
          autoclose:false
        }
        this.toastrService.changeData(data)}

        this.router.navigateByUrl('/portal/hybridevent/'+this.eventid)
      }
    })
   }
}
