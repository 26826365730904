<!-- <div style="color:white ;display: inline-flex; width: 100%;justify-content: space-between;font-family: Quicksand !important;"> -->
<div style="color:white ;font-family: Quicksand !important;padding: 20px;" [ngStyle]="{'padding':mode=='verticatal' ? '0':'20px'} ">
    <div *ngIf="mode=='horizontal'" class="Back" style="position: absolute; top: 70px; left: -10px;">
        <a href="/dashboard"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back to dashboard</a>
    </div>
    <div *ngIf="mode=='horizontal'" style="font-size: 22px;margin: 20px 0;">Organisation settings</div>
    <!--------Change mode------------>
    <div style="font-size: 12px;border: 1px solid;border-radius: 10px;padding: 2px 5px;position: absolute; z-index: 2;right: 10px; top: 85px;">
        <span (click)="mode='horizontal'" style="border-right: 0.5px solid white;padding: 0 5px;cursor: pointer;" [ngStyle]="{'color':mode=='horizontal' ? '#007bff':'white'}">Horizontal</span>
        <span (click)="mode='verticatal'" style="border-left: 0.5px solid white;padding: 0 5px;cursor: pointer;" [ngStyle]="{'color':mode=='verticatal' ? '#007bff':'white'} ">Verticatal</span>
    </div>
    <div [ngStyle]="mode=='verticatal' ? {display: 'inline-flex' } : {}">
        <!----------Horizontal---------->
        <div *ngIf="mode=='horizontal'">
            <div style="display: inline-flex;width: 100%;border-bottom: 1px solid #282a33;">
                <div (click)="tab='details'" style="cursor: pointer;padding: 10px 0;margin-right: 10px;" [ngClass]="tab=='details'? 'ActiveTab' : ''">Details</div>
                <div (click)="tab='team'" style="cursor: pointer;padding: 10px 0;margin-right: 10px;" [ngClass]="tab=='team'? 'ActiveTab' : ''">Team</div>
                <div (click)="tab='events'" style="cursor: pointer;padding: 10px 0;margin-right: 10px;" [ngClass]="tab=='events'? 'ActiveTab' : ''">Events</div>
            </div>
        </div>
        <!----------Verticatal---------->
        <div *ngIf="mode=='verticatal'" style="display: inline-flex;border-bottom: 1px solid #282a33;">
            <div class="sidebar">
                <div class="Back">
                    <a href="/dashboard"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back to dashboard</a>
                </div>
                <div style="font-size:12px; margin: 15px 5px; ">Organisation settings</div>
                <div (click)="tab='details'" class="Item" [ngClass]="tab=='details' ? 'activatedItem' : ''">Details
                </div>
                <div (click)="tab='team'" class="Item" [ngClass]="tab=='team' ? 'activatedItem' : ''">Team</div>
                <div (click)="tab='events'" class="Item" [ngClass]="tab=='events' ? 'activatedItem' : ''">Events</div>
            </div>
        </div>
        <!----------tabs---------->
        <div>
            <div *ngIf="tab=='details'">
                <form [formGroup]="organisationform">
                    <div style="margin: 30px 0;">
                        <div style="display: inline-flex; align-items: flex-start;width: 100%;">
                            <div style="margin-right: 15px;">
                                <input (change)="uploadLogo($event)" (click)="clickInput($event)" type="file" id="file" style="display: none;" />
                                <div>
                                    <div *ngIf="(!organisation.logo && (!imageSrc || imageSrc  =='')) " style="color: white; width: 95px;text-align: center; height: 24px;   z-index: 1; text-align: right;">
                                    </div>
                                    <label *ngIf="(!organisation.logo && (!imageSrc || imageSrc  =='')) " for="file" style="                                    
                                    display: flex;flex-direction: column; justify-content: center;align-items: center;overflow: hidden; width: 100px; height: 100px;position: relative;
                                    top: -20px; border: 1px dashed;border-radius: 50%; cursor: pointer; ">
                                        <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                                        Upload logo
                                    </label>
                                </div>
                                <div>
                                    <div *ngIf="imageSrc && imageSrc !=''" style="color: white; width: 95px;text-align: center;    position: relative; top: 10px; z-index: 1; text-align: right;">
                                        <i class="fa fa-times-circle-o" style="color: #0e1120; background-color: white; cursor: pointer; border-radius: 50%;" (click)="removelogo()" aria-hidden="true"></i>
                                    </div>
                                    <label *ngIf="imageSrc && imageSrc !=''" style=" display: flex; flex-direction: column; justify-content: flex-end;align-items: center;overflow: hidden; width: 100px; height: 100px;position: relative;
                                    top: -20px; border: 1px dashed; border-radius: 50%; background-position: center;background-size: cover; " [style.background-image]="'url('+imageSrc+')'">
                                        <label for="file"
                                            style="cursor: pointer;margin-bottom: unset !important;color: white; background: #0e1120;
                                        width: 110px; text-align: center; height: 20px;border-radius: 50%; display: flex;align-items: center; justify-content: center;">
                                            <i class="fa fa-camera" style="font-size: 12px;" aria-hidden="true"></i>
                                        </label>
                                    </label>
                                </div>
                                <div>
                                    <div *ngIf="organisation.logo && organisation.logo!='' && (!imageSrc || imageSrc =='')" style="color: white; width: 95px;text-align: center; height: 24px;   z-index: 1; text-align: right;">
                                    </div>
                                    <label *ngIf="organisation.logo && organisation.logo!='' && (!imageSrc || imageSrc =='')" style=" display: flex; flex-direction: column; justify-content: flex-end;align-items: center;overflow: hidden; width: 100px; height: 100px;position: relative;
                                    top: -20px; border: 1px dashed; border-radius: 50%; background-position: center;background-size: cover; " [style.background-image]="'url('+organisation.logo+')'">
                                        <label for="file"
                                            style="cursor: pointer;margin-bottom: unset !important;color: white; background: #0e1120;
                                        width: 110px; text-align: center; height: 20px;border-radius: 50%; display: flex;align-items: center; justify-content: center;">
                                            <i class="fa fa-camera" style="font-size: 12px;" aria-hidden="true"></i>
                                        </label>
                                    </label>
                                </div>
                            </div>
                            <div style="width: 80%;">
                                <div>
                                    <div>
                                        <div> Organisation name</div>
                                        <input type="text" id="name" class="form-control" formControlName="name" style="border-color: #ccc;" placeholder="Add a name to your organisation" [ngClass]="{'is-invalid': submitted && formControls.name.errors}" />
                                    </div>
                                    <div>
                                        <div>
                                            Organisation description
                                        </div>
                                        <textarea type="text" id="description" class="form-control" formControlName="description" style="border-color: #ccc;" placeholder="Add a description to your organisation" [ngClass]="{'is-invalid': submitted && formControls.description.errors}"></textarea>
                                    </div>
                                    <div>
                                        <div style="display: inline-flex;width: 100%;justify-content: space-between;align-items: center;">
                                            <div style="width: 45%;">
                                                <div> Organisation email</div>
                                                <input type="text" id="email" class="form-control" formControlName="email" style="border-color: #ccc;" placeholder="Add a email to your organisation" [ngClass]="{'is-invalid': submitted && formControls.email.errors}" />
                                            </div>
                                            <div style="width: 45%;">
                                                <div> Organisation website</div>
                                                <input type="text" id="website" class="form-control" formControlName="website" style="border-color: #ccc;" placeholder="Add a website to your organisation" [ngClass]="{'is-invalid': submitted && formControls.website.errors}" />
                                            </div>
                                        </div>
                                        <div style="display: inline-flex;width: 100%;justify-content: space-between;align-items: center;">
                                            <div style="width: 45%;">
                                                <div> Organisation linkedin</div>
                                                <input type="text" id="linkedin" class="form-control" formControlName="linkedin" style="border-color: #ccc;" placeholder="Add a linkedin to your organisation" [ngClass]="{'is-invalid': submitted && formControls.linkedin.errors}" />
                                            </div>
                                            <div style="width: 45%;">
                                                <div> Organisation twitter</div>
                                                <input type="text" id="twitter" class="form-control" formControlName="twitter" style="border-color: #ccc;" placeholder="Add a twitter to your organisation" [ngClass]="{'is-invalid': submitted && formControls.twitter.errors}" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div style="margin-left: 100px;">
                    <div style="cursor: pointer;width: 150px; border: 1px solid; text-align: center;border-radius: 5px; height: 35px; margin: auto; display: flex;align-items: center; justify-content: center;" (click)=" editOrganisation()">Save</div>
                </div>
            </div>
            <div *ngIf="tab=='team'">
                <div style="width: 100%; display: flex;" [ngStyle]="{'justify-content':mode=='horizontal' ? 'flex-end':''} ">

                    <div class="Invite" (click)="inviteMember()">+ Invite a new team member</div>
                </div>
                <div style="display: inline-flex;">
                    <div class="Invite" (click)="display='Members'" style="width: 100px; " [ngStyle]="{'color':display=='Members' ? '#007bff':'white'} ">Members</div>
                    <div class="Invite" (click)="display='invitations'" style="width: 100px; " [ngStyle]="{'color':display=='invitations'  ? '#007bff':'white'} ">Invitations</div>
                </div>
                <div *ngIf="display=='Members'">
                    <div style="display: inline-flex;">
                        <div style="display: flex; flex-direction: column;">
                            <div *ngIf="organisation.team.owner" style="display: inline-block;border: 1px solid; margin: 10px; padding: 10px; border-radius: 5px;">
                                <div>
                                    Owner
                                </div>
                                <div style=" display: inline-flex;align-items: center; ">
                                    <div *ngIf="organisation.team.owner.additional_data && organisation.team.owner.additional_data.photo" style="border-radius: 50%; height: 30px;width: 30px;background-position: center;background-size: cover;" [style.background-image]="'url('+organisation.team.owner.additional_data.photo+')'">
                                    </div>
                                    <div *ngIf="!organisation.team.owner.additional_data || organisation.team.owner.additional_data && !organisation.team.owner.additional_data.photo" style="border-radius: 50%; filter: invert(1);height: 30px;width: 30px;background-position: center;background-size: cover;background-image: url(../../../assets/default-user.png)">
                                    </div>
                                    <div style="margin-left: 10px;">
                                        <div>
                                            {{organisation.team.owner.firstname}} {{organisation.team.owner.lastname}}
                                        </div>
                                        <div>
                                            {{organisation.team.owner.email}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="organisation.team.moderators">
                                <div style="display: inline-flex;flex-wrap: wrap;">
                                    <div *ngFor="let organizer of organisation.team.moderators" style="display: inline-block;border: 1px solid; margin: 10px; padding: 10px; border-radius: 5px;">
                                        <div>
                                            <div>
                                                Moderators
                                            </div>
                                            <div style=" display: inline-flex;align-items: center; ">
                                                <div *ngIf="organizer.moderator.additional_data && organizer.moderator.additional_data.photo" style="border-radius: 50%; height: 30px;width: 30px;background-position: center;background-size: cover;" [style.background-image]="'url('+organizer.moderator.additional_data.photo+')'">
                                                </div>
                                                <div *ngIf="!organizer.moderator.additional_data || organizer.moderator.additional_data && !organizer.moderator.additional_data.photo" style="border-radius: 50%; filter: invert(1);height: 30px;width: 30px;background-position: center;background-size: cover;background-image: url(../../../assets/default-user.png)">
                                                </div>
                                                <div style="margin-left: 10px;">
                                                    <div>
                                                        {{organizer.moderator.firstname}} {{organizer.moderator.lastname}}
                                                    </div>
                                                    <div>
                                                        {{organizer.moderator.email}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                Events: <span *ngIf="organizer.events">{{organizer.events.length}}</span>
                                                <span *ngIf="!organizer.events">0</span>
                                                <div *ngIf="organizer.events">
                                                    <div *ngFor="let event of organizer.events">
                                                        <div class="EventLink" style="cursor: pointer;">
                                                            -<span [routerLink]="['/dashboard/', event._id, 'event']" style="text-decoration-line: underline; text-decoration-thickness: 1px;">
                                                                {{event.title}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="display=='invitations'">
                    <table class="table dark-table" style="color: white;">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Email</th>
                                <th scope="col">Status</th>
                                <th scope="col">Created at</th>
                                <th scope="col">Confirmed at</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let invitation of invitations; let i=index">
                                <th scope="row">{{i+1}}</th>
                                <td>{{invitation.email}}</td>
                                <td>{{invitation.status}}</td>

                                <td *ngIf="invitation.created_at">{{invitation.created_at}}</td>
                                <td *ngIf="!invitation.created_at">-</td>

                                <td *ngIf="invitation.confirmed_at">{{invitation.confirmed_at}}</td>
                                <td *ngIf="!invitation.confirmed_at">-</td>


                                <td style="font-size: 12px;">
                                    <i class="fa fa-repeat" aria-hidden="true" style="cursor: pointer;" (click)="resendInvitation(invitation._id)"></i>
                                    <span style="margin-left: 10px;"><i class="fa fa-trash-o" style="cursor: pointer;"  (click)="removeInvitation(invitation._id)" aria-hidden="true"></i>
</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            <div *ngIf="tab=='events' ">
            </div>
        </div>
    </div>
</div>