<!-- <div style="height: 100%;"> -->
   
    <!-- <div class="popup-container"> -->
        <div *ngIf="loginPopup" style="align-self: center; width: 25vw; min-width: 400px;">
            <app-login-popup (OutPutData)="changePopup($event)" [data]="dataLogin"></app-login-popup>
        </div>
        <div *ngIf="signupPopup" style="width: 25vw;min-width: 400px;align-self: center;">
            <app-signup-popup (OutPutData)="changePopup($event)" [data]="dataSignup"></app-signup-popup>
        </div>
    <!-- </div> -->
<!-- </div> -->

