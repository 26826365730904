<div *ngIf="signuppage" class="Signup" style="width :100% ">
    <div *ngIf="!hide" class="header">
        <div class="title Quicksand-bold PopupTitle" style="text-align:center;"> SIGN UP</div>
        <div class="paragraphe Quicksand-regular" style="text-align: center">Already have an account?&nbsp;<span (click)="Login()" style="text-decoration: underline;">Login</span>
        </div>
        <div class="event-info">
            <div style="width: 35%;min-width: 175px;">
                <div *ngIf="event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]!='mp4'"
                    class="ar-16-9 " [style.background-image]="'url('+event.cover_url+')'"></div>
                <div *ngIf="event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]=='mp4'">
                    <video autoplay="autoplay" loop muted style="width: 100%;">
                        <source [src]="event.cover_url" type="video/mp4;">
                    </video>
                </div>
            </div>
            
            <div style="align-self: center;">
                <div class="subTitle Quicksand-semibold" style="text-align: left;margin-bottom: 10px;line-height: 25px;">{{event.title}}
                </div>
                <div class="paragraphe Quicksand-regular" style="text-align: left;color: #FFFFFF">{{event.start_date}}</div>
            </div>
        </div>
    </div>
    <div *ngIf="hide" class="header2">
        <div class="Quicksand-bold PopupTitle"> SIGN UP</div>
        <div class="paragraphe Quicksand-regular" style="text-align: center">Already have an account?&nbsp;<span (click)="Login()" style="text-decoration: underline;">Login</span>
        </div>
    </div>
    <div class="formulaire">
        <form class="form flex-column" [formGroup]="signupForm" (keydown.enter)="keyDownFunctionSignup($event)">
            <div class="input-group">
                <label class="Quicksand-regular paragraphe" style="color: #8F9BB3;" for="email">Email</label>
                <input type="text" class="input-form"
                    [ngClass]="{Error : submittedSignup && signupformControls.email.errors }" name="email" id="email"
                    placeholder="email" formControlName="email" style="background-color: #121829; border: 1px solid #0E1120;">
            </div>
            <div class="Name" style="margin-bottom: 0 !important;  width: 100%;">
                <div class="input-group" style="margin-right: 30px;margin-bottom: 20px;">
                    <label for="firstname" class="Quicksand-regular paragraphe" style="color: #8F9BB3;">First name</label>
                    <input class="input-form"
                        [ngClass]="{Error : submittedSignup && signupformControls.firstname.errors }" type="text"
                        id="firstname" placeholder="First name" name="firstname" formControlName="firstname" >
                </div>
                <div class="input-group">
                    <label for="lastname" class="Quicksand-regular paragraphe" style="color: #8F9BB3;">Last name</label>
                    <input class="input-form"
                        [ngClass]="{Error : submittedSignup && signupformControls.lastname.errors}" type="text"
                        id="lastname" placeholder="Last name" name="lastname" formControlName="lastname" >
                </div>
            </div>
            <div class="Name" style="margin-bottom: 0 !important;  width: 100%;">
                <div class="input-group" style="margin-right: 30px;margin-bottom: 40px;">
                    <label for="password" class="Quicksand-regular paragraphe" style="color: #8F9BB3;">Password</label>
                    <input class="input-form" [ngClass]="{Error : submittedSignup && signupformControls.pwd.errors}"
                        type="password" name="pwd" type="password" formControlName="pwd" id="password"
                        placeholder="password" >
                </div>
                <div class="input-group" style="margin-bottom: 40px;">
                    <label for="confirmpassword" class="Quicksand-regular paragraphe" style="color: #8F9BB3;">Confirm password</label>
                    <input class="input-form"
                        [ngClass]="{Error : submittedSignup && signupformControls.confirmpassword.errors}"
                        type="password" name="confirmpassword" type="password" formControlName="confirmpassword"
                        id="confirmpassword" placeholder="Confirm password" >

                </div>
            </div>

           
        </form>
        <div style=" width: 100%;"
            [ngClass]="Signupmsg[0]=='error' ? 'ErrorMessage' : Signupmsg[0]=='success'? 'SuccessMessage' : Signupmsg[0]=='warn'? 'WarnMessage'  :''">
            {{Signupmsg[1]}}
        </div>

    </div>
    <div style="display: inline-flex;justify-content: space-between; width: 100%; ">
        <div class="options">
            <nb-checkbox (checkedChange)="toggle($event)" class="Quicksand-regular paragraphe" style="color: #8F9BB3;">I agree with terms and conditions</nb-checkbox>
        </div>
    </div>
    <div class="next-btn-container">
        <button type="submit"  class="action-btn subTitle Quicksand-bold" style="color :#141A2A;text-align:center" (click)="signUp()">SIGN UP</button>
    </div>
    <div style="width: 100%; display: inline-flex;align-items: center;margin: 30px 0;">
        <div style="height: 1px;background-color:#8F9BB3 ;width: 50%;"></div>
        <div class="Quicksand-regular paragraphe" style="color: #8F9BB3;margin:10px;" >or</div>
        <div style="height: 1px;background-color:#8F9BB3;width: 50%; "></div>
    </div>
    <div style="width: 100%;display: flex;justify-content: center; " class="googlelinkedinButton">
        <a class="Google_Linkedin" (click)="connectWithSocialmedia('google')">
            <i class="fa fa-google" style="font-size: 19px;"></i>
        </a>
        <a class="Google_Linkedin" (click)="connectWithSocialmedia('linkedin')">
            <i class="fa fa-linkedin" style="font-size: 19px;"></i>
        </a>
    </div>
</div>
<!-- <app-verification-popup *ngIf="verification" [data]="dataVerif"></app-verification-popup> -->