<div *ngIf="event">
    <div>
        <div style="background: #171F33;display: flex;flex-direction: row; flex-wrap: nowrap;">
            <div class="padding-div"></div>
            <div class="event-card" id="event-card">
                <!-- <div *ngIf="cover && cover.split('.')[ cover.split('.').length-1]!='mp4'" class="event-cover ">
                <div style="    background-size: cover;
                height: 35vh;
                width: 30vw;
                min-height: 250px;
                min-width: 470px;
            " [style.background-image]="'url('+cover+')'" ></div> -->

                <div class="event-details ">
                    <div>
                        <!-- <div *ngIf="cover && cover.split('.')[ cover.split('.').length-1]!='mp4'" class="event-cover ">
                            <div  style="background-size: cover;
                        height: 15vw;
                        width: 30vw;
                        min-height: 210px;
                        min-width: 350px;" [style.background-image]="'url('+cover+')'"></div>
                        </div> -->
                        <div *ngIf="cover && cover.split('.')[ cover.split('.').length-1]!='mp4'" class="event-cover ">
                            <div class="ar-16-9 " style="border-radius: 15px"
                                [style.background-image]="'url('+cover+')'"></div>
                        </div>
                        <div *ngIf="cover && cover.split('.')[ cover.split('.').length-1]=='mp4'" class="event-cover">
                            <video autoplay="autoplay" loop muted style="width: 100%;
                        height: 15vw;
                        width: 30vw;
                        min-height: 210px;
                        min-width: 350px;
                        border-radius: 15px">
                                <source [src]="cover" type="video/mp4;">
                            </video>
                        </div>
                    </div>
                    <div class="event-infos" id="event-infos">
                        <div style="display: inline-flex; gap:20px">
                            <div *ngIf="event.type" class="event-type paragraphe Quicksand-semibold">{{event.type |
                                titlecase}}</div>
                            <div *ngIf="event.status == 'Live' || event.status == 'live'"
                                class="session-status paragraphe Quicksand-semibold">{{event.status | titlecase}}</div>
                        </div>
                        <div id="event-title" *ngIf="event.title"
                            class=" line-limit event-title bigTitle Quicksand-semibold"
                            (mouseover)="hoverdiv('$event','divtoshow',true)"
                            (mouseout)="hoverdiv($event,'divtoshow',false)">
                            {{event.title | titlecase}}
                            <div id="divtoshow" style="background: rgb(56 59 68 / 85%);
                            text-align: left;
                            padding: 0px 10px;
                            border-radius: 10px;
                            color: rgb(120, 129, 152);
                            position: absolute;
                            visibility: hidden;
                            top: 20%;
                            left: 38%;" class="smallText">{{event.title | titlecase}}</div>
                        </div>
                        <div id="event-short-desc2" style="display:inline-flex; margin-bottom: 10px;"
                            *ngIf="event.short_summary">
                            <div class="event-short-desc Quicksand-regular paragraphe line-limit"> {{event.short_summary
                                | titlecase}}
                            </div>
                            <div *ngIf="countLines('desc1')>2 && readmore" class="tagsText" style="text-align: right;
                                cursor: pointer;
                                color: #7ea5ff;
                                align-self: flex-end;" (click)="readMore()">more</div>
                        </div>
                        <div id="event-short-desc1"
                            style="display:inline-flex; margin-bottom: 10px;position: absolute; z-index: -10; visibility: hidden;"
                            *ngIf="event.short_summary">
                            <div id="desc1" class="event-short-desc Quicksand-regular paragraphe">
                                {{event.short_summary | titlecase}}
                            </div>
                            <div *ngIf="countLines('desc1')>2 && !readmore" class="tagsText" style="text-align: right;
                            cursor: pointer;
                           align-self: flex-end;
                            color: #7ea5ff;" (click)="readMore()"> less </div>
                        </div>
                        <div id="event-tags-container" class="event-tags-container" *ngIf="event.tags">
                            <div id="leftarrow" *ngIf="rightArrowValue >= 4 " class="arrowLeft"
                                (mouseenter)="scrollDivRooms(event.tags, 4,'tags','leftarrow')"
                                (mouseleave)="stopTimerRooms(timerRooms)"></div>
                            <div class="event-tags" id="tags">
                                <div class="tag tagsText" style="color:#8f9bb3;" *ngFor="let tag of event.tags">{{tag |
                                    titlecase}}
                                </div>
                            </div>
                            <div id="rightarrow" *ngIf="overflowActivated" class="arrowRight"
                                (mouseenter)="scrollDivRooms(event.tags, -4,'tags','rightarrow')"
                                (mouseleave)="stopTimerRooms(timerRooms)"></div>
                        </div>
                        <div *ngIf="!event.tags" style="height: 40px;"></div>
                        <div>
                            <div class="event-organizer" *ngIf=" event.organizer">
                                <div class="subTitle white-color Quicksand-bold" style="text-align: left">
                                    Organizer
                                </div>
                                <div class="content">
                                    <div [style.background-image]="'url('+event.organizer.logo+')'"
                                        class="organizer-logo"></div>
                                    <!-- <img [src]=event.organizer.logo> -->
                                    <div id="organizer" class="paragraphe line-limit Quicksand-regular"
                                        style="align-self: center;"
                                        (mouseover)="hoverdiv('$event','organizertoshow',true)"
                                        (mouseout)="hoverdiv($event,'organizertoshow',false)">{{event.organizer.name |
                                        titlecase}}
                                    </div>

                                </div>
                            </div>
                            <div id="organizertoshow" class="smallText" style="background: rgb(56 59 68 / 85%);
                    text-align: left;
                    padding: 10px;
                    border-radius: 10px;
                    color: rgb(120, 129, 152);
                    position: absolute;
                    top: 87%;
                    width: fit-content;
                    left: 45%;
                    visibility: hidden;
                            ">{{event.organizer.name | titlecase}}</div>
                        </div>

                    </div>
                </div>
                <div class="date-card">
                    <div class="days-datails" *ngIf="event.days && event.days.length == 1">
                        <div>
                            <div class="day-card">
                                <span id="month" class="smallText white-color Quicksand-regular"
                                    style="text-align: center"> {{
                                    convertDate(event.start_date, event.end_date,event.utcoffset)[0].split(' ')[0]
                                    }}</span>
                                <span id="day" class="subTitle"> {{ convertDate(event.start_date,
                                    event.end_date,event.utcoffset)[0].split('
                                    ')[1] }}</span>
                                <span id="year" class="smallText white-color Quicksand-regular"
                                    style="text-align: center;"> 2022 </span>
                            </div>
                            <div class="time-details ">
                                <div class="smallText" style="display:inline-flex">
                                    <div class="smallText Quicksand-regular" style="color: #8F9BB3;margin-right: 5px;">
                                        Start :
                                    </div><span class="smallText Quicksand-semibold">{{
                                        convertDate(event.start_date, event.end_date,event.utcoffset)[0].split(' ')[2]
                                        }}</span>
                                </div>
                                <div class="smallText" style="display:inline-flex">
                                    <div class="smallText Quicksand-regular" style="color: #8F9BB3;margin-right: 5px;">
                                        End :
                                    </div><span>{{
                                        convertDate(event.start_date,
                                        event.end_date,event.utcoffset)[1].split(' ')[2] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="days-datails" *ngIf="event.days && event.days.length > 1">
                        <div>
                            <div class="day-card">
                                <span id="month" class="smallText white-color uicksand-regular"
                                    style="text-align: center;"> {{
                                    convertDate(event.start_date, event.end_date,event.utcoffset)[0].split(' ')[0]
                                    }}</span>
                                <span id="day" class="subTitle"> {{ convertDate(event.start_date,
                                    event.end_date,event.utcoffset)[0].split('
                                    ')[1] }}</span>
                                <span id="year" class="smallText white-color Quicksand-regular"
                                    style="text-align: center;"> 2022 </span>
                            </div>
                            <div class="time-details">
                                <div style="display:inline-flex">
                                    <div class="smallText Quicksand-regular" style="color: #8F9BB3;margin-right: 5px;">
                                        Start :
                                    </div><span>{{ convertDate(event.start_date,
                                        event.end_date,event.utcoffset)[0].split(' ')[2] }}</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="day-card">
                                <span id="month" class="smallText white-color Quicksand-regular"
                                    style="text-align: center;;"> {{
                                    convertDate(event.start_date, event.end_date,event.utcoffset)[1].split(' ')[0]
                                    }}</span>
                                <span id="day" class="subTitle"> {{ convertDate(event.start_date,
                                    event.end_date,event.utcoffset)[1].split('
                                    ')[1] }}</span>
                                <span id="year" class="smallText white-color Quicksand-regular"
                                    style="text-align: center;"> 2022 </span>
                            </div>
                            <div class="time-details">
                                <div style="display:inline-flex">
                                    <div class="smallText Quicksand-regular" style="color: #8F9BB3;margin-right: 5px;">
                                        End :
                                    </div><span>{{ convertDate(event.start_date,
                                        event.end_date,event.utcoffset)[1].split(' ')[2] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="access-details">
                        <div *ngIf="join || haveAccess " class="ticket-details paragraphe">
                            <div *ngIf="message !== ''">
                                <div class="paragraphe Quicksand-medium" style="color: #A3A3A3;"><img
                                        src="../../../assets/portal/V.png"
                                        style="width: 15px;height: 15px;align-self: center;"> You have access to this
                                    event</div>
                                <div class="ticket-name paragraphe Quicksand-medium" style="align-items: center;">
                                    <div (click)="showTicket()">{{message}}
                                    </div>
                                    <img src="../../../assets/portal/Groupe 13274.png"
                                        style="position: relative; top: -5px; left: 3px;">
                                </div>
                            </div>

                            <div *ngIf="message == '' && show" class="paragraphe Quicksand-medium"
                                style="margin-bottom: 5px;color: #A3A3A3;">
                                <img src="../../../assets/portal/V.png" style="width: 15px;
                                height: 15px;
                                align-self: center;"> You have access to this event
                            </div>
                        </div>
                        <div *ngIf="!join && !haveAccess" class="ticket-details">
                        </div>
                        <div *ngIf="register" class="action-btn subTitle Quicksand-bold" style="color :#141A2A;text-align:center;display: flex;
                        flex-direction: column;
                        justify-content: center;" (click)="onClickRegister()">
                            REGISTER
                        </div>
                        <div class="lauched-btn subTitle Quicksand-bold" style="text-align: center;align-self: center;"
                            *ngIf="countdown">
                            <img class="icon" src="../../../assets/portal/stopwatch_23f1-fe0f.png" />
                            <countdown [config]="moreThan24Hours" style="align-self: center;"></countdown>
                        </div>
                        <div class="action-btn subTitle Quicksand-bold" style="color :#141A2A;text-align:center;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;" *ngIf="event.status == 'live'" (click)="onClickJoinEvent()">
                            {{joinBtn}}
                        </div>
                        <div *ngIf="event.status == 'finished'"
                            class="action-btn finished-btn  subTitle Quicksand-bold">
                            <img src="../../../assets/portal/icons/popper.png"
                                style="width: 30px;margin-right: 10px;text-align: center;">
                            <div style="align-self: center;color: white;">FINISHED</div>
                        </div>
                        <div style="display: inline-flex;padding:10px 0px 0px 0px;" *ngIf="event.is_inviteonly">
                            <img style="width: 10px;height: 50%;align-self: center;margin-right: 5px;"
                                src="../../../assets/portal/lock1.png">
                            <div class="tagsText Quicksand-medium" style="color: #A3A3A3;">This event is by invitation
                                only</div>
                        </div>
                        <hr style="background: #262626; width: 90px; margin: 10px 70px !important;">
                        <div class="social-media ">
                            <span class="tagsText">Share this event on : </span>
                            <div>
                                <img src="../../../assets/twiter.png">
                                <img src="../../../assets/fb.png">
                                <img src="../../../assets/IN.png" style="width: 20px;height: 20px;">
                                <img src="../../../assets/url.png">
                                <div class="calendar-icon"> <img src="../../../assets/Groupe 12361.png"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="padding-div"></div>
        </div>
    </div>
    <div class="tabs-container">
        <div style=" border-bottom : 1px solid #262626 ;display: inline-flex; flex-wrap: nowrap;    width: 100%;">
            <div class="padding-div"></div>
            <div id="tabButtons" class="tab-buttons" [class.sticky]="sticky" #stickyMenu>

                <button id="descBtn" class="tablinks subTitle Quicksand-semibold "
                    (click)="OnClickTab('descBtn','description')">Description</button>
                <button *ngIf="event.areas && event.areas.includes('sessions') " id="sessionBtn"
                    class="tablinks subTitle Quicksand-semibold"
                    (click)="OnClickTab('sessionBtn','session')">Sessions</button>
                <button id="speakerBtn" class="tablinks subTitle Quicksand-semibold"
                    (click)="OnClickTab('speakerBtn','speaker')">Speakers
                </button>
                <button *ngIf="event.areas && event.areas.includes('exhibitors') " id="exhibitorBtn"
                    class="tablinks subTitle Quicksand-semibold"
                    (click)="OnClickTab('exhibitorBtn','exhibitor')">Exhibitors</button>
            </div>
            <div class="padding-div"></div>
        </div>
        <div style="display: inline-flex; flex-wrap: nowrap;width: 100%;">
            <div class="padding-div"></div>

            <div id="tabsBody" [ngClass]="sticky == true ? 'tabs-body' : 'tabcontent'" style="width: 100%;">
                <div id="description" *ngIf="activateTabDescription">
                    <div class="smallText">
                        <div class="paragraphe Quicksand-regular" [innerHTML]="event.description "></div>
                    </div>
                    <div id="sponsor" *ngIf="event && event.sponsors && event.sponsors.length>0" class="Quicksand-bold"
                        style="margin-top: 40px;">
                        <span class="subTitle"> Sponsors </span>
                        <div class="sponsor-container">
                            <img *ngFor="let url of event.sponsors" [src]=url>
                        </div>
                    </div>
                    <!--Video-->
                    <!-- <div *ngIf="event.videos && event.videos.length>0">
                        <div class="titleSession subTitle">Video</div>
                        <div id="videoContainer" class="videos-container">
                            <div *ngFor="let video of event.videos;let i=index" [id]="'videoCard'+i" class="video-wrapper">
                                <video #video
                                    [id]="i" [muted]="'muted'">
                                    <source [src]="video" type="video/mp4">
                                </video>
                                <div [id]="'image'+session._id"
                                    *ngIf="session.cover_url && session.cover_url.split('.')[ session.cover_url.split('.').length-1]!='mp4'"
                                    [style.background-image]="'url('+session.cover_url+')'" class="session-cover">
                                </div>
                                <div class="content-video" (mouseenter)="playVideo(i ,true)"
                                    (mouseleave)="playVideo(i ,false)">
                                    <div class="header-btn">
                                        <div class="duration tagsText">
                                            {{video.duration}}
                                        </div>
                                    </div>
                                    <div style="display: flex;justify-content: center;height: 100%;">
                                        <div [id]="'whitePlay'+i" class="play-icon">
                                        </div>
                                        <div [id]="'redPlay'+i" class="play-icon-hover">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="session-video-info">
                                            <div class="smallText Quicksand-bold"
                                                style="text-align: start;margin-bottom: 5px">
                                                {{video.title}}
                                            </div>
                                        </div>
                                        <div class="session-video-footer">
                                            <div class="duration tagsText">
                                                {{video.duration}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>

                <div id="session" *ngIf="activateTabSession">

                    <app-session [rooms]="rooms" [myRooms]="myRooms" [myDays]="myDays"
                        [total_Fsessions]="total_Fsessions" [availableSessions]="availableSessions"></app-session>
                </div>
                <div id="speaker" *ngIf="activateTabSpeaker">
                    <app-speaker id="speaker-container" [speakers]="speakers" [total_speakers]="total_speakers">
                    </app-speaker>
                </div>
                <div id="exhibitor" *ngIf="activateTabExhibitor">
                    <app-exhibitor [exhibitors]="exhibitors"></app-exhibitor>
                </div>
            </div>
            <div class="padding-div"></div>

        </div>
    </div>
</div>