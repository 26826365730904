<!-- <div *ngIf="data.showNavBar"  class="top-bar">
    <img src="../../../assets/popups/icons8-close-30.png" style="width: 25px;" (click)="back()">
</div> -->
<div *ngIf="(!launched && code && !ticket && !havepass) || (data.invited && !ticket)" class="proccess-complet">
    <div class="image">
        <img src="../../../assets/popups/welcome.png" />
    </div>
    <div *ngIf="!launched && code && !data.invited " class="sub-title" style="margin-bottom: 20px;text-align: center;color: #A3A3A3;">Welcome</div>
    <div *ngIf="data.invited" class="sub-title" style="margin-bottom: 20px;text-align: center;color: #A3A3A3;">A pass has been allocated to you</div>
    <div class="join-btn" *ngIf="!launched">
        <button class="button" (click)="redirectToEventInterface()">Join this event</button>
    </div>
    <div class="lauched-btn subTitle" *ngIf="launched">
        <img src="../../../assets/portal/stopwatch_23f1-fe0f.png" />
        <countdown [config]="moreThan24Hours"></countdown>
    </div>
</div>
<div *ngIf="!data.passes" class="proccess-complet">
    <div class="image">
        <img src="../../../assets/popups/error.png" />
    </div>
    <div class="sub-title" style="margin-bottom: 20px;text-align: center;color: #A3A3A3;">Sorry, this event has no more passes left</div>
</div>
<div *ngIf="havepass && !ticket && !launched && !data.invited " class="proccess-complet">
    <div class="image">
        <img src="../../../assets/popups/welcome.png" />
    </div>
    <div class="sub-title" style="margin-bottom: 20px;text-align: center;color: #A3A3A3;">Welcome</div>
    <div class="join-btn" *ngIf="!launched">
        <button class="button" (click)="redirectToEventInterface()">Join this event</button>
    </div>
    <div class="lauched-btn subTitle" *ngIf="launched">
        <img src="../../../assets/portal/stopwatch_23f1-fe0f.png" />
        <countdown [config]="moreThan24Hours"></countdown>
    </div>
</div>
<div *ngIf="ticket" class="ticket-container">
    <div class="header">
        <div *ngIf="!havepass && !data.showTicket && !data.invited" class="title popup-title"> Congratulations, you have your pass </div>
        <div *ngIf="data.invited" class="title popup-title">Welcome</div>
        <div *ngIf="havepass && !data.showTicket && !data.invited" class="title popup-title"> Welcome</div>
        <div class="event-info">
            <div style="width: 50%;">
                <div *ngIf="event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]!='mp4'" class="ar-16-9 " [style.background-image]="'url('+event.cover_url+')'"></div>
                <div *ngIf="event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]=='mp4'">
                    <video autoplay="autoplay" loop muted style="width: 100%;">
                        <source [src]="event.cover_url" type="video/mp4;">
                    </video>
                </div>            </div>
            <div style="align-self: center; margin-left: 10px;">
               
                <div class="subTitle Quicksand-semibold" style="text-align: left;margin-bottom: 10px;line-height: 25px;">{{event.title}}</div>
                <div class="paragraphe Quicksand-regular" style="text-align: left;color: #FFFFFF">{{event.start_date}}</div>
            </div>
        </div>
    </div>

    <div class="ticket1" [style.background-color]="ticket.color">
        <div class="ticket-details">
            <div class="ticket-name subTitle">
                {{ticket.name}}
            </div>
            <div class="ticket-desc line-limit  paragraphe">
                {{ticket.description}}
            </div>
        </div>

        <div class="separateur">
            <div class="circle" style="top: -36px;"></div>
            <div class="vl"></div>
            <div class="circle" style="bottom: -36px;"></div>
        </div>
        <div class="price subTitle">
            {{ticket.price}}{{ticket.currency}}
        </div>
    </div>

    <div class="join-btn" *ngIf="!launched">
        <button class="button" (click)="redirectToEventInterface()">Join this event</button>
    </div>
    <div class="lauched-btn subTitle" *ngIf="launched">
        <img src="../../../assets/portal/stopwatch_23f1-fe0f.png" />
        <countdown [config]="moreThan24Hours"></countdown>
    </div>
</div>
<div *ngIf="((launched && code) || (launched && havepass))&& !ticket && !data.invited" class="ticket-container">
    <div class="header">
        <div *ngIf="!havepass" class="title popup-title"> Congratulations, you have your pass </div>
        <div *ngIf="havepass" class="title popup-title"> Welcome</div>
        <div class="event-info">
            <div style="width: 50%;">
                <div *ngIf="event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]!='mp4'" class="ar-16-9 " [style.background-image]="'url('+event.cover_url+')'"></div>
                <div *ngIf="event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]=='mp4'">
                    <video autoplay="autoplay" loop muted style="width: 100%;">
                        <source [src]="event.cover_url" type="video/mp4;">
                    </video>
                </div>            </div>
            <div style="align-self: center; margin-left: 10px;">
                
                <div class="subTitle Quicksand-semibold" style="text-align: left;margin-bottom: 10px;line-height: 25px;">{{event.title}}</div>
                <div class="paragraphe Quicksand-regular" style="text-align: left;color: #FFFFFF">{{event.start_date}}</div>
            </div>
        </div>
    </div>
    <div class="lauched-btn subTitle" *ngIf="launched">
        <img src="../../../assets/portal/stopwatch_23f1-fe0f.png" />
        <countdown [config]="moreThan24Hours"></countdown>
    </div>
</div>
<div *ngIf="!data.invited && !ticket && !code && data.passes && !havepass" class="proccess-complet">
    <div class="image">
        <img src="../../../assets/popups/locked.png" />
    </div>
    <div class="subTitle" style="margin-bottom: 20px;text-align: center;color: #A3A3A3;">Sorry, you have not been invited to this event</div>
</div>