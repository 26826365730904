<div class="sidebar" id="sidebar">
    <div class="Back">
        <a href="/dashboard"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back to dashboard</a>
    </div>
    <div class="Item" [ngClass]="currenttab == 'Event Page' ? 'activatedItem' : ''" (click)="clickOnTab('Event Page')">
        Event Page</div>
    <div class="Item" [ngClass]="currenttab == 'Registration' ? 'activatedItem' : ''" (click)="clickOnTab('Registration')">Registration</div>
    <div *ngIf="accessevent=='auth' ||currenttab == 'Access Pass'" class="Item" [ngClass]="currenttab == 'Access Pass' ? 'activatedItem' : ''" (click)="clickOnTab('Access Pass')">Access Pass</div>
    <div *ngIf="areasevent && areasevent.includes('sessions')" class="Item" [ngClass]="currenttab == 'Sessions' ? 'activatedItem' : ''" (click)="clickOnTab('Sessions')">Sessions</div>
    <div *ngIf="areasevent && areasevent.includes('exhibitors')" class="Item" [ngClass]="currenttab == 'Exhibitors' ? 'activatedItem' : ''" (click)="clickOnTab('Exhibitors')">Exhibitors</div>
    <div class="Item" [ngClass]="currenttab == 'Invitations' ? 'activatedItem' : ''" (click)="clickOnTab('Invitations')">Invitations</div>

</div>