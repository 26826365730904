import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultLayoutRoutingModule } from './default-layout-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { DefaultLayoutComponent } from './default-layout.component';
import { RouterModule } from '@angular/router';
import { EventPageComponent } from '../event-page/event-page.component';
import { PassesComponent } from '../passes/passes.component';
import { NgSelectModule } from '@ng-select/ng-select'; 
import { InvitationsComponent } from '../invitations/invitations.component';
import { RegistrationComponent } from '../registration/registration.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { SortPipe } from '../sort.pipe';
import { NbAccordionModule, NbCheckboxModule, NbLayoutModule, NbRadioModule, NbSidebarModule } from '@nebular/theme';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PortalModule } from 'src/app/portal/portal.module';
@NgModule({
  declarations: [SidebarComponent,SortPipe, DefaultLayoutComponent, EventPageComponent, PassesComponent,InvitationsComponent,RegistrationComponent],
  imports: [
    CommonModule, RouterModule, 
    DefaultLayoutRoutingModule,
    FormsModule, ReactiveFormsModule
    ,NgSelectModule ,
    AngularMultiSelectModule,
    NbSidebarModule,
    NbLayoutModule,
    CKEditorModule,
    NbAccordionModule ,
    NbCheckboxModule,
    NbRadioModule,
    PortalModule
  ]
})
export class DefaultLayoutModule { }
