import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardTestService } from './dashboard-test.service';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { ExploreEventService } from '../explore-event/explore-event.service';

import { OrganisationSettingsComponent } from './organisation-settings/organisation-settings.component';
 const routes: Routes =
  [
    {
      // canActivate: [AuthGuard],
      path: "",
      component: DashboardComponent,
       
      resolve: { DashboardTestService,ExploreEventService },
      data: {
        title: "all events",
        page: ""
      },
    },
    {
      path: ":eventid",
      component: DefaultLayoutComponent,

      children: [
        {
          path: '',
          loadChildren: () => import('./default-layout/default-layout.module').then(m => m.DefaultLayoutModule)
        },
      ]
    },
    {
      path: "organisation/:organisationid",
      component: OrganisationSettingsComponent,
      resolve: { DashboardTestService },
      data: {
        title: "organisation settings",
        page: ""
      },
    },
    // {
    //   path: "confirm/invitation",
    //   component: ConfirmInvitationComponent,
    //   // resolve: { DashboardTestService },
    //   data: {
    //     title: "confirm Invitation",
    //     page: ""
    //   },
    // }
  ];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardTestRoutingModule { 

  
}
