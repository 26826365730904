<div class="cover-profile">
    <div class="cover">
        <div class="cover-background">
            <div *ngIf="cover && cover.split('.')[ cover.split('.').length-1]!='mp4'" class="speaker-cover">
                <div [style.background-image]="'url('+cover+')'" class="speaker-cover-img"></div>
            </div>
            <div *ngIf="cover && cover.split('.')[ cover.split('.').length-1]=='mp4'" class="speaker-cover">
                <video id="videoCover" #video autoplay loop [muted]="'muted'" style="width: 100%;">
                    <source [src]="cover" type="video/mp4;">
                </video>
            </div>
        </div>
    </div>
    <div class="cover-content">
        <div *ngIf="!muted && cover && cover.split('.')[ cover.split('.').length-1]=='mp4' " style="width: 35px;
        height: 35px;
        background-repeat: no-repeat;
        position: relative;
        align-self: flex-end;
        margin-right: 60px;
        cursor: pointer;
        bottom: 190px;
        background: url(../../../assets/on_sound.png);" (click)="soundOnOff(false)"></div>
        <div *ngIf="muted && cover && cover.split('.')[ cover.split('.').length-1]=='mp4'" style="width: 35px;
        height: 35px;
        background-repeat: no-repeat;
        position: relative;
        align-self: flex-end;
        margin-right: 60px;
        cursor: pointer;
        bottom: 190px;
        background: url(../../../assets/off_sound.png);" (click)="soundOnOff(true)"></div>
        <div *ngIf="speaker?.additional_data?.cover_profile?.type == 'session'" class="watchSession">
            <div style="display: flex;flex-direction: column;gap:5px;align-self:center ;max-width: 290px;">
                <div class="Quicksand-semibold paragraphe line-limit-two">
                    {{speakerSession.title}}
                </div>
                <div class="tagsText Quicksand-regular line-limit-one">
                    Event :  {{speakerEvent}}
                </div>
            </div>
            <div class="message-btn Quicksand-bold" style="align-self:center ;cursor: pointer;" (click)="openVideoPlayer()">Watch</div>
        </div>
    </div>
</div>
<div style="display: inline-flex;width: 100%;position: relative;top: -117px;">
    <div class="padding-div"></div>
    <div style="display: flex;flex-direction: column;width: 100%;gap: 40px;">
        <div class="speaker-infos">
            <div style="display: inline-flex;gap: 20px;">
                <div style="width: 150px;
                height: 150px;
                border-radius: 50%;
                align-self: center;
                background-size: cover;
                background-position: center;" [style.background-image]="'url('+speaker?.additional_data?.photo+')'">
                </div>
                <div class="speaker-info-perso">
                    <div class="PopupTitle Quicksand-bold" style="align-self:flex-start">{{speaker.firstname}} {{speaker.lastname}}</div>
                    <div style="display: inline-flex; gap:45px;">
                        <div *ngIf="speaker.additional_data && speaker.additional_data.country"
                            style="display: inline-flex;gap:5px">
                            <div style="background: url(../../../assets/pin.png);" class="small-icons"></div>
                            <div class="Quicksand-medium paragraphe" style="flex: none;">
                                {{speaker?.additional_data?.country}}</div>
                        </div>
                        <div *ngIf="speaker.additional_data && speaker.additional_data.title"
                            style="display: inline-flex;gap:5px">
                            <div style="background: url(../../../assets/job.png);" class="small-icons"></div>
                            <div class="Quicksand-medium paragraphe" style="flex: none;">
                                {{speaker?.additional_data?.title}}</div>
                        </div>
                    </div>
                    <div style="display: inline-flex;gap:5px">
                        <div style="background: url(../../../assets/followers.png);" class="small-icons"></div>
                        <div class="Quicksand-medium paragraphe" style="text-decoration: underline;">Followers: </div>
                        <div class="Quicksand-medium paragraphe" style="text-decoration: underline;">1,6K</div>
                    </div>
                    <div style="display: inline-flex; gap:20px;margin-top: 10px;">
                        <!-- <div class="watch-btn message-btn Quicksand-bold smallText">Message</div> -->
                        <div class="watch-btn follow-btn Quicksand-bold smallText">Follow</div>
                    </div>
                </div>
            </div>
            <div style="display: inline-flex;gap:20px;align-self: flex-end;">
                <div class="icon" style="background: url(../../../assets/TW.png);" (click)="openTwitter()"></div>
                <div class="icon" style="background: url(../../../assets/IN.png);" (click)="openLinkedIn()"></div>
            </div>
        </div>
        <div class="extra-info">
            <div class="about-speaker" *ngIf="speaker.additional_data && speaker.additional_data.bio">
                <div class="Quicksand-semibold smallText" style="text-align: left;">About me</div>
                <div class="paragraphe Quicksand-regular line-limit"> {{speaker?.additional_data?.bio}}</div>
                <div class="showMore-btn tagsText Quicksand-semibold "> SHOW MORE </div>
            </div>
            <div class="speaker-interests"
                *ngIf="speaker.additional_data && speaker.additional_data.interests && speaker.additional_data.interests.length>0 ">
                <div style=" height: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                padding: 30px 15px 0 15px">
                    <div class="Quicksand-semibold smallText" style="text-align: left;margin-left: 15px;">Select your
                        interests</div>
                    <div class="interests-container">
                        <div *ngFor="let tag of speaker.additional_data.interests ; let i=index" [id]="'tag'+i"
                            class="tag tagsText" style="color:#8f9bb3;height: 30px;padding: 0 12px;cursor: pointer;"
                            (click)="clickTag(i)">
                            {{tag |titlecase}}
                        </div>
                    </div>
                </div>

                <div class="interests-shadow">

                </div>
            </div>
        </div>
        <div *ngIf="sessions && sessions.length>0" style="display: flex;flex-direction: column;gap: 20px;">
            <div class="titles" style="margin-bottom: 15px;text-align: left;width: fit-content;">
                <div class="highlighted-title subTitle Quicksand-bold">Expert Opinion</div>
            </div>
            <app-playlist-player [isProfile]="'true'" [data]="sessions"></app-playlist-player>
        </div>
    </div>
    <div class="padding-div"></div>
</div>
<app-footer></app-footer>