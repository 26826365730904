<div class="padding-div"></div>

<div *ngIf="((!is_admin || is_admin !='true') && selectedSpeakers.length == 3) || is_admin=='true'"
    style="width: 100%;">

    <div class="key-opinion-part">
        <div style="display: inline-flex;">
            <div style="display: inline-flex;justify-content:space-between;width:100%;align-items: center;">
                <div [ngClass]="sections_title_icon && sections_title_icon !='' ?'titles_dupiflix':'titles'"
                    style="display:inline-flex;gap:20px;align-items: center;">
                    <div style="display: inline-flex;align-items: center;">
                        <img *ngIf="sections_title_icon && sections_title_icon !=''" [src]="sections_title_icon" style="width: 20px;margin-right: 5px;
                    height: 20px;" />

                        <div [ngClass]="!sections_title_icon || sections_title_icon =='' ?'highlighted-title':''"
                            class=" subTitle Quicksand-bold">

                            Speakers </div>
                    </div>
                </div>
                <div *ngIf="is_admin && is_admin=='true'" class="show-all smallText Quicksand-semibold"><i
                        class="fa fa-pencil" style="color: white;cursor: pointer;" (click)="updateSpeakers()"></i>
                </div>
            </div>
        </div>
        <div class="key-opinion-container">
            <div style="display: flex;
            position: absolute;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            top: 0;">
                <div class="speakers-photos">
                    <div *ngIf="is_admin && is_admin =='true' && (!selectedSpeakers || (selectedSpeakers && selectedSpeakers.length==0))"
                        class="speakers-photos">
                        <img src="../../../assets/noData.png" style="margin:auto;" />
                    </div>
                    <div *ngFor="let speaker of selectedSpeakers ; let i=index" class="speaker-photo"
                        [defaultImage]="defaultImage" [lazyLoad]="speaker?.additional_data?.photo"
                        (onStateChange)="onStateChange($event,i)" [style.left.px]="-55*(i) "
                        (click)="gotoProfile(speaker._id)" style="cursor: pointer;">
                        <div class="speaker-content" (mouseenter)="showLinkedIn(true,i)"
                            (mouseleave)="showLinkedIn(false,i)">
                            <div *ngIf="!loading"
                                style="display: inline-flex; justify-content: space-between;padding:0 20px 40px 20px">
                                <div style="display: flex;flex-direction: column;justify-content: center;">
                                    <div class="smallText Quicksand-bold"> {{speaker?.firstname}} {{speaker?.lastname}}
                                    </div>
                                    <div class="paragraphe Quicksand-regular"> {{speaker?.additional_data?.title}}
                                    </div>
                                </div>
                                <div *ngIf="speaker?.additional_data?.social_media?.linkedin && speaker?.additional_data?.social_media?.linkedin !='' " [id]="i" style="visibility: hidden;">
                                    <a [href]="speaker?.additional_data?.social_media?.linkedin" style="text-decoration: none; color: white;"
                                        target="_blank">
                                        <div class="icon-in">
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <ngx-spinner class="speaker-content" style=" background:#0e112000;width: 100%;height: 100%; border-radius: 10px;position: absolute;display: flex;flex-direction: column;
                            justify-content: center;" [name]="'speakerSpinner'+i" bdColor="#0e112000" size="default"
                                color="#8fa7e6" type="ball-spin-clockwise" [fullScreen]="false"></ngx-spinner>
                        </div>
                    </div>
                </div>
                <div id="paragraphe" class="speakers-paragraphe" *ngIf="showParagraphe">
                    <div class="bigTitle speakers-title Quicksand-bold" [style.color]="color_config"
                        [innerHTML]="speakers_message.title">

                    </div>
                    <div class="paragraphe speakers-text Quicksand-regular" [innerHTML]="speakers_message.message">

                    </div>
                    <div class="speaker-showMore Quicksand-bold smallText">SHOW MORE</div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="loading" style="display: flex;justify-content: center;">
        <div class="donut"></div>
    </div>
</div>
<div class="padding-div"></div>