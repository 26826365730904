<div class="padding-div" ></div>
<div class="testimonials-part" *ngIf="testimonials && testimonials.length>0 ||  is_admin=='true'" >
    <div style="display: inline-flex;">
        
        <div style="display: inline-flex;justify-content:space-between;width:100%;align-items: center;">
         

            <div [ngClass]="sections_title_icon && sections_title_icon !='' ?'titles_dupiflix':'titles'"
            style="display:inline-flex;gap:20px;align-items: center;">
            <div style="display: inline-flex;align-items: center;">
                <img *ngIf="sections_title_icon && sections_title_icon !=''" [src]="sections_title_icon" style="width: 20px;margin-right: 5px;
                height: 20px;" />

                <div  [ngClass]="!sections_title_icon || sections_title_icon =='' ?'highlighted-title':''" class=" subTitle Quicksand-bold">

                    Testimonials </div>
            </div> 
        </div>



            <div *ngIf="is_admin && is_admin=='true'" class="show-all smallText Quicksand-semibold"><i
                    class="fa fa-pencil" style="color: white;cursor: pointer;" (click)="updateTestimonials()"></i>
            </div>
        </div>
    </div>  
    <div  *ngIf="testimonials &&testimonials.length>0 ">

   
    <div *ngIf="!loading" style="display: inline-flex;justify-content: flex-start;
    padding-top: 22.22%;
    min-height: 315px;
    position: relative;">
        <div style="display: inline-flex;top: 0;
        bottom: 0;
        position: absolute;">
            <div class="testimonials-container">
                <div
                    *ngIf="is_admin && is_admin =='true' && (!testimonials || (testimonials && testimonials.length==0))">
                    <img src="../../../assets/noData.png" style="margin:auto;" />
                </div>
                <div *ngFor="let testimonial of testimonials ; let i = index" [id]="testimonial._id"
                    [ngClass]="selectedTestimonial == testimonial._id ? 'testimonial' : 'testimonial-reduite'"
                    [style.zIndex]="(testimonials.length - i)*1000"
                    [ngStyle]="{'background-color':(i==1) ? '#1E2946':(i==2) ?'#1B253D':(i==3) ?'#171F33' : '#243050' , 'border-radius':(i==0) ? '10px 0 0 10px' :'none'} ">
                    <div *ngIf="selectedTestimonial == testimonial._id" class="testimonial-details">
                        <div class="testimonial-header">
                            <div style="display: inline-flex;gap: 40px;">
                                <div *ngIf="testimonial.speaker && testimonial.speaker.additional_data && testimonial.speaker.additional_data.photo"
                                    class="testimonial-img"
                                   [ngStyle]="{'border':color_config=='#fff' ? 'none' :'2px solid '+color_config}"
                                    [style.background-image]="'url('+testimonial.speaker?.additional_data?.photo+')'"
                                    style="background-position: center;"><img style="visibility: hidden;"
                                        [src]="testimonial.speaker?.additional_data?.photo"></div>
                                <div *ngIf="testimonial.speaker && (!testimonial.speaker.additional_data  || !testimonial.speaker.additional_data.photo)"
                                    class="testimonial-img"
                                    [style.background-image]="'url(../../assets/placeholder.png)'"
                                    style="background-position: center;"><img style="visibility: hidden;"
                                        [src]="'../../assets/placeholder.png'"></div>
                                <div class="testimonial-speaker">
                                    <div class="smallText Quicksand-bold"> {{testimonial.speaker?.firstname| titlecase}}
                                        {{testimonial.speaker?.lastname| titlecase}}</div>
                                    <div class="paragraphe Quicksand-regular">
                                        {{testimonial.speaker.title | titlecase}} </div>
                                </div>
                            </div>
                        </div>
                        <div style="display:flex;flex-direction:column;">
                            <div class="icon-testimonial"></div>
                            <div class="paragraphe testimonial-text Quicksand-regular"
                                style="position: absolute; z-index: -10; margin-bottom: 10px;  visibility: hidden;margin-top: 30px;font-family: Quicksand-regular;"
                                [id]="'testimonial'+i">
                                {{testimonial.testimonial | titlecase}} </div>
                            <div class="line-limit paragraphe  testimonial-text Quicksand-regular"
                                [id]="i+'testimonial'">
                                {{testimonial.testimonial | titlecase}} </div>
                        </div>
                        <!-- <div *ngIf="countLines(i)>2 && readmore" class="paragraphe readMore" style="font-family : Quicksand-semibold"
                          (click)="readMoreTestimonial(i)">Read more </div>
                        <div *ngIf="countLines(i)>2 && !readmore" class="paragraphe readMore" style="font-family : Quicksand-semibold"
                          (click)="readMoreTestimonial(i)"> REDUCE</div> -->
                    </div>
                    <div *ngIf="selectedTestimonial != testimonial._id" class="testimonial-details-reduite"
                        [ngStyle]="{'border-radius':i==0 ? '10px 0 0 10px' :'none'}"
                        (click)="openTestimonial(testimonial._id,(testimonials.length - i)*1000)">
                        <!-- <div class="testimonial-img" [defaultImage]="defaultImage" [style.background-image]="'url('+testimonial.speaker.photo+')'"
                            style="align-self: center;">
                            <img style="visibility: hidden;" [src]="testimonial.speaker.photo">
                        </div> -->
                        <div *ngIf="testimonial.speaker && testimonial.speaker.additional_data && testimonial.speaker.additional_data.photo"
                            class="testimonial-img"
                            [ngStyle]="{'border':color_config=='#fff' ? 'none' :'2px solid '+color_config}"

                            [style.background-image]="'url('+testimonial.speaker?.additional_data?.photo+')'"
                            style="background-position: center;"><img style="visibility: hidden;"
                                [src]="testimonial.speaker?.additional_data?.photo"></div>
                        <div *ngIf="testimonial.speaker && (!testimonial.speaker.additional_data  || !testimonial.speaker.additional_data.photo)"
                            class="testimonial-img" [style.background-image]="'url(../../assets/placeholder.png)'"
                            style="background-position: center;"><img style="visibility: hidden;"
                                [src]="'../../assets/placeholder.png'"></div>

                        <div class="smallText one-line-limit Quicksand-bold" style="transform: rotate(90deg);">
                            {{testimonial.speaker?.firstname| titlecase}} {{testimonial.speaker?.lastname| titlecase}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ngx-spinner style=" background:#0e112000;width: 100%;height: 100%;display: flex;flex-direction: column;
    justify-content: center;" bdColor="#0e112000" size="default" color="#8fa7e6" type="ball-spin-clockwise"
        [fullScreen]="false"></ngx-spinner>
    </div>
</div>
<div class="padding-div"></div>

