<!-- <app-shared-explore></app-shared-explore> -->
<app-explore-event [is_admin]="'true'"></app-explore-event>
<!---------Category----------->


<!-- <div style="height: 20vh; background:red;width:100%;">
</div>
<div class="explore-home-card">
    <div class="cover-explore">
        <div id="slideshow" class="slider">
            <div *ngIf="!uploaded " class="cover-diapo">
                <input type="file" (change)="readURL($event)" name="video" id="inputfile" style="opacity: 0;position: absolute;" accept="video/*" />
                <label class="changeVideo Quicksand-medium" style="cursor: pointer;" for="inputfile">
                    Change cover video</label>
                <video *ngIf="video   " autoplay="autoplay" [loop]="'true'" [muted]="'muted'" style="width: 100%; height: 100%;">
                    <source [src]="video" type="video/mp4;">
                </video>
            </div>
            <div *ngIf="uploaded  " class="cover-diapo">
                <div *ngIf="uploaded" style="    position: absolute;
                    float: right;
                    cursor: pointer;
                    z-index: 1;
                    top: 10px;
                    right: 10px;
                    display:inline-flex;
                ">
                    <div class="saveVideo" (click)="saveVideo()">Save</div>
                    <div class="cancelVideo" (click)="cancelVideo()">Cancel</div>
                </div>
                <video *ngIf="videoSrc" autoplay="autoplay" [loop]="'true'" [muted]="'muted'" style="width: 100%; height: 100%;">
                    <source [src]="videoSrc" type="video/mp4;">
                </video>
            </div>
        </div>
        <div class="cover-content" id="cover-content">
            <div style="align-self: center;width: 100%; display: flex; justify-content: center;">
                <div style="display: flex;flex-direction:column;justify-content: center;overflow: hidden;">
                    <div class="paragraphe Quicksand-regular" style="margin-bottom: 10px;">
                        Our suggestions of specialties, diseases and treatment topics :
                        <span> <i class="fa fa-pencil icons" style="cursor: pointer;"
                                (click)="AddCategory()"></i></span>
                    </div>
                    <div id="categories-container" class="categories-container">
                        <div id="leftarrowDay" *ngIf="rightArrowCategorie >= 5 " class="arrowLeft" (mouseenter)="scrollCategories(divTest, 5,'categories-container','leftarrowDay')" (mouseleave)="stopTimerCategories(timerCategories)"></div>
                        <div id="categories" class="categories">
                            <div *ngFor="let categorie of ListCategories" class="categorie">
                                <div class="icon-categorie" style="color: white;">
                                    <img [src]="categorie.icon" style="width: 30px;height:30px" />
                                </div>
                                <div *ngIf="!categorie.name.split(' ')[2] || categorie.name.split(' ')[2] == '' " class="  name-categorie Quicksand-semibold">
                                    <div>{{categorie.name}}</div>
                                </div>
                                <marquee *ngIf="categorie.name.split(' ')[2] && categorie.name.split(' ')[2] != '' " direction="left" lass="  name-categorie Quicksand-semibold">
                                    {{categorie.name}} </marquee>
                            </div>
                        </div>
                        <div id="rightarrowDay" *ngIf="overflowActivatedCategorie" class="arrowRight" style="margin-right: 10px;" (mouseenter)="scrollCategories(divTest, -5,'categories-container','rightarrowDay')" (mouseleave)="stopTimerCategories(timerCategories)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!---------Recommended----------->
<!-- <div class="recommended-events-part">
    <div style="display: inline-flex;">
        <div class="padding-div"></div>
        <div style="display: inline-flex;justify-content:space-between;width:100%">
            <div class="titles" style="display:inline-flex;gap:20px">
                <div class="highlighted-title subTitle Quicksand-bold" style="width: 100%;">Recommended</div>
            </div>
            <div class="show-all smallText Quicksand-semibold"><i class="fa fa-pencil" style="color: white;cursor: pointer;" (click)="updateRecommendedEvents()"></i> </div>
        </div>
        <div class="padding-div"></div>
    </div>
    <div *ngIf="recommendedevents && recommendedevents.length>0" id="recommended-events-container" class="recommended-events-container " style="padding: 0 8%;" #recommendedEvents (mousedown)="startDragging($event, false, recommendedEvents)" (mouseup)="stopDragging($event, false)"
        (mouseleave)="stopDragging($event, false)" (mousemove)="moveEvent($event, recommendedEvents)">



        <div *ngFor="let event of recommendedevents" class="event-card">
            <div *ngIf="event.cover_url" class="event-card-cover" [style.background-image]="'url('+event.cover_url+')'">
            </div>
            <div *ngIf="!event.cover_url" class="event-card-cover" [style.background-image]="'url(../../../assets/placeholder.png)'">
            </div>
            <div>
                <div *ngIf="event.cover_url" class="event-card-cover-content" [style.background-image]="'url('+event.cover_url+')'">
                </div>
                <div *ngIf="!event.cover_url" class="event-card-cover-content" [style.background-image]="'url(../../../assets/placeholder.png)'">
                </div>
                <div class="event-card-content">
                    <div style="display:inline-flex;gap: 5px;" class=" OneLine">
                        <div class="Quicksand-medium paragraphe" style="flex: none"> {{event.type}} : </div>
                        <div class="Quicksand-bold smallText OneLine" style="cursor: pointer;flex: none;max-width: 230px;">{{event.title}}</div>
                    </div>
                    <div>
                        <div *ngIf="event.status=='launched'" style="display: inline-flex;gap: 5px;">
                            <div class="Quicksand-regular paragraphe" style="color: #8F9BB3;">Start date : </div>
                            <div class="Quicksand-bold paragraphe" style="color:#8FA7E6;">02/11/2022</div>
                        </div>
                        <div *ngIf="event.status=='live'">
                            <div class="Quicksand-regular paragraphe" style="color: #8F9BB3;">Finished in 6 days
                            </div>
                        </div>
                        <div *ngIf="event.status=='finished'">
                            <div class="Quicksand-regular paragraphe" style="color: #8F9BB3;">Finished</div>
                        </div>
                    </div>
                    <div *ngIf="event.pass" class="paragraphe Quicksand-light" style="color: #FAFAFA;"> US$49.00 ~ US$149.00
                    </div>
                    <div *ngIf="!event.pass" class="paragraphe Quicksand-light" style="color: #FAFAFA;"> Free</div>
                    <div style="overflow: hidden;max-width: 100% !important;display: flex;justify-content: space-between;">
                        <div style="display: flex;
                        justify-content: flex-start!important;
                        width: max-content;
                        overflow: hidden;
                        gap: 5px;">
                            <div *ngFor="let tag of event.tags; let i= index" style="flex: none;">
                                <div *ngIf="i<4" class="tag tagsText Quicksand-light" style="height: 25px;align-self: center;">
                                    <div>{{tag}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="buttons">
                            <div class="icon-favori"></div>
                            <div class="icon-share"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <div *ngIf="!recommendedevents ||( recommendedevents && recommendedevents.length==0)" style="width: 100% !important;    display: flex;
    align-items: center;
    justify-content: center;">
        <img src="../../../assets/noData.png" />

    </div>
</div> -->
<!---------Upcoming----------->
<!-- <div class="upcoming-events-part">
    <div style="display: inline-flex;">
        <div class="padding-div"></div>
        <div style="display: inline-flex;justify-content:space-between;width:100%">
            <div class="titles" style="display:inline-flex;gap:20px">
                <div class="highlighted-title subTitle Quicksand-bold" style="width: 100%;">Upcoming Events</div>
            </div>
            <div class="show-all smallText Quicksand-semibold"><i class="fa fa-pencil" style="color: white;cursor: pointer;" (click)="updateUpcomingEvents()"></i> </div>
        </div>
        <div class="padding-div"></div>
    </div>
    <div *ngIf="  upcomingevents && upcomingevents.length>0 " class="upcoming-events-container">

        <div class="cover" style="width: 100% !important;height: 60vh;">

            <swiper [config]="config" class="slider">
                <div class="swiper-wrapper" (mouseenter)="showArrows(true)" (mouseleave)="showArrows(true)">
                    <div class="swiper-slide" *ngFor="let event of upcomingevents;let i=index" [ngStyle]="{'background-image': event.cover_url ? 'url('+event.cover_url+')':'grey'} ">
                        <div class="upcoming-events-content">
                            <div class="upcoming-events-paraghraphe">
                                <div class="event-title bigTitle Quicksand-semibold">{{event.title}}
                                </div>
                                <div class="event-type paragraphe Quicksand-semibold ">{{event.type}}</div>
                                <div class="event-short-desc paragraphe Quicksand-regular">{{event.short_summary}}
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
                <div id="swiper-button-next" class="swiper-button-next"></div>
                <div id="swiper-button-prev" class="swiper-button-prev"></div>
            </swiper>


        </div>
    </div>
    <div *ngIf="!upcomingevents ||( upcomingevents && upcomingevents.length==0)" class="upcoming-events-container" style="width: 100% !important;    display: flex;
    align-items: center;
    justify-content: center;">
        <img src="../../../assets/noData.png" />


    </div>
</div> -->
<!---------Playlist----------->
<!-- <div class="player-container">
    <div style="display: inline-flex;    width: 100%;">
        <div class="padding-div"></div>
        <div style="display: inline-flex;justify-content:space-between;width:100%">
            <div class="titles" style="display:inline-flex;gap:20px">
                <div class="highlighted-title subTitle Quicksand-bold" style="width: 100%;">
                    Our Channel Selection
                </div>
            </div>
            <div class="show-all smallText Quicksand-semibold"><i class="fa fa-pencil" style="color: white;cursor: pointer;" (click)="updateSelectedChannel()"></i> </div>
        </div>
        <div class="padding-div"></div>
    </div>
    <div class="selected-channel-container">
        <div *ngIf="selectedchannel && selectedchannel._id" class="cover" style="width: 100% !important;height: 60vh;">
            <div class="cover-diapo-channel" [ngStyle]="{'background-image': selectedchannel.cover_url ? 'url('+selectedchannel.cover_url+')':'url(../../../assets/placeholder.png)'} ">
                <div class="cover-content" style="justify-content: space-between; ">
                    <div class="upcoming-events-content">
                        <div class="upcoming-events-paraghraphe">
                            <div class="event-title bigTitle Quicksand-semibold ">{{selectedchannel.title}}
                            </div>
                            <div class="event-type paragraphe Quicksand-semibold ">Playlist</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectedchannel.rooms" id="channel-container" class="channel-container" #selectedChannel (mousedown)="startDragging($event, false, selectedChannel)" (mouseup)="stopDragging($event, false)" (mouseleave)="stopDragging($event, false)" (mousemove)="moveEvent($event, selectedChannel)">
                    <div *ngFor="let session of selectedchannel.rooms.sessions;let i=index" [id]="'videoCard'+i" class="video-wrapper">
                        <video #video *ngIf="session && session.cover_url && session.cover_url.split('.')[ session.cover_url.split('.').length-1]=='mp4'" [id]="session._id" [muted]="'muted'">
                            <source [src]="session.cover_url" type="video/mp4">
                        </video>
                        <div [id]="'image'+session._id" *ngIf="session && session.cover_url && session.cover_url.split('.')[ session.cover_url.split('.').length-1]!='mp4'" [ngStyle]="{'background-image': session.cover_url ? 'url('+session.cover_url+')':'url(../../../assets/placeholder.png)'} "
                            class="session-cover">
                        </div>
                        <div class="content-video">
                            <div class="header-btn">
                                <div class=" duration tagsText Quicksand-regular">
                                    {{session.duration}}
                                </div>
                            </div>
                            <div style="display: flex;justify-content: center;height: 100%;" (click)="openVideo(i)">
                                <div [id]="'whitePlay'+session._id" class="play-icon">
                                </div>
                                <div [id]="'redPlay'+session._id" class="play-icon-hover">
                                </div>
                            </div>
                            <div>
                                <div class="session-video-info">
                                    <div class="smallText Quicksand-bold" style="text-align: start;margin-bottom: 5px">
                                        {{session.title}}
                                    </div>
                                    <div *ngIf="session && session.speakers" class="session-speakers" style="align-items: flex-start;" [style.left.px]=" -15*(session.speakers.length-1) ">
                                        <div *ngFor="let speaker of session.speakers;let i = index" style="display: inline-flex;">
                                            <div *ngIf="i<=2" [style.background-image]="'url('+speaker.photo+')'" class="speaker" [style.left.px]=" 15*(session.speakers.length-1-i) ">
                                            </div>
                                            <div *ngIf="session.speakers.length>3 && i==2" [style.background-image]="'url('+speaker.photo+')'" class="last-speaker" [style.left.px]=" 15*(session.speakers.length-1-i) ">
                                            </div>
                                            <div *ngIf="session.speakers.length>3 && i==2" class="tagsText" style="
                                            font-family:Quicksand-regular ;
                                            align-self: flex-end;   
                                            position: relative;
                                            left: -25px;
                                            top: -6px;">
                                                +{{session.speakers.length-i}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="session-video-footer">
                                    <div class="session-video-tags">
                                        <div *ngFor="let tag of session.tags; let i= index">
                                            <div *ngIf="i<4" class="tag tagsText Quicksand-light" style="height: 25px;align-self: center;">
                                                <div>{{tag}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="showMore-btn" (click)="showMore()">
                                        <div class="tagsText showMore-text Quicksand-bold">Show More
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div *ngIf="!selectedchannel ||( selectedchannel && !selectedchannel._id)" style="width: 100% !important;    display: flex;
        align-items: center;
        justify-content: center;">
            <img src="../../../assets/noData.png" />

        </div>
    </div>
</div> -->
<!---------Speakers----------->
<!-- <div class="key-opinion-part" style="margin: 110px 0;">
    <div style="display: inline-flex;">
        <div class="padding-div"></div>
        <div style="display: inline-flex;justify-content:space-between;width:100%">
            <div class="upcomingEvent-title Quicksand-bold">
                <div class="highlighted-title subTitle Quicksand-bold" style="width:230px;">Key Opinion Leaders</div>
            </div>
            <div class="show-all smallText Quicksand-semibold"><i class="fa fa-pencil" style="color: white;cursor: pointer;" (click)="updateSpeakers()"></i> </div>
        </div>
        <div class="padding-div"></div>
    </div>
    <div class="key-opinion-container">
        <div class="padding-div"></div>
        <div *ngIf=" selectedSpeakers && selectedSpeakers.length>0" class="speakers-photos">

            <div *ngFor="let speaker of selectedSpeakers ; let i=index" class="speaker-photo" style="    background-position: center;" [ngStyle]="{'background-image': speaker.additional_data && speaker.additional_data.photo ? 'url('+speaker?.additional_data?.photo+')':'url(../../../assets/placeholder.png)'} "
                [style.left.px]="-55*(i) ">
                <div class="speaker-content" (mouseenter)="showLinkedIn(true,i)" (mouseleave)="showLinkedIn(false,i)">
                    <div style="display: inline-flex; justify-content: space-between;padding:0 20px 40px 20px">
                        <div style="display: flex;flex-direction: column;justify-content: center;">
                            <div class="smallText Quicksand-bold"> {{speaker.firstname}} {{speaker.lastname}}</div>
                            <div class="paragraphe Quicksand-regular"> {{speaker?.additional_data?.position}}
                            </div>
                        </div>
                        <div *ngIf="speaker.additional_data && speaker.additional_data.social_media && speaker.additional_data.social_media.linkedin" [id]="i" style="visibility: hidden;">
                            <a [href]="speaker.additional_data.social_media.linkedin" style="text-decoration: none; color: white;" target="_blank">
                                <div class="icon-in">
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <div *ngIf="!selectedSpeakers ||( selectedSpeakers && selectedSpeakers.length==0)" style="width: 100% !important;    display: flex;
        align-items: center;
        justify-content: center;">
            <img src="../../../assets/noData.png" />

        </div>
        <div id="paragraphe" class="speakers-paragraphe">
            <div class="bigTitle speakers-title Quicksand-bold">
                MEET THE INCREDIBLE SPEAKERS
            </div>
            <div class="paragraphe speakers-text Quicksand-regular">
                Le cartel de Cali reprend le pouvoir en Colombie. Les successeurs d’Escobar passent à l’action et déclarent la guerre.
            </div>
            <div class="speaker-showMore Quicksand-bold smallText">SHOW MORE</div>
        </div>
        <div class="padding-div"></div>
    </div>
</div> -->
<!---------Testimonials----------->
<!-- <div class="testimonials-part">
    <div style="display: inline-flex;">
        <div class="padding-div"></div>
        <div style="display: inline-flex;justify-content:space-between;width:100%">
            <div class="upcomingEvent-title">
                <div class="highlighted-title subTitle Quicksand-bold" style="width:130px;">Testimonials</div>
            </div>
            <div class="show-all smallText Quicksand-semibold"><i class="fa fa-pencil" style="color: white;cursor: pointer;" (click)="updateTestimonials()"></i> </div>

        </div>

        <div class="padding-div"></div>
    </div>

    <div style="display: inline-flex;">
        <div class="padding-div"></div>
        <div *ngIf="testimonials && testimonials.length>0" class="testimonials-container">
            <div *ngFor="let testimonial of testimonials ; let i = index" [id]="testimonial.speaker._id" [ngClass]="selectedTestimonial == testimonial.speaker._id ? 'testimonial-open' : 'testimonial-closed'" [style.zIndex]="(testimonials.length - i)*1000" [ngStyle]="{'background-color':(i==1) ? '#1E2946':(i==2) ?'#1B253D':(i==3) ?'#171F33' : '#243050' , 'border-radius':(i==0) ? '10px 0 0 10px' :'none'} ">
                <div *ngIf="selectedTestimonial == testimonial.speaker._id" class="testimonial-details">
                    <div class="testimonial-header">
                        <div style="display: inline-flex;gap: 40px;">
                            <div *ngIf="testimonial.speaker && testimonial.speaker.additional_data && testimonial.speaker.additional_data.photo" class="testimonial-img" [style.background-image]="'url('+testimonial.speaker?.additional_data?.photo+')'" style="background-position: center;"><img style="visibility: hidden;" [src]="testimonial.speaker?.additional_data?.photo"></div>
                            <div *ngIf="testimonial.speaker && (!testimonial.speaker.additional_data  || !testimonial.speaker.additional_data.photo)" class="testimonial-img" [style.background-image]="'url(../../../assets/placeholder.png)'" style="background-position: center;"><img style="visibility: hidden;" [src]="'../../../assets/placeholder.png'"></div>

                            <div class="testimonial-speaker">
                                <div class="smallText Quicksand-bold">{{testimonial.speaker?.firstname| titlecase}} {{testimonial.speaker?.lastname| titlecase}}
                                </div>
                                <div class="paragraphe Quicksand-regular">
                                    {{testimonial.speaker?.additional_data?.position | titlecase}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="display:flex;flex-direction:column;">
                        <div class="icon-testimonial"></div>
                        <div class="paragraphe testimonial-text Quicksand-regular" style="position: absolute; z-index: -10; margin-bottom: 10px;  visibility: hidden;margin-top: 30px;font-family: Quicksand-regular;" [id]="'testimonial'+i">
                            {{testimonial.testimonial | titlecase}} </div>
                        <div class="line-limit paragraphe  testimonial-text Quicksand-regular" [id]="i+'testimonial'">
                            {{testimonial.testimonial | titlecase}} </div>
                    </div> 
                </div>
                <div *ngIf="selectedTestimonial != testimonial.speaker._id" class="testimonial-details-reduite" [ngStyle]="{'border-radius':i==0 ? '10px 0 0 10px' :'none'}" (click)="openTestimonial(testimonial.speaker._id,(testimonials.length - i)*1000)">
                    <div *ngIf="testimonial.speaker && testimonial.speaker.additional_data && testimonial.speaker.additional_data.photo" class="testimonial-img" [style.background-image]="'url('+testimonial.speaker?.additional_data?.photo+')'" style="align-self: center;background-position: center;">
                        <img style="visibility: hidden;" [src]="testimonial.speaker?.additional_data?.photo">
                    </div>

                    <div *ngIf="testimonial.speaker && (!testimonial.speaker.additional_data  || !testimonial.speaker.additional_data.photo)" class="testimonial-img" [style.background-image]="'url(../../../assets/placeholder.png)'" style="align-self: center; background-position: center;">
                        <img style="visibility: hidden;" [src]="'../../../assets/placeholder.png'">
                    </div>





                    <div class="smallText one-line-limit Quicksand-bold" style="transform: rotate(90deg);">
                        {{testimonial.speaker?.firstname| titlecase}} {{testimonial.speaker?.lastname| titlecase}}
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="testimonials && testimonials.length==0" style="width: 100% !important;    display: flex;
            align-items: center;
            justify-content: center;">
            <img src="../../../assets/noData.png" />

        </div>
        <div class="padding-div"></div>
    </div>

</div> -->