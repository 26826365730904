import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { DashboardEventService } from './dashboard-event.service';
import * as lodash from 'lodash';
import { PortalService } from '../portal/portal.service';

@Component({
  selector: 'app-dashboard-event',
  templateUrl: './dashboard-event.component.html',
  styleUrls: ['./dashboard-event.component.css']
})
export class DashboardEventComponent implements OnInit {
  eventID;
  eventDetails = "";
  constructor(private router: Router, private route: ActivatedRoute,private dashboardservice : DashboardEventService) {     
  }
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.eventID = params['eventid'];
    });
    this.dashboardservice.onChangedDetailsEventById.subscribe((response : any) => {
      
      if (response) {
        if (response.status == 'error') {
          Swal.fire({
            icon: response.status,
            title: response.message,
            background: '#1C1C1C ',
            backdrop: `
                blur(10px)
              `
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigateByUrl("/portal/hybridevent/" + this.eventID)
            }
          })
        }
        else if (response.status == 'success')
        {
          this.eventDetails = response.message
        }
      }
    })
  }
  backtoportal()
  {
    this.router.navigateByUrl("/portal/hybridevent/" + this.eventID)
  }
}