<div class="exibitors">
    <!-- <div class="titleExhibitor subTitle Quicksand-bold">Exhibitors</div> -->
    <div class="exhibitor" *ngFor="let exhibitor of exhibitors;let i=index" [id]="'booth'+exhibitor._id" [style.background-image]="'url('+exhibitor.card_cover+')'" [ngClass]="exhibitor.card_size == 'large' ? 'large' : 'default'" (mouseenter)=" showMoreDetails(true,exhibitor._id)"
        (mouseleave)=" showMoreDetails(false,exhibitor._id)">
        <div class="exhibitor-details" [id]="exhibitor._id">
            <div class="exhibitor-logo sub-title">
                <img [src]="exhibitor.logo" class="img_logo">
                <div *ngIf="show && exhibitor._id == showExhibitorName" class="exibitor-name sub-title" [id]="i">
                    {{exhibitor.name | titlecase}}
                </div>
            </div>
            <div *ngIf="show && exhibitor._id == showExhibitorName" class="exhibitor-desc Quicksand-regular sub-title">
                {{exhibitor.description | titlecase}}
            </div>
            <div *ngIf="exhibitor._id != showExhibitorName" class="exhibitor-desc Quicksand-regular line-limit  sub-title">
                {{exhibitor.description | titlecase}}
            </div>
        </div>
    </div>
</div>