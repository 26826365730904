import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { DataService } from 'src/app/services/data.service';
import Swal from 'sweetalert2';
import { AddOrganisationComponent } from '../add-organisation/add-organisation.component';
import { DashboardTestService } from '../dashboard-test.service';
import { PopupComponent } from '../popup/popup.component';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  events
  organisations
  index = 0
  is_admin = false
  can_add = false
  constructor(private dashboardtestservice: DashboardTestService, public dialog: NbDialogService, private router: Router, public dataService: DataService) { }
  ngOnInit(): void {
    this.dashboardtestservice.onChangedEvents.subscribe((response: any) => {
      this.events = response
    })
    this.dashboardtestservice.onChangedUser.subscribe((response: any) => {
    
      
      if (response  ) {
        if (response.role && response.role.includes("admin")) {
          
          this.is_admin = true
          this.index = -1
        }
        
      }
    })
    this.dashboardtestservice.onChangedOrganisations.subscribe((response: any) => {
      if (response && response.status == "success") {
       
        this.can_add = response.can_add
        this.organisations = response.organisations
      }
    })
   }
  ngAfterViewInit() {
    this.dataService.navbar_height.subscribe(height => {
      if (document.getElementById('nb-sidebar')) {
        if (height == 95) {
          document.getElementById('nb-sidebar').style.paddingTop = '20px';
        }
        else {
          if (height < 95) {
            document.getElementById('nb-sidebar').style.paddingTop = '0px';

          }
        }
      }

    })
  }
  openPopup(popupname, eventid, event) {
    const dialogRef = this.dialog.open(PopupComponent, {
      context: {
        data: {
          name: popupname,
          eventid: eventid,
          event: event,
        },
      },
      hasBackdrop: false
    });
    dialogRef.onClose.subscribe((result) => {
    });
  }
  deleteEvent(eventid, organisationid) {
    Swal.fire({
      title: "Are you sure want to remove this event?",
      text: "The event will be hided but won't be deleted permanently enless you ask for it!",
      icon: "warning",
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: "Yes, hide it!",
      cancelButtonText: "No, keep it",
      denyButtonText: "Permanently delete it!"
    }).then((result) => {
      if (result.value) {
        this.dashboardtestservice.deleteEventById(eventid, organisationid).then((res: any) => {
          Swal.fire(res.message, "", res.status);
        });
        // Swal.fire("Deleted!", "The event has been deleted.", "success");
      } else if (result.dismiss == Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "", "error");
      } else if (result.isDenied) {
        this.permanentlyDeleteEvent(eventid, organisationid);
      }
    });
  }
  permanentlyDeleteEvent(eventid, organisationid) {
    Swal.fire({
      title: "Are you sure want to permanently delete this event?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.dashboardtestservice.permanentlyDeleteEvent(eventid, organisationid).then((res: any) => {
          Swal.fire(res.message, "", res.status);
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "", "error");
      }
    });
  }
  opened_event
  openEvent(event) {
    this.opened_event = event
  }
  AddOrganisation() {
    const dialogRef = this.dialog.open(AddOrganisationComponent, {
      hasBackdrop: false

    });
    dialogRef.onClose.subscribe((result) => {
    });
  }
  navigate(organisationid) {
    this.router.navigateByUrl("/dashboard/organisation/" + organisationid);
  }
}
