<!-- <div *ngIf="verification && !showAccess_Popup && !showAlert_popup"> -->
    <!-- <div *ngIf="data.verifyPopup" class="top-bar"  (click)="back()">
        <img src="../../assets/popups/icons8-left-arrow-50.png" style="width: 25px; cursor: pointer;">
    </div> -->
    <div  *ngIf="!pre_verif" style="display: flex;justify-content: center; height: 100%; padding-top: 5%;">
        <div   class="Signup">
            <div class="header">
                <div  class="Quicksand-bold PopupTitle" style="text-align:center;" >LET’S VERIFY YOUR EMAIL</div>
                <div class="header-cover-verification"> <img  src="../../../assets/popups/success.png"></div>
            </div>
            <form class="form flex-column" [formGroup]="verificationForm" style="display: flex;justify-content: center;">
                <div  class="Quicksand-regular paragraphe" style="padding-bottom: 10px;color: #8F9BB3;text-align: center;">Enter the 6-digit verification code sent to your email :</div>
                <div class="input-container-signup">
                    <input   formControlName="nb1" [ngClass]="{Error : submittedVerification && (verificationformControls.nb1.errors || wrongCode)}"  type="text" class="code-input-signup input-form"  maxlength = "1"/>
                    <input   formControlName="nb2" [ngClass]="{Error : submittedVerification && (verificationformControls.nb2.errors || wrongCode)}" type="text" class="code-input-signup input-form" maxlength = "1"/>
                    <input   formControlName="nb3" [ngClass]="{Error : submittedVerification && (verificationformControls.nb3.errors || wrongCode)}" type="text" class="code-input-signup input-form" maxlength = "1"/>
                    <input   formControlName="nb4" [ngClass]="{Error : submittedVerification && (verificationformControls.nb4.errors || wrongCode)}" type="text" class="code-input-signup input-form" maxlength = "1"/>
                    <input   formControlName="nb5" [ngClass]="{Error : submittedVerification && (verificationformControls.nb5.errors || wrongCode)}" type="text" class="code-input-signup input-form" maxlength = "1"/>
                    <input   formControlName="nb6" [ngClass]="{Error : submittedVerification && (verificationformControls.nb6.errors || wrongCode)}"type="text" class="code-input-signup input-form" maxlength = "1"/>
                </div>
            </form>
            <div style="padding-bottom: 20px; text-align: center;"  [ngClass]="resendVerifyMessage[0] ? 'SuccessMessage':''">
                {{resendVerifyMessage[1]}}
            </div>
            <div style="padding-bottom: 20px; text-align: center;"  [ngClass]="verifyMessage[0] ? 'ErrorMessage2'   :''">
                {{verifyMessage[1]}}
            </div>
            <div style="width: 100%;display: inline-flex;flex-direction: column;justify-content: center;">
                <div class="next-btn-container" style="align-self: center;">
                    <button  class="action-btn subTitle Quicksand-bold" style="color :#141A2A;text-align:center"  (click)="verifyCode()">Next</button> 
                </div>
            </div>
            <div style="display: inline-flex; justify-content: space-between; width: 100%;">
                <div  style="color: #8F9BB3; text-align: center;" class="paragraphe Quicksand-regular">Don’t receive the code ? 
                    <span  *ngIf="resend" class="paragraphe Quicksand-bold"  style="border-bottom: 1px solid;cursor:pointer;color: #8F9BB3;font-weight: bold;" (click)="resendverification()" >Resend</span>
                    <span *ngIf="!resend" class="paragraphe Quicksand-bold"  style="border-bottom: 1px solid;cursor:pointer;color: #505050;font-weight: bold;" >Resend</span>
                </div>
                <div *ngIf="(!data.invited && showSkip  && !data.confirmFromtop) || data.fromNavBar" class="skipBtn paragraphe Quicksand-bold">
                    <span (click)="skip()" >skip <span style="font-weight: bold; text-align: center;"></span> </span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="pre_verif"  style="display: flex;justify-content: center; height: 100%; padding-top: 5%;">
        <div class="verify-process"> 
            <div class="image">
                <img src="../../../assets/popups/success.png" /> 
            </div>
            <div class="sub-title" style="margin-bottom: 20px;text-align: center;color: #A3A3A3;">Before continuing you have to verify your email</div>
            <div class="next-btn-container" style="width: 50%;align-self: center;">
                <button   class="action-btn subTitle Quicksand-bold" style="color :#141A2A;text-align:center"  (click)="showVerificationPopup()">VERIFY NOW</button>
            </div>
        </div>
        
    </div>
<!-- </div> -->

<!-- <app-alert-popup *ngIf="showAlert_popup && !verification && !showAccess_Popup"  [data]="dataAlert"></app-alert-popup>
<app-access-system *ngIf="showAccess_Popup && !verification && !showAlert_popup" [data]="dataAccess"></app-access-system> -->

<!-- <app-authentification-system *ngIf="login" [data]="authData" ></app-authentification-system> -->
