import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { PortalService } from './portal/portal.service';
import { AuthentificationSystemService } from './authentification-system/authentification-system.service';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor( private cookieService: CookieService,
    public portalService: PortalService,
    public authService: AuthentificationSystemService,
    public router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const page = next.data.page;
      // if (this.cookieService.get("token")) {
      if (this.cookieService.get('token')) {
       
         return true;
      }
      else {
       
        return false;
      }
    

  }
}
