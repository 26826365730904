import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import * as lodash from 'lodash';
import { StateChange } from 'ng-lazyload-image';
import { NgxSpinnerService } from 'ngx-spinner';
import { VideoPlayerComponent } from 'src/app/video-player/video-player.component';
import { Location } from '@angular/common';

import { ExploreEventService } from '../explore-event.service';
import { ExploreService } from 'src/app/dashboard-test/explore.service';
import { EditExplorePopupComponent } from 'src/app/dashboard-test/edit-explore-popup/edit-explore-popup.component';
import { config } from 'src/config/config';

@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.css']
})
export class PlaylistComponent implements OnInit {
  @ViewChild('selectedChannel') selectedChannel: ElementRef;
  @ViewChild('tags') tags: ElementRef;

  @Output() activeBloc = new EventEmitter();
  @Input() is_admin
  @ViewChildren('video') video: any;
  playDiapo: boolean = true;
  mouseDown = false;
  startX: any;
  scrollLeft: any;
  events: any;
  selectedVideo: any;
  channel = null
  sessions: any = []
  loading: boolean = true
  imageStates: string[] = [];
  showPlaylistBtn: boolean;
  defaultImage = "../../../assets/placeholder.png";
  mouseDownTag = false;
  startXTag: any;
  scrollLeftTag: any;
  dragging: boolean = false;
  draggingSessions: boolean = false;
  sections_title_icon=config.sections_title_icon
  selectedSpeaker: any;
  selectedSession: any;
  speaker: any;
  show: boolean;
  hoveredSession
  constructor(public exploreService: ExploreEventService, public router: Router, private spinner: NgxSpinnerService,
    public exploredashboardService: ExploreService,
    private cd: ChangeDetectorRef, public dialog: NbDialogService) { }

  ngOnInit(): void {
     this.exploreService.onChangedPlaylist.subscribe((res: any) => {
      if (res) {
        this.channel = {
          _id: res._id,
          title: res.title,
          cover_url: res.cover_url,
          sessions: res.rooms[0].sessions,
          type : res.type
        }
        this.loading = false
      }
      // }
    })
    this.activeBloc.emit("speakers")

  }
  ngAfterViewInit() {
    this.spinner.show("playlistSpinner").then((res) => {
    });
  }
  onStateChange(state: StateChange, i) {

    this.imageStates.push(state.reason);
    if (state.reason == "finally") {
      this.spinner.hide("playlistSpinner").then((res) => {
        this.showPlaylistBtn = true
      });
    }
    this.cd.detectChanges();
  }
  startDragging(e, flag, el) {
    if (!this.dragging) {
      this.mouseDown = true;
      this.startX = e.pageX - el.offsetLeft;
      this.scrollLeft = el.scrollLeft;
    }
  }
  stopDragging(e, flag) {
    this.mouseDown = false;
  }
  moveEvent(e, el) {
    e.preventDefault();
    this.draggingSessions = true

    if (!this.mouseDown) {
      this.draggingSessions = false
      return;
    }
    const x = e.pageX - el.offsetLeft;
    const scroll = x - this.startX;
    el.scrollLeft = this.scrollLeft - scroll;
  }
  // playPause(id) {

  //   this.playDiapo = !this.playDiapo
  //   if (!this.playDiapo) {
  //     document.getElementById(id).style.animationPlayState = "paused"
  //   }
  //   else {
  //     document.getElementById(id).style.animationPlayState = "running"
  //     document.querySelector<HTMLElement>('.slider').style.animationPlayState = "running";


  //   }
  // }
  playVideo(id, play) {
    let div = lodash.find(this.video.toArray(), { 'nativeElement': document.getElementById(id) });

    if (div) {
      if (play) {
        this.selectedVideo = id
        div.nativeElement.play()
        document.getElementById(id).classList.add("hover")
        document.getElementById(id).classList.remove("notHover")
        document.getElementById("whitePlay" + id).style.visibility = "hidden"
        document.getElementById("whitePlay" + id).style.position = "absolute !important"
        document.getElementById("redPlay" + id).style.visibility = "visible"
        document.getElementById("redPlay" + id).style.position = "relative !important"
      }
      else {
        this.selectedVideo = ""
        div.nativeElement.pause()
        document.getElementById(id).classList.remove("hover")
        document.getElementById(id).classList.add("notHover")
        document.getElementById("whitePlay" + id).style.visibility = "visible"
        document.getElementById("whitePlay" + id).style.position = "relative !important"
        document.getElementById("redPlay" + id).style.visibility = "hidden"
        document.getElementById("redPlay" + id).style.position = "absollute !important"
      }
    }
    else {

      let divImage = document.getElementById("image" + id)
      if (divImage) {
        if (play) {
          this.selectedVideo = id
          divImage.classList.add("hover")
          divImage.classList.remove("notHover")
          document.getElementById("whitePlay" + id).style.visibility = "hidden"
          document.getElementById("whitePlay" + id).style.position = "absolute !important"
          document.getElementById("redPlay" + id).style.visibility = "visible"
          document.getElementById("redPlay" + id).style.position = "relative !important"
        }
        else {
          this.selectedVideo = ""
          divImage.classList.remove("hover")
          divImage.classList.add("notHover")
          document.getElementById("whitePlay" + id).style.visibility = "visible"
          document.getElementById("whitePlay" + id).style.position = "relative !important"
          document.getElementById("redPlay" + id).style.visibility = "hidden"
          document.getElementById("redPlay" + id).style.position = "absollute !important"
        }
      }
    }
  }

  openVideo(i) {
    if(!this.draggingSessions)
    {
      const dialogRef = this.dialog.open(VideoPlayerComponent, {
        context: {
          data: {
            sessions: this.channel.sessions,
            index: i,
            title: this.channel.title
          }
        },
        hasBackdrop: false
      }).onClose.subscribe((result) => { });
    }
  
  }
  showMore(index) {
    this.router.navigateByUrl("/more/channel/" + this.channel._id + "/" + index).then();
  }
  gotToEvent()
  {
    this.router.navigateByUrl('/portal/channelevent/' + this.channel._id)
  }
  /********************Manage explore*****************/
  updateSelectedChannel() {
    this.exploredashboardService.getChannels().then((res: any) => {
      if (res && res.status == "success") {
        // this.events = res.channels
        const dialogRef = this.dialog.open(EditExplorePopupComponent, {
          context:
          {
            data: {
              name: "updateSelectedChannel",
              explore_id: this.exploreService.explore_id,
              events: res.channels,
              selectedchannel: this.channel
            },
          }, hasBackdrop: false
        });
        dialogRef.onClose.subscribe((result) => {
        });
      }
    })
  }
  startDraggingTags(e, flag, el) {    
    document.getElementById("channel-container").style.overflow = "hidden"
    this.mouseDownTag = true;
    this.dragging = true
    this.startXTag = e.pageX - el.offsetLeft;
    this.scrollLeftTag = el.scrollLeft;
  }
  stopDraggingTags(e, flag) {
    this.mouseDownTag = false;
    this.dragging = false
    document.getElementById("channel-container").style.overflow = "unset"

  }
  moveEventTags(e, el) {
    e.preventDefault();
    this.dragging = true
    if (!this.mouseDownTag) {
      this.dragging = false
      return;
    }
    const x = e.pageX - el.offsetLeft;
    const scroll = x - this.startXTag;
    el.scrollLeft = this.scrollLeft - scroll;
  }
  gotoProfile(speaker)
  {
    if(speaker && speaker.linked_user && speaker.linked_user.status=="linked")
    {
      this.router.navigateByUrl("/profile/speaker/" + speaker.linked_user.user_id).then((res)=>{});

    }
  }
  showSpeaker(hoveredSession,speaker, showpopup, sessionId) {
    // this.hoveredSession = hoveredSession
    this.selectedSpeaker = speaker
    this.selectedSession = sessionId
    if (showpopup) {
      this.show = true
      this.speaker = speaker
    }
    else {
      this.show = false
      this.speaker = null
    }
  }
}
