import { Component, Input, OnInit } from '@angular/core';
import { config } from 'src/config/config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
@Input() from
  /*****From config******/
  top_footer_config
  bottom_footer_config  /*********************/
  constructor() { }
  ngOnInit() {
/**********Configuration**************/
this.top_footer_config=config.top_footer
this.bottom_footer_config=config.bottom_footer
  }

}
