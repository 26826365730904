import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { EditExplorePopupComponent } from 'src/app/dashboard-test/edit-explore-popup/edit-explore-popup.component';
import { ExploreService } from 'src/app/dashboard-test/explore.service';
import { config } from 'src/config/config';
import { ExploreEventService } from '../explore-event.service';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {
  @Output() activeBloc = new EventEmitter();
  @Input()is_admin

  testimonials 
  selectedTestimonial;
  defaultImage = "../../../assets/placeholder.png"
  currentZindex: "4000";
  loading: boolean=true;
  sections_title_icon=config.sections_title_icon
  color_config=config.primary_color

  constructor(public exploreService: ExploreEventService,public router: Router,private spinner: NgxSpinnerService,public exploredashboardService:ExploreService,public dialog:NbDialogService) { }

  ngOnInit(): void {
 
    this.spinner.show()
    this.exploreService.onChangedTestimonials.subscribe((res)=>
    {
       
      this.testimonials = res
      if (this.testimonials && this.testimonials.length>0)
      {
        this.selectedTestimonial = this.testimonials[0]._id;
      }

      setTimeout(() => {
        this.loading = false
        this.spinner.hide()

      }, 2000);

    }) 
    this.activeBloc.emit("footer")

  }
  ngAfterViewInit() {
    this.spinner.show()
  }
  openTestimonial(id, zIndex) {
      
    document.getElementById(this.selectedTestimonial).style.zIndex = this.currentZindex
    document.getElementById(id).style.zIndex = "4000 !important"
    document.getElementById(id).style.borderRadius = "none !important"
    this.selectedTestimonial = id
    this.currentZindex = zIndex

  }
  

  /***********************Manage Explore***************/
  updateTestimonials() {



    this.exploredashboardService.getSpeakers().then((res: any) => {
      if (res && res.status == "success") {
        // this.events = res.channels
        const dialogRef = this.dialog.open(EditExplorePopupComponent, {
          context:
          {
            data: {
              name: "updateTestimonials",
              explore_id: this.exploreService.explore_id,
              testimonials: this.testimonials,
              speakers: res.speakers,
            },
          }, hasBackdrop: false
        });
        dialogRef.onClose.subscribe((result) => {
        });
      }
    })



  }
}
