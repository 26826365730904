<div style="display:flex;justify-content: center;height: 90vh;">
    <div class="content">
        <div style="background:url(../../../assets/enveloppe.png) ; background-repeat: no-repeat;background-size: cover;width: 95px;
    height: 84px;
    margin-bottom: 10vh;
    align-self: center;">
        </div>
        <div class="Quicksand-regular paragraphe" style="margin-bottom: 20px;align-self: center;"> would you like to
            continue with this email </div>
        <div class="input-email">
            <div class="input-text paragraphe Quicksand-light" style="flex: 2;padding: 10px;">
                {{email}}
            </div>
            <div class="input-btn" (click)="joinEvent()">
                <div
                    style="background: url(../../../assets/flesh.png);width: 20px;height: 14px;background-repeat: no-repeat;background-size: cover;align-self: center;cursor: pointer;">
                </div>
            </div>
        </div>
        <div class="other-email-text Quicksand-regular  paragraphe" (click)="changeEmail()"> Change </div>
    </div>
</div>