import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { StateChange } from 'ng-lazyload-image';
import { NgxSpinnerService } from 'ngx-spinner';
import { EditExplorePopupComponent } from 'src/app/dashboard-test/edit-explore-popup/edit-explore-popup.component';
import { ExploreService } from 'src/app/dashboard-test/explore.service';
import { config } from 'src/config/config';
import { ExploreEventService } from '../explore-event.service';

@Component({
  selector: 'app-recommended-events',
  templateUrl: './recommended-events.component.html',
  styleUrls: ['./recommended-events.component.css']
})
export class RecommendedEventsComponent implements OnInit {
  @Input() is_admin
  @Output() activeBloc = new EventEmitter();
  imageStates: string[] = [];
  mouseDown = false;
  startX: any;
  scrollLeft: any;
  events: any;
  loading: boolean = true
  dragging: boolean = false;
  defaultImage = "../../../assets/placeholder.png"
  mouseDownTag = false;
  startXTag: any;
  scrollLeftTag: any;
  sameday = false
  nbDays
  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  sections_title_icon = config.sections_title_icon
  constructor(public exploreService: ExploreEventService, public router: Router, private spinnerRecommendedEvent: NgxSpinnerService,
    private exploredashboardService: ExploreService, public dialog: NbDialogService,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
 
    this.exploreService.onChangedRecommendedEvents.subscribe((res) => {
      if (res) {
        this.events = res
        this.loading = false
 
         // var Time = this.events[0].start_date.getTime() - this.events[0].end_date.getTime(); 
        // var Days = Time / (1000 * 3600 * 24);
   

      }
    })

    this.activeBloc.emit("upcommingEvent")

  }
  ngAfterViewInit() {
    this.spinnerRecommendedEvent.show("recommendedSpinner").then((res) => {
    });
  }
  // getNBDays(event) {
  //   var Time = event.start_date.getTime() - event.end_date.getTime();
  //   var Days = Time / (1000 * 3600 * 24);
  //   return Days

  // }
  onStateChange(state: StateChange) {
    this.imageStates.push(state.reason);
    if (state.reason == "finally") {
      this.spinnerRecommendedEvent.hide("recommendedSpinner").then((res) => {
      });
    }
    this.cd.detectChanges();
  }
  startDragging(e, flag, el) {
    this.mouseDown = true;
    this.startX = e.pageX - el.offsetLeft;
    this.scrollLeft = el.scrollLeft;
  }
  stopDragging(e, flag) {
    this.mouseDown = false;
  }
  moveEvent(e, el) {
    e.preventDefault();
    this.dragging = true
    if (!this.mouseDown) {
      this.dragging = false
      return;
    }
    const x = e.pageX - el.offsetLeft;
    const scroll = x - this.startX;
    el.scrollLeft = this.scrollLeft - scroll;
  }
  startDraggingTags(e, flag, el) {

    let div = document.getElementById(el);
    this.mouseDown = true;
    this.startX = e.pageX - div.offsetLeft;
    this.scrollLeft = div.scrollLeft;
  }
  stopDraggingTags(e, flag) {
    this.mouseDown = false;
  }
  moveEventTags(e, el) {
    let div = document.getElementById(el);
    e.preventDefault();
    this.dragging = true
    if (!this.mouseDown) {
      this.dragging = false
      return;
    }
    const x = e.pageX - div.offsetLeft;
    const scroll = x - this.startX;
    div.scrollLeft = this.scrollLeft - scroll;
  }
  gotoportal(id, type) {
    if (!this.dragging) {
      if (type == "channel") {
        this.router.navigateByUrl('/portal/channelevent/' + id)
      }
      else if (type == "session") {
        this.router.navigateByUrl('/portal/sessionevent/' + id)
      }
      else { this.router.navigateByUrl('/portal/hybridevent/' + id) }
    }
  }

  /**********************************Manage Explore **********************************/

  updateRecommendedEvents() {
    this.exploredashboardService.getLiveAndFinishedEvents().then((res: any) => {
      if (res && res.status == "success") {
        // this.events = res.events
        const dialogRef = this.dialog.open(EditExplorePopupComponent, {
          context:
          {
            data: {
              name: "updateRecommendedEvents",
              explore_id: this.exploreService.explore_id,
              events: res.events,
              recommendedevents: this.events
            },
          }, hasBackdrop: false
        });
        dialogRef.onClose.subscribe((result) => {
        });
      }
    })
  }

  convertDate(startdate, enddate, utcoffset) {

    if (!startdate && !enddate) {

    } else {
      let starttime = this.convertTimeToAM_PM(startdate.split('T')[1].slice(0, 5));
      let endtime = this.convertTimeToAM_PM(enddate.split('T')[1].slice(0, 5));

      startdate = startdate.split('T')[0].split("-");
      enddate = enddate.split('T')[0].split("-");
      let newdate = ["", "", ""];
      newdate[0] = this.months[startdate[1] - 1].slice(0, 3) + " " + startdate[2].slice(0, 2) + " " + starttime
      if (startdate[2].slice(0, 2) == enddate[2].slice(0, 2)) {
        this.sameday = true
        newdate[1] = this.months[enddate[1] - 1].slice(0, 3) + " " + enddate[2].slice(0, 2) + " " + endtime
      }
      else {
        this.sameday = false
        newdate[1] = this.months[enddate[1] - 1].slice(0, 3) + " " + enddate[2].slice(0, 2) + " " + endtime
      }
      newdate[2] = this.convertUtCoffset(utcoffset)
      return newdate;
    }
  }
  convertTimeToAM_PM(time) {
    if (time) {
      let hour = time.split(':')[0]
      if (hour == '00') {
        return ("12:" + time.split(':')[1] + "am")
      }
      else if (Number(hour) == 12) {
        return ("12:" + time.split(':')[1] + "pm")
      }
      else if (Number(hour) > 12) {
        return (Number(hour - 12) + ":" + time.split(':')[1] + "pm")
      }
      else if (hour < 12) {
        if (hour < 10) {
          return (hour[1] + ":" + time.split(':')[1] + "am")
        }
        else {
          return (time + "am")
        }
      }
    }
  }
  convertUtCoffset(utcoffset) {
    let convertedUTC = ["", "", "+"]
    if (utcoffset) {
      if (utcoffset.includes('-')) {
        convertedUTC[2] = ''
      }
      let utc = utcoffset.split('.')
      convertedUTC[0] = utc[0]
      if (!utc[1]) {
        convertedUTC[1] = "00"
      }
      else if (utc[1].length == 1 && utc[1] != '5') {
        convertedUTC[1] = utc[1] + "0"
      }
      else if (utc[1].length == 1 && utc[1] == '5') {
        convertedUTC[1] = "30"
      }
      else if (utc[1].length == 2) {
        convertedUTC[1] = utc[1]
      }
      if (!utc[1]) {
        return convertedUTC[2] + convertedUTC[0];
      }
      else {
        return convertedUTC[2] + convertedUTC[0] + ":" + convertedUTC[1]
      }
    }
  }
  /******************* Convert the format of the date (used in event details card)  ***********************/
  splitDate(startdate, enddate) {
    if (!startdate && !enddate) {
    } else {
      startdate = startdate.split("-");
      enddate = enddate.split("-");
      let newdate: string;
      if (startdate[2].slice(0, 2) == enddate[2].slice(0, 2)) {
        newdate = startdate[2].slice(0, 2) + "/" + startdate[1] + "/" + startdate[0];
      } else {
        newdate = startdate[2].slice(0, 2) + "/" + startdate[1] + "-" + enddate[2].slice(0, 2) + "/" + enddate[1];
      }
      return newdate;
    }
  }
}
