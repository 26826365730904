<<<<<<< HEAD
=======

>>>>>>> 878eb53b099bee267a597a3b2a5ae82a13146c04
<div class="access-popup">
    <div *ngIf="ticket && tickets && tickets.length>0" class="popup-code-container">
        <div class="header">
            <div class="title popup-title"> LET’S GET YOUR PASS </div>
            <div class="paragraphe Quicksand-regular" style="text-align: center">Already have an access ?&nbsp;
                <span (click)="Login()" style="text-decoration: underline;">Log in </span>
            </div>
            <div class="event-info">
                <div style="width: 35%;min-width: 175px;">
                    <div *ngIf="event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]!='mp4'"
                        class="ar-16-9 " [style.background-image]="'url('+event.cover_url+')'"></div>
                    <div
                        *ngIf="event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]=='mp4'">
                        <video autoplay="autoplay" loop muted style="width: 100%;">
                            <source [src]="event.cover_url" type="video/mp4;">
                        </video>
                    </div>
                </div>

                <div class="event-details">
                    <div class="subTitle Quicksand-semibold line-limit"
                        style="text-align: left;margin-bottom: 10px;line-height: 25px;">{{event.title}}</div>
                    <div class="paragraphe Quicksand-regular" style="text-align: left;color: #FFFFFF">
                        {{event.start_date}}</div>
                </div>
            </div>
        </div>
        <app-tickets [tickets]="tickets" [status]="data.status" (authPopup)="onchangePopup($event)"
            (accessPopup)="changePopupAccess($event)"></app-tickets>
        <div class="Quicksand-regular paragraphe" style="color: #8F9BB3;text-align: center;">You have a
            &nbsp; <span (click)="showvoucherPopup()" style="text-decoration: underline;"> voucher </span></div>
    </div>
    <div *ngIf="authCode " class="popup-code-container">
        <div class="header">
            <div class="title popup-title">THIS EVENT HAS AN ACCESS CODE</div>
            <div *ngIf="isShowing" class="paragraphe Quicksand-regular" style="text-align: center">Already have an
                account? &nbsp; <span (click)="Login()" style="text-decoration: underline;">Login</span>
                <!-- <div *ngIf="access.access=='email'">&nbsp; or &nbsp;
                    <span (click)="Email()">Via email</span>
                </div> -->
            </div>
            <div class="event-info">
                <div style="width: 35%;min-width: 175px;">
                    <div *ngIf="event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]!='mp4'"
                        class="ar-16-9 " [style.background-image]="'url('+event.cover_url+')'"></div>
                    <div
                        *ngIf="event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]=='mp4'">
                        <video autoplay="autoplay" loop muted style="width: 100%;">
                            <source [src]="event.cover_url" type="video/mp4;">
                        </video>
                    </div>
                </div>
                <div style="align-self: center; margin-left: 10px;">
                    <div class="subTitle Quicksand-semibold line-limit"
                        style="text-align: left;margin-bottom: 10px;line-height: 25px;">{{event.title}}
                    </div>
                    <div class="paragraphe Quicksand-regular" style="text-align: left;color: #FFFFFF">
                        {{event.start_date}}</div>
                </div>
            </div>
        </div>
        <div>
            <form class="form flex-column" [formGroup]="authForm">
                <div class="Quicksand-regular paragraphe"
                    style="padding-bottom: 10px;color: #8F9BB3;text-align: center;">Enter the 4-digit event password :
                </div>
                <div class="input-container">
                    <input formControlName="nb1" 
                        [ngClass]="{Error :submittedAuth && authFormControls.nb1.errors || wrongCode}" type="text"
                        class="code-input input-form" maxlength="1" />
                    <input formControlName="nb2" 
                        [ngClass]="{Error :submittedAuth && authFormControls.nb2.errors || wrongCode}" type="text"
                        class="code-input input-form" maxlength="1" />
                    <input formControlName="nb3" 
                        [ngClass]="{Error :submittedAuth && authFormControls.nb3.errors || wrongCode}" type="text"
                        class="code-input input-form" maxlength="1" />
                    <input formControlName="nb4"
                        [ngClass]="{Error :submittedAuth && authFormControls.nb4.errors || wrongCode}" type="text"
                        class="code-input input-form" maxlength="1" />
                    <!-- <input  formControlName="nb5" [ngClass]="{Error :submittedAuth && authFormControls.nb5.errors || wrongCode}" type="text" class="code-input input-form" maxlength="1" />
                    <input  formControlName="nb6" [ngClass]="{Error :submittedAuth && authFormControls.nb6.errors || wrongCode}" type="text" class="code-input input-form" maxlength="1" /> -->
                </div>
                <div style="padding-bottom: 20px; text-align: center;" [ngClass]="erreurMsg[0] ? 'ErrorMessage2'   :''">
                    {{erreurMsg[1]}}
                </div>
            </form>
            <div class="next-btn-container">
                <button class="action-btn subTitle Quicksand-bold" style="color :#141A2A;text-align:center"
                    style="width: 100%;" (click)="confirmCodeRestrictedEvent()">NEXT</button>
            </div>
        </div>
    </div>
    <div *ngIf="openCode" class="popup-code-container">
        <div class="header">
            <div class="title popup-title">This event is password protected</div>
            <div *ngIf="isShowing" class="paragraphe Quicksand-regular" style="text-align: center">Already have an account? &nbsp; <span
                    (click)="Login()" style="text-decoration: underline;">Login</span>
                <!-- <div *ngIf="access.access=='email'">&nbsp; or &nbsp;
                    <span *ngIf="access.access=='email'" (click)="Email()">Via email</span>
                </div> -->
            </div>
            <div class="event-info">
                <div style="width: 35%;min-width: 175px;">
                    <div *ngIf="event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]!='mp4'"
                        class="ar-16-9 " [style.background-image]="'url('+event.cover_url+')'"></div>
                    <div
                        *ngIf="event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]=='mp4'">
                        <video autoplay="autoplay" loop muted style="width: 100%;">
                            <source [src]="event.cover_url" type="video/mp4;">
                        </video>
                    </div>
                </div>
                <div style="align-self: center; margin-left: 10px;">
                    <div class="subTitle Quicksand-semibold line-limit"
                        style="text-align: left;margin-bottom: 10px;line-height: 25px;">{{event.title}}
                    </div>
                    <div class="paragraphe Quicksand-regular" style="text-align: left;color: #FFFFFF">
                        {{event.start_date}}</div>
                </div>
            </div>
        </div>
        <form class="form flex-column" [formGroup]="codeForm">
            <div class="Quicksand-regular paragraphe" style="padding-bottom: 10px;color: #8F9BB3;text-align: center;">
                Enter the 4-digit event password :</div>
            <div class="input-container">
                <input nbInput formControlName="nb1" 
                    [ngClass]="{Error : submittedCode && codeFormControls.nb1.errors || wrongCode}" type="text"
                    class="code-input input-form" maxlength="1" />
                <input nbInput formControlName="nb2" 
                    [ngClass]="{Error : submittedCode && codeFormControls.nb2.errors || wrongCode}" type="text"
                    class="code-input input-form" maxlength="1" />
                <input nbInput formControlName="nb3" 
                    [ngClass]="{Error : submittedCode && codeFormControls.nb3.errors || wrongCode}" type="text"
                    class="code-input input-form" maxlength="1" />
                <input nbInput formControlName="nb4"
                    [ngClass]="{Error : submittedCode && codeFormControls.nb4.errors || wrongCode}" type="text"
                    class="code-input input-form" maxlength="1" />
                <!-- <input nbInput formControlName="nb5" [ngClass]="{Error : submittedCode && codeFormControls.nb5.errors || wrongCode}" type="text" class="code-input input-form" maxlength="1" />
                <input nbInput formControlName="nb6" [ngClass]="{Error : submittedCode && codeFormControls.nb6.errors || wrongCode}" type="text" class="code-input input-form" maxlength="1" /> -->
            </div>
            <div style="padding-bottom: 20px;" [ngClass]="erreurMsg[0] ? 'ErrorMessage2'   :''">
                {{erreurMsg[1]}}
            </div>
        </form>
        <div class="next-btn-container">
            <button class="action-btn subTitle Quicksand-bold" style="color :#141A2A;text-align:center"
                style="width: 100%;" (click)="confirmCode()">Join</button>
        </div>
    </div>
    <div *ngIf="voucher" class="popup-code-container">
        <div class="header">
            <div class="title popup-title">Access with a voucher</div>
            <div *ngIf="isShowing" class="sub-title information-text">Already have an access ? &nbsp; <span
                    (click)="Login()">Login</span>
                <!-- <div *ngIf="access.access=='email'">&nbsp; or &nbsp;
                    <span *ngIf="access.access=='email'" (click)="Email()">Via email</span>
                </div> -->
            </div>
            <div class="event-info">
                <div style="width: 35%;min-width: 175px;">
                    <div *ngIf="event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]!='mp4'"
                        class="ar-16-9 " [style.background-image]="'url('+event.cover_url+')'"></div>
                    <div
                        *ngIf="event.cover_url && event.cover_url.split('.')[ event.cover_url.split('.').length-1]=='mp4'">
                        <video autoplay="autoplay" loop muted style="width: 100%;">
                            <source [src]="event.cover_url" type="video/mp4;">
                        </video>
                    </div>
                </div>
                <div style="align-self: center; margin-left: 10px;">
                    <div class="subTitle Quicksand-semibold line-limit"
                        style="text-align: left;margin-bottom: 10px;line-height: 25px;">{{event.title}}
                    </div>
                    <div class="paragraphe Quicksand-regular" style="text-align: left;color: #FFFFFF">
                        {{event.start_date}}</div>
                </div>
            </div>
        </div>
        <form class="form flex-column" [formGroup]="voucherForm">
            <div class="Quicksand-regular paragraphe" style="padding-bottom: 10px;color: #8F9BB3;text-align: center;">
                Enter the 4-digit event password :</div>
            <div class="input-container">
                <input formControlName="nb1" 
                    [ngClass]="{Error : submittedVoucher && voucherFormControls.nb1.errors || wrongCode}" type="text"
                    class="code-input input-form" maxlength="1" />
                <input formControlName="nb2" 
                    [ngClass]="{Error : submittedVoucher && voucherFormControls.nb2.errors || wrongCode}" type="text"
                    class="code-input input-form" maxlength="1" />
                <input formControlName="nb3" 
                    [ngClass]="{Error : submittedVoucher && voucherFormControls.nb3.errors || wrongCode}" type="text"
                    class="code-input input-form" maxlength="1" />
                <input formControlName="nb4"
                    [ngClass]="{Error : submittedVoucher && voucherFormControls.nb4.errors || wrongCode}" type="text"
                    class="code-input input-form" maxlength="1" />
                <!-- <input  formControlName="nb5"  [ngClass]="{Error : submittedVoucher && voucherFormControls.nb5.errors || wrongCode}" type="text" class="code-input input-form" maxlength="1" />
                <input  formControlName="nb6"  [ngClass]="{Error : submittedVoucher && voucherFormControls.nb6.errors || wrongCode}" type="text" class="code-input input-form" maxlength="1" /> -->
            </div>
            <div style="padding-bottom: 20px;" [ngClass]="erreurMsg[0] ? 'ErrorMessage2':''">
                {{erreurMsg[1]}}
            </div>
        </form>
        <div class="next-btn-container">
            <button type="submit" class="action-btn subTitle Quicksand-bold" style="color :#141A2A;text-align:center"
                style="width: 100%;" (click)="purchaseVoucher()">NEXT</button>
        </div>
        <!-- <div class="information-text sub-title" style="margin: 3% 0%;">Get a &nbsp; <span (click)="showticketPopup()">
                Ticket </span></div> -->
    </div>
</div>
<!-- <app-authentification-system *ngIf="login &&  !accessPopup && !showAlert_popup" [data]="authData"  (OutPutDataAuth)="changePopup($event)"></app-authentification-system> -->
<!-- <app-authentification-system *ngIf="login &&  !accessPopup && !showAlert_popup" [data]="authData"></app-authentification-system>

<app-alert-popup *ngIf="showAlert_popup && !login &&  !accessPopup "  [data]="dataAlert"></app-alert-popup> -->