
import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
 import jwt_decode from 'jwt-decode';
import { PortalService } from 'src/app/portal/portal.service';
import { DataService } from '../../services/data.service';
import * as CryptoJS from 'crypto-js';
import { UserService } from 'src/app/services/user.service';
import { AuthentificationSystemService } from '../authentification-system.service';
import { NbDialogRef } from '@nebular/theme';
import { NbDialogService } from '@nebular/theme';
import * as lodash from 'lodash';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-signup-popup',
  templateUrl: './signup-popup.component.html',
  styleUrls: ['./signup-popup.component.css']
})
export class SignupPopupComponent implements OnInit {
  @Output() OutPutData = new EventEmitter();


  @Input() data;
  errorPassword = "Please make sure your passwords match";
  errorMessage: boolean = false;
  hide = true;
  verification: boolean = false;
  verifyMessage = [false, ""]
  signupForm: FormGroup;
  token;
  Signupmsg = ["", ""]
  email = ""
  submitted: boolean = false;
  submittedSignup: boolean = false;
  submittedVerification: boolean = false;
  signuppage: boolean = true;
  wrongCode: boolean = false;
  role = ""
  firsturl = ""
  urlpicture: any;
  picturetoupload
  errormessage = "";
  verificationForm: FormGroup;
  dataVerif: any;
  resendVerifForm = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email])
  })
  event: any;
  DecodedToken: any;
  wrong: boolean;
  constructor( private shareddata: DataService, private portalService: PortalService, private authService: AuthentificationSystemService, private formBuilder: FormBuilder, public dialog: NbDialogService,
    public dialogRef: NbDialogRef<SignupPopupComponent>, private userService: UserService,private cookieService: CookieService) {
    this.firsturl = this.firsturl.concat(this.urlpicture)
  }
  ngOnInit(): void {


    this.signupForm = this.formBuilder.group({
      firstname: new FormControl("", [Validators.required]),
      lastname: new FormControl("", [Validators.required]),
      username: new FormControl("", []),
      email: new FormControl("", [Validators.required, Validators.email]),
      pwd: new FormControl("", [Validators.required]),
      confirmpassword: new FormControl("", [Validators.required]),
    });
    this.verificationForm = this.formBuilder.group({
      nb1: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb2: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb3: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb4: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb5: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb6: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
    });
    this.event = this.shareddata.event;
    if (this.data.signType == "signup") this.hide = true
    else this.hide = false
  }
  @HostListener('keyup', ['$event']) onKeyDown(e: any) {
    e.preventDefault();
    if (this.wrong || this.Signupmsg[0] == 'error') {
      this.Signupmsg = ["", ""]
      this.wrong = false
    }

  }
  checked = false;

  toggle(checked: boolean) {
    this.checked = checked;
  }

  get signupformControls() {
    return this.signupForm.controls;
  }
  get verificationformControls() {
    return this.verificationForm.controls;
  }

  signUp() {

    this.submittedSignup = true;
    if (this.signupForm.invalid) return;
    else if (this.signupForm.controls["pwd"].value != this.signupForm.controls["confirmpassword"].value) {
      this.signupForm.controls['confirmpassword'].setErrors({ [this.errorPassword]: true })
      this.signupForm.controls['pwd'].setErrors({ [this.errorPassword]: true })
      return;
    }
    else {
      let finalemail = this.signupForm.controls['email'].value
      finalemail = finalemail.trim()
      finalemail = finalemail.replace(/\r?\n|\r/g, "")
      this.signupForm.controls['email'].setValue(finalemail)
      var encryptedpwd = CryptoJS.AES.encrypt(this.signupForm.controls['pwd'].value, 'ourkey').toString();
      this.authService.registerAttendee({ firstname: this.signupForm.controls['firstname'].value, lastname: this.signupForm.controls['lastname'].value, email: this.signupForm.controls['email'].value, pwd: encryptedpwd }).subscribe((res: any) => {

        if (res.status == "error") {
          this.wrong = true
          this.Signupmsg = ['error', res['message']];
        }
        else {
         //  this.cookieService.set( 'usertype', 'User');

          this.DecodedToken = jwt_decode(res['accessToken']);
           // this.cookieService.set( 'token', res['accessToken'],this.DecodedToken.exp );
          // this.cookieService.set('exp', this.DecodedToken.exp );

          this.userService.onChangedUser.next(this.DecodedToken.data)
          this.signupForm.reset()
          this.signuppage = false;
          this.Signupmsg = ["success", "Successfully registered, confirm your email and get back to this page to login!"];
          this.submittedSignup = false;
          if (this.data.signType == "signup") {
            if (this.shareddata.code != "" && this.shareddata.event && this.shareddata.event._id) {
              this.portalService.verifyToJoinDashboardEvent(this.shareddata.code, this.shareddata.event._id, "restricted", this.DecodedToken.data._id).then((res: any) => {
                if (res && res.status == "success") {
                  this.userService.getUserById()
                }
              })
            }
            if (this.data.fromNavbar) {
              this.dataVerif =
              {
                verif: "signup",
                email: finalemail,
                verifPopup: true,
                fromNavBar: true
              }
              this.OutPutData.emit(this.dataVerif);
            }
            else {

              this.dataVerif =
              {
                verif: "signup",
                email: finalemail,
                type: this.data.type,
                pass: this.data.pass,
                status: this.data.status,
                verifPopup: true
              }
              this.OutPutData.emit(this.dataVerif);
            }
          }
          else {
            if (this.shareddata.access && this.shareddata.access.access == "auth" && this.shareddata.access.protected == "false" && !this.shareddata.event.is_inviteonly) {
              this.portalService.verifyToJoinDashboardEvent("", this.shareddata.event._id, "restricted", this.DecodedToken.data._id).then((res: any) => {
                if (res && res.status == "success") {
                  this.userService.getUserById().then((userData: any) => {
                    this.dataVerif =
                    {
                      email: finalemail,

                      status: this.data.status,
                      verifPopup: true,
                      type: this.data.type,
                    }
                    this.OutPutData.emit(this.dataVerif);
                  })
                }
              })
            }
            else if (this.shareddata.access && this.shareddata.access.access == "auth" && this.shareddata.access.protected == "true" && !this.shareddata.event.is_inviteonly) {
              if (this.data.pass) {
                if (this.data.type == "ticket") {
                  
                  this.portalService.verifyAccessCode(null, this.data.pass, null, this.shareddata.eventid, this.data.status, this.authService.currentUser._id).subscribe((res: any) => {
                    if (res && res.status == "success") {

                      this.userService.getUserById().then((res1: any) => {

                        var event_as_attendee;
                        if (this.cookieService.get("usertype") == "User")
                          event_as_attendee = lodash.find(res1.user.events_as_attendee, { 'event_id': this.shareddata.eventid });
                        else
                          event_as_attendee = lodash.find(res1.temporary_user.currentUser.events, { 'event_id': this.shareddata.eventid });
                        var ticket = event_as_attendee.pass;
                        this.dataVerif =
                        {
                          email: finalemail,
                          type: this.data.type,
                          status: this.data.status,
                          ticket: ticket,
                          verifPopup: true
                        }
                        this.OutPutData.emit(this.dataVerif);
                        
                      });

                    }
                  })

                }
                else if (this.data.type == "code") {

                  this.portalService.verifyAccessCode(this.data.pass, null, null, this.shareddata.eventid, this.data.status, this.authService.currentUser._id).subscribe((res: any) => {
                    if (res && res.status == "success") {
                      this.userService.getUserById().then((res1: any) => {
                        let dataVerif =
                        {
                          eventid: this.shareddata.eventid,
                          status: this.data.status,
                          verifPopup: true,
                          type: this.data.type,
                        }
                        this.OutPutData.emit(dataVerif)

                      });
                    }
                    else {
                      let dataVerif =
                      {
                        eventid: this.shareddata.eventid,
                        status: this.data.status,
                        verifPopup: true,
                        type: this.data.type,
                        passes: "false",
                      }
                      this.OutPutData.emit(dataVerif)
                    }
                  })
                }
                else if (this.data.type == "voucher") {
                  this.portalService.verifyAccessCode(null, null, this.data.pass, this.shareddata.eventid, this.data.status, this.authService.currentUser._id).subscribe((res: any) => {
                    if (res && res.status == "success") {
                      this.userService.getUserById().then((res1: any) => {
                        if (res1.status == "success") {
                          let dataVerif =
                          {
                            eventid: this.shareddata.eventid,
                            status: this.data.status,
                            verifPopup: true,
                            type: this.data.type,
                          }
                          this.OutPutData.emit(dataVerif)
                        }
                      });
                    }
                    else {
                      let dataVerif =
                      {
                        eventid: this.shareddata.eventid,
                        status: this.data.status,
                        verifPopup: true,
                        type: this.data.type,
                        passes: "false",
                      }
                      this.OutPutData.emit(dataVerif)
                    }
                  });
                }
              }
              else{
                
                let dataVerif =
                {
                  status: this.data.status,
                  verifPopup: true,
                  type: this.data.type,
                }
                this.OutPutData.emit(dataVerif)
              }
            }

            else {
              this.dataVerif =
              {
                email: finalemail,
                type: this.data.type,
                pass: this.data.pass,
                status: this.data.status,
                verifPopup: true
              }
              this.OutPutData.emit(this.dataVerif);
            }

          }
        }
      });
    }
    this.submittedSignup = false;
  }

  keyDownFunctionSignup(e) {
    this.signUp()
  }
  keyDownFunctionVerifyCode(event) {
    // this.verifyCode()
  }
  Login() {
    let data;
    if (this.data.signType == "signup") {
      data =
      {
        popup: "login",
        eventid: this.shareddata.eventid,
        loginType: "login",
        authPopup: true

      }
    }
    else {
      data =
      {
        popup: "login",
        eventid: this.shareddata.eventid,
        status: this.data.status,
        type: this.data.type,
        authPopup: true

      }
    }
    this.OutPutData.emit(data);
    this.shareddata.getAccessPopup("login")
  }

  selectFile(event: any) {
    this.defaultpic = false
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      this.errormessage = 'You must select an image';
      return;
    }
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.errormessage = "Only images are supported";
      return;
    }
    this.picturetoupload = event.target.files[0]
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.errormessage = "";
      this.urlpicture = reader.result;
    }
  }
  deletePhoto() {
    this.urlpicture = this.firsturl
    this.picturetoupload = null
  }

  defaultpic = false
  openDefaultPic() {
    this.defaultpic = !this.defaultpic
  }
  connectWithSocialmedia(socialmedia) {
  //   window.open("this.url/users/" + socialmedia, "_self")
  //   if (this.data && this.data.id) {
  //     sessionStorage.setItem('go_to_event', this.data.id)
  //   }
  //   if (this.data && this.data.ticketid) {
  //     sessionStorage.setItem('ticketid', this.data.ticketid)
  //   }
  }


}
