import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import Swal from "sweetalert2";
import { DataService } from '../services/data.service';
import jwt_decode from 'jwt-decode';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class DashboardEventService {
  onChangedDetailsEventById: BehaviorSubject<any>;
  params;
  data;
  url;
  subscription: Subscription;
  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute,public dataService : DataService,private cookieService: CookieService) {
    this.onChangedDetailsEventById = new BehaviorSubject([]);
    
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.url = environment.url
    return new Promise<void>((resolve, reject) => {
      this.params = route.params;
      this.data = route.data;
      Promise.all([
        this.getEventDetails()
      ]).then(
        () => {
          resolve();
        },
        (reject) => {
        }
      );
    });
  }
  getEventDetails() {
    if (this.data && this.data.title && this.data.title.includes("Event Interface") ) {
      let code  = this.dataService.code
      let decodedToken;
      let userId = '';
      return new Promise<void>((resolve, reject) => {
        if (this.params.eventid) {
            let status=this.data.page
            if (this.cookieService.get('token'))
            {
              decodedToken = jwt_decode(this.cookieService.get('token'));
              userId = decodedToken.data._id;
            }
            if (this.cookieService.get("usertype") == "temporaryUser" && status == "open") {
              userId = ""
            }
      
            let headers;            
            if (userId != "" && code!="")
            {
              headers = 
              {
                code : code,
                userid : userId
              }
            }
            else if (userId == "" && code!="")
            {
              headers = 
              {
                code : code,
              }
            }
            else if (userId != "" && code == "")
            {
              headers = 
              {
                userid : userId
              }
            }
            if(status)
            {
              if (status == "restricted")
              {
                this.http
                .get(this.url+"/events/details/restricted/" + this.params.eventid , {headers : headers,  withCredentials: true } )
                .subscribe((response: any) => {

                  this.onChangedDetailsEventById.next(response);
                  resolve(response);
                }, reject);
              }
              else if (status == "open")
              {  
                
          
                

                this.http
                .get(this.url+"/events/details/open/" + this.params.eventid,{headers :headers} )
                .subscribe((response: any) => {                  
                  this.onChangedDetailsEventById.next(response);
                  resolve(response);
                }, reject);
              }
            }
        }
         else {
          resolve();
        }
      });
    }
  }
  
}
