import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SpeakerComponent } from '../portal/portal-event/speaker/speaker.component';
import { ProfilesServiceService } from './profiles-service.service';
import { SpeakerProfileComponent } from './speaker-profile/speaker-profile.component';
const routes: Routes = [
  {
    path: "",
    data: {
      title: "Profiles",
    },
    children: [
      {
        path: "speaker/:id",
        component: SpeakerProfileComponent,
        resolve: { ProfilesServiceService },
        data: {
          title: "Profile Speaker"
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfilesRoutingModule { }
