import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class AuthentificationSystemService {
  currentUser;
  url = environment.url;
  public userLoggedIn = new Subject<boolean>();
  constructor(private http: HttpClient, private cookieService: CookieService) {
    if (this.cookieService.get("token")) {
      var DecodedToken: any = jwt_decode(this.cookieService.get("token"));
      this.currentUser = DecodedToken.data
    }
    else {
      this.currentUser = null
    }
  }
  login(user) {
    return this.http
    .post<any>(this.url +"/users/login",user, {withCredentials: true})
    .toPromise();
  
    // return this.http.post(this.url + "/users/login", user, { withCredentials: true });
  }
  loginViaEmail(user) {
    return this.http.post(this.url + "/temporary-users/login", user, { withCredentials: true });
  }
  registerAttendee(user) {
    return this.http.post(this.url + "/users/register", user, { headers: { role: 'attendee' }, withCredentials: true });
  }
  VerifyCode(email, code) {
    if (this.cookieService.get("usertype") == "User")
      return this.http.patch(this.url + '/users/verify/' + email + "/" + code, null, { withCredentials: true })
    else if (this.cookieService.get("usertype") == "temporaryUser")
      return this.http.get(this.url + '/temporary-users/verify/' + email + "/" + code, { withCredentials: true })
  }

  logout(id) {
    if (this.cookieService.get("usertype") == "User") {
      return this.http.get(this.url + "/users/logout/" + id, { withCredentials: true });
    }
    else if (this.cookieService.get("usertype") == "temporaryUser") {
      return this.http.get(this.url + "/temporary-users/logout/" + id, { withCredentials: true });
    }
  }
  refresh() {
    var DecodedToken: any = jwt_decode(this.cookieService.get("token"));
    if (this.cookieService.get("usertype") == "User") {
      return this.http.post(this.url + "/users/refresh-token", { userid: DecodedToken.data._id }, { withCredentials: true });
    }
    else if (this.cookieService.get("usertype") == "temporaryUser") {
      return this.http.post(this.url + "/temporary-users/refresh-token", { userid: DecodedToken.data._id }, { withCredentials: true });
    }
  }
  resendverification(email) {
    if (email != null) {
      if (this.cookieService.get("usertype") == "User") {
        return this.http.post(this.url + "/users/resendverification", { email: email })
      }
      else if (sessionStorage.getItem("usertype") == "temporaryUser") {
        return this.http.post(this.url + "/temporary-users/resendverification", { email: email });
      }
    }
  }
  forgetPassword(email) {
    return this.http.post(this.url + "/users/forgottenpassword", { email: email }, { withCredentials: true });
  }
  resetPassword(id,hash,pwd)
  {
    return this.http.patch(this.url + "/users/changepassword/"+id+"/"+hash,pwd);
  }
  verifyUserIsLoggedIn()
  {
    return this.http.get(this.url + "/users/is_loggedin",{ withCredentials: true });
  }
  createUserAndAcceptInvitation(user,code)
  {
    return this.http.post(this.url + "users/acceptinvitationaddattendee/" + code, user)
  }
    
}
