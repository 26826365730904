/* import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpClient,
  HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthentificationSystemService } from "./authentification-system/authentification-system.service";
import jwt_decode from 'jwt-decode';
import { Router } from "@angular/router";
import { catchError } from "rxjs/operators";
import { DataService } from "./services/data.service";
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private cookieService: CookieService, private authservice: AuthentificationSystemService, private http: HttpClient, private dataService: DataService) { }
  done = "false"
  //  cookieService:CookieService
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var url = environment.url;
    const expiration = this.cookieService.get('exp')
    if (expiration && expiration.split('j:')[1] && expiration.split('j:')[1].toString() && this.done == "false") {
      if (Date.now() < new Date(expiration.split('j:')[1].toString().replace(/"/g, "")).getTime()) {
        const transformedReq = req.clone({})
        return next.handle(transformedReq);
      }
      else {
         if (this.done == "false") {
          this.done = "true"
          var DecodedToken: any = jwt_decode(this.cookieService.get("token"));
          if (DecodedToken && DecodedToken.data && DecodedToken.data._id) {
            const userId = DecodedToken.data._id
            new Promise<void>((resolve, reject) => {
              let usertype = this.cookieService.get('usertype')
               let type = 'users'
              if (usertype == "temporaryUser") {
                type = 'temporary-users'
              }
              this.http.post(url + "/" + type + "/refresh-token", { userid: userId }, { withCredentials: true }).pipe(catchError(this.handleError))
                .subscribe((response: any) => {
                  if (response && response.message && response.message.includes('ok')) {
                    this.done = "true"
                    let access: any = jwt_decode(response['accessToken']);
                    // this.cookieService.delete('token');
                    // this.cookieService.delete('exp');
                    // this.cookieService.set('token', response['accessToken'], access.exp);
                    // this.cookieService.set('exp', access.exp);
                    this.done = "false"
                  }
                  else if (response && response.message && response.message.includes('access denied')) {
                    this.done = "true"
                     // this.cookieService.deleteAll();
                    this.dataService.changeHeight(75)
                    this.dataService.notifyAboutChange("logout")
                    this.router.navigateByUrl('/');
                  }
                  resolve(response);
                }, reject);
            });
          }
          else {
            this.done = "false"
          }
        }
        const transformedReq = req.clone({});
        return next.handle(transformedReq)
      }
    }
    else {
      const transformedReq = req.clone({})
      return next.handle(transformedReq);
      // return next.handle(req).pipe(catchError(this.handleError));
    }
  }
  handleError = (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) { }
    else {
      if (error.status == 401) {
         // this.cookieService.deleteAll()
        // this.router.navigateByUrl('/');
      }
      return throwError(error.message);
    }
  }
} */
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { environment } from "src/environments/environment";
import { DataService } from './services/data.service';
declare var toastr;
@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService, private http: HttpClient, private router: Router,private dataService:DataService) { }
  private isRefreshingToken = false;
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          const httpErrorCode: number = error['status'];
          switch (httpErrorCode) {
            case 401:
              return this.handle401Error(request, next);
            default:
              console.warn
                (
                  'Sorry! something went wrong.',
                  'Error!'
                );
              return throwError(error);
          }
        } else {
          return throwError(error);
        }
      })
    );
  }
  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const expiration = this.cookieService.get('exp')
    if (expiration && expiration.split('j:')[1] && expiration.split('j:')[1].toString() && !this.isRefreshingToken) {
      if (Date.now() > new Date(expiration.split('j:')[1].toString().replace(/"/g, "")).getTime()) {
        if (!this.isRefreshingToken) {
          this.isRefreshingToken = true;
          // Reset here so that the following requests wait until the token
          // comes back from the refreshToken call.
          var url = environment.url;
          var DecodedToken: any = jwt_decode(this.cookieService.get("token"));
          const userId = DecodedToken.data._id
          let usertype = this.cookieService.get('usertype')
          let type = 'users'
          if (usertype == "temporaryUser") {
            type = 'temporary-users'
          }
          return this.http.post(url + "/" + type + "/refresh-token", { userid: userId }, { withCredentials: true }).pipe(
            switchMap((response:any) => {
              if(response && response.message && response.message =="access denied"){
                this.dataService.notifyAboutChange("logout")
                this.router.navigateByUrl('/')
              }
              return next.handle(
                this.sendRequest(request)
              );
            }),
            catchError((error) => {
              // If there is an exception calling 'refreshToken', bad news so logout.
              if (this.cookieService.get("usertype") == "User") {
                this.http.get(url + "/" + type+"/logout/" + userId, { withCredentials: true }).subscribe((res)=>{
                  this.dataService.notifyAboutChange("logout")
                  this.router.navigateByUrl('/')
                });
              }
              else if (this.cookieService.get("usertype") == "temporaryUser") {
                this.http.get(url + "/" + type+"/logout/" + userId, { withCredentials: true }).subscribe((res)=>{
                  this.dataService.notifyAboutChange("logout")
                  this.router.navigateByUrl('/')
                });
              }
              return throwError('');

            }),
            finalize(() => {
              this.isRefreshingToken = false;
            })
          );
        } else {
          const transformedReq = request.clone({})
          return next.handle(transformedReq);
        }
      }
    }
  }
  sendRequest(
    request: HttpRequest<any>
  ): HttpRequest<any> {
    return request.clone({
      // setHeaders: { Authorization: 'Bearer ' + token }
    });
  }
}