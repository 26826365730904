import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import * as CryptoJS from 'crypto-js';

import { DashboardTestService } from '../dashboard-test.service';
import { NbDialogRef } from '@nebular/theme';
import { Router } from '@angular/router';
@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css'],
  providers: [DatePipe]
})
export class PopupComponent implements OnInit {
  @Input() data;
  AddEventForm: FormGroup
  EditEventForm: FormGroup
  AddVoucherForm: FormGroup
  submitted = false
  organisations
  cover
  activate_is_inviteonly = false
  is_inviteonly = false
  currenttimezone = ""
  /************************ */
  AddPassForm: FormGroup
  EditPassForm: FormGroup
  constructor(private dashboardtestservice: DashboardTestService, public datepipe: DatePipe,
    public dialogRef: NbDialogRef<PopupComponent>, private fb: FormBuilder, public changeDetectorRef: ChangeDetectorRef, public router: Router) { }
  @HostListener('window:resize', ['$event'])
  onResize(event) {


    if (document.getElementById('data-popup') && document.getElementById('popup-container')) {
      let height_popupcontainer = document.getElementById('popup-container').offsetHeight;
      document.getElementById('data-popup').style.height = (height_popupcontainer - 46).toString() + 'px';

    }
  }
  ngOnInit(): void {


    this.currenttimezone = this.datepipe.transform(Date.now(), 'z')
    this.currenttimezone = this.currenttimezone.substring(3, this.currenttimezone.length)
    if (this.currenttimezone[0] == '+') {
      this.currenttimezone = this.currenttimezone.substring(1, this.currenttimezone.length)
    }

    if (this.data && this.data.name == 'addevent') {
      this.onNumberGenerated()
      this.AddEventForm = this.fb.group({
        title: new FormControl("", [Validators.required, Validators.maxLength(50)]),
        type: new FormControl("", [Validators.required]),

        start_date: new FormControl("", [Validators.required]),
        end_date: new FormControl("", [Validators.required]),
        organisation: new FormControl("", [Validators.required]),
        access: new FormControl("", [Validators.required]),
        is_inviteonly: new FormControl(false, [Validators.required]),
        short_summary: new FormControl("", [Validators.maxLength(200)]),
        utcoffset: new FormControl(this.currenttimezone, []),
        protected_pwd: new FormControl("", []),
      })
      this.dashboardtestservice.getOrganisationsByUser().then((response: any) => {
        if (response && response.status == "success") {
          this.organisations = response.organisations
        }
      })
    }
    if (this.data && this.data.name == 'editevent') {
      this.EditEventForm = this.fb.group({
        title: new FormControl(this.data.event.title, []),
        start_date: new FormControl(this.data.event.start_date.split('.')[0], []),
        end_date: new FormControl(this.data.event.end_date.split('.')[0], []),
        organisation: new FormControl(this.data.event.organizer._id, []),
        cover_url: new FormControl(this.data.event.cover_url, []),
        access: new FormControl(this.data.event.access, []),
        is_inviteonly: new FormControl(this.data.event.is_inviteonly, []),
        short_summary: new FormControl(this.data.event.short_summary, []),
        utcoffset: new FormControl(this.data.event.utcoffset, []),
      })
      if (this.EditEventForm.controls['is_inviteonly'].value == true) { this.activate_is_inviteonly = true }
      this.dashboardtestservice.getOrganisationsByUser().then((response: any) => {
        if (response && response.status == "success") {
          this.organisations = response.organisations
        }
      })
    }
    if (this.data && this.data.name == 'addTicket') {
      this.AddPassForm = this.fb.group({
        name: new FormControl("", [Validators.required]),
        description: new FormControl("", []),
        color: new FormControl("", []),
        price: new FormControl("", [Validators.required]),
        currency: new FormControl("", [Validators.required]),
        quantity: new FormControl("", [Validators.required]),
      })
    }
    if (this.data && this.data.name == 'addCode') {
      this.onNumberGenerated()
      this.AddPassForm = this.fb.group({
        code: new FormControl(this.generatedNumber, [Validators.required]),
        description: new FormControl("", []),
        // color: new FormControl("", []),
        price: new FormControl("", [Validators.required]),
        currency: new FormControl("", [Validators.required]),
        quantity: new FormControl("", [Validators.required]),
      })
    }
    if (this.data && this.data.name == 'editTicket') {
      this.EditPassForm = this.fb.group({
        name: new FormControl(this.data.pass.name, [Validators.required]),
        description: new FormControl(this.data.pass.description, []),
        color: new FormControl(this.data.pass.color, []),
        price: new FormControl(this.data.pass.price, [Validators.required]),
        currency: new FormControl(this.data.pass.currency, [Validators.required]),
        quantity: new FormControl(this.data.pass.quantity, [Validators.required]),
      })
    }
    if (this.data && this.data.name == 'editCode') {
      this.EditPassForm = this.fb.group({
        code: new FormControl(this.data.pass.code, [Validators.required]),
        description: new FormControl(this.data.pass.description, []),
        // color: new FormControl(this.data.pass.color, []),
        price: new FormControl(this.data.pass.price, [Validators.required]),
        currency: new FormControl(this.data.pass.currency, [Validators.required]),
        quantity: new FormControl(this.data.pass.quantity, [Validators.required]),
      })
      // this.color= this.EditPassForm.controls['color'].value
      this.generatedNumber = this.EditPassForm.controls['code'].value
    }
    if (this.data && this.data.name == 'generateVoucher') {
      this.onNumberGenerated()
      this.AddVoucherForm = this.fb.group({
        code: new FormControl(this.generatedNumber, [Validators.required]),
        description: new FormControl("", []),
        // color: new FormControl("", []),
        // price: new FormControl("", [Validators.required]),
        // currency: new FormControl("", [Validators.required]),
        quantity: new FormControl("", [Validators.required]),
      })
    }
    if (this.data && this.data.name == 'voucherslist') {
      this.dashboardtestservice.getVouchersByEventidAndTicketid(this.data.eventid, this.data.ticketid).then((res: any) => {
        this.vouchers = res
      })
    }
  }
  ngAfterViewInit() {


    if (document.getElementById('data-popup') && document.getElementById('popup-container')) {
      let height_popupcontainer = document.getElementById('popup-container').offsetHeight;
      document.getElementById('data-popup').style.height = (height_popupcontainer - 46).toString() + 'px';

    }

  }
  vouchers = []
  get formControls() {
    return this.AddEventForm.controls;
  }
  get formControlsEdit() {
    return this.EditEventForm.controls;
  }
  get formControlsEditPass() {
    return this.EditPassForm.controls;
  }
  get formControlsAddPass() {
    return this.AddPassForm.controls;
  }
  get formControlsVoucher() {
    return this.AddVoucherForm.controls;
  }
  selectorganisation(event) {
    this.AddEventForm.controls['organisation'].setValue(event.target.value)
  }
  addEvent() {
    this.submitted = true;
    if (this.AddEventForm.invalid) {
      return;
    }
    else {
      if (!this.activate_is_inviteonly && this.AddEventForm.controls['is_inviteonly'].value == true) { this.AddEventForm.controls['is_inviteonly'].setValue(false) }
      if (this.AddEventForm.controls['access'].value != 'protected_password') {
        this.AddEventForm.removeControl('protected_pwd');
      }
      else if (this.AddEventForm.controls['access'].value == 'protected_password') {

        var encrypt_protected_pwd = CryptoJS.AES.encrypt(this.generatedNumber.toString(), 'OurProtectedPwdKey').toString()
        this.AddEventForm.controls['protected_pwd'].setValue(encrypt_protected_pwd)
      }

      this.dashboardtestservice.addEvent(this.AddEventForm.value).then((res: any) => {
        if (res.status == "success") {
          this.dialogRef.close() 
          Swal.fire({
            icon: res.status,
            title: res.message,
            background: '#1C1C1C ',
            backdrop: `
                blur(10px)
              `
          }).then((result) => {
            if (result.isConfirmed) {
              if (res.event) {
                
                this.router.navigateByUrl("/dashboard/" + res.event + "/event")
              }
            }
          })

          // this.dialogRef.close((res) => {
          //   this.changeDetectorRef.reattach();
          // })
        }
        else{
          Swal.fire({
            icon: res.status,
            title: res.message,
            background: '#1C1C1C ',
            backdrop: `
                blur(10px)
              `
          })
        }
      });
      this.submitted = false;
    }
  }
  selectFile(event: any) {
    if (event && event.target && event.target.files[0]) {
      this.cover = event.target.files[0]
    }
  }
  editEvent() {
    this.submitted = true;
    if (this.EditEventForm.invalid) {
      return;
    }
    else {
      if (!this.activate_is_inviteonly && this.EditEventForm.controls['is_inviteonly'].value == true) { this.EditEventForm.controls['is_inviteonly'].setValue(false) }
      this.dashboardtestservice.editEvent(this.data.event._id, this.EditEventForm.value, this.cover, null, null).then((res: any) => {
        if (res.status == "success") {
          this.dialogRef.close((res) => {
            this.changeDetectorRef.reattach();
          })
        }
      });
      this.submitted = false;
    }
  }
  selectAccess(event) {
    if (event.target.value == "auth" || event.target.value == "email") { this.activate_is_inviteonly = true }
    else {
      this.activate_is_inviteonly = false
    }
  }
  
  addPass() {
    this.submitted = true;
    if (this.AddPassForm.invalid) {
      return;
    }
    else {
      let type
      if (this.data.name == "addTicket") {
        type = 'ticket'
        this.AddPassForm.controls['color'].setValue(this.color)
      }
      else if (this.data.name == 'addCode') {
        type = 'code'
        this.AddPassForm.controls['code'].setValue(this.generatedNumber)
      }
      this.dashboardtestservice.addPass(this.data.eventid, this.AddPassForm.value, type).then((res: any) => {
        if (res.status == "success") {
          this.dialogRef.close((res) => {
            this.changeDetectorRef.reattach();
          })
        }
      });
      this.submitted = false;
    }
  }
  editPass() {
    this.submitted = true;
    if (this.EditPassForm.invalid) {
      return;
    }
    else {
      if (this.data.name == 'editCode') {
        this.EditPassForm.controls['code'].setValue(this.generatedNumber)
      }
      if (this.data.name == 'editTicket') {
        this.EditPassForm.controls['color'].setValue(this.color)
      }
      this.dashboardtestservice.editPass(this.data.pass._id, this.data.eventid, this.EditPassForm.value).then((res: any) => {
        if (res.status == "success") {
          this.dialogRef.close((res) => {
            this.changeDetectorRef.reattach();
          })
        }
      });
      this.submitted = false;
    }
  }
  openpalette = false
  opencolorPalette() {
    this.openpalette = !this.openpalette
  }
  palettecolor = ["#258FFB", "#FF5964", "#59CD90", "#FFBC42", "#A499FF", "#BF9B6B"]
  getcolor(e) {
    this.color = e
  }
  color = "#258FFB"
  generatedNumber: number;
  onNumberGenerated(): void {
    this.generatedNumber = Math.floor((Math.random() * (10000 - 1000)) + 1000)
  }
  counter(i: number) {
    return new Array(i);
  }
  generateVoucher() {
    this.submitted = true;
    if (this.AddVoucherForm.invalid) {
      return;
    }
    else {
      this.AddVoucherForm.controls['code'].setValue(this.generatedNumber)
      this.dashboardtestservice.generateVoucher(this.data.eventid, this.data.ticketid, this.AddVoucherForm.value).then((res: any) => {
        if (res.status == "success") {
          this.dialogRef.close((res) => {
            this.changeDetectorRef.reattach();
          })
        }
      });
      this.submitted = false;
    }
  }
  removeVoucher(voucherid) {
    Swal.fire({
      title: "Are you sure want to remove this voucher?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, keep it",
      denyButtonText: "delete it!"
    }).then((result) => {
      if (result.value) {
        this.dashboardtestservice.removeVoucher(voucherid).then((res: any) => {
          Swal.fire(res.message, "", res.status);
          this.dashboardtestservice.getVouchersByEventidAndTicketid(this.data.eventid, this.data.ticketid).then((res: any) => {
            if (!res || res && res.length == 0) { this.dialogRef.close() }
            this.vouchers = res
          })
        });
      } else {
        Swal.fire("Cancelled", "", "error");
      }
    });
  }
  expireVoucher(voucherid) {
    Swal.fire({
      title: "This voucher will not be used?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, keep it",
      denyButtonText: "delete it!"
    }).then((result) => {
      if (result.value) {
        this.dashboardtestservice.expireVoucher(voucherid).then((res: any) => {
          Swal.fire(res.message, "", res.status);
          this.dashboardtestservice.getVouchersByEventidAndTicketid(this.data.eventid, this.data.ticketid).then((res: any) => {
            if (!res || res && res.length == 0) { this.dialogRef.close() }
            this.vouchers = res
          })
        });
      } else {
        Swal.fire("Cancelled", "", "error");
      }
    });
  }
  clearSelected(event) {
  }
  selectUTC(event) { }
  back() {
    this.dialogRef.close()
  }
  errorsMsg = { "title": "", "short_summary": "" }
  limitInput(event, input) {
    if (input == 'title') {
      if (event.target.value && event.target.value.length > 50) {
        this.errorsMsg.title = "Maximum 50 characters (" + (Number(event.target.value.length) - Number(50)) + " over)"
      }
      else {
        this.errorsMsg.title = ""
      }

    }

    if (input == 'short_summary') {
      if (event.target.value && event.target.value.length > 200) {
        this.errorsMsg.short_summary = "Maximum 200 characters (" + (Number(event.target.value.length) - Number(200)) + " over)"
      }
      else {
        this.errorsMsg.short_summary = ""
      }

    }
  }
}
