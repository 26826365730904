import { environment } from "src/environments/environment";

var domain = environment.domain;

export const config = {
    platform_name:"",
    favicon:"",
    logo: "",
    top_footer: {
        url: "",
        welcome_message: "",
        message: ""
    },
    bottom_footer: {
        title: "",
        description: ""
    },
    speakers:{
        title:"",
        message:""
    },

    sections_title_icon:"",
    primary_color:"",
    bucket_prefix:""

}
if (domain && domain.includes("dupiflix")) {
    config.platform_name="Dupiflix"
config.favicon="assets/dupiflix/Dupiflix_logo.png"
    config.logo = "../../../assets/dupiflix/Dupiflix_logo.png"
    config.top_footer = {
        url: "../../assets/dupiflix/Dupiflix_footer_cover.png",
        welcome_message: "",
        message: ""
    }
    config.bottom_footer= {
        title: "<img src='../../../assets/dupiflix/Dupiflix_footer_logo.png'/>",
        description: "2022 Sanofi and Regeneron Pharmaceuticals Inc. All rights reserved.<br> This medicinal product is subject to additional monitoring.<br> This will allow quick identification of new safety information.<br> Healthcare professionals are asked to report any suspected adverse reactions."
    },
    config.speakers={
        title:"Find out the line up for the 31<sup>st</sup> edition", 
        message:"EADV’s flagship Annual Congress is one of the most important events in the dermato-venereology calendar.<br>The events are a great opportunity to experience outstanding scientific content from top speakers in the field while getting your valuable CME CPD credits."
    }
    config.sections_title_icon="../../../assets/dupiflix/Dupiflix_nextIcon.png"
 config.primary_color="#00A880"
 config.bucket_prefix="dupiflix-"


}
else {
    config.platform_name="V2 Health"
    config.favicon="assets/logoicon.ico"

    config.logo = "../../../assets/newLogo.png"
    config.top_footer = {
        url: "../../assets/footerExplore.png",
        welcome_message: "Welcome to INCEPT",
        message: "Publish Medical Content.<br> Build Your Next Event In Minutes"
    }
    config.bottom_footer= {
        title:"INCEPT",
        description:"INCEPT is a newly established company that enables client, immersive virtual and hybrid online events on a global scale using a proprietary, multi-channel technology platform."
    },
    config.speakers={
        title:"MEET THE INCREDIBLE SPEAKERS",
        message:"Le cartel de Cali reprend le pouvoir en Colombie. Les successeurs d’Escobar passent à l’action et déclarent la guerre."
    }
    config.sections_title_icon=""
    config.primary_color="#fff"
    config.bucket_prefix="ez-"


   
}
