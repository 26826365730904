import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { DashboardTestService } from '../dashboard-test.service';

@Component({
  selector: 'app-add-organisation',
  templateUrl: './add-organisation.component.html',
  styleUrls: ['./add-organisation.component.css']
})
export class AddOrganisationComponent implements OnInit {
  AddOrganisationForm: FormGroup

  constructor(private fb: FormBuilder, private dashboardtestservice: DashboardTestService, public dialogRef: NbDialogRef<AddOrganisationComponent>) { }

  ngOnInit(): void {
    this.AddOrganisationForm = this.fb.group({
      name: new FormControl("", [Validators.required]),
      description: new FormControl("", [Validators.required]),

    })
  }
  submitted = false
  addOrganisation() {
    this.submitted = true;
    if (this.AddOrganisationForm.invalid) {
      return;
    }
    else {

      this.dashboardtestservice.addOrganisation(this.AddOrganisationForm.value,this.logo).then((res: any) => {
        
        // if (res.status == "success") {
          
          this.dialogRef.close();
        // }
      });
      this.submitted = false;
    }
  }
  get formControls() {
    return this.AddOrganisationForm.controls;
  }
  logo
  readURL(event): void {
    if (event.target.files && event.target.files[0]) {

      this.logo = event.target.files[0];

    }
  }
  back() {
    this.dialogRef.close()
  }
}
