import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Location } from '@angular/common';
import * as lodash from 'lodash';

import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import 'videojs-playlist'
import 'videojs-playlist-ui'
import 'videojs-overlay'
import "videojs-contrib-hls/dist/videojs-contrib-hls";
// import "@videojs/http-streaming"

// import 'videojs-contrib-hls'
import { ExploreEventService } from '../explore-event/explore-event.service';
import { PortalService } from '../portal/portal.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-playlist-player',
  templateUrl: './playlist-player.component.html',
  styleUrls: ['./playlist-player.component.css']
})
export class PlaylistPlayerComponent implements OnInit {
  @ViewChild('target', { static: true }) target: ElementRef;
  @ViewChildren('video') video: any;
  @ViewChildren('videoCapsule') videoCapsule: any;
  
  @Input() data;
  @Input() isProfile;
  @Input() options: {
    aspectRatio: '9:16',
    autoplay: true,
    responsive: true,
  };
  player
  playlist
  videosrc: any = []
  selectedSession
  endVideo = new BehaviorSubject(false);
  duration: number;
  session
  shownVideos: any = [];
  countDownText = "10"
  fullScreen = false
  speakers: any = [];
  channel
  status
  play: boolean = false;
  currentSessionLocal = new BehaviorSubject("");
  playerShow: boolean = true
  videolaunched: boolean = false;
  value: boolean = false;
  paused  : boolean = true;
  userActivity;
  userInactive: Subject<any> = new Subject();
  responsive: boolean;
  constructor(public router: Router,private exploreService: ExploreEventService, private readonly location: Location, private portalService: PortalService, private cd: ChangeDetectorRef) {
    // this.setTimeout();
    // this.userInactive.subscribe(() => {
    //   this.showControls(false)
    // });
  }
  ngOnInit(): void {
  }
  ngAfterViewInit() {
    
    let index = this.location.path().split("/")[this.location.path().split("/").length - 1];
    this.shownVideos = this.data
    for (var i = 0; i < this.data.length; i++) {
      var vidsUrl = ""
      if ((this.data[i].status != "not started" && this.data[i].status != "launched" && this.data[i].status != "Launched") && this.data[i].video_url) {
        vidsUrl = this.data[i].video_url;
        this.videosrc.push({ sources: [{ src: vidsUrl }] });
      }
    }
    let newArray = []
    var midrollPlayer = false
    var sentDuration = true;
    let ad = new BehaviorSubject(false);
    var prevButton
    var nextButton
    var dislikeButton
    var likeButton
    let duration = new BehaviorSubject(0);
    var data = this.data
    var videosrcLocal = this.videosrc
    let videoEnd = new BehaviorSubject(false);
    let fullScreen = new BehaviorSubject(false);
    let isShowen = new BehaviorSubject(true);
    let session = new BehaviorSubject(this.data[1]);
    let contentChanged = new BehaviorSubject(false);
    var buttonComponent = videojs.getComponent('Button');
    var player
    var currentSessionLocal;

    var current_session = lodash.find(this.data, { '_id': index });
    currentSessionLocal = new BehaviorSubject(current_session);

    videojs.Hls.xhr.beforeRequest = function (options) {
      options.headers = {
        "Access-Control-Allow-Origin": "*",
      };
      return options;
    };

    videojs.registerPlugin('examplePlugin', function () {
      player = this
      player.playlist(videosrcLocal);
      player.playlist.autoadvance(5);
      currentSessionLocal.next(current_session)
      // if (player.player_ != null) {
      //   if (this.playlist.currentIndex() == 0) {
      //     player.getChild('controlBar').removeChild('prevButton');
      //     player.getChild('controlBar').removeChild('nextButton');
      //     player.getChild('controlBar').addChild('nextButton', {}, 1);

      //   }
      //   else if (this.playlist.currentIndex() == (videosrcLocal.length - 1).toString()) {
      //     player.getChild('controlBar').removeChild('nextButton');
      //     player.getChild('controlBar').removeChild('prevButton');
      //     player.getChild('controlBar').addChild('prevButton', {}, 0);
      //   }
      //   else {
      //     player.getChild('controlBar').removeChild('prevButton');
      //     player.getChild('controlBar').removeChild('nextButton');
      //     player.getChild('controlBar').addChild('prevButton', {}, 0);
      //     player.getChild('controlBar').addChild('nextButton', {}, 2);
      //   }
      // }
      player.on('fullscreenchange', (event) => {
        if (player.isFullscreen_) {
          fullScreen.next(true)
        }
        else {
          fullScreen.next(false)
        }
      })
      player.on("loadedmetadata", (event) => {
        currentSessionLocal.next(data[player.playlist.currentIndex()])
        videoEnd.next(false)
        midrollPlayer = false
        sentDuration = true
        if (this.playlist.currentIndex() < data.length - 1) {
          session.next(data[this.playlist.currentIndex() + 1])
        }
        else {
          session.next(null)
        }
        if (player.isFullscreen_) {
          fullScreen.next(true)
        }
        else {
          fullScreen.next(false)
        }
        if (player.player_ != null) {
          if (this.playlist.currentIndex() == 0) {
            player.getChild('controlBar').removeChild('prevButton');
            player.getChild('controlBar').removeChild('nextButton');
            player.getChild('controlBar').addChild('nextButton', {}, 1);

          }
          else if (this.playlist.currentIndex() == (videosrcLocal.length - 1).toString()) {
            player.getChild('controlBar').removeChild('nextButton');
            player.getChild('controlBar').removeChild('prevButton');
            player.getChild('controlBar').addChild('prevButton', {}, 0);
          }
          else {
            player.getChild('controlBar').removeChild('prevButton');
            player.getChild('controlBar').removeChild('nextButton');
            player.getChild('controlBar').addChild('prevButton', {}, 0);
            player.getChild('controlBar').addChild('nextButton', {}, 2);
          }
        }

      });
      player.on('progress', function () {
      });
      player.on("error", (event) => {
        if (document.getElementById("videoContainer")) {
          document.getElementById("videoContainer").style.visibility = "hidden"
          document.getElementById("videoContainer").style.position = "absolute"
          document.getElementById("videoContainer").style.zIndex = "-1"
        }

      })
      player.on('contentchanged', function () {
        // isShowen.next(true)
      });
      /************ preroll Ads **************/
      player.on('readyforpreroll', function () {
      });
      player.on('ended', function () {
        videoEnd.next(true)
      })
      /************ midroll Ads **************/
      player.on('timeupdate', function (event) {

      });
      player.on('pause', function () {
        this.bigPlayButton.show();
        player.one('play', function () {
          this.bigPlayButton.hide();
        });
      });
    });

    if (document.getElementById("videoPlayer")) {
      this.player = videojs(document.getElementById("videoPlayer"), {
        inactivityTimeout: 0,
        responsive: true,
        Headers: {
          "Access-Control-Allow-Origin": "*",
        },
        crossOrigin: "Anonymous"
        // html5: {
        //   vhs: {
        //     overrideNative: true
        //   },
        //   nativeAudioTracks: false,
        //   nativeVideoTracks: false
        // },

        // liveui: true
        // aspectRatio: "16:9",
        // fluid : true

      })
      this.player.examplePlugin()
      this.player.playlist.autoadvance(5)
      this.player.playlistUi();
      videoEnd.subscribe((res: any) => {
        if (res == true) {
          this.endVideo.next(true)
          this.countDown(10)
        }
        else {
          this.endVideo.next(false)
        }
      })
      fullScreen.subscribe((res: any) => {
        if (res == true) {
          this.fullScreen = true
          duration.next(player.duration())
        }
        else {
          duration.next(null)
          this.fullScreen = false
        }
      })
      duration.subscribe((res) => {
        if (res != 0 && res != null) {
          if (document.getElementById("allVideo")) {
            document.getElementById("allVideo").style.visibility = "visible"
            document.getElementById("allVideo").style.display = "flex"
          }
          if (document.getElementById("nextVideo")) {
            document.getElementById("nextVideo").style.visibility = "visible"
            document.getElementById("nextVideo").style.display = "block"
          }

          this.duration = res
          if (player.player_ != null) {

            if (this.session != null) {
              player.overlay({
                content: document.getElementById("nextVideo"),
                overlays: [{
                  start: this.duration,
                  end: this.duration + 10,
                  align: 'bottom-right'
                }]
              });
            }
            else {
              this.onResize()
              player.overlay({
                content: document.getElementById("allVideo"),
                overlays: [{
                  start: this.duration,
                  end: this.duration + 10,
                  align: 'center'
                }]
              });
            }
          }

        }
        else {
          if (document.getElementById("allVideo")) {
            document.getElementById("allVideo").style.visibility = "hidden"
            document.getElementById("allVideo").style.display = "none"
          }
          if (document.getElementById("nextVideo")) {
            document.getElementById("nextVideo").style.visibility = "hidden"
            document.getElementById("nextVideo").style.display = "none"
          }
        }
      })
      session.subscribe((res) => {
        if (res && res != null) {
          this.session = res
        }
        else {
          this.session = null
        }
      })
      isShowen.subscribe((res) => {
        this.playerShow = res
      })
      /***********************************control bar buttons  *****************************************/
      if (player.player_ != null) {
        buttonComponent = videojs.getComponent('Button');
        player.getChild('controlBar').removeChild('likeButton');
        player.getChild('controlBar').removeChild('dislikeButton');
        player.getChild('controlBar').removeChild('nextButton');
        player.getChild('controlBar').removeChild('prevButton');
        prevButton = videojs.extend(buttonComponent, {
          constructor: function () {
            buttonComponent.apply(this, arguments);
            this.addClass('vjs-icon-previous-item');
            this.controlText('Previous');
          },
          handleClick: function (e) {
            player.playlist.previous();
          }
        });
        nextButton = videojs.extend(buttonComponent, {
          constructor: function () {
            buttonComponent.apply(this, arguments);
            this.addClass('vjs-icon-next-item');
            this.controlText('Next');
          },
          handleClick: function (e) {
            player.playlist.next();
          }
        });
        likeButton = videojs.extend(buttonComponent, {
          constructor: function () {
            buttonComponent.apply(this, arguments);
            this.addClass('likeIcon');
          },
          handleClick: function (e) {
            player.getChild('controlBar').removeChild('likeButton');
            player.getChild('controlBar').addChild('dislikeButton', {}, 11);
          }
        });
        dislikeButton = videojs.extend(buttonComponent, {
          constructor: function () {
            buttonComponent.apply(this, arguments);
            this.addClass('like-icon-clicked');
          },
          handleClick: function (e) {
            player.getChild('controlBar').removeChild('dislikeButton');
            player.getChild('controlBar').addChild('likeButton', {}, 11);
          }
        });
        videojs.registerComponent('prevButton', prevButton);
        videojs.registerComponent('nextButton', nextButton);
        videojs.registerComponent('likeButton', likeButton);
        videojs.registerComponent('dislikeButton', dislikeButton);
        player.getChild('controlBar').addChild('likeButton', {}, 11);
        if (index == "0") {
          player.getChild('controlBar').removeChild('prevButton');
          player.getChild('controlBar').removeChild('nextButton');
          player.getChild('controlBar').addChild('nextButton', {}, 1);
        }
        else if (index == (this.videosrc.length - 1).toString()) {
          player.getChild('controlBar').removeChild('nextButton');
          player.getChild('controlBar').removeChild('prevButton');
          player.getChild('controlBar').addChild('prevButton', {}, 0);
        }
        else {
          player.getChild('controlBar').addChild('prevButton', {}, 0);
          player.getChild('controlBar').addChild('nextButton', {}, 2);
        }

      }
    }
    currentSessionLocal.subscribe((res: any) => {
      
      if (res) {
        this.speakers = []
        this.selectedSession = res
        this.speakers = this.selectedSession.speakers
        this.status = res.status
        if (res.status == "not started" || res.status == "launched" || res.status == "Launched") {
          if (document.getElementById("videoContainer")) {
            document.getElementById("videoContainer").style.visibility = "hidden"
            document.getElementById("videoContainer").style.position = "absolute"
            document.getElementById("videoContainer").style.zIndex = "-1"
            this.playerShow = false
          }

        }
        else {
          if (document.getElementById("videoContainer")) {
            document.getElementById("videoContainer").style.visibility = "visible"
            document.getElementById("videoContainer").style.position = "relative"
            document.getElementById("videoContainer").style.zIndex = "0"
            this.playerShow = true
          }
        }
      }
    })
    this.currentSessionLocal.subscribe((res: any) => {
      if (res) {
        this.speakers = []
        this.selectedSession = res        
        this.speakers = this.selectedSession.speakers
        this.status = res.status
      }
    })
    this.onResize()
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.shownVideos = []
    if (document.getElementById("allVideo")) {
      if (window.innerWidth >= 1267 && window.innerWidth < 1920) {
        document.getElementById("allVideo").style.width = "100%"
      }
      else {
        document.getElementById("allVideo").style.width = "80vw"

      }
      if (window.innerWidth >= 1267 && window.innerWidth < 1920) {
        if (this.data.length >= 3) {
          for (let i = 0; i < 3; i++) {
            const el = this.data[i];
            this.shownVideos.push(el)
          }
        }
        else {
          for (let i = 0; i < this.data.length; i++) {
            const el = this.data[i];
            this.shownVideos.push(el)
          }
        }

      }
      else if (window.innerWidth >= 1920) {
        if (this.data.length >= 6) {
          for (let i = 0; i < 6; i++) {
            const el = this.data[i];
            this.shownVideos.push(el)
          }
        }
        else {
          for (let i = 0; i < this.data.length; i++) {
            const el = this.data[i];
            this.shownVideos.push(el)
          }
        }
      }
      else if (window.innerWidth < 1297) {
        if (this.data.length >= 2) {
          for (let i = 0; i < 2; i++) {
            const el = this.data[i];
            this.shownVideos.push(el)
          }
        }
        else {
          for (let i = 0; i < this.data.length; i++) {
            const el = this.data[i];
            this.shownVideos.push(el)
          }
        }
      }
    }

    if (window.innerWidth <= 1070) {
      this.responsive = true
      if (document.getElementById("speakers-bloc")) {
        document.getElementById("speakers-bloc").style.position = "relative"
      }
      if (document.getElementById("player-container")) { document.getElementById("player-container").style.flexDirection = "column" }
      if (document.getElementById("description")) { document.getElementById("description").style.maxWidth = "100% !important" }
      if (document.getElementById("videoContent")) { document.getElementById("videoContent").style.width = "100% !important" }
    }
    else {
      this.responsive = false
      if (document.getElementById("speakers-bloc")) { document.getElementById("speakers-bloc").style.position = "absolute" }
      if (document.getElementById("player-container")) { document.getElementById("player-container").style.flexDirection = "row" }
      if (document.getElementById("description")) { document.getElementById("description").style.maxWidth = "57vw!important" }
      if (document.getElementById("videoContent")) { document.getElementById("videoContent").style.width = "58vw !important" }
    }
  }
  @HostListener('document:keydown.Space ', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.play) {
      this.target.nativeElement.play()
      this.play = !this.play
    }
    else {
      this.target.nativeElement.pause()
      this.play = !this.play
    }
  }
  // @HostListener('window:mousemove', ['$event']) refreshUserState(e) {
  //   if (e) {
  //     this.showControls(true)
  //   }
  //   clearTimeout(this.userActivity);
  //   this.setTimeout();
  // }
  // setTimeout() {
  //   this.userActivity = setTimeout(() => this.userInactive.next(undefined), 3000);
  // }
  selectSession(i) {
    this.currentSessionLocal.next(this.data[i])
    this.target.nativeElement.pause()
    this.paused = true
    this.play = false
    if (this.data[i].status == "not started" || this.data[i].status == "launched" || this.data[i].status == "Launched") {
      document.getElementById("videoContainer").style.visibility = "hidden"
      document.getElementById("videoContainer").style.position = "absolute"
      document.getElementById("videoContainer").style.zIndex = "-1"
      this.playerShow = false
    }
    else {
      document.getElementById("videoContainer").style.visibility = "visible"
      document.getElementById("videoContainer").style.position = "relative"
      document.getElementById("videoContainer").style.zIndex = "0"
      this.playerShow = true
      this.cd.detectChanges()
      this.player.playlist.currentItem(i);

    }
    if (!this.isProfile || this.isProfile == "false") { window.scroll(0, 0); }
  }
  showControls(show) {

    this.endVideo.subscribe((res: any) => {
      if (res == true) {
        this.value = true
      }
      else {
        this.value = false
      }
      if (document.getElementById("videoContent")) {

        if (show) {
          document.getElementById("videoContent").style.visibility = "visible"
        }
        else if (!show && !this.value) {
          document.getElementById("videoContent").style.visibility = "hidden"
        }
        else if (!show && this.value) {
          document.getElementById("videoContent").style.visibility = "visible"
        }
      }
    })
    if (document.getElementById("videoContent")) {
      if (show) {
        document.getElementById("videoContent").style.visibility = "visible !important"
      }
      else if (!show) {
        document.getElementById("videoContent").style.visibility = "hidden !important"
      }
    }
    // else if (!show && !value) {
    //   document.getElementById("videoContent").style.visibility = "hidden"
    // }
    // else if (!show && value) {
    //   document.getElementById("videoContent").style.visibility = "visible"
    // }
  }
  countDown(time) {
    setTimeout(() => {
      time = time - 1
      this.countDownText = time.toString()
      if (time > 0) {
        this.countDown(time)
      }
    }, 1000);
  }
  onClickSkipAds() {
    this.player.trigger('adended');
  }
  onClickOnNextSession() {
    this.player.playlist.next();
  }


  closeVideoPlayer() {
  }
  playVideoCover(id, play) {
    
    let div = lodash.find(this.video.toArray(), { 'nativeElement': document.getElementById(id) });
    if (div) {
      if (play) {
        div.nativeElement.play()
        if(document.getElementById(id) && document.getElementById("whitePlay" + id) && document.getElementById("redPlay" + id))
        {

          document.getElementById(id).classList.add("hover")
          document.getElementById(id).classList.remove("notHover")
          document.getElementById("whitePlay" + id).style.visibility = "hidden"
          document.getElementById("whitePlay" + id).style.position = "absolute !important"
          document.getElementById("redPlay" + id).style.visibility = "visible"
          document.getElementById("redPlay" + id).style.position = "relative !important"
        }
      }
      else {
        div.nativeElement.pause()
        if(document.getElementById(id) && document.getElementById("whitePlay" + id) && document.getElementById("redPlay" + id))
        {

          document.getElementById(id).classList.remove("hover")
          document.getElementById(id).classList.add("notHover")
          document.getElementById("whitePlay" + id).style.visibility = "visible"
          document.getElementById("whitePlay" + id).style.position = "relative !important"
          document.getElementById("redPlay" + id).style.visibility = "hidden"
          document.getElementById("redPlay" + id).style.position = "absollute !important"
        }
        }
    }
    // else {
    //   let divImage = document.getElementById("image" + id)
    //   if (divImage) {
    //     if (play) {
    //       divImage.classList.add("hover")
    //       divImage.classList.remove("notHover")
    //       if(document.getElementById("whitePlay" + id) && document.getElementById("redPlay" + id))
    //     {
    //       document.getElementById("whitePlay" + id).style.visibility = "hidden"
    //       document.getElementById("whitePlay" + id).style.position = "absolute !important"
    //       document.getElementById("redPlay" + id).style.visibility = "visible"
    //       document.getElementById("redPlay" + id).style.position = "relative !important"
    //     }

          
    //     }
    //     else {
    //       divImage.classList.remove("hover")
    //       divImage.classList.add("notHover")
    //       if(document.getElementById("whitePlay" + id) && document.getElementById("redPlay" + id))
    //       {
    //         document.getElementById("whitePlay" + id).style.visibility = "visible"
    //         document.getElementById("whitePlay" + id).style.position = "relative !important"
    //         document.getElementById("redPlay" + id).style.visibility = "hidden"
    //         document.getElementById("redPlay" + id).style.position = "absollute !important"
    //       }
    //     }
    //   }
    // }
  }
  playVideoCoverCapsule(id, play) {
    let div = lodash.find(this.videoCapsule.toArray(), { 'nativeElement': document.getElementById("capsule"+id) });
    if (div) {
      if (play) {
        div.nativeElement.play()
      }
      else {
        div.nativeElement.pause()
        }
    }
   
  }
  playVideo() {
    console.log("this.play",this.play);
    
    if (!this.play) {
      this.target.nativeElement.play()
      this.paused = false
      this.play = !this.play
    }
    else {
      this.target.nativeElement.pause()
      this.paused = true
      this.play = !this.play
    }
  }
  gotoProfile(speaker)
  {
    if(speaker && speaker.linked_user && speaker.linked_user.status=="linked")
    {
      this.router.navigateByUrl("/profile/speaker/" + speaker.linked_user.user_id).then((res)=>{});

    }
  }
  // showControls(show) {
  //   let value = false
  //   this.endVideo.subscribe((res: any) => {
  //     if (res == true) {
  //       value = true
  //     }
  //     else {
  //       value = false
  //     }
  //     if (show) {
  //       document.getElementById("videoContent").style.visibility = "visible"
  //     }
  //     else if (!show && !value) {
  //       document.getElementById("videoContent").style.visibility = "hidden"
  //     }
  //     else if (!show && value) {
  //       document.getElementById("videoContent").style.visibility = "visible"
  //     }
  //   })
  //   if (show) {
  //     document.getElementById("videoContent").style.visibility = "visible"
  //   }
  //   else if (!show && !value) {
  //     document.getElementById("videoContent").style.visibility = "hidden"
  //   }
  //   else if (!show && value) {
  //     document.getElementById("videoContent").style.visibility = "visible"
  //   }
  // }
}
