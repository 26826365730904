import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import Swal from 'sweetalert2';
import { AddExhibitorComponent } from '../add-exhibitor/add-exhibitor.component';
import { DashboardTestService } from '../dashboard-test.service';
import {
  CdkDragDrop,
  CdkDragEnter,
  CdkDragMove,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-exhibitors',
  templateUrl: './exhibitors.component.html',
  styleUrls: ['./exhibitors.component.css']
  // , '../../portal/portal-event/exhibitor/exhibitor.component.css']
})
export class ExhibitorsComponent implements OnInit {
  exhibitors
  eventID
  showExhibitorName = -1;
  show: boolean = false
  constructor(public dashboardtestservice: DashboardTestService, public dialog: NbDialogService, private route: ActivatedRoute) { }
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.eventID = params["eventid"];
    });
    this.dashboardtestservice.onChangedExhibitorsByEventid.subscribe((res) => {
      if (res && res.status == "success") {
        this.exhibitors = res.booths.sort((a: any, b: any) => {
          if (a.position < b.position) {
            return -1;
          } else if (a.position > b.position) {
            return 1;
          } else {
            return 0;
          }
        })
      }
    })
  }
  removeBooth(id) {
    Swal.fire({
      title: "Are you sure want to remove  this exhibitor?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.dashboardtestservice.deleteExhibitor(id).then((res: any) => {
          Swal.fire(res.message, "", res.status);
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "", "error");
      }
    });
  }
  editBooth(id, exhibitor) {
    const dialogRef = this.dialog.open(AddExhibitorComponent, {
      context:
      {
        data: {
          name: "edit",
          eventid: this.eventID,
          exhibitor: exhibitor,
          exhibitor_id: id
        },
      }, hasBackdrop: false
    });
    dialogRef.onClose.subscribe((result) => {
    });
  }
  showElement(id) {
    this.showExhibitorName = id;
  }
  showMoreDetails(value, id) {
    if (value) {
      this.showExhibitorName = id;
      this.show = true
      document.getElementById(id).style.background = "transparent linear-gradient(180deg,#00000059 0%,#00000094 0%,#000000 100%) 0% 0% no-repeat padding-box";
    }
    else {
      this.showExhibitorName = null;
      this.show = false
      document.getElementById(id).style.background = "transparent   linear-gradient(180deg, #00000000 0%, #000000E7 80%, #000000 100%) 0% 0% no-repeat padding-box";
    }
  }
  allowDrop(ev) {
    ev.preventDefault();
  }
  drag(ev) {
    ev.dataTransfer.setData("text", ev.target.id);
  }
  drop(ev) {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    ev.target.appendChild(document.getElementById(data));
  }
  counter(i: number) {
    return new Array(i);
  }
  addExhibitor() {
    const dialogRef = this.dialog.open(AddExhibitorComponent, {
      context:
      {
        data: {
          name: "add",
          index: this.exhibitors.length,
          eventid: this.eventID
        },
      }, hasBackdrop: false
    });
    dialogRef.onClose.subscribe((result) => {
    });
  }
  @ViewChild('dropListContainer') dropListContainer?: ElementRef;
  public items: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  dropListReceiverElement?: HTMLElement;
  dragDropInfo?: {
    dragIndex: number;
    dropIndex: number;
  };
  dragIndex_global
  dropIndex_global
  moved_exhibitor
  dragEntered(event: CdkDragEnter<number>, exhibitor) {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;
    this.dragIndex_global = dragIndex
    this.dropIndex_global = dropIndex
    this.moved_exhibitor = exhibitor
    this.dragDropInfo = { dragIndex, dropIndex };
    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');
    const collection: any = document.getElementsByClassName('cdk-drag-placeholder')
    if (collection && exhibitor.card_size == 'default') {
      collection[0].style.width = '30%'
    }
    else if (collection && exhibitor.card_size === 'large') {
      collection[0].style.width = '65%'
    }


    

    if (phElement) {
      phContainer.removeChild(phElement);
      phContainer.parentElement?.insertBefore(phElement, phContainer);
      // const element = this.exhibitors.splice(dragIndex, 1)[0];
      // this.exhibitors.splice(dropIndex, 0, element);
      moveItemInArray(this.exhibitors, dragIndex, dropIndex);
      this.array_diif = []
      for (let index = 0; index < this.exhibitors.length; index++) {
        const element = this.exhibitors[index];
        if (element.position != index)
          this.array_diif.push(element)
        element.position = index
      }
    }
  }
  array_diif

  dragMoved(event: CdkDragMove<number>, size) {
    if (!this.dropListContainer || !this.dragDropInfo) return;
    const placeholderElement =
      this.dropListContainer.nativeElement.querySelector(
        '.cdk-drag-placeholder'
      );

  
    const receiverElement =
      this.dragDropInfo.dragIndex > this.dragDropInfo.dropIndex
        ? placeholderElement?.nextElementSibling
        : placeholderElement?.previousElementSibling;
    if (!receiverElement) {
      return;
    }
    receiverElement.style.display = 'none';
    this.dropListReceiverElement = receiverElement;
  }
  dragDropped(event: CdkDragDrop<number>) {
    if (!this.dropListReceiverElement) {
      return;
    }
    // for (let index = 0; index < this.exhibitors.length; index++) {
    //   const element = this.exhibitors[index];
    // }
    this.dashboardtestservice
      .changeExhibitorsPosition(this.array_diif, this.eventID).then((res: any) => {
      })
    this.dropListReceiverElement.style.removeProperty('display');
    this.dropListReceiverElement = undefined;
    this.dragDropInfo = undefined;
  }
}
