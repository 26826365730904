import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import Swal from "sweetalert2";
import { DataService } from '../services/data.service';
import jwt_decode from 'jwt-decode';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { PortalService } from '../portal/portal.service';
@Injectable({
  providedIn: 'root'
})
export class DashboardTestService {

  onChangedEvents: BehaviorSubject<any>;
  onChangedOrganisationsByUser: BehaviorSubject<any>;
  onChangedEventById: BehaviorSubject<any>;
  onChangedPassesByEventid: BehaviorSubject<any>
  onChangedVouchersByEventidAndTicketid: BehaviorSubject<any>;
  onChangedInvitationsByEventid: BehaviorSubject<any>;
  onChangedOrganisations: BehaviorSubject<any>;
  onChangedOrganisationById: BehaviorSubject<any>;
  onChangedInvitationByOrganisationId: BehaviorSubject<any>;
  onChangedExhibitorsByEventid: BehaviorSubject<any>;
  onChangedDaysByEventId: BehaviorSubject<any>;
  onChangedRoomsByEventid: BehaviorSubject<any>;
  onChangedUser: BehaviorSubject<any>;
  params;
  data;
  url;
  access
  areasevent
  subscription: Subscription;
  constructor(private http: HttpClient, private portalservice:PortalService, private router: Router, private route: ActivatedRoute, public dataService: DataService, private cookieService: CookieService) {
    this.onChangedEvents = new BehaviorSubject([]);
    this.onChangedOrganisationsByUser = new BehaviorSubject([]);
    this.onChangedEventById = new BehaviorSubject([])
    this.onChangedPassesByEventid = new BehaviorSubject([])
    this.onChangedVouchersByEventidAndTicketid = new BehaviorSubject([])
    this.onChangedInvitationsByEventid = new BehaviorSubject([])
    this.onChangedOrganisations = new BehaviorSubject([])
    this.onChangedOrganisationById = new BehaviorSubject([])
    this.onChangedExhibitorsByEventid = new BehaviorSubject([])
    this.onChangedDaysByEventId = new BehaviorSubject([])
    this.onChangedRoomsByEventid = new BehaviorSubject([])
    this.onChangedUser = new BehaviorSubject([])
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.url = environment.url
    return new Promise<void>((resolve, reject) => {
      this.params = route.params;
      this.data = route.data;
      Promise.all([
        // this.getEvents(),
        this.getUserById(),
        this.getEventById(),
        this.getPassesByEventid(),
        this.getInvitationsByEventid(),
        this.getOrganisations(),
        this.getOrganisationById(),
        this.getExhibitorsByEventId(),
        this.getDaysOfTheEventById(), this.getSessionsByEventId()
      ]).then(
        () => {
          resolve();
        },
        (reject) => {
        }
      );
    });
  }

  getUserById() {

    if (this.cookieService.get('token')) {
      const decodedToken: any = jwt_decode(this.cookieService.get('token'));
      const userId = decodedToken.data._id;
      return new Promise((resolve, reject) => {

        if (this.cookieService.get("usertype") == "User") {
          this.http.get(this.url + "/users/id/" + userId, { withCredentials: true })
          
            .subscribe((response: any) => {
              if (response.status == "success") {
                this.onChangedUser.next(response.user);
               }
              resolve(response);
            }, reject);
        }
     

      });
    }
  }



  getOrganisations() {
    if (this.data && this.data.title && this.data.title == "all events") {
      let decodedToken;
      let userId = '';
      return new Promise<void>((resolve, reject) => {
        if (this.cookieService.get('token')) {
          decodedToken = jwt_decode(this.cookieService.get('token'));
          userId = decodedToken.data._id;
        }
        let headers = { userid: userId }
        this.http
          .get(this.url + "/organisations/all/" + userId, { withCredentials: true, headers: headers }

          )
          .subscribe((response: any) => {
            this.onChangedOrganisations.next(response);
            resolve(response);
          }, reject);
      });
    }
  }
  getEventById() {
    return new Promise<void>((resolve, reject) => {
      if (this.params && this.params.eventid && (this.data.title == 'Event page' || this.data.title == 'Registration')) {
        this.http
          .get(this.url + "/events/byid/" + this.params.eventid, { withCredentials: true })
          .subscribe((response: any) => {
            if (response && response.status == "success") {
              this.onChangedEventById.next(response);
              // if (response && response.status == "success") {
              this.access = JSON.parse(response.event.access).access
              this.areasevent = response.event.areas
              // }
              resolve(response);
            }
            else {
              this.router.navigateByUrl('/')
            }
          }, reject);
      } else {
        resolve();
      }
    });
  }
  getOrganisationById() {
    return new Promise<void>((resolve, reject) => {
      if (this.params && this.params.organisationid && this.data.title == 'organisation settings') {
        this.http
          .get(this.url + "/organisations/" + this.params.organisationid, { withCredentials: true })
          .subscribe((response: any) => {
            if (response && response.status == "success") {
              this.onChangedOrganisationById.next(response);
              resolve(response);
            }
            else {
              this.router.navigateByUrl('/')
            }
          }, reject);
      } else {
        resolve();
      }
    });
  }
  getOrganisationsByUser() {
    let decodedToken;
    let userId = '';
    return new Promise<void>((resolve, reject) => {
      if (this.cookieService.get('token')) {
        decodedToken = jwt_decode(this.cookieService.get('token'));
        userId = decodedToken.data._id;
      }
      this.http
        .get(this.url + "/organisations/user/" + userId, { withCredentials: true })
        .subscribe((response: any) => {
          this.onChangedOrganisationsByUser.next(response);
          resolve(response);
        }, reject);
    });
  }
  getPassesByEventid() {
    return new Promise<void>((resolve, reject) => {
      if (this.params && this.params.eventid && (this.data.title == 'Access pass' || this.data.title == 'Invitations')) {
        this.http
          .get(this.url + "/passes/details/" + this.params.eventid, { withCredentials: true })
          .subscribe((response: any) => {
            if (response) {
              this.onChangedPassesByEventid.next(response);
            }
            resolve(response);
          }, reject);
      } else {
        resolve();
      }
    });
  }
  getVouchersByEventidAndTicketid(eventid, ticketid) {
    const decodedToken: any = jwt_decode(this.cookieService.get('token'));
    const userId = decodedToken.data._id;
    return new Promise<void>((resolve, reject) => {
      this.http
        .get(this.url + "/vouchers/" + ticketid, { headers: { eventid: eventid, userid: userId }, withCredentials: true })
        .subscribe((response: any) => {
          if (response) {
            this.onChangedVouchersByEventidAndTicketid.next(response);
          }
          resolve(response);
        }, reject);
    });
  }
  addEvent(event) {
    const decodedToken: any = jwt_decode(this.cookieService.get('token'));
    const userId = decodedToken.data._id;
    return new Promise((resolve, reject) => {
      this.http
        .post(this.url + "/events/add", event, {
          headers: { userid: userId, organisation: event.organisation }, withCredentials: true
        })
        .subscribe((response: any) => {
          
          this.getOrganisations();
          // Swal.fire({
          //   icon: response.status,
          //   title: response.message,
          //   background: '#1C1C1C ',
          //   backdrop: `
          //       blur(10px)
          //     `
          // }).then((result) => {
          //   if (result.isConfirmed) {
          //     if (response.event) {
          //       this.getOrganisations();
          //       this.router.navigateByUrl("/dashboard/" + response.event + "/event")
          //     }
          //   }
          // })
          resolve(response);
        }, reject);
    });
  }
  addOrganisation(organisation, logo) {
    const decodedToken: any = jwt_decode(this.cookieService.get('token'));
    const userId = decodedToken.data._id;
    return new Promise((resolve, reject) => {
      this.http
        .post(this.url + "/organisations/add", organisation, {
          headers: { userid: userId },withCredentials:true
        })
        .subscribe((response: any) => {
          resolve(response);
          if (logo && response && response.status == 'success') {
            return new Promise((resolve, reject) => {
              var formData1 = new FormData();
              formData1.append("logo", logo);
              this.http
                .post(this.url + "/organisations/setlogo", formData1, {
                  headers: { organisationid: response.organisation }, withCredentials: true
                })
                .subscribe((response: any) => {
                  this.getOrganisationById();
                 }, reject);
            });
          }
          // Swal.fire({
          //   icon: response.status,
          //   title: response.message,
          //   background: '#1C1C1C ',
          //   backdrop: `
          //       blur(10px)
          //     `
          // }).then((result) => {
          //   if (result.isConfirmed) {
          //     this.getOrganisations()
          //     // this.router.navigateByUrl("/portal/hybridevent/" + this.eventID)
          //   }
          // })
        
        }, reject);
    });
  }
  addExhibitor(exhibitor, logo, cover, eventid) {

    const decodedToken: any = jwt_decode(this.cookieService.get('token'));
    const userId = decodedToken.data._id;
    return new Promise((resolve, reject) => {
      this.http
        .post(this.url + "/booths/add", exhibitor, {
          headers: { userid: userId }, withCredentials: true
        })
        .subscribe((response: any) => {
          if (response && response.status == 'success') {
            if (logo) {
              new Promise((resolve, reject) => {
                var formData1 = new FormData();
                formData1.append("logo", logo);
                this.http
                  .post(this.url + "/booths/setlogo", formData1, {
                    headers: { boothid: response.boothid, eventid: eventid }, withCredentials: true
                  })
                  .subscribe((response: any) => {
                    this.getExhibitorsByEventId();
                    // resolve(response);
                  });
              });
            }
            if (cover) {

              new Promise((resolve, reject) => {
                var formData1 = new FormData();
                formData1.append("cover", cover);
                this.http
                  .post(this.url + "/booths/setcover", formData1, {
                    headers: { boothid: response.boothid, eventid: eventid }, withCredentials: true
                  })
                  .subscribe((response: any) => {
                    this.getExhibitorsByEventId();
                    // resolve(response);
                  });
              });
            }
          }
          resolve(response);
        }, reject);
    });
  }
  editExhibitor(id, exhibitor, logo, cover, eventid) {
    const decodedToken: any = jwt_decode(this.cookieService.get('token'));
    const userId = decodedToken.data._id;
    return new Promise((resolve, reject) => {
      this.http
        .patch(this.url + "/booths/edit/" + id, exhibitor, {
          headers: { userid: userId, eventid: eventid }, withCredentials: true
        })
        .subscribe((response: any) => {
          if (response && response.status == 'success') {
            if (logo) {
              new Promise((resolve, reject) => {
                var formData1 = new FormData();
                formData1.append("logo", logo);
                this.http
                  .post(this.url + "/booths/setlogo", formData1, {
                    headers: { boothid: response.boothid, eventid: eventid }, withCredentials: true
                  })
                  .subscribe((response: any) => {
                    this.getExhibitorsByEventId();
                    // resolve(response);
                  });
              });
            }
            if (cover) {

              new Promise((resolve, reject) => {
                var formData1 = new FormData();
                formData1.append("cover", cover);
                this.http
                  .post(this.url + "/booths/setcover", formData1, {
                    headers: { boothid: response.boothid, eventid: eventid }, withCredentials: true
                  })
                  .subscribe((response: any) => {
                    this.getExhibitorsByEventId();
                    // resolve(response);
                  });
              });
            }
          }
          this.getExhibitorsByEventId();
          resolve(response);
        }, reject);
    });
  }
  changeExhibitorsPosition(exhibitors, eventid) {
    const decodedToken: any = jwt_decode(this.cookieService.get('token'));
    const userId = decodedToken.data._id;
    return new Promise((resolve, reject) => {
      this.http
        .patch(this.url + "/booths/change_position", exhibitors, {
          headers: { userid: userId, eventid: eventid }, withCredentials: true
        })
        .subscribe((response: any) => {
          if (response && response.status == 'success') {
            this.getExhibitorsByEventId();
          }
          resolve(response);
        }, reject);
    });
  }
  deleteExhibitor(id) {
    let decodedToken;
    let userId = '';
    return new Promise<void>((resolve, reject) => {
      if (this.cookieService.get('token')) {
        decodedToken = jwt_decode(this.cookieService.get('token'));
        userId = decodedToken.data._id;
      }
      this.http
        .delete(this.url + "/booths/delete/" + id, { headers: { userid: userId }, withCredentials: true })
        .subscribe((response: any) => {
          this.getExhibitorsByEventId();
          resolve(response);
        }, reject);
    });
  }
  editEvent(eventid, event, cover, sponsors, videos) {
    const decodedToken: any = jwt_decode(this.cookieService.get('token'));
    const userId = decodedToken.data._id;
    return new Promise((resolve, reject) => {
      this.http
        .patch(this.url + "/events/edit/" + eventid, event, {
          headers: { userid: userId }, withCredentials: true
        })
        .subscribe((response: any) => {
          if (cover) { this.editCover(cover, eventid) }
          if (sponsors && sponsors.length > 0) {
            for (let i = 0; i < sponsors.length; i++) {
              let is_last = false
              if (i == sponsors.length - 1) {
                is_last = true
              }
              this.UploadFiles(sponsors[i], eventid, 'sponsors', is_last)
            }
          }
          if (videos && videos.length > 0) {
            for (let i = 0; i < videos.length; i++) {
              let is_last = false
              if (i == videos.length - 1) {
                is_last = true
              }
              this.UploadFiles(videos[i], eventid, 'videos', is_last)
            }
          }
          if (response.status == "success") {
            Swal.fire({
              icon: response.status,
              title: response.message,
              timer: 1000,
              showConfirmButton: false,
              background: '#1C1C1C ',
              backdrop: `
                blur(10px)
              `
            }).then((result) => {
              this.getEventById()
            })
          }
          else {
            Swal.fire({
              icon: response.status,
              title: response.message,
              background: '#1C1C1C ',
              backdrop: `
                blur(10px)
              `
            }).then((result) => {
              if (result.isConfirmed) {
                this.portalservice.getEventPublicById()
              }
            })
          }
          resolve(response);
        }, reject);
    });
  }
  editCover(cover, eventid) {
    return new Promise((resolve, reject) => {
      var formData1 = new FormData();
      formData1.append("cover", cover);
      this.http
        .post(this.url + "/events/setcover", formData1, {
          headers: { eventid: eventid }, withCredentials: true
        })
        .subscribe((response: any) => {
          this.getEventById();
          resolve(response);
        }, reject);
    });
  }
  UploadFiles(file, eventid, type, last) {
    return new Promise((resolve, reject) => {
      var formData1 = new FormData();
      formData1.append("files", file);
      this.http
        .post(this.url + "/events/setfiles", formData1, {
          headers: { eventid: eventid, type: type }, withCredentials: true
        })
        .subscribe((response: any) => {
          if (last) { this.getEventById(); }
          resolve(response);
        }, reject);
    });
  }
  deleteEventById(eventid, organisationid) {
    let decodedToken;
    let userId = '';
    return new Promise<void>((resolve, reject) => {
      if (this.cookieService.get('token')) {
        decodedToken = jwt_decode(this.cookieService.get('token'));
        userId = decodedToken.data._id;
      }
      this.http
        .patch(this.url + "/events/delete/" + eventid, null, { headers: { userid: userId, organisation: organisationid }, withCredentials: true })
        .subscribe((response: any) => {
          this.getOrganisations();
          resolve(response);
        }, reject);
    });
  }
  permanentlyDeleteEvent(eventid, organisationid) {
    let decodedToken;
    let userId = '';
    return new Promise<void>((resolve, reject) => {
      if (this.cookieService.get('token')) {
        decodedToken = jwt_decode(this.cookieService.get('token'));
        userId = decodedToken.data._id;
      }
      this.http
        .delete(this.url + "/events/permanentlydelete/" + eventid, { headers: { userid: userId, organisation: organisationid }, withCredentials: true })
        .subscribe((response: any) => {
          this.getOrganisations();
          resolve(response);
        }, reject);
    });
  }
  addPass(eventid, passform, type) {
    let decodedToken;
    let userId = '';
    return new Promise<void>((resolve, reject) => {
      if (this.cookieService.get('token')) {
        decodedToken = jwt_decode(this.cookieService.get('token'));
        userId = decodedToken.data._id;
      }
      // , { headers: { userid: userId, eventid: eventid } }
      this.http
        .post(this.url + "/passes/add/" + type + "/" + eventid, passform, { headers: { userid: userId }, withCredentials: true })
        .subscribe((response: any) => {
          if (response.status == 'error') {
            Swal.fire({
              icon: response.status,
              title: response.message,
              background: '#1C1C1C ',
              backdrop: `
                  blur(10px)
                `
            })
          }
          this.getPassesByEventid();
          resolve(response);
        }, reject);
    });
  }
  editPass(passid, eventid, passform) {
    let decodedToken;
    let userId = '';
    return new Promise<void>((resolve, reject) => {
      if (this.cookieService.get('token')) {
        decodedToken = jwt_decode(this.cookieService.get('token'));
        userId = decodedToken.data._id;
      }
      this.http
        .patch(this.url + "/passes/edit/" + passid, passform, { headers: { userid: userId }, withCredentials: true })
        .subscribe((response: any) => {
          this.getPassesByEventid();
          resolve(response);
        }, reject);
    });
  }
  deleteTicketById(ticketid, eventid) {
    let decodedToken;
    let userId = '';
    return new Promise<void>((resolve, reject) => {
      if (this.cookieService.get('token')) {
        decodedToken = jwt_decode(this.cookieService.get('token'));
        userId = decodedToken.data._id;
      }
      this.http
        .delete(this.url + "/passes/delete/" + ticketid, { headers: { userid: userId }, withCredentials: true })
        .subscribe((response: any) => {
          this.getPassesByEventid();
          resolve(response);
        }, reject);
    });
  }
  generateVoucher(eventid, ticketid, AddVoucherForm) {
    let decodedToken;
    let userId = '';
    return new Promise<void>((resolve, reject) => {
      if (this.cookieService.get('token')) {
        decodedToken = jwt_decode(this.cookieService.get('token'));
        userId = decodedToken.data._id;
      }
      // , { headers: { userid: userId, eventid: eventid } }
      this.http
        .post(this.url + "/vouchers/add/" + ticketid, AddVoucherForm, { headers: { userid: userId, eventid: eventid }, withCredentials: true })
        .subscribe((response: any) => {
          if (response.status == 'error') {
            Swal.fire({
              icon: response.status,
              title: response.message,
              background: '#1C1C1C ',
              backdrop: `
                  blur(10px)
                `
            })
          }
          this.getPassesByEventid();
          resolve(response);
        }, reject);
    });
  }
  removeVoucher(voucherid) {
    let decodedToken;
    let userId = '';
    return new Promise<void>((resolve, reject) => {
      if (this.cookieService.get('token')) {
        decodedToken = jwt_decode(this.cookieService.get('token'));
        userId = decodedToken.data._id;
      }
      // , { headers: { userid: userId, eventid: eventid } }
      this.http
        .delete(this.url + "/vouchers/delete/" + voucherid, { headers: { userid: userId }, withCredentials: true })
        .subscribe((response: any) => {
          if (response.status == 'error') {
            Swal.fire({
              icon: response.status,
              title: response.message,
              background: '#1C1C1C ',
              backdrop: `
                  blur(10px)
                `
            })
          }
          this.getPassesByEventid();
          resolve(response);
        }, reject);
    });
  }
  expireVoucher(voucherid) {
    let decodedToken;
    let userId = '';
    return new Promise<void>((resolve, reject) => {
      if (this.cookieService.get('token')) {
        decodedToken = jwt_decode(this.cookieService.get('token'));
        userId = decodedToken.data._id;
      }
      // , { headers: { userid: userId, eventid: eventid } }
      this.http
        .patch(this.url + "/vouchers/expire/" + voucherid, null, { headers: { userid: userId }, withCredentials: true })
        .subscribe((response: any) => {
          if (response.status == 'error') {
            Swal.fire({
              icon: response.status,
              title: response.message,
              background: '#1C1C1C ',
              backdrop: `
                  blur(10px)
                `
            })
          }
          this.getPassesByEventid();
          resolve(response);
        }, reject);
    });
  }
  getInvitationsByEventid() {
    let decodedToken;
    let userId = '';
    return new Promise<void>((resolve, reject) => {
      if (this.cookieService.get('token')) {
        decodedToken = jwt_decode(this.cookieService.get('token'));
        userId = decodedToken.data._id;
      }
      if (this.params && this.params.eventid && this.data.title == 'Invitations') {
        this.getAccessEventById()
        this.http
          .get(this.url + "/invitations/" + this.params.eventid, { headers: { userid: userId }, withCredentials: true })
          .subscribe((response: any) => {
            if (response) {
              this.onChangedInvitationsByEventid.next(response);
            }
            resolve(response);
          }, reject);
      } else {
        resolve();
      }
    });
  }
  sendInvitations(invitation, eventid) {
    const decodedToken: any = jwt_decode(this.cookieService.get('token'));
    const userId = decodedToken.data._id;
    return new Promise((resolve, reject) => {
      this.http
        .post(this.url + "/invitations/send/" + eventid, invitation, {
          headers: { userid: userId, eventid: eventid }, withCredentials: true
        })
        .subscribe((response: any) => {
          let message = ''
          if (response) {
            for (let index = 0; index < response.length; index++) {
              const element = response[index];
              message += '<div style="font-size:16px;">' + element.email + " " + element.status + '<span style="font-size:16px;font-weight:bold;">,</span></div>'
            }
            Swal.fire({
              icon: "success",
              html: message,
              background: '#1C1C1C ',
              backdrop: `
                blur(10px)
              `
            }).then((result) => {
              if (result.isConfirmed) {
                this.getInvitationsByEventid()
                // this.router.navigateByUrl("/portal/hybridevent/" + this.eventID)
              }
            })
          }
          resolve(response);
        }, reject);
    });
  }
  deleteInvitation(invitationid, eventid, organisationid) {
    let decodedToken;
    let userId = '';
    var headers = {}
    return new Promise<void>((resolve, reject) => {
      if (this.cookieService.get('token')) {
        decodedToken = jwt_decode(this.cookieService.get('token'));
        userId = decodedToken.data._id;
      }
      if (eventid) {
        headers = { userid: userId, eventid: eventid }
      }
      else if (organisationid) {
        headers = { userid: userId, organisation: organisationid }
      }
      this.http
        .delete(this.url + "/invitations/delete/" + invitationid, { headers: headers, withCredentials: true })
        .subscribe((response: any) => {
          this.getInvitationsByEventid();
          resolve(response);
        }, reject);
    });
  }
  resendInvitation(invitationid, eventid, organisationid) {
    let decodedToken;
    let userId = '';
    var headers = {}
    return new Promise<void>((resolve, reject) => {
      if (this.cookieService.get('token')) {
        decodedToken = jwt_decode(this.cookieService.get('token'));
        userId = decodedToken.data._id;
      }
      if (eventid) {
        headers = { userid: userId, eventid: eventid }
      }
      else if (organisationid) {
        headers = { userid: userId, organisation: organisationid }
      }
      this.http
        .post(this.url + "/invitations/resendinvitation/" + invitationid, null, { headers: headers, withCredentials: true })
        .subscribe((response: any) => {
          this.getInvitationsByEventid();
          resolve(response);
        }, reject);
    });
  }
  editOrganisation(organisationid, organisation, logo) {
    const decodedToken: any = jwt_decode(this.cookieService.get('token'));
    const userId = decodedToken.data._id;
    return new Promise((resolve, reject) => {
      this.http
        .patch(this.url + "/organisations/edit/" + organisationid, organisation, {
          headers: { userid: userId }, withCredentials: true
        })
        .subscribe((response: any) => {
          if (logo) {
            if (logo && response && response.status == 'success') {
              // return new Promise((resolve, reject) => {
              var formData1 = new FormData();
              formData1.append("logo", logo);
              this.http
                .post(this.url + "/organisations/setlogo", formData1, {
                  headers: { organisationid: response.organisation_id }, withCredentials: true
                })
                .subscribe((res: any) => {
                  this.getOrganisationById();
                  // resolve(response);
                });
              // });
            }
          }
          if (response.status == "success") {
            Swal.fire({
              icon: response.status,
              title: response.message,
              timer: 1000,
              showConfirmButton: false,
              background: '#1C1C1C ',
              backdrop: `
                blur(10px)
              `
            }).then((result) => {
              this.getOrganisationById()
            })
          }
          else {
            Swal.fire({
              icon: response.status,
              title: response.message,
              background: '#1C1C1C ',
              backdrop: `
                blur(10px)
              `
            }).then((result) => {
              if (result.isConfirmed) {
                this.getOrganisationById()
              }
            })
          }
          resolve(response);
        }, reject);
    });
  }
  inviteTeamMemeber(member, organisationid) {
    const decodedToken: any = jwt_decode(this.cookieService.get('token'));
    const userId = decodedToken.data._id;
    return new Promise((resolve, reject) => {
      this.http
        .post(this.url + "/invitations/invite/team", member, {
          headers: { userid: userId, organisation: organisationid }, withCredentials: true
        })
        .subscribe((response: any) => {
          Swal.fire({
            icon: response.status,
            title: response.message,
            background: '#1C1C1C ',
            backdrop: `
                blur(10px)
              `
          }).then((result) => {
            if (result.isConfirmed) {
              if (response.event) {
                this.getOrganisationById();
                this.getInvitationsByorganisationid()
                this.router.navigateByUrl("/dashboard/" + response.event + "/event")
              }
            }
          })
          resolve(response);
        }, reject);
    });
  }
  getInvitationsByorganisationid() {
    let decodedToken;
    let userId = '';
    return new Promise<void>((resolve, reject) => {
      if (this.cookieService.get('token')) {
        decodedToken = jwt_decode(this.cookieService.get('token'));
        userId = decodedToken.data._id;
      }
      if (this.params && this.params.organisationid && this.data.title == 'organisation settings') {
        this.http
          .get(this.url + "/invitations/organisation/" + this.params.organisationid, { headers: { userid: userId }, withCredentials: true })
          .subscribe((response: any) => {
            // if (response) {
            //   this.onChangedInvitationByOrganisationId.next(response);
            // }
            resolve(response);
          }, reject);
      } else {
        resolve();
      }
    });
  }
  getAccessEventById() {
    return new Promise<void>((resolve, reject) => {
      if (this.params && this.params.eventid) {
        this.http
          .get(this.url + "/events/access/" + this.params.eventid, { withCredentials: true })
          .subscribe((response1: any) => {
            if (response1 && response1.status == "success") {
              this.areasevent = response1.areas
              this.access = response1.access
              resolve(response1);
            }
          }, reject);
      } else {
        resolve();
      }
    });
  }
  getExhibitorsByEventId() {
    // return new Promise((resolve, reject) => {
    // if (this.data.title == "exhibitors"  ) {
    //   this.getAccessEventById()
    // this.http
    //   .get(this.url+"/booths/public/" + this.params.eventid)
    //   .subscribe((response: any) => {
    //     if (response && response.status == "success") {
    //       resolve(response.booths);
    //     } 
    //   }, reject);
    //   }
    // });
    let decodedToken;
    let userId = '';
    return new Promise<void>((resolve, reject) => {
      if (this.cookieService.get('token')) {
        decodedToken = jwt_decode(this.cookieService.get('token'));
        userId = decodedToken.data._id;
      }
      if (this.params && this.params.eventid && this.data.title == 'exhibitors') {
        this.getAccessEventById()
        this.http
          .get(this.url + "/booths/public/" + this.params.eventid, { withCredentials: true })
          .subscribe((response: any) => {
            if (response) {
              this.onChangedExhibitorsByEventid.next(response);
            }
            resolve(response);
          }, reject);
      } else {
        resolve();
      }
    });
  }
  getDaysOfTheEventById() {
    return new Promise<void>((resolve, reject) => {
      if (this.params && this.params.eventid && this.data.title == 'Sessions') {
        this.http
          .get(this.url + "/events/days/" + this.params.eventid, { withCredentials: true })
          .subscribe((response: any) => {
            if (response && response.status == "success") {
              this.onChangedDaysByEventId.next(response.days);
              // if (response && response.status == "success") { 
              // }
              resolve(response);
            }
          }, reject);
      } else {
        resolve();
      }
    });
  }
  getSessionsByEventId() {
    let decodedToken;
    let userId = '';
    return new Promise<void>((resolve, reject) => {
      if (this.cookieService.get('token')) {
        decodedToken = jwt_decode(this.cookieService.get('token'));
        userId = decodedToken.data._id;
      }
      if (this.params && this.params.eventid && this.data.title == 'Sessions') {
        this.getAccessEventById()
        this.http
          .get(this.url + "/rooms/public/" + this.params.eventid, { withCredentials: true })
          .subscribe((response: any) => {
            if (response && response.status == "success") {
              this.onChangedRoomsByEventid.next(response.rooms);
            }
            resolve(response);
          }, reject);
      } else {
        resolve();
      }
    });
  }
}
