import { ChangeDetectorRef, Component, EventEmitter,  Input, OnChanges, OnInit, Output } from '@angular/core';
import * as lodash from 'lodash';
import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user.service';
import { PortalService } from 'src/app/portal/portal.service';
import { AuthentificationSystemService } from 'src/app/authentification-system/authentification-system.service';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit, OnChanges {
  @Input() tickets: any = [];
  @Input() status: any = [];
  @Output() authPopup = new EventEmitter();
  @Output() accessPopup = new EventEmitter();
  @Output() OutPutDataShared = new EventEmitter();
  
  ticketSelected: any;
  currentCount;
  opentickets: boolean;
  pass: any;
  havePass: boolean = false;
  erreurMsg = ["", ""];
  roleinevent = "attendee";
  disabled: boolean = false;
  ticket: any;
  dataAlert : any;
  showAlert_popup 
  constructor(public changeDetectorRef: ChangeDetectorRef, private portalService: PortalService, public dialogRef: NbDialogRef<TicketsComponent>, public dialog: NbDialogService, private authService: AuthentificationSystemService, private userSevice: UserService,
    private sharedData: DataService,private cookieService: CookieService ) {
  }
  ngOnInit(): void {

    if (this.cookieService.get("usertype") == "User")
    {
      if (this.authService.currentUser && this.authService.currentUser._id) {
        
        let user = this.authService.currentUser

        if ( user && user.role.includes('admin')) {
          this.roleinevent = 'admin'
        }
        else if ( user && user.role.includes("agency")) {
          this.roleinevent = 'agency'
        }
        else if (user.events_as_speaker && user.events_as_speaker.includes(this.sharedData.eventid)) {
          this.roleinevent = 'speaker'
        }
        else if (user.events_as_exhibitor && user.events_as_exhibitor.includes(this.sharedData.eventid)) {
          this.roleinevent = 'exhibitor'
        }
        else if (user.events_as_attendee && user.events_as_attendee.includes(this.sharedData.eventid)) {
          this.roleinevent = 'attendee'
        }
      }
    }
  }
  ngOnChanges()
  {
    if (this.tickets && this.tickets[0]) {

      this.ticketSelected = this.tickets[0]._id
      this.currentCount = this.tickets[0].quantity - this.tickets[0].purchased
      if (this.currentCount == 0) 
      {
        this.disabled = true;
      }
      else 
      {
        this.disabled = false;
      }
    }
  }
  selectTicket(id, purchased, quantity)
  {
    this.ticketSelected = id;
    this.currentCount = quantity - purchased;
    this.opentickets = false;
    if (this.currentCount == 0) {
      this.disabled = true;
    }
    else {
      this.disabled = false;
    }
  }
  getMyPass()
  {
    this.ticket = lodash.find(this.tickets, { '_id': this.ticketSelected });
    if (!this.disabled) {
      if (this.cookieService.get("token") && this.cookieService.get("usertype") == "User") 
      {
        this.portalService.verifyAccessCode(null, this.ticketSelected, null, this.sharedData.eventid, this.status, this.authService.currentUser._id).subscribe((res: any) => {
       
          if (res && res.status == "success") {

            this.userSevice.getUserById().then((res1: any) => {
               
              this.dataAlert=
              {
                eventid: this.sharedData.eventid,
                ticket: this.ticket,
                passes: true,
                alertPopup : true
              }
              this.accessPopup.emit(this.dataAlert);
             
            });
          }
          else {
            if (res.message == "Sorry No passes left." || res.message == "Sorry No tickets left.") {
              this.dataAlert=
              {
                passes: false,
                alertPopup : true
              }
              this.accessPopup.emit(this.dataAlert);
              
            }
            else {
              this.dataAlert=
              {
                messsages: res.message,
                eventid: this.sharedData.eventid,
                ticket: this.ticket,
                passes: true,
                alertPopup : true
              },
              this.accessPopup.emit(this.dataAlert);
              
            }
          }
        })
      }
      else 
      {
        let data = {
          loginType: "auth",
          eventid: this.sharedData.eventid,
          status: this.status,
          type: "ticket",
          pass: this.ticketSelected,
          ticket: this.ticket,
          authPopup : true
        }
        this.accessPopup.emit(data);
      }
    }
  }
}
