import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DashboardTestService } from '../dashboard-test.service';
import * as CryptoJS from 'crypto-js';
import { DataService } from 'src/app/services/data.service';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
})
export class RegistrationComponent implements OnInit {
  tab = 'access'
  event
  access
  protected
  is_inviteonly = false
  EditEventAccess: FormGroup
  additionForm = false
  editAccess = false
  activate_is_inviteonly = false
  dropdownList = [
    { id: 0, "field": "Email", "attribute": "email", "type": "email", "related": true, "required": false },
    { id: 1, "field": "Firstname", "attribute": "firstname", "type": "single-line", "related": true, "required": false },
    { id: 2, "field": "Lastname", "attribute": "lastname", "type": "single-line", "related": true, "required": false },
    { id: 3, "field": "Photo", "attribute": "photo", "type": "upload", "related": true, "required": false }
  ];
  openCustomField = false
  openRelatedField = false
  fields = new FormArray([]);
  options = []
  is_error = [false, '']
  fieldname = ""
  selectedtype = "single-line"
  input = ''
  relatedFields = []
  disabled_access = false
  protected_pwd
  nav_bar_height
  constructor(private dashboardtestservice: DashboardTestService, private fb: FormBuilder,public dataService:DataService) { }
  ngOnInit(): void {
    this.dashboardtestservice.onChangedEventById.subscribe((response: any) => {
      this.fields = this.fb.array([]);
      if (response && response.status == "success") { this.event = response.event }
      this.access = JSON.parse(this.event.access).access
      this.protected = JSON.parse(this.event.access).protected
      if ((this.access == 'auth' || this.access == 'email') && this.protected == 'true') {
        this.disabled_access = true
      }
      if (this.access != 'protected_password') {
        this.onNumberGenerated()
      }
      if (this.access == 'protected_password') {
        this.generatedNumber = CryptoJS.AES.decrypt(this.event.protected_pwd.toString(), 'OurProtectedPwdKey').toString(CryptoJS.enc.Utf8)
      }
      this.EditEventAccess = this.fb.group({
        access: new FormControl(this.access, []),
        is_inviteonly: new FormControl(this.event.is_inviteonly, []),
        protected_pwd: new FormControl(this.generatedNumber, [])
      })
      this.is_inviteonly = this.EditEventAccess.controls['is_inviteonly'].value
      if (this.EditEventAccess.controls['is_inviteonly'].value == true || this.EditEventAccess.controls['access'].value == 'auth' || this.EditEventAccess.controls['access'].value == 'email') {
        this.activate_is_inviteonly = true
      }
      if (this.event && this.event.registration && this.event.registration) {
        let number = 0
        for (let i = 0; i < this.event.registration.length; i++) {
          number = this.fields.value.length + 1
          let field = new FormGroup({
            id: new FormControl(number),
            field: new FormControl(this.event.registration[i].field),
            attribute: new FormControl(this.event.registration[i].attribute),
            required: new FormControl(this.event.registration[i].required),
            related: new FormControl(this.event.registration[i].related),
            type: new FormControl(this.event.registration[i].type),
            options: new FormControl(this.event.registration[i].options),
            position: new FormControl(this.fields.value.length + 1),
          });
          this.setField(field);
          for (let i = 0; i < this.fields.value.length; i++) {
            if (this.fields.value[i] && this.fields.value[i].related == true) {
              let field = this.fields.value[i]
              // const index_global = this.dropdownList.findIndex(
              //   (control: any) => control.field == this.fields.value[i].field
              // );
              // field.id = this.dropdownList[index_global].id
              this.relatedFields.push(field)
            }
          }
        }
      }
    })
  }
  ngAfterViewInit() {
    this.dataService.navbar_height.subscribe(height => {
  
      this.nav_bar_height=height
    

 })
  }
  setField(field: any): void {
    this.fields.push(field);
  }
  selectAccess(event) {
    if (event.target.value == "auth" || event.target.value == "email") { this.activate_is_inviteonly = true }
    else {
      this.activate_is_inviteonly = false
    }
  }
  editAccessEvent() {
    // if ((this.event.access.is_include('email') || this.event.access.is_include('auth')) && (this.EditEventAccess.controls['access'].value == 'open' || this.EditEventAccess.controls['access'].value == 'protected_password')) {
    //   if ((this.event.pass && this.event.pass.type == 'ticket' && this.event.pass.tickets.length > 0) || this.event.pass && this.event.pass.type == 'code' && (this.event.pass.code || this.event.pass.code.length > 0)) { 
    //     return;
    //   }
    // }
    if (this.EditEventAccess.controls['access'].value != 'protected_password') {
      this.EditEventAccess.removeControl('protected_pwd');
    }
    else if (this.EditEventAccess.controls['access'].value == 'protected_password') {
      var encrypt_protected_pwd = CryptoJS.AES.encrypt(this.generatedNumber.toString(), 'OurProtectedPwdKey').toString()
      this.EditEventAccess.controls['protected_pwd'].setValue(encrypt_protected_pwd)
    }
    if (!this.activate_is_inviteonly && this.EditEventAccess.controls['is_inviteonly'].value == true) { this.EditEventAccess.controls['is_inviteonly'].setValue(false) }
    this.dashboardtestservice.editEvent(this.event._id, this.EditEventAccess.value, null,null,null).then((res: any) => {
      if (res.status == "success") {
        this.editAccess = false
      }
    });
  }
  addRelatedField(item: any, event) {
    if (event.target.checked) {
      const index_global = this.dropdownList.findIndex(
        (control: any) => control.field == item.field
      );
      this.dropdownList[index_global].id = this.fields.length + 1
      this.relatedFields.push(this.dropdownList[index_global])
      let field = new FormGroup({
        id: new FormControl(this.dropdownList[index_global].id),
        field: new FormControl(item.field),
        attribute: new FormControl(item.attribute),
        required: new FormControl(item.required),
        type: new FormControl(item.type),
        related: new FormControl(item.related),
        position: new FormControl(this.fields.value.length + 1),
      });
      this.setField(field);
    }
    else if (!event.target.checked) {
      const index = this.fields.controls.findIndex(
        (control: any) =>
          control.value.field == item.field
      );
      if (index >= 0) { this.fields.removeAt(index); }
      this.relatedFields.splice(this.options.indexOf(item), 1)
    }
  }
  changeRequired(event, index) {
    // this.selectedItems[index].required = event.target.checked
    const index_item = this.fields.controls.findIndex(
      (control: any) => {
        control.value.id == this.fields.value[index].id
        if (control.value.id == this.fields.value[index].id) { control.controls['required'].setValue(event.target.checked) }
      }
    );
  }
  onSubmit() {
    const index = this.fields.controls.findIndex(
      (control: any) =>
        control.value.id == ''
    );
    if (index >= 0) { this.fields.removeAt(index); }
    this.dashboardtestservice.editEvent(this.event._id, { registration: this.fields.value }, null,null,null).then((res: any) => {
      if (res.status == "success") {
        this.editAccess = false
      }
    });
    this.fieldname = ""
    this.additionForm = false
    this.openCustomField = false
    this.openRelatedField = false
    this.fields = this.fb.array([]);
  }
  addAndRemoveOptions(action, option) {
    if (action == 'add') {
      this.options.push(option)
    }
    else if (action == 'remove') {
      this.options.splice(this.options.indexOf(option), 1)
    }
    if (document.getElementById('input')) { (document.getElementById('input') as HTMLInputElement).value = ''; }
    this.input = ''
  }
  addCustomField() {
    if (this.fieldname == "") {
      this.is_error = [true, 'field name is empty']
    }
    else if (((this.selectedtype == 'multi select' || this.selectedtype == 'single select') && this.options.length == 0)) {
      this.is_error = [true, 'options are name is empty']
    }
    else {
      this.is_error = [false, '']
      let attribute = (this.fieldname.trim()).replace(/\s/g, '_')
      let number = this.fields.value.length + 4
      // let Item:any = { id: number, itemName: this.fieldname, attribute: attribute,related:false, required: false ,type:this.selectedtype}
      let field = new FormGroup({
        id: new FormControl(number),
        field: new FormControl(this.fieldname),
        attribute: new FormControl(attribute),
        required: new FormControl(false),
        related: new FormControl(false),
        type: new FormControl(this.selectedtype),
        options: new FormControl(this.options),
        position: new FormControl(this.fields.value.length + 1),
      });
      this.setField(field);
      this.openCustomField = false;
      (document.getElementById('fieldname') as HTMLInputElement).value = '';
    }
    this.fieldname = ""
    this.options = []
  }
  removeField(index, option) {
    this.fields.removeAt(index);
    if (option.related == true) {
      this.relatedFields.splice(this.options.indexOf(option), 1)
    }
  }
  selectTypeInput(event) {
    this.selectedtype = event.target.value
  }
  is_include(option): Boolean {
    let item = this.fields.value.filter(item => item.field === option.field)
    if (item && item.length > 0) {
      return true
    }
    return false
  }
  closeAsidebar() {
    this.fieldname = ""
    this.additionForm = false
    this.openCustomField = false
    this.openRelatedField = false
    this.dashboardtestservice.getEventById()
  }
  imageSrc
  readURL(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;
      reader.readAsDataURL(file);
    }
  }
  generatedNumber: number;
  onNumberGenerated(): void {
    this.generatedNumber = Math.floor((Math.random() * (10000-1000)) + 1000)
  }
  counter(i: number) {
    return new Array(i);
  }
  get formControls() {
    return this.EditEventAccess.controls;
  }
  UpDownFields(action, field) {
    if (action == 'up') {
      if (field.position != 1) {
        let current_position = field.position
        this.fields.controls.findIndex(
          (control: any) => {
            if (control.value.position == current_position - 1) {
              control.controls['position'].setValue(current_position)
            }
            else if (control.value.id == field.id) {
              control.controls['position'].setValue(current_position - 1)
            }
          }
        );
      }
    }
    else     if (action == 'down') {
      if (field.position != this.fields.value.length) {
        let current_position = field.position
        this.fields.controls.findIndex(
          (control: any) => {
            if (control.value.position == current_position + 1) {
              control.controls['position'].setValue(current_position)
            }
            else if (control.value.id == field.id) {
              control.controls['position'].setValue(current_position + 1)
            }
          }
        );
      }
    }
  }
  openAdditionalForm(){
    this.additionForm=!this.additionForm
  }
}
