<div *ngIf="loginPopup" style="overflow: auto;width: 100%;height: 100vh;display: flex;justify-content: center;">
    <div style="width: 400px;align-self: center;">
        <div class="header2" style="margin-bottom: 10%;">
            <div class="title welcome-msg">LOG IN</div>
            <div class="sub-title information-text">Not registred? &nbsp; <span (click)="signup()">Signup</span></div>
            <div style="margin-top: 40px;"><img class="logo" src="../../../assets/portal/logo.png" width="155px" />
            </div>
        </div>
        <div class="formulaire">
            <form class="form flex-column" [formGroup]="loginForm" (keydown.enter)="keyDownFunctionLogin($event)">
                <div class="input-group">
                    <label for="email">Email</label>
                    <input class="input-form" [ngClass]="{Error: submitted && formControls.email.errors}" type="text"
                        name="email" id="email" placeholder="email" formControlName="email">
                </div>
                <div class="input-group">
                    <label for="pwd">Password</label>
                    <input class="input-form" [ngClass]="{Error: submitted && formControls.pwd.errors}" type="password"
                        name="pwd" id="pwd" formControlName="pwd" placeholder="password">
                </div>
                <div style="margin: 10px 0;display: inline-flex;justify-content: space-between; width: 100%; ">
                    <div class="options">
                        <nb-checkbox name="remember_me" formControlName="remember_me" class="sub-title"
                            style="color: #707070;">Remember me </nb-checkbox>
                    </div>
                    <div class="options sub-title">
                        <a (click)="forgotPassword()">Forgot Password ?</a>
                    </div>
                </div>
            </form>
            <div style=" width: 100%;max-width: 400px;margin-top: 10px;"
                [ngClass]="loginMessage[0]=='error' ? 'ErrorMessage' : loginMessage[0]=='success'? 'SuccessMessage' : loginMessage[0]=='warn'? 'WarnMessage'  :''">
                {{loginMessage[1]}}
            </div>
        </div>
        <div class="next-btn-container">
            <button type="submit"  class="action-btn subTitle Quicksand-bold" style="color :#141A2A;text-align:center" (click)="login()">LOG IN</button>
        </div>
        <div style="width: 100%; display: inline-flex;align-items: center;">
            <div style="height: 1px;background-color:#6c6b7f ;width: 50%;"></div>
            <div style="margin:10px;color: #6c6b7f;">or </div>
            <div style="height: 1px;background-color:#6c6b7f;width: 50%; "></div>
        </div>
        <div style="width: 100%;display: flex;justify-content: center;" class="googlelinkedinButton">
            <a class="Google_Linkedin" (click)="connectWithSocialmedia('google')">
                <i class="fa fa-google" style="font-size: 19px;"></i>
            </a>
            <a class="Google_Linkedin" (click)="connectWithSocialmedia('linkedin')">
                <i class="fa fa-linkedin" style="font-size: 19px;"></i>
            </a>
        </div>
    </div>
</div>
<div *ngIf="signupPopup" style="overflow: auto;width: 100%;height: 100vh;display: flex;justify-content: center;">
    <div style="width: 400px;align-self: center;">
        <div class="Signup" style="width :100% ">
            <div class="header2">
                <div class="title welcome-msg"> SIGN UP</div>
                <div class="sub-title information-text">Already have an account? &nbsp; <span (click)="backToLogin()">Login</span>
                </div>
                <div style="margin-top: 40px;"><img class="logo" src="../../../assets/portal/logo.png" width="155px" />
                </div>
            </div>
            <div class="formulaire">
                <form class="form flex-column" [formGroup]="signupForm" (keydown.enter)="keyDownFunctionSignup($event)">
                    <div class="input-group">
                        <label for="email">Email</label>
                        <input type="text" class="input-form"
                            [ngClass]="{Error : submittedSignup && signupformControls.email.errors }" name="email" id="email"
                            placeholder="email" formControlName="email">
                    </div>
                    <div class="Name" style="margin-bottom: 0 !important;  width: 100%;">
                        <div class="input-group" style="margin-right: 30px;margin-bottom: 5%;">
                            <label for="firstname">First name</label>
                            <input class="input-form"
                                [ngClass]="{Error : submittedSignup && signupformControls.firstname.errors }" type="text"
                                id="firstname" placeholder="First name" name="firstname" formControlName="firstname">
                        </div>
                        <div class="input-group">
                            <label for="lastname">Last name</label>
                            <input class="input-form"
                                [ngClass]="{Error : submittedSignup && signupformControls.lastname.errors }" type="text"
                                id="lastname" placeholder="Last name" name="lastname" formControlName="lastname">
                        </div>
                    </div>
                    <div class="Name" style="margin-bottom: 0 !important;  width: 100%;">
                        <div class="input-group" style="margin-right: 30px;margin-bottom: 5%;">
                            <label for="password">Password</label>
                            <input class="input-form" [ngClass]="{Error : submittedSignup && signupformControls.pwd.errors}"
                                type="password" name="pwd" type="password" formControlName="pwd" id="password"
                                placeholder="password">
                        </div>
                        <div class="input-group">
                            <label for="confirmpassword">Confirm password</label>
                            <input class="input-form"
                                [ngClass]="{Error : submittedSignup && signupformControls.confirmpassword.errors}"
                                type="password" name="confirmpassword" type="password" formControlName="confirmpassword"
                                id="confirmpassword" placeholder="Confirm password">
        
                        </div>
                    </div>
                    <div style="margin: 10px 0;display: inline-flex;justify-content: space-between; width: 100%; ">
                        <div class="options">
                            <nb-checkbox (checkedChange)="toggle($event)">I agree with terms and conditions</nb-checkbox>
                        </div>
                    </div>
                </form>
                <div style=" width: 100%;"
                    [ngClass]="Signupmsg[0]=='error' ? 'ErrorMessage' : Signupmsg[0]=='success'? 'SuccessMessage' : Signupmsg[0]=='warn'? 'WarnMessage'  :''">
                    {{Signupmsg[1]}}
                </div>
        
            </div>
            <div class="next-btn-container">
                <button type="submit"  class="action-btn subTitle Quicksand-bold" style="color :#141A2A;text-align:center" (click)="signUp()">SIGN UP</button>
            </div>
            <div style="width: 100%; display: inline-flex;align-items: center;">
                <div style="height: 1px;background-color:#6c6b7f ;width: 50%;"></div>
                <div style="margin:10px;color: #6c6b7f;">or</div>
                <div style="height: 1px;background-color:#6c6b7f;width: 50%; "></div>
            </div>
            <div style="width: 100%;display: flex;justify-content: center;" class="googlelinkedinButton">
                <a class="Google_Linkedin" (click)="connectWithSocialmedia('google')">
                    <i class="fa fa-google" style="font-size: 19px;"></i>
                </a>
                <a class="Google_Linkedin" (click)="connectWithSocialmedia('linkedin')">
                    <i class="fa fa-linkedin" style="font-size: 19px;"></i>
                </a>
            </div>
        </div>
    </div>
</div>

<div *ngIf="verifyAccount">
    <div  style="display: flex;justify-content: center; height: 100%; padding-top: 5%;">
        <div   class="Signup">
            <div class="header">
                <div  style="text-align:center; font-weight: bold; color: white; " class = "title">Let’s verify your Email</div>
                <div class="header-cover-verification"> <img  src="../../../assets/popups/success.png"></div>
            </div>
            <form class="form flex-column" [formGroup]="verificationForm" style="display: flex;justify-content: center;">
                <div style="padding-bottom: 20px;color: #A3A3A3;text-align: center;" class="sub-title">Enter the 6-digit verification code sent to your email :</div>
                <div class="input-container-signup">
                    <input   formControlName="nb1" [ngClass]="{Error : submittedVerification && (verificationformControls.nb1.errors || wrongCode)}"  type="text" class="code-input-signup input-form"  maxlength = "1"/>
                    <input   formControlName="nb2" [ngClass]="{Error : submittedVerification && (verificationformControls.nb2.errors || wrongCode)}" type="text" class="code-input-signup input-form" maxlength = "1"/>
                    <input   formControlName="nb3" [ngClass]="{Error : submittedVerification && (verificationformControls.nb3.errors || wrongCode)}" type="text" class="code-input-signup input-form" maxlength = "1"/>
                    <input   formControlName="nb4" [ngClass]="{Error : submittedVerification && (verificationformControls.nb4.errors || wrongCode)}" type="text" class="code-input-signup input-form" maxlength = "1"/>
                    <input   formControlName="nb5" [ngClass]="{Error : submittedVerification && (verificationformControls.nb5.errors || wrongCode)}" type="text" class="code-input-signup input-form" maxlength = "1"/>
                    <input   formControlName="nb6" [ngClass]="{Error : submittedVerification && (verificationformControls.nb6.errors || wrongCode)}"type="text" class="code-input-signup input-form" maxlength = "1"/>
                </div>
                <div  style="padding-bottom: 30px; color: #A3A3A3; text-align: center;" class="sub-title">Don’t receive the code ? 
                    <span  *ngIf="resend" class="sub-title"  style="border-bottom: 1px solid;cursor:pointer;color: #A3A3A3;font-weight: bold;" (click)="resendverification()" >Resend</span>
                    <span *ngIf="!resend" class="sub-title"  style="border-bottom: 1px solid;cursor:pointer;color: #505050;font-weight: bold;" >Resend</span>
                </div>
                <div style="padding-bottom: 20px; text-align: center;"  [ngClass]="resendVerifyMessage[0] ? 'SuccessMessage':''">
                    {{resendVerifyMessage[1]}}
                </div>
                <div style="padding-bottom: 20px; text-align: center;"  [ngClass]="verifyMessage[0] ? 'ErrorMessage2'   :''">
                    {{verifyMessage[1]}}
                </div>
            </form>
            <div style="width: 100%;display: flex;flex-direction: column;justify-content: center;">
                <div class="next-btn-container" style="align-self: center;">
                    <button  class="action-btn subTitle Quicksand-bold" style="color :#141A2A;text-align:center"  (click)="verifyCode()">Next</button> 
                </div>
                <div  class="skipBtn smallText" style="display: flex;justify-content: center;">
                    <span (click)="skip()" >skip <span style="font-weight: bold; text-align: center;"> <i class="fa fa-angle-right"></i> </span> </span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div *ngIf="forgetPassword">

</div> -->