import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardTestRoutingModule } from './dashboard-test-routing.module';
import { PopupComponent } from './popup/popup.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms"; 
import { DefaultLayoutModule } from './default-layout/default-layout.module';
import { RouterModule } from '@angular/router';
import { FilterPipe } from './filter.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { InvitationsComponent } from './invitations/invitations.component';
import { SendInvitationComponent } from './send-invitation/send-invitation.component';
import { RegistrationComponent } from './registration/registration.component';
import { AddOrganisationComponent } from './add-organisation/add-organisation.component';
import { OrganisationSettingsComponent } from './organisation-settings/organisation-settings.component';
import { SortPipe } from './sort.pipe';
 import { NbCheckboxModule, NbLayoutModule, NbRadioModule, NbSelectModule, NbSidebarModule } from '@nebular/theme';
 import { ExhibitorsComponent } from './exhibitors/exhibitors.component';
import { SessionsComponent } from './sessions/sessions.component';
import { AddExhibitorComponent } from './add-exhibitor/add-exhibitor.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AddEditSessionComponent } from './add-edit-session/add-edit-session.component';
import { ManageExploreComponent } from './manage-explore/manage-explore.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { EditExplorePopupComponent } from './edit-explore-popup/edit-explore-popup.component';
// import { SwiperModule } from 'ngx-swiper-wrapper';
import { NgxUsefulSwiperModule } from "ngx-useful-swiper";
import { ExploreEventModule } from '../explore-event/explore-event.module';
import { PortalModule } from '../portal/portal.module';
 
@NgModule({
  declarations: [DashboardComponent, PopupComponent, FilterPipe, SendInvitationComponent, AddOrganisationComponent, OrganisationSettingsComponent, 
      ExhibitorsComponent, SessionsComponent, AddExhibitorComponent, AddEditSessionComponent, ManageExploreComponent, EditExplorePopupComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule, ReactiveFormsModule,
    DashboardTestRoutingModule,
    DefaultLayoutModule,
    NgSelectModule,
    NbSidebarModule,
    NbLayoutModule,
    NbRadioModule,
    DragDropModule,
    NgxSpinnerModule,
    NbCheckboxModule,
    NgxUsefulSwiperModule,
    NbSelectModule ,
    ExploreEventModule,
    PortalModule
    // SwiperModule,

    
  ]
})
export class DashboardTestModule { }
