import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import Swal from 'sweetalert2';
import { DashboardTestService } from '../dashboard-test.service';
import { SendInvitationComponent } from '../send-invitation/send-invitation.component';
@Component({
  selector: 'app-organisation-settings',
  templateUrl: './organisation-settings.component.html',
  styleUrls: ['./organisation-settings.component.css']
})
export class OrganisationSettingsComponent implements OnInit {
  organisation
  tab = 'details'
  organisationform: FormGroup
  submitted = false
  mode = 'horizontal'
  imageSrc
  logo
  display="Members"
  invitations
  constructor(private dashboardtestservice: DashboardTestService, private fb: FormBuilder, public dialog: NbDialogService) { }
  ngOnInit(): void {
    this.dashboardtestservice.onChangedOrganisationById.subscribe((response: any) => {
      if (response && response.status == "success") {
        this.organisation = response.organisation
        this.organisationform = this.fb.group({
          name: new FormControl(this.organisation.name, [Validators.required]),
          description: new FormControl(this.organisation.description, []),
          twitter: new FormControl(this.organisation.twitter, []),
          email: new FormControl(this.organisation.email, []),
          linkedin: new FormControl(this.organisation.linkedin, []),
          website: new FormControl(this.organisation.website, []),
          team: new FormControl(this.organisation.team, []),
        })
      }
    })
    this.getInvitations()
  }
  getInvitations(){
    this.dashboardtestservice.getInvitationsByorganisationid().then((response: any) => {
      if (response && response.status == "success") {
        this.invitations=response.invitations
      }
      
    })
  }
  get formControls() {
    return this.organisationform.controls;
  }
  uploadLogo(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.logo = event.target.files[0]
      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;
      reader.readAsDataURL(file);
    }
  }
  removelogo() {
    this.logo = null
    this.imageSrc = ''
  }
  clickInput(event) {
    event.target.value = null
  }
  editOrganisation() {
    if (this.organisationform.invalid) {
      return
    }
    else {
      this.dashboardtestservice.editOrganisation(this.organisation._id, this.organisationform.value, this.logo)
    }
  }
  inviteMember() {
    const dialogRef = this.dialog.open(SendInvitationComponent, {
 context : {

   data: {
      organisation: this.organisation._id,
     name:"invite to organisation"
   },
 },      hasBackdrop:false

    });
    dialogRef.onClose.subscribe((result) => {
      this.getInvitations()
    });
    // Swal.fire({
    //   title: 'Invite a team member',
    //   input: 'text',
    //   inputAttributes: {
    //     autocapitalize: 'off'
    //   },
    //   showCancelButton: true,
    //   confirmButtonText: 'Invite',
    //   showLoaderOnConfirm: true,
    //   preConfirm: (user) => {
    //     return this.dashboardtestservice.inviteTeamMemeber({ email: user }, this.organisation._id).then(res => {

    //     }).catch(error => {
    //       Swal.showValidationMessage(
    //         `Request failed: ${error}`
    //       )
    //     })
    //   },
    //   allowOutsideClick: () => !Swal.isLoading()
    // }).then((result) => {
     
    // })




  }
  resendInvitation(invitationid){
    Swal.fire({
      title: "Are you sure want to resend this invitation?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, resend it!",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.dashboardtestservice.resendInvitation(invitationid,null,this.organisation._id).then((res: any) => {

          Swal.fire(res.message, "", res.status);
          this.getInvitations()
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "", "error");
      }
    });
  
  }
  removeInvitation(invitationid){
    Swal.fire({
      title: "Are you sure want to remove this invitation?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:"Yes, delete it!",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.dashboardtestservice.deleteInvitation(invitationid,null,this.organisation._id).then((res: any) => {

          Swal.fire(res.message, "", res.status);
          this.getInvitations()
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "", "error");
      }
    });
  }
}
