import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { throwIfEmpty } from 'rxjs/operators';
import { PortalService } from '../../portal.service';
import { PortalEventComponent } from '../portal-event.component';

@Component({
  selector: 'app-speaker',
  templateUrl: './speaker.component.html',
  styleUrls: ['./speaker.component.css']
})
export class SpeakerComponent implements OnInit {
  @Input() speakers: any = [];
  @Input() total_speakers; 
  total_testimonials;
  newSpeakers: any = [];
  newTestimonials;
  newSpeakersLoading: boolean = false;
  newSpeakersLoaded: boolean = false;
  newTestimonialsLoading: boolean = false;
  newTestimonialsLoaded: boolean = false;
  show_speakers_bool: boolean = false
  current_number_speakers: number
  show_testimonials_bool: boolean = false
  current_number_testimonials: number
  testimonials: any = [];
  readmore: boolean = true;
  more_speakers = { page: '1', limit: '6' };
  more_testimonials = { page: '1', limit: '4' };
  loading: boolean = true;
  placeholder: boolean = false;
  moreCard: boolean = false;
  speakersLeft

  constructor(public portaleventservice: PortalService) {
    
    this.portaleventservice.getMoreTestimonials("1").then((res: any) => {
      this.testimonials = res.testimonials;
      this.total_testimonials = res.total;
      this.current_number_testimonials = Number(this.more_testimonials.limit) * Number(this.more_testimonials.page)
    })
  }
  ngOnInit(): void {
    this.more_speakers = { page: '1', limit: '6' }
    this.current_number_speakers = this.speakers.length
    setTimeout(() => {
      this.loading = false
    }, 2000);
  }
  countLines(i) {
    var lines = 0
    var el = document.getElementById('testimonial' + i);
    if (el) {
      lines = el.offsetHeight / 19
    }
    return lines;
  }
  readMoreTestimonial(i) {
    var el_more = document.getElementById('testimonial' + i);
    var el_less = document.getElementById(i + 'testimonial');
    if (this.readmore) {
      el_more.style.visibility = "visible"
      el_more.style.position = "relative";
      el_more.style.zIndex = "0";
      el_less.style.position = "absolute";
      el_less.style.zIndex = "-1";
    }
    else {
      el_less.style.position = "relative";
      el_less.style.zIndex = "0";
      el_more.style.position = "absolute";
      el_more.style.zIndex = "-1";
    }
    this.readmore = !this.readmore
  }
  showMoreSpeaker() {
    this.show_speakers_bool = true
    this.more_speakers.page = (Number(this.more_speakers.page) + 1).toString();
    this.portaleventservice.getMoreSpeakers(this.more_speakers.page).then((res: any) => {
      if (res.status == "success") {
        this.newSpeakersLoading = true;
        this.newSpeakersLoaded = true;
        if(this.newSpeakers.length>0)
        {
          this.newSpeakers.forEach(speaker => {
            this.speakers.push(speaker)
          });
          this.newSpeakers = []
        }
        if(this.newSpeakers.length == 0)
        {
          res.speakers.forEach(speaker => {
            this.newSpeakers.push(speaker)
          });
        }
        this.current_number_speakers = this.speakers.length + this.newSpeakers.length
        this.speakersLeft = "+" + (this.total_speakers - this.current_number_speakers).toString();
        setTimeout(() => {          
          this.newSpeakersLoaded = false;
        }, 2000);
      }
    });
  }
  reduceSpeakers() {
    this.show_speakers_bool = false
    this.newSpeakers = [];
    this.newSpeakersLoading = false;
    this.loading = false;
    this.more_speakers = { page: '1', limit: '6' };
    this.speakers = [ ]
    this.current_number_speakers = Number(this.more_speakers.limit) * Number(this.more_speakers.page)
    this.portaleventservice.getMoreSpeakers(this.more_speakers.page).then((res: any) => {
     if(res && res.speakers)
     {
        this.speakers = res.speakers
     }
    })
  }
  reduceTestimonials() {
    this.show_testimonials_bool = false
    this.newTestimonials = [];
    this.newTestimonialsLoading = false;
    this.loading = false;
    this.more_testimonials = { page: '1', limit: '4' };
    this.current_number_testimonials = Number(this.more_testimonials.limit) * Number(this.more_testimonials.page)
  }
  showMoreTestimonials() {
    this.show_testimonials_bool = true
    this.more_testimonials.page = (Number(this.more_testimonials.page) + 1).toString();
    this.portaleventservice.getMoreTestimonials(this.more_testimonials.page).then((res: any) => {
      if (res.status == "success") {
        this.newTestimonialsLoading = true;
        this.newTestimonialsLoaded = true;
        this.newTestimonials = res.testimonials
        this.current_number_testimonials = Number(this.more_testimonials.limit) * Number(this.more_testimonials.page)
        setTimeout(() => {
          this.newTestimonialsLoaded = false;
        }, 3000);
      }
    });
  }
  onHoverShowMoreSpeaker(activate) {
    if (activate) {
      document.getElementById("showMoreSpeaker").style.boxShadow = "none";
    }
    else {
      document.getElementById("showMoreSpeaker").style.boxShadow = "inset 0px -200px 100px 20px #121212";
    }

  }
  onHoverShowMoreTestimonials(activate) {
    if (activate) {
      document.getElementById("showMoreTestimonials").style.boxShadow = "none";
    }
    else {
      document.getElementById("showMoreTestimonials").style.boxShadow = "inset 0px -200px 100px 20px #121212";
    }

  }
}
