import { ChangeDetectorRef, Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as lodash from 'lodash';
import { PortalService } from 'src/app/portal/portal.service';
import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user.service';
import { AuthentificationSystemService } from '../authentification-system.service';
import { NbDialogRef } from '@nebular/theme';
import { NbDialogService } from '@nebular/theme';
import jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-verification-popup',
  templateUrl: './verification-popup.component.html',
  styleUrls: ['./verification-popup.component.css']
})
export class VerificationPopupComponent implements OnInit {
  @Input() data;
  @Input() context;
  @Output() OutPutDataShared = new EventEmitter();
  authData;
  submittedVerification: boolean = false;
  login: boolean = false;
  verifyMessage = [false, ""]
  resendVerifyMessage = [false, ""]
  wrongCode: boolean = false;
  verifyProcess: boolean = false;
  invited: boolean = false;
  pre_verif: boolean = false;
  showSkip: boolean = true;
  verificationForm: FormGroup;
  showAlert_popup: boolean = false;
  verification: boolean = true;
  showAccess_Popup: boolean = false;
  dataAccess: any;
  event: any;
  dataAlert: any;
  email
  resend: boolean = true;

  constructor(private cookieService: CookieService, private shareddata: DataService, private portalService: PortalService,
    private authService: AuthentificationSystemService, private formBuilder: FormBuilder, public dialog: NbDialogService,
    public dialogRef: NbDialogRef<VerificationPopupComponent>, private userService: UserService, private portalservice: PortalService, public changeDetectorRef: ChangeDetectorRef) {
  }
  @HostListener('keyup', ['$event']) onKeyDown(e: any) {
    e.preventDefault();
    if (this.wrongCode) {
      this.wrongCode = false
      this.verifyMessage = [false, ""]
    }
    if (this.resendVerifyMessage[0] == true) {
      this.resendVerifyMessage = [false, ""]
    }
    if (e.key == 'Delete' || e.keyCode == 8) {
      let prevControl: any = e.srcElement.previousElementSibling;
      while (true) {
        if (prevControl) {
          if (prevControl.type == e.srcElement.type) {
            prevControl.focus();
            prevControl.select();
            return;
          }
        }
        else {
          return;
        }
      }
    }
    else {
      if (e.srcElement.maxLength == e.srcElement.value.length) {
        let nextControl: any = e.srcElement.nextElementSibling;
        while (true) {
          if (nextControl) {
            if (nextControl.type == e.srcElement.type) {
              nextControl.focus();
              nextControl.select();
              return;
            }
            else {
              nextControl = nextControl.nextElementSibling;
            }
          }
          else {
            return;
          }
        }
      }
    }
  }
  ngOnInit(): void {
    this.verificationForm = this.formBuilder.group({
      nb1: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb2: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb3: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb4: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb5: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      nb6: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
    });
    if (!this.data.confirmFromtop) {

      if (this.shareddata.event && this.shareddata.event.is_inviteonly) {
        this.showSkip = false
        this.verifyProcess = true
      }
      else if (this.shareddata.event && !this.shareddata.event.is_inviteonly) {
        this.verifyProcess = false
      }
      else if ((this.data && this.data.verif == "signup") || (this.data && this.data.signType == "signup")) {
        this.verifyProcess = false
      }
      else {
        this.pre_verif = true
        this.verifyProcess = true
      }
    }
    else {
      this.verifyProcess = false
    }
  }
  get verificationformControls() {
    return this.verificationForm.controls;
  }
  verifyUserRole() {
    let event_as_attendee;
    let events_as_exhibitor;
    let event_as_speaker
    let message;
    if (this.cookieService.get("usertype") == "User") {
      if (this.authService.currentUser.role.includes("agency")) {
        // necessity for more testes 
      }
      else if (this.authService.currentUser.role.includes("attendee")) {
        event_as_attendee = lodash.find(this.authService.currentUser.events_as_attendee, { 'event_id': this.shareddata.eventid });
      }
      else if (this.authService.currentUser.role.includes("exhibitor")) {
        events_as_exhibitor = lodash.find(this.authService.currentUser.events_as_exhibitor, { 'event_id': this.shareddata.eventid });
      }
      else if (this.authService.currentUser.role.includes("speaker")) {
        event_as_speaker = lodash.find(this.authService.currentUser.events_as_speaker, { 'event_id': this.shareddata.eventid });
      }
    }
    else if (this.cookieService.get("usertype") == "temporaryUser") {

      event_as_attendee = lodash.find(this.authService.currentUser.events, { 'event_id': this.shareddata.eventid });
    }
    if (this.authService.currentUser.role.includes("agency") || event_as_attendee || event_as_speaker || events_as_exhibitor) {
      this.invited = true
    }
    else {
      this.invited = false
      return;
    }
  }
  verifyCode() {
    this.submittedVerification = true
    if (this.verificationForm.invalid) {
      return
    }
    let DecodedToken;
    DecodedToken = jwt_decode(this.cookieService.get("token"));
    let current_email = DecodedToken.data.email
    let code = this.verificationForm.controls["nb1"].value + this.verificationForm.controls["nb2"].value + this.verificationForm.controls["nb3"].value + this.verificationForm.controls["nb4"].value + this.verificationForm.controls["nb5"].value + this.verificationForm.controls["nb6"].value
    this.authService.VerifyCode(current_email, code).subscribe((res: any) => {
      if (res) {
        if (res.status == "error") {
          this.wrongCode = true
          this.verifyMessage = [true, res.message]
        }
        else {
          if (!this.verifyProcess) {
            this.wrongCode = false
            this.userService.getUserById().then((resultUser) => {
              if (!this.data.confirmFromtop) {
                if (this.cookieService.get("usertype") == "temporaryUser") {
                  if (this.shareddata.access && this.shareddata.access.protected == "false") {
                    this.dataAlert =
                    {
                      code: true,
                      passes: true,
                      alertPopup: true,
                    }
                    this.OutPutDataShared.emit(this.dataAlert);
                  }
                }
                else {
                  if ((this.data.signupType && this.data.signupType == "signup") || (this.data.verif && this.data.verif == "signup")) {
                    if (this.data.fromNavBar) {
                      this.OutPutDataShared.emit({ close: true });
                    }
                    else {
                      if (this.shareddata.event && this.shareddata.access.protected == "true") {
                        this.OutPutDataShared.emit({ close: true });
                      }
                      else {
                        this.dataAlert =
                        {
                          eventid: this.shareddata.eventid,
                          code: true,
                          passes: true,
                          alertPopup: true,
                        }

                        this.OutPutDataShared.emit(this.dataAlert);
                      }
                    }
                  }
                  else {
                    // if (this.shareddata.event && this.shareddata.event.is_inviteonly) {
                    //   if (this.cookieService.get("token")) {
                    //     if (this.authService.currentUser.status == "verified") {
                    //       this.verifyUserRole()
                    //       setTimeout(() => {
                    //         if (this.invited) {
                    //           this.router.navigateByUrl("eventinterface/restricted/" + this.shareddata.eventid);
                    //         }
                    //         else {
                    //           this.portalService.verifyInvitation(current_email, this.shareddata.eventid).then((res: any) => {
                    //             if (res.status == "success") {
                    //               if (res.invitation_type == "invite_event") {
                    //                 if (this.shareddata.access && this.shareddata.access.protected == "true") {
                    //                   this.dataAccess = {
                    //                     eventid: this.shareddata.eventid,
                    //                     status: "auth",
                    //                     type: this.shareddata.event.pass.type,
                    //                     accessPopup: true
                    //                   }

                    //                   this.OutPutDataShared.emit(this.dataAccess);
                    //                 }
                    //                 else {
                    //                   {
                    //                     this.dataAlert = {
                    //                       passes: true,
                    //                       code: true,
                    //                       alertPopup: true
                    //                     }

                    //                     this.OutPutDataShared.emit(this.dataAlert);
                    //                   }

                    //                 }

                    //               }
                    //               else if (res.invitation_type == "allocate_access_code") {
                    //                 this.dataAlert =
                    //                 {
                    //                   code: true,
                    //                   passes: true,
                    //                   invited: true,

                    //                   alertPopup: true
                    //                 }
                    //                 this.OutPutDataShared.emit(this.dataAlert)

                    //               }
                    //               else if (res.invitation_type == "allocate_ticket") {
                    //                 this.dataAlert =
                    //                 {
                    //                   passes: true,
                    //                   invited: true,
                    //                   ticket: res.allocated_pass,

                    //                   alertPopup: true
                    //                 }
                    //                 this.OutPutDataShared.emit(this.dataAlert)

                    //               }
                    //             }
                    //             else if (res.status == "error") {

                    //               this.dataAlert =
                    //               {
                    //                 passes: true,
                    //                 invited: false,
                    //                 alertPopup: true
                    //               }
                    //               this.OutPutDataShared.emit(this.dataAlert)

                    //             }
                    //           })
                    //         }
                    //       }, 50);
                    //     }
                    //   }
                    // }
                    // else {
                    if (this.shareddata.access && this.shareddata.access.access == "auth" && this.shareddata.access.protected == "false") {

                      this.dataAlert =
                      {
                        eventid: this.shareddata.eventid,
                        code: true,
                        passes: true,
                        alertPopup: true,
                      }

                      this.OutPutDataShared.emit(this.dataAlert);
                    }
                    else if (!this.data.pass) {
                      if (this.shareddata.access && this.shareddata.access.protected == 'true') {
                        this.OutPutDataShared.emit({ close: true });
                      }
                      else {

                        this.dataAlert =
                        {
                          eventid: this.shareddata.eventid,
                          code: true,
                          passes: true,
                          alertPopup: true,
                        }

                        this.OutPutDataShared.emit(this.dataAlert);
                      }

                    }
                    else if (this.data.pass && !this.data.protected) {
                      if (this.data.ticket) {
                        this.dataAlert =
                        {
                          eventid: this.shareddata.eventid,
                          ticket: this.data.ticket,
                          passes: true,
                          alertPopup: true
                        }
                        this.OutPutDataShared.emit(this.dataAlert)
                      }
                      else {
                        if (this.data.passes && this.data.passes == "false") {
                          this.dataAlert =
                          {
                            passes: false,
                            alertPopup: true
                          }
                          this.OutPutDataShared.emit(this.dataAlert)
                        }
                        else {
                          this.dataAlert =
                          {
                            eventid: this.shareddata.eventid,
                            code: true,
                            passes: true,
                            alertPopup: true
                          }
                          this.OutPutDataShared.emit(this.dataAlert)
                        }
                      }
                      // if (this.data.type == "ticket") {
                      //   this.portalService.verifyAccessCode(null, this.data.pass, null, this.shareddata.eventid, this.data.status, this.authService.currentUser._id).subscribe((res: any) => {
                      //     if (res && res.status == "success") {
                      //       this.userService.getUserById().then((res1: any) => {
                      //         var event_as_attendee;
                      //         if (this.cookieService.get("usertype") == "User")
                      //           event_as_attendee = lodash.find(res1.user.events_as_attendee, { 'event_id': this.shareddata.eventid });
                      //         else
                      //           event_as_attendee = lodash.find(res1.temporary_user.currentUser.events, { 'event_id': this.shareddata.eventid });

                      //         var ticket = event_as_attendee.pass;
                      //         this.dataAlert =
                      //         {
                      //           eventid: this.shareddata.eventid,
                      //           ticket: ticket,
                      //           passes: true,
                      //           alertPopup: true
                      //         }
                      //         this.OutPutDataShared.emit(this.dataAlert)

                      //       });
                      //     }
                      //   })
                      // }
                      // else if (this.data.type == "code") {

                      //   this.portalService.verifyAccessCode(this.data.pass, null, null, this.shareddata.eventid, this.data.status, this.authService.currentUser._id).subscribe((res: any) => {
                      //     if (res && res.status == "success") {
                      //       this.userService.getUserById().then((res1: any) => {
                      //         var event_as_attendee = lodash.find(res1.user.events_as_attendee, { 'event_id': this.shareddata.eventid });
                      //         var ticket = event_as_attendee.pass;
                      //         this.dataAlert =
                      //         {
                      //           eventid: this.shareddata.eventid,
                      //           code: true,
                      //           passes: true,
                      //           alertPopup: true
                      //         }
                      //         this.OutPutDataShared.emit(this.dataAlert)

                      //       });
                      //     }
                      //     else {
                      //       this.dataAlert =
                      //       {
                      //         passes: false,
                      //         alertPopup: true
                      //       }
                      //       this.OutPutDataShared.emit(this.dataAlert)
                      //     }
                      //   })
                      // }
                      // else if (this.data.type == "voucher") {
                      //   this.portalService.verifyAccessCode(null, null, this.data.pass, this.shareddata.eventid, this.data.status, this.authService.currentUser._id).subscribe((res: any) => {
                      //     if (res && res.status == "success") {
                      //       this.userService.getUserById().then((res1: any) => {
                      //         if (res1.status == "success") {
                      //           this.dataAlert =
                      //           {
                      //             eventid: this.shareddata.eventid,
                      //             code: true,
                      //             passes: true,
                      //             alertPopup: true
                      //           }
                      //           this.OutPutDataShared.emit(this.dataAlert)
                      //         }
                      //       });
                      //     }
                      //     else {
                      //       this.dataAlert =
                      //       {
                      //         passes: false,
                      //         alertPopup: true
                      //       }
                      //       this.OutPutDataShared.emit(this.dataAlert)
                      //     }
                      //   });
                      // }
                    }
                    // }
                  }
                }
              }
              else {
                this.OutPutDataShared.emit({ close: true });
              }
            })

          }
          else {
            // if (this.data.verif == "email") {
            if (this.cookieService.get("usertype") == "temporaryUser") {
              this.portalservice.verifyInvitation(current_email, this.shareddata.eventid).then((res: any) => {

                if (res.status == "success") {

                  this.userService.getUserById().then((res1) => {

                    if (res.invitation_type == "invite_event") {

                      if (this.shareddata.access && this.shareddata.access.protected == "true") {

                        this.dataAccess = {
                          eventid: this.shareddata.eventid,
                          status: "email",
                          type: this.shareddata.event.pass.type,
                          accessPopup: true
                        }

                        this.OutPutDataShared.emit(this.dataAccess);

                      }
                      else {

                        this.dataAlert =
                        {
                          code: true,
                          passes: true,
                          alertPopup: true
                        }
                        this.OutPutDataShared.emit(this.dataAlert)

                      }
                    }
                    else if (res.invitation_type == "allocate_access_code") {
                      this.dataAlert =
                      {
                        code: true,
                        passes: true,
                        invited: true,
                        alertPopup: true
                      }
                      this.OutPutDataShared.emit(this.dataAlert)

                    }
                    else if (res.invitation_type == "allocate_ticket") {
                      //get the ticket
                      this.dataAlert =
                      {
                        passes: true,
                        invited: true,
                        ticket: res.allocated_pass,
                        alertPopup: true
                      }
                      this.OutPutDataShared.emit(this.dataAlert)

                    }
                  })

                }
                else if (res.status == "error") {
                  /*// sessionStorage.removeItem("token");
                    // sessionStorage.removeItem("usertype");
                     var DecodedToken: any = jwt_decode(this.cookieService.get("token"));
                    if (DecodedToken && DecodedToken.data && DecodedToken.data._id) {
                      this.authService.logout(DecodedToken.data._id).subscribe((res: any) => {
                        if (res && res.status == "success") {
                          this.authService.currentUser = null
   
                          // this.cookieService.deleteAll();
  
                          this.authService.userLoggedIn.next(false)
  
                        }
                      })
                    } 

                  this.shareddata.notifyAboutChange("logout")*/
                  this.dataAlert =
                  {
                    passes: true,
                    invited: false,
                    alertPopup: true
                  }
                  this.OutPutDataShared.emit(this.dataAlert)

                }
              })
            }
            else {
              this.portalService.verifyInvitation(current_email, this.shareddata.eventid).then((res: any) => {
                if (res.status == "success") {
                  this.userService.getUserById()
                  if (res.invitation_type == "invite_event") {
                    if (this.shareddata.access && this.shareddata.access.protected == "true") {
                      this.dataAccess = {
                        eventid: this.shareddata.eventid,
                        status: "auth",
                        type: this.shareddata.event.pass.type,

                        accessPopup: true
                      }
                      this.OutPutDataShared.emit(this.dataAccess);
                    }
                    else {
                      this.dataAlert =
                      {
                        code: true,
                        passes: true,
                        alertPopup: true
                      }
                      this.OutPutDataShared.emit(this.dataAlert)
                    }
                  }
                  else if (res.invitation_type == "allocate_access_code") {
                    this.dataAlert =
                    {
                      code: true,
                      passes: true,
                      invited: true,
                      alertPopup: true
                    }
                    this.OutPutDataShared.emit(this.dataAlert)
                  }
                  else if (res.invitation_type == "allocate_ticket") {
                    this.dataAlert =
                    {
                      passes: true,
                      invited: true,
                      ticket: res.allocated_pass,
                      alertPopup: true
                    }
                    this.OutPutDataShared.emit(this.dataAlert)
                  }
                }
                else if (res.status == "error") {
                  this.userService.getUserById()
                  this.dataAlert =
                  {
                    passes: true,
                    invited: false,
                    alertPopup: true
                  }
                  this.OutPutDataShared.emit(this.dataAlert)
                }
              })
            }
          }
          this.shareddata.verifiedStatus(true);
        }
      }
    })
  }
  skip() {
    if (this.data.verif == "email") {
      if (this.shareddata.access && this.shareddata.access.protected == "false") {
        this.dataAlert =
        {
          code: true,
          passes: true,
          alertPopup: true,
        }
        this.OutPutDataShared.emit(this.dataAlert);
      }
    }
    else if (this.data.verif != "email") {
      if ((this.data.signupType && this.data.signupType == "signup") || (this.data.verif && this.data.verif == "signup")) {

        this.OutPutDataShared.emit({ close: true });
      }
      else {
        if (!this.data.pass) {
          if (this.shareddata.access && this.shareddata.access.protected == 'true') { this.OutPutDataShared.emit({ close: true }); }
          else {
            this.dataAlert =
            {
              eventid: this.shareddata.eventid,
              code: true,
              passes: true,
              alertPopup: true,
            }
            this.OutPutDataShared.emit(this.dataAlert);
          }
        }
        else if (this.data.pass) {
          if (this.data.ticket) {
            this.dataAlert =
            {
              eventid: this.shareddata.eventid,
              ticket: this.data.ticket,
              passes: true,
              alertPopup: true
            }
            this.OutPutDataShared.emit(this.dataAlert)
          }
          else {
            if (this.data.passes && this.data.passes == "false") {
              this.dataAlert =
              {
                passes: false,
                alertPopup: true
              }
              this.OutPutDataShared.emit(this.dataAlert)
            }
            else {
              this.dataAlert =
              {
                eventid: this.shareddata.eventid,
                code: true,
                passes: true,
                alertPopup: true
              }
              this.OutPutDataShared.emit(this.dataAlert)
            }
          }
          // if (this.data.type == "ticket") {

          //   this.portalService.verifyAccessCode(null, this.data.pass, null, this.shareddata.eventid, this.data.status, this.authService.currentUser._id).subscribe((res: any) => {

          //     if (res && res.status == "success") {
          //       this.userService.getUserById().then((res1: any) => {


          //         var event_as_attendee;
          //         if (this.cookieService.get("usertype") == "User")
          //           event_as_attendee = lodash.find(res1.user.events_as_attendee, { 'event_id': this.shareddata.eventid });
          //         else
          //           event_as_attendee = lodash.find(res1.temporary_user.events, { 'event_id': this.shareddata.eventid });

          //         var ticket = event_as_attendee.pass;

          //         this.dataAlert =
          //         {
          //           eventid: this.shareddata.eventid,
          //           ticket: ticket,
          //           passes: true,
          //           alertPopup: true,
          //         }

          //         this.OutPutDataShared.emit(this.dataAlert);

          //       });
          //     }
          //     else {
          //       if (res.message == "Sorry No tickets left.") {
          //         this.dataAlert =
          //         {
          //           passes: false,
          //           alertPopup: true,
          //         }
          //         this.OutPutDataShared.emit(this.dataAlert);

          //       }
          //     }
          //   })
          // }
          // else if (this.data.type == "code") {
          //   this.portalService.verifyAccessCode(this.data.pass, null, null, this.shareddata.eventid, this.data.status, this.authService.currentUser._id).subscribe((res: any) => {
          //     if (res && res.status == "success") {
          //       this.userService.getUserById().then((res1: any) => {


          //         var event_as_attendee = lodash.find(res1.user.events_as_attendee, { 'event_id': this.shareddata.eventid });
          //         var ticket = event_as_attendee.pass;
          //         this.dataAlert =
          //         {
          //           eventid: this.shareddata.eventid,
          //           code: true,
          //           passes: true,

          //           alertPopup: true,


          //         }
          //         this.OutPutDataShared.emit(this.dataAlert);

          //       });
          //     }
          //     else {
          //       this.dataAlert =
          //       {
          //         passes: false,

          //         alertPopup: true,


          //       }
          //       this.OutPutDataShared.emit(this.dataAlert);

          //     }
          //   })
          // }
          // else if (this.data.type == "voucher") {

          //   this.portalService.verifyAccessCode(null, null, this.data.pass, this.shareddata.eventid, this.data.status, this.authService.currentUser._id).subscribe((res: any) => {
          //     this.userService.getUserById()
          //     if (res && res.status == "success") {
          //       this.dataAlert =
          //       {
          //         eventid: this.shareddata.eventid,
          //         code: true,
          //         passes: true,
          //         alertPopup: true,


          //       }
          //       this.OutPutDataShared.emit(this.dataAlert);

          //     }
          //     else {
          //       this.dataAlert =
          //       {
          //         passes: false,
          //         alertPopup: true,


          //       }
          //       this.OutPutDataShared.emit(this.dataAlert);

          //     }
          //   });
          // }
        }
      }
    }
  }
  Login() {
    this.authData =
    {
      popup: "login",
      eventid: this.shareddata.eventid,
      status: this.data.status,
      type: this.data.type,
    }
    this.login = true
  }
  back() {
    this.dialogRef.close()

  }
  showVerificationPopup() {
    this.pre_verif = false
  }
  resendverification() {
    if (this.authService.currentUser && this.authService.currentUser._id) {

      this.authService.resendverification(this.authService.currentUser.email).subscribe((res) => {
        this.resendVerifyMessage = [true, "the verification message has been sent to you ..."]
        this.resend = false
        setTimeout(() => {
          this.resend = true
          this.resendVerifyMessage = [false, ""]
        }, 20000);
      })
    }
  }
}