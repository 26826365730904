import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import jwt_decode from 'jwt-decode';
const CountdownTimeUnits: Array<[string, number]> = [
  ['Y', 1000 * 60 * 60 * 24 * 365], // years
  ['Mo', 1000 * 60 * 60 * 24 * 30], // months
  ['D', 1000 * 60 * 60 * 24], // days
  ['H', 1000 * 60 * 60], // hours
  ['M', 1000 * 60], // minutes
  ['S', 1000], // seconds
  // ['S', 1], // million seconds
];
@Injectable({
  providedIn: 'root'
})
export class DataService {
  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  difftimeinsec = 0;
  moreThan24Hours;
  duration = 0;
  sameday = false
  public eventStatus;
  public eventid ;
  public event;
  public access;
  public code = "";
  public temporaryUserCurrentEmail = "";
  public onchangePath =  new BehaviorSubject(true);
  currentonchangePath = this.onchangePath.asObservable();

  public accesspopup = new BehaviorSubject('');
  currentaccesspopup = this.accesspopup.asObservable();

  public sharedevent = new BehaviorSubject('');
  currentsharedevent = this.sharedevent.asObservable();

  public backgroundColor = new BehaviorSubject('#1212122b');
  currentbackgroundColor = this.backgroundColor.asObservable();
  
  public verified = new BehaviorSubject(false);
  currentverifiedStatus = this.verified.asObservable(); 

  public UserStatus = new BehaviorSubject('verified');
  currentUserStatus = this.UserStatus.asObservable();

  public navbar_height = new BehaviorSubject(75);
  currentHeight = this.navbar_height.asObservable();
   
  public auth_active_popup = new BehaviorSubject("login");
  current_auth_active_popup = this.auth_active_popup.asObservable();

  public myUser = new BehaviorSubject('');
  currentUser = this.myUser.asObservable();
  private email = new BehaviorSubject('');
  currentemail = this.email.asObservable();
  
  private role = new BehaviorSubject(localStorage.getItem('currentrole'));
  currentrole = this.role.asObservable();


 
  subjectNotifier: Subject<null> = new Subject<null>();
  constructor() { }
 
  convertDate(startdate, enddate, utcoffset) {
    if (!startdate && !enddate) {
    } else {
      let starttime = this.convertTimeToAM_PM(startdate.split('T')[1].slice(0, 5));
      let endtime = this.convertTimeToAM_PM(enddate.split('T')[1].slice(0, 5));
      startdate = startdate.split('T')[0].split("-");
      enddate = enddate.split('T')[0].split("-");
      let newdate = ["", "", ""];
      newdate[0] = this.months[startdate[1] - 1].slice(0, 3) + " " + startdate[2].slice(0, 2) + " " + starttime
      if (startdate[2].slice(0, 2) == enddate[2].slice(0, 2)) {
        this.sameday = true
        newdate[1] = this.months[enddate[1] - 1].slice(0, 3) + " " + enddate[2].slice(0, 2) + " " + endtime
      }
      else {
        this.sameday = false
        newdate[1] = this.months[enddate[1] - 1].slice(0, 3) + " " + enddate[2].slice(0, 2) + " " + endtime
      }
      newdate[2] = this.convertUtCoffset(utcoffset)
      return newdate;
    }
  }
  convertTimeToAM_PM(time) {
    if (time) {
      let hour = time.split(':')[0]
      if (hour == '00') {
        return ("12:" + time.split(':')[1] + "am")
      }
      else if (Number(hour) == 12) {
        return ("12:" + time.split(':')[1] + "pm")
      }
      else if (Number(hour) > 12) {
        return (Number(hour - 12) + ":" + time.split(':')[1] + "pm")
      }
      else if (hour < 12) {
        if (hour < 10) {
          return (hour[1] + ":" + time.split(':')[1] + "am")
        }
        else {
          return (time + "am")
        }
      }
    }
  }
  convertUtCoffset(utcoffset) {
    let convertedUTC = ["", "", "+"]
    if (utcoffset) {
      if (utcoffset.includes('-')) {
        convertedUTC[2] = ''
      }
      let utc = utcoffset.split('.')
      convertedUTC[0] = utc[0]
      if (!utc[1]) {
        convertedUTC[1] = "00"
      }
      else if (utc[1].length == 1 && utc[1] != '5') {
        convertedUTC[1] = utc[1] + "0"
      }
      else if (utc[1].length == 1 && utc[1] == '5') {
        convertedUTC[1] = "30"
      }
      else if (utc[1].length == 2) {
        convertedUTC[1] = utc[1]
      }
      if (!utc[1]) {
        return convertedUTC[2] + convertedUTC[0];
      }
      else {
        return convertedUTC[2] + convertedUTC[0] + ":" + convertedUTC[1]
      }
    }
  }

  onChangePath(value)
  {
    this.onchangePath.next(value)
  }
  changeHeight(value) 
  {
    this.navbar_height.next(value)
  }
  changeActivePopup(popup)
  {
    
    this.auth_active_popup.next(popup)
  }
  changeNavBarColor(color)
  {
    this.backgroundColor.next(color)
  }
  notifyAboutChange(notif) {
    this.subjectNotifier.next(notif);
  }
  getEventPassword(pwd)
  {
    this.code =pwd
  }
  getUserStatus(status)
  { 
    this.UserStatus.next(status)
  }
  changeEventStatus(status)
  {
    this.eventStatus = status
  }
  getEvent(event)
  {
     this.event = event
     this.sharedevent.next(event)
  }
  getAccessPopup(acess)
  {
     
     this.accesspopup.next(acess)
  }
  verifiedStatus(status)
  {
    
    this.verified.next(status)
  }
  

  getAccess(access)
  {
     this.access = access
  }


// ***********************************************************************************
  getCurrentUser(user){
    this.myUser.next(user);
  }
  changeEmail(email: string) {
    this.email.next(email)
  }
  changeEventId(eventID: string )
  {
     this.eventid = eventID
  }
  changeRole(role: string) {
    this.role.next(role)
  }





  
}
