import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import jwt_decode from 'jwt-decode';
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthentificationSystemService } from '../authentification-system/authentification-system.service';
import { DataService } from './data.service';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  url: string = environment.url
  onChangedUser: BehaviorSubject<any>;
  onChangedTemporaryUser: BehaviorSubject<any>;
  onChangedRoleEventById: BehaviorSubject<any>;
  currentUser
  params;
  data;
  constructor(private location: Location, private http: HttpClient, private router: Router, private authService: AuthentificationSystemService, private cookieService: CookieService, public shareddata: DataService) {
    this.onChangedUser = new BehaviorSubject([]);
    this.onChangedTemporaryUser = new BehaviorSubject([]);
    this.onChangedRoleEventById = new BehaviorSubject([]);

  }
  resolve(route: ActivatedRouteSnapshot) {
    return new Promise<void>((resolve, reject) => {
      this.params = route.params;
      this.data = route.data;
      Promise.all([
        this.getUserById()
      ]).then(
        () => {
          resolve();
        },
        (reject) => {
        }
      );
    });
  }
  handleError = (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) {
    }
    else {

      if (error.status == 401) {
        this.router.navigateByUrl('/login');
      }

      return throwError('Something bad happened; please try again later.');
    }
  }
  getUserById() {

    if (this.cookieService.get('token')) {
      const decodedToken: any = jwt_decode(this.cookieService.get('token'));
      const userId = decodedToken.data._id;
      return new Promise((resolve, reject) => {

        if (this.cookieService.get("usertype") == "User") {
          this.http.get(this.url + "/users/id/" + userId, { withCredentials: true })
            .pipe(catchError(this.handleError))
            .subscribe((response: any) => {
              if (response.status == "success") {

                this.onChangedUser.next(response.user);
                this.getRoleByEventid()
                this.authService.currentUser = response.user
              }
              resolve(response);
            }, reject);
        }
        if (this.cookieService.get("usertype") == "temporaryUser") {

          this.http.get(this.url + "/temporary-users/id/" + userId, { withCredentials: true })
            .pipe(catchError(this.handleError))
            .subscribe((response: any) => {
              if (response.status == "success") {
                this.onChangedUser.next(response.temporary_user);
                this.authService.currentUser = response.temporary_user
              }
              resolve(response);
            }, reject);
        }

      });
    }
  }
  getRoleByEventid() {

    if (this.data && this.data.title && this.data.title == 'Portal Event') {

      return new Promise<void>((resolve, reject) => {
        let userId
        if (this.cookieService.get('token')) {
          const decodedToken: any = jwt_decode(this.cookieService.get('token'));
          userId = decodedToken.data._id;
          let headers;
          if (userId && userId != "") {
            headers = { userid: userId }
          }
          if (this.params.eventid) {
            this.http
              .get(
                this.url + "/events/role/" + this.params.eventid, { headers: headers, withCredentials: true }
              )
              .subscribe((response: any) => {
                this.onChangedRoleEventById.next(response);
                resolve(response);
              }, reject);

          }

        }



      });
    }

  }
}
