import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import jwt_decode from 'jwt-decode';
import * as CryptoJS from 'crypto-js';
import { PortalService } from 'src/app/portal/portal.service';
import { DataService } from 'src/app/services/data.service';
import * as lodash from 'lodash';
import { UserService } from 'src/app/services/user.service';
import { AuthentificationSystemService } from '../authentification-system.service';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { NbDialogService } from '@nebular/theme';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.css']
})
export class LoginPopupComponent implements OnInit {
  @Output() OutPutData = new EventEmitter();
  @Input() data;
  activate: boolean = true;
  verification: boolean = false;
  hide: boolean = false;
  haveAccount: boolean = false;
  submitted
  loginForm: FormGroup;
  DecodedToken;
  loginMessage = ["", ""]
  Event: any;
  dataVerif: any;
  dataAlert: any;
  showAlert_Popup: boolean = false;
  invited: boolean = false;
  dataAccess: any;
  showAccess_Popup: boolean = false;
  event_as_attendee;
  message;
  userPass: any;
  access: any;
  dataAuth: {};
  changeEmail: boolean;
  constructor(private authService: AuthentificationSystemService, public portalService: PortalService, public shareddata: DataService, public dialogRef: NbDialogRef<LoginPopupComponent>, public dialog: NbDialogService, private userService: UserService,
    private cookieService: CookieService, private toastrService: NbToastrService) { }
  ngOnInit(): void {    
    this.loginForm = new FormGroup({
      email: new FormControl("", [Validators.email, Validators.required]),
      pwd: new FormControl("", [Validators.required]),
      remember_me: new FormControl(""),
    });
    this.Event = this.shareddata.event;
    this.access = this.shareddata.access
    if (this.data.status == "email" || this.data.emailLogin || this.data.changeEmail) {

      this.activate = false
      if (this.data.changeEmail) {
        this.changeEmail = true
      }
      this.OutPutData.emit({ emailPopup: true })
    }
    else {
      if (this.data.loginType == "auth" || this.data.loginType == "login" || this.data.popup == "login") {
        this.activate = true
      }
    }
    if (this.data.status == "email" || this.data.emailLogin || this.data.changeEmail) { this.hide = false }
    else if (this.data.loginType == "login" || this.data.signType == "signup") this.hide = true
    this.shareddata.currentaccesspopup.subscribe((popup) => {
      if (popup && popup == "email") {
        this.activate = false
        this.data.status = "email"
      }
      else if (popup && popup == "login") {
        this.activate = true
      }
    })
  }
  @HostListener('keyup', ['$event']) onKeyDown(e: any) {
    e.preventDefault();
    if (this.formControls.email.errors || this.formControls.pwd.errors || this.loginMessage[0] == 'error') {
      this.loginMessage = ["", ""]
    }
  }
  get formControls() {
    return this.loginForm.controls;
  }
  verifyUserRole() {
    let events_as_exhibitor;
    let event_as_speaker
    if (this.authService.currentUser) {
      if (this.cookieService.get("usertype") == "User") {
        if (this.authService.currentUser.role.includes("agency")) {
          this.message = "You already have an access as agency!"
        }
        else if (this.authService.currentUser.role.includes("attendee")) {
          this.event_as_attendee = lodash.find(this.authService.currentUser.events_as_attendee, { 'event_id': this.shareddata.eventid });
          this.message = "You already have a pass!"
        }
        else if (this.authService.currentUser.role.includes("exhibitor")) {
          events_as_exhibitor = lodash.find(this.authService.currentUser.events_as_exhibitor, { 'event_id': this.shareddata.eventid });
          this.message = "You already have an access as exhibitor!"
        }
        else if (this.authService.currentUser.role.includes("speaker")) {
          event_as_speaker = lodash.find(this.authService.currentUser.events_as_speaker, { 'event_id': this.shareddata.eventid });
          this.message = "You already have an access as speaker!"
        }
      }
      else if (this.cookieService.get("usertype") == "temporaryUser") {
        this.event_as_attendee = lodash.find(this.authService.currentUser.events, { 'event_id': this.shareddata.eventid });
      }
      if (this.authService.currentUser.role.includes("agency") || this.event_as_attendee || event_as_speaker || events_as_exhibitor) {
        this.invited = true
        this.userPass = this.event_as_attendee;
      }
      else {
        this.invited = false
      }
    }
  }
  login() {
    this.submitted = true
    let finalemail = this.loginForm.controls['email'].value
    finalemail = finalemail.trim()
    finalemail = finalemail.replace(/\r?\n|\r/g, "")
    this.loginForm.controls['email'].setValue(finalemail)
    // simple Authentification
    if (this.data.loginType == "login" && !this.changeEmail) {
      var encryptedpwd = CryptoJS.AES.encrypt(this.loginForm.controls['pwd'].value, 'ourkey').toString();
      if (this.loginForm.invalid) {
        return
      }
      else {
        this.authService.login({ email: this.loginForm.controls['email'].value, pwd: encryptedpwd }).then(data => {
          if (data['accessToken']) {
            this.dialogRef.close()
            this.DecodedToken = jwt_decode(data['accessToken']);
            this.userService.onChangedUser.next(this.DecodedToken.data)
            if (this.shareddata.event && this.shareddata.event._id) {
              this.portalService.verifyToJoinDashboardEvent(this.shareddata.code, this.shareddata.event._id, this.shareddata.access.access, this.DecodedToken.data._id).then((res: any) => {
                if (res && res.status == "success") {
                  this.userService.getUserById().then((userData: any) => {
                    this.dataAlert =
                    {
                      code: true,
                      passes: true,
                      alertPopup: true,
                    }
                    this.OutPutData.emit(this.dataAlert);
                  })
                }
              })
            }
          }
          else {
            this.loginMessage = ['error', data['message']]
          }
        })
      }
    }
    // event acess restricted with auth
    else if ((this.data.status == "auth" || this.activate)) {
      if (this.loginForm.invalid) {
        return
      }
      else {
        var encryptedpwd = CryptoJS.AES.encrypt(this.loginForm.controls['pwd'].value, 'ourkey').toString();
        this.authService.login({ email: this.loginForm.controls['email'].value, pwd: encryptedpwd }).then(data => {
          // sessionStorage.setItem("usertype", "User")
          // this.cookieService.set( 'usertype', 'User' );
          if (data['accessToken']) {
            // sessionStorage.setItem('token', data['accessToken']);
            // this.authService.setUserLoggedIn(true)
            this.DecodedToken = jwt_decode(data['accessToken']);
            //  this.cookieService.set( 'token', data['accessToken'],this.DecodedToken.exp );
            // this.cookieService.set('exp', this.DecodedToken.exp );
            if (this.shareddata.event && !this.shareddata.event.is_inviteonly) {
              if (this.shareddata.access && this.shareddata.access.access == "auth" && this.shareddata.access.protected == "false") {
                this.portalService.verifyToJoinDashboardEvent("", this.shareddata.event._id, this.shareddata.access.access, this.DecodedToken.data._id).then((res: any) => {
                  if (res && res.status == "success") {
                    this.userService.getUserById().then((userData: any) => {
                      this.dataAlert =
                      {
                        code: true,
                        passes: true,
                        alertPopup: true,
                      }
                      this.OutPutData.emit(this.dataAlert);
                    })
                  }
                })
              }
              else if (!this.data.pass) {

                this.userService.onChangedUser.next(this.DecodedToken.data)
                this.verifyUserRole();
                if (!this.invited) {
                  this.OutPutData.emit({ close: true });
                }
                else {
                  if (this.userPass && this.userPass.pass && this.userPass.pass.name) {
                    var ticket = this.userPass.pass;
                    this.dataAlert =
                    {
                      message: "You already have a pass for this event",
                      eventid: this.shareddata.eventid,
                      ticket: ticket,
                      passes: true,
                      alertPopup: true,
                    }
                    this.OutPutData.emit(this.dataAlert);
                  }
                  else {
                    this.dataAlert =
                    {
                      message: "You already have a pass for this event",
                      eventid: this.shareddata.eventid,
                      code: true,
                      passes: true,
                      alertPopup: true,
                    }
                    this.OutPutData.emit(this.dataAlert);
                  }
                }
              }
              else if (this.data.pass) {
                if (this.data.type == "ticket") {
                  this.portalService.verifyAccessCode(null, this.data.pass, null, this.shareddata.eventid, this.data.status, this.DecodedToken.data._id).subscribe((res: any) => {
                    if (res && res.status == "success") {
                      this.userService.getUserById().then((res1: any) => {
                        var event_as_attendee = lodash.find(this.authService.currentUser.events_as_attendee, { 'event_id': this.shareddata.eventid });
                        var ticket = event_as_attendee.pass;
                        this.dataAlert =
                        {
                          message: res.message,
                          eventid: this.shareddata.eventid,
                          ticket: ticket,
                          passes: true,
                          alertPopup: true,
                        }
                        this.OutPutData.emit(this.dataAlert);
                      });
                    }
                    else {
                      this.userService.getUserById()
                      if (res.message == "You already have a pass for this event." || res.message == "You already have a ticket for this event.") {
                        this.userService.onChangedUser.next(this.DecodedToken.data)
                        var event_as_attendee = lodash.find(this.DecodedToken.data.events_as_attendee, { 'event_id': this.shareddata.eventid });
                        var ticket = event_as_attendee.pass;
                        this.dataAlert =
                        {
                          message: res.message,
                          eventid: this.shareddata.eventid,
                          ticket: ticket,
                          passes: true,
                          alertPopup: true,
                          showNavBar: true
                        }
                        this.OutPutData.emit(this.dataAlert);
                      }
                      else if (res.message == "As an agency  you don't need a pass." || res.message == "As an Admin you don't need a pass."
                        || res.message == "As a speaker, you already have access to this event you don't need a pass."
                        || res.message == "As an exhibitor, you already have access to this event you don't need a pass.") {
                        this.dataAlert =
                        {
                          eventid: this.shareddata.eventid,
                          message: res.message,
                          passes: true,
                          alertPopup: true,
                          showNavBar: true
                        }
                        this.OutPutData.emit(this.dataAlert);
                      }
                      else if (res.message == "Sorry No passes left.") {
                        this.dataAlert =
                        {
                          eventid: this.shareddata.eventid,
                          message: res.message,
                          passes: false,
                          alertPopup: true,
                          showNavBar: true
                        }
                        this.OutPutData.emit(this.dataAlert);
                      }
                    }
                  })
                }
                else if (this.data.type == "code") {
                  this.portalService.verifyAccessCode(this.data.pass, null, null, this.shareddata.eventid, this.data.status, this.DecodedToken.data._id).subscribe((res: any) => {
                    if (res && res.status == "success") {
                      this.userService.getUserById().then((res1) => {
                        this.dataAlert =
                        {
                          eventid: this.shareddata.eventid,
                          message: res.message,
                          passes: true,
                          alertPopup: true,
                          showNavBar: true
                        }
                        this.OutPutData.emit(this.dataAlert);
                      });
                    }
                    else {
                      this.userService.onChangedUser.next(this.DecodedToken.data)
                      if (res.message !== "Sorry No passes left.") {
                        this.dataAlert =
                        {
                          eventid: this.shareddata.eventid,
                          message: res.message,
                          passes: true,
                          alertPopup: true,
                          showNavBar: true
                        }
                        this.OutPutData.emit(this.dataAlert);
                      }
                      else {
                        this.dataAlert =
                        {
                          eventid: this.shareddata.eventid,
                          message: res.message,
                          passes: false,
                          alertPopup: true,
                          showNavBar: true
                        }
                        this.OutPutData.emit(this.dataAlert);
                      }
                    }
                  })
                }
                else if (this.data.type == "voucher") {
                  this.portalService.verifyAccessCode(null, null, this.data.pass, this.shareddata.eventid, this.data.status, this.DecodedToken.data._id).subscribe((res: any) => {
                    if (res && res.status == "success") {
                      this.userService.getUserById().then((res) => {
                        this.dataAlert =
                        {
                          eventid: this.shareddata.eventid,
                          code: true,
                          passes: true,
                          pass: this.data.pass,
                          alertPopup: true,
                          showNavBar: true
                        }
                        this.OutPutData.emit(this.dataAlert);
                      });
                    }
                    else {
                      this.userService.onChangedUser.next(this.DecodedToken.data)
                      if (res.message !== "Sorry No tickets left.") {
                        this.dataAlert =
                        {
                          eventid: this.shareddata.eventid,
                          message: res.message,
                          passes: true,
                          alertPopup: true,
                          showNavBar: true
                        }
                        this.OutPutData.emit(this.dataAlert);
                      }
                      else {
                        this.dataAlert =
                        {
                          eventid: this.shareddata.eventid,
                          message: res.message,
                          passes: false,
                          alertPopup: true,
                          showNavBar: true
                        }
                        this.OutPutData.emit(this.dataAlert);
                      }
                    }
                  })
                }
              }
            }
            else {
              this.userService.onChangedUser.next(this.DecodedToken.data)
              if (this.cookieService.get("token")) {
                if (this.DecodedToken.data.status == "verified") {
                  this.verifyUserRole()
                  setTimeout(() => {
                    // if (this.invited) {
                    //   if (this.shareddata.access && this.shareddata.access.protected == "true") {
                    //     if (this.shareddata.event.pass.type == "ticket") {
                    //       let ticket = this.event_as_attendee.pass;
                    //       this.dataAlert = {
                    //         eventid: this.shareddata.eventid,
                    //         message: this.message,
                    //         ticket: ticket,
                    //         passes: true,
                    //         alertPopup: true,
                    //         showNavBar: true
                    //       }
                    //     }
                    //     else if (this.shareddata.event.pass.type == "code") {
                    //       this.dataAlert = {
                    //         eventid: this.shareddata.eventid,
                    //         message: this.message,
                    //         code: true,
                    //         passes: true,
                    //         alertPopup: true,
                    //         showNavBar: true
                    //       }
                    //     }
                    //     this.OutPutData.emit(this.dataAlert);
                    //   }
                    //   else {
                    //     this.dataAlert = {
                    //       code: true,
                    //       passes: true,
                    //       alertPopup: true,
                    //       showNavBar: true
                    //     }
                    //     this.OutPutData.emit(this.dataAlert);
                    //   }
                    // }
                    // else (!this.invited)
                    // {
                    this.portalService.verifyInvitation(this.DecodedToken.data.email, this.shareddata.eventid).then((res: any) => {
                      if (res.status == "success") {
                        if (res.message && (res.message.includes("this user is an agency") || res.message.includes("admin"))) {
                          this.dataAlert = {
                            code: true,
                            passes: true,
                            alertPopup: true,
                          }
                          this.OutPutData.emit(this.dataAlert);
                        }
                        if (res.invitation_type == "invite_event") {
                          if (this.shareddata.access && this.shareddata.access.protected == "true") {
                            this.dataAccess = {
                              eventid: this.shareddata.eventid,
                              status: "auth",
                              type: this.Event.pass.type,
                              accessPopup: true
                            }
                            this.OutPutData.emit(this.dataAccess);
                          }
                          else {
                            this.userService.getUserById().then((res1: any) => {
                              this.dataAlert = {
                                passes: true,
                                code: true,
                                alertPopup: true
                              }
                              this.OutPutData.emit(this.dataAlert);
                            })
                          }
                        }
                        else if (res.invitation_type == "allocate_access_code") {
                          this.dataAlert =
                          {
                            code: true,
                            passes: true,
                            invited: true,
                            alertPopup: true,
                            showNavBar: true
                          }
                          this.OutPutData.emit(this.dataAlert);
                        }
                        else if (res.invitation_type == "allocate_ticket") {
                          this.dataAlert =
                          {
                            passes: true,
                            invited: true,
                            ticket: res.allocated_pass,
                            alertPopup: true,
                            showNavBar: true
                          }
                          this.showAlert_Popup = true;
                          this.showAccess_Popup = false
                          this.verification = false
                        }
                      }
                      else if (res.status == "error") {
                        this.dataAlert =
                        {
                          passes: true,
                          invited: false,
                          alertPopup: true,
                          showNavBar: true
                        }
                        this.OutPutData.emit(this.dataAlert);
                      }
                    })
                    // }
                  }, 2);
                }
                else {
                  this.dataVerif = {
                    invited: true,
                    email: this.DecodedToken.data.email,
                    verifPopup: true
                  }
                  this.OutPutData.emit(this.dataVerif);
                }
              }
            }
          }
          else {
            this.loginMessage = ['error', data['message']]
          }
        });
      }
    }
    else {
      if (this.loginForm.controls['email'].invalid) return;
      else {
        this.authService.loginViaEmail({ email: this.loginForm.controls['email'].value }).subscribe((data: any) => {
          if (data['accessToken']) {
            this.DecodedToken = jwt_decode(data['accessToken']);
            let event
            let eventuser = this.DecodedToken.data
            event = lodash.find(eventuser, { 'event_id': this.shareddata.eventid });
            if (this.shareddata.event && !this.changeEmail) {
              if (!this.shareddata.event.is_inviteonly) {
                if (!this.data.pass && this.shareddata.access && this.shareddata.access.protected == "false") {
                  this.portalService.verifyToJoinDashboardEvent("", this.shareddata.eventid, this.shareddata.access.access, this.DecodedToken.data._id).then((res: any) => {
                    if (res && res.status == "success") {
                      this.userService.getUserById().then(res1 => {
                        if (event && event.status == "verified") {
                          this.dataAlert =
                          {
                            eventid: this.shareddata.eventid,
                            passes: true,
                            code: true,
                            alertPopup: true,
                          }
                          this.OutPutData.emit(this.dataAlert);
                        }
                        else {
                          this.dataVerif =
                          {
                            verif: "email",
                            email: this.loginForm.controls['email'].value,
                            passes: true,
                            verifPopup: true
                          }
                          this.OutPutData.emit(this.dataVerif)
                        }
                      })
                    }
                  })
                }
              }
              else {
                if (this.cookieService.get("token")) {
                  this.portalService.verifyInvitation(this.DecodedToken.data.email, this.shareddata.eventid).then((res: any) => {
                    if (res.status == "success") {
                      this.userService.getUserById().then((res1: any) => {
                        event = lodash.find(res1.temporary_user.events, { 'event_id': this.shareddata.eventid });
                        if (!event) {
                          if (res.invitation_type == "invite_event") {
                            if (this.shareddata.access && this.shareddata.access.protected == 'false') {
                              this.dataAccess = {
                                eventid: this.shareddata.eventid,
                                status: "auth",
                                type: this.Event.pass.type,
                                accessPopup: true
                              }
                              this.OutPutData.emit(this.dataAccess);
                            }

                          }

                        }
                        else {
                          this.userService.onChangedUser.next(this.DecodedToken.data)
                          if (res.invitation_type == "invite_event") {
                            this.dataAlert =
                            {
                              code: true,
                              passes: true,
                              alertPopup: true,
                              message: "Welcome!"
                            }
                            this.OutPutData.emit(this.dataAlert);
                          }
                        }
                      })
                    }
                    else if (res.status == "error") {
                      if (res.message.includes("please verify")) {
                        this.userService.getUserById().then((res1) => {
                          if (this.Event.pass) {
                            this.dataVerif = {
                              invited: true,
                              email: this.DecodedToken.data.email,
                              verif: "email",
                              type: this.Event.pass.type,
                              verifPopup: true
                            }
                            this.OutPutData.emit(this.dataVerif);
                          }
                          else {
                            this.dataVerif = {
                              invited: true,
                              email: this.DecodedToken.data.email,
                              verif: "email",
                              verifPopup: true
                            }
                            this.OutPutData.emit(this.dataVerif);
                          }
                        })
                      }
                      else {
                        this.dataAlert =
                        {
                          passes: true,
                          invited: false,
                          alertPopup: true
                        }
                        this.OutPutData.emit(this.dataAlert);
                      }
                    }
                  })
                }
              }

            }
            else {
              this.userService.onChangedUser.next(this.DecodedToken.data)
              this.dialogRef.close()
            }
          }
          else {
            this.loginMessage = ['error', data['message']]
            if (data['message'] == "this email is already related to a user") {
              this.OutPutData.emit({ authPopup: true })
              this.activate = true
              this.loginMessage = ["", ""];
              this.data.status = "auth"
              let finalemail = this.loginForm.controls['email'].value
              finalemail = finalemail.trim()
              finalemail = finalemail.replace(/\r?\n|\r/g, "")
              this.loginForm.controls['email'].setValue(finalemail)
            }
          }
        });
      }
    }
  }
  signup() {
    let data;
    if (this.data.loginType == "login") {
      data =
      {
        popup: "signup",
        eventid: this.shareddata.eventid,
        signType: "signup"
      }
    }
    else {
      data =
      {
        popup: "signup",
        eventid: this.shareddata.eventid,
        status: this.data.status,
        auth: this.data.status,
        type: this.data.type,
        pass: this.data.pass,
        ticket: this.data.ticket
      }
    }
    this.OutPutData.emit(data);
  }
  showLoginFormulaire() {
    this.activate = true
    this.loginMessage = ["", ""];
    this.data.status = "auth"
    if (this.haveAccount) {
      let finalemail = this.loginForm.controls['email'].value
      finalemail = finalemail.trim()
      finalemail = finalemail.replace(/\r?\n|\r/g, "")
      this.loginForm.controls['email'].setValue(finalemail)
    }
    else {
      this.loginForm.reset();
    }
    this.haveAccount = false
    this.OutPutData.emit({ authPopup: true })
  }
  forgotPassword() {
    this.loginMessage = ["", ""]
    this.dataAuth =
    {
      forgetPwdPopup: true
    }
    this.OutPutData.emit(this.dataAuth);
  }
  keyDownFunctionLogin(e) {
    this.login();
    this.submitted = true
  }
  connectWithSocialmedia(socialmedia) {
    //   window.open("this.url/users/" + socialmedia, "_self")
    //   if (this.data && this.data.id) {
    //     // sessionStorage.setItem('go_to_event', this.data.id)
    //   }
    //   if (this.data && this.data.ticketid) {
    //     // sessionStorage.setItem('ticketid', this.data.ticketid)
    //   }
  }
  back() {
    if (this.showAccess_Popup) {
      this.dialogRef.close()
    }
  }
  Email() {
    this.activate = false
  }
}