<div *ngIf="!loading && !newSpeakersLoading" class="speakers" id="speakers1">
  <div class="speaker-card" *ngFor="let speaker of speakers">
    <div class="speaker-pic">
      <div class="speaker-img" [style.background-image]="'url('+speaker.photo+')'"><img style="visibility: hidden;" [src]="speaker.photo"></div>
      <div class="speaker-role tagsText">
        Speaker
      </div>
      <div class="speaker-infos">
        <div id="name" class="smallText">{{speaker.firstname | titlecase}} {{speaker.lastname | titlecase}}</div>
        <div id="position" class="paragraphe Quicksand-medium" >{{speaker.title | titlecase}}
        </div>
      </div>
    </div>
    <div style="align-self: center;">
      <a [href]="speaker.linkedin" style="text-decoration: none; color: white; " target="_blank">
        <div class="icon-in">
        </div>
      </a>
    </div>
  </div>
  <!-- <div *ngIf="!loading && !newSpeakersLoading && current_number_speakers < total_speakers && total_speakers > 6 && moreCard " class="more-speaker-card">
    <button class="more-speaker-button tagsText" (click)="showMoreSpeaker()">{{speakersLeft}}</button>
  </div> -->
</div>
<div *ngIf="loading && !newSpeakersLoading" class="speakers">
  <div class="speaker-card" *ngFor="let speaker of speakers">
    <div class="speaker-pic">
      <div class="speaker-img"></div>
      <div class="speaker-role smallText">
      </div>
    </div>
    <div class="speaker-infos">
      <div id="name" class="smallText"></div>
      <div id="position" class="smallText"></div>
      <div style="align-self: center;">
        <!-- <div style="align-self: center;">
          <a [href]="speaker.linkedin" style="text-decoration: none; color: white; " target="_blank">
            <div class="icon-in">
            </div>
          </a>
        </div> -->
      </div>
    </div>
  </div>
</div>
<div *ngIf="newSpeakersLoading" class="speakers">
  <div *ngIf="newSpeakersLoaded" style="width: 100%;display: inline-flex;flex-wrap: wrap;">
    <div class="speaker-card" *ngFor="let speaker of speakers">
      <div class="speaker-pic">
        <div class="speaker-img" [style.background-image]="'url('+speaker.photo+')'"></div>
        <div class="speaker-role smallText">
          Speaker
        </div>
      </div>
      <div class="speaker-infos">
        <div id="name" class="smallText">{{speaker.firstname | titlecase}} {{speaker.lastname | titlecase}}</div>
        <div id="position" class="paragraphe">{{speaker.title | titlecase}}</div>
        <a [href]="speaker.linkedin" style="text-decoration: none; color: white; " target="_blank"><i
            class="fa fa-linkedin-square" aria-hidden="true"></i></a>
      </div>
    </div>
    <div class="speaker-card" *ngFor="let speaker of newSpeakers">
      <div class="speaker-pic">
        <div class="speaker-img"></div>
        <div class="speaker-role smallText"></div>
      </div>
      <div class="speaker-infos">
        <div id="name" class="smallText"></div>
        <div id="position" class="smallText"></div>
        <!-- <div style="align-self: center;">
          <a [href]="speaker.linkedin" style="text-decoration: none; color: white; " target="_blank">
            <div class="icon-in">
            </div>
          </a>
        </div> -->
      </div>
    </div>
  </div>
  <div *ngIf="!newSpeakersLoaded" style="width: 100%;display: inline-flex;flex-wrap: wrap;" id="speakers2">
    <div class="speaker-card" *ngFor="let speaker of speakers">
      <div class="speaker-pic">
        <div class="speaker-img" [style.background-image]="'url('+speaker.photo+')'"></div>
        <div class="speaker-role smallText">
          Speaker
        </div>
      </div>
      <div class="speaker-infos">
        <div id="name" class="smallText">{{speaker.firstname | titlecase}} {{speaker.lastname | titlecase}}</div>
        <div id="position" class="paragraphe">{{speaker.title | titlecase}}</div>
        <a [href]="speaker.linkedin" style="text-decoration: none; color: white; " target="_blank"><i
            class="fa fa-linkedin-square" aria-hidden="true"></i></a>
      </div>
    </div>
    <div class="speaker-card" *ngFor="let speaker of newSpeakers">
      <div class="speaker-pic">
        <div class="speaker-img" [style.background-image]="'url('+speaker.photo+')'"></div>
        <div class="speaker-role smallText">
          Speaker
        </div>
      </div>
      <div class="speaker-infos">
        <div id="name" class="smallText">{{speaker.firstname | titlecase}} {{speaker.lastname | titlecase}}</div>
        <div id="position" class="paragraphe">{{speaker.title | titlecase}}</div>
        <div style="align-self: center;">
          <a [href]="speaker.linkedin" style="text-decoration: none; color: white; " target="_blank">
            <div class="icon-in">
            </div>
          </a>
        </div>
      </div>
    </div>
    <!-- <div *ngIf="current_number_speakers < total_speakers && total_speakers > 6 && moreCard " class="more-speaker-card">
      <button class="more-speaker-button smallText" (click)="showMoreSpeaker()">{{speakersLeft}}</button>
    </div> -->
  </div>

</div>


<div *ngIf="current_number_speakers < total_speakers && total_speakers > 6" class="showMoreSpeaker"
  id="showMoreSpeaker">

  <button class="showMoreBtn smallText" (click)="showMoreSpeaker()"> SHOW MORE</button>
</div>

<div *ngIf="!newSpeakersLoaded && show_speakers_bool && current_number_speakers >= total_speakers "
  class="showMoreSpeaker">
  <button class="reduceBtn smallText" (click)="reduceSpeakers()"> REDUCE</button>
</div>


<!-- *******************************testimonials******************************* -->

<div *ngIf="testimonials.length > 0" class="testimonials-container">
  <div id="label-testimonial" class="subTitle"> Testimonials </div>
  <div *ngIf="!loading && !newTestimonialsLoading" class="testimonials">
    <div *ngFor="let testimonial of testimonials ; let i = index" class="testimonial">
      <div class="testimonial-img" [style.background-image]="'url('+testimonial.speaker.photo+')'"><img style="visibility: hidden;" [src]="testimonial.speaker.photo"></div>
      <div class="testimonial-details">
        <div class="testimonial-header">
          <div style="display: inline-flex;">
            <div class="testimonial-speaker">
              <div class="smallText Quicksand-bold" >{{testimonial.speaker.firstname | titlecase}} {{testimonial.speaker.lastname | titlecase}}</div>
              <div class="paraghraphe  Quicksand-regular" > {{testimonial.speaker.title |
                titlecase}} </div>
            </div>
          </div>
          <div class="icon-testimonial"></div>
        </div>
        <div class="paragraphe  Quicksand-regular"
          style="position: absolute; z-index: -10; margin-bottom: 10px;  visibility: hidden;margin-top: 30px;"
          [id]="'testimonial'+i">
          {{testimonial.testimonial | titlecase}} </div>
        <div class="line-limit paragraphe  testimonial-paragraphe" [id]="i+'testimonial'"> {{testimonial.testimonial
          | titlecase}} </div>
        <div *ngIf="countLines(i)>2 && readmore" class="paragraphe readMore Quicksand-semibold" 
          (click)="readMoreTestimonial(i)">Read more </div>
        <div *ngIf="countLines(i)>2 && !readmore" class="paragraphe readMore Quicksand-semibold" 
          (click)="readMoreTestimonial(i)"> REDUCE</div>
      </div>
    </div>
  </div>
  <div *ngIf="loading && !newTestimonialsLoading" class="testimonials">
    <div *ngFor="let testimonial of testimonials ; let i = index" class="testimonial">
      <div class="testimonial-img"></div>
      <div class="testimonial-details">
        <div class="testimonial-header">
          <div style="display: inline-flex;">
            <div class="testimonial-speaker">
              <div class="smallText Quicksand-bold" ></div>
              <div class="paraghraphe Quicksand-regula" > </div>
            </div>
          </div>
          <div class="icon-testimonial"></div>
        </div>
        <div class="paragraphe testimonial-paragraphe">
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="newTestimonialsLoading" class="testimonials">
    <div *ngIf="newTestimonialsLoaded">
      <div *ngFor="let testimonial of testimonials ; let i = index" class="testimonial">
        <div class="testimonial-img"></div>
        <div class="testimonial-details">
          <div class="testimonial-header">
            <div style="display: inline-flex;">
              <div class="testimonial-speaker">
                <div class="smallText Quicksand-bold" ></div>
                <div class="paraghraphe Quicksand-regular" > </div>
              </div>
            </div>
            <div class="icon-testimonial"></div>
          </div>
          <div class="paragraphe testimonial-paragraphe">
          </div>
        </div>
      </div>
      <div *ngFor="let testimonial of newTestimonials ; let i = index" class="testimonial">
        <div class="testimonial-img"></div>
        <div class="testimonial-details">
          <div class="testimonial-header">
            <div style="display: inline-flex;">
              <div class="testimonial-speaker">
                <div class="smallText Quicksand-bold" ></div>
                <div class="paraghraphe Quicksand-regular" > </div>
              </div>
            </div>
            <div class="icon-testimonial"></div>
          </div>
          <div class="paragraphe testimonial-paragraphe">
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!newTestimonialsLoaded">
      <div *ngFor="let testimonial of testimonials ; let i = index" class="testimonial">
        <div class="testimonial-img" [style.background-image]="'url('+testimonial.speaker.photo+')'"><img style="visibility: hidden;" [src]="testimonial.speaker.photo"></div>
        <div class="testimonial-details">
          <div class="testimonial-header">
            <div style="display: inline-flex;">
              <div class="testimonial-speaker">
                <div class="smallText Quicksand-bold" >{{testimonial.speaker.firstname | titlecase}} {{testimonial.speaker.lastname | titlecase}}
                </div>
                <div class="paraghraphe Quicksand-regular" > {{testimonial.speaker.title |
                  titlecase}} </div>
              </div>
            </div>
            <div class="icon-testimonial"></div>
          </div>
          <div class="paragraphe"
            style="position: absolute; z-index: -10; margin-bottom: 10px;  visibility: hidden;margin-top: 30px;font-family: Quicksand-regular;"
            [id]="'testimonial'+i">
            {{testimonial.testimonial | titlecase}} </div>
          <div class="line-limit paragraphe Quicksand-regular"
            style="margin-bottom: 10px;margin-top: 30px;" [id]="i+'testimonial'">
            {{testimonial.testimonial
            | titlecase}} </div>
          <div *ngIf="countLines(i)>2 && readmore" class="paragraphe readMore Quicksand-semibold" 
            (click)="readMoreTestimonial(i)">Read more </div>
          <div *ngIf="countLines(i)>2 && !readmore" class="paragraphe readMore Quicksand-semibold" 
            (click)="readMoreTestimonial(i)"> REDUCE</div>
        </div>
      </div>
      <div *ngFor="let testimonial of newTestimonials ; let i = index" class="testimonial">
        <div class="testimonial-img" [style.background-image]="'url('+testimonial.speaker.photo+')'"><img style="visibility: hidden;" [src]="testimonial.speaker.photo"></div>
        <div class="testimonial-details">
          <div class="testimonial-header">
            <div style="display: inline-flex;">
              <div class="testimonial-speaker">
                <div class="smallText Quicksand-bold" >{{testimonial.speaker.firstname | titlecase}} {{testimonial.speaker.lastname | titlecase}}
                </div>
                <div class="paraghraphe Quicksand-regular" > {{testimonial.speaker.title|
                  titlecase}} </div>
              </div>
            </div>
            <div class="icon-testimonial"></div>
          </div>
          <div class="paragraphe"
            style="position: absolute; z-index: -10; margin-bottom: 10px;  visibility: hidden;margin-top: 30px;font-family: Quicksand-regular;"
            [id]="'testimonial'+i">
            {{testimonial.testimonial | titlecase}} </div>
          <div class="line-limit paragraphe Quicksand-regular"
            style="margin-bottom: 10px;margin-top: 30px; " [id]="i+'testimonial'">
            {{testimonial.testimonial
            | titlecase}} </div>
          <div *ngIf="countLines(i)>2 && readmore" class="paragraphe readMore Quicksand-semibold" 
            (click)="readMoreTestimonial(i)">Read more </div>
          <div *ngIf="countLines(i)>2 && !readmore" class="paragraphe readMore Quicksand-semibold" 
            (click)="readMoreTestimonial(i)"> REDUCE</div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="!loading && !newTestimonialsLoading && current_number_testimonials < total_testimonials  && total_testimonials > 4"
    class="showMoreTestimonial" id="showMoreTestimonial"
    style="height:110px;margin-bottom: 80px;box-shadow: #0e1120 0px 500px 200px 20px inset">
    <button class="showMoreBtn smallText" (click)="showMoreTestimonials()" style="top: 100px;"> SHOW MORE</button>
  </div>
  <div *ngIf="!newTestimonialsLoaded && show_testimonials_bool && current_number_testimonials >=total_testimonials "
    class="showMoreTestimonial" id="showMoreTestimonial"
    style="height:130px;margin-bottom: 80px;box-shadow: #0e1120 0px -125px 41px 20px inset;background-image: none;">
    <button class="reduceBtn smallText" (click)="reduceTestimonials()">
      Reduce
    </button>
  </div>
</div>