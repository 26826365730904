import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthentificationSystemService } from '../authentification-system.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  @Input() data
  submitted: boolean = false;
  forgetPasswordForm: FormGroup;
  disable: boolean = false;
  message = ["", ""]
  alreadySent: boolean= false;
  constructor(private authServices: AuthentificationSystemService) {
  }

  ngOnInit(): void {
    this.forgetPasswordForm = new FormGroup({
      email: new FormControl("", [Validators.email, Validators.required]),
    });
  }
  @HostListener('keyup', ['$event']) onKeyDown(e: any) {
    e.preventDefault();
    if (this.formControls.email.errors || this.message[0] == 'error') {
      this.message = ["", ""]
    }
  }
  get formControls() {
    return this.forgetPasswordForm.controls;
  }
  changeMotPassword() {
    this.submitted = true
    let finalemail = this.forgetPasswordForm.controls['email'].value
    finalemail = finalemail.trim()
    finalemail = finalemail.replace(/\r?\n|\r/g, "")
    this.forgetPasswordForm.controls['email'].setValue(finalemail)
    if (this.forgetPasswordForm.invalid) {
      return
    }
    else {
      if (!this.disable)
      {
        this.authServices.forgetPassword(this.forgetPasswordForm.controls['email'].value).subscribe((res: any) => {
          if (res && res.status == "success") {
            this.disable = true
            this.message = ["success","An email has been sent to you !"]
  
          }
          else {
            this.message = ["error",res.message]
          }
  
        })
      }
      else 
      {
        this.alreadySent = true
      }
    }
  }
}
