<div class="DesktopVersion">
    <div class="tickets">
        <div *ngFor="let ticket of tickets" (click)="selectTicket(ticket._id,ticket.purchased,ticket.quantity)"
            class="ticket"
            [ngStyle]="{'background-color': (ticketSelected == ticket._id && ticket.quantity > ticket.purchased) ? ticket.color : (ticketSelected == ticket._id &&  ticket.quantity == ticket.purchased) ? '#A3A3A3' :(ticketSelected == ticket._id)?ticket.color:'transparent' }">
            <div [ngClass]="ticketSelected != ticket._id ?'ticket-capsule':'selected-ticket'">
                <div class="ticket-content">
                    <div class="radio-button">
                        <img *ngIf="ticket.quantity > ticket.purchased  && ticketSelected != ticket._id"
                            src="../../assets/check2.png">
                        <img *ngIf="ticket.quantity > ticket.purchased && ticketSelected == ticket._id"
                            src="../../assets/check.png">
                        <img *ngIf="ticket.quantity == ticket.purchased && ticketSelected == ticket._id"
                            src="../../assets/check3.png" style="opacity: 20%; ">
                        <img *ngIf="ticket.quantity == ticket.purchased && ticketSelected != ticket._id"
                            src="../../assets/check2.png" style="opacity: 20%; ">
                        <div [ngStyle]="{'color':(ticketSelected == ticket._id && ticket.quantity > ticket.purchased ) ? '#0E1120':'#EDF1F7'} "
                            class="OneLine Quicksand-bold smallText" style="margin-left: 5px; ">
                            {{ticket.name}}
                        </div>
                    </div>
                    <div class="ticket-info">
                        <div style="text-align: right;"
                            [ngStyle]="{'color':(ticketSelected == ticket._id && ticket.quantity > ticket.purchased ) ? '#0E1120':'#EDF1F7'} ">
                            <div *ngIf="ticket.price>0" class="smallText Quicksand-bold">{{ticket.price}}{{ticket.currency}}</div>
                            <div *ngIf="ticket.price == 0">Free</div>
                            <div *ngIf="ticket.quantity > ticket.purchased"  class=" tagsText  Quicksand-regular" [ngStyle]="{'color':(ticketSelected == ticket._id && ticket.quantity > ticket.purchased ) ? '#0E1120':'#EDF1F7'} ">
                                {{ticket.quantity - ticket.purchased }} available</div>
                            <div *ngIf="ticket.quantity == ticket.purchased" class=" tagsText Quicksand-regular">
                                Out of stock
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="ticketSelected == ticket._id && ticket.description" class="paraghraphe Quicksand-regular"
                    [ngStyle]="{'color':ticket.quantity > ticket.purchased ? 'black' :  '#707070' }"
                    style="color: #0E1120;">
                    {{ticket.description}}
                </div>
            </div>
        </div>
    </div>
    <div class="next-btn-container">
        <button class="action-btn subTitle Quicksand-bold" style="color :#141A2A;text-align:center"
            (click)="getMyPass()"
            [ngStyle]="{'background-color':(currentCount>0 && roleinevent=='attendee') ? 'white' : '#A3A3A3' , 'color':(currentCount>0 && roleinevent=='attendee')? 'black':'black' }">
            GET YOUR PASS
        </button>
    </div>
</div>