import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardEventService } from './dashboard-event/dashboard-event.service';
import { PortalComponent } from './portal/portal.component';
import { UserService } from './services/user.service';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { ResetPasswordComponent } from './authentification-system/reset-password/reset-password.component';
import { LoginPageComponent } from './authentification-system/login-page/login-page.component';
import { ConfirmInvitationComponent } from './confirm-invitation/confirm-invitation.component';
import { PlaylistPlayerComponent } from './playlist-player/playlist-player.component';
import { SessionDetailsComponent } from './session-details/session-details.component';
import { ExploreEventService } from './explore-event/explore-event.service';
import { ProfilesComponent } from './profiles/profiles.component';

const routes: Routes = [
 
  {
    path: "portal",
    // resolve: { UserService },
    data: 
    {
      title: "Portal Event"
    },
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./portal/portal.module").then((m) => m.PortalModule),
      },
    ]
  },
  {
    path: "",
    pathMatch:"full",
    data: 
    {
      title: "Explore Event"
    },
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./explore-event/explore-event.module").then((m) => m.ExploreEventModule),
      },
    ]
  },
  // {
  //   path: "**",
  
  //   data: 
  //   {
  //     title: "Explore Event"
  //   },
  //   children: [
  //     {
  //       path: "",
  //       loadChildren: () =>
  //         import("./explore-event/explore-event.module").then((m) => m.ExploreEventModule),
  //     },
  //   ]
  // },
  {
    path: "exploreevents",
    data: 
    {
      title: "Explore Event"
    },
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./explore-event/explore-event.module").then((m) => m.ExploreEventModule),
      },
    ]
  },
  {
    path: "eventinterface",
    data: 
    {
      title: "Event Interface"
    },
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./dashboard-event/dashboard-event.module").then((m) => m.DashboardEventModule),
      },
    ]
  },

  {
    path: "dashboard",
    data: 
    {
      title: "Dashboard Testing"
    },
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./dashboard-test/dashboard-test.module").then((m) => m.DashboardTestModule),
      },
    ]
  },
  {
    path: "resetpassword/:id/:hash",
    component  : ResetPasswordComponent,
    data: 
    {
      title: "reset password"
    }
  },
  {
    path: "login",
    component  : LoginPageComponent,
    data: 
    {
      title: "login"
    }
  },
  {
    path: "more/:page/:eventid/:sessionid",
    component  : SessionDetailsComponent,
    data: 
    {
      title: "more details"
    }
  },
  {
    path: "confirminvitation/:email/:eventid/:invitationcode",
    resolve: { ExploreEventService },
    component  : ConfirmInvitationComponent,
    data: 
    {
      title: "confirm invitation"
    }
  },
  {
    path: "profile",
    data: 
    {
      title: "Profiles"
    },
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./profiles/profiles.module").then((m) => m.ProfilesModule),
      },
    ]
  }
];

@NgModule({
  // imports: [RouterModule.forRoot(routes, { useHash: false })],
  imports: [RouterModule.forRoot(routes )],

  exports: [RouterModule]
})
export class AppRoutingModule { }
