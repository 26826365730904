import { Component, OnInit, ViewChildren } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { VideoPlayerComponent } from 'src/app/video-player/video-player.component';
import { ProfilesServiceService } from '../profiles-service.service';

@Component({
  selector: 'app-speaker-profile',
  templateUrl: './speaker-profile.component.html',
  styleUrls: ['./speaker-profile.component.css']
})
export class SpeakerProfileComponent implements OnInit {
  @ViewChildren('video') video: any;
  muted: boolean = false
  cover
  speaker
  sessions
  speakerSession
  speakerEvent
  constructor(private profileService: ProfilesServiceService,public dialog: NbDialogService) { }

  ngOnInit(): void {
    this.profileService.onChangeSpeaker.subscribe((res: any) => {
      if (res) {
        this.speaker = res
        if (res.additional_data && res.additional_data.cover_profile && res.additional_data.cover_profile) {
          if (res.additional_data.cover_profile.type == "video" || res.additional_data.cover_profile.type == "image") {
            this.cover = res.additional_data.cover_profile.url
          }
          else if (res.additional_data.cover_profile.type == "session") {
            if (res.additional_data.cover_profile.session_id && res.additional_data.cover_profile.session_id.cover_url) {
              this.speakerSession =  res.additional_data.cover_profile.session_id
              this.speakerEvent =  res.additional_data.cover_profile.session_id.event_id.title
              this.cover = res.additional_data.cover_profile.session_id.cover_url
            }
          }
        }
      }
    })
    this.profileService.onChangeSession.subscribe((res: any) => {
      if (res) {
        this.sessions = res
      }
    })
  }

  ngAfterViewInit() {
    let audio = document.getElementById('videoCover') as HTMLMediaElement
    if (audio) { audio.muted = true; this.muted = true }
  }
  soundOnOff(value) {
    let audio = document.getElementById('videoCover') as HTMLMediaElement
    if (value) {
      if (audio) { audio.muted = false }
      if (this.video) { this.video.muted = false }
      this.muted = false
    }
    else {
      if (audio) { audio.muted = true }
      if (this.video) { this.video.muted = true }
      this.muted = true
    }
  }
  clickTag(i) {
     
    if (document.getElementById("tag"+i)) {
      if (document.getElementById("tag"+i).style.background == "#3c465f") {
        document.getElementById("tag"+i).style.background = "#8FA7E6"
      }
      else if (document.getElementById("tag"+i).style.background == "#8FA7E6") {
        document.getElementById("tag"+i).style.background == "#3c465f"
      }
    }
  }
  openVideoPlayer()
  {
    const dialogRef = this.dialog.open(VideoPlayerComponent, {
      context: {
        data: {
          sessions : [this.speakerSession],
          index : 0,
          title : this.speakerEvent
        }
      },
      hasBackdrop: false
    }).onClose.subscribe((result) => {});
  }
  openLinkedIn() {
  }
  openTwitter() {
  }
}

