<div *ngIf="!success" style="height: 100%;">
    <div style=" width: 100%;height: 100vh; display: flex; justify-content: center;">
        <div style="overflow: auto;width: 400px;align-self: center;">
            <div class="header2" style="margin-bottom: 10%;">
                <div class="title welcome-msg">Reset your password</div>
                <div class="smallText Quicksand-regular" style="text-align: center;"> Enter a new password below. </div>
                <div style="margin-top: 40px;"><img class="logo" src="../../../assets/portal/logo.png" width="155px" />
                </div>
            </div>
            <div class="formulaire">
                <form class="form flex-column" [formGroup]="loginForm">
                    <div class="input-group">
                        <label for="email">New Password</label>
                        <input class="input-form" [ngClass]="{Error: submitted && formControls.pwd.errors}"
                            type="password" formControlName="pwd" placeholder="confirm password">
                    </div>
                    <div class="input-group">
                        <label for="pwd">Confirm The New Password</label>
                        <input class="input-form" [ngClass]="{Error: submitted && formControls.confirmpassword.errors}"
                            type="password" formControlName="confirmpassword" placeholder="password">
                    </div>
                </form>
                <div style=" width: 100%;max-width: 400px;margin-top: 10px;text-align: left;"
                    [ngClass]="loginMessage[0]=='error' ? 'ErrorMessage' : loginMessage[0]=='success'? 'SuccessMessage' : loginMessage[0]=='warn'? 'WarnMessage'  :''">
                    {{loginMessage[1]}}
                </div>
            </div>
            <div class="next-btn-container">
                <button type="submit"  class="action-btn subTitle Quicksand-bold" style="color :#141A2A;text-align:center" (click)="ResetPassword(0)">Reset Password</button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="success" style="height: 100%;">
    <app-login-page></app-login-page>
</div>
<!-- <ngx-spinner  bdColor ="#191c304f" size="default" color="#8fa7e6" type="ball-spin-clockwise" [fullScreen] = "true">
        </ngx-spinner> -->