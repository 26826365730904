import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { AuthentificationSystemService } from '../authentification-system.service';
import { NbGlobalLogicalPosition, NbGlobalPhysicalPosition, NbGlobalPosition, NbIconConfig, NbToastRef, NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  loginForm: FormGroup;
  submitted: boolean = false
  success: boolean = false
  loginMessage = ["", ""]
  errorPassword = "Please make sure your passwords match";
  errorMessage: boolean = false;
  id
  hash
  changed: any;
  physicalPositions = NbGlobalPhysicalPosition;
  logicalPositions = NbGlobalLogicalPosition;
  private index: number = 0;

  constructor(private readonly location: Location, private authServices: AuthentificationSystemService,private toastrService: NbToastrService,private router: Router) { }

  ngOnInit(): void {
    if (this.location.path().includes('resetpassword')) {
      this.id = this.location.path().split("/")[this.location.path().split("/").length - 2];
      this.hash = this.location.path().split("/")[this.location.path().split("/").length - 1];
    }
    this.loginForm = new FormGroup({
      pwd: new FormControl("", [Validators.required]),
      confirmpassword: new FormControl("", [Validators.required]),
    });
  }
  @HostListener('keyup', ['$event']) onKeyDown(e: any) {
    e.preventDefault();
    if (this.formControls.pwd.errors || this.formControls.confirmpassword.errors || this.loginMessage[0] == 'error') {
      this.loginMessage = ["", ""]
    }
  }
  get formControls() {
    return this.loginForm.controls;
  }
  ResetPassword(duration) {
    this.submitted = true
    if (this.loginForm.invalid) {
      return
    }
    else if (this.loginForm.controls["pwd"].value != this.loginForm.controls["confirmpassword"].value) {
      this.loginMessage = ["error","Please make sure your passwords match"]

      this.loginForm.controls['confirmpassword'].setErrors({ [this.errorPassword]: true })
      this.loginForm.controls['pwd'].setErrors({ [this.errorPassword]: true })
      return;
    }
    else {
        if (this.id != "" && this.hash != "") {
          var encryptedpwd = CryptoJS.AES.encrypt(this.loginForm.controls['pwd'].value, 'ourkey').toString();
          this.authServices.resetPassword(this.id, this.hash, {pwd : encryptedpwd}).subscribe((res: any) => {
            if (res && res.status=="success")
            {
              this.toastrService.show('', `password changed`, {duration,icon:"",status:"success",destroyByClick:true,preventDuplicates:true});
              this.success = true             
              // this.loginMessage = ["success","password changed"]
              this.loginForm.reset()
            }
          
            else if (res && res.status=="error")
            {
              this.success = false             

              this.loginForm.reset()             
              const toastRef: NbToastRef = this.toastrService.show('', `password has been already changed`, {duration,icon:"",status:"warning",destroyByClick:true,preventDuplicates:true});
              // this.loginMessage = ["warn","password has been already changed !"]
            }
          })
        }
    }
  }
  
}
