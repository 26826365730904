import { ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';   
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DataService } from '../services/data.service';
@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css']
})
export class DefaultLayoutComponent implements OnInit {

  isCollapsedSidebar: boolean = true;
  userprofile = false;
  eventID: any;
  hide: boolean = false;
  id
  hash
  constructor(public dataService: DataService, private route: ActivatedRoute, private router: Router,private readonly location: Location) {    
  }
  ngOnInit(): void {
   if (this.location.path().includes('resetpassword') || this.location.path().includes('confirminvitation')  || this.location.path().includes('login') || this.location.path().includes('signup'))
   {    
    this.id = this.location.path().split("/")[this.location.path().split("/").length-2];
    this.hash =  this.location.path().split("/")[this.location.path().split("/").length-1];
    this.hide = true    
   }
   else 
   {
    this.hide = false    
   }
   this.dataService.currentonchangePath.subscribe((value)=>{
    if (value && value == true)
    {
      this.hide = false 
    }
    else{
      this.hide=true
    }
    
   })
    this.route.queryParams.subscribe((params) => {
      this.eventID = params["eventid"];
    });
  }
  scrollToTop() {
    var myDiv = document.getElementById('main');
    myDiv.scrollTop = 0;
  }
  onMenuCollapsed(value: boolean) {
    this.isCollapsedSidebar = value;
  }
  onUserProfile(value: boolean) {
    this.userprofile = value;
  }
  ngAfterViewInit() {
    this.dataService.navbar_height.subscribe(height => {
      if (document.getElementById('nb-layout-column')) {
        if (height == 95) {

          document.getElementById('nb-layout-column').setAttribute('style', 'padding-top:20px !important');

        }
        else {
          if (height < 95) {
            document.getElementById('nb-layout-column').setAttribute('style', 'padding-top: 0px !important');
          }
        }
      }
    })
  }
}
