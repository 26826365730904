<div style="display:flex;justify-content: center;height: 90vh;">
    <div class="content">
        <div style="background:url(../../../assets/enveloppe.png) ; background-repeat: no-repeat;background-size: cover;    width: 95px;
    height: 84px;
    margin-bottom: 10vh;
    align-self: center;">
        </div>
        <div class="Quicksand-regular paragraphe" style="margin-bottom: 20px;align-self: center;">
            Enter your username or email address and we'll email
            you instructions on how to reset your password </div>
        <form [formGroup]="forgetPasswordForm">
            <div class="input-email">
                <input class="input-text input-form paragraphe Quicksand-light" style="flex: 2;padding: 10px;"
                    [ngClass]="{Error: submitted && formControls.email.errors}" formControlName="email" />
                <div class="input-btn" (click)="changeMotPassword()">
                    <div
                        style="background: url(../../../assets/flesh.png);width: 20px;height: 14px;background-repeat: no-repeat;background-size: cover;align-self: center;">
                    </div>
                </div>
            </div>
            <div *ngIf="!alreadySent" style="width: 100%;max-width:25vw;margin-top: 10px;text-align: left;" [ngClass]="message[0]=='error' ? 'ErrorMessage' : message[0]=='success'? 'SuccessMessage' : message[0]=='warn'? 'WarnMessage'  :''">
                {{message[1]}}
            </div>
            <div *ngIf="alreadySent" style="width: 100%;max-width:25vw;margin-top: 10px;text-align: left;" class="WarnMessage">
              An email has been already sent to you !
            </div>
        </form>
        <!-- <div class="other-email-text Quicksand-regular  paragraphe" (click)=" changeEmail()"> Change </div> -->
    </div>
</div>