<div *ngIf="events && events.length >0 ||  is_admin=='true'" id="recommended-event" class="recommended-events-part">
    <div style="display: inline-flex;">
        <div class="padding-div"></div>

        <div style="display: inline-flex;justify-content:space-between;width:100%;align-items: center;">
            <div [ngClass]="sections_title_icon && sections_title_icon !='' ?'titles_dupiflix':'titles'"
                style="display:inline-flex;gap:20px;align-items: center;">
                <div style="display: inline-flex;align-items: center;">
                    <img *ngIf="sections_title_icon && sections_title_icon !=''" [src]="sections_title_icon" style="width: 20px;margin-right: 5px;
                    height: 20px;" />

                    <div  [ngClass]="!sections_title_icon || sections_title_icon =='' ?'highlighted-title':''" class=" subTitle Quicksand-bold">

                        Recommended </div>
                </div> 
            </div>





            <div *ngIf="!is_admin || is_admin!='true'" class="show-all smallText Quicksand-semibold">Show All</div>
            <div *ngIf="is_admin && is_admin=='true'" class="show-all smallText Quicksand-semibold"><i
                    class="fa fa-pencil" style="color: white;cursor: pointer;" (click)="updateRecommendedEvents()"></i>
            </div>
        </div>
        <div class="padding-div"></div>
    </div>
    <div *ngIf="events && events.length >0 ">
        <div *ngIf="!loading" id="recommended-events-container" class="recommended-events-container" #upcomingEvents
            (mousedown)="startDragging($event, false, upcomingEvents)" (mouseup)="stopDragging($event, false)"
            (mouseleave)="stopDragging($event, false)" (mousemove)="moveEvent($event, upcomingEvents)">
            <div *ngFor="let event of events" class="event-card" (click)="gotoportal(event._id,event.type)">
                <div class="event-card-cover" [defaultImage]="defaultImage" [lazyLoad]="event.cover_url"
                    (onStateChange)="onStateChange($event)">
                </div>
                <div>
                    <div class="event-card-cover-content" [defaultImage]="defaultImage" [lazyLoad]="event.cover_url"
                        [style.background-image]="'url('+event.cover_url+')'">
                    </div>
                    <div class="event-card-content">
                        <div style="display:inline-flex;gap: 5px;">
                            <div class="Quicksand-medium paragraphe" style="flex: none"> {{event.type | titlecase }} : </div>
                            <div class="Quicksand-bold smallText line-limit"
                                style="cursor: pointer;flex: none;max-width: 230px;">{{event.title | titlecase}}</div>
                        </div>
                        <div>
                            <div *ngIf="event.status=='launched'" style="display: inline-flex;gap: 5px;">
                                <div class="Quicksand-regular paragraphe" style="color: #8F9BB3;">Start date : </div>
                                <div class="Quicksand-bold paragraphe" style="color:#8FA7E6;">02/11/2022</div>
                            </div>
                            <div *ngIf="event.status=='live'">
                                <div class="Quicksand-regular paragraphe" style="color: #8F9BB3;">Finished in 6 days
                                </div>
                            </div>
                            <div *ngIf="event.status=='finished'">
                                <div class="Quicksand-regular paragraphe" style="color: #8F9BB3;">Finished</div>
                            </div>
                        </div>
                        <div *ngIf="event.pass && event.pass.type && event.pass.type=='ticket' " class="paragraphe Quicksand-light" style="color: #FAFAFA;"> US$49.00 ~
                            US$149.00</div>
                        <div *ngIf="!event.pass" class="paragraphe Quicksand-light" style="color: #FAFAFA;"> Free</div>
                        <div
                            style="overflow: hidden;max-width: 100% !important;display: flex;justify-content: space-between;">
                            <div id="event._id" style="display: flex;
                            justify-content: flex-start!important;
                            width: 16vw;
                            overflow: hidden;
                            gap: 5px;">
                                <div *ngFor="let tag of event.tags; let i= index" style="flex: none;">
                                    <div *ngIf="i<3" class="tag tagsText Quicksand-light"
                                        style="height: 25px;align-self: center;">
                                        <div>{{tag}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="buttons">
                                <div class="icon-favori"></div>
                                <div class="icon-share"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <ngx-spinner [name]="'recommendedSpinner'" size="default" color="#8fa7e6" type="ball-spin-clockwise"
            [fullScreen]="false">
        </ngx-spinner>
    </div>
</div>

<!-- (mousedown)="startDraggingTags($event, false, event._id)"
                            (mouseup)="stopDraggingTags($event, false)" (mouseleave)="stopDraggingTags($event, false)"
                            (mousemove)="moveEventTags($event, event._id)" -->