import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { CookieService } from 'ngx-cookie-service';
import { DataService } from 'src/app/services/data.service';
const CountdownTimeUnits: Array<[string, number]> = [
  ['Y', 1000 * 60 * 60 * 24 * 365], // years
  ['Mo', 1000 * 60 * 60 * 24 * 30], // months
  ['D', 1000 * 60 * 60 * 24], // days
  ['H', 1000 * 60 * 60], // hours
  ['M', 1000 * 60], // minutes
  ['S', 1000], // seconds
  // ['S', 1], // million seconds
];
@Component({
  selector: 'app-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.css']
})
export class AlertPopupComponent implements OnInit {
  @Input() data
  message: any;
  join: boolean = false;
  ticketpopup: boolean = false;
  code: boolean = false;
  voucher: boolean = false;
  passes: boolean = true;
  event: any;
  ticket: any;
  havepass: boolean = false;
  launched: boolean = false;
  moreThan24Hours;
  duration;
  constructor(public dialogRef: NbDialogRef<AlertPopupComponent>,
    public dialog: NbDialogService, private router: Router, private shareddata: DataService, public changeDetectorRef: ChangeDetectorRef,private cookieService: CookieService) { }

  ngOnInit(): void {
    
    this.event = this.shareddata.event
    if (this.data && this.data.ticket) {
      this.ticket = this.data.ticket
      this.ticketpopup = true
    }
    else if (this.data.code) {
      this.code = true
    }
    if (this.data.access == "open") {
        if (this.shareddata.event.status  != "live") {
          this.launched = true
        }
        
        // if (this.shareddata.eventStatus == "live") {
          // this.dialogRef.onClose.subscribe(_ => {
          //   setTimeout(() => {
              // this.router.navigateByUrl("/eventinterface/open/" + this.shareddata.eventid);
              // this.dialogRef.close()
          //   }, 2000)
          // })
        // }
        // else {
        //   this.launched = true
        // }
      // }
      // else {
        // if (this.shareddata.eventStatus == "live") {
          // this.dialogRef.onClose.subscribe(_ => {
          //   setTimeout(() => {
              // this.router.navigateByUrl("/eventinterface/restricted/" + this.shareddata.eventid);
              // this.dialogRef.close()
          //   }, 2000)
          // })
        // }
        // else {
        //   this.launched = true
        // }
      // }
    }
    if (this.data.message && this.data.message !== "Sorry No passes left.") {
      this.havepass = true
      if (this.shareddata.event.status  != "live") {
        this.launched = true
      }
    }
    if (this.data.invited && this.data.code)
    {
      if (this.shareddata.event.status != "live") {
        this.launched = true
      }
      // if (this.shareddata.eventStatus == "live") {
        
      //   setTimeout(() => {
      //     this.dialogRef.close()
      //   }, 2000)
      //   this.dialogRef.onClose.subscribe(_ => {
          // setTimeout(() => {
            // this.dialogRef.close()
            // this.router.navigateByUrl("/eventinterface/restricted/" + this.shareddata.eventid);
          // }, 2000)
        // })
    }
      // else {
      //   this.launched = true
      // }
    
    if (this.shareddata.event.status == "launched") {
      this.launched = true
      if (this.cookieService.get("usertype") == "User") {
        if (this.data.message) {
          this.message = this.data.message
        }
        else {
          this.message = "You’re all set , stay tuned !"
        }
      }
      else {
        this.message = "You’re all set , stay tuned !"
      }

    }
    else if (this.shareddata.event.status == "live") {

      this.launched = false
      if (this.data.message) {
        this.message = this.data.message
      }
      else {
        this.message = "You’re all set & Ready to"
      }
    }
    this.countDown()
  }
  redirectToEventInterface() {
    if (this.shareddata.access && (this.shareddata.access.access == "open" || this.shareddata.access.access == "protected_password"))
    {
      this.router.navigateByUrl("/eventinterface/open/" + this.shareddata.eventid).then((res)=>{    this.dialogRef.close()
      });

    }
    else if (this.shareddata.access && (this.shareddata.access.access == "email" || this.shareddata.access.access == "auth"))
    {

      this.router.navigateByUrl("/eventinterface/restricted/" + this.shareddata.eventid).then((res)=>{    this.dialogRef.close()
      });;
    }
  }
  countDown() {
    let startevent = new Date(this.event.startDate)
    let today = new Date();
    let timezone = this.event.utcoffset
    if (!timezone.includes('.')) {
      startevent.setHours(startevent.getHours() - Number(timezone));
    }
    else if (timezone.includes('.')) {
      startevent.setHours(startevent.getHours() - Number(timezone.split('.')[0]));
      if (Number(timezone.split('.')[1]) != 5) {
        startevent.setMinutes(startevent.getMinutes() - Number(timezone.split('.')[1]));
      }
      else if (Number(timezone.split('.')[1]) == 5) {
        startevent.setMinutes(startevent.getMinutes() - 30);
      }
    }
    let difftime = ((startevent.getTime() - (today.getTime() + (today.getTimezoneOffset() * 60))) / 1000)
    let formatDate;
    var d = Math.floor(difftime / (3600 * 24));

    if (d == 0) formatDate = "HHh : MMm : SSs"
    else formatDate = "Dd : HHh : MMm:SSs"

    this.moreThan24Hours = {
      leftTime: difftime,
      format: formatDate,
      formatDate: ({ date, formatStr }) => {
        this.duration = Number(date || 0);
        return CountdownTimeUnits.reduce((current, [name, unit]) => {
          if (current && current.indexOf(name) !== -1) {
            const v = Math.floor(this.duration / unit);
            this.duration -= v * unit;
            return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
              return v.toString().padStart(match.length, '0');
            });
          }
          if (this.event.status == "launched" && this.duration == 0) {
            this.join = true
          }
          return current;
        }, formatStr);
      },
    };
  }
  back() {
    this.dialogRef.close()
  }
}
