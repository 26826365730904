import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastrService {
  public title = new BehaviorSubject('');
  currentTitle= this.title.asObservable();

  public type = new BehaviorSubject('');
  currentType= this.type.asObservable();

  public message = new BehaviorSubject('');
  currentMessage= this.message.asObservable();

  public display = new BehaviorSubject('hidden');
  currentDisplay= this.display.asObservable();

  public autoclose = new BehaviorSubject(true);
  currentAutoclose= this.autoclose.asObservable();

  public data = new BehaviorSubject({display:"hidden"});
  currentData= this.data.asObservable();


  constructor() { }
 
 

  changeData(value:any) 
  {
     
    this.data.next(value)
    this.title.next(value.title)
    this.type.next(value.type)
    this.message.next(value.message)
    this.display.next(value.display)
    this.autoclose.next(value.autoclose)

  }

}
