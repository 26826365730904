<div *ngIf="display_toastr=='visible' ">
    <div class="Toastr move-me-3">
        <div style="margin: 15px 20px ; display: inline-flex;   align-items: center;">
            <div class="icon" style="position: absolute; right: 0;   top: 10px;cursor: pointer;" (click)="closeToastr()">
                <img src="../../assets/icon-close.png" />
            </div>
            <div class="Bar" [ngClass]="toastr_type=='info' ? 'InfoToastr':toastr_type=='success' ? 'SuccessToastr':toastr_type=='warning' ? 'WarningToastr':toastr_type=='error' ? 'ErrorToastr':'' ">
            </div>

            <div *ngIf="toastr_type=='success'" class="icon">
                <img src="../../assets/success_toastr.png" />
            </div>
            <div *ngIf="toastr_type=='info'" class="icon">
                <img src="../../assets/info_toastr.png" />

            </div>
            <div *ngIf="toastr_type=='warning'" class="icon">
                <img src="../../assets/warning_toastr.png" />

            </div>
            <div *ngIf="toastr_type=='error'" class="icon">
                <img src="../../assets/error_toastr.png" />

            </div>
            <div>
                <div class="smallText Quicksand-bold">{{toastr_title}}</div>
                <div class="paragraphe Quicksand-regular" [innerHTML]="toastr_message">{{toastr_message}}</div>


            </div>
        </div>
    </div>