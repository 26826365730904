import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import Swal from "sweetalert2";
import { DataService } from '../services/data.service';
import jwt_decode from 'jwt-decode';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service'; 
import { ExploreEventService } from '../explore-event/explore-event.service';
  @Injectable({
    providedIn: 'root'
  })
  export class ExploreService {
  
    onChangedExplore: BehaviorSubject<any>;
   
    params;
    data;
    url; 
    constructor(private http: HttpClient, public dataService: DataService,public exploreeventService:ExploreEventService, private cookieService: CookieService) {
      this.onChangedExplore = new BehaviorSubject([]);
      this.url = environment.url
     
     
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    
      
      return new Promise<void>((resolve, reject) => {
        this.params = route.params;
        this.data = route.data;
        Promise.all([
          // this.getEvents(),
          this.getExplore(),
          
        ]).then(
          () => {
            resolve();
          },
          (reject) => {
          }
        );
      });
    }
    getExplore() {
      // if (this.data && this.data.title && this.data.title == "Manage Explore") {
        let decodedToken;
        let userId = '';
        return new Promise<void>((resolve, reject) => {
          if (this.cookieService.get('token')) {
            decodedToken = jwt_decode(this.cookieService.get('token'));
            userId = decodedToken.data._id;
          }
          let headers = { userid: userId }
          this.http
            .get(this.url + "/exploreevents/all" , { withCredentials: true, headers: headers }
  
            )
            .subscribe((response: any) => {
               
              this.onChangedExplore.next(response);
              resolve(response);
            }, reject);
        });
      // }
    }
    getAllEvents() {
     
      return new Promise<void>((resolve, reject) => {
        this.http
          .get(this.url +"/events/all/public")
          .subscribe((response: any) => {
            // this.test = response
            // this.onChangedRecommendedEvents.next(response)
            resolve(response);
          }, reject);
      });
    }
    getLiveAndFinishedEvents() {
     
      let decodedToken;
      let userId = '';
      return new Promise<void>((resolve, reject) => {
        if (this.cookieService.get('token')) {
          decodedToken = jwt_decode(this.cookieService.get('token'));
          userId = decodedToken.data._id;
        }
        let headers = { userid: userId }
        this.http
          .get(this.url + "/events/liveandfinished" , { withCredentials: true, headers: headers }

          )
          .subscribe((response: any) => {
             
             resolve(response);
          }, reject);
      });
    }
    getUpcomingEvents() {
     
      let decodedToken;
      let userId = '';
      return new Promise<void>((resolve, reject) => {
        if (this.cookieService.get('token')) {
          decodedToken = jwt_decode(this.cookieService.get('token'));
          userId = decodedToken.data._id;
        }
        let headers = { userid: userId }
        this.http
          .get(this.url + "/events/upcoming" , { withCredentials: true, headers: headers }

          )
          .subscribe((response: any) => {
             
             resolve(response);
          }, reject);
      });
    }
    getChannels() {
     
      let decodedToken;
      let userId = '';
      return new Promise<void>((resolve, reject) => {
        if (this.cookieService.get('token')) {
          decodedToken = jwt_decode(this.cookieService.get('token'));
          userId = decodedToken.data._id;
        }
        let headers = { userid: userId }
        this.http
          .get(this.url + "/events/channels" , { withCredentials: true, headers: headers }

          )
          .subscribe((response: any) => {
             
             resolve(response);
          }, reject);
      });
    }
    getSpeakers() {
     
      let decodedToken;
      let userId = '';
      return new Promise<void>((resolve, reject) => {
        if (this.cookieService.get('token')) {
          decodedToken = jwt_decode(this.cookieService.get('token'));
          userId = decodedToken.data._id;
        }
        let headers = { userid: userId }
        this.http
          .get(this.url + "/users/speakers" , { withCredentials: true, headers: headers }

          )
          .subscribe((response: any) => {
             
             resolve(response);
          }, reject);
      });
    }
    getCategories() {
     
      let decodedToken;
      let userId = '';
      return new Promise<void>((resolve, reject) => {
        if (this.cookieService.get('token')) {
          decodedToken = jwt_decode(this.cookieService.get('token'));
          userId = decodedToken.data._id;
        }
        let headers = { userid: userId }
        this.http
          .get(this.url + "/exploreevents/readFiles" , { withCredentials: true, headers: headers }

          )
          .subscribe((response: any) => {
             
             resolve(response);
          }, reject);
      });
    }
    editExploreVideoCover(coverexplore,exploreid) {
      if (coverexplore && this.cookieService.get('token')) {        
          let decodedToken:any = jwt_decode(this.cookieService.get('token'));
          const userId = decodedToken.data._id;
          let header= {userid:userId,exploreid:exploreid} 
        return new Promise((resolve, reject) => {           
          var formData1 = new FormData();
          formData1.append("coverexplore", coverexplore);
          this.http
            .post(this.url + "/exploreevents/setcover", formData1, {
              headers:header,
              withCredentials: true
            })
            .subscribe((response: any) => {
              // this.getExplore();
              resolve(response);
            }, reject);
        });
      }
  
    }
    editExplore(id, explore,action ) {
      const decodedToken: any = jwt_decode(this.cookieService.get('token'));
      const userId = decodedToken.data._id;
      return new Promise((resolve, reject) => {
        this.http
          .patch(this.url + "/exploreevents/edit/" + id, explore, {
            headers: { userid: userId  }, withCredentials: true
          })
          .subscribe((response: any) => {
            if (response && response.status == 'success') {
              if(action=='category'){
                this.exploreeventService.getCategories()
              }
              else if(action=='recommended'){
                this.exploreeventService.getAllEvents()
              }
              else    if(action=='upcoming'){
                this.exploreeventService.getLaunchedEvents()
              }
              else    if(action=='channel'){
                this.exploreeventService.getLaunchedEvents()
              }
              else    if(action=='speaker'){
                this.exploreeventService.getSpeakers()
              }
              else    if(action=='testimonoal'){
                this.exploreeventService.getTestimonials()
              }
             this.getExplore()
              resolve(response);
            }
          
          }, reject);
      });
    }
}
