<div style="height: 100%;" id="popup-container">
    <div class="top-bar" (click)="back()">
        <img src="../../assets/icons8-close-30.png" style="width: 25px; cursor: pointer;">
    </div>
    <div id="data-popup" style="height: 100%;overflow: auto;">
        <div *ngIf="data" class="popup-container" style="align-self: center;">
            <!--------------------Event---------------------->
            <div *ngIf="data.name=='addevent'">
                <div style="font-size: 22px;text-align: center; font-weight: bold;">Add Event</div>
                <form [formGroup]="AddEventForm">
                    <div style="margin: 5px 0;">
                        <div style="margin: 5px 0;">Title</div>
                        <input type="text" id="eventtitle" class="form-control" (input)="limitInput($event,'title')"
                            formControlName="title" style="border-color: #ccc;" placeholder="Add a title to your event"
                            [ngClass]="{'is-invalid': submitted && formControls.title.errors}" />
                        <div style="color: red;    font-size: 12px;">{{errorsMsg.title}}</div>

                    </div>
                    <div style="margin: 5px 0;">
                        <div style="margin: 5px 0;">Short summary</div>
                        <input type="text" id="eventshortdescription" (input)="limitInput($event,'short_summary')"
                            class="form-control" formControlName="short_summary" style="border-color: #ccc;"
                            placeholder="Add a short summary to your event"
                            [ngClass]="{'is-invalid': submitted && formControls.short_summary.errors}" />
                        <div style="color: red;    font-size: 12px;">{{errorsMsg.title}}</div>

                    </div>
                    <div style="display: inline-flex;justify-content: space-between;width:  100%;margin: 5px 0;">
                        <div style="width: 45%;">
                            <div style="margin: 5px 0;">Start Date</div>
                            <input type="datetime-local" id="eventstartdate" class="form-control"
                                formControlName="start_date" style="border-color: #ccc;"
                                [ngClass]="{'is-invalid': submitted && formControls.start_date.errors}" />
                        </div>
                        <div style="width: 45%;">
                            <div style="margin: 5px 0;">End Date</div>
                            <input type="datetime-local" id="eventenddate" class="form-control"
                                formControlName="end_date" style="border-color: #ccc;"
                                [ngClass]="{'is-invalid': submitted && formControls.end_date.errors}" />
                        </div>
                    </div>
                    <div style=" margin: 5px 0;  ">
                        <div style="  margin-bottom: 5px;">Timezone</div>
                        <ng-select bindLabel=" name" class="form-control" style="color: white;"
                            (change)="selectUTC($event)" (clear)="clearSelected($event)" formControlName="utcoffset">
                            <ng-option value="-12">(UTC-12:00) Eniwetok, Kwajalein</ng-option>
                            <ng-option value="-11">(UTC-11) Midway Island, Samoa</ng-option>
                            <ng-option value="-10">(UTC-10) Hawaii</ng-option>
                            <ng-option value="-9.5">(UTC-9:30) Taiohae</ng-option>
                            <ng-option value="-9">(UTC-9) Alaska</ng-option>
                            <ng-option value="-8">(UTC-8) Pacific Time (US &amp; Canada)</ng-option>
                            <ng-option value="-7">(UTC-7) Mountain Time (US &amp; Canada)</ng-option>
                            <ng-option value="-6">(UTC-6) Central Time (US &amp; Canada), Mexico City</ng-option>
                            <ng-option value="-5">(UTC-5) Eastern Time (US &amp; Canada), Bogota, Lima
                            </ng-option>
                            <ng-option value="-4.5">(UTC-4:30) Caracas</ng-option>
                            <ng-option value="-4">(UTC-4) Atlantic Time (Canada), Caracas, La Paz</ng-option>
                            <ng-option value="-3.5">(UTC-3:30) Newfoundland</ng-option>
                            <ng-option value="-3">(UTC-3) Brazil, Buenos Aires, Georgetown</ng-option>
                            <ng-option value="-2">(UTC-2) Mid-Atlantic</ng-option>
                            <ng-option value="-1">(UTC-1) Azores, Cape Verde Islands</ng-option>
                            <ng-option value="0" selected="selected">(UTC) Western Europe Time, London, Lisbon,
                                Casablanca
                            </ng-option>
                            <ng-option value="1">(UTC+1) Brussels, Copenhagen, Madrid, Paris</ng-option>
                            <ng-option value="2">(UTC+2) Kaliningrad, South Africa</ng-option>
                            <ng-option value="3">(UTC+3) Baghdad, Riyadh, Moscow, St. Petersburg</ng-option>
                            <ng-option value="3.5">(UTC+3:30) Tehran</ng-option>
                            <ng-option value="4">(UTC+4) Abu Dhabi, Muscat, Baku, Tbilisi</ng-option>
                            <ng-option value="4.5">(UTC+4:30)Kabul</ng-option>
                            <ng-option value="5">(UTC+5) Ekaterinburg, Islamabad, Karachi, Tashkent</ng-option>
                            <ng-option value="5.5">(UTC+5:30) Bombay, Calcutta, Madras, New Delhi</ng-option>
                            <ng-option value="5.75">(UTC+5:45) Kathmandu, Pokhara</ng-option>
                            <ng-option value="6">(UTC+6) Almaty, Dhaka, Colombo</ng-option>
                            <ng-option value="6.5">(UTC+6:30) Yangon, Mandalay</ng-option>
                            <ng-option value="7">(UTC+7) Bangkok, Hanoi, Jakarta</ng-option>
                            <ng-option value="8">(UTC+8) Beijing, Perth, Singapore, Hong Kong</ng-option>
                            <ng-option value="8.75">(UTC+8:45) Eucla</ng-option>
                            <ng-option value="9">(UTC+9) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</ng-option>
                            <ng-option value="9.5">(UTC+9:30) Adelaide, Darwin</ng-option>
                            <ng-option value="10">(UTC+10) Eastern Australia, Guam, Vladivostok</ng-option>
                            <ng-option value="10.5">(UTC+10:30) Lord Howe Island</ng-option>
                            <ng-option value="11">(UTC+11) Magadan, Solomon Islands, New Caledonia</ng-option>
                            <ng-option value="11.5">(UTC+11:30) Norfolk Island</ng-option>
                            <ng-option value="12">(UTC+12) Auckland, Wellington, Fiji, Kamchatka</ng-option>
                            <ng-option value="12.75">(UTC+12:45) Chatham Islands</ng-option>
                            <ng-option value="13">(UTC+13) Apia, Nukualofa</ng-option>
                            <ng-option value="14">(UTC+14) Line Islands, Tokelau</ng-option>
                        </ng-select>
                    </div>
                    <div style="width: 45%;margin: 5px 0;">
                        <div style="margin: 5px 0;">Organisation</div>
                        <select class="form-control" name="organisation" formControlName="organisation"
                            class="form-control" (change)="selectorganisation($event)"
                            [ngClass]="{'is-invalid': submitted && formControls.organisation.errors}">
                            <option value="" disabled selected>Select organisation</option>
                            <option *ngFor="let organisation of organisations; let index=index"
                                [value]="organisation._id">
                                {{organisation.name}}
                            </option>
                        </select>
                    </div>
                    <div style="width: 45%;margin: 5px 0;">
                        <div style="margin: 5px 0;">Type</div>
                        <div class="form-group">
                            <div class="flex-container" class="btn-group btn-group-toggle" data-toggle="buttons"
                                  style="display: inline-flex;align-items: center;">
                                <div style="display: inline-flex;align-items: center;">
                                    <input type="radio" id="session" value="session" name="type"
                                        formControlName="type" />
                                    <label class="flex-item btn   flex-item-config" for="session">
                                        Session
                                    </label>
                                </div>
                                <div style="display: inline-flex;align-items: center;">
                                    <input type="radio" id="channel" value="channel" name="type"
                                        formControlName="type" />
                                    <label class="flex-item btn  flex-item-config" for="channel">
                                        Channel </label>
                             
                                </div>
                                <div style="display: inline-flex;align-items: center;">
                                    <input type="radio" id="hybrid" value="hybrid" name="type"
                                        formControlName="type" />
                                    <label class="flex-item btn  flex-item-config" for="hybrid">
                                        Hybrid </label>
                             
                                </div>
                                <div style="display: inline-flex;align-items: center;">
                                    <input type="radio" id="virtual_venue" value="virtual venue" name="type"
                                        formControlName="type" />
                                    <label class="flex-item btn  flex-item-config" for="virtual_venue">
                                        Virtual Venue </label>
                             
                                </div>
                            </div>
                        </div>
                     
                    </div>
                    <div style="margin: 10px 0 5px 0;">
                        <div style="margin: 5px 0;">Choose who can enter this event</div>
                        <div class="form-group">
                            <div class="flex-container" class="btn-group btn-group-toggle" data-toggle="buttons"
                                (change)="selectAccess($event)" style="display: flex;
                    flex-direction: column;">
                                <div>
                                    <input type="radio" id="open" value="open" name="access" formControlName="access" />
                                    <label class="flex-item btn   flex-item-config" for="open">
                                        Anyone can enter the event
                                    </label>
                                </div>
                                <div>
                                    <input type="radio" id="protected_password" value="protected_password" name="access"
                                        formControlName="access" />
                                    <label class="flex-item btn  flex-item-config" for="protected_password">
                                        Anyone can only access by entering the event password
                                    </label>
                                    <div *ngIf="formControls.access.value=='protected_password'"
                                        style="display: inline-flex;align-items: center; width: 100%;">
                                        <div *ngFor="let i of  counter(4);let index =index "
                                            style="display: inline-flex;align-items: center;">
                                            <div style="border:1px solid white;width: 30px;
                                    height: 30px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 5px;
                                    margin-right: 20px;">{{generatedNumber.toString()[index]}}</div>
                                        </div>
                                        <div (click)=" onNumberGenerated()">
                                            <img src="../../../assets/refresh.png"
                                                style="width: 30px;height: 30px;cursor: pointer;" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <input type="radio" id="auth" value="auth" name="access" formControlName="access" />
                                    <label class="flex-item btn   flex-item-config" for="auth">
                                        Anyone can enter after sign in
                                    </label>
                                </div>
                                <div>
                                    <input type="radio" id="email" value="email" name="access"
                                        formControlName="access" />
                                    <label class="flex-item btn   flex-item-config" for="email">
                                        Anyone can enter after entering the email
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="margin: 5px 0;">
                        <input *ngIf="!activate_is_inviteonly" type="checkbox" disabled id="is_inviteonly" />
                        <input *ngIf="activate_is_inviteonly" type="checkbox" (change)="is_inviteonly=!is_inviteonly"
                            [value]="is_inviteonly" id="is_inviteonly" name="is_inviteonly"
                            formControlName="is_inviteonly" />
                        <label class="LabelCheckbox" style="margin-left: 10px; cursor: pointer;"
                            [ngStyle]="{'color':activate_is_inviteonly ? 'white':'#212529'} " for="is_inviteonly">Only
                            people
                            who are
                            invited can
                            enter
                        </label>
                    </div>
                </form>
                <div style="width: 100% ;margin-top: 20px;">
                    <div (click)="addEvent()" style=" margin: auto;   width: 100px;
            border: 1px solid;cursor: pointer;
            border-radius: 5px;    padding: 5px;
            text-align: center;">
                        Add
                    </div>
                </div>
            </div>

            <!--------------------Passes---------------------->
            <div *ngIf="data.name=='addTicket' || data.name=='addCode' ">
                <div *ngIf="data.name=='addTicket' " style="font-size: 22px;text-align: center; font-weight: bold;">Add
                    Ticket
                </div>
                <div *ngIf="data.name=='addCode' " style="font-size: 22px;text-align: center; font-weight: bold;">
                    Generate Code
                </div>
                <form [formGroup]="AddPassForm">
                    <div *ngIf="data.name=='addTicket'" style="margin: 5px 0;">
                        <div style="margin: 5px 0;">Name</div>
                        <input type="text" class="form-control" formControlName="name" style="border-color: #ccc;"
                            placeholder="Add a name to your ticket"
                            [ngClass]="{'is-invalid': submitted && formControlsAddPass.name.errors}" />
                    </div>
                    <div *ngIf="data.name=='addCode'" style="margin: 5px 0;">
                        <div style="margin: 5px 0;">Code</div>
                        <div style="display: inline-flex;align-items: center; width: 100%;">
                            <div *ngFor="let i of  counter(4);let index =index "
                                style="display: inline-flex;align-items: center;">
                                <div style="border:1px solid white;width: 30px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 5px;
                        margin-right: 20px;">{{generatedNumber.toString()[index]}}</div>
                            </div>
                            <div (click)=" onNumberGenerated()">
                                <img src="../../../assets/refresh.png"
                                    style="width: 30px;height: 30px;cursor: pointer;" />
                            </div>
                        </div>
                        <!-- <input type="text" id="eventtitle" class="form-control" formControlName="code" style="border-color: #ccc;" placeholder="Add a code to your event" [ngClass]="{'is-invalid': submitted && formControlsAddPass.code.errors}" /> -->
                    </div>
                    <div style="margin: 5px 0;">
                        <div style="margin: 5px 0;">Description</div>
                        <textarea type="text" class="form-control" formControlName="description"
                            style="border-color: #ccc;  height: 5rem; resize: none "
                            placeholder="Add a description to your event"
                            [ngClass]="{'is-invalid': submitted && formControlsAddPass.description.errors}"></textarea>
                    </div>
                    <div style="margin: 5px 0;">
                        <div style="margin: 5px 0;">Price</div>
                        <div style="display: inline-flex;">
                            <input type="number" class="form-control" formControlName="price"
                                style="border-color: #ccc;"
                                [ngClass]="{'is-invalid': submitted && formControlsAddPass.price.errors}" />
                            <div style="margin-left: 20px;">
                                <select class="form-control" formControlName="currency"
                                    style="border-color: #ccc;width: 70px;"
                                    [ngClass]="{'is-invalid': submitted && formControlsAddPass.currency.errors}">
                                    <option value="€">€</option>
                                    <option value="$">$</option>
                                    <option value="DT">DT</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div style="margin: 5px 0;">
                        <div style="margin: 5px 0;">Quantity</div>
                        <input type="number" class="form-control" formControlName="quantity"
                            style="border-color: #ccc;width: 100px;"
                            [ngClass]="{'is-invalid': submitted && formControlsAddPass.quantity.errors}" />
                    </div>
                    <div *ngIf="data.name=='addTicket'" style="margin: 5px 0;">
                        <div class="Palette">
                            <div style="margin: 5px 0;">Select color</div>
                            <div style="margin: 5px 15px; display: inline-flex; border-radius: 5px; ">
                                <div *ngFor="let c of palettecolor">
                                    <div class="Color" [style.background-color]="c" [style.border-color]="c"
                                        (click)="getcolor(c)" style="width: 50px;margin-right: 20px;">
                                        <div *ngIf='color==c'> <img src="../../../assets/tick.png"
                                                style="    width: 17px;" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div style="width: 100%;margin-top: 20px;">
                    <div (click)="addPass()" style=" margin: auto;   width: 100px;
            border: 1px solid;cursor: pointer;
            border-radius: 5px;    padding: 5px;
            text-align: center;">
                        Add
                    </div>
                </div>
            </div>
            <div *ngIf="data.name=='editTicket' || data.name=='editCode' ">
                <div *ngIf="data.name=='editTicket' " style="font-size: 22px;text-align: center; font-weight: bold;">
                    Edit Ticket
                </div>
                <div *ngIf="data.name=='editCode' " style="font-size: 22px;text-align: center; font-weight: bold;">Edit
                    Code
                </div>
                <form [formGroup]="EditPassForm">
                    <div *ngIf="data.name=='editTicket'" style="margin: 5px 0;">
                        <div style="margin: 5px 0;">Name</div>
                        <input type="text" class="form-control" formControlName="name" style="border-color: #ccc;"
                            placeholder="Add a name to your pass"
                            [ngClass]="{'is-invalid': submitted && formControlsEditPass.name.errors}" />
                    </div>
                    <div *ngIf="data.name=='editCode'" style="margin: 5px 0;">
                        <div style="margin: 5px 0;">Code</div>
                        <div style="display: inline-flex;align-items: center; width: 100%;">
                            <div *ngFor="let i of  counter(4);let index =index "
                                style="display: inline-flex;align-items: center;">
                                <div style="border:1px solid white;width: 30px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 5px;
                        margin-right: 20px;">{{generatedNumber.toString()[index]}}</div>
                            </div>
                            <div (click)=" onNumberGenerated()">
                                <img src="../../../assets/refresh.png"
                                    style="width: 30px;height: 30px;cursor: pointer;" />
                            </div>
                        </div>
                        <!-- <input type="text" id="eventtitle" class="form-control" formControlName="code" style="border-color: #ccc;" placeholder="Add a code to your event" [ngClass]="{'is-invalid': submitted && formControlsEditPass.code.errors}" /> -->
                    </div>
                    <div style="margin: 5px 0;">
                        <div style="margin: 5px 0;">Description</div>
                        <textarea type="text" class="form-control" formControlName="description"
                            style="border-color: #ccc;  height: 5rem; resize: none "
                            placeholder="Add a description to your event"
                            [ngClass]="{'is-invalid': submitted && formControlsEditPass.description.errors}"></textarea>
                    </div>
                    <div style="margin: 5px 0;">
                        <div style="margin: 5px 0;">Price</div>
                        <div style=" display: inline-flex;">
                            <input type="number" class="form-control" formControlName="price"
                                style="border-color: #ccc;"
                                [ngClass]="{'is-invalid': submitted && formControlsEditPass.price.errors}" />
                            <div style="margin-left: 20px;">
                                <select class="form-control" formControlName="currency"
                                    style="border-color: #ccc;width: 70px;"
                                    [ngClass]="{'is-invalid': submitted && formControlsEditPass.currency.errors}">
                                    <option value="€">€</option>
                                    <option value="$">$</option>
                                    <option value="DT">DT</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div style="margin: 5px 0;">
                        <div style="margin: 5px 0;">Quantity</div>
                        <input type="number" class="form-control" formControlName="quantity"
                            style="border-color: #ccc;width: 100px;"
                            [ngClass]="{'is-invalid': submitted && formControlsEditPass.quantity.errors}" />
                    </div>
                    <div *ngIf="data.name=='editTicket'" style="margin: 5px 0;">
                        <div class="Palette">
                            <div style="margin: 5px 0;">Select color</div>
                            <div style="margin: 5px 15px; display: inline-flex; border-radius: 5px; ">
                                <div *ngFor="let c of palettecolor">
                                    <div class="Color" [style.background-color]="c" [style.border-color]="c"
                                        (click)="getcolor(c)" style="width: 50px;margin-right: 20px;">
                                        <div *ngIf='color==c'> <img src="../../../assets/tick.png"
                                                style="    width: 17px;" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div style="width: 100%;margin-top: 20px;">
                    <div (click)="editPass()" style=" margin: auto;   width: 100px;
            border: 1px solid;cursor: pointer;
            border-radius: 5px;    padding: 5px;
            text-align: center;">
                        Edit
                    </div>
                </div>
            </div>
            <!--------------------Vouchers---------------------->
            <div *ngIf="data.name=='generateVoucher'  ">
                <div style="font-size: 22px;text-align: center; font-weight: bold;">Generate Voucher
                </div>
                <form [formGroup]="AddVoucherForm">
                    <div style="margin: 5px 0;">
                        <div style="margin: 5px 0;">Ticket name</div>
                        <div class="form-control">
                            {{data.pass.name}}
                        </div>
                    </div>
                    <div style="margin: 5px 0;">
                        <div style="margin: 5px 0;">Voucher Code</div>
                        <div style="display: inline-flex;align-items: center; width: 100%;">
                            <div *ngFor="let i of  counter(4);let index =index "
                                style="display: inline-flex;align-items: center;">
                                <div style="border:1px solid white;width: 30px;
                            height: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 5px;
                            margin-right: 20px;">{{generatedNumber.toString()[index]}}</div>
                            </div>
                            <div (click)=" onNumberGenerated()">
                                <img src="../../../assets/refresh.png"
                                    style="width: 30px;height: 30px;cursor: pointer;" />
                            </div>
                        </div>
                        <!-- <input type="text" id="eventtitle" class="form-control" formControlName="code" style="border-color: #ccc;" placeholder="Add a code to your event" [ngClass]="{'is-invalid': submitted && formControlsAddPass.code.errors}" /> -->
                    </div>
                    <div style="margin: 5px 0;">
                        <div style="margin: 5px 0;">Description</div>
                        <textarea type="text" class="form-control" formControlName="description"
                            style="border-color: #ccc;  height: 5rem; resize: none "
                            placeholder="Add a description to your event"
                            [ngClass]="{'is-invalid': submitted && formControlsVoucher.description.errors}"></textarea>
                    </div>
                    <div style="margin: 5px 0;">
                        <div style="margin: 5px 0;">Quantity</div>
                        <input type="number" class="form-control" formControlName="quantity"
                            style="border-color: #ccc;width: 100px;"
                            [ngClass]="{'is-invalid': submitted && formControlsVoucher.quantity.errors}" />
                    </div>
                </form>
                <div style="width: 100%;margin-top: 20px;">
                    <div (click)="generateVoucher()" style=" margin: auto;   width: 100px;
                border: 1px solid;cursor: pointer;
                border-radius: 5px;    padding: 5px;
                text-align: center;">
                        Add
                    </div>
                </div>
            </div>
            <div *ngIf="data.name=='voucherslist'">
                <div>
                    <div style="font-size: 22px;text-align: center; font-weight: bold;">Vouchers List
                    </div>
                    <div style="display: inline-flex;justify-content: center;width: 100%;flex-wrap: wrap;">
                        <div *ngFor="let voucher of vouchers" style="margin: 5px;   min-width: 200px; border: 1px solid;
            padding: 5px;  border-radius: 5px;">
                            <i *ngIf="voucher.purchased==0" class="fa fa-trash" style="float: right;"
                                (click)="removeVoucher(voucher._id)"></i>
                            <i *ngIf="voucher.purchased>0 && voucher.quantity-voucher.purchased>0 " class="fa fa-undo"
                                style="float: right;" (click)="expireVoucher(voucher._id)"></i>
                            <div style="margin: 5px 0;">
                                <div style="margin: 5px 0;">Code: {{voucher.code}}</div>
                            </div>
                            <div style="margin: 5px 0;">
                                <div style="margin: 5px 0;">Quantity: {{voucher.quantity}}
                                </div>
                                <div style="margin: 5px 0;">
                                    <div style="margin: 5px 0;">Available: {{voucher.quantity-voucher.purchased}}
                                    </div>
                                    <div style="margin: 5px 0;">
                                        <div style="margin: 5px 0;">Emails:</div>
                                        <div *ngFor="let email of voucher.emails">
                                            <div>
                                                -{{email}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>