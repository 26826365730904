import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { PortalService } from 'src/app/portal/portal.service';
import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user.service';
import * as lodash from 'lodash';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-reuse-temporaryuser',
  templateUrl: './reuse-temporaryuser.component.html',
  styleUrls: ['./reuse-temporaryuser.component.css']
})
export class ReuseTemporaryuserComponent implements OnInit {
  @Input() data
  @Output() OutPutDataShared = new EventEmitter();

  dataAlert
  dataAccess
  dataVerif
  dataAuth
  email
  constructor(private portalService: PortalService, private userService: UserService, private shareddata: DataService, private cookieService: CookieService) { }

  ngOnInit(): void {
    if (this.data && this.data.email)
    {
      this.email = this.data.email
    }
    else if (this.shareddata.temporaryUserCurrentEmail != "")
    {
      this.email =this.shareddata.temporaryUserCurrentEmail
    }
  }
  joinEvent() {
    if (this.cookieService.get("token")) {
      var DecodedToken: any = jwt_decode(this.cookieService.get("token"));
     
    }
    if (this.shareddata.event && !this.shareddata.event.is_inviteonly) {
    
      this.portalService.verifyToJoinDashboardEvent("", this.shareddata.eventid, "email", DecodedToken.data._id).then((res: any) => {
        if (res && res.status == "success") {
          this.userService.getUserById().then(res1 => {
            this.dataAlert =
            {
              eventid: this.data.eventid,
              passes: true,
              code: true,
              alertPopup: true,
            }
            this.OutPutDataShared.emit(this.dataAlert);
          })
        }
      })
    }
    else {
      if (this.cookieService.get("token")) {
        var DecodedToken: any = jwt_decode(this.cookieService.get("token"));
        if (this.data.status && this.data.status == "verified") {
          this.portalService.verifyInvitation(this.data.email, this.shareddata.eventid).then((res: any) => {
            if (res.status == "success") {
              this.userService.getUserById().then((res1: any) => {
                let event = lodash.find(res1.temporary_user.events, { 'event_id': this.shareddata.eventid });

                // if (!event) {
                //   if (res.invitation_type == "invite_event") {
                //     if (this.shareddata.access && this.shareddata.access.protected == 'false') {
                //       this.dataAccess = {
                //         eventid: this.shareddata.eventid,
                //         status: "auth",
                //         accessPopup: true
                //       }
                //       this.OutPutDataShared.emit(this.dataAccess);
                //     }
                //   }
                // }
                // else {
                this.userService.onChangedUser.next(DecodedToken.data)
                if (res.invitation_type == "invite_event") {
                  this.dataAlert =
                  {
                    code: true,
                    passes: true,
                    alertPopup: true,
                    message: "Welcome!"
                  }
                  this.OutPutDataShared.emit(this.dataAlert);
                }
                // }
              })
            }
            else if (res.status == "error") {
              if (res.message.includes("please verify")) {
                this.userService.getUserById().then((res1) => {
                  if (this.shareddata.event && this.shareddata.event.pass) {
                    this.dataVerif = {
                      invited: true,
                      email: DecodedToken.data.email,
                      verif: "email",
                      type: this.shareddata.event.pass,
                      verifPopup: true
                    }
                    this.OutPutDataShared.emit(this.dataVerif);
                  }
                  else {
                    this.dataVerif = {
                      invited: true,
                      email: DecodedToken.data.email,
                      verif: "email",
                      verifPopup: true
                    }
                    this.OutPutDataShared.emit(this.dataVerif);
                  }
                })
              }
              else {
                // this.userService.onChangedUser.next(this.DecodedToken.data)
                this.dataAlert =
                {
                  passes: true,
                  invited: false,
                  alertPopup: true
                }
                this.OutPutDataShared.emit(this.dataAlert);
              }
            }
          })
        }
        else {
          this.dataVerif = {
            invited: true,
            email: DecodedToken.data.email,
            verif: "email",
            verifPopup: true
          }
          this.OutPutDataShared.emit(this.dataVerif);
        }
      }
    }
  }
  changeEmail() {
    this.dataAuth =
    {
      status: "email",
      authPopup: true,
    }
    this.OutPutDataShared.emit(this.dataAuth);

  }
}

