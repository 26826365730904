import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { CookieService } from 'ngx-cookie-service';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-shared-component-join-event',
  templateUrl: './shared-component-join-event.component.html',
  styleUrls: ['./shared-component-join-event.component.css']
})
export class SharedComponentJoinEventComponent implements OnInit {
  @Input() data;
  authPopup: boolean = false;
  verifPopup: boolean = false;
  accessPopup: boolean = false;
  alertPopup: boolean = false;
  reuseEmailPopup: boolean = false;
  forgetPwdPopup: boolean = false;
  currentPopup
  prevPopup: any = [];
  accessData: any;
  constructor(private dialogRef: NbDialogRef<SharedComponentJoinEventComponent>, private shareddata: DataService, private cookieService: CookieService) { }
  @HostListener('window:resize', ['$event'])
  onResize(event) {

    // if (document.getElementById('data-popup') && document.getElementById('popup-container')) {
    //   let height_datapopup = document.getElementById('data-popup').offsetHeight;
    //   let height_popupcontainer = document.getElementById('popup-container').offsetHeight;
    //   document.getElementById('data-popup').style.height = (height_popupcontainer - 46).toString() + 'px';
    //   document.getElementById('data-popup').style.overflow = 'auto';
    // }
  }
  ngOnInit(): void {
    if (this.data) {
      if (this.data.authPopup) {
        this.currentPopup = "auth"
        this.authPopup = true
        this.accessPopup = false
        this.verifPopup = false
        this.alertPopup = false
        this.reuseEmailPopup = false
        this.forgetPwdPopup = false
      }
      else if (this.data.accessPopup) {
        this.currentPopup = "access"
        this.authPopup = false
        this.accessPopup = true
        this.verifPopup = false
        this.alertPopup = false
        this.reuseEmailPopup = false
        this.forgetPwdPopup = false
      }
      else if (this.data.verifPopup) {
        this.currentPopup = "verif"
        this.authPopup = false
        this.accessPopup = false
        this.verifPopup = true
        this.alertPopup = false
        this.reuseEmailPopup = false
        this.forgetPwdPopup = false
      }
      else if (this.data.alertPopup) {
        this.currentPopup = "alert"
        this.authPopup = false
        this.accessPopup = false
        this.verifPopup = false
        this.alertPopup = true
        this.reuseEmailPopup = false
        this.forgetPwdPopup = false
      }
      else if (this.data.reuseEmailPopup) {
        this.currentPopup = "reuseEmail"
        this.authPopup = false
        this.accessPopup = false
        this.verifPopup = false
        this.alertPopup = false
        this.reuseEmailPopup = true
        this.forgetPwdPopup = false
      }
      else if (this.data.forgetPwdPopup) {
        this.currentPopup = "forgetPwdPopup"
        this.authPopup = false
        this.accessPopup = false
        this.verifPopup = false
        this.alertPopup = false
        this.reuseEmailPopup = false
        this.forgetPwdPopup = true
      }
      this.prevPopup.push(this.currentPopup)
    }
  }
  ngAfterViewInit() {

    // if (document.getElementById('data-popup') && document.getElementById('popup-container')) {

    //   let height_datapopup = document.getElementById('data-popup').offsetHeight;
    //   let height_popupcontainer = document.getElementById('popup-container').offsetHeight;
    //   document.getElementById('data-popup').style.height = (height_popupcontainer - 46).toString() + 'px';
    //   document.getElementById('data-popup').style.overflow = 'auto';

    // }

  }
  changePopup(event) {

    if (event.close) {
      this.dialogRef.close()
    }
    else if (event.authPopup) {
      this.data = event
      this.currentPopup = "auth"
      this.authPopup = true
      this.accessPopup = false
      this.verifPopup = false
      this.alertPopup = false
      this.reuseEmailPopup = false
      this.forgetPwdPopup = false
      this.prevPopup.push(this.currentPopup)
    }
    else if (event.accessPopup) {
      this.data = event
      this.currentPopup = "access"
      this.authPopup = false
      this.accessPopup = true
      this.verifPopup = false
      this.alertPopup = false
      this.reuseEmailPopup = false
      this.forgetPwdPopup = false

      this.prevPopup.push(this.currentPopup)
    }
    else if (event.verifPopup) {
      this.data = event
      this.currentPopup = "verif"
      this.verifPopup = true
      this.authPopup = false
      this.accessPopup = false
      this.reuseEmailPopup = false
      this.alertPopup = false
      this.forgetPwdPopup = false

      this.prevPopup.push(this.currentPopup)
    }
    else if (event.alertPopup) {
      this.data = event
      this.currentPopup = "alert"
      this.authPopup = false
      this.accessPopup = false
      this.verifPopup = false
      this.alertPopup = true
      this.reuseEmailPopup = false
      this.forgetPwdPopup = false
      this.prevPopup.push(this.currentPopup)
    }
    else if (event.forgetPwdPopup) {
      this.data = event
      this.currentPopup = "forgetPwdPopup"
      this.authPopup = false
      this.accessPopup = false
      this.verifPopup = false
      this.alertPopup = false
      this.reuseEmailPopup = false
      this.forgetPwdPopup = true
      this.prevPopup.push(this.currentPopup)
    }
    else if (event.reuseEmailPopup) {
      this.data = event
      this.currentPopup = "reuseEmail"
      this.authPopup = false
      this.accessPopup = false
      this.verifPopup = false
      this.alertPopup = false
      this.reuseEmailPopup = true
      this.forgetPwdPopup = false

      this.prevPopup.push(this.currentPopup)
    }
    else if (event.voucherPopup) {
      this.currentPopup = "voucher"
      this.prevPopup.push(this.currentPopup)
    }
    else if (event.emailPopup) {
      this.currentPopup = "email"
      this.prevPopup.push(this.currentPopup)
    }

  }
  back() {
    
    if (this.currentPopup == "verif" || this.currentPopup == "alert" || this.currentPopup == "reuseEmail") {
      this.dialogRef.close()
    }
    else if (this.currentPopup == "forgetPwdPopup") {
      this.data = {
        loginType: "login"
      }
      this.currentPopup = "auth"
      this.authPopup = true
      this.accessPopup = false
      this.verifPopup = false
      this.alertPopup = false
      this.reuseEmailPopup = false
      this.forgetPwdPopup = false
      this.prevPopup.pop();
    }
    else if (this.prevPopup[this.prevPopup.length - 1] == "voucher") {
      this.shareddata.getAccessPopup("ticket")
      this.prevPopup.pop();
    }
    else if (this.prevPopup.length == 1 && this.prevPopup[0] == 'auth') {
      this.dialogRef.close()
    }
    else if (this.prevPopup[this.prevPopup.length - 2] == "voucher") {
      this.shareddata.getAccessPopup("voucher")
      this.authPopup = false
      this.accessPopup = true
      this.verifPopup = false
      this.alertPopup = false
      this.reuseEmailPopup = false
      this.forgetPwdPopup = false
      this.prevPopup.pop();
    }
    else if (this.prevPopup.length == 2 && this.prevPopup[this.prevPopup.length - 2] == "reuseEmail") {
      this.dialogRef.close()
    }
    else if (this.prevPopup[this.prevPopup.length - 3] == "reuseEmail" && this.currentPopup == "email") {
      this.authPopup = false
      this.accessPopup = false
      this.verifPopup = false
      this.alertPopup = false
      this.reuseEmailPopup = true
      this.forgetPwdPopup = false
      this.prevPopup.pop();
    }
    else if (this.prevPopup.length == 3 && this.prevPopup[0] == "reuseEmail" && (this.prevPopup[this.prevPopup.length - 2] == "email" || this.prevPopup[this.prevPopup.length - 2]) == "auth") {
      this.authPopup = false
      this.accessPopup = false
      this.verifPopup = false
      this.alertPopup = false
      this.reuseEmailPopup = true
      this.forgetPwdPopup = false
      this.prevPopup.pop();
    }
    else if (this.prevPopup[this.prevPopup.length - 2] == "email" && this.prevPopup[this.prevPopup.length - 3] == "auth") {
      this.shareddata.getAccessPopup("email")
      this.prevPopup.pop();
    }
    else if (this.prevPopup[this.prevPopup.length - 1] == "email" && this.prevPopup[this.prevPopup.length - 2] == "auth" && this.prevPopup.length - 2 == 0) {
      this.shareddata.getAccessPopup("email")
      this.dialogRef.close()
    }
    else if (this.shareddata.event && !this.shareddata.event.is_inviteonly) {
      if (!this.prevPopup || this.prevPopup.length == 0 || this.prevPopup.length == 1 || this.currentPopup == "verif" || this.currentPopup == "alert") {
        this.dialogRef.close()
      }
      else if (this.prevPopup[this.prevPopup.length - 2] == "auth") {

        this.authPopup = true
        this.accessPopup = false
        this.verifPopup = false
        this.alertPopup = false
        this.reuseEmailPopup = false
        this.forgetPwdPopup = false
      }
      else if (this.prevPopup[this.prevPopup.length - 2] == "access") {
        this.authPopup = false
        this.accessPopup = true
        this.verifPopup = false
        this.alertPopup = false
        this.reuseEmailPopup = false
        this.forgetPwdPopup = false
      }
      this.prevPopup.pop();
    }
    else if (this.shareddata.event && this.shareddata.event.is_inviteonly) {
      if (!this.prevPopup || this.prevPopup.length == 0 || this.prevPopup.length == 1 || this.currentPopup == "verif" || this.currentPopup == "alert") {
        this.dialogRef.close()
      }
      else if (this.prevPopup[this.prevPopup.length - 2] == "auth") {
        if (this.cookieService.get("token")) {
          this.dialogRef.close()
        }
        else {
          this.authPopup = true
          this.accessPopup = false
          this.verifPopup = false
          this.alertPopup = false
          this.reuseEmailPopup = false
          this.forgetPwdPopup = false
        }

      }
      else if (this.prevPopup[this.prevPopup.length - 2] == "access") {
        this.authPopup = false
        this.accessPopup = true
        this.verifPopup = false
        this.alertPopup = false
        this.reuseEmailPopup = false
        this.forgetPwdPopup = false
      }

      this.prevPopup.pop();
    }
    else {
      if (!this.prevPopup || this.prevPopup.length == 0 || this.prevPopup.length == 1 || this.currentPopup == "verif") {
        this.dialogRef.close()
      }
      else if (this.prevPopup[this.prevPopup.length - 2] == "auth") {
        this.dialogRef.close()
      }
      this.prevPopup.pop();
    }
  }
}
