import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import Swal from 'sweetalert2';
import * as lodash from 'lodash';
import { AuthentificationSystemService } from 'src/app/authentification-system/authentification-system.service';
import { AuthentificationSystemComponent } from 'src/app/authentification-system/authentification-system.component';
import { NbDialogService } from '@nebular/theme';
import jwt_decode from 'jwt-decode';
import { UserService } from 'src/app/services/user.service';
import { SharedComponentJoinEventComponent } from 'src/app/shared-component-join-event/shared-component-join-event.component';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { config } from 'src/config/config';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('dropdown') dropdown: ElementRef;
  curentbackgroundColor = "#1212122b"
  currentStatus = ""
  onChangeColor: Subscription;
  onCheckStatus: Subscription;
  isChanged: boolean = false;
  verified: boolean = true;
  openDropDown: boolean = false;
  openDropDownUser: boolean = false;

  data;
  eventId;
  logoutBtn: boolean = false;
  DecodedToken: any;
  navBarHeight: number;
  verifiedBar: boolean = false;
  eventID
  shared_event
  user: boolean = false;
  temporaryuser: boolean = false;
  email: any;
  firstLetter
  myuser: any;
  profilePic

  /*****From config******/
  logo
  /*********************/
  constructor(private spinner: NgxSpinnerService, public changeDetectorRef: ChangeDetectorRef, public dialog: NbDialogService, public dataService: DataService, private cdr: ChangeDetectorRef,
    private router: Router, private cookieService: CookieService, public  authService: AuthentificationSystemService, private userServices: UserService) {
  }
  ngOnInit(): void {    
    
    this.logo = config.logo;
    this.onChangeColor = this.dataService.currentbackgroundColor.subscribe(backgroundColor => {
      this.curentbackgroundColor = backgroundColor
      if (backgroundColor != "#1212122b") this.isChanged = true;
      else this.isChanged = false;
    })
    this.dataService.subjectNotifier.subscribe((value) => {
      if (value == "logout") {
        this.authService.currentUser = null
        this.verified = true;
        this.logoutBtn = false;
        this.verifiedBar = false
      }
    })
    this.dataService.sharedevent.subscribe(event => {
      if (this.cookieService.get("token") && this.cookieService.get("usertype") == "User") {
         
        this.logoutBtn = true
      }
      if (event && event != '') {
        this.shared_event = event
      }
      this.userServices.onChangedUser
        .subscribe((res1: any) => {
          if (res1 && res1._id) {
            if (this.cookieService.get("token")) {
 
              this.logoutBtn = true
              this.authService.currentUser = res1
              this.myuser = res1
              if (res1.additional_data && res1.additional_data.photo) {
                this.profilePic = res1.additional_data.photo
              }
              else {
                this.profilePic = "https://cquipsplus.ca/wp-content/themes/cera/assets/images/avatars/user-avatar.png"
              }
              if (this.cookieService.get("usertype") == "temporaryUser") {
                this.temporaryuser = true
                this.email = res1.email
                this.firstLetter = this.email[0].toUpperCase()
                if (this.cookieService.get('is_verified') == "true") {
                  this.verified = true;
                  // this.dataService.changeHeight(75)
                }
                else {
                  this.verified = false;
                  // this.dataService.changeHeight(95)
                }
              }
              else {
                this.user = true
                this.temporaryuser = false
                if (res1.status == "verified") { this.verified = true; }
                else {
                  this.verified = false;
                  // this.dataService.changeHeight(95)
                }
              }
            }
            else{
              this.logoutBtn = false
              this.temporaryuser = false
              this.user = false
            }
          }
          else {
            if (this.cookieService.get("token")) {
 
              this.logoutBtn = true
              var DecodedToken: any = jwt_decode(this.cookieService.get("token"));
              if (DecodedToken && DecodedToken.data && DecodedToken.data._id) {
                this.authService.currentUser = DecodedToken.data
                this.myuser = DecodedToken.data
                if (DecodedToken.data && DecodedToken.data.additional_data && DecodedToken.data.additional_data.photo) {
                  this.profilePic = DecodedToken.data.additional_data.photo
                }
                else {
                  this.profilePic = "https://cquipsplus.ca/wp-content/themes/cera/assets/images/avatars/user-avatar.png"
                }
                if (this.cookieService.get("usertype") == "temporaryUser") {
                  this.temporaryuser = true
                  this.email = DecodedToken.data.email
                  this.dataService.temporaryUserCurrentEmail = this.email
                  this.firstLetter = this.email[0].toUpperCase()
                  if (this.cookieService.get('is_verified') == "true") {
                    this.verified = true;
                  }
                  else {
                    this.verified = false;
                  }
                }
                else {
                  this.user = true
                  if (res1.status == "verified") { this.verified = true; }
                  else {
                    this.verified = false;
                  }
                }

              }

            }
            else{
              this.logoutBtn = false
              this.temporaryuser = false
              this.user = false
            }
          }
        })
    })
    if (this.dataService.temporaryUserCurrentEmail != "") {
      this.firstLetter = this.dataService.temporaryUserCurrentEmail[0].toUpperCase()
    }    
    // this.dataService.currentverifiedStatus.subscribe((status) => {

    // if (status) {
    //   this.verifiedBar = true
    //   this.verified = true
    //   this.dataService.changeHeight(95)

    // }
    // else {
    //   this.dataService.changeHeight(75)
    // }
    // })
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (document.getElementById("dropdown")) {
      if (!document.getElementById("dropdown").contains(event.target)) {
        this.openDropDown = false
      }
    }
    if (document.getElementById("dropdownUser")) {
      if (!document.getElementById("dropdownUser").contains(event.target)) {
        this.openDropDownUser = false
      }
    }
  }
  closeBar() {
    this.verifiedBar = false
  }
  logout() {
    if (this.cookieService.get("token")) {
      Swal.fire({
        title: "Are you sure want to logout?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, log me out!",
        cancelButtonText: "No, keep me in!",
      }).then((result) => {
        if (result.value) {
          if (this.cookieService.get("token")) {
            var DecodedToken: any = jwt_decode(this.cookieService.get("token"));
            if (DecodedToken && DecodedToken.data && DecodedToken.data._id) {
              this.authService.logout(DecodedToken.data._id).subscribe(((res: any) => {

                if (res && res.status == "success") {
                  this.authService.currentUser = null
                  this.authService.userLoggedIn.next(false)
                  this.verified = true;
                  this.verifiedBar = false;
                  this.logoutBtn = false;
                  this.temporaryuser = false;
                  this.user = false
                  this.dataService.notifyAboutChange("logout")
                  if (this.shared_event) { this.router.navigateByUrl("portal/hybridevent/" + this.shared_event._id) }
                  else {
                    this.router.navigateByUrl("/")
                  }
                }
              }))
            }
          }

        }
        else if (result.dismiss == Swal.DismissReason.cancel) { }
      });
    }
    else {
      window.location.reload();
      this.authService.currentUser = null
      this.authService.userLoggedIn.next(false)
      this.verified = true;
      this.verifiedBar = false;
      this.logoutBtn = false;
      this.temporaryuser = false;
      this.user = false
      this.dataService.notifyAboutChange("logout")
      if (this.shared_event) { this.router.navigateByUrl("portal/hybridevent/" + this.shared_event._id) }
      else {
        this.router.navigateByUrl("/")
      }
    }
  }
  Login() {
    this.authService.verifyUserIsLoggedIn().subscribe((res: any) => {
      if (res && res.status == "success") {
        window.location.reload();
        if (this.cookieService.get('token')) {
          this.DecodedToken = jwt_decode(this.cookieService.get('token'));
          this.userServices.onChangedUser.next(this.DecodedToken.data)
        }
      }
      else {
        this.dataService.getAccessPopup("login")
        this.changeDetectorRef.detach();
        const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
          context: {
            data: {
              loginType: "login",
              authPopup: true
            }
          },
          hasBackdrop: false
        }).onClose.subscribe((res) => {
          this.changeDetectorRef.reattach();
        });
      }
    })
  }
  Signup() {
    this.openDropDown = false
    this.dataService.getAccessPopup("login")
    this.changeDetectorRef.detach();
    const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
      context: {
        data: {
          signType: "signup",
          fromNavbar: true,
          authPopup: true
        },
      },
      hasBackdrop: false
    }).onClose.subscribe((res) => {
      this.changeDetectorRef.reattach();
    });
  }
  logoutTemporaryUser() {
    var DecodedToken: any = jwt_decode(this.cookieService.get("token"));
    if (DecodedToken && DecodedToken.data && DecodedToken.data._id) {
      this.authService.logout(DecodedToken.data._id).subscribe(((res: any) => {
        if (res && res.status == "success") {
          this.authService.currentUser = null
          this.authService.userLoggedIn.next(false)
          this.logoutBtn = false;
          this.temporaryuser = false
          this.dataService.notifyAboutChange("logout")
          if (this.shared_event) { this.router.navigateByUrl("portal/hybridevent/" + this.shared_event._id) }
          else {
            this.router.navigateByUrl("/")
          }
        }
      }))
    }
  }
  OpenDropDown() {
    this.openDropDown = !this.openDropDown
  }
  OpenDropDownUser() {
    this.openDropDownUser = !this.openDropDownUser
  }
  changeEmail() {
    this.openDropDown = false
    const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
      context: {
        data: {
          authPopup: true,
          loginType: "login",
          changeEmail: true
        }
      },
      hasBackdrop: false
    }).onClose.subscribe((res) => {
      this.changeDetectorRef.reattach();
    });
  }
  confirmAcount() {
    let verif
    if (this.cookieService.get("usertype") == "User") {
      verif = "auth"
    }
    else if (this.cookieService.get("usertype") == "temporaryUser") {
      verif = "email"
    }
    this.changeDetectorRef.detach();
    const dialogRef = this.dialog.open(SharedComponentJoinEventComponent, {
      context: {
        data: {
          confirmFromtop: true,
          verifPopup: true,
          verif: verif
        },
      },
      hasBackdrop: false
    }).onClose.subscribe((res) => {
      this.changeDetectorRef.reattach();
    });
  }
  navigate() {
    if (this.router.url != '/') { this.router.navigateByUrl('/') }
  }
  gotoDashboard() {
    if (this.authService.currentUser && (this.authService.currentUser.role.includes("admin") || this.authService.currentUser.role.includes("agency"))) 
    { 
      this.router.navigateByUrl('/dashboard') 
    }
  }
}
