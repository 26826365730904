<div style="background-color: #0E1120;" >
    <div   class="publish-part" [style.background-image]="'url('+top_footer_config.url+')'">
        <div class="publish-paragraphe">
            <div *ngIf="top_footer_config && top_footer_config.welcome_message  && top_footer_config.welcome_message !=''" class="bigTitle Quicksand-bold"
                style="text-align: left;">
                <div [innerHTML]="top_footer_config.welcome_message"></div>

            </div>
            <div *ngIf="top_footer_config && top_footer_config.message  && top_footer_config.message !=''">


                <div class="subTitle publish-text Quicksand-medium">
                    <div [innerHTML]="top_footer_config.message"></div>
                </div>
                <div class="smallText speaker-showMore Quicksand-bold" style="width: 242px;height: 40px;">CREATE
                    EXPERIENCE
                </div>
            </div>
        </div>
    </div>
    <div class="footer-explore">
        <div style="color: #8F9BB3;display: inline-flex;width: 100%;justify-content: center;    align-items: center;
        ">
            <div class="menu-footer">
                <div class="sub-menu">
                    <div class="paragraphe Quicksand-medium" style="color: #8F9BB3;">Home</div>
                    <div class="paragraphe Quicksand-medium" style="color: #8F9BB3;">Contact us</div>
                    <div class="paragraphe Quicksand-medium" style="color: #8F9BB3;">Terms of service
                    </div>
                    <div class="paragraphe Quicksand-medium" style="color: #8F9BB3;">About us</div>
                </div>
                <div class="sub-menu">
                    <div class="paragraphe Quicksand-medium" style="color: #8F9BB3;">Description</div>
                    <div class="paragraphe Quicksand-medium" style="color: #8F9BB3;">Sessions</div>
                    <div class="paragraphe Quicksand-medium" style="color: #8F9BB3;">Speakers</div>
                    <div class="paragraphe Quicksand-medium " style="color: #8F9BB3;">Exhibitors</div>
                </div>
            </div>
            <div style="display:inline-flex; justify-content: center;width:40%;">
                <div class="paragraphe-footer">
                    <div *ngIf="bottom_footer_config && bottom_footer_config.title && bottom_footer_config.title!=''" class="bigTitle speakers-title Quicksand-bold" style="color: #8F9BB3;text-align: left;">
                       <div [innerHTML]="bottom_footer_config.title"> </div>
                    </div>
                    <div *ngIf="bottom_footer_config && bottom_footer_config.description && bottom_footer_config.description!=''" class="paragraphe" style="color: #8F9BB3;line-height: 28px;max-width: 55vh;">
                        <div [innerHTML]="bottom_footer_config.description"> </div>
                    </div>
                    <div class="footer-icons">
                        <img style="width: 30px;height: 30px;" src="../../../assets/twiter.png">
                        <img style="width: 30px;height: 30px;" src="../../../assets/fb.png">
                        <img style="width: 30px;height: 30px;" src="../../../assets/IN.png">
                        
                        <!-- <img style="width: 30px;height: 30px;" src="../../assets/IN.png"> -->
                        <img style="width: 30px;height: 30px;" src="../../../assets/url.png">
                    </div>
                </div>
            </div>
        </div>
        <div class="copy-right tagsText Quicksand-regular">
            © 2022 Incept. All rights reserved.
        </div>

    </div>
</div>