import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from '../services/toastr.service';

@Component({
  selector: 'app-toastr',
  templateUrl: './toastr.component.html',
  styleUrls: ['./toastr.component.css']
})
export class ToastrComponent implements OnInit {
   toastr_type
   toastr_message
   toastr_title
   display_toastr="hidden"
   autoclose_toastr=false
  constructor(public toastrService:ToastrService) { }

  ngOnInit(): void {
    this.toastrService.currentData.subscribe((data:any) => {
       this.toastr_type=data.type
      this.toastr_message=data.message
      this.toastr_title=data.title
      this.display_toastr=data.display
      this.autoclose_toastr=data.autoclose
     


      if(this.autoclose_toastr){
        
        setTimeout(() => {
           this.closeToastr() 
        }, 20000);
      }

    })
  
  

  }
  closeToastr(){
    this.toastrService.changeData({display:"hidden"})
  }
   

}
