<div style="color:white;font-family: 'Quicksand';margin-left: 10px;">
    <div style="font-size: 22px;margin-bottom: 10px;">
        Registration & Access to event
    </div>
    <div style="display: inline-flex;width: 100%; border-bottom: 1px solid #282a33; ">
        <div (click)="tab='access'" style="cursor: pointer;padding: 10px 0;margin-right: 10px;" [ngClass]="tab=='access'? 'ActiveTab' : ''">Access Event & Registration</div>
        <div (click)="tab='attendees'" style="cursor: pointer;padding: 10px 0;" [ngClass]="tab=='attendees'? 'ActiveTab' : ''">Attendees
        </div>
    </div>
    <div style="margin-top: 10px;">
        <div *ngIf="tab=='access'">
            <div>
                <div style="font-weight: 600;  font-size: 18px;">Who can enter this event when it’s live?</div>
                <div *ngIf="!editAccess" style="display: inline-flex;">
                    <div style="display: inline-flex;align-items: center;">
                        <div *ngIf="event.access.includes('open')">
                            <i class="fa fa-globe" aria-hidden="true" sstyle="font-size:18px;margin-right: 5px;"></i>
                            <span>
                                Anyone can enter the event
                            </span>
                        </div>
                        <div *ngIf="event.access.includes('protected_password')">
                            <i class="fa fa-key" aria-hidden="true" sstyle="font-size:18px;margin-right: 5px;"></i>
                            <span>
                                Anyone can only access by entering the event password
                            </span>
                        </div>
                        <div *ngIf="event.access.includes('email') && !event.is_inviteonly">
                            <i class="fa fa-lock" aria-hidden="true" sstyle="font-size:18px;margin-right: 5px;"></i>
                            <span>
                                Anyone can enter after entering the email
                            </span>
                        </div>
                        <div *ngIf="event.access.includes('auth') && !event.is_inviteonly">
                            <i class="fa fa-lock" aria-hidden="true" sstyle="font-size:18px;margin-right: 5px;"></i>
                            <span>
                                Anyone can enter after sign in
                            </span>
                        </div>

                        <div *ngIf="event.access.includes('auth') && event.is_inviteonly">
                            <i class="fa fa-envelope-open-o" aria-hidden="true" sstyle="font-size:18px;margin-right: 5px;"></i>
                            <span>
                                Only people who are invited can enter after sign in
                            </span>
                        </div>
                        <div *ngIf="event.access.includes('email') && event.is_inviteonly">
                            <i class="fa fa-envelope-open-o" aria-hidden="true" sstyle="font-size:18px;margin-right: 5px;"></i>
                            <span>
                                Only people who are invited can enter after entering the email
                            </span>
                        </div>
                    </div>
                    <div style="margin: 10px; cursor: pointer;" (click)="editAccess=!editAccess">
                        <div><img src="../../../assets/edit.png" /> </div>
                    </div>
                </div>
                <div *ngIf="editAccess" style="border: 1px solid;  width: 480px; padding: 10px; margin: 10px 0; border-radius: 5px;padding-top: 0;">
                    <i class="fa fa-long-arrow-left" aria-hidden="true" style="cursor: pointer;position: relative; left: -5px;" (click)="editAccess=false"></i>
                    <form [formGroup]="EditEventAccess">
                        <div class="form-group">
                            <div class="flex-container" class="btn-group btn-group-toggle" data-toggle="buttons" (change)="selectAccess($event)" style="display: flex; flex-direction: column;">
                                <div>
                                    <input *ngIf="disabled_access" type="radio" id="open" disabled />
                                    <input *ngIf="!disabled_access" type="radio" id="open" value="open" name="access" formControlName="access" />
                                    <label class="flex-item btn   flex-item-config" for="open" [ngStyle]="{'color':!disabled_access ? 'white':'gray'} ">
                                        Anyone can enter the event
                                    </label>
                                </div>
                                <div>
                                    <input *ngIf="disabled_access" type="radio" id="protected_password" disabled />
                                    <input *ngIf="!disabled_access" type="radio" id="protected_password" value="protected_password" name="access" formControlName="access" />
                                    <label class="flex-item btn  flex-item-config" for="protected_password" [ngStyle]="{'color':!disabled_access ? 'white':'gray'} ">
                                        Anyone can only access by entering the event password
                                    </label>
                                    <div *ngIf="formControls.access.value=='protected_password'" style="display: inline-flex;align-items: center; width: 100%;">
                                        <div *ngFor="let i of  counter(4);let index =index " style="display: inline-flex;align-items: center;">
                                            <div style="border:1px solid white;width: 30px;
                                            height: 30px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            border-radius: 5px;
                                            margin-right: 20px;">{{generatedNumber.toString()[index]}}</div>
                                        </div>
                                        <div (click)=" onNumberGenerated()">
                                            <img src="../../../assets/refresh.png" style="width: 30px;height: 30px;cursor: pointer;" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <input type="radio" id="email" value="email" name="access" formControlName="access" />
                                    <label class="flex-item btn   flex-item-config" for="email">
                                        Anyone can enter after entering the email
                                    </label>
                                </div>
                                <div>
                                    <input type="radio" id="auth" value="auth" name="access" formControlName="access" />
                                    <label class="flex-item btn   flex-item-config" for="auth">
                                        Anyone can enter after sign in
                                    </label>
                                </div>

                            </div>
                        </div>
                        <div style="margin: 5px 0;">
                            <input *ngIf="!activate_is_inviteonly" type="checkbox" disabled id="is_inviteonly" />
                            <input *ngIf="activate_is_inviteonly" type="checkbox" (change)="is_inviteonly=!is_inviteonly" [value]="is_inviteonly" id="is_inviteonly" name="is_inviteonly" formControlName="is_inviteonly" />
                            <label class="LabelCheckbox" style="margin-left: 10px; cursor: pointer;" [ngStyle]="{'color':activate_is_inviteonly ? 'white':'#212529'} " for="is_inviteonly">
                                Only people who are invited can enter
                            </label>
                        </div>
                    </form>
                    <div (click)="editAccessEvent()" style="cursor: pointer;border: 1px solid;   width: 100px; margin: auto;  text-align: center;  border-radius: 5px; padding: 5px;">
                        Save
                    </div>
                </div>
            </div>
            <div>
                <div style="font-weight: 600;  font-size: 18px;">People registering for the event may passing by an additional form.</div>
                <div *ngIf=" event && (event.registration && event.registration.length==0 || !event.registration)"> No additional form exists
                    <span style="margin: 10px; cursor: pointer" (click)=" openAdditionalForm() ">
                        <i class="fa fa-plus-square-o" aria-hidden="true"
                            style="color: #323249;font-weight: bold; font-size: 18px;position: relative;top: 2px;"></i>
                    </span>
                </div>
                <div *ngIf="event && event.registration && event.registration.length>0 ">
                    <div style=" width: 60%; border: 1px solid;padding: 20px; margin: 20px 0;border-radius: 5px;">
                        <span (click)="openAdditionalForm()" style="float: right;cursor: pointer;"><img
                                src="../../../assets/edit.png" /></span>
                        <div style="text-align: center; font-size: 18px;">Additional form</div>
                        <div *ngFor="let item of event.registration | sort:'position'">
                            <div *ngIf="item.type == 'upload'">
                                <div style="margin: 5px 0;">{{item.field}} <span *ngIf="item.required">*</span></div>
                                <input (change)="readURL($event)" type="file" id="file" style="display: none;" [required]="item.required">
                                <label *ngIf="!imageSrc || imageSrc ==''" for="file" style="    display: flex;
                                flex-direction: column; justify-content: center;align-items: center;
                               height: 115px; border: 1px dashed;    border-radius: 50%; cursor: pointer; ">
                                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                                    Upload image
                                </label>
                                <div *ngIf="imageSrc && imageSrc !=''" style="color: white; width: 95px;text-align: center; position: relative;top: 20px; text-align: right;">

                                    <i class="fa fa-times-circle-o" style="color: #0e1120; background-color: white; cursor: pointer; border-radius: 50%;" (click)="imageSrc=''" aria-hidden="true"></i>
                                </div>
                                <label *ngIf="imageSrc && imageSrc !=''" style="    display: flex;
                                flex-direction: column; justify-content: flex-end;align-items: center;overflow: hidden;
                                height: 115px; border: 1px dashed;    border-radius: 50%; background-position: center;background-size: cover; " [style.background-image]="'url('+imageSrc+')'">
                                    <label for="file" style="cursor: pointer;margin-bottom: unset !important">
                                        <i class="fa fa-camera"
                                            style="color: white;background: #0e1120; width: 110px;text-align: center; height: 20px;border-radius: 50%;"
                                            aria-hidden="true"></i></label>
                                </label>
                            </div>
                            <div *ngIf="item.type == 'email'">
                                <div style="margin: 5px 0;">{{item.field}} <span *ngIf="item.required">*</span></div>
                                <input type="text" class="form-control" [placeholder]="item.field" [required]="item.required" />
                            </div>
                            <div *ngIf="item.type == 'single-line'">
                                <div style="margin: 5px 0;">{{item.field}} <span *ngIf="item.required">*</span></div>
                                <input type="text" class="form-control" [placeholder]="item.field" [required]="item.required" />
                            </div>
                            <div *ngIf="item.type == 'paragraph'">
                                <div style="margin: 5px 0;">{{item.field}} <span *ngIf="item.required">*</span></div>
                                <textarea type="text" class="form-control" [placeholder]="item.field" [required]="item.required"></textarea>
                            </div>
                            <div *ngIf="item.type == 'multi-select'">
                                <div style="margin: 5px 0;">{{item.field}} <span *ngIf="item.required">*</span></div>
                                <div class="form-control" style="height: 100%;">
                                    <div *ngFor="let option of item.options;let last=last" style="margin: 5px 0;">
                                        <input type="checkbox" [id]="option" style="margin-right: 10px;">
                                        <label [for]="option" style="margin-bottom: 0 !important;">{{option}}</label>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="item.type == 'single-select'">
                                <div style="margin: 5px 0;">{{item.field}} <span *ngIf="item.required">*</span></div>
                                <div class="form-control" style="height: 100%;">
                                    <div *ngFor="let option of item.options;let last=last" style="margin: 5px 0;">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" [id]="option">
                                            <label class="form-check-label" [for]="option">
                                                {{option}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="tab=='attendees'">
            <div>aaaaaaaaaaaa</div>
        </div>
    </div>
    <div class="asideBar" id="asideBar" [ngClass]="additionForm  ?'asideBarOpened':'asideBarClosed'" [style.top.px]="nav_bar_height">
        <div class="InsideAsideBar">
            <div><i class="fa fa-times" style="float: right;cursor: pointer;" (click)="closeAsidebar()"></i></div>
            <div style="font-weight: 600;  font-size: 18px;text-align: center;">Additional Form</div>
            <div style="width: 100%;margin-top: 30px; border: 1px solid white;border-radius: 5px;">
                <div style="width: 100%; display:inline-flex;padding:10px;border-bottom: 1px solid white;font-weight: bold;">
                    <div style="width: 10%;"></div>
                    <div style="width: 40%;">Registration field</div>
                    <div style="width: 20%; ">Type</div>
                    <div style="width: 15%;text-align: center;">Related</div>
                    <div style="width: 10%;text-align: center;">Required</div>
                    <div style="width: 5%;"></div>
                </div>
                <div *ngFor="let field of fields.value| sort:'position';let last=last;let index=index" [ngStyle]="{'border-bottom':!last ? '1px solid white':'none'} ">
                    <div *ngIf="field.type!='multi-select' && field.type!='single-select'" style="width: 100%; display:inline-flex;padding:10px;">
                        <div style="width: 10%;">{{field.title}} -
                            <span style="display: inline-flex;">
                                <i class="fa fa-long-arrow-down UpDown"  (click)="UpDownFields('down', field)" aria-hidden="true"></i>
                                <i class="fa fa-long-arrow-up UpDown" (click)="UpDownFields('up', field)" aria-hidden="true"></i>

                            </span>
                        </div>
                        <div style="width:40%;">
                            <div>
                                {{field.field}}
                            </div>
                            <div style="font-size: 12px;">
                                {{field.attribute}}
                            </div>

                        </div>
                        <div style="width: 20%; ">{{field.type}}</div>
                        <div style="width: 15%;text-align: center;">
                            <i *ngIf="field.related" class="fa fa-check" aria-hidden="true"></i>
                        </div>

                        <div style="width: 10%;text-align: center;">
                            <input type="checkbox" [checked]="field.required" (change)="changeRequired($event,index)" style="cursor: pointer;">
                        </div>
                        <div style="width: 5%;text-align: center;">
                            <i class="fa fa-trash" (click)="removeField(index,field)" style="cursor: pointer;"></i>
                        </div>
                    </div>
                    <div *ngIf="field.type=='multi-select' || field.type=='single-select'">
                        <div style="width: 100%; display:inline-flex;padding:10px;">
                            <div style="width: 10%;">{{field.title}} - <span style="display: inline-flex;">
                                <i class="fa fa-long-arrow-down UpDown"  (click)="UpDownFields('down', field)" aria-hidden="true"></i>
                                <i class="fa fa-long-arrow-up UpDown" (click)="UpDownFields('up', field)" aria-hidden="true"></i>

                            </span></div>
                            <div style="width:40%;">
                                <div>
                                    {{field.field}}
                                </div>
                                <div style="font-size: 12px;">
                                    {{field.attribute}}
                                </div>
                            </div>
                            <div style="width: 20%; ">{{field.type}}</div>
                            <div style="width: 15%;text-align: center;"> <i *ngIf="field.related" class="fa fa-check" aria-hidden="true"></i>
                            </div>

                            <div style="width: 10%;text-align: center;">
                                <input type="checkbox" [checked]="field.required" (change)="changeRequired($event,index)" style="cursor: pointer;">
                            </div>
                            <div style="width: 5%;text-align: center;">
                                <i class="fa fa-trash" (click)="removeField(index,field)" style="cursor: pointer;"></i>
                            </div>
                        </div>
                        <div style="width: 80%;margin-left: 100px;">
                            <div *ngFor="let option of field.options">
                                <div>-{{option}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div style="display: inline-flex;   margin: 20px 0 0 0;width: 100%;justify-content: space-between;">
                    <div [ngClass]="openRelatedField ? 'selectedOption':'unSelectedOption'" (click)="openRelatedField=true;openCustomField=false">
                        Add related fields <span style="margin-left: 10px; border: 1px solid; padding: 0 5px; border: 1px solid; border-radius: 5px;">+</span>
                    </div>
                    <div [ngClass]="openCustomField ? 'selectedOption':'unSelectedOption'" (click)="openCustomField=true;openRelatedField=false">
                        Add Custom fields <span style="margin-left: 10px; border: 1px solid; padding: 0 5px; border: 1px solid; border-radius: 5px;">+</span>
                    </div>
                </div>
                <div *ngIf="openRelatedField" style="border: 1px solid; border-radius: 5px;padding: 10px;border-top-left-radius:0 !important">
                    <div *ngFor="let option of dropdownList;let last=last" style="margin: 5px 0;">
                        <input type="checkbox" [id]="option.field" style="margin-right: 10px;" (change)="addRelatedField(option,$event)" [checked]="relatedFields && relatedFields.includes(option) || is_include(option) ">
                        <label [for]="option.field" style="margin-bottom: 0 !important;">{{option.field}} </label>
                    </div>
                </div>
                <div *ngIf="openCustomField">
                    <div style="border: 1px solid; border-radius: 5px;padding: 10px;border-top-right-radius:0 !important">
                        <div>
                            <div>Field name</div>
                            <input type="text" class="form-control" placeholder="Enter the name of the field" id="fieldname" style="margin: 5px 0 10px 0;" [(ngModel)]="fieldname">
                        </div>
                        <div>
                            <div>Input type</div>
                            <select class="form-control" class="form-control" (change)="selectTypeInput($event)" style="margin:10px 0;">
                                <option value="single-line" selected> Single line text </option>
                                <option value="paragraph"> Paragraph text </option>
                                <option value="multi-select"> Mutli select </option>
                                <option value="single-select"> Single select </option>
                            </select>
                        </div>
                        <div *ngIf="selectedtype=='multi-select' || selectedtype=='single-select' ">
                            <div>Options</div>
                            <div>
                                <div *ngFor="let option of options;let last=last">
                                    <div style="display: inline-flex;justify-content: space-between;width: 100%;    border: 1px solid;
                                    border-radius: 5px; padding: 5px;margin: 5px 0;">
                                        <div>
                                            {{option}}
                                        </div>
                                        <div>
                                            <i class="fa fa-times" style="cursor: pointer;" (click)="addAndRemoveOptions('remove',option)"></i>
                                        </div>
                                    </div>
                                    <input *ngIf="last" type="text" class="form-control" id="input" style="width: 60%;" placeholder="Enter option" (keydown.enter)="addAndRemoveOptions('add',$event.target.value)" (input)="input=$event.target.value" />
                                </div>
                                <div *ngIf="options.length==0">
                                    <input type="text" class="form-control" style="width: 60%;" id="input" placeholder="Enter option" (keydown.enter)="addAndRemoveOptions('add',$event.target.value)" (input)="input=$event.target.value" />
                                </div>
                                <div *ngIf="input !=''" style="font-size: 12px; margin-top: 5px;">Click enter to save the option
                                </div>
                            </div>
                        </div>
                        <div *ngIf="is_error[0]" style="color: red;">{{is_error[1]}}</div>
                        <div (click)="addCustomField()" style="width: 100px; border: 1px solid;margin: auto;margin-top: 20px;text-align: center;border-radius: 5px;padding: 3px;cursor:pointer;">
                            Add</div>
                    </div>
                </div>
                <div (click)="onSubmit()" style="width: 100px;border: 1px solid;margin: auto;    margin-right: unset;margin-top: 20px;text-align: center;border-radius: 5px;padding: 3px;cursor:pointer;">
                    Save</div>
            </div>
        </div>
    </div>