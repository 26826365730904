import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { PortalService } from '../../portal.service';
import * as lodash from 'lodash';
import { FormControl } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ThisReceiver } from '@angular/compiler';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { VideoPlayerComponent } from 'src/app/video-player/video-player.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.css'],
})
export class SessionComponent implements OnInit {
  @ViewChildren('video') video: any;
  @Input() rooms
  @Input() myRooms
  @Input() myDays;
  @Input() availableSessions;
  @Input() total_Fsessions;

  sessions: any = []
  days
  selectedday = "0"
  status = "Live"
  selectedRoom: any;
  timerRooms
  rightArrowValue: number;
  activated: boolean = false
  selected_session: any;
  show: boolean;
  speaker: any;
  selectedSpeaker: any;
  selectedSession: any;
  showBox: boolean;
  liveRooms: any = [];
  roomsToShow: any = [];
  rightArrowValueDay: number;
  overflowActivated: boolean;
  overflowActivateddays: boolean;
  overflowActivatedRooms: boolean;
  room_name: any;
  selectedVideo: any;
  muted: any;
  playWhite: boolean = true;
  playRed: boolean = false;
  line: number;
  show_sessions_bool: boolean;
  more_sessions = { page: '1', limit: '4' };
  current_number_Fsessions: number;
  first_availableSessions: any = []
  showFSpeaker: boolean;
  selectedFSpeaker: any;
  selectedFSession: any;
  selectedDropDown: any;
  tags: any = [];
  speakers: any = [];
  roomsFilter: any = []
  selectedFilterTag = "Tags"
  selectedFilterSpeaker = "Speakers"
  selectedFilterRooms = "Rooms"
  showDropdown: boolean = false;
  filterArray: any = [];
  currentDivId: any;
  currentParentId: any;

  overflowFilterActivated: boolean = false
  rightArrowValueFilter: number;
  constructor(private portaleventservice: PortalService, private shareddata: DataService, private elem: ElementRef,public dialog: NbDialogService,public router: Router) {
  }
  ngOnInit(): void {

    this.days = this.shareddata.event.days
    this.selectedRoom = this.rooms[0]
    this.rooms[0].sessions.forEach(session => {
      if (session.day_index == this.selectedday) {
        this.sessions.push(session)
      }
    });    
    this.rooms.forEach(room => {
      if (room.is_live) {
        this.liveRooms.push(room)
      }
    });
    if (this.availableSessions) {
      this.current_number_Fsessions = this.availableSessions.length
    }
    this.portaleventservice.getSpeakersAndTagsOfSessionsByEventid().then((res: any) => {
      if (res.status == "success") {
        if (res.tags) {
          this.tags = res.tags
        }
        if (res.speakers) {
          this.speakers = res.speakers
        }
        if (res.rooms) {
          this.roomsFilter = res.rooms
        }
      }
    })
    this.first_availableSessions = this.availableSessions
  }
  ngAfterViewInit() {

    if (document.getElementById(this.selectedday)) {
      document.getElementById(this.selectedday).style.background = "linear-gradient(#ffffff 0px, #ffffff 0px) left bottom / 25% 4px no-repeat";
    }
    if (document.getElementById("Days") && document.getElementById("days-container")) {
      if (document.getElementById("Days").scrollWidth > document.getElementById("days-container").offsetWidth) {
        this.overflowActivateddays = true
        document.getElementById("days-container").scrollLeft = 0
      }
    }
    if (document.getElementById("Rooms") && document.getElementById("Rooms-container")) {
      if (document.getElementById("Rooms").scrollWidth > document.getElementById("Rooms-container").offsetWidth) {
        this.overflowActivatedRooms = true
      }
    }
    if (document.getElementById("optionFilter") && document.getElementById("selectedOptions-container")) {
      if (document.getElementById("optionFilter").scrollWidth > document.getElementById("selectedOptions-container").offsetWidth) {
        this.overflowFilterActivated = true
      }
    }
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // this.onReseizeScreen()
    if (document.getElementById("Days") && document.getElementById("days-container")) {
      if (document.getElementById("Days").scrollWidth > document.getElementById("days-container").offsetWidth) {
        this.overflowActivateddays = true
      }
      else {
        this.overflowActivateddays = false
        this.rightArrowValueDay = 0
      }
    }
    if (document.getElementById("Rooms") && document.getElementById("Rooms-container")) {
      if (document.getElementById("Rooms").scrollWidth > document.getElementById("Rooms-container").offsetWidth) {
        this.overflowActivatedRooms = true
      }
      else {
        this.overflowActivatedRooms = false
        this.rightArrowValue = 0
      }
    }
    if (document.getElementById("optionFilter") && document.getElementById("selectedOptions-container"))
    {
      
      if (document.getElementById("optionFilter").scrollWidth > document.getElementById("selectedOptions-container").offsetWidth) {
        this.overflowFilterActivated = true
      }
      else {
        this.overflowFilterActivated = false
        this.rightArrowValueFilter = 0
      }
    }
    
  }
  

  @HostListener('document:click', ['$event'])
  clickout(event) {

    // if (!document.getElementById("dropdown-tags").contains(event.target) && !document.getElementById("dropdown-speakers").contains(event.target) && !document.getElementById("dropdown-rooms").contains(event.target)) {
    //   this.closeAllDropDowns()
    // }
  }
  showMoreFinishedSessions() {
    this.show_sessions_bool = true
    this.more_sessions.page = (Number(this.more_sessions.page) + 1).toString();
    this.portaleventservice.getMoreFinishedSessions(this.more_sessions, "").then((res: any) => {
      if (res) {
        // if (res.total == this.total_Fsessions)
        // {
        res.sessions.forEach(session => {
          this.availableSessions.push(session)
        });
        // }
        // else if (res.total != this.total_Fsessions)
        // {
        //   this.availableSessions = []
        //   this.first_availableSessions = []

        //   this.availableSessions =  res.sessions
        //   this.first_availableSessions =  res.sessions

        // }


        this.current_number_Fsessions = Number(this.more_sessions.limit) * Number(this.more_sessions.page)
      }
    });
  }
  reduceFinishedSessions() {
    let first_session = [];
    for (let i = 0; i < 4; i++) {
      const session = this.first_availableSessions[i];
      first_session.push(session)
    }
    this.availableSessions = []
    this.first_availableSessions = []
    this.availableSessions = first_session
    this.first_availableSessions = this.availableSessions
    this.current_number_Fsessions = this.availableSessions.length
    this.more_sessions = { page: '1', limit: '6' };
  }
  setReminder(id, value) {
    this.selected_session = id
    if (value) {
if(document.getElementById("reminder" + id))
{

  document.getElementById("reminder" + id).style.visibility = "hidden"
  document.getElementById("reminder" + id).style.position = "absolute"
  document.getElementById("activated" + id).style.visibility = "visible"
  document.getElementById("activated" + id).style.position = "relative"
  this.activated = true
}
    }
    else {
      if(document.getElementById("reminder" + id))
{
      document.getElementById("reminder" + id).style.visibility = "visible"
      document.getElementById("reminder" + id).style.position = "relative"
      document.getElementById("activated" + id).style.visibility = "hidden"
      document.getElementById("activated" + id).style.position = "absolute"
      this.activated = false
    }
  }

  }
  getSessionByDay(i) {
    this.myRooms = []
    this.rooms.forEach(room => {

      let session: any = []
      session = room.sessions.filter(session => session.day_index == i)

      if (session != null && session.length > 0) {
        this.myRooms.push(room)
      }
    });

    if (document.getElementById(this.selectedday)) {
      document.getElementById(this.selectedday).style.background = "transparent";
      this.selectedday = i
      document.getElementById(this.selectedday).style.background = "linear-gradient(#ffffff 0px, #ffffff 0px) left bottom / 25% 4px no-repeat";
      this.sessions = []
      this.selectedRoom = this.myRooms[0]
      this.selectedRoom.sessions.forEach(session => {
        if (session.day_index == this.selectedday) {
          this.sessions.push(session)
        }
      });
    }
  }
  onClickRoom(id) {
    let room = lodash.find(this.rooms, { '_id': id });
    this.selectedRoom = room
    this.sessions = []
    room.sessions.forEach(session => {
      if (session.day_index == this.selectedday) {
        this.sessions.push(session)
      }
    });
  }
  showSpeaker(speaker, showpopup, sessionId) {
    this.selectedSpeaker = speaker
    this.selectedSession = sessionId
    if (showpopup) {
      this.show = true
      this.speaker = speaker
    }
    else {
      this.show = false
      this.speaker = null
    }
  }

  /********************** video ********************/
  showSpeakerInVideoCard(speaker, showpopup, sessionId) {
    this.selectedFSpeaker = speaker
    this.selectedFSession = sessionId
    if (showpopup) {
      this.showFSpeaker = true
      this.speaker = speaker
    }
    else {
      this.showFSpeaker = false
      this.speaker = null
    }
  }
  playVideo(id, play) {
    let div = lodash.find(this.video.toArray(), { 'nativeElement': document.getElementById(id) });

    if (div) {
      if (play) {
        this.selectedVideo = id
        div.nativeElement.play()
        if(document.getElementById(id) && document.getElementById("whitePlay" + id) && document.getElementById("redPlay" + id))
        {

          document.getElementById(id).classList.add("hover")
          document.getElementById(id).classList.remove("notHover")
          document.getElementById("whitePlay" + id).style.visibility = "hidden"
          document.getElementById("whitePlay" + id).style.position = "absolute !important"
          document.getElementById("redPlay" + id).style.visibility = "visible"
          document.getElementById("redPlay" + id).style.position = "relative !important"
        }
       
      }
      else {
        this.selectedVideo = ""
        div.nativeElement.pause()
        if(document.getElementById(id) && document.getElementById("whitePlay" + id) && document.getElementById("redPlay" + id))
        {

          document.getElementById(id).classList.remove("hover")
          document.getElementById(id).classList.add("notHover")
          document.getElementById("whitePlay" + id).style.visibility = "visible"
          document.getElementById("whitePlay" + id).style.position = "relative !important"
          document.getElementById("redPlay" + id).style.visibility = "hidden"
          document.getElementById("redPlay" + id).style.position = "absollute !important"
        }
        }
    }
    else {
      let divImage = document.getElementById("image" + id)
      if (divImage) {
        if (play) {
          this.selectedVideo = id
          divImage.classList.add("hover")
          divImage.classList.remove("notHover")
          if(document.getElementById("whitePlay" + id) && document.getElementById("redPlay" + id))
        {
          document.getElementById("whitePlay" + id).style.visibility = "hidden"
          document.getElementById("whitePlay" + id).style.position = "absolute !important"
          document.getElementById("redPlay" + id).style.visibility = "visible"
          document.getElementById("redPlay" + id).style.position = "relative !important"
        }

          
        }
        else {
          this.selectedVideo = ""
          divImage.classList.remove("hover")
          divImage.classList.add("notHover")
          if(document.getElementById("whitePlay" + id) && document.getElementById("redPlay" + id))
          {

            document.getElementById("whitePlay" + id).style.visibility = "visible"
            document.getElementById("whitePlay" + id).style.position = "relative !important"
            document.getElementById("redPlay" + id).style.visibility = "hidden"
            document.getElementById("redPlay" + id).style.position = "absollute !important"
          }
        }
      }
    }
  }
  /**********************************************************/
  scrollDivRooms(elementToScroll: HTMLElement, depl, div, arrowId) {
    if (div == "Rooms") {
      if (this.rooms.length > 0) {
        if (document.getElementById(arrowId) != null) {
          elementToScroll = document.getElementById("Rooms") as HTMLInputElement
          elementToScroll.scrollLeft -= depl;
          this.rightArrowValue = elementToScroll.scrollLeft
          this.timerRooms = setTimeout(() => {
            this.scrollDivRooms(elementToScroll, depl, div, arrowId)
          }, 30);
        }
      }
    }
    else {
      if (this.days.length > 0) {
        if (document.getElementById(arrowId) != null) {
          elementToScroll = document.getElementById("days-container") as HTMLInputElement
          elementToScroll.scrollLeft -= depl;
          this.rightArrowValueDay = elementToScroll.scrollLeft
          this.timerRooms = setTimeout(() => {
            this.scrollDivRooms(elementToScroll, depl, div, arrowId)
          }, 30);
        }
      }
    }

  }
  stopTimerRooms(timerRooms: number) {
    clearTimeout(timerRooms);
  }
  showRoomsName(show) {
    if (show) {
      if(document.getElementById("liveBox"))
      {document.getElementById("liveBox").style.width = "300px"}

    }
    else {
      if(document.getElementById("liveBox"))
      {document.getElementById("liveBox").style.width = "93px"}
    }
  }

  GoToLiveRoom(id) {
    let room
    let index
    for (let i = 0; i < this.rooms.length; i++) {
      const r = this.rooms[i];
      if (r._id == id) {
        room = r;
        index = i
      }
    }
    room.sessions.forEach(session => {
      if (session.status == "Live") {
        document.getElementById(this.selectedday).style.background = "transparent";
        this.selectedday = session.day_index
        document.getElementById(this.selectedday).style.background = "linear-gradient(#ffffff 0px, #ffffff 0px) left bottom / 25% 4px no-repeat";
        this.onClickRoom(id)
        document.getElementById("room" + index).scrollIntoView(false);
        document.getElementById(this.selectedday).scrollIntoView(false);
        let elementToScroll = document.getElementById("Rooms") as HTMLInputElement
        this.rightArrowValue = elementToScroll.scrollLeft
        this.myRooms = []
        this.rooms.forEach(room => {

          let session: any = []
          session = room.sessions.filter(session => session.day_index == this.selectedday)

          if (session != null && session.length > 0) {
            this.myRooms.push(room)
          }
        });
      }
    });

  }
  onDayHover(i, visible, show) {
    if (show && visible) {
      document.getElementById(i).style.background = "linear-gradient(#ffffff 0px, #ffffff 0px) left bottom / 25% 4px no-repeat";
    }
    else {
      if (i == this.selectedday) {
        document.getElementById(i).style.background = "linear-gradient(#ffffff 0px, #ffffff 0px) left bottom / 25% 4px no-repeat";

      }
      else {
        document.getElementById(i).style.background = "transparent";
      }
    }
  }

  // hoverdiv(e, divid, visible,roomName) {
  //   let lines = 0
  //   this.room_name =  roomName

  //   if (document.getElementById("roomname"+divid) && document.getElementById("roomnameShow"))
  //   {
  //     var div = document.getElementById("roomname"+divid);
  //     var divtoshow = document.getElementById("roomnameShow");
  //     if (div) {
  //       lines = div.offsetHeight / 19
  //     }    
  //     if (visible && lines>1) {        

  //       divtoshow.style.visibility = "visible"
  //       divtoshow.style.position = "absolute"
  //       return false;
  //     }
  //     else {
  //       divtoshow.style.visibility = "hidden"
  //       divtoshow.style.position = "absolute"

  //     }
  //   }
  // }
  showDropDown(idDiv, idParent) {
    if (this.currentDivId && this.currentDivId == idDiv) {
      if ( document.getElementById(idDiv).style.visibility == "hidden")
      {
        document.getElementById(idParent).style.border = "1px solid #3c465f"
        document.getElementById(idDiv).style.visibility = "visible"
        document.getElementById(idDiv).style.zIndex = "2"
      }
      else if ( document.getElementById(idDiv).style.visibility == "visible")
      {
        document.getElementById(idParent).style.border = "1px solid rgb(25,32,56)"
        document.getElementById(idDiv).style.visibility = "hidden"
        document.getElementById(idDiv).style.zIndex = "0"
      }
     
    }
    else {
      if (!document.getElementById(this.currentDivId)) {
        document.getElementById(idParent).style.border = "1px solid #3c465f"
        document.getElementById(idDiv).style.visibility = "visible"
        document.getElementById(idDiv).style.zIndex = "2"
      }
      else {
        document.getElementById(this.currentParentId).style.border = "1px solid rgb(25,32,56)"
        document.getElementById(this.currentDivId).style.visibility = "hidden"
        document.getElementById(this.currentDivId).style.zIndex = "0"
        document.getElementById(idParent).style.border = "1px solid #3c465f"
        document.getElementById(idDiv).style.visibility = "visible"
        document.getElementById(idDiv).style.zIndex = "2"
      }
    }
    this.currentDivId = idDiv
    this.currentParentId = idParent
    if (document.getElementById("optionFilter") && document.getElementById("selectedOptions-container")) {
      if (document.getElementById("optionFilter").scrollWidth > document.getElementById("selectedOptions-container").offsetWidth) {
        this.overflowFilterActivated = true
      }
    }
     // this.showDropdown = !this.showDropdown    
    // if (this.showDropdown) {
    //   document.getElementById(idParent).style.border = "1px solid #3c465f"
    //   document.getElementById(idDiv).style.visibility = "visible"
    //   document.getElementById(idDiv).style.zIndex = "2"
    //   this.currentDivId = idDiv
    //   this.currentParentId = idParent 
    // }
    // else {
    //   document.getElementById(idParent).style.border = "1px solid rgb(25,32,56)"
    //   document.getElementById(idDiv).style.visibility = "hidden"
    //   document.getElementById(idDiv).style.zIndex = "0"
    // }
  }
  closeAllDropDowns() {
    document.getElementById("dropdown-tags").style.border = "1px solid rgb(25,32,56)"
    document.getElementById("filter-tags").style.visibility = "hidden"
    document.getElementById("filter-tags").style.zIndex = "0"
    document.getElementById("dropdown-speakers").style.border = "1px solid rgb(25,32,56)"
    document.getElementById("filter-speakers").style.visibility = "hidden"
    document.getElementById("filter-speakers").style.zIndex = "0"
    document.getElementById("dropdown-rooms").style.border = "1px solid rgb(25,32,56)"
    document.getElementById("filter-rooms").style.visibility = "hidden"
    document.getElementById("filter-rooms").style.zIndex = "0"
  }
  selectOption(filter, option, idDiv, idParent, idOption) {
    // this.showDropDown(idDiv, idParent)
    document.getElementById(idParent).style.border = "1px solid rgb(25,32,56)"
    document.getElementById(idDiv).style.visibility = "hidden"
    document.getElementById(idDiv).style.zIndex = "0"
    let found_option = lodash.find(this.filterArray, { 'id': idOption });
    if (!found_option) {
      if (filter == "tag") {
        this.filterArray.push({ name: "tag", value: option, id: idOption })
        this.selectedFilterTag = option
      }
      if (filter == "speaker") {
        this.filterArray.push({ name: "speaker", value: option, id: idOption })
        this.selectedFilterSpeaker = option.name
      }
      if (filter == "room") {
        this.filterArray.push({ name: "room", value: option, id: idOption })
        this.selectedFilterRooms = option.name
      }
      this.more_sessions.page = "1"

      this.portaleventservice.getMoreFinishedSessions(this.more_sessions, this.filterArray).then((res: any) => {

        this.first_availableSessions = []
        this.first_availableSessions = res.sessions
      })

    }
  
  }
  removeOption(index) {
    this.filterArray.splice(index, 1);
    if (document.getElementById("optionFilter") && document.getElementById("selectedOptions-container")) {
      if (document.getElementById("optionFilter").scrollWidth <= document.getElementById("selectedOptions-container").offsetWidth) {
        this.overflowFilterActivated = false
        this.rightArrowValueFilter = 0
      }
    }
    this.more_sessions.page = "1"

    this.portaleventservice.getMoreFinishedSessions(this.more_sessions, this.filterArray).then((res: any) => {
      this.first_availableSessions = []
      this.first_availableSessions = res.sessions
    })
  }
  scrollOptionsFilter(elementToScroll: HTMLElement, depl, div, arrowId) {
    
    if (this.filterArray && this.filterArray.length > 0) {
      if (document.getElementById(arrowId) != null) {
        elementToScroll = document.getElementById("optionFilter") as HTMLInputElement
        elementToScroll.scrollLeft -= depl;
        this.rightArrowValueFilter = elementToScroll.scrollLeft        
        this.timerRooms = setTimeout(() => {
          this.scrollOptionsFilter(elementToScroll, depl, div, arrowId)
        }, 30);
      }
    }
  }
  stopTimerFilter(timerRooms: number) {
    clearTimeout(timerRooms);
  }
  openVideo(i)
  {    
    const dialogRef = this.dialog.open(VideoPlayerComponent, {
      context: {
        data: {
          sessions : this.availableSessions,
          index : i,
          title : this.shareddata.event.event_title
        }
      },
      hasBackdrop: false
    }).onClose.subscribe((result) => {});
  }
  showMore(id)
  {
    this.router.navigateByUrl("/more/available-session/"+this.shareddata.eventid+"/"+id).then();
  }
  showScheduledSessionPlaylist(i)
  {
    this.router.navigateByUrl("/more/session/"+this.shareddata.eventid+"/"+i).then();
  }
}
