<div *ngIf="data && data.length>0" style="display: flex;flex-direction: column;height: 100%;width: 100%;padding: 25px 125px;gap: 20px;">
    <div style="display: flex;cursor: pointer;" (click)="BackToExplore()">
        <div class="arrowLeft"></div>
        <div class="Quicksand-regular paragraphe"> Back </div>
    </div>
    <div>
        <app-playlist-player [data]="data"></app-playlist-player>
    </div>
</div>


