
import { ExploreEventRoutingModule } from './explore-event-routing.module';
import { RecommendedEventsComponent } from './recommended-events/recommended-events.component';
import { UpcommingEventsComponent } from './upcomming-events/upcomming-events.component';
import { PlaylistComponent } from './playlist/playlist.component';
import { SpeakersComponent } from './speakers/speakers.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountdownModule } from 'ngx-countdown';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExploreEventComponent } from './explore-event.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InfoToasterComponent } from '../tosters/info-toaster/info-toaster.component';
import { SharedLayoutModule } from '../shared-layout/shared-layout.module';



@NgModule({
  declarations: [
    ExploreEventComponent,
    RecommendedEventsComponent,
    UpcommingEventsComponent,
    PlaylistComponent,
    SpeakersComponent,
    TestimonialsComponent,
    // InfoToasterComponent
  ],
  imports: [
    CommonModule,
    ExploreEventRoutingModule,
    RouterModule,
    ReactiveFormsModule,
    RouterModule,
    CountdownModule,
    FormsModule,
    NgxUsefulSwiperModule,
    LazyLoadImageModule,
    NgxSpinnerModule,
    SharedLayoutModule
   ],
   exports:[ ExploreEventComponent,
    RecommendedEventsComponent,
    UpcommingEventsComponent,
    PlaylistComponent,
    SpeakersComponent,
    TestimonialsComponent,]
})
export class ExploreEventModule { }
