import { animate, group, keyframes, query, style, transition, trigger } from "@angular/animations";
import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, Output, ViewChild, ViewChildren } from "@angular/core";
import { Router } from "@angular/router";
import { NbDialogService } from "@nebular/theme";
import { StateChange } from "ng-lazyload-image";
import { NgxSpinnerService } from "ngx-spinner";
import { config } from "src/config/config";
import { EditExplorePopupComponent } from "../dashboard-test/edit-explore-popup/edit-explore-popup.component";
import { ExploreService } from "../dashboard-test/explore.service";
import { ExploreEventService } from "./explore-event.service";
 
@Component({
  selector: 'app-explore-event',
  templateUrl: './explore-event.component.html',
  styleUrls: ['./explore-event.component.css'],

})

export class ExploreEventComponent implements OnInit {
  @Input() is_admin
  @ViewChildren('video') video: any;
  recommendedEventData
  events: any = []
  launchedEvents: any = []
  playDiapo: boolean = true;
  loading: boolean = true;
  divTest: HTMLElement
  active = "notactive"
  overflowActivatedCategorie: boolean = false
  ListCategories
  muted: boolean = false
  // ListCategories = [
  //   {
  //     name: "Dermatology",
  //     icon: "../../assets/iconEx1.png",

  //   },
  //   {
  //     name: "Dentistry",
  //     icon: "../../assets/iconEx2.png",

  //   },
  //   {
  //     name: "Auto-immune",
  //     icon: "../../assets/iconEx3.png",

  //   },
  //   {
  //     name: "Cardiology",
  //     icon: "../../assets/iconEx4.png",

  //   },
  //   {
  //     name: "Cardiology",
  //     icon: "../../assets/iconEx4.png",

  //   },
  //   {
  //     name: "Cardiology",
  //     icon: "../../assets/iconEx4.png",

  //   },

  // ]

  rightArrowCategorie;
  timerCategories;
  duration: number;
  moreThan24Hours: { leftTime: number; format: any; formatDate: ({ date, formatStr }: { date: any; formatStr: any; }) => any; };
  selectedVideo: any;
  recommendedEventScrolledIntoView: boolean = false
  upcomingEventScrolledIntoView: boolean = false;
  upcommingEvent: boolean = false
  playlist: boolean = false
  speaker: boolean = false
  testimonial: boolean = false
  footer: boolean = false
  speakerScrolledIntoView: boolean = false
  playlistEventScrolledIntoView: boolean = false
  testimonialsScrolledIntoView: boolean = false
  speakerComponent: boolean = false;
  testimonialComponent: boolean = false;
  playlistComponent: boolean = false;
  upcommingEventComponent: boolean = false;
  startLeft = 0
  index = -100
  counter: number = 0;
  // Imagedata = "https://t4.ftcdn.net/jpg/04/67/52/33/240_F_467523338_Jqts8Kr7VL5N1UqF8HeGu60vTu60v32P.jpg"
  Imagedata
  defaultImage = '../../assets/default.jpg';
  imageStates: string[] = [];
  indexBar = 0;
  clickOnSliderBar: any;
  loaded: boolean = false;

  /*****From config******/
  top_footer_config
  bottom_footer_config  /*********************/
  constructor(public exploreService: ExploreEventService, public exploredashboardService: ExploreService, public dialog: NbDialogService, public router: Router, private cd: ChangeDetectorRef, private spinner: NgxSpinnerService) { }
  ngOnInit() {
/**********Configuration**************/
this.top_footer_config=config.top_footer
this.bottom_footer_config=config.bottom_footer

/***********************************/

    this.speakerComponent = false;
    this.testimonialComponent = false;
    this.playlistComponent = false;
    this.upcommingEventComponent = false;
    this.exploreService.onChangedCategories.subscribe((res) => {
      if (res) {
        this.ListCategories = res.categories
        this.Imagedata = res.explore_video_url
      }
    })
    this.exploreService.getAllEvents().then((res: any) => {
    })
    // setTimeout(() => {
    //   this.spinner.hide("homespinner");
    // }, 50000);
  }
  ngAfterViewInit() {
    let audio = document.getElementById('videoCover') as HTMLMediaElement
    let audioAdmin = document.getElementById('videoCoverAdmin') as HTMLMediaElement
    if (audio) { audio.muted = true ; this.muted = true}
    if (audioAdmin) { audio.muted = true; this.muted = true }
    this.spinner.show("homespinner");
    if (document.getElementById("categories") && document.getElementById("categories-container")) {
      if (document.getElementById("categories").scrollWidth > document.getElementById("categories-container").offsetWidth) {
        this.overflowActivatedCategorie = true
      }
    }
    var ua = navigator.userAgent;

    if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) || window.window.innerWidth <= 880) {
      this.upcommingEvent = true
      this.upcommingEventComponent = true
      if (document.getElementById("upcoming-event")) {
        this.exploreService.getLaunchedEvents().then((res: any) => {
        })
      }
    }
  }

  // @HostListener('window:scroll', ['$event'])
  // isScrolledIntoView() {
  //   let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
  //   let max = document.documentElement.scrollHeight;
  //   if (this.upcommingEvent) {
  //     this.upcommingEventComponent = true
  //     if (document.getElementById("upcoming-event")) {
  //       if (!this.upcomingEventScrolledIntoView) {
  //         const rect = document.getElementById("upcoming-event").getBoundingClientRect();
  //         const topShown = rect.top >= 0;
  //         const bottomShown = rect.bottom <= window.innerHeight;
  //         this.upcomingEventScrolledIntoView = topShown;
  //         // if (this.upcomingEventScrolledIntoView) {
  //           this.exploreService.getLaunchedEvents().then((res: any) => {
  //           })
  //         // }
  //       }
  //     }
  //   }
  //   if (this.playlist) {
  //     this.playlistComponent = true
  //     if (document.getElementById("playlist")) {
  //       if (!this.playlistEventScrolledIntoView) {
  //         const rect = document.getElementById("playlist").getBoundingClientRect();
  //         const topShown = rect.top >= 0;
  //         const bottomShown = Math.round(rect.bottom) <= window.innerHeight;
  //         this.playlistEventScrolledIntoView = topShown;
  //         // if (this.playlistEventScrolledIntoView) {
  //           this.exploreService.getPlaylist().then((res: any) => {
  //           })
  //         // }
  //       }
  //     }
  //   }
  //   if (this.speaker) {
  //     this.speakerComponent = true
  //     if (document.getElementById("speakers")) {
  //       if (!this.speakerScrolledIntoView) {
  //         const rect = document.getElementById("speakers").getBoundingClientRect();
  //         const topShown = rect.top >= 0;
  //         const bottomShown = Math.round(rect.bottom) <= window.innerHeight;
  //         this.speakerScrolledIntoView = topShown;
  //         if (this.speakerScrolledIntoView) {
  //           this.exploreService.getSpeakers().then((res: any) => {
  //           })
  //         }
  //       }
  //     }
  //   }
  //   if (this.testimonial) {
  //     this.testimonialComponent = true
  //     if (document.getElementById("testimonial")) {
  //       if (!this.testimonialsScrolledIntoView) {
  //         const rect = document.getElementById("testimonial").getBoundingClientRect();
  //         const topShown = rect.top >= 0;
  //         const bottomShown = Math.round(rect.bottom) <= window.innerHeight;
  //         this.testimonialsScrolledIntoView = topShown;
  //         if (this.testimonialsScrolledIntoView) {
  //           this.exploreService.getTestimonials().then((res: any) => {
  //           })
  //         }
  //       }
  //     }
  //   }
  //   // }
  // }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (document.getElementById("categories") && document.getElementById("categories-container")) {
      if (document.getElementById("categories").scrollWidth > document.getElementById("categories-container").offsetWidth) {
        this.overflowActivatedCategorie = true
      }
      else {
        this.overflowActivatedCategorie = false
        this.rightArrowCategorie = 0
      }
    }
  }
  onActiveBloc(event) {
    if (event == "upcommingEvent") {
      this.upcommingEvent = true
      this.upcommingEventComponent = true
      this.exploreService.getLaunchedEvents().then((res: any) => {
      })
    }
    else if (event == "playlist") {
      this.playlistComponent = true
      this.playlist = true
      this.exploreService.getPlaylist().then((res: any) => {
      })
    }
    else if (event == "speakers") {
      this.speakerComponent = true
      this.speaker = true
      this.exploreService.getSpeakers().then((res: any) => {
      })
    }
    else if (event == "testimonials") {
      this.testimonialComponent = true
      this.testimonial = true
      this.exploreService.getTestimonials().then((res: any) => {
      })
    }
    else if (event == "footer") {
      this.footer = true
    }
  }
  onStateChange(state: StateChange) {
    this.imageStates.push(state.reason);
    if (state.reason == "finally") {
      this.spinner.hide("homespinner");
    }
    this.cd.detectChanges();
  }

  // countDown(date, utcoffset) {
  //   let startevent = new Date(date)
  //   let today = new Date();
  //   let timezone = utcoffset
  //   if (!timezone.includes('.')) {
  //     startevent.setHours(startevent.getHours() - Number(timezone));
  //   }
  //   else if (timezone.includes('.')) {
  //     startevent.setHours(startevent.getHours() - Number(timezone.split('.')[0]));
  //     if (Number(timezone.split('.')[1]) != 5) {
  //       startevent.setMinutes(startevent.getMinutes() - Number(timezone.split('.')[1]));
  //     }
  //     else if (Number(timezone.split('.')[1]) == 5) {
  //       startevent.setMinutes(startevent.getMinutes() - 30);
  //     }
  //   }
  //   let difftime = ((startevent.getTime() - (today.getTime() + (today.getTimezoneOffset() * 60))) / 1000)
  //   let formatDate;
  //   var d = Math.floor(difftime / (3600 * 24));
  //   if (d == 0) formatDate = "HHh:MMm:SSs"
  //   else formatDate = "Dd:HHh:MMm:SSs"
  //   this.moreThan24Hours = {
  //     leftTime: difftime,
  //     format: formatDate,
  //     formatDate: ({ date, formatStr }) => {
  //       this.duration = Number(date || 0);
  //       return CountdownTimeUnits.reduce((current, [name, unit]) => {
  //         if (current && current.indexOf(name) !== -1) {
  //           const v = Math.floor(this.duration / unit);
  //           this.duration -= v * unit;
  //           return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
  //             return v.toString().padStart(match.length, '0');
  //           });
  //         }

  //         return current;
  //       }, formatStr);
  //     },
  //   };
  // }
  scrollCategories(elementToScroll: HTMLElement, depl, div, arrowId) {
    if (this.ListCategories.length > 0) {
      if (document.getElementById(arrowId) != null) {
        elementToScroll = document.getElementById("categories") as HTMLInputElement
        elementToScroll.scrollLeft -= depl;
        this.rightArrowCategorie = elementToScroll.scrollLeft
        this.timerCategories = setTimeout(() => {
          this.scrollCategories(elementToScroll, depl, div, arrowId)
        }, 30);
      }
    }

  }
  stopTimerCategories(timerCategories: number) {
    clearTimeout(timerCategories);
  }

  /************************Dashboard**********************/
  uploaded = false
  videoSrc
  errorMessage = { video: "" }
  AddCategory() {
    this.exploredashboardService.getCategories().then((res: any) => {
      const dialogRef = this.dialog.open(EditExplorePopupComponent, {
        context:
        {
          data: {
            name: "addcategory",
            explore_id: this.exploreService.explore_id,
            icons: res,
            categories: this.ListCategories
          },
        }, hasBackdrop: false
      });
      dialogRef.onClose.subscribe((result) => {
      });
    })
  }
  readURL(event): void {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0] && !event.target.files[0].type.includes('video/mp4')) {
        this.errorMessage.video = "Invalid file type,only files with the following extensions are allowed:.mp4 "
      }
      else {
        this.uploaded = true
        this.errorMessage.video = "";
        this.videoSrc = ""
        const file = event.target.files[0];
        this.video = event.target.files[0];
        const reader = new FileReader();
        reader.onload = e => {
          this.videoSrc = reader.result;
        }
        reader.readAsDataURL(file);
      }
    }
  }
  cancelVideo() {
    this.uploaded = false
    this.errorMessage.video = "";
    this.videoSrc = ""
    this.video = null
  }
  saveVideo() {
    if (this.video && this.video != null && this.video != '') {

      this.exploredashboardService.editExploreVideoCover(this.video, this.exploreService.explore_id).then((res: any) => {
        if (res && res.explore_video_url) {
          this.uploaded = false
          this.errorMessage.video = "";
          this.videoSrc = ""
          this.Imagedata = res.explore_video_url

        }
      })
    }
  }

  soundOnOff(value) {
    let audio = document.getElementById('videoCover') as HTMLMediaElement
    let audioAdmin = document.getElementById('videoCoverAdmin') as HTMLMediaElement

    if (value) {
      if (audio) { audio.muted = false }
      if (audioAdmin) { audio.muted = false }
      if(this.video)
     { this.video.muted = false}
      this.muted = false
    }
    else {

      if (audio) { audio.muted = true }
      if (audioAdmin) { audio.muted = true }
      if(this.video)
     { this.video.muted = true}
      this.muted = true

    }
  }
}