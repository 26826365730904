import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DashboardTestService } from '../dashboard-test.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
@Component({
  selector: 'app-event-page',
  templateUrl: './event-page.component.html',
  styleUrls: ['./event-page.component.css', '../../portal/portal-event/portal-event.component.css']
})
export class EventPageComponent implements OnInit {
  event
  EventForm: FormGroup
  activate_is_inviteonly = false
  timezone = [
    { name: "(GMT -12:00) Eniwetok, Kwajalein", value: "-12:00" },
    { name: "(GMT -11:00) Midway Island, Samoa", value: "-11:00" },
    { name: "(GMT -10:00) Hawaii", value: "-10:00" },
    { name: "(GMT -9:30) Taiohae", value: "-09:50" },
    { name: "(GMT -9:00) Alaska", value: "-09:00" },
    { name: "(GMT -8:00) Pacific Time (US &amp; Canada)", value: "-08:00" },
    { name: "(GMT -7:00) Mountain Time (US &amp; Canada)", value: "-07:00" },
    { name: "(GMT -6:00) Central Time (US &amp; Canada), Mexico City", value: "-06:00" },
    { name: "(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima", value: "-05:00" },
    { name: "(GMT -4:30) Caracas", value: "-04:50" },
    { name: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz", value: "-04:00" },
    { name: "(GMT -3:30) Newfoundland", value: "-03:50" },
    { name: "(GMT -3:00) Brazil, Buenos Aires, Georgetown", value: "-03:00" },
    { name: "(GMT -2:00) Mid-Atlantic", value: "-02:00" },
    { name: "(GMT -1:00) Azores, Cape Verde Islands", value: "-01:00" },
    { name: "(GMT) Western Europe Time, London, Lisbon, Casablanca", value: "+00:00" },
    { name: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris", value: "+01:00" },
    { name: "(GMT +2:00) Kaliningrad, South Africa", value: "+02:00" },
    { name: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg", value: "+03:00" },
    { name: "(GMT +3:30) Tehran", value: "+03:50" },
    { name: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", value: "+04:00" },
    { name: "(GMT +4:30) Kabul", value: "+04:50" },
    { name: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent", value: "+05:00" },
    { name: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", value: "+05:50" },
    { name: "(GMT +5:45) Kathmandu, Pokhara", value: "+05:75" },
    { name: "(GMT +6:00) Almaty, Dhaka, Colombo", value: "+06:00" },
    { name: "(GMT +6:30) Yangon, Mandalay", value: "+06:50" },
    { name: "(GMT +7:00) Bangkok, Hanoi, Jakarta", value: "+07:00" },
    { name: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong", value: "+08:00" },
    { name: "(GMT +8:45) Eucla", value: "+08:75" },
    { name: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk", value: "+09:00" },
    { name: "(GMT +9:30) Adelaide, Darwin", value: "+09:50" },
    { name: "(GMT +10:00) Eastern Australia, Guam, Vladivostok", value: "+10:00" },
    { name: "(GMT +10:30) Lord Howe Island", value: "+10:50" },
    { name: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia", value: "+11:00" },
    { name: "(GMT +11:30) Norfolk Island", value: "+11:50" },
    { name: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka", value: "+12:00" },
    { name: "(GMT +12:45) Chatham Islands", value: "+12:75" },
    { name: "(GMT +13:00) Apia, Nukualofa", value: "+13:00" },
    { name: "(GMT +14:00) Line Islands, Tokelau", value: "+14:00" }
  ]
  cover
  submitted = false
  sameday = false
  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  tags = []
  imageSrc
  public Editor = ClassicEditor;
  public editorData = '';
  errorsMsg = { "title": "", "short_summary": "" }
  currentButton = 'description'
  sponsors = []
  videos=[]
  sponsorsSrc = []
  videoSrc=[]
  fileTypes = ["image/png", "image/gif", "image/jpeg", "image/jpg"]
  errorMessage = { sponsors: "", video: "", cover: "" }
  
  constructor(private dashboardtestservice: DashboardTestService, private fb: FormBuilder) { }
  ngOnInit(): void {
    this.dashboardtestservice.onChangedEventById.subscribe((response: any) => {
      
      if (response && response.status == "success") { this.event = response.event }
    })
    this.EventForm = this.fb.group({
      title: new FormControl(this.event.title, [Validators.required, Validators.maxLength(50)]),
      start_date: new FormControl(this.event.start_date.split('.')[0], [Validators.required]),
      end_date: new FormControl(this.event.end_date.split('.')[0], [Validators.required]),
      organisation: new FormControl(this.event.organizer._id, [Validators.required]),
      cover_url: new FormControl(this.event.cover_url, []),
      short_summary: new FormControl(this.event.short_summary, [Validators.maxLength(200)]),
      utcoffset: new FormControl(this.event.utcoffset, []),
      tags: new FormControl(this.event.tags, []),
      description: new FormControl(this.event.description, []),
      sponsors: new FormControl(this.event.sponsors, []),
      videos: new FormControl(this.event.videos, []),
      areas: new FormControl(this.event.areas, []),
      type: new FormControl(this.event.type, []),

    })
    if (this.EventForm.controls['tags'].value && this.EventForm.controls['tags'].value != null && this.EventForm.controls['tags'].value != '' && this.EventForm.controls['tags'].value.length > 0) {
      this.tags = this.EventForm.controls['tags'].value
    }
    else {
      this.tags = []
    }
    if (this.EventForm.controls['areas'].value && this.EventForm.controls['areas'].value != null && this.EventForm.controls['areas'].value != '' && this.EventForm.controls['areas'].value.length > 0) {
      this.areas = Object.assign([], this.EventForm.controls['areas'].value)
    }
    else {
      this.areas = []
    }
  }
  convertDate(startdate, enddate, utcoffset) {
    if (!startdate && !enddate) {
    } else {
      let starttime = this.convertTimeToAM_PM(startdate.split('T')[1].slice(0, 5));
      let endtime = this.convertTimeToAM_PM(enddate.split('T')[1].slice(0, 5));
      startdate = startdate.split('T')[0].split("-");
      enddate = enddate.split('T')[0].split("-");
      let newdate = ["", "", ""];
      newdate[0] = this.months[startdate[1] - 1].slice(0, 3) + " " + startdate[2].slice(0, 2) + ", " + starttime
      if (startdate[2].slice(0, 2) == enddate[2].slice(0, 2)) {
        this.sameday = true
        newdate[1] = endtime
      }
      else {
        this.sameday = false
        newdate[1] = this.months[enddate[1] - 1].slice(0, 3) + " " + enddate[2].slice(0, 2) + ", " + endtime
      }
      newdate[2] = this.convertUtCoffset(utcoffset)
      return newdate;
    }
  }
  convertTimeToAM_PM(time) {
    if (time) {
      let hour = time.split(':')[0]
      if (hour == '00') {
        return ("12:" + time.split(':')[1] + "AM")
      }
      else if (Number(hour) == 12) {
        return ("12:" + time.split(':')[1] + "PM")
      }
      else if (Number(hour) > 12) {
        return (Number(hour - 12) + ":" + time.split(':')[1] + "PM")
      }
      else if (hour < 12) {
        if (hour < 10) {
          return (hour[1] + ":" + time.split(':')[1] + "AM")
        }
        else {
          return (time + "AM")
        }
      }
    }
  }
  convertUtCoffset(utcoffset) {
    let convertedUTC = ["", "", "+"]
    if (utcoffset) {
      if (utcoffset.includes('-')) {
        convertedUTC[2] = ''
      }
      let utc = utcoffset.split('.')
      convertedUTC[0] = utc[0]
      if (!utc[1]) {
        convertedUTC[1] = "00"
      }
      else if (utc[1].length == 1 && utc[1] != '5') {
        convertedUTC[1] = utc[1] + "0"
      }
      else if (utc[1].length == 1 && utc[1] == '5') {
        convertedUTC[1] = "30"
      }
      else if (utc[1].length == 2) {
        convertedUTC[1] = utc[1]
      }
      if (!utc[1]) {
        return convertedUTC[2] + convertedUTC[0];
      }
      else {
        return convertedUTC[2] + convertedUTC[0] + ":" + convertedUTC[1]
      }
    }
  }
  editcover(event) {
    if (event && event.target && event.target.files[0]) {
      this.cover = event.target.files[0]
      this.dashboardtestservice.editCover(this.cover, this.event._id).then((res: any) => { })
    }
  }
  get formControls() {
    return this.EventForm.controls;
  }
  selectUTC(event) {
  }
  clearSelected(event) {
  }
  addTag(event): void {
    this.tags.push(event.target.value);
    this.EventForm.controls['tags'].setValue(this.tags);
    (document.getElementById('InputTag') as HTMLInputElement).value = ''
  }
  removeTag(index) {
    this.tags.splice(index, 1);
    this.EventForm.controls['tags'].setValue(this.tags)
  }
  saveChanges() {
    this.submitted = true;
    if (this.EventForm.invalid) {
      return;
    }
    else {
      this.dashboardtestservice.editEvent(this.event._id, this.EventForm.value, this.cover, this.sponsors,this.videos).then((res: any) => {
        if (res.status == "success") {
        }
      });
      this.submitted = false;
    }
  }
  readURL(event): void {
    if (event.target.files && event.target.files[0]) {
      if (!this.fileTypes.includes(event.target.files[0].type) && !event.target.files[0].type.includes('video')) {
       
        
        this.errorMessage.cover = "Invalid file type,only video or file with the following extensions are allowed: .png, .jpeg, .jpg, .gif"
      }
      else {
        this.errorMessage.cover = ""
        const file = event.target.files[0];
        this.cover = event.target.files[0];
        const reader = new FileReader();
        reader.onload = e => { 
          
          this.imageSrc = reader.result;
        }
        reader.readAsDataURL(file);
      }
    }
  }

  readMultipleURL(filestype, event): void {
    if (filestype == 'sponsors') {
      if (!this.fileTypes.includes(event.target.files[0].type)) {
        this.errorMessage.sponsors = "Invalid file type,only file with the following extensions are allowed: .png, .jpeg, .jpg, .gif"
      }
      else {
        this.errorMessage.sponsors = ""
        if (event.target.files && event.target.files[0]) {
          const file = event.target.files[0];
          this.sponsors.push(event.target.files[0])
          
          const reader = new FileReader();
          reader.onload = e => {
            this.sponsorsSrc.push(reader.result)
          };
          reader.readAsDataURL(file);
        }
      }
    }
    else if (filestype == 'videos') {
      if (!event.target.files[0].type.includes('video/')) {
        this.errorMessage.video = "Invalid file type,only video is  allowed"
      }
      else {
        this.errorMessage.video = ""
        if (event.target.files && event.target.files[0]) {
          const file = event.target.files[0];
          this.videos.push(event.target.files[0])
          const reader = new FileReader();
          reader.onload = e => {
            this.videoSrc.push(reader.result)
          };
          reader.readAsDataURL(file);
        }
      }
    }
  }
  limitInput(event, input) {
    if (input == 'title') {
      if (event.target.value && event.target.value.length > 50) {
        this.errorsMsg.title = "Maximum 50 characters (" + (Number(event.target.value.length) - Number(50)) + " over)"
      }
      else {
        this.errorsMsg.title = ""
      }
    }
    if (input == 'short_summary') {
      if (event.target.value && event.target.value.length > 200) {
        this.errorsMsg.short_summary = "Maximum 200 characters (" + (Number(event.target.value.length) - Number(200)) + " over)"
      }
      else {
        this.errorsMsg.short_summary = ""
      }
    }
  }
  removeSponsorForm(index) {
    this.EventForm.controls['sponsors'].value.splice(index, 1)
  }
  removeSponsor(index) {
    this.sponsors.splice(index, 1);
    this.sponsorsSrc.splice(index, 1);
  }
  removeVideoForm(index) {
    this.EventForm.controls['videos'].value.splice(index, 1)
  }
  removeVideo(index) {
    this.videos.splice(index, 1);
    this.videoSrc.splice(index, 1);
  }
  clickInput(event) {
    event.target.value = null
  }
  areas=[]
  selectArea(e,area){
  
     if(!e){
      this.areas.splice(this.areas.indexOf(area), 1)
     }
    else{
  
      this.areas.push(area)
     
    }
    this.EventForm.controls['areas'].setValue(this.areas);
     
  }
  
}
